export default {
  addKeyModal: {
    action: '申し込み',
    errorMessage:
      'キーの適用中にエラーが発生しました。問題が解決しない場合は、MetricaSportsサポートチームに連絡してください',
    minutesMessage: 'あなたのキーが処理されました。%{minutes}分が利用可能になりました。',
    placeholder: 'キーを入力してください...',
    successMessage: 'キーは正常に処理されました',
    title: 'キーを追加する'
  },
  admin: {
    NoGames: 'ゲームはありません。最初のゲームを追加してください...',
    NoResults: '結果がありません...',
    addKey: 'キーを追加',
    atd: 'ATD',
    automatedTrackingData: '自動追跡データ（ATD）',
    automatedTrackingDataJobSuccess:
      '%{vpName} での自動追跡データ処理が終了し、Metrica Play で利用できるようになりました',
    automatedTrackingDataProcessedTooltip: '処理された自動追跡データ',
    awayTeam: 'アウェイチーム',
    awayTeamScore: 'アウェイチームスコア',
    ballDetection: 'ボール検出',
    ballTracking: 'ボールTracking',
    ballTrackingProcessedTooltip: 'ボール検出が処理されました',
    ballTrackingTooltip:
      'このプロセスにより、ボールが検出され、Playのライブフォーメーション（2Dピッチ）内でボールを視覚化できるようになります。ボール座標は、FIFA EPTS形式のファイルでも利用できます。',
    cancelATDProcessSuccessfully: '自動追跡データプロセスが %{vpName} でキャンセルされました',
    cancelDataIntegrationProcessSuccessfully:
      'データ統合プロセスは %{vpName} でキャンセルされました',
    cancelEventsProcessSuccessfully: 'Smart Taggingのプロセスは%{vpName}でキャンセルされました。',
    cancelProcessTooltip: 'プロセスをキャンセルする',
    cancelSurfacesProcessSuccessfully: 'サーフェス処理が %{vpName} でキャンセルされました。',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Tracking FIFA フォーマットプロセスは %{vpName} でキャンセルされました',
    category: 'カテゴリー',
    checking: 'チェック中...',
    cloudMinutes: 'クラウド時間',
    competition: 'コンペ',
    connectionFailed: '接続に失敗しました',
    connectionFailedText:
      'ステータス情報の取得に問題があるようです。インターネット接続を確認して、ページをリロードしてください。問題が解決しない場合は、サポートに連絡してください',
    correctedTracking: 'Tracking修正処理中',
    correctionUsage: 'このビデオプロジェクトをTrackingの修正に使用しますか。',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM)は、ATDで作成されたトラックを修正するためのPlay内のユーザー向けのモジュールで、あらゆるサッカーゲームにおいてIDのないデータを自動的に生成します。TCMを使用して、間違ったトラックを修正し、IDを割り当てることができます。詳しくは、弊社にお問い合わせください。',
    createVideoProject: 'ビデオプロジェクトを作成する',
    creatingGame: 'ビデオプロジェクトを作成しています...',
    dataIntegrationFailed:
      '%{vpName} でデータ統合に失敗しました。データ統合パネルに移動し、ファイルを確認して再試行してください。エラーが続く場合は、Metrica Sports サポートチームに連絡してください',
    dataIntegrationJobSuccess: '%{vpName} のデータ統合プロセスが正常に終了しました',
    date: '日付',
    description: '説明',
    editedSuccessfully: '%{vpName} は正常に編集されました',
    errorContactSupport: 'エラーが発生しました。サポートに連絡する',
    errorProcessTooltip: 'プロセス中にエラーが発生しました',
    eventsDetection: '自動タグ付け',
    eventsDetectionProcessedTooltip: 'スマートタギング処理済み',
    eventsDetectionTooltip: {
      footer: '*30分以上のビデオで利用可能です',
      header: '私たちの技術を利用して、試合中に発生する一連のイベントを自動的に検出しましょう。',
      improved: '改善された'
    },
    fieldRecognition: 'フィールド検出',
    fieldTrackingProcessedTooltip: 'フィールド検出が処理されました',
    fieldTrackingTooltip:
      'フィールド検出を処理して、Playのフィールドに応じて、速度と距離の値とすべての図面を使用して視覚化を追加します。',
    fileDeletedSuccessfully: '%{fileName} は %{vpName} に対して正常に削除されました',
    fileUploadedError: '%{vpName} の %{fileName} のアップロード中にエラーが発生しました',
    fileUploadedSuccessfully: '%{fileName} が %{vpName} に対して正常にアップロードされました',
    forkedGameLimit: 'このビデオプロジェクトは別の組織に属しているため、変更することはできません',
    gameDeletedSuccessfully: '%{game} が正常に削除されました',
    gameVideoProject: 'ゲームビデオプロジェクト',
    getMoreMinutes: 'さらに入手',
    homeTeam: 'ホームチーム',
    homeTeamScore: 'ホームチームスコア',
    initializing: '初期化中...',
    jobFailed:
      '%{process} プロセスが %{vpName} に失敗しました。Metrica Sports サポートチームに連絡してください',
    liveCoding: 'またはライブコーディングを続行しますか？',
    liveCodingTooltip:
      'ライブコーディング用のビデオファイルを選択せずに、ビデオプロジェクトを作成できます。ビデオを入手したら、それを追加して、コード化されたビデオプロジェクト内に自動追跡データを含めることができます。',
    localTeamOnTheLeft: '「ホームチーム」は前半のフィールドの左サイドにありますか？',
    localTeamOnTheLeftTooltip:
      '自動タグ付けを行うには、各チームがピッチのどちら側に配置されているかを指定します。アップロードしている動画をチェックして検証してください。',
    noFootballSideViewTooltip:
      'このビデオプロジェクトには、サッカー以外の広角またはテレビ放送のビデオファイルが含まれているため、プレーヤーとチームの検出のみが処理されています。',
    noMatches: '一致するものはありません...',
    noSubscriptionOwnerTooltip:
      'サブスクリプションの Admin である「%{email}」に連絡してアップグレードするか、プランページにアクセスして独自のプランを作成してください',
    noVideoFile: 'ビデオファイルがありません',
    notPossibleToDeleteThisEntry: 'このエントリーを削除することはできません',
    numberOfHalves: 'このゲームの半分はいくつありますか？',
    numberOfHalvesTooltip:
      '自動タグ付けを処理するには、アップロードする動画に含めるゲーム期間の数を指定します。',
    oldAtdTooltip: 'このビデオは古いバージョンのATDで処理されています',
    onRequest: '要求に応じて',
    organizations: '組織',
    place: '位置',
    playerTracking: 'プレーヤーとチームの検出',
    playerTrackingProcessedTooltip: 'プレーヤーの検出が処理されました',
    playerTrackingTooltip:
      'このプロセスでは、各チームのゴールキーパーと10人のフィールドプレーヤーが検出されます。これにより、ライブフォーメーション（2Dピッチ）を視覚化し、Playにプレーヤーの視覚化を自動的に追加できます。プレーヤーの座標は、FIFA EPTS形式のファイルでも利用できます。',
    postProcessingData: '後処理データ...',
    preProcessingAtd: '前処理中...',
    processingTooltip: '処理中...',
    readyToUpload: 'アップロードする準備ができました',
    search: '検索...',
    selectVideoFile: 'ビデオファイルを選択',
    settingUpAtd: '検出をセットアップ中...',
    settings: '設定',
    singleVideoProject: 'シングルビデオプロジェクト',
    startAtd: 'ATDを開始します',
    startFifaMessage:
      '%{vpName} の FIFA 統合プロセスが開始されました。完了したら通知を表示しますので、しばらくお待ちください。',
    startProcessingWaitingJobsFailed: '待機中のジョブの送信に失敗しました',
    startProcessingWaitingJobsSuccessfully: '待機中のジョブが正常に送信されました',
    startSsMessage:
      '%{vpName} の Second Spectrum 統合プロセスが開始されました。完了したら通知を表示しますので、しばらくお待ちください。',
    startStatsMessage:
      '%{vpName} の Stats 統合プロセスが開始されました。完了したら通知を表示しますので、しばらくお待ちください。',
    startTracabDflMessage:
      '%{vpName} の Tracab DFL 統合プロセスが開始されました。完了したら通知を表示しますので、しばらくお待ちください。',
    startTracabMessage:
      '%{vpName} の Tracab 統合プロセスが開始されました。完了したら通知を表示しますので、しばらくお待ちください。',
    surfacesJobSuccess: '%{vpName} サーフェスプロセスが正常に終了しました',
    trackingCorrectionProcessedTooltip: 'Tracking修正処理済み',
    trackingFifaFormatJobSuccess:
      '%{vpName} の FIFA フォーマットプロセスの Tracking は正常に終了しました。これで、FIFA 形式のダウンロードメニュー項目をクリックしてファイルをダウンロードできます。',
    trackingLicence: 'Trackingライセンスがありません',
    uncaughtErrorRow:
      'ビデオプロジェクトエラー。このページを更新してください。問題が解決しない場合は、サポートに連絡するか、削除して再作成してください',
    updatingGame: 'ビデオプロジェクトを更新中...',
    upgradePlan: 'アップグレード',
    uploadAndTracking: 'アップロードとTracking',
    uploadingVideoFile: 'ビデオファイルのアップロード',
    videoFile: 'ビデオファイル',
    videoFootballSideView: 'このビデオファイルはサッカーの広角またはテレビ放送ですか？',
    videoFootballSideViewTooltip:
      'フィールド検出は、これらのタイプのビデオに対してのみ最適化されています。',
    videoMultipartStart:
      '%{videoName} は %{vpName} にアップロードされています。アップロードが完了するまで、このページを閉じないでください',
    videoPlaylist: 'このビデオファイルは再生リストですか？',
    videoPlaylistTooltip:
      'YESを選択すると、クリップ検出が実行され、ビデオ内でカットが行われたことを認識します。例えば、複数のクリップを含むビデオをアップロードする場合に最適なツールです。この処理を実行した後、Playでビデオプロジェクトをダウンロードすると、イベントとしてすべてのカット含むコードと共にクリップ検出のコーディングファイルがあります。',
    videoProjectSettings: 'ビデオプロジェクトの設定',
    videoUploadError:
      '%{vpName} の %{videoName} の動画アップロードに失敗しました。もう一度やり直してください',
    videoUploaded: '%{videoName} が %{vpName} にアップロードされました',
    vpCreated: '%{vpName} が作成されました',
    waitingAtd: '待機中…'
  },
  billing: {
    address: '住所',
    billed: '請求済み',
    completed: '支払済み',
    details: '請求の詳細',
    history: '請求履歴',
    invoiceNotFound: '請求書が見つかりません',
    noInvoices: '請求書がありません',
    past_due: '期限切れ',
    taxId: '納税者番号'
  },
  checkout: {
    chargeSubscription: 'サブスクリプションに課金する',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*割引コードはチェックアウト時に適用できます',
    excTax: '（税抜き）',
    immediatePayment: '即時支払い',
    incTax: '(税込)',
    monthlyBilled: '月額請求',
    nextPayment: '次の支払い',
    noPacks: 'クラウド分パックなし',
    onDate: '%{date}に',
    packs: 'パック',
    packsTotal: 'トータルパック',
    perMonth: '/月',
    perYear: '/年',
    plans: 'プラン',
    plansTotal: 'プラン合計',
    proceed: 'レジに進む',
    subtotal: '小計',
    taxPayable: '支払うべき税金（%{percentage}%）',
    total: '合計',
    unpaid:
      'あなたの最新の請求書はまだ支払われていません。続行するには、お支払い情報を更新してください。',
    updatePaymentMethod: '支払い方法を更新する',
    updateSubscription: 'サブスクリプションを更新する',
    userDevice: '1ユーザー/デバイス',
    usersDevices: '%{number} ユーザー/デバイス',
    yearlyBilled: '年間請求'
  },
  common: {
    back: '戻る',
    cancel: 'キャンセル',
    canceling: 'キャンセル中...',
    clearAll: 'すべてクリア',
    edit: '編集',
    email: 'Eメール',
    errorMsg: '%{name} の処理中にエラーが発生しました。お問い合わせはサポートまでご連絡ください。',
    errorTryAgain: 'エラー。もう一度お試しください',
    genericError: 'エラー、プロセスが失敗しました。お問い合わせはサポートまでご連絡ください。',
    loading: 'ロード中…',
    login: 'ログイン',
    logout: 'ログアウト',
    new: '新しい',
    next: '次',
    no: 'No',
    noFileChosen: 'ファイルが選択されていません',
    ok: 'OK',
    required: '必須',
    reset: 'パスワードを再設定する',
    restart: '再起動',
    selectFile: 'ファイルを選ぶ',
    sort: '選別',
    start: '始める',
    today: '今日',
    uploading: 'アップロード中...',
    yes: 'Yes'
  },
  confirmModal: {
    description: 'よろしいですか？',
    title: '確認'
  },
  dataIntegration: {
    deleteFile: 'ファイルを削除する',
    featureNotAvailable:
      'この機能は、現在のプランでは利用できません。この機能を使用するには、別のプランにアップグレードする必要があります。詳細についてはお問い合わせください',
    notSideView:
      'データ統合は、サッカー以外の広角またはTV放送のビデオファイルを含むビデオプロジェクトでは使用できません。',
    open: 'データ統合を開く',
    processJobsFirst:
      '[データ統合]パネルにアクセスする前に、まず自動追跡データ（ATD）の処理を開始する必要があります',
    processed: '処理されたデータ統合',
    selectFile: 'ファイルを選ぶ',
    startProcess: 'プロセスの開始',
    title: 'データ統合',
    waiting: '待機中...',
    waitingProcess: 'プレーヤーとフィールドの検出プロセスが完了すると、統合プロセスが開始されます。'
  },
  deleteModal: {
    checkboxLabel: 'MetricaSportsからのメール受信も停止したい',
    confirmationType: '%{validateFormKey} と入力して、次のことを確認します。',
    deleteAction: 'Yes、削除します',
    description: 'アカウントを完全に削除してもよろしいですか？',
    information:
      'このアカウントで作成されたすべてのビデオプロジェクトが失われます。一度削除すると復旧できません。',
    keepAccount: 'アカウントを保持する',
    suggestion: 'アカウントを保持することをお勧めします。',
    title: '永遠に削除しますか？',
    validateFormKey: '削除'
  },
  error: {
    oops: 'おっと ！',
    restart: 'あなたは現在開発中のものに入ろうとしてるようです...'
  },
  eventsDetection: {
    automaticVp: 'Smart Taggingは、古いATDバージョンのビデオプロジェクトでは利用できません。',
    needsAtd: 'スマートタギングプロセスを実行するために、最初にATDプロセスを開始してください。',
    needsFootballSideView:
      'スマートタグ付けは、サッカー/フットボール以外の広角またはテレビ放送のビデオファイルを含むビデオプロジェクトでは利用できません。',
    needsGameVp: 'スマートタギングは、ゲームビデオプロジェクトでのみ利用可能です。',
    needsNoPlaylist:
      'スマートタグ付けは、プレイリストのビデオファイルを含むビデオプロジェクトでは利用できません。'
  },
  forgotPassword: {
    adminNoResetPassword:
      '管理者ユーザは、パスワードのリセットにプライベートエンドポイントを使用する必要がある',
    notFound: 'ユーザーが見つかりません。有効なメールアドレスでもう一度お試しください',
    success: 'パスワードがリセットされました！ メールを確認してください'
  },
  login: {
    contactUs: 'お問い合わせ',
    enterYourEmail: 'メールアドレスを入力',
    enterYourPassword: 'パスワードを入力してください',
    forgotPassword: 'パスワードをお忘れですか？',
    invalidEmail: '無効なメールアドレス',
    licenceExpired: 'ライセンスの有効期限が切れています。',
    trialExpired: 'ご利用のトライアルは終了しています。',
    upgradePlan: 'プランをアップグレードする'
  },
  patterns: {
    delete: 'パターンファイルを削除する',
    title: 'カスタムパターンファイル',
    upload: 'カスタムパターンファイルをアップロードする',
    uploaded: 'アップロードされたパターンファイル',
    uploading: 'パターンファイルのアップロード'
  },
  plans: {
    academic: 'アカデミック',
    addMoreProducts: '+ 製品を追加する',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code 月額',
    basicCodePadYearly: 'Basic+Code 年間',
    basicMftCodePadMonthly: 'Full Basic 月額',
    basicMftCodePadYearly: 'Full Basic 年間',
    basicMftMonthly: 'Basic+MFT 月額',
    basicMftXmlMonthly: 'Basic MFT + XML 月額',
    basicMftXmlYearly: 'Basic MFT + XML 年間',
    basicMftYearly: 'Basic+MFT 年間',
    basicMonthly: 'Basic 月額',
    basicXmlMonthly: 'Basic XML 月額',
    basicXmlYearly: 'Basic XML 年間',
    basicYearly: 'Basic 年間',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'エリート年間',
    eliteYearly: 'Elite 年間',
    essentialsCodeMonthly: 'Essentials + Code ∞ 月額',
    essentialsCodeYearly: 'Essentials + Code ∞ 年間',
    essentialsMftCodeMonthly: 'フルエッセンシャルズ月額',
    essentialsMftCodeYearly: 'フルエッセンシャル年間',
    essentialsMftMonthly: 'Essentials + MFT 月額',
    essentialsMftYearly: 'Essentials + MFT 年間',
    essentialsMonthly: 'Essentials 月額',
    essentialsYearly: 'Essentials 年間',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite 月額',
    liteYearly: 'Lite 年間',
    minutes10: '10クラウド分パック',
    minutes100: '100クラウド分パック',
    minutes1000: '1,000クラウド分パック',
    minutes10000: '10,000クラウド分パック',
    plan: '%{planName} プラン',
    primeMftMonthly: 'プライム + MFT 月額',
    primeMftYearly: 'プライム + MFT 年間',
    primeMonthly: 'プライム月額',
    primePresetsMftMonthly: 'フルプライム月額',
    primePresetsMftYearly: 'フルプライム年間',
    primePresetsMonthly: 'プライム + プリセット 月額',
    primePresetsYearly: 'プライム + プリセット 年間',
    primeYearly: 'プライム年間',
    pro: 'Pro',
    pro2024Monthly: 'プロ月額',
    pro2024Yearly: 'プロ年間',
    proYearly: 'Pro 年間',
    starter: 'Starter',
    starterCodeMonthly: 'スターター + コード ∞ 月額',
    starterCodePadMonthly: 'Code 月額',
    starterCodePauseMonthly: 'フルスターター月額',
    starterCodePauseYearly: 'フルスターター年間',
    starterCodeYearly: 'スターター + コード ∞ 年間',
    starterPauseMonthly: 'スターター + ポーズ ∞ 月額',
    starterPauseYearly: 'スターター + ポーズ ∞ 年間',
    student: '学生',
    trial: 'Trial'
  },
  playlist: {
    download: 'プレイリストファイルをダウンロード',
    notProcessed: 'プレイリストファイルは、このビデオプロジェクトでは処理されませんでした。',
    processed: 'プレイリストファイルが処理されました',
    title: 'プレイリスト',
    titleDownload: 'プレイリストをダウンロード'
  },
  products: {
    addOns: 'アドオン',
    basic: '無制限のコード＆アノテーション',
    code: 'CodePad',
    elite: {
      description:
        '分析ワークフローを自動化し、カスタマイズします。このプランには、"Full Essentials" プランの全機能と、"Pro" プランの全機能を備えたPlay2への完全アクセス、及びPlay3へのアクセスが含まれます。',
      feature1: '✓ 共有クラウドストレージ',
      feature2: '✓ トラッキングデータの統合',
      feature3: '✓ トラッキング修正マネージャー',
      feature4: '✓ RESTful API',
      feature5: '✓ 5,000 ATD 分パック'
    },
    essentials: {
      addon1: '無制限のCoding機能（CodePad、ライブCoding、XMLインポート）',
      addon2: 'マニュアルフィールドトラッキング',
      essentialsCodeDescription:
        'このプランでは、ビデオの再生時および一時停止時に無制限の動きのビジュアライゼーションを追加できるダイナミックテレストレーションが利用可能になります。このプランにはマニュアルフィールドトラッキングが含まれていないため、速度と距離のビジュアライゼーションは含まれません。また、CodePad、ライブコーディング、XMLインポートなどのコーディング機能の制限を解除し、ビデオプロジェクトごとのコードの制限を4つから無制限にします。',
      essentialsDescription:
        'このプランでは、ビデオの再生時および一時停止時に無制限の動きのビジュアライゼーションを追加できるダイナミックテレストレーションが利用可能になります。このプランにはマニュアルフィールドトラッキングが含まれていないため、速度および距離のビジュアライゼーションは含まれません。コーディング機能は限定されています。',
      essentialsMftCodeDescription:
        'このプランでは、ビデオの再生時および一時停止時に無制限の動きのビジュアライゼーションを追加できるダイナミックテレストレーションが利用可能になり、マニュエルフィールドトラッキングも有効になり、速度と距離のビジュアライゼーションを追加できます。また、CodePad、ライブコーディング、XMLインポートなどのコーディング機能の制限を解除し、ビデオプロジェクトごとのコードの制限を4つから無制限にします。',
      essentialsMftDescription:
        'このプランは、ビデオの再生時および一時停止時に無制限の動きのビジュアライゼーションを追加できるダイナミックテレストレーションを有効にし、マニュエルフィールドトラッキングも活性化して、速度と距離のビジュアライゼーションを追加できます。コーディング機能は限定されたままです。',
      feature1: '✓ 試合後のコーディング（最大4つのコードを含むCode Table）',
      feature2: '✓ 一時停止中の無制限のテレストレーション',
      feature3: '✓ ダイナミックテレストレーション',
      feature4: '✓ 3分間のATDパック'
    },
    featuresIncluded: '含まれる機能:',
    freeFeature1: '✓ 試合後のコーディング（最大4つのコードを含むCode Table）',
    freeFeature2: '✓ キーフレーミング',
    freeFeature3: '✓ テレストレーション（最大150の注釈まで）',
    freeFeature4: '✓ ビデオエクスポート',
    freeFeatures: '無料機能',
    fullBasic: 'マニュアルフィールドトラッキング',
    lite: '自動選手＆フィールドトラッキング',
    liteDescription:
      '高度なCodingと高度なビジュアライゼーション。全てが自動で、Full Basicよりも3倍速い！',
    minutesDescription:
      'どのプランでもチャージしてください。自動追跡データ用の分パックを入手してください。',
    minutesPack: 'クラウド分パック',
    otherDescription:
      '基本的なテレストレーションと限定されたアノテーションを備えた高度なcoding機能',
    prime: {
      addon1: 'マニュアルフィールドトラッキング',
      addon2: 'プリセットテレストレーション（カスタマイズして自分のビジュアライゼーションを保存）',
      feature1: '✓ "Full Essentials" プランの全機能を備えたPlay2への完全アクセス',
      feature2: '✓ Play3へのアクセス：',
      feature2_1: 'シーケンシャルポーズ',
      feature2_2: '高度なテレストレーション',
      feature2_3: 'カスタムビデオエクスポート',
      feature3: '✓ 10分のATDパック',
      primeDescription:
        'このプランには、"Essentials + Code ∞" プランの全機能と共にPlay2への完全アクセスが含まれ、高度なテレストレーションを備えたPlay3が利用可能になります。',
      primeMftDescription:
        'このプランには、"Full Essentials"プランの全機能と共にPlay2への完全アクセスが含まれ、高度なテレストレーションを備えたPlay3が利用可能になります。',
      primePresetsDescription:
        'このプランには、"Essentials + Code ∞" プランの全機能と共にPlay2への完全アクセスが含まれ、高度なテレストレーションとカスタムビジュアライゼーションを備えたPlay3が利用可能になります。',
      primePresetsMftDescription:
        'このプランには、"Full Essentials"プランの全機能と共にPlay2への完全アクセスが含まれ、高度なテレストレーションとカスタマイズされたビジュアライゼーションを備えたPlay3が利用可能になります。'
    },
    pro: {
      description:
        'このプランには、"Full Essentials"プランの全機能とPlay2への完全アクセス、及び"Full Prime"プランの全機能とPlay3へのアクセスが含まれています。',
      feature1: '✓ 自動選手追跡',
      feature2: '✓ 自動フィールドトラッキング',
      feature3: '✓ 「Full Essentials」プランの全機能を備えたPlay2への完全アクセス',
      feature4: '✓ Play3へのアクセス：',
      feature4_1: 'シーケンシャルポーズ',
      feature4_2: '高度なテレストレーション',
      feature4_3: 'カスタムビデオエクスポート',
      feature4_4:
        'プリセットテレストレーション（カスタマイズして自分のビジュアライゼーションを保存）',
      feature5: '✓ 100 ATD 分パック'
    },
    remove: '削除',
    starter: {
      addon1: 'ポーズ中の無制限テレストレーション',
      addon2: '無制限のCoding機能（CodePad、ライブCoding、XMLインポート）',
      feature1: '✓ 試合後のコーディング（最大4つのコードを含むCode Table）',
      feature2: '✓ 一時停止中のテレストレーション（月に最大50の注釈まで）',
      feature3: '✓ ビデオエクスポート',
      starterCodeDescription:
        'このプランは、CodePad、ライブCoding、XMLインポートなどのcoding機能の制限を解除します。また、ビデオプロジェクトごとのコードの制限を4つから無制限に解放します。Free Starterからの視覚化機能の制限はそのままです。',
      starterCodePauseDescription:
        'このプランは、CodePad、ライブコーディング、XMLインポートなどのコーディング機能の制限を解除します。また、ビデオプロジェクトごとのコードの制限を4つから無制限に解放し、月に50回の一時停止中のテレストレーションの制限を無制限に解除します。',
      starterPauseDescription:
        'このプランは、一時停止中のテレストレーションの制限を月50回から無制限に解除します。コーディング機能の制限はFree Starterのままです。'
    },
    totalPack: '合計: %{currency} %{cost}',
    totalPlan: '合計: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: '請求',
    billingSettings: '請求設定',
    billingSettingsDescription: '（プランを変更し、支払い方法を更新してください...）',
    cancelAt: 'あなたのプランがキャンセルされるのは',
    cancelPlan: 'プランをキャンセルする',
    confirmNewPassword: '新しいパスワードを確認',
    deleteAccount: 'アカウントを削除する',
    details: '私の詳細',
    emailNotActivated: 'メール通知が有効になりました',
    emailNotDeactivated: 'メール通知を停止しました',
    emailNotifications: 'メール通知',
    language: '言語',
    languageChanged: '言語が更新されました',
    newPassword: '新しいパスワード',
    organizationLabel: '組織の接頭辞',
    organizationTooltip:
      'この接頭辞は、カスタムパターンを Video Project にインポートする際に必要です。詳しくはお問い合わせいただくか、codeball.metrica-sports.com のドキュメントをご覧ください。',
    passwordsDontMatch: 'パスワードが一致しません',
    pauseAt: 'あなたのプランは次の日に一時停止します。',
    planCanceled: 'プランはキャンセルされました。詳細については、メールを確認してください。',
    plans: 'プラン',
    quantity: '量：',
    renewAt: 'プランの更新日',
    title: '設定',
    upgradePlan: 'アップグレードプラン',
    userKey: 'ユーザーキー:',
    userKeyUsages: '%{current}/%{max} の使用量',
    userSettings: 'ユーザー設定'
  },
  shoppingCart: {
    subtitle: 'あなたのパフォーマンスを向上させるために、Playプランとアドオンを選択してください。',
    title: 'あなたのプレイを設定してください'
  },
  subscription: {
    cancelSubscription: '登録をキャンセルする',
    errorUpdating:
      'あなたのサブスクリプションを更新中にエラーが発生しました。support@metrica-sports.comまでメールを送ってください。喜んでお手伝いします。',
    free: '無料',
    isObsolete:
      'あなたのサブスクリプションは変更できません。変更を有効にするために、古い製品を新しいものに置き換えてください。',
    licenseKeys: 'ライセンスキー',
    pendingRefundRequest:
      'このサブスクリプションに対して返金リクエストが保留中の間、これ以上の変更を加えることはできません。',
    undoCancel: 'サブスクリプションのキャンセルを取り消す',
    undoPause: 'サブスクリプションの一時停止を解除',
    updatePaymentMethod: '支払い方法を更新する',
    updatedSuccessfully: 'サブスクリプションが正常に更新されました。',
    yourPlans: 'あなたのプラン',
    yourSubscription: 'あなたのサブスクリプション'
  },
  successfulPayment: {
    activateLicence:
      '提供されたライセンスキーを入力して、ライセンスまたはCloudクレジットを有効にしてください。',
    allTheBest: 'すべてが最高でありますように,',
    applyKey: 'このアカウントにキーを適用する',
    applyKeyDescription: 'ログイン中のアカウントにライセンスキーを自動的に適用しますか？',
    downloadPlay: '%{pre}私たちのウェブサイトを通じてPLAY%{post}をダウンロードしてください。',
    faq: 'ご注文を開始するお手伝いをするために、当社のウェブサイトで%{pre}よくある質問%{post}をご覧ください。この段階であなたに最も関連性が高いかもしれない情報の簡単な要約をこちらに示します：',
    goToSummary:
      'それ以外の場合は、以下の要約を参照してアクティベーションの指示を確認してください。',
    happyPlay:
      'すべてが明確であることを願っていますが、さらに質問や疑問がある場合は、遠慮なくご連絡ください。ハッピープレイ！',
    helpDesk: '%{pre}HelpDesk%{post}をチェックして、PLAYの一般的な情報をご覧ください。',
    keyApplied: 'キーが適用されました',
    licenseKey: 'あなたのライセンスキーは：%{pre}%{licenseKey}%{post}です。',
    metricaSportsTeam: 'メトリカ・スポーツのチーム',
    settingsCloud: '%{pre}Cloud%{post}を訪問し、設定メニューに移動してください。',
    technicalSupport:
      '技術サポートが必要な場合は、PLAY/Cloudで利用可能なお問い合わせフォームに記入するか、%{pre}support@metrica-sports.com%{post}までメールを送信してください。',
    termsAndConditions:
      '「%{pre}Cloud%{post}の設定を通じて明示的にキャンセルされるまで、当社のサブスクリプションは自動的に更新されます。これは、当社の利用規約に従ったものです。」',
    thanks:
      'ご注文いただきありがとうございます！正常に処理され、以下にライセンスキーを添付しました。',
    title: '注文確認',
    updatePayment: '支払い方法を更新してください。',
    updateSubscription: 'あなたのサブスクリプションをキャンセルまたは変更してください。'
  },
  surfaces: {
    automaticVp: '古いATDバージョンのビデオプロジェクトではサーフェスはご\n利用頂けません',
    delete: 'サーフェスファイルの削除',
    needsAtd: '最初にATDプロセスを開始して、サーフェスプロセスを実行します',
    needsElite:
      'Eliteプランでは、カスタムサーフェスがご利用いただけます。詳しくはお問い合わせください。',
    needsFootballSideView:
      'サッカー以外の広角またはテレビ放送のビデオファイルを含むビデオプロジェクトではサーフェスはご利用頂けません。',
    pitchControl: 'このプロセスを実行することにより、PlayにPitch Controlの視覚化を追加できます。',
    pitchControlProcessed: 'Pitch Controlサーフェスが処理されました',
    pitchControlProcessing: 'Pitch Controlの処理中',
    processed: 'アップロードされたサーフェスファイル',
    processing: 'サーフェスファイルの処理',
    title: 'サーフェス',
    titlePitchControl: 'Pitch Control',
    upload: '独自のサーフェスをアップロードする',
    uploading: 'サーフェスをアップロードしています...'
  },
  trackingFifaFormat: {
    download: 'FIFA EPTSフォーマットファイルをダウンロードする',
    needsElite:
      'この機能は、データ統合モジュール、自動追跡データ付きビデオプロジェクト、またはEliteプランのビデオプロジェクトに対応するファイルをインポートした後にのみご利用頂けます。',
    processed: 'FIFA EPTSフォーマットが処理されました',
    start:
      'FIFA EPTS形式のファイルを生成するプロセスを開始します。その後、ファイルをダウンロードできるようになります',
    title: 'FIFA EPTSフォーマット',
    titleDownload: 'FIFA EPTSフォーマットをダウンロード'
  },
  upgradeModal: {
    linksDescription: '最適なCloudEngine時間料金パックを入手してください。',
    minutePackLinks: {
      minutes_10: '10分10€でご利用いただけます。',
      minutes_100: '100分90€でご利用いただけます。',
      minutes_1000: '1,000分750€でご利用いただけます。',
      minutes_10000: '10,000分5,000€でご利用いただけます。'
    },
    noMinutes: {
      description:
        'このビデオプロジェクトを作成するのに十分な時間がありません。どのプランでも時間料金パックを手に入れることができることをご存じですか？',
      secondDescription: '',
      title: 'ビデオプロジェクトを作成するためのより多くの時間を取得してください！'
    },
    notEnoughMinutes: {
      description: 'ビデオプロジェクトのATDプロセスに十分な時間がないことをお知らせします。',
      secondDescription:
        'ATDを処理する場合は、アカウントにさらに時間を追加する必要があります。得た時間に有効期限はありません！',
      title: '十分な時間がありません'
    }
  },
  video: {
    cancelUpload: 'アップロードをキャンセル',
    delete: 'ビデオを削除する',
    download: 'ビデオをダウンロード',
    title: 'ビデオファイル',
    upload: 'ビデオをアップロード',
    uploaded: 'アップロードされたビデオ',
    uploading: 'ビデオのアップロード'
  },
  videoProject: {
    delete: 'ビデオプロジェクトを削除する',
    edit: 'ビデオプロジェクトの編集',
    processWaitingJobs: '待機中のジョブを処理する',
    title: 'ビデオプロジェクト'
  }
}
