/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import atdLogo from '../../../../assets/atd-icon.svg'
import AutomatedTrackingDataJob from '../AutomatedTrackingDataJob'
import { theme, Status, GAMES_DATASET_DATA_TYPE } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logoImg: css`
    width: 18px;
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function shouldDisableItem(atdState, dataType) {
  if (atdState.deletingVp || dataType !== GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA) {
    return true
  }

  return false
}

function AutomatedTrackingDataMenuItem(props) {
  const startJob = props.processAutomatedTrackingData

  return (
    <AutomatedTrackingDataJob
      vp={props.vp}
      dataType={GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA}
      automatedTrackingDataJob={props.automatedTrackingDataJob}
      isVpCreatedInCloud={props.isVpCreatedInCloud}
      deletingVp={props.deletingVp}
      render={atdState => {
        const disabledItem = shouldDisableItem(atdState, props.vp.data_type)

        return (
          <div
            css={css`
              ${styles.itemContainer};
              pointer-events: ${disabledItem ? 'none' : 'all'};
            `}
          >
            <div css={styles.icon}>
              <span
                css={css`
                  ${styles.innerIcon};
                  opacity: ${disabledItem ? 0.35 : 1};
                  transform: rotate(270deg);
                `}
              >
                <img src={atdLogo} css={styles.logoImg} />
              </span>
            </div>
            <div css={styles.content} className="video-menu-item-content">
              <div
                css={css`
                  ${styles.name};
                  color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
                `}
              >
                {window.I18n.t('admin.automatedTrackingData')}
              </div>
              <div css={styles.icons}>{automatedTrackingDataJobUI(props, atdState, startJob)}</div>
            </div>
          </div>
        )
      }}
    />
  )
}

function automatedTrackingDataJobUI(props, atdState, startJob) {
  if (
    props.vp.data_type === GAMES_DATASET_DATA_TYPE.SYNCED ||
    props.vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED ||
    props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATIC
  ) {
    return null
  }

  if (
    atdState.hasAutomatedTrackingData ||
    props.vp.data_type === GAMES_DATASET_DATA_TYPE.ELITE ||
    (!props.vp.is_football_side_view &&
      props.trackingJob &&
      props.trackingJob.status === Status.COMPLETE)
  ) {
    return done()
  }

  if (!props.vp.files.video) {
    return null
  }

  if (props.isAnyJobRunning()) {
    return jobInProgress(props)
  }

  if (props.automatedTrackingDataJob) {
    const isAtd = props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA
    const automatedTrackingDataJob = isAtd && props.automatedTrackingDataJob
    const status = automatedTrackingDataJob && automatedTrackingDataJob.status

    switch (status) {
      case 'CHECKING':
      case Status.QUEUED:
      case Status.QUEUED_LOCALLY:
      case Status.STARTING:
      case Status.FINISHING:
      case Status.CONNECTIONFAILED:
      case Status.ACTIVE:
        return jobInProgress(props)
      case Status.COMPLETE:
        return done()
      case Status.FAILED:
        return errorUI(props, startJob)
      case Status.CANCEL:
        return startUI(props, startJob)
      default:
        return null
    }
  }

  if (atdState.showStartAtd && !props.isAnyJobRunning()) {
    return startUI(props, startJob)
  }

  if (atdState.errorTryAgain) {
    return errorUI(props, startJob)
  }

  return null
}

function jobInProgress(props) {
  return <RunningUi vp={props.vp} cancelProcesses={props.cancelProcesses} />
}

function done() {
  return (
    <React.Fragment>
      <Tooltip
        title={window.I18n.t('admin.automatedTrackingDataProcessedTooltip')}
        enterDelay={400}
        placement="top"
      >
        <Icon
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.common.successGreen};
          `}
        >
          check_circle_outline
        </Icon>
      </Tooltip>
    </React.Fragment>
  )
}

function errorUI(props, startJob) {
  const isEnabled = props.errorTrackingLinkAvailable && props.isProcessAtdAvailable
  if (props.isMetricaSportsUser) {
    return (
      <span
        css={css`
          ${styles.iconTag};
          ${styles.iconButton};
          color: ${theme.palette.error.main};
          font-size: 12px;
          text-decoration: underline;
          ${isEnabled ? '' : styles.disableIcon};
        `}
        onClick={() => startJob()}
      >
        {window.I18n.t('common.errorTryAgain')}
      </span>
    )
  }

  return (
    <span
      css={css`
        ${styles.iconTag};
        color: ${theme.palette.error.main};
        font-size: 12px;
        ${isEnabled ? '' : styles.disableIcon};
      `}
    >
      {window.I18n.t('admin.errorContactSupport')}
    </span>
  )
}

function startUI(props, startJob) {
  return (
    <span
      css={css`
        ${styles.iconTag};
        ${styles.iconButton};
        color: ${theme.palette.common.blue};
        font-size: 12px;
        text-decoration: underline;
        ${props.isProcessAtdAvailable ? '' : styles.disableIcon};
      `}
      // eslint-disable-next-line
      onClick={() => startJob()}
    >
      {window.I18n.t('common.start')}
    </span>
  )
}

class RunningUi extends React.PureComponent {
  state = {
    isHovered: false
  }

  setHover = isHovered => () => {
    this.setState({ isHovered })
  }

  cancelProcess = () => {
    confirmAlert()
      .then(() => {
        this.props.cancelProcesses(this.props.vp)()
      })
      .catch(() => {})
  }

  render() {
    return (
      <Tooltip title={window.I18n.t('admin.cancelProcessTooltip')} enterDelay={400} placement="top">
        {!this.state.isHovered ? (
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
            onMouseEnter={this.setHover(true)}
            onMouseLeave={this.setHover(false)}
          />
        ) : (
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onMouseLeave={this.setHover(false)}
            onClick={this.cancelProcess}
          >
            cancel
          </Icon>
        )}
      </Tooltip>
    )
  }
}

AutomatedTrackingDataMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  cancelProcesses: PropTypes.func.isRequired,
  trackingJob: PropTypes.object, // eslint-disable-line
  automatedTrackingDataJob: PropTypes.object, // eslint-disable-line
  errorTrackingLinkAvailable: PropTypes.bool.isRequired, // eslint-disable-line
  deletingVp: PropTypes.bool.isRequired,
  uploadingVideo: PropTypes.bool.isRequired, // eslint-disable-line
  processAutomatedTrackingData: PropTypes.func.isRequired, // eslint-disable-line
  isAnyJobRunning: PropTypes.func.isRequired,
  downloadAtdFile: PropTypes.func.isRequired, // eslint-disable-line
  isProcessAtdAvailable: PropTypes.bool.isRequired // eslint-disable-line
}

export default AutomatedTrackingDataMenuItem
