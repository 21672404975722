/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { theme, Status, JOB_TYPES } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-top: 1px solid ${grey};
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function DataIntegrationMenuItem(props) {
  const hasLicence = props.hasLicence
  const isRunning = props.isDataIntegrationRunning
  const isWaiting = props.isDataIntegrationWaiting
  const isDataIntegrationJobAvailable = props.isDataIntegrationJobAvailable
  const isComplete =
    props.dataIntegrationJobRow && props.dataIntegrationJobRow.status === Status.COMPLETE
  const error = props.dataIntegrationJobRow && props.dataIntegrationJobRow.status === Status.FAILED
  const dataIntegrationType =
    props.dataIntegrationJobRow &&
    props.dataIntegrationJobRow.payload &&
    props.dataIntegrationJobRow.payload.provider
  let disabledItem = false
  if (!props.isVpCreatedInCloud || !hasLicence || props.vp.forked_from) {
    disabledItem = true
  } else {
    disabledItem = !props.isDataIntegrationJobAvailable
  }

  // If it doesn't have licence, show the tooltip if it's waiting.
  // Otherwise, show it just if tracking or homography are missing.
  const showWrapperTooltip = hasLicence
    ? !props.vp.files[JOB_TYPES.TRACKING.file] ||
      !JOB_TYPES.HOMOGRAPHY.files.some(v => props.vp.files[v])
    : isWaiting || disabledItem

  const tooltipText =
    (!props.vp.is_football_side_view && window.I18n.t('dataIntegration.notSideView')) ||
    (isWaiting && window.I18n.t('dataIntegration.waitingProcess')) ||
    (hasLicence &&
      !isDataIntegrationJobAvailable &&
      window.I18n.t('dataIntegration.processJobsFirst')) ||
    (disabledItem && window.I18n.t('dataIntegration.featureNotAvailable'))

  return (
    <Tooltip
      title={tooltipText}
      enterDelay={200}
      placement="top"
      {...(showWrapperTooltip && !!tooltipText ? {} : { open: false })}
    >
      <div>
        <div
          css={css`
            ${styles.itemContainer};
            pointer-events: ${disabledItem ? 'none' : 'all'};
          `}
        >
          <div css={styles.icon} />
          <div css={styles.content} className="video-menu-item-content">
            <div
              css={css`
                ${styles.name};
                color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
              `}
            >
              {window.I18n.t('dataIntegration.title')}
            </div>
            <div css={styles.icons}>
              {hasLicence &&
                dataIntegrationMenuItemsUI(
                  isRunning,
                  isComplete,
                  error,
                  isDataIntegrationJobAvailable,
                  props.openDataIntegration,
                  props.vp._id,
                  props.cancelDataIntegration,
                  props.startDataIntegrationJob,
                  props.isVpCreatedInCloud,
                  props.isMetricaSportsUser,
                  dataIntegrationType,
                  isWaiting
                )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

function dataIntegrationMenuItemsUI(
  isRunning,
  isComplete,
  error,
  isDataIntegrationJobAvailable,
  openDataIntegration,
  vpId,
  cancelDataIntegration,
  startDataIntegrationJob,
  isVpCreatedInCloud,
  isMetricaSportsUser,
  dataIntegrationType,
  isWaiting
) {
  if (error) {
    if (isMetricaSportsUser) {
      return (
        <React.Fragment>
          {uploadUI(openDataIntegration, isDataIntegrationJobAvailable, isVpCreatedInCloud)}
          <span
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
              font-size: 12px;
              text-decoration: underline;
              ${isDataIntegrationJobAvailable ? '' : styles.disableIcon};
            `}
            // eslint-disable-next-line
            onClick={() => startDataIntegrationJob(dataIntegrationType)}
          >
            {window.I18n.t('common.errorTryAgain')}
          </span>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {uploadUI(openDataIntegration, isDataIntegrationJobAvailable, isVpCreatedInCloud)}
        <span
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.error.main};
            font-size: 12px;
            ${isDataIntegrationJobAvailable ? '' : styles.disableIcon};
          `}
        >
          {window.I18n.t('admin.errorContactSupport')}
        </span>
      </React.Fragment>
    )
  }

  if (isWaiting) {
    return (
      <React.Fragment>
        <span
          css={css`
            ${styles.iconTag};
            ${styles.iconButton};
            color: ${theme.palette.common.grey};
            font-size: 10px;
            ${isDataIntegrationJobAvailable ? '' : styles.disableIcon};
          `}
          // eslint-disable-next-line
        >
          {window.I18n.t('dataIntegration.waiting')}
        </span>
      </React.Fragment>
    )
  }

  if (isRunning) {
    return (
      <RunningUi
        vpId={vpId}
        cancelDataIntegration={cancelDataIntegration}
        dataIntegrationType={dataIntegrationType}
      />
    )
  }

  if (isComplete) {
    return (
      <React.Fragment>
        {uploadUI(openDataIntegration, isDataIntegrationJobAvailable, isVpCreatedInCloud)}
        {/* We are not reprocessing tracab for now */}
        {/* <Tooltip
          title      = {'Reprocess'}
          enterDelay = {400}
          placement  = "top"
        >
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.secondary.main};
              ${isDataIntegrationJobAvailable && hasTracabFiles ? '' : styles.disableIcon};
            `}
            // eslint-disable-next-line
            onClick={() => startDataIntegrationJob(dataIntegrationType)}
          >
            loop
          </Icon>
        </Tooltip> */}
        <Tooltip
          title={window.I18n.t('dataIntegration.processed')}
          enterDelay={400}
          placement="top"
        >
          <Icon
            css={css`
              ${styles.iconTag};
              color: ${theme.palette.common.successGreen};
            `}
          >
            check_circle_outline
          </Icon>
        </Tooltip>
      </React.Fragment>
    )
  }

  return uploadUI(openDataIntegration, isDataIntegrationJobAvailable, isVpCreatedInCloud)
}

function uploadUI(openDataIntegration, isDataIntegrationJobAvailable, isVpCreatedInCloud) {
  return (
    <Tooltip title={window.I18n.t('dataIntegration.open')} enterDelay={400} placement="top">
      <Icon
        css={css`
          ${styles.iconTag};
          ${styles.iconButton};
          color: ${theme.palette.secondary.main};
          ${isVpCreatedInCloud && isDataIntegrationJobAvailable ? '' : styles.disableIcon};
        `}
        onClick={openDataIntegration}
      >
        cloud_upload
      </Icon>
    </Tooltip>
  )
}

class RunningUi extends React.PureComponent {
  state = {
    isHovered: false
  }

  setHover = isHovered => () => {
    this.setState({ isHovered })
  }

  cancelProcess = () => {
    confirmAlert()
      .then(() => {
        this.props.cancelDataIntegration(this.props.vpId, this.props.dataIntegrationType)
      })
      .catch(() => {})
  }

  render() {
    return (
      <Tooltip title={window.I18n.t('admin.cancelProcessTooltip')} enterDelay={400} placement="top">
        {!this.state.isHovered ? (
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
            onMouseEnter={this.setHover(true)}
            onMouseLeave={this.setHover(false)}
          />
        ) : (
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onMouseLeave={this.setHover(false)}
            onClick={this.cancelProcess}
          >
            cancel
          </Icon>
        )}
      </Tooltip>
    )
  }
}

DataIntegrationMenuItem.defaultProps = {
  dataIntegrationJobRow: null,
  isDataIntegrationWaiting: false
}

DataIntegrationMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  openDataIntegration: PropTypes.func.isRequired,
  dataIntegrationJobRow: PropTypes.object,
  isDataIntegrationJobAvailable: PropTypes.bool.isRequired,
  isDataIntegrationRunning: PropTypes.bool.isRequired,
  isDataIntegrationWaiting: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  startDataIntegrationJob: PropTypes.func.isRequired,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  cancelDataIntegration: PropTypes.func.isRequired,
  hasLicence: PropTypes.bool.isRequired
}

export default DataIntegrationMenuItem
