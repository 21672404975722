/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { theme } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    cursor: default;
    outline: 0;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 15px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function deleteVideo(props) {
  confirmAlert()
    .then(() => {
      props.deleteVideo(props.vp)()
    })
    .catch(() => {})
}

function VideoMenuItem(props) {
  const disabledItem = !props.isVpCreatedInCloud && !props.isDownloadVideoAvailable
  const isDeleteVideoAvailable = props.isVpCreatedInCloud && props.isDeleteVideoAvailable
  const isDownloadVideoAvailable = props.isDownloadVideoAvailable

  return (
    <div
      css={css`
        ${styles.itemContainer};
        pointer-events: ${disabledItem ? 'none' : 'all'};
      `}
    >
      <div css={styles.icon} />
      <div css={styles.content} className="video-menu-item-content">
        <div
          css={css`
            ${styles.name};
            color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
          `}
        >
          {window.I18n.t('video.title')}
        </div>
        <div css={styles.icons}>
          {/* Delete video */}
          <Tooltip title={window.I18n.t('video.delete')} enterDelay={400} placement="top">
            <Icon
              css={css`
                ${styles.iconTag};
                ${styles.iconButton};
                color: ${theme.palette.error.main};
                ${isDeleteVideoAvailable ? '' : styles.disableIcon};
              `}
              onClick={() => deleteVideo(props)}
            >
              delete
            </Icon>
          </Tooltip>

          {/* Donwload video */}
          <Tooltip title={window.I18n.t('video.download')} enterDelay={400} placement="top">
            <Icon
              css={css`
                ${styles.iconTag};
                ${styles.iconButton};
                color: ${theme.palette.secondary.main};
                ${isDownloadVideoAvailable ? '' : styles.disableIcon};
              `}
              onClick={props.downloadVideo(props.vp._id, props.vp.files.video)}
            >
              cloud_download
            </Icon>
          </Tooltip>

          {/* Cancel uploading video */}
          {props.isCancelVideoUploadAvailable && (
            <Tooltip title={window.I18n.t('video.cancelUpload')} enterDelay={400} placement="top">
              <Icon
                css={css`
                  ${styles.iconTag};
                  ${styles.iconButton};
                  color: ${theme.palette.error.main};
                `}
                onClick={props.cancelVideoUpload}
              >
                highlight_off
              </Icon>
            </Tooltip>
          )}

          {/* Uploading video */}
          {props.isCancelVideoUploadAvailable && (
            <Tooltip title={window.I18n.t('video.uploading')} enterDelay={400} placement="top">
              <CircularProgress
                css={css`
                  ${styles.iconTag};
                `}
                size={14}
                thickness={4}
                color="primary"
              />
            </Tooltip>
          )}

          {/* Upload video */}
          {props.isVpCreatedInCloud && props.isUploadVideoAvailable && (
            <Tooltip title={window.I18n.t('video.upload')} enterDelay={400} placement="top">
              <Icon
                css={css`
                  ${styles.iconTag};
                  ${styles.iconButton};
                  color: ${theme.palette.secondary.main};
                `}
                onClick={props.uploadVideo}
              >
                cloud_upload
              </Icon>
            </Tooltip>
          )}

          {/* Video uploaded */}
          {props.isDownloadVideoAvailable && (
            <Tooltip title={window.I18n.t('video.uploaded')} enterDelay={400} placement="top">
              <Icon
                css={css`
                  ${styles.iconTag};
                  color: ${theme.palette.common.successGreen};
                `}
              >
                check_circle_outline
              </Icon>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

VideoMenuItem.propTypes = {
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  vp: PropTypes.object.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  isUploadVideoAvailable: PropTypes.bool.isRequired,
  cancelVideoUpload: PropTypes.func.isRequired,
  isCancelVideoUploadAvailable: PropTypes.bool.isRequired,
  downloadVideo: PropTypes.func.isRequired,
  isDownloadVideoAvailable: PropTypes.bool.isRequired,
  deleteVideo: PropTypes.func.isRequired, // eslint-disable-line
  isDeleteVideoAvailable: PropTypes.bool.isRequired
}

export default VideoMenuItem
