/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { theme, Status, JOB_TYPES } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.85);
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function TrackingFifaFormatMenuItem(props) {
  const disabledItem = props.isTrackingFifaFormatAvailable
    ? false
    : !props.vp.files[JOB_TYPES.TRACKING_FIFA_FORMAT.file]
  const isRunning = props.isTrackingFifaFormatRunning
  const isComplete = props.trackingFifaFormatFileUploaded
  const error = props.trackingFifaFormatJob && props.trackingFifaFormatJob.status === Status.FAILED

  return (
    <Tooltip
      title={window.I18n.t('trackingFifaFormat.needsElite')}
      enterDelay={200}
      placement="top"
      {...(disabledItem ? {} : { open: false })}
    >
      <div>
        <div
          css={css`
            ${styles.itemContainer};
            pointer-events: ${disabledItem ? 'none' : 'all'};
          `}
        >
          <div css={styles.icon} />
          <div css={styles.content} className="video-menu-item-content">
            <TitleLabel isComplete={isComplete} disabledItem={disabledItem} />
            <div css={styles.icons}>
              {trackingFifaFormatMenuItemsUI(
                isRunning,
                isComplete,
                error,
                disabledItem,
                props.startTrackingFifaFormatJob,
                props.downloadFifaFormatFile,
                props.vp._id,
                props.cancelTrackingFifaFormat,
                props.isMetricaSportsUser
              )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

function trackingFifaFormatMenuItemsUI(
  isRunning,
  isComplete,
  error,
  disabledItem,
  startTrackingFifaFormatJob,
  downloadFifaFormatFile,
  vpId,
  cancelTrackingFifaFormat,
  isMetricaSportsUser
) {
  if (error) {
    if (isMetricaSportsUser) {
      return (
        <span
          css={css`
            ${styles.iconTag};
            ${styles.iconButton};
            color: ${theme.palette.error.main};
            font-size: 12px;
            text-decoration: underline;
            ${disabledItem ? styles.disableIcon : ''};
          `}
          onClick={() => startTrackingFifaFormatJob()}
        >
          {window.I18n.t('common.errorTryAgain')}
        </span>
      )
    }

    return (
      <span
        css={css`
          ${styles.iconTag};
          color: ${theme.palette.error.main};
          font-size: 12px;
          ${disabledItem ? styles.disableIcon : ''};
        `}
      >
        {window.I18n.t('admin.errorContactSupport')}
      </span>
    )
  }

  if (isRunning) {
    return <RunningUi vpId={vpId} cancelTrackingFifaFormat={cancelTrackingFifaFormat} />
  }

  if (isComplete) {
    return (
      <React.Fragment>
        <Tooltip
          title={window.I18n.t('trackingFifaFormat.download')}
          enterDelay={400}
          placement="top"
        >
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.secondary.main};
              ${disabledItem ? styles.disableIcon : ''};
            `}
            onClick={() => downloadFifaFormatFile()}
          >
            cloud_download
          </Icon>
        </Tooltip>
        <Tooltip
          title={window.I18n.t('trackingFifaFormat.processed')}
          enterDelay={400}
          placement="top"
        >
          <Icon
            css={css`
              ${styles.iconTag};
              color: ${theme.palette.common.successGreen};
            `}
          >
            check_circle_outline
          </Icon>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <Tooltip title={window.I18n.t('trackingFifaFormat.start')} enterDelay={200} placement="top">
      <span
        css={css`
          ${styles.iconTag};
          ${styles.iconButton};
          color: ${theme.palette.common.blue};
          font-size: 12px;
          text-decoration: underline;
          ${disabledItem ? styles.disableIcon : ''};
        `}
        onClick={() => startTrackingFifaFormatJob()}
      >
        {window.I18n.t('common.start')}
      </span>
    </Tooltip>
  )
}

class RunningUi extends React.PureComponent {
  state = {
    isHovered: false
  }

  setHover = isHovered => () => {
    this.setState({ isHovered })
  }

  cancelProcess = () => {
    confirmAlert()
      .then(() => {
        this.props.cancelTrackingFifaFormat(this.props.vpId)
      })
      .catch(() => {})
  }

  render() {
    return (
      <Tooltip title={window.I18n.t('admin.cancelProcessTooltip')} enterDelay={300} placement="top">
        {!this.state.isHovered ? (
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
            onMouseEnter={this.setHover(true)}
            onMouseLeave={this.setHover(false)}
          />
        ) : (
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onMouseLeave={this.setHover(false)}
            onClick={this.cancelProcess}
          >
            cancel
          </Icon>
        )}
      </Tooltip>
    )
  }
}

// eslint-disable-next-line react/prop-types
function TitleLabel({ disabledItem, isComplete }) {
  return (
    <div
      css={css`
        ${styles.name};
        color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
      `}
    >
      {window.I18n.t(isComplete ? 'trackingFifaFormat.titleDownload' : 'trackingFifaFormat.title')}
    </div>
  )
}

TrackingFifaFormatMenuItem.defaultProps = {
  trackingFifaFormatJob: null
}

TrackingFifaFormatMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  isTrackingFifaFormatAvailable: PropTypes.bool.isRequired,
  isTrackingFifaFormatRunning: PropTypes.bool.isRequired,
  trackingFifaFormatFileUploaded: PropTypes.bool.isRequired,
  trackingFifaFormatJob: PropTypes.object,
  startTrackingFifaFormatJob: PropTypes.func.isRequired,
  downloadFifaFormatFile: PropTypes.func.isRequired,
  cancelTrackingFifaFormat: PropTypes.func.isRequired
}

export default TrackingFifaFormatMenuItem
