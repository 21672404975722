/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import correctionLogo from '../../../../assets/correction-icon.svg'
import AutomatedTrackingDataJob from '../AutomatedTrackingDataJob'
import { theme, Status, GAMES_DATASET_DATA_TYPE, JOB_TYPES } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'
import gamesService from '../../../../services/games'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logoImg: css`
    width: 18px;
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function shouldDisableItem(atdState, atdJob, dataType) {
  return atdState.deletingVp || dataType !== GAMES_DATASET_DATA_TYPE.CORRECTED
}

function CorrectionMenuItem(props) {
  const startJob = props.processAutomatedTrackingData

  return (
    <AutomatedTrackingDataJob
      vp={props.vp}
      dataType={GAMES_DATASET_DATA_TYPE.CORRECTED}
      automatedTrackingDataJob={props.automatedTrackingDataJob}
      trackingFifaFormatJob={props.trackingFifaFormatJob}
      isVpCreatedInCloud={props.isVpCreatedInCloud}
      deletingVp={props.deletingVp}
      render={atdState => {
        const disabledItem = shouldDisableItem(
          atdState,
          props.automatedTrackingDataJob,
          props.vp.data_type
        )

        return (
          <div css={styles.itemContainer}>
            <div css={styles.icon}>
              <span
                css={css`
                  ${styles.innerIcon};
                  opacity: ${disabledItem ? 0.35 : 1};
                `}
              >
                <img src={correctionLogo} css={styles.logoImg} />
              </span>
            </div>
            <div css={styles.content} className="video-menu-item-content">
              <div
                css={css`
                  ${styles.name};
                  color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
                `}
              >
                {window.I18n.t('admin.correctedTracking')}
                <Tooltip
                  title={window.I18n.t('admin.correctionUsageTooltip')}
                  enterDelay={200}
                  placement="left"
                >
                  <Icon
                    style={{
                      color: 'grey',
                      fontSize: '18px',
                      marginLeft: '10px',
                      top: '6px',
                      left: '5px',
                      transform: 'rotate(5deg)'
                    }}
                  >
                    info
                  </Icon>
                </Tooltip>
              </div>
              <div css={styles.icons}>{correctionJobUI(props, atdState, startJob)}</div>
            </div>
          </div>
        )
      }}
    />
  )
}

function correctionJobUI(props, atdState, startJob) {
  if (props.vp.data_type !== GAMES_DATASET_DATA_TYPE.CORRECTED) {
    return null
  }

  if (!props.vp.files.video) {
    return null
  }

  if (props.vp.is_corrected && props.isAnyJobRunning()) {
    return jobInProgress(props)
  }

  if (props.trackingFifaFormatJob) {
    const status = props.trackingFifaFormatJob.status

    switch (status) {
      case 'CHECKING':
      case Status.QUEUED:
      case Status.QUEUED_LOCALLY:
      case Status.STARTING:
      case Status.FINISHING:
      case Status.CONNECTIONFAILED:
      case Status.ACTIVE:
        return jobInProgress(props)
      case Status.COMPLETE:
        return done()
      case Status.FAILED:
        return errorUI(props, startJob)
      case Status.CANCEL:
        return startUI(props, startJob)
      default:
        return null
    }
  }

  if (props.vp.is_corrected && props.vp.files[JOB_TYPES.TRACKING_FIFA_FORMAT.file]) {
    return done()
  }

  if (atdState.showStartAtd && !props.isAnyJobRunning()) {
    return startUI(props, startJob)
  }

  if (atdState.errorTryAgain) {
    return errorUI(props, startJob)
  }

  return null
}

function jobInProgress(props) {
  return <RunningUi vp={props.vp} cancelProcesses={props.cancelProcesses} />
}

function done() {
  return (
    <React.Fragment>
      <Tooltip
        title={window.I18n.t('admin.trackingCorrectionProcessedTooltip')}
        enterDelay={400}
        placement="top"
      >
        <Icon
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.common.successGreen};
          `}
        >
          check_circle_outline
        </Icon>
      </Tooltip>
    </React.Fragment>
  )
}

function errorUI(props, startJob) {
  const isEnabled = props.errorTrackingLinkAvailable && props.isProcessCorrectionAvailable
  if (props.isMetricaSportsUser) {
    return (
      <span
        css={css`
          ${styles.iconTag};
          ${styles.iconButton};
          color: ${theme.palette.error.main};
          font-size: 12px;
          text-decoration: underline;
          ${isEnabled ? '' : styles.disableIcon};
        `}
        onClick={() => startJob()}
      >
        {window.I18n.t('common.errorTryAgain')}
      </span>
    )
  }

  return (
    <span
      css={css`
        ${styles.iconTag};
        color: ${theme.palette.error.main};
        font-size: 12px;
        ${isEnabled ? '' : styles.disableIcon};
      `}
    >
      {window.I18n.t('admin.errorContactSupport')}
    </span>
  )
}

function startUI(props, startJob) {
  return (
    <span
      css={css`
        ${styles.iconTag};
        ${styles.iconButton};
        color: ${theme.palette.common.blue};
        font-size: 12px;
        text-decoration: underline;
        ${props.isProcessCorrectionAvailable ? '' : styles.disableIcon};
      `}
      // eslint-disable-next-line
      onClick={() => {
        confirmAlert()
          .then(async () => {
            await gamesService.markAsCorrected(props.vp._id)
            startJob()
          })
          .catch(() => {})
      }}
    >
      {window.I18n.t('common.start')}
    </span>
  )
}

class RunningUi extends React.PureComponent {
  state = {
    isHovered: false
  }

  setHover = isHovered => () => {
    this.setState({ isHovered })
  }

  cancelProcess = () => {
    confirmAlert()
      .then(() => {
        this.props.cancelProcesses(this.props.vp)()
      })
      .catch(() => {})
  }

  render() {
    return (
      <Tooltip title={window.I18n.t('admin.cancelProcessTooltip')} enterDelay={400} placement="top">
        {!this.state.isHovered ? (
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
            onMouseEnter={this.setHover(true)}
            onMouseLeave={this.setHover(false)}
          />
        ) : (
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onMouseLeave={this.setHover(false)}
            onClick={this.cancelProcess}
          >
            cancel
          </Icon>
        )}
      </Tooltip>
    )
  }
}

CorrectionMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  cancelProcesses: PropTypes.func.isRequired,
  trackingJob: PropTypes.object, // eslint-disable-line
  automatedTrackingDataJob: PropTypes.object, // eslint-disable-line
  trackingFifaFormatJob: PropTypes.object, // eslint-disable-line
  errorTrackingLinkAvailable: PropTypes.bool.isRequired, // eslint-disable-line
  deletingVp: PropTypes.bool.isRequired,
  uploadingVideo: PropTypes.bool.isRequired, // eslint-disable-line
  processAutomatedTrackingData: PropTypes.func.isRequired, // eslint-disable-line
  isAnyJobRunning: PropTypes.func.isRequired,
  downloadAtdFile: PropTypes.func.isRequired, // eslint-disable-line
  isProcessCorrectionAvailable: PropTypes.bool.isRequired // eslint-disable-line
}

export default CorrectionMenuItem
