import { combineReducers } from 'redux'
import auth from './auth'
import admin from './admin'
import notifications from './notifications'

const rootReducer = combineReducers({
  auth,
  admin,
  notifications
})

export default rootReducer
