/** @jsx jsx */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import memoize from 'lodash/memoize'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import { fetchModelsParams } from '../../constants'
import modelsService from '../../services/models'

const renderInputKeyDown = toggleButtonProps => e => {
  // this prevents the menu from being open or closed when the user
  // press space with the keyboard and add the sapce to the input string
  if (e.defaultPrevented || e.keyCode === 32) {
    return
  }

  toggleButtonProps().onKeyDown(e)
}

function renderInput(getToggleButtonProps, showSelect, inputProps) {
  const { InputProps, classes, ref, isOpen, ...other } = inputProps

  return (
    <div
      {...getToggleButtonProps()}
      className={classes.relative}
      onKeyDown={renderInputKeyDown(getToggleButtonProps)}
    >
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        {...other}
      />
      {showSelect && (
        <Icon classes={{ root: isOpen ? classes.iconSelectBaseRotated : classes.iconSelectBase }}>
          arrow_drop_down
        </Icon>
      )}
    </div>
  )
}

const renderSuggestionStyles = suggestionValue => ({
  pointerEvents: suggestionValue ? 'all' : 'none',
  paddingLeft: 9,
  paddingRight: 9,
  fontSize: 14.5
})
const renderSuggestionStylesMemo = memoize(renderSuggestionStyles)

// eslint-disable-next-line
function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, query, selectedItem }) {
  const matches = match(suggestion.label, query)
  const parts = parse(suggestion.label, matches)
  const isHighlighted = highlightedIndex === index

  const isSelected = selectedItem.find(({ label }) => label === suggestion.label)

  return (
    <MenuItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
      key={suggestion.value}
      style={renderSuggestionStylesMemo(suggestion.value)}
    >
      <div>
        {parts.map((part, idx) =>
          part.highlight ? (
            <span
              key={String(idx)}
              css={css`
                font-weight: 500;
              `}
            >
              {part.text}
            </span>
          ) : (
            <strong
              key={String(idx)}
              css={css`
                font-weight: ${isSelected ? 500 : 300};
              `}
            >
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  )
}

/*eslint-disable */
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}
/* eslint-enable */

function getSuggestions(value, suggestions) {
  const val = value
  const inputValue = deburr(val.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0
  const suggestionsFiltered = suggestions.filter(suggestion => {
    const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue
    if (keep) {
      count += 1
    }
    return keep
  })

  if (inputLength === 0) {
    return suggestions
  } else if (!suggestionsFiltered.length) {
    return [{ label: window.I18n.t('admin.noMatches'), value: null }]
  }

  return suggestionsFiltered
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    bottom: 42,
    maxHeight: 255,
    scrollbarWidth: 'none',
    overflowY: 'overlay',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    flexGrow: 1
  },
  iconSelectBase: {
    position: 'absolute',
    right: -5,
    fontSize: 24,
    top: 36,
    color: 'rgba(0, 0, 0, 0.54)',
    transition: 'all .2s ease'
  },
  iconSelectBaseRotated: {
    position: 'absolute',
    right: -5,
    fontSize: 24,
    top: 36,
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(180deg)',
    transition: 'all .2s ease'
  },
  relative: {
    position: 'relative'
  }
})

const itemToString = i => (i ? i.label : '')

function Multiselect({
  classes,
  onChange,
  placeholder,
  name,
  onBlur,
  label,
  error,
  errorText,
  suggestions,
  fetchSuggestions,
  ...other
}) {
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState([])
  const [fetchedSuggestions, setFetchedSuggestions] = React.useState([])

  useEffect(() => {
    if (other.initialSelected) {
      setSelectedItem(other.initialSelected)
    }
  }, [other.initialSelected])

  function handleKeyDown(event) {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setNewSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }

  async function handleInputChange(event) {
    setInputValue(event.target.value)
    if (!fetchSuggestions || !inputValue) {
      return
    }
    const params = { ...fetchModelsParams, select: 'id name' }
    const {
      data: { docs: organizations }
    } = await modelsService.searchOrganizations(inputValue, params)
    const organizationOptions = organizations.map(organization => ({
      label: organization.name,
      value: organization._id
    }))
    setFetchedSuggestions(organizationOptions)
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem]
    if (item && newSelectedItem.map(({ value }) => value).indexOf(item.value) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setNewSelectedItem(newSelectedItem)
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setNewSelectedItem(newSelectedItem)
  }

  function setNewSelectedItem(item) {
    setSelectedItem(item)
    onChange(item)
  }

  const showSelect = !!fetchedSuggestions.length || !!suggestions.length

  return (
    <div className={classes.root}>
      <Downshift
        {...other}
        itemToString={itemToString}
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          isOpen,
          getToggleButtonProps
        }) => (
          <div className={classes.container}>
            {renderInput(getToggleButtonProps, showSelect, {
              fullWidth: true,
              classes,
              isOpen,
              InputProps: getInputProps({
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item.value}
                    tabIndex={-1}
                    label={item.label}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onChange: handleInputChange,
                onKeyDown: handleKeyDown,
                placeholder,
                name,
                onBlur
              }),
              label,
              error,
              helperText: errorText,
              margin: 'normal'
            })}
            <div {...getMenuProps()}>
              {isOpen && showSelect ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(
                    inputValue2,
                    fetchSuggestions ? fetchedSuggestions : suggestions
                  ).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      query: inputValue,
                      selectedItem: selectedItem2
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}

Multiselect.defaultProps = {
  placeholder: '',
  onBlur: () => ({}),
  onChange: () => ({}),
  name: '',
  label: '',
  errorText: '',
  error: false
}

Multiselect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  suggestions: PropTypes.array.isRequired
}

export default withStyles(styles)(Multiselect)
