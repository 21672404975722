/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import { Route, Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import tokenService from '../../../services/token'
import DynamicImport from '../DynamicImport'

const styles = {}
styles.progressWrapper = css`
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

function UnauthorizedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (tokenService.get()) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        }
        if (rest.path === '/login') {
          return (
            <DynamicImport load={() => import('../../login')}>
              {DynamicComponent =>
                DynamicComponent === null ? (
                  <div css={styles.progressWrapper}>
                    <CircularProgress color="primary" size={45} thickness={4} />
                  </div>
                ) : (
                  <DynamicComponent {...props} />
                )
              }
            </DynamicImport>
          )
        }
        return <Component {...props} />
      }}
    />
  )
}

export default UnauthorizedRoute
