/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import Plan from './Plan'
import PlanStripe from './PlanStripe'

const styles = {}
styles.container = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
`

function Plans(props) {
  const [products, setProducts] = React.useState([])

  React.useEffect(() => {
    setProducts([...props.monthlyProducts, ...props.yearlyProducts])
  }, [props.monthlyProducts, props.yearlyProducts])

  const showStripeSubscriptions =
    props.subscriptionInUse &&
    props.subscriptionInUse.order &&
    !props.subscriptionInUse.order.merchant

  return (
    <div css={styles.container}>
      {
        // Stripe subscriptions.
        showStripeSubscriptions &&
          props.subscriptions.map(subscription => (
            <PlanStripe key={subscription.order._id} subscription={subscription} />
          ))
      }

      {
        // Free subscription.
        props.subscriptions.length === 0 && (
          <Plan
            paddle={props.paddle}
            products={products}
            monthlyProducts={props.monthlyProducts}
            yearlyProducts={props.yearlyProducts}
            subscription={{}}
            userIp={props.userIp}
            user={props.user}
            customer={props.customer}
            address={props.address}
          />
        )
      }

      {
        // Paddle subscriptions.
        !showStripeSubscriptions &&
          props.subscriptions.map(subscription => {
            // Don't show Stripe subscriptions.
            if (subscription.order && !subscription.order.merchant) {
              return null
            }

            return (
              <Plan
                key={subscription.order ? subscription.order._id : subscription.planName}
                paddle={props.paddle}
                products={products}
                monthlyProducts={props.monthlyProducts}
                yearlyProducts={props.yearlyProducts}
                subscription={subscription}
                userIp={props.userIp}
                user={props.user}
                customer={props.customer}
                address={props.address}
              />
            )
          })
      }
    </div>
  )
}

Plans.defaultProps = {
  paddle: undefined,
  subscriptionInUse: undefined,
  customer: undefined,
  address: undefined
}

Plans.propTypes = {
  paddle: PropTypes.object,
  userIp: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  subscriptionInUse: PropTypes.object,
  subscriptions: PropTypes.array.isRequired,
  customer: PropTypes.object,
  address: PropTypes.object,
  monthlyProducts: PropTypes.array.isRequired,
  yearlyProducts: PropTypes.array.isRequired
}

export default Plans
