import api from './api'

const orderService = {}

orderService.getPricePreview = (id, items) => api.patch(`/orders/${id}/price-preview`, { items })

orderService.getInvoiceByTransactionId = id => api.get(`/orders/${id}/transaction/invoice`)

orderService.getOrderByTransactionId = id => api.get(`/orders/${id}/transaction`)

orderService.getOrderById = id => api.get(`/orders/${id}/subscription`)

orderService.updateSubscription = (id, items) => api.patch(`/orders/${id}`, { items })

orderService.chargeSubscription = (id, items) => api.post(`/orders/${id}/charge`, { items })

orderService.updatePaymentMethodSubscription = id =>
  api.get(`/orders/${id}/subscription/update-payment-method`)

orderService.undoSchedulesSubscription = id => api.post(`/orders/${id}/subscription/undo-schedules`)

orderService.cancelSubscription = id => api.post(`/orders/${id}/subscription/cancel`)

export default orderService
