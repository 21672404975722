import React from 'react'
import PropTypes from 'prop-types'
import { Status, GAMES_DATASET_DATA_TYPE, JOB_TYPES } from '../../../constants'

const initialState = {
  deletingVp: false,
  disableActions: false,
  hasEventsDetection: false,
  hasEventsDetectionJob: false,
  showStartEventsDetection: false,
  isOnHold: false,
  errorTryAgain: false
}

function EventsDetectionJob(props) {
  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    const { vp, deletingVp } = props
    const hasEventsDetection = vp.compute_events && vp.files[JOB_TYPES.EVENTS_DETECTION.file]
    const hasEventsDetectionJob = props.eventsDetectionJob
    const errorTryAgain = hasEventsDetectionJob && props.eventsDetectionJob.status === Status.FAILED
    const showStartEventsDetection =
      vp.files.video &&
      !vp.is_playlist &&
      vp.is_football_side_view &&
      !hasEventsDetection &&
      props.isVpCreatedInCloud &&
      vp.data_type !== GAMES_DATASET_DATA_TYPE.AUTOMATIC

    if (deletingVp) {
      setState({
        ...initialState,
        deletingVp: true
      })
      return
    }

    if (hasEventsDetection) {
      setState({
        ...initialState,
        hasEventsDetection: true
      })
      return
    }

    if (hasEventsDetectionJob) {
      setState({
        ...initialState,
        hasEventsDetectionJob: true
      })
      return
    }

    if (showStartEventsDetection) {
      setState({
        ...initialState,
        showStartEventsDetection: true
      })
      return
    }

    if (errorTryAgain) {
      setState({
        ...initialState,
        errorTryAgain: true
      })
      return
    }

    setState({ ...initialState })
  }, [props.vp, props.deletingVp, props.eventsDetectionJob, props.isVpCreatedInCloud])

  const renderFn = props.render || props.children // eslint-disable-line
  return renderFn(state)
}

EventsDetectionJob.propTypes = {
  vp: PropTypes.object.isRequired,
  eventsDetectionJob: PropTypes.object, // Don't use {} as a default prop for this
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default EventsDetectionJob
