import React from 'react'
import Multiselect from '../../components/Multiselect'

const multiselectOnChange = (form, field, onChange) => changes => {
  form.setFieldValue(field.name, changes, true)
  onChange(changes)
}

function MultiselectField({ field, form, onChange, ...other }) {
  const currentError = form.errors[field.name]

  React.useEffect(() => {
    form.setFieldValue(field.name, other.initialSelected, true)
  }, [other.initialSelected])

  return (
    <Multiselect
      name={field.name}
      error={form.touched[field.name] && Boolean(currentError)}
      errorText={form.touched[field.name] && currentError}
      onBlur={field.onBlur}
      onChange={multiselectOnChange(form, field, onChange)}
      {...other}
    />
  )
}

export default MultiselectField
