/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { theme } from '../../../constants'
import userService from '../../../services/user'
import getTrackingMinutes from '../../../utils/getTrackingMinutes'
import minTommss from '../../../utils/minPlans'
import * as Actions from '../actions'

const styles = {}

styles.blockingOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
`

styles.box = css`
  position: absolute;
  width: 700px;
  border-radius: 5px;
  padding: 45px 60px;
  background: white;
  border: 10px solid lightgray;
  pointer-events: all;
`

styles.title = css`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 25px;
`

styles.error = css`
  color: ${theme.palette.error.main};
  margin: 0px 30px 15px 30px;
`
styles.descriptionContainer = css`
  display: flex;
  justify-content: space-evenly;
  margin: 30px;
`

styles.loaderContainer = css`
  margin-left: 10px;
`

styles.actionContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

styles.actionButton = css`
  background: ${theme.palette.common.mainGradient};
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 18px;
`

styles.iconClose = css`
  position: absolute;
  display: flex;
  color: lightgrey;
  top: 20px;
  right: 20px;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 28px;
  cursor: pointer;
`

function AddKeyModal(props) {
  // Use a reference to read value properly within event listener callbacks.
  const [addKey, _setAddKey] = React.useState('')
  const addKeyRef = React.useRef(addKey)
  const setAddKey = key => {
    addKeyRef.current = key
    _setAddKey(key)
  }

  const [isEnabled, _setIsEnabled] = React.useState(false)
  const isEnabledRef = React.useRef(isEnabled)
  const setIsEnabled = how => {
    isEnabledRef.current = how
    _setIsEnabled(how)
  }

  const keyInputRef = React.useRef(null)
  const [showError, setShowError] = React.useState('')
  const [showLoader, setShowLoader] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  function handleChange(event) {
    const key = event.target.value

    // Disable submit if key is empty.
    setIsEnabled(!!key)
    setShowError('')
    setAddKey(key)
  }

  function onKeyUp(event) {
    if (event.keyCode === 13) {
      onSubmit()
    } else if (event.keyCode === 27) {
      onClose()
    }
  }

  async function onSubmit() {
    const key = addKeyRef.current
    const enabled = isEnabledRef.current
    if (!key || !enabled) {
      return
    }

    // On submit, show loader and disable submit to not
    // perform many retries with the same key.
    setShowLoader(true)
    setIsEnabled(false)

    try {
      await userService.addKey(props.user._id, { key })

      const totalMinutes = getTrackingMinutes(props.user)
      const fetchedUser = await Actions.updateUser(props.onApply)
      const totalMinutesAfter = getTrackingMinutes(fetchedUser)
      if (totalMinutesAfter > totalMinutes) {
        toast.info(
          window.I18n.t('addKeyModal.minutesMessage', { minutes: minTommss(totalMinutesAfter) })
        )
      } else {
        toast.info(window.I18n.t('addKeyModal.successMessage'))
      }

      onClose()
    } catch (error) {
      const message = error.response && error.response.data && error.response.data.message
      if (message) {
        setShowError(message)
        setShowLoader(false)

        // If there's an error (a typo, for instance), focus the input element
        // automatically to not have to click on it.
        if (keyInputRef.current) {
          setTimeout(() => keyInputRef.current.focus(), 100)
        }
      } else {
        console.error(error)
        toast.error(window.I18n.t('addKeyModal.errorMessage'))
        onClose()
      }
    }
  }

  function onClose() {
    Actions.closeAddKeyModal()
  }

  return (
    <div css={styles.blockingOverlay}>
      <div css={styles.box}>
        <span css={styles.title}>{window.I18n.t('addKeyModal.title')}</span>
        <div css={styles.descriptionContainer}>
          <TextField
            inputRef={keyInputRef}
            placeholder={window.I18n.t('addKeyModal.placeholder')}
            onChange={handleChange}
            type="text"
            disabled={showLoader}
            autoFocus
            fullWidth
          />
          {showLoader && (
            <div css={styles.loaderContainer}>
              <CircularProgress size={24} thickness={4} />
            </div>
          )}
        </div>
        {showError && <div css={styles.error}>{showError}</div>}
        <div css={styles.actionContainer}>
          <div css={styles.actionButton}>
            <Button disabled={!isEnabled} onClick={onSubmit}>
              {window.I18n.t('addKeyModal.action')}
            </Button>
          </div>
        </div>
        <span css={styles.iconClose} onClick={onClose}>
          {' '}
          X{' '}
        </span>
      </div>
    </div>
  )
}

AddKeyModal.defaultProps = {
  onApply: () => {}
}

AddKeyModal.propTypes = {
  user: PropTypes.object.isRequired,
  onApply: PropTypes.func
}

export default AddKeyModal
