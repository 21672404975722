/* eslint-disable max-len */
import { toast } from 'react-toastify'
import store from '../store'
import jobsService from '../services/jobs'
import ActionTypes from '../constants/actionTypes'
import amplitude from '../analytics/amplitude'
import {
  checkProgressInterval,
  Status,
  AnalyticsEvents,
  JOB_TYPES,
  DATA_INTEGRATION_PROVIDERS,
  GAMES_DATASET_DATA_TYPE,
  NOT_ENOUGH_MINUTES_KEY
} from '../constants'
import { updateOrganizationMinutes, getVp } from '../modules/admin/actions'

const activeJobTypes = [Status.QUEUED_LOCALLY, Status.QUEUED, Status.STARTING, Status.ACTIVE]

const jobTypesMapping = {
  [JOB_TYPES.PRE_PROCESSING.back]: JOB_TYPES.PRE_PROCESSING.front,
  [JOB_TYPES.TRACKING.back]: JOB_TYPES.TRACKING.front,
  [JOB_TYPES.HOMOGRAPHY.back]: JOB_TYPES.HOMOGRAPHY.front,
  [JOB_TYPES.DATA_INTEGRATION.back]: JOB_TYPES.DATA_INTEGRATION.front,
  [JOB_TYPES.TRACKING_FIFA_FORMAT.back]: JOB_TYPES.TRACKING_FIFA_FORMAT.front,
  [JOB_TYPES.AUTOMATED_TRACKING_DATA.back]: JOB_TYPES.AUTOMATED_TRACKING_DATA.front,
  [JOB_TYPES.EVENTS_DETECTION.back]: JOB_TYPES.EVENTS_DETECTION.front,
  [JOB_TYPES.SURFACES.back]: JOB_TYPES.SURFACES.front,
  [JOB_TYPES.BALL_TRACKING.back]: JOB_TYPES.BALL_TRACKING.front
}

class polling {
  constructor(vpId, vpName, vpType, vpIsSideView) {
    this.vpId = vpId
    this.vpName = vpName
    this.vpIsSideView = vpIsSideView
    this.vpType = vpType
    this.intervalTime = checkProgressInterval
    this.store = store.getState()
    this.jobTypes = Object.keys(jobTypesMapping)

    const admin = this.store.admin
    this.items = this.jobTypes.map(jobType => {
      return {
        [jobType]:
          (jobType === JOB_TYPES.PRE_PROCESSING.back && admin.preProcessingJobs[vpId]) ||
          (jobType === JOB_TYPES.TRACKING.back && admin.trackingJobs[vpId]) ||
          (jobType === JOB_TYPES.EVENTS_DETECTION.back && admin.eventsDetectionJobs[vpId]) ||
          (jobType === JOB_TYPES.SURFACES.back && admin.surfaceJobs[vpId]) ||
          (jobType === JOB_TYPES.BALL_TRACKING.back && admin.ballTrackingJobs[vpId]) ||
          (jobType === JOB_TYPES.HOMOGRAPHY.back && admin.fieldRecognitionJobs[vpId]) ||
          (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back &&
            admin.automatedTrackingDataJobs[vpId]) ||
          (jobType === JOB_TYPES.DATA_INTEGRATION.back && admin.dataIntegrationJobs[vpId]) ||
          (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back && admin.trackingFifaFormatJobs[vpId])
      }
    })
    this.dataIntegrationType = this.items.find(
      item => item && item.payload && item.payload.provider
    )
  }

  getItemByJobType(jobType) {
    const jobTypeIndex = this.items.findIndex(item => Object.keys(item).includes(jobType))
    let item
    if (jobTypeIndex > -1) {
      item = this.items[jobTypeIndex][jobType]
    }

    return item
  }

  isAnyJobRunning() {
    // this.items has format [[tracking:{...}], [homography:{...}], ...]
    // Take into account only AWS job, not sub-jobs.
    const awsJobs = ['pre_processing', 'tracking', 'homography', 'tracking_ball', 'post_processing']
    const isRunning = this.items.some(v => {
      for (const jobType of Object.keys(v)) {
        if (awsJobs.includes(jobType) && activeJobTypes.includes(v[jobType].status)) {
          return true
        }
      }

      return false
    })

    return isRunning
  }

  setup = () => {
    const isAnyJobRunning = this.isAnyJobRunning()
    if (isAnyJobRunning) {
      this._checkProgress()

      // Clear any previous interval.
      if (this.interval) {
        clearInterval(this.interval)
      }

      this.interval = setInterval(this._checkProgress, this.intervalTime)
    }
  }

  tearDown = (force = false) => {
    if (!this.interval) {
      return
    }
    if (force || !this.isAnyJobRunning()) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  cancel = jobTypes => {
    const pollingItems = this.items
    jobTypes.forEach(jt => {
      if (pollingItems[jt] && !activeJobTypes.includes(pollingItems[jt].status)) {
        return
      }

      store.dispatch({
        type: ActionTypes.CHANGE_JOBS_ITEM,
        job: jobTypesMapping[jt],
        payload: {
          [this.vpId]: {
            status: Status.CANCEL,
            progress: null,
            payload: pollingItems[jt] && pollingItems[jt].payload
          }
        }
      })
    })
  }

  start = jobType => {
    const item = this.getItemByJobType(jobType)
    store.dispatch({
      type: ActionTypes.CHANGE_JOBS_ITEM,
      job: jobTypesMapping[jobType],
      payload: {
        [this.vpId]: {
          status: Status.STARTING,
          progress: null,
          payload: item && item.payload
        }
      }
    })

    const starting = true
    this._checkProgress(starting)

    // Clear any previous interval.
    if (this.interval) {
      clearInterval(this.interval)
    }

    this.interval = setInterval(this._checkProgress, this.intervalTime)
  }

  _checkProgress = async starting => {
    const localJobs = this.jobTypes.map(jobType => {
      const reduxStore = store.getState()
      return (
        reduxStore.admin &&
        ((jobType === JOB_TYPES.PRE_PROCESSING.back &&
          reduxStore.admin.preProcessingJobs &&
          reduxStore.admin.preProcessingJobs[this.vpId]) ||
          (jobType === JOB_TYPES.TRACKING.back &&
            reduxStore.admin.trackingJobs &&
            reduxStore.admin.trackingJobs[this.vpId]) ||
          (jobType === JOB_TYPES.HOMOGRAPHY.back &&
            reduxStore.admin.fieldRecognitionJobs &&
            reduxStore.admin.fieldRecognitionJobs[this.vpId]) ||
          (jobType === JOB_TYPES.BALL_TRACKING.back &&
            reduxStore.admin.ballTrackingJobs &&
            reduxStore.admin.ballTrackingJobs[this.vpId]) ||
          (jobType === JOB_TYPES.EVENTS_DETECTION.back &&
            reduxStore.admin.eventsDetectionJobs &&
            reduxStore.admin.eventsDetectionJobs[this.vpId]) ||
          (jobType === JOB_TYPES.SURFACES.back &&
            reduxStore.admin.surfaceJobs &&
            reduxStore.admin.surfaceJobs[this.vpId]) ||
          (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back &&
            reduxStore.admin.automatedTrackingDataJobs &&
            reduxStore.admin.automatedTrackingDataJobs[this.vpId]) ||
          (jobType === JOB_TYPES.DATA_INTEGRATION.back &&
            reduxStore.admin.dataIntegrationJobs &&
            reduxStore.admin.dataIntegrationJobs[this.vpId]) ||
          (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
            reduxStore.admin.trackingFifaFormatJobs &&
            reduxStore.admin.trackingFifaFormatJobs[this.vpId]))
      )
    })

    const running = localJobs && localJobs.some(v => v && v.status !== null)
    if (starting || running) {
      try {
        let jobTypeComplete
        let jobTypeFailed

        const { data } = await jobsService.progress(this.vpId)
        const jobs = Object.keys(data)
        await Promise.all(
          jobs.map(async jobType => {
            if (!this.jobTypes.includes(jobType)) {
              return
            }

            // Get API job.
            const apiJob = data[jobType]
            const apiStatus = apiJob.status
            const apiProgress = apiJob.progress
            const apiPayload = apiJob.payload

            // Get local job to compare against API.
            let localJob = this.getItemByJobType(jobType)
            const localStatus = localJob && localJob.status
            const localProgress = localJob && localJob.progress

            // If we receive a different status or progress, set it.
            if (localStatus !== apiStatus || localProgress !== apiProgress) {
              if (!localJob) {
                localJob = {}

                const jobTypeIndex = this.items.findIndex(item =>
                  Object.keys(item).includes(jobType)
                )
                this.items[jobTypeIndex][jobType] = localJob
              }

              localJob.status = apiStatus
              localJob.progress = apiProgress
            }

            if (apiStatus === Status.COMPLETE && localStatus && localStatus !== Status.COMPLETE) {
              // Get the last job completed.
              jobTypeComplete = jobType

              await this._itemComplete(jobType)
              return
            }

            if (apiStatus === Status.CANCEL && localStatus && localStatus !== Status.CANCEL) {
              await this._itemCanceled(jobType, apiPayload.reason)
              return
            }

            if (apiStatus === Status.FAILED && localStatus && localStatus !== Status.FAILED) {
              // Get the first job failed.
              if (!jobTypeFailed) {
                jobTypeFailed = jobType
              }

              await this._itemFailed(jobType)
              return
            }

            if (apiStatus === Status.UNKNOWN && localStatus && localStatus !== Status.UNKNOWN) {
              // Get the first job failed.
              if (!jobTypeFailed) {
                jobTypeFailed = jobType
              }

              localJob.status = Status.FAILED
              await this._itemFailed(jobType)
              return
            }

            store.dispatch({
              type: ActionTypes.CHANGE_JOBS_ITEM,
              job: jobTypesMapping[jobType],
              payload: {
                [this.vpId]: {
                  status: apiStatus,
                  progress: apiProgress,
                  payload: apiJob.payload
                }
              }
            })
          })
        )

        // Send notification for last completed job.
        if (jobTypeComplete) {
          // VP Types Constants
          const isAtd = this.vpType === GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA
          const isSynced = this.vpType === GAMES_DATASET_DATA_TYPE.SYNCED
          let message

          if (jobTypeComplete === JOB_TYPES.SURFACES.back) {
            const { data: newVp } = await getVp(this.vpId)
            const surfacesArray = newVp.files && newVp.files.surfaces
            const hasPitchControl =
              surfacesArray && surfacesArray.find(v => v.name === 'Pitch Control')
            if (hasPitchControl) {
              message = window.I18n.t('admin.surfacesJobSuccess', { vpName: this.vpName })
            }
          }

          if (!message) {
            message =
              (jobTypeComplete === JOB_TYPES.TRACKING.back &&
                isAtd &&
                !this.vpIsSideView &&
                window.I18n.t('admin.automatedTrackingDataJobSuccess', { vpName: this.vpName })) ||
              (jobTypeComplete === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
                isAtd &&
                window.I18n.t('admin.automatedTrackingDataJobSuccess', { vpName: this.vpName })) ||
              (jobTypeComplete === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
                isSynced &&
                window.I18n.t('admin.dataIntegrationJobSuccess', { vpName: this.vpName })) ||
              (jobTypeComplete === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
                window.I18n.t('admin.trackingFifaFormatJobSuccess', { vpName: this.vpName }))
          }

          if (message) {
            toast.success(message)
          }
        }

        // Send notification for last failed job.
        if (jobTypeFailed) {
          // VP Types Constants
          const isSynced = this.vpType === GAMES_DATASET_DATA_TYPE.SYNCED
          let message

          if (jobTypeFailed === JOB_TYPES.TRACKING_FIFA_FORMAT.back && isSynced) {
            message = window.I18n.t('admin.dataIntegrationFailed', { vpName: this.vpName })
          } else if (jobTypeFailed === JOB_TYPES.SURFACES.back) {
            const { data: newVp } = await getVp(this.vpId)
            if (newVp.compute_surfaces) {
              message = window.I18n.t('common.errorMsg', { name: this.vpName })
            }
          }

          if (!message) {
            message = window.I18n.t('common.errorMsg', { name: this.vpName })
          }

          if (message) {
            toast.error(message)
          }
        }
      } catch (error) {
        this.jobTypes.map(jobType => {
          const item = this.getItemByJobType(jobType)
          return store.dispatch({
            type: ActionTypes.CHANGE_JOBS_ITEM,
            job: jobTypesMapping[jobType],
            payload: {
              [this.vpId]: {
                status: Status.CONNECTIONFAILED,
                progress: null,
                payload: item && item.payload
              }
            }
          })
        })
      }
    }
  }

  _updateTableItem = async () => {
    const { data } = await getVp(this.vpId)
    store.dispatch({
      type: ActionTypes.UPDATE_TABLE_LIST_ITEM,
      payload: data
    })
  }

  _itemCanceled = async (jobType, reason) => {
    const item = this.getItemByJobType(jobType)
    store.dispatch({
      type: ActionTypes.CHANGE_JOBS_ITEM,
      job: jobTypesMapping[jobType],
      payload: {
        [this.vpId]: {
          status: Status.CANCEL,
          progress: null,
          payload: item && item.payload
        }
      }
    })

    await this._updateTableItem()

    if (jobType === JOB_TYPES.PRE_PROCESSING.back && reason === NOT_ENOUGH_MINUTES_KEY) {
      this.tearDown()

      store.dispatch({
        type: ActionTypes.CHANGE_JOBS_ITEM,
        job: jobTypesMapping[JOB_TYPES.AUTOMATED_TRACKING_DATA.back],
        payload: {
          [this.vpId]: {
            status: Status.CANCEL,
            progress: null
          }
        }
      })

      store.dispatch({
        type: ActionTypes.NOT_ENOUGH_MINUTES,
        payload: true
      })
    }
  }

  _itemFailed = async jobType => {
    const item = this.getItemByJobType(jobType)
    store.dispatch({
      type: ActionTypes.CHANGE_JOBS_ITEM,
      job: jobTypesMapping[jobType],
      payload: {
        [this.vpId]: {
          status: Status.FAILED,
          progress: null,
          payload: item && item.payload
        }
      }
    })

    // Analytics
    const dataIntegrationType =
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB && 'TRACAB') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM &&
        'SECOND_SPECTRUM') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB_DFL && 'TRACAB_DFL') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.STATS && 'STATS') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.FIFA && 'FIFA')
    const event =
      (jobType === JOB_TYPES.TRACKING.back && AnalyticsEvents.AUTO_TRACKING_FAILED) ||
      (jobType === JOB_TYPES.HOMOGRAPHY.back && AnalyticsEvents.AUTO_HOMOGRAPHY_FAILED) ||
      (jobType === JOB_TYPES.DATA_INTEGRATION.back &&
        AnalyticsEvents[`AUTO_${dataIntegrationType}_FAILED`]) ||
      (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back &&
        AnalyticsEvents.AUTO_AUTOMATED_TRACKING_DATA_FAILED) ||
      (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
        AnalyticsEvents.AUTO_TRACKING_FIFA_FORMAT_FAILED) ||
      (jobType === JOB_TYPES.BALL_TRACKING.back && AnalyticsEvents.AUTO_BALL_TRACKING_FAILED)

    if (event) {
      amplitude.logEvent(event)
    }
  }

  _itemComplete = async jobType => {
    store.dispatch({
      type: ActionTypes.REMOVE_JOBS_ITEM,
      job: jobTypesMapping[jobType],
      payload: this.vpId
    })

    await this._updateTableItem()

    if (jobType === JOB_TYPES.PRE_PROCESSING.back) {
      updateOrganizationMinutes()
    }

    // Analytics
    const dataIntegrationType =
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB && 'TRACAB') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM &&
        'SECOND_SPECTRUM') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB_DFL && 'TRACAB_DFL') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.STATS && 'STATS') ||
      (this.dataIntegrationType === DATA_INTEGRATION_PROVIDERS.FIFA && 'FIFA')
    const event =
      (jobType === JOB_TYPES.TRACKING.back && AnalyticsEvents.AUTO_TRACKING_FINISHED) ||
      (jobType === JOB_TYPES.HOMOGRAPHY.back && AnalyticsEvents.AUTO_HOMOGRAPHY_FINISHED) ||
      (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back &&
        AnalyticsEvents.AUTO_AUTOMATED_TRACKING_DATA_FINISHED) ||
      (jobType === JOB_TYPES.DATA_INTEGRATION.back &&
        AnalyticsEvents[`AUTO_${dataIntegrationType}_FINISHED`]) ||
      (jobType === JOB_TYPES.BALL_TRACKING.back && AnalyticsEvents.AUTO_BALL_TRACKING_FINISHED) ||
      (jobType === JOB_TYPES.EVENTS_DETECTION.back &&
        AnalyticsEvents.AUTO_EVENTS_DETECTION_FINISHED) ||
      (jobType === JOB_TYPES.SURFACES.back && AnalyticsEvents.AUTO_SURFACES_FINISHED) ||
      (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back &&
        AnalyticsEvents.AUTO_TRACKING_FIFA_FORMAT_FINISHED)

    if (event) {
      amplitude.logEvent(event)
    }
  }
}

export default polling
