/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import Fab from '@material-ui/core/Fab'
import zendeskWidget from '../../utils/zendeskWidget'
import { theme } from '../../constants'

const styles = {}
styles.container = css`
  position: fixed;
  top: 14px;
  right: 14px;
  z-index: 300;
`
styles.question = css`
  border: 2px solid #fff;
  color: #fff;
  font-size: 20px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`
styles.button = {
  width: 36,
  height: 36,
  background: theme.palette.secondary.main
}

function onClick() {
  zendeskWidget.toggle()
}

const ZendeskWidgetButton = () => {
  return (
    <div css={styles.container}>
      <Fab style={styles.button} onClick={onClick}>
        <span css={styles.question}>?</span>
      </Fab>
    </div>
  )
}

export default ZendeskWidgetButton
