import qs from 'qs'
import api from './api'

const modelsService = {}

// Stadiums list
modelsService.getStadiums = params =>
  api.get('/stadiums/user', { params, paramsSerializer: paramsSe => qs.stringify(paramsSe) })

// Delete a single Stadium
modelsService.deleteStadium = id => api.delete(`/stadiums/${id}/user`)

// Competitions list
modelsService.getCompetitions = params =>
  api.get('/competitions/user', { params, paramsSerializer: paramsSe => qs.stringify(paramsSe) })

// Delete a single Competition
modelsService.deleteCompetition = id => api.delete(`/competitions/${id}/user`)

// Teams list
modelsService.getTeams = params =>
  api.get('/teams/user', { params, paramsSerializer: paramsSe => qs.stringify(paramsSe) })

// Delete a single Team
modelsService.deleteTeam = id => api.delete(`/teams/${id}/user`)

// Organizations list
modelsService.getOrganizations = params =>
  api.get('/organizations/user', { params, paramsSerializer: paramsSe => qs.stringify(paramsSe) })

// Search Organizations
modelsService.searchOrganizations = (search, params) =>
  api.get(`/organizations/search/${search}`, {
    params,
    paramsSerializer: paramsSe => qs.stringify(paramsSe)
  })

export default modelsService
