import React from 'react'
import DatePicker from '../../components/DatePicker.js'

const datePickerOnChange = (form, field) => date => form.setFieldValue(field.name, date, true)

function DatePickerField({ field, form, ...other }) {
  return (
    <DatePicker
      id={field.name}
      value={field.value.toString()}
      format="DD MMM YYYY"
      onChange={datePickerOnChange(form, field)}
      okLabel={window.I18n.t('common.ok')}
      cancelLabel={window.I18n.t('common.cancel')}
      todayLabel={window.I18n.t('common.today')}
      disableFuture={false}
      fullWidth
      {...other}
    />
  )
}

export default DatePickerField
