import api from './api'

const gameDatasets = {}

// Get part upload url
gameDatasets.getPartUploadUrl = (resource, payload) =>
  api.get(`/game-datasets/get-part-upload-url/${resource}`, payload)

// Start multipart upload
gameDatasets.startMultipartUpload = (resource, payload) =>
  api.put(`/game-datasets/start-multipart-upload/${resource}`, payload)

// Complete multipart upload
gameDatasets.completeMultipartUpload = (resource, payload) =>
  api.put(`/game-datasets/complete-multipart-upload/${resource}`, payload)

// Cancel multipart upload
gameDatasets.cancelMultipartUpload = (resource, payload) =>
  api.put(`/game-datasets/cancel-multipart-upload/${resource}`, payload)

// Process waiting jobs
gameDatasets.startProcessingWaitingJobs = id => api.put(`/game-datasets/${id}/jobs`)

// Delete video project
gameDatasets.deleteVideoProject = id => api.delete(`/game-datasets/${id}/organization`)

// Update game datasets
gameDatasets.update = (id, payload) => api.put(`/game-datasets/${id}/organization`, payload)

// Upload Resource file
gameDatasets.uploadResourceFile = (id, resource, resourceFile, payload) =>
  api.put(`/game-datasets/${id}/${resource}/${resourceFile}`, payload)

// Upload file
gameDatasets.uploadFile = (id, resource, payload) =>
  api.put(`/game-datasets/${id}/${resource}`, payload)

// Delete Resource file
gameDatasets.deleteResourceFile = (id, resource, resourceFile) =>
  api.delete(`/game-datasets/${id}/${resource}/${resourceFile}`)

// Remove GameDataset file
gameDatasets.removeFileAndUpdateGameDataset = (id, resource) =>
  api.delete(`/game-datasets/${id}/${resource}`)

// Get GameDataset File Signed URL
gameDatasets.getGameDatasetFileSignedURL = (id, resource) =>
  api.get(`/game-datasets/${id}/${resource}`)

// Get single video project
gameDatasets.getVp = (id, payload) => api.get(`/game-datasets/${id}/organization`, payload)

export default gameDatasets
