/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import fieldLogo from '../../../../assets/field-detection.svg'
import FieldRecognitionJob from '../FieldRecognitionJob'
import { theme, Status } from '../../../../constants'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    margin-left: 30px;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logoImg: css`
    width: 18px;
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function FieldRecognitionMenuItem(props) {
  return (
    <FieldRecognitionJob
      vp={props.vp}
      fieldRecognitionJob={props.fieldRecognitionJob}
      isVpCreatedInCloud={props.isVpCreatedInCloud}
      deletingVp={props.deletingVp}
      render={fieldRecognitionState => {
        const disabledItem =
          fieldRecognitionState.isOnHold ||
          fieldRecognitionState.disableActions ||
          fieldRecognitionState.deletingVp

        return (
          <div css={styles.itemContainer}>
            <div css={styles.content} className="video-menu-item-content">
              <div css={styles.icon}>
                <span
                  css={css`
                    ${styles.innerIcon};
                    opacity: ${disabledItem ? 0.35 : 1};
                  `}
                >
                  <img src={fieldLogo} css={styles.logoImg} />
                </span>
              </div>
              <div
                css={css`
                  ${styles.name};
                  color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
                `}
              >
                {window.I18n.t('admin.fieldRecognition')}

                <Tooltip
                  title={window.I18n.t('admin.fieldTrackingTooltip')}
                  enterDelay={200}
                  placement="left"
                >
                  <Icon
                    style={{
                      color: 'grey',
                      fontSize: '18px',
                      marginLeft: '10px',
                      top: '6px',
                      left: '5px',
                      transform: 'rotate(5deg)'
                    }}
                  >
                    info
                  </Icon>
                </Tooltip>
              </div>
              <div css={styles.icons}>{fieldRecognitionJobUI(props, fieldRecognitionState)}</div>
            </div>
          </div>
        )
      }}
    />
  )
}

function fieldRecognitionJobUI(props, fieldRecognitionState) {
  if (fieldRecognitionState.hasFieldRecognition) {
    return done()
  }

  if (!props.vp.files.video) {
    return null
  }

  if (fieldRecognitionState.hasFieldRecognitionJob) {
    const status = props.fieldRecognitionJob && props.fieldRecognitionJob.status
    switch (status) {
      case 'CHECKING':
      case Status.QUEUED_LOCALLY:
      case Status.QUEUED:
      case Status.STARTING:
      case Status.ACTIVE:
      case Status.FINISHING:
      case Status.CONNECTIONFAILED:
        return (
          <Tooltip
            title={window.I18n.t('admin.processingTooltip')}
            enterDelay={400}
            placement="top"
          >
            <CircularProgress
              css={css`
                ${styles.iconTag};
              `}
              size={14}
              thickness={4}
              color="primary"
            />
          </Tooltip>
        )
      case Status.COMPLETE:
        return done()
      case Status.FAILED:
        return errorUI()
      case Status.CANCEL:
      default:
        return null
    }
  }

  if (fieldRecognitionState.errorTryAgain) {
    return errorUI()
  }

  return null
}

function done() {
  return (
    <React.Fragment>
      <Tooltip
        title={window.I18n.t('admin.fieldTrackingProcessedTooltip')}
        enterDelay={400}
        placement="top"
      >
        <Icon
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.common.successGreen};
          `}
        >
          check_circle_outline
        </Icon>
      </Tooltip>
    </React.Fragment>
  )
}

function errorUI() {
  return (
    <Tooltip title={window.I18n.t('admin.errorProcessTooltip')} enterDelay={400} placement="top">
      <Icon
        css={css`
          ${styles.iconTag};
          color: ${theme.palette.common.redError};
        `}
      >
        highlight_off
      </Icon>
    </Tooltip>
  )
}

FieldRecognitionMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  fieldRecognitionJob: PropTypes.object, // eslint-disable-line
  errorFieldRecognitionLinkAvailable: PropTypes.bool.isRequired, // eslint-disable-line
  deletingVp: PropTypes.bool.isRequired,
  uploadingVideo: PropTypes.bool.isRequired // eslint-disable-line
}

export default FieldRecognitionMenuItem
