/* eslint-disable indent */
/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  withStyles
} from '@material-ui/core'
import getSymbolFromCurrency from 'currency-symbol-map'
import moment from 'moment/dist/moment'
import PropTypes from 'prop-types'
import { DefaultCurrencyCode, theme } from '../../constants'
import formatPrice from '../../utils/formatPrice'
import normalizePrice from '../../utils/normalizePrice'
import { ProductNames } from '../../constants/payments'
import orderService from '../../services/order'
import localizePrice from '../../utils/localizePrice'

const styles = {}
styles.rootContainer = css`
  position: relative;
  width: 100%;
`
styles.container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.common.mainGreen};
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -12px black;
`
styles.loadingContainer = css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
  pointer-events: none;
  opacity: 0.5;
  z-index: 1;
`
styles.plansContainer = css``
styles.plansHeader = css`
  display: flex;
  justify-content: space-between;
`
styles.plansTitle = css`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
`
styles.planContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
styles.lineThroughText = css`
  text-decoration-line: line-through;
`
styles.planQuantityContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`
styles.packsContainer = css``
styles.packsHeader = css``
styles.packsTitle = css`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
`
styles.packContainer = css`
  display: flex;
  justify-content: space-between;
`
styles.packTitle = css`
  font-size: 14px;
`
styles.priceContainer = css`
  display: flex;
  justify-content: space-between;
`
styles.boldText = css`
  font-size: 16px;
  font-weight: bold;
`
styles.rightText = css`
  text-align: right;
`
styles.boldRightText = css`
  font-size: 16px;
  font-weight: bold;
  text-align: right;
`
styles.totalText = css`
  font-size: 16px;
  font-weight: bold;
`
styles.vatText = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`
styles.paymentContainer = css`
  display: flex;
  flex-direction: column;
`
styles.buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
`
styles.separator = css`
  border: 1px solid ${theme.palette.common.charolBlack};
  width: 100%;
`
styles.hintText = css`
  margin-top: 8px;
  font-size: 12px;
`
styles.newProductButton = {
  color: theme.palette.common.charolBlack,
  textTransform: 'none'
}
styles.button = {
  height: '32px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.blue,
  textTransform: 'none',
  borderRadius: '2px',
  padding: '0px 6px'
}

const CssCycleSelect = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      padding: '0px 28px 0px 16px',
      height: '32px',
      borderRadius: '2px',
      border: '1px solid black'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

const DateFormat = 'MMM Do, YYYY'

const BillingCycle = [
  { value: 'monthly', label: 'Billed monthly' },
  { value: 'yearly', label: 'Billed yearly' }
]

function Checkout(props) {
  const [cycle, setCycle] = React.useState('/month')
  const [currencySymbol, setCurrencySymbol] = React.useState(
    getSymbolFromCurrency(DefaultCurrencyCode)
  )

  const [isLoading, setIsLoading] = React.useState(false)

  const [nextPaymentOriginal, setNextPaymentOriginal] = React.useState(0)
  const [recurrentPaymentOriginal, setRecurrentPaymentOriginal] = React.useState(0)

  const [immediatePayment, setImmediatePayment] = React.useState(0)
  const [immediateDate, setImmediateDate] = React.useState(0)
  const [nextPayment, setNextPayment] = React.useState(0)
  const [nextDate, setNextDate] = React.useState(0)
  const [recurringPayment, setRecurringPayment] = React.useState(0)

  const [subtotal, setSubtotal] = React.useState(0)
  const [taxTotal, setTaxTotal] = React.useState(0)
  const [total, setTotal] = React.useState(0)

  const [totalPlans, setTotalPlans] = React.useState(0)
  const [totalPacks, setTotalPacks] = React.useState(0)

  React.useEffect(() => {
    if (!props.isShopping) {
      return
    }

    const now = Date.now()
    setImmediateDate(moment(now).format(DateFormat))

    setNextDate(
      moment(now)
        .add(1, cycle === '/month' ? 'M' : 'Y')
        .format(DateFormat)
    )
  }, [cycle])

  React.useEffect(() => {
    if (props.products.length === 0) {
      return
    }

    if (!props.subscription || !props.subscription.order) {
      return
    }

    const items = props.subscription.order.items
      .map(v => ({ id: v.id, quantity: v.quantity }))
      .filter(v => {
        const product = props.products.find(p => p.priceId === v.id)
        return product && product.cycle !== 'one_time'
      })

    orderService.getPricePreview(props.subscription.order.code, items).then(response => {
      const preview = response.data
      if (!preview) {
        return
      }

      const next = preview.next_transaction
      setNextPaymentOriginal(formatPrice(next.details.totals.total, props.currencyCode, true))

      const recurring = preview.recurring_transaction_details
      setRecurrentPaymentOriginal(formatPrice(recurring.totals.total, props.currencyCode, true))
    })
  }, [props.subscription, props.products])

  const hasPriceInCurrency = (product, currencyCode) => {
    return product.localizedPrices.some(v => v.unit_price.currency_code === currencyCode)
  }

  // If the product has the currency in 'localizedPrices' use it, otherwise perform a preview
  // request in order to get the price conversion.
  const getPrice = async product => {
    const needsConversion = !hasPriceInCurrency(product, props.currencyCode)
    if (needsConversion) {
      const previewRequest = {
        items: [{ priceId: product.priceId, quantity: product.quantity }],
        currencyCode: props.currencyCode
      }

      if (props.customer && props.customer.id) {
        previewRequest.customerId = props.customer.id
      }

      const response = await props.paddle.PricePreview(previewRequest)
      const preview = response.data
      return normalizePrice(preview.details.lineItems[0].totals.subtotal, props.currencyCode)
    }

    return localizePrice(product, props.currencyCode) * product.quantity
  }

  React.useEffect(() => {
    Promise.all(props.plansCheckout.map(v => getPrice(v))).then(prices => {
      const newTotalPlans = prices.reduce((acc, price) => acc + price, 0)
      setTotalPlans(newTotalPlans)
    })

    Promise.all(props.packsCheckout.map(v => getPrice(v))).then(prices => {
      const newTotalPacks = prices.reduce((acc, price) => acc + price, 0)
      setTotalPacks(newTotalPacks)
    })

    if (!props.isShopping && (!props.subscription || props.subscription.order)) {
      return
    }

    const previewPrices = async () => {
      if (props.plansCheckout.length === 0 && props.packsCheckout.length === 0) {
        return
      }

      setIsLoading(true)

      if (!props.currencyCode) {
        return
      }

      const productsCheckout = [...props.plansCheckout, ...props.packsCheckout]
      const items = productsCheckout.map(v => ({ priceId: v.priceId, quantity: v.quantity }))
      const previewRequest = {
        items,
        customerIpAddress: props.userIp,
        currencyCode: props.currencyCode
      }

      if (props.customer && props.customer.id) {
        previewRequest.customerId = props.customer.id
      }

      const response = await props.paddle.PricePreview(previewRequest)
      const preview = response.data

      if (preview) {
        let newSubtotal = 0.0
        let newTaxTotal = 0.0
        let newRecurringTotal = 0.0
        let newTotal = 0.0
        preview.details.lineItems.forEach(v => {
          newSubtotal += normalizePrice(v.totals.subtotal, props.currencyCode)
          newTaxTotal += normalizePrice(v.totals.tax, props.currencyCode)
          newTotal += normalizePrice(v.totals.total, props.currencyCode)

          if (v.price.billingCycle) {
            newRecurringTotal += normalizePrice(v.totals.total, props.currencyCode)
          }
        })

        setSubtotal(formatPrice(newSubtotal, props.currencyCode))
        setTaxTotal(formatPrice(newTaxTotal, props.currencyCode))
        setTotal(formatPrice(newTotal, props.currencyCode))

        setImmediatePayment(formatPrice(newRecurringTotal, props.currencyCode))
        setNextPayment(formatPrice(newRecurringTotal, props.currencyCode))

        const now = Date.now()
        setImmediateDate(moment(now).format(DateFormat))
        setNextDate(
          moment(now)
            .add(1, cycle === '/month' ? 'M' : 'Y')
            .format(DateFormat)
        )
      }

      setIsLoading(false)
    }

    previewPrices()
  }, [props.plansCheckout, props.packsCheckout, props.currencyCode, props.userIp])

  React.useEffect(() => {
    if (props.isShopping || !props.subscription) {
      return
    }

    const previewPrices = async () => {
      if (props.plansCheckout.length === 0 || !props.subscription.order) {
        return
      }

      setIsLoading(true)

      const items = props.plansCheckout.map(v => ({ id: v.priceId, quantity: v.quantity }))
      const response = await orderService.getPricePreview(props.subscription.order.code, items)
      const preview = response.data

      if (preview) {
        const now = Date.now()

        const immediate = preview.immediate_transaction
        if (immediate) {
          setImmediatePayment(
            formatPrice(immediate.details.totals.grand_total, props.currencyCode, true)
          )
          setImmediateDate(moment(immediate.billing_period.starts_at).format(DateFormat))
        } else {
          setImmediatePayment(formatPrice(0, props.currencyCode))
          setImmediateDate(moment(now).format(DateFormat))
        }

        const next = preview.next_transaction
        if (next) {
          setNextPayment(formatPrice(next.details.totals.total, props.currencyCode, true))
          setNextDate(moment(next.billing_period.starts_at).format(DateFormat))
        } else {
          setNextPayment(formatPrice(0, props.currencyCode))
          setNextDate(
            moment(now)
              .add(1, cycle === '/month' ? 'M' : 'Y')
              .format(DateFormat)
          )
        }

        const recurring = preview.recurring_transaction_details
        if (recurring) {
          setRecurringPayment(formatPrice(recurring.totals.total, props.currencyCode, true))

          setSubtotal(formatPrice(recurring.totals.subtotal, props.currencyCode, true))
          setTaxTotal(formatPrice(recurring.totals.tax, props.currencyCode, true))
          setTotal(formatPrice(recurring.totals.total, props.currencyCode, true))
        }
      }

      setIsLoading(false)
    }

    previewPrices()
  }, [props.plansCheckout])

  React.useEffect(() => {
    setCycle(props.isMonthly ? '/month' : '/year')
  }, [props.isMonthly])

  const handleSelectBillingCycle = e => {
    props.onBillingCycle(e.target.value === 'monthly')
  }

  return (
    <div css={styles.rootContainer}>
      {(isLoading || props.isLoading) && (
        <div css={styles.loadingContainer}>
          <CircularProgress color="primary" size={35} thickness={4} />
        </div>
      )}

      <div css={styles.container}>
        <div css={styles.plansContainer}>
          <div css={styles.plansHeader}>
            <span css={styles.plansTitle}>{window.I18n.t('checkout.plans')}</span>
            <FormControl>
              <CssCycleSelect
                select
                variant="outlined"
                name="billing-cycle"
                disabled={props.plansCheckout.some(v => v.isDeprecated)}
                value={props.isMonthly ? 'monthly' : 'yearly'}
                onChange={handleSelectBillingCycle}
              >
                {BillingCycle.map(v => (
                  <MenuItem key={`menu-item-${v.value}`} value={v.value}>
                    <span css={styles.billingCycleDropdownLabel}>
                      {window.I18n.t(`checkout.${v.value}Billed`)}
                    </span>
                  </MenuItem>
                ))}
              </CssCycleSelect>
            </FormControl>
          </div>

          {props.products.length > 0 && props.plansCheckout.length === 0 && (
            <div key="plan-checkout-free" css={styles.planContainer}>
              <span css={styles.boldText}>
                {window.I18n.t('plans.plan', { planName: window.I18n.t('plans.starter') })}
              </span>
              <div css={styles.planQuantityContainer}>
                <span>{window.I18n.t('checkout.userDevice')}</span>
                <span>
                  {window.I18n.t('checkout.costRecurrent', {
                    currency: props.currencyCode,
                    cost: formatPrice(0, props.currencyCode),
                    cycle: window.I18n.t(`checkout.${cycle === '/month' ? 'perMonth' : 'perYear'}`)
                  })}
                </span>
              </div>
            </div>
          )}

          {props.plansCheckout.map(v => {
            const product = ProductNames.find(p => p.value === v.productCode)

            return (
              <div key={`plan-checkout-${v.id}`} css={styles.planContainer}>
                <span css={styles.boldText}>
                  {' '}
                  {window.I18n.t('plans.plan', {
                    planName: product.translationKey
                      ? window.I18n.t(`plans.${product.translationKey}`)
                      : product.translation
                  })}
                </span>
                <div css={styles.planQuantityContainer}>
                  <span>
                    {window.I18n.t(
                      v.quantity === 1 ? 'checkout.userDevice' : 'checkout.usersDevices',
                      { number: v.quantity }
                    )}
                  </span>
                  <span>
                    {window.I18n.t('checkout.costRecurrent', {
                      currency: hasPriceInCurrency(v, props.currencyCode)
                        ? props.currencyCode
                        : DefaultCurrencyCode,
                      cost: formatPrice(
                        localizePrice(v, props.currencyCode) * v.quantity,
                        props.currencyCode
                      ),
                      cycle: window.I18n.t(
                        `checkout.${cycle === '/month' ? 'perMonth' : 'perYear'}`
                      )
                    })}
                  </span>
                </div>
              </div>
            )
          })}
        </div>

        {props.isShopping && (
          <React.Fragment>
            <div css={styles.priceContainer} style={{ marginTop: '8px' }}>
              <span css={styles.totalText}>{window.I18n.t('checkout.plansTotal')}</span>
              <span css={styles.totalText}>
                {window.I18n.t('checkout.costRecurrent', {
                  currency: props.currencyCode,
                  cost: formatPrice(totalPlans, props.currencyCode),
                  cycle: window.I18n.t(`checkout.${cycle === '/month' ? 'perMonth' : 'perYear'}`)
                })}
              </span>
            </div>
            <div css={styles.vatText}>
              <span>{window.I18n.t('checkout.excTax')}</span>
            </div>

            {props.packsCheckout.length > 0 && (
              <React.Fragment>
                <div css={styles.packsContainer}>
                  <div css={styles.packsHeader}>
                    <span css={styles.packsTitle}>{window.I18n.t('checkout.packs')}</span>
                  </div>
                  {props.packsCheckout.length === 0 && (
                    <span css={styles.packTitle}>{window.I18n.t('checkout.noPacks')}</span>
                  )}
                  {props.packsCheckout.map(v => {
                    const product = ProductNames.find(p => p.value === v.priceCode)

                    return (
                      <div key={`pack-checkout-${v.id}`} css={styles.packContainer}>
                        <span css={styles.packTitle}>
                          {v.quantity} x {window.I18n.t(`plans.${product.translationKey}`)}
                        </span>
                        <span>
                          {window.I18n.t('checkout.costOneTime', {
                            currency: hasPriceInCurrency(v, props.currencyCode)
                              ? props.currencyCode
                              : DefaultCurrencyCode,
                            cost: formatPrice(
                              localizePrice(v, props.currencyCode) * v.quantity,
                              props.currencyCode
                            )
                          })}
                        </span>
                      </div>
                    )
                  })}
                </div>

                <div css={styles.priceContainer} style={{ marginTop: '8px' }}>
                  <span css={styles.totalText}>{window.I18n.t('checkout.packsTotal')}</span>
                  <span css={styles.totalText}>
                    {window.I18n.t('checkout.costOneTime', {
                      currency: props.currencyCode,
                      cost: formatPrice(totalPacks, props.currencyCode)
                    })}
                  </span>
                </div>
                <div css={styles.vatText}>
                  <span>{window.I18n.t('checkout.excTax')}</span>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {(props.plansCheckout.length > 0 || props.packsCheckout.length > 0) &&
          !props.totalsDisabled && (
            <React.Fragment>
              <div css={styles.priceContainer} style={{ marginTop: '16px' }}>
                <span css={styles.boldText}>{window.I18n.t('checkout.subtotal')}</span>
                <span css={styles.boldText}>
                  {window.I18n.t('checkout.costOneTime', {
                    currency: props.currencyCode,
                    cost: subtotal
                  })}
                </span>
              </div>

              <div css={styles.priceContainer}>
                <span>{window.I18n.t('checkout.taxPayable', { percentage: props.taxRate })}</span>
                <span>
                  {window.I18n.t('checkout.costOneTime', {
                    currency: props.currencyCode,
                    cost: taxTotal
                  })}
                </span>
              </div>

              <hr css={styles.separator} />
              <div css={styles.priceContainer}>
                <span css={styles.boldText}>{window.I18n.t('checkout.total')}</span>
                <span css={styles.boldText}>
                  {window.I18n.t(`checkout.${props.isShopping ? 'costOneTime' : 'costRecurrent'}`, {
                    currency: props.currencyCode,
                    cost: total,
                    cycle: window.I18n.t(`checkout.${cycle === '/month' ? 'perMonth' : 'perYear'}`)
                  })}
                </span>
              </div>
              <hr css={styles.separator} />

              {!props.buttonDisabled && !props.totalsDisabled && (
                <React.Fragment>
                  {!props.isShopping && (
                    <React.Fragment>
                      <div css={styles.priceContainer} style={{ marginTop: '50px' }}>
                        <div css={styles.paymentContainer}>
                          <span css={styles.boldText}>
                            {window.I18n.t('checkout.immediatePayment')}
                          </span>
                          {immediateDate && (
                            <span>{window.I18n.t('checkout.onDate', { date: immediateDate })}</span>
                          )}
                        </div>

                        <div css={styles.paymentContainer}>
                          <span css={styles.boldRightText}>
                            {window.I18n.t('checkout.costOneTime', {
                              currency: props.currencyCode,
                              cost: immediatePayment
                            })}
                          </span>
                          <span css={styles.rightText}>{window.I18n.t('checkout.incTax')}</span>
                        </div>
                      </div>
                      <hr css={styles.separator} />
                    </React.Fragment>
                  )}

                  <div css={styles.priceContainer}>
                    <div css={styles.paymentContainer}>
                      <span css={styles.boldText}>{window.I18n.t('checkout.nextPayment')}</span>
                      <div>
                        {nextDate && (
                          <span>{window.I18n.t('checkout.onDate', { date: nextDate })}</span>
                        )}
                        {!props.isShopping && (
                          <span css={styles.lineThroughText} style={{ marginLeft: '4px' }}>
                            {window.I18n.t('checkout.costOneTime', {
                              currency: props.currencyCode,
                              cost: nextPaymentOriginal
                            })}
                          </span>
                        )}
                      </div>
                    </div>

                    <div css={styles.paymentContainer}>
                      <span css={styles.boldRightText}>
                        {window.I18n.t('checkout.costOneTime', {
                          currency: props.currencyCode,
                          cost: nextPayment
                        })}
                      </span>
                      <span css={styles.rightText}>{window.I18n.t('checkout.incTax')}</span>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

        <div css={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{
              ...styles.button,
              ...(props.buttonDisabled ||
              isLoading ||
              props.isLoading ||
              !props.currencyCode ||
              (props.plansCheckout.length === 0 && props.packsCheckout.length === 0)
                ? { pointerEvents: 'none', opacity: 0.5 }
                : {})
            }}
            onClick={props.onCheckout}
          >
            {props.buttonText}
          </Button>
        </div>

        {props.hintText && <span css={styles.hintText}>{props.hintText}</span>}
      </div>
    </div>
  )
}

Checkout.defaultProps = {
  paddle: undefined,
  customer: undefined,
  address: undefined,
  subscription: undefined,
  packsCheckout: [],
  isShopping: true,
  currencyCode: '',
  isLoading: false,
  buttonDisabled: false,
  hintText: '',
  totalsDisabled: false
}

Checkout.propTypes = {
  paddle: PropTypes.object,
  customer: PropTypes.object,
  address: PropTypes.object,
  userIp: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  plansCheckout: PropTypes.array.isRequired,
  packsCheckout: PropTypes.array,
  products: PropTypes.array.isRequired,
  isShopping: PropTypes.bool,
  currencyCode: PropTypes.string,
  taxRate: PropTypes.number.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  hintText: PropTypes.string,
  totalsDisabled: PropTypes.bool,
  onBillingCycle: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired
}

export default Checkout
