/** @jsx jsx */
// eslint-disable-next-line
import React, { PureComponent, Fragment } from 'react'
import { css, jsx } from '@emotion/core'
import * as TSMetrica from '@metrica-sports/tsmetrica'
import path from 'path'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { toast } from 'react-toastify'
import _ from 'lodash'
import moment from 'moment/dist/moment'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import MultipartHiddenUploadForm from '../../components/MultipartHiddenUploadForm'
import ChangePercentage from '../../components/ChangePercentage'
import gameDatasetsService from '../../../services/gameDatasets'
import jobsService from '../../../services/jobs'
import downloadFile from '../../../utils/downloadFile'
import FileHiddenUploadForm from '../../components/FileHiddenUploadForm'
import Polling from '../../../utils/polling'
import videoFileLogo from '../../../assets/videoFileLogo.png'
import clipFileLogo from '../../../assets/clip-detection-icon.svg'
import UncaughtErrorRow from './UncaughtErrorRow'
import AutomatedTrackingCol from './AutomatedTrackingCol'
import Progress from '../../components/Progress'
import VideoMenuItem from './rowMenuItems/VideoMenuItem'
import VideoProjectMenuItem from './rowMenuItems/VideoProjectMenuItem'
import DataIntegrationMenuItem from './rowMenuItems/DataIntegrationMenuItem'
import FieldRecognitionMenuItem from './rowMenuItems/FieldRecognitionMenuItem'
import AutomatedTrackingDataMenuItem from './rowMenuItems/AutomatedTrackingDataMenuItem'
import TrackingMenuItem from './rowMenuItems/TrackingMenuItem'
import BallTrackingMenuItem from './rowMenuItems/BallTrackingMenuItem'
import SurfaceMenuItem from './rowMenuItems/SurfaceMenuItem'
import PitchControlMenuItem from './rowMenuItems/PitchControlMenuItem'
import SurfacesMenuItem from './rowMenuItems/SurfacesMenuItem'
import PatternsMenuItem from './rowMenuItems/PatternsMenuItem'
import TrackingFifaFormatMenuItem from './rowMenuItems/TrackingFifaFormatMenuItem'
import CorrectionMenuItem from './rowMenuItems/CorrectionMenuItem'
import EventsDetectionMenuItem from './rowMenuItems/EventsDetectionMenuItem'
import PlaylistMenuItem from './rowMenuItems/PlaylistMenuItem'
import amplitude from '../../../analytics/amplitude'
import {
  theme,
  Status,
  AnalyticsEvents,
  videoFormats,
  JOB_TYPES,
  DATA_INTEGRATION_PROVIDERS,
  GAMES_DATASET_DATA_TYPE,
  checkProgressInterval
} from '../../../constants'

const cellStyles = {
  body: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    position: 'relative',
    height: 55,
    fontWeight: '500',
    padding: '0px 10px'
  }
}
const CustomTableRow = withStyles(() => ({
  root: {
    position: 'relative',
    clear: 'both',
    height: '55px',
    transform: 'scale(1)'
  }
}))(TableRow)

const DateCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '9%',
    textTransform: 'uppercase'
  }
}))(TableCell)

const CompetitionCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '19%'
  }
}))(TableCell)

const DescriptionCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '23%'
  }
}))(TableCell)

const PlaceCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '17%'
  }
}))(TableCell)

const UploadCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '11.5%',
    textTransform: 'uppercase'
  }
}))(TableCell)

const TrackingCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '8%'
  }
}))(TableCell)

const FieldCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '8%'
  }
}))(TableCell)

const MenuCell = withStyles(() => ({
  body: {
    ...cellStyles.body,
    width: '3.5%',
    textTransform: 'uppercase'
  }
}))(TableCell)

const styles = {}
styles.progressBar = {
  borderRadius: 2
}
styles.iconSuccess = {
  color: theme.palette.common.successGreen
}
styles.iconConnectionFailed = {
  color: theme.palette.error.main
}
styles.moreButonWrapper = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

styles.videoTooltipContent = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 22px;
  height: 20px;
  cursor: default;
`
styles.downloadVideoLink = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.common.blue};
  text-decoration: underline;
  cursor: pointer;
`
styles.errorTrackingLink = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.error.main};
  text-decoration: underline;
  cursor: pointer;
`
styles.homographyLoading = css`
  position: relative;
  top: 5px;
  left: 2px;
  color: ${theme.palette.primary.dark};
`
styles.greyRowText = css`
  color: ${theme.palette.common.grey};
  text-transform: none;
  font-size: 12px;
`
styles.percentLine = css`
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 13px;
  color: ${theme.palette.common.white};
  transform: translateX(-50%);
`
styles.newLabel = css`
  background-color: ${theme.palette.common.blue};
  color: ${theme.palette.common.white};
  padding: 1px 2px;
  border-radius: 2px;
  margin-right: 10px;
  font-size: 10px;
  text-transform: uppercase;
`
styles.openInForm = css`
  font-weight: bolder;
  font-size: 14px;
  cursor: default;
`
styles.circularProgressWithHover = css`
  align-items: center;
  background-color: #fafafa;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  min-height: 48px;
`

styles.menuIcon = css`
  display: none;
  &:hover {
    display: inline-block;
  }
`

const noMenuClicks = `pointer-events: none; color: ${theme.palette.common.grey}`

class AdminTableRow extends PureComponent {
  state = {
    anchorMenuEl: null,
    uploadingVideo: false,
    deletingVideo: false,
    deletingVp: false,
    downloadingVideo: false,
    uncaughtError: false,
    cancelingVideoUpload: false,
    deletingPatterns: false,
    isHoverOnMoreOptions: false,
    hasWritePermission: false,
    uploadingSurface: false,
    uploadingPatterns: false
  }

  vpName = this.props.row.game
    ? this.props.row.game.description ||
      `${_makeName(this.props.row.game.home_team)} vs ${_makeName(this.props.row.game.away_team)}`
    : ''
  vpType = this.props.row.data_type
  mainPolling = new Polling(
    this.props.row._id,
    this.vpName,
    this.vpType,
    this.props.row.is_football_side_view
  )

  // Not using polling because surface processing is not related to AWS.
  surfaceInterval = null

  componentDidCatch(error, info) {
    console.log('Uncaught Error in VP row: ', error)
    console.log('Error Info: ', info)
    this.setState({ uncaughtError: true })
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload)
    this.mainPolling.setup()

    // If some surface path is missing, it means some surface is being processed.
    const vp = this.props.row
    const surfaces = vp.files.surfaces
    if (!surfaces.every(v => !!v.path)) {
      this.startSurfaceInterval()
    }

    this.setWritePermission()
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload)
    this.mainPolling.tearDown(true)
  }

  beforeUnload = event => {
    if (this.state.uploadingVideo) {
      event.preventDefault()
      const confirmationText = 'confirmation text'
      event.returnValue = undefined
      return confirmationText
    }
  }

  setWritePermission = () => {
    const hasWritePermission = this.props.hasWritePermissionOnOrganization(
      this.props.row.organizations
    )
    this.setState({ hasWritePermission })
  }

  handleMenuOpen = e => {
    this.setState({ anchorMenuEl: e.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorMenuEl: null, isHoverOnMoreOptions: false })
  }

  uploadVideo = () => {
    this.uploadVideoInputEl.openFileSelector()
    this.handleMenuClose()
  }

  hoverInMoreOptions = () => {
    this.setState({ isHoverOnMoreOptions: true })
  }

  hoverOutMoreOptions = () => {
    this.setState({ anchorMenuEl: null, isHoverOnMoreOptions: false })
  }

  startUploadVideoExternal = (fileObject, automatedTrackingData) => {
    this.uploadVideoInputEl.startUploadVideoExternal(fileObject, automatedTrackingData)
  }

  cancelVideoUpload = async () => {
    try {
      this.setState({ cancelingVideoUpload: true })
      this.handleMenuClose()
      await this.uploadVideoInputEl.cancelUpload()
      amplitude.logEvent(AnalyticsEvents.VIDEO_UPLOAD_CANCELED)
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    } finally {
      this.setState({ uploadingVideo: false, cancelingVideoUpload: false })
    }
  }

  uploadSurface = () => {
    this.uploadSurfaceInputEl.openFileSelector()
  }

  uploadPatterns = () => {
    this.uploadPatternsInputEl.openFileSelector()
  }

  startProcessingWaitingJobs = id => async () => {
    try {
      this.handleMenuClose()
      await gameDatasetsService.startProcessingWaitingJobs(id)
      await this.props.updateTableItem(id)

      toast.success(window.I18n.t('admin.startProcessingWaitingJobsSuccessfully'))
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('admin.startProcessingWaitingJobsFailed'))
    }
  }

  deleteVideoProject = (id, vpName) => async () => {
    try {
      this.handleMenuClose()
      this.setState({ deletingVp: true })
      await gameDatasetsService.deleteVideoProject(id)
      this.props.deleteVp(id)
      const gameName = vpName || this.vpName
      toast.success(window.I18n.t('admin.gameDeletedSuccessfully', { game: gameName }))
    } catch (error) {
      console.error(error)
      const gameName = vpName || this.vpName
      toast.error(window.I18n.t('common.errorMsg', { name: gameName }))
    } finally {
      this.setState({ deletingVp: false })
    }
  }

  editVideoProject = vp => () => {
    this.handleMenuClose()
    this.props.openDialogToEditVp(vp)
  }

  formatDate = date => {
    return date ? (
      moment(date).format('DD MMM YYYY')
    ) : (
      <span
        css={css`
          text-transform: none;
        `}
      >{`${window.I18n.t('common.no')} ${window.I18n.t('admin.date')}`}</span>
    )
  }

  beforeUploadSurface = (file, fileName, resource) => {
    return new Promise((resolve, reject) => {
      const upload = () => {
        const resourceName = path.basename(fileName, path.extname(fileName))
        resolve(`${resource}${resourceName}`)
      }

      // File size exceeds a limit, avoid reading it.
      if (file.size > 256 * 1024 * 1024) {
        upload()
        return
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        let allowUpload = true
        try {
          // Check surface format.
          TSMetrica.Surfaces.checkFormat(reader.result)
        } catch (e) {
          // If parsing failed, e.g. file too big, allow upload.
          if (!e.message || !e.message.startsWith("Couldn't parse JSON surface.")) {
            reject(e)
            allowUpload = false
          }
        }

        // Get resource.
        if (allowUpload) {
          upload()
        }
      })

      reader.readAsText(file)
    })
  }

  hiddenUploadFormOnStart = suffix => {
    this.setState({ [`uploading${suffix}`]: true })
  }

  hiddenUploadFormOnFinish = async (data, vpId, fileName, suffix) => {
    await this.props.updateTableItem(vpId)
    this.setState({ [`uploading${suffix}`]: false })
    toast.success(
      window.I18n.t('admin.fileUploadedSuccessfully', { fileName, vpName: this.vpName })
    )
  }

  hiddenUploadFormOnError = (err, fileName, suffix) => {
    this.setState({ [`uploading${suffix}`]: false })
    toast.error(window.I18n.t('admin.fileUploadedError', { fileName, vpName: this.vpName }))
  }

  multiPartHiddenVideoUploadFormRef = el => {
    this.uploadVideoInputEl = el
  }

  multiPartHiddenVideoUploadFormOnStart = videoName => {
    this.setState({ uploadingVideo: true })
    toast.info(window.I18n.t('admin.videoMultipartStart', { videoName, vpName: this.vpName }))
    amplitude.logEvent(AnalyticsEvents.VIDEO_UPLOAD_STARTED)
  }

  multiPartHiddenVideoUploadFormOnProgress = updatePercentage => progress => {
    updatePercentage(progress * 100)
  }

  multiPartHiddenVideoUploadFormOnFinish =
    (updatePercentage, vpId) => async (videoName, startAtd) => {
      updatePercentage(null)

      toast.success(window.I18n.t('admin.videoUploaded', { videoName, vpName: this.vpName }))
      await this.props.updateTableItem(vpId)
      this.setState({ uploadingVideo: false })
      amplitude.logEvent(AnalyticsEvents.VIDEO_UPLOAD_COMPLETED)
      if (startAtd) {
        await this.startJob(vpId, JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
      }
    }

  multiPartHiddenVideoUploadFormOnError = updatePercentage => videoName => {
    updatePercentage(null)
    this.setState({ uploadingVideo: false })
    toast.error(window.I18n.t('admin.videoUploadError', { videoName, vpName: this.vpName }))
  }

  multiPartHiddenSurfaceUploadFormRef = el => {
    this.uploadSurfaceInputEl = el
  }

  multiPartHiddenSurfaceUploadFormOnStart = surfaceName => {
    this.setState({ uploadingSurface: true })
    toast.info(
      window.I18n.t('admin.videoMultipartStart', { videoName: surfaceName, vpName: this.vpName })
    )
    amplitude.logEvent(AnalyticsEvents.MULTIPART_UPLOAD_STARTED)
  }

  multiPartHiddenSurfaceUploadFormOnProgress = progress => {}

  multiPartHiddenSurfaceUploadFormOnFinish = vpId => async surfaceName => {
    toast.success(
      window.I18n.t('admin.videoUploaded', { videoName: surfaceName, vpName: this.vpName })
    )
    await this.props.updateTableItem(vpId)
    this.setState({ uploadingSurface: false })
    amplitude.logEvent(AnalyticsEvents.MULTIPART_UPLOAD_COMPLETED)
    this.startSurfaceInterval()
  }

  multiPartHiddenSurfaceUploadFormOnError = surfaceName => {
    this.setState({ uploadingSurface: false })
    toast.error(
      window.I18n.t('admin.videoUploadError', { videoName: surfaceName, vpName: this.vpName })
    )
  }

  startSurfaceInterval = () => {
    if (this.surfaceInterval) {
      return
    }

    // Start an interval to check surface processing has finished.
    const vpId = this.props.row._id
    this.surfaceInterval = setInterval(async () => {
      const vp = await this.props.updateTableItem(vpId)

      // Stop interval when all surface paths exist.
      const surfaces = vp.files.surfaces
      if (surfaces.every(v => !!v.path)) {
        clearInterval(this.surfaceInterval)
        this.surfaceInterval = null
      }
    }, checkProgressInterval)
  }

  startJob = async (id, jobType, payload = {}) => {
    try {
      // Check minutes when job is ATD and it's not a corrected VP. If it's a corrected VP,
      // check if tracking-to-correct file exists. If it doesn't exist, check minutes. Otherwise,
      // skip minutes-check because we're running the correction-task, i.e. this job belongs to
      // a VP for which user has already consumed minutes.
      const vp = this.props.row
      const isCorrected = vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED
      if (
        JOB_TYPES.AUTOMATED_TRACKING_DATA.back === jobType &&
        (!isCorrected || !vp.files.tracking_to_correct)
      ) {
        const trackingMinutes = this.props.trackingMinutes
        const minToMs = 60 * 1000
        const trackingInMs = trackingMinutes * minToMs
        const videoLengthInMs = vp.video.tracking_length || 0
        if (trackingMinutes !== -1 && trackingInMs < videoLengthInMs) {
          this.props.openUpgradeModal('notEnoughMinutes')
          return
        }
      }

      await jobsService.start(id, jobType, payload)

      if (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back) {
        this.mainPolling.start(JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
        amplitude.logEvent(AnalyticsEvents.AUTO_AUTOMATED_TRACKING_DATA_SUBMITTED)
      } else if (jobType === JOB_TYPES.EVENTS_DETECTION.back) {
        this.mainPolling.start(JOB_TYPES.EVENTS_DETECTION.back)
        amplitude.logEvent(AnalyticsEvents.AUTO_EVENTS_DETECTION_SUBMITTED)
      } else if (jobType === JOB_TYPES.SURFACES.back) {
        this.mainPolling.start(JOB_TYPES.SURFACES.back)
        amplitude.logEvent(AnalyticsEvents.AUTO_SURFACES_SUBMITTED)
      } else if (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back) {
        this.mainPolling.start(JOB_TYPES.TRACKING_FIFA_FORMAT.back)
        amplitude.logEvent(AnalyticsEvents.AUTO_TRACKING_FIFA_FORMAT_SUBMITTED)
      }

      return Promise.resolve()
    } catch (error) {
      console.error(error)

      if (jobType === JOB_TYPES.AUTOMATED_TRACKING_DATA.back) {
        this.props.failedJobItem(id, JOB_TYPES.AUTOMATED_TRACKING_DATA.front)
        toast.error(
          window.I18n.t('admin.jobFailed', {
            process: 'Automated Tracking Data',
            vpName: this.vpName
          })
        )
      } else if (jobType === JOB_TYPES.EVENTS_DETECTION.back) {
        this.props.failedJobItem(id, JOB_TYPES.EVENTS_DETECTION.front)
        toast.error(window.I18n.t('admin.jobFailed', { process: 'Event', vpName: this.vpName }))
      } else if (jobType === JOB_TYPES.SURFACES.back) {
        this.props.failedJobItem(id, JOB_TYPES.SURFACES.front)
        toast.error(window.I18n.t('admin.jobFailed', { process: 'Surface', vpName: this.vpName }))
      } else if (jobType === JOB_TYPES.TRACKING_FIFA_FORMAT.back) {
        this.props.failedJobItem(id, JOB_TYPES.TRACKING_FIFA_FORMAT.front)
        toast.error(
          window.I18n.t('admin.jobFailed', { process: 'Tracking FIFA format', vpName: this.vpName })
        )
      }

      this.mainPolling.tearDown()
    }
  }

  downloadVideo = (id, fileName) => async () => {
    try {
      this.handleMenuClose()
      this.setState({ downloadingVideo: true })
      const {
        data: { url: signedUrl }
      } = await gameDatasetsService.getGameDatasetFileSignedURL(id, 'video')
      downloadFile(signedUrl, fileName)
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    } finally {
      this.setState({ downloadingVideo: false })
    }
  }

  deleteVideo = vp => async () => {
    try {
      this.handleMenuClose()
      this.setState({ deletingVideo: true })
      await gameDatasetsService.removeFileAndUpdateGameDataset(vp._id, 'video')

      if (vp.files[JOB_TYPES.TRACKING.file]) {
        await gameDatasetsService.removeFileAndUpdateGameDataset(vp._id, JOB_TYPES.TRACKING.file)
        this.props.editJobItem(
          {
            [vp._id]: { status: null, progress: null }
          },
          JOB_TYPES.TRACKING.front
        )
      }

      for (const homographyFile of JOB_TYPES.HOMOGRAPHY.files) {
        if (vp.files[homographyFile]) {
          await gameDatasetsService.removeFileAndUpdateGameDataset(vp._id, homographyFile)
          this.props.editJobItem(
            {
              [vp._id]: { status: null, progress: null }
            },
            JOB_TYPES.HOMOGRAPHY.front
          )
        }
      }

      await this.props.updateTableItem(vp._id)
      const fileName = vp.files.video.split('/').pop()
      toast.success(
        window.I18n.t('admin.fileDeletedSuccessfully', { fileName, vpName: this.vpName })
      )
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    } finally {
      this.setState({ deletingVideo: false })
    }
  }

  deleteSurface = vp => async name => {
    try {
      this.handleMenuClose()

      await gameDatasetsService.removeFileAndUpdateGameDataset(vp._id, `surface_${name}`)
      await this.props.updateTableItem(vp._id)

      toast.success(
        window.I18n.t('admin.fileDeletedSuccessfully', { fileName: name, vpName: this.vpName })
      )
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  deletePatterns = vp => async () => {
    try {
      this.handleMenuClose()
      this.setState({ deletingPatterns: true })

      await gameDatasetsService.removeFileAndUpdateGameDataset(vp._id, 'custom_patterns')
      await this.props.updateTableItem(vp._id)

      const fileName = vp.files.custom_patterns.split('/').pop()
      toast.success(
        window.I18n.t('admin.fileDeletedSuccessfully', { fileName, vpName: this.vpName })
      )
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    } finally {
      this.setState({ deletingPatterns: false })
    }
  }

  cancelProcesses = vp => async () => {
    try {
      const vpId = vp._id
      const isSynced = vp.data_type === GAMES_DATASET_DATA_TYPE.SYNCED
      const isAtd = vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA
      const isCorrected = vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED
      this.handleMenuClose()

      if (
        (isAtd || isSynced || isCorrected) &&
        (this.isTrackingRunning() ||
          this.isFieldRecognitionRunning() ||
          this.isBallTrackingRunning() ||
          this.isAtdRunning())
      ) {
        this.mainPolling.cancel([
          JOB_TYPES.AUTOMATED_TRACKING_DATA.back,
          JOB_TYPES.TRACKING.back,
          JOB_TYPES.HOMOGRAPHY.back,
          JOB_TYPES.BALL_TRACKING.back
        ])
        this.mainPolling.tearDown()

        await jobsService.stop(vpId, JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
        amplitude.logEvent(AnalyticsEvents.AUTO_AUTOMATED_TRACKING_DATA_STOPPED)
        toast.info(window.I18n.t('admin.cancelATDProcessSuccessfully', { vpName: this.vpName }))
      }
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  cancelDataIntegration = async (vpId, dataIntegrationType) => {
    try {
      if (this.isDataIntegrationRunning()) {
        const jobType = JOB_TYPES.DATA_INTEGRATION.back
        const payload = { provider: dataIntegrationType }
        this.mainPolling.cancel([jobType])
        this.mainPolling.tearDown()
        await jobsService.stop(vpId, jobType, payload)
        const analyticsEvent =
          (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB &&
            AnalyticsEvents.AUTO_TRACAB_STOPPED) ||
          (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB_DFL &&
            AnalyticsEvents.AUTO_TRACAB_DFL_STOPPED) ||
          (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM &&
            AnalyticsEvents.AUTO_SECOND_SPECTRUM_STOPPED) ||
          (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.STATS &&
            AnalyticsEvents.AUTO_STATS_STOPPED) ||
          (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.FIFA &&
            AnalyticsEvents.AUTO_FIFA_STOPPED)
        amplitude.logEvent(analyticsEvent)
      }

      await this.props.updateTableItem(vpId)
      toast.info(
        window.I18n.t('admin.cancelDataIntegrationProcessSuccessfully', { vpName: this.vpName })
      )
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  cancelEventsDetection = async vpId => {
    try {
      if (this.isEventsDetectionRunning()) {
        const jobType = JOB_TYPES.EVENTS_DETECTION.back
        this.mainPolling.cancel([jobType])
        this.mainPolling.tearDown()
        await jobsService.stop(vpId, jobType)
        amplitude.logEvent(AnalyticsEvents.AUTO_EVENTS_DETECTION_STOPPED)
      }

      await this.props.updateTableItem(vpId)
      toast.info(window.I18n.t('admin.cancelEventsProcessSuccessfully', { vpName: this.vpName }))
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  cancelSurface = async vpId => {
    try {
      if (this.isSurfaceRunning()) {
        const jobType = JOB_TYPES.SURFACES.back
        this.mainPolling.cancel([jobType])
        this.mainPolling.tearDown()
        await jobsService.stop(vpId, jobType)
        amplitude.logEvent(AnalyticsEvents.AUTO_SURFACES_STOPPED)
      }

      await this.props.updateTableItem(vpId)
      toast.info(window.I18n.t('admin.cancelSurfacesProcessSuccessfully', { vpName: this.vpName }))
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  cancelTrackingFifaFormat = async vpId => {
    try {
      if (this.isTrackingFifaFormatRunning()) {
        const jobType = JOB_TYPES.TRACKING_FIFA_FORMAT.back
        this.mainPolling.cancel([jobType])
        this.mainPolling.tearDown()
        await jobsService.stop(vpId, jobType)
        amplitude.logEvent(AnalyticsEvents.AUTO_TRACKING_FIFA_FORMAT_STOPPED)
      }

      await this.props.updateTableItem(vpId)
      toast.info(
        window.I18n.t('admin.cancelTrackingFifaFormatProcessSuccessfully', { vpName: this.vpName })
      )
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: this.vpName }))
    }
  }

  isTrackingFifaFormatAvailable = row => {
    // Condition for starting FIFA EPS process
    return Boolean(
      this.state.hasWritePermission &&
        row.files[JOB_TYPES.TRACKING.file] &&
        !this.isTrackingRunning() &&
        !this.state.deletingVp &&
        row.data_type !== GAMES_DATASET_DATA_TYPE.SYNCED &&
        row.files[JOB_TYPES.DATA_INTEGRATION.file] &&
        !row.forked_from
    )
  }

  isAnyJobRunning = () => {
    return Boolean(
      this.isTrackingRunning() ||
        this.isFieldRecognitionRunning() ||
        this.isDataIntegrationRunning() ||
        this.isAtdRunning() ||
        this.isSurfaceRunning() ||
        this.isTrackingFifaFormatRunning()
    )
  }

  isATDJobsRunning = () => {
    return Boolean(
      this.isTrackingRunning() ||
        this.isFieldRecognitionRunning() ||
        this.isAtdRunning() ||
        this.isBallTrackingRunning()
    )
  }

  isTrackingRunning = () => {
    const vp = this.props.row
    const trackingJob = this.props.trackingJobRow
    const hasTrackingJob = !vp.files[JOB_TYPES.TRACKING.file] && trackingJob
    const trackingJobFinished =
      hasTrackingJob &&
      (trackingJob.status === Status.COMPLETE ||
        trackingJob.status === Status.FAILED ||
        trackingJob.status === Status.CANCEL)

    return hasTrackingJob && !trackingJobFinished
  }

  isBallTrackingRunning = () => {
    const vp = this.props.row
    const ballTrackingJob = this.props.ballTrackingJobRow
    const hasBallTrackingJob = !vp.files[JOB_TYPES.BALL_TRACKING.file] && ballTrackingJob
    const ballTrackingJobFinished = ballTrackingJob
      ? ballTrackingJob.status === Status.COMPLETE ||
        ballTrackingJob.status === Status.FAILED ||
        ballTrackingJob.status === Status.CANCEL
      : true

    return hasBallTrackingJob && !ballTrackingJobFinished
  }

  isFieldRecognitionRunning = () => {
    const vp = this.props.row
    const fieldRecognitionJob = this.props.fieldRecognitionJobRow
    const hasFieldRecognitionJob =
      !JOB_TYPES.HOMOGRAPHY.files.some(v => vp.files[v]) && fieldRecognitionJob
    const fieldRecognitionJobFinished =
      hasFieldRecognitionJob &&
      (fieldRecognitionJob.status === Status.COMPLETE ||
        fieldRecognitionJob.status === Status.FAILED ||
        fieldRecognitionJob.status === Status.CANCEL)

    return hasFieldRecognitionJob && !fieldRecognitionJobFinished
  }

  isAtdRunning = () => {
    const vp = this.props.row
    const automatedTrackingDataJob = this.props.automatedTrackingDataJobRow
    const hasAutomatedTrackingDataJob =
      (vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA ||
        vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED) &&
      !vp.files[JOB_TYPES.AUTOMATED_TRACKING_DATA.file] &&
      automatedTrackingDataJob
    const automatedTrackingDataJobFinished =
      hasAutomatedTrackingDataJob &&
      (automatedTrackingDataJob.status === Status.COMPLETE ||
        automatedTrackingDataJob.status === Status.FAILED ||
        automatedTrackingDataJob.status === Status.CANCEL)

    return hasAutomatedTrackingDataJob && !automatedTrackingDataJobFinished
  }

  isEventsDetectionRunning = () => {
    const vp = this.props.row
    if (!vp.compute_events) {
      return false
    }
    const eventsDetectionJob = this.props.eventsDetectionJobRow
    const eventsDetectionJobFinished =
      eventsDetectionJob &&
      (eventsDetectionJob.status === Status.COMPLETE ||
        eventsDetectionJob.status === Status.FAILED ||
        eventsDetectionJob.status === Status.CANCEL)

    return eventsDetectionJob && !eventsDetectionJobFinished
  }

  isSurfaceRunning = () => {
    const vp = this.props.row
    if (!vp.compute_surfaces) {
      return false
    }
    const surfaceJob = this.props.surfaceJobRow
    const surfaceJobFinished =
      surfaceJob &&
      (surfaceJob.status === Status.COMPLETE ||
        surfaceJob.status === Status.FAILED ||
        surfaceJob.status === Status.CANCEL)

    return surfaceJob && !surfaceJobFinished
  }

  isDataIntegrationRunning = () => {
    const vp = this.props.row
    const isSynced = vp.data_type === GAMES_DATASET_DATA_TYPE.SYNCED
    const dataIntegrationJob = this.props.dataIntegrationJobRow
    const dataIntegrationJobFinished = dataIntegrationJob
      ? dataIntegrationJob.status === Status.COMPLETE ||
        dataIntegrationJob.status === Status.FAILED ||
        dataIntegrationJob.status === Status.CANCEL
      : true

    return isSynced && !dataIntegrationJobFinished
  }

  isDataIntegrationWaiting = () => {
    const dataIntegrationJob = this.props.dataIntegrationJobRow
    return (
      dataIntegrationJob &&
      (dataIntegrationJob.status === Status.QUEUED_LOCALLY ||
        dataIntegrationJob.status === Status.QUEUED) &&
      (this.isTrackingRunning() || this.isFieldRecognitionRunning())
    )
  }

  isTrackingFifaFormatRunning = () => {
    const trackingFifaFormatJob = this.props.trackingFifaFormatJobRow
    const trackingFifaFormatJobFinished = trackingFifaFormatJob
      ? trackingFifaFormatJob.status === Status.COMPLETE ||
        trackingFifaFormatJob.status === Status.FAILED ||
        trackingFifaFormatJob.status === Status.CANCEL
      : true
    return trackingFifaFormatJob && !trackingFifaFormatJobFinished
  }

  isDeleteVideoAvailable = row => {
    return Boolean(
      this.state.hasWritePermission &&
        row.files.video &&
        !this.state.deletingVideo &&
        !this.state.deletingVp &&
        !this.isTrackingRunning() &&
        !this.isFieldRecognitionRunning() &&
        !this.isDataIntegrationRunning() &&
        !row.forked_from
    )
  }

  isDeletePatternsAvailable = row => {
    return Boolean(
      row.files.custom_patterns && !this.state.deletingPatterns && !this.state.deletingVp
    )
  }

  isEditVideoProjectAvailable = () => {
    return this.state.hasWritePermission && !this.state.deletingVp
  }

  isDeleteVideoProjectAvailable = () => {
    return (
      !this.state.deletingVp &&
      (this.props.isMetricaSportsUser || (this.state.hasWritePermission && !this.isAnyJobRunning()))
    )
  }

  isCancelProcessesAvailable = () => {
    return Boolean(
      this.props.row.files.video &&
        (this.isTrackingRunning() ||
          this.isFieldRecognitionRunning() ||
          this.isBallTrackingRunning() ||
          this.isAtdRunning())
    )
  }

  errorFieldRecognitionLinkAvailable = () => {
    return !this.state.deletingVideo && !this.state.deletingVp
  }

  isDownloadVideoAvailable = row => {
    return Boolean(row.files.video && !this.state.downloadingVideo && !this.state.deletingVideo)
  }

  isCancelVideoUploadAvailable = () => {
    return this.state.uploadingVideo
  }

  isUploadVideoAvailable = row => {
    return (
      !row.files.video &&
      !this.state.deletingVp &&
      !this.state.uploadingVideo &&
      this.state.hasWritePermission
    )
  }

  isUploadPatternsAvailable = row => {
    return (
      !row.files.custom_patterns &&
      !this.state.deletingVp &&
      !this.state.uploadingPatterns &&
      this.state.hasWritePermission
    )
  }

  errorTrackingLinkAvailable = () => {
    return !this.state.deletingVideo && !this.state.deletingVp
  }

  trackingFifaFormatFileUploaded = vp => {
    return !!vp.files[JOB_TYPES.TRACKING_FIFA_FORMAT.file]
  }

  playlistFileUploaded = vp => play3 => {
    return !!(play3 ? vp.files.playlist3 : vp.files.playlist)
  }

  videoMultiPartUI = (row, percentage) => {
    if (this.state.cancelingVideoUpload) {
      return <span css={styles.greyRowText}>{window.I18n.t('common.canceling')}</span>
    }

    if (typeof percentage === 'number' && percentage === 0 && this.state.uploadingVideo) {
      return <span css={styles.greyRowText}>{window.I18n.t('admin.initializing')}</span>
    }

    if (typeof percentage === 'number' && percentage > 0 && this.state.uploadingVideo) {
      return (
        <Progress
          percent={percentage.toFixed(0)}
          strokeWidth={16}
          trailWidth={16}
          strokeColor={theme.palette.common.blue}
          trailColor={theme.palette.common.blue2}
          style={styles.progressBar}
        />
      )
    }

    if (row.files.video) {
      const fileName = row.files.video.split('/').pop()
      return (
        <Tooltip title={fileName} placement="top-start">
          <div css={styles.videoTooltipContent}>
            <img
              src={row.is_playlist ? clipFileLogo : videoFileLogo}
              alt="video file"
              width={18}
              css={css`
                margin-right: 5px;
                ${row.is_playlist && 'margin-left: 3px;'}
              `}
            />
            <span
              css={css`
                color: ${theme.palette.secondary.dark};
              `}
            >
              {this.formatDate(row.lastVideoUpdate)}
            </span>
          </div>
        </Tooltip>
      )
    }

    if (this.state.uploadingVideo) {
      return <span css={styles.greyRowText}>{window.I18n.t('common.uploading')}</span>
    }

    return (
      <span
        css={css`
          ${styles.downloadVideoLink};
          ${this.isUploadVideoAvailable(row) ? null : noMenuClicks};
        `}
        onClick={this.uploadVideo}
      >
        {window.I18n.t('admin.readyToUpload')}
      </span>
    )
  }

  makeResult = vp => {
    const homeResult = _.get(vp, 'game.result.home')
    const awayResult = _.get(vp, 'game.result.away')
    const home = typeof homeResult === 'number' ? vp.game.result.home : '--'
    const away = typeof awayResult === 'number' ? vp.game.result.away : '--'
    return `${home} : ${away}`
  }

  isVpCreatedInCloud = vp => {
    return vp.data_type !== GAMES_DATASET_DATA_TYPE.ELITE
  }

  processAutomatedTrackingDataJob = async () => {
    try {
      this.handleMenuClose()

      const id = this.props.row._id
      await this.startJob(id, JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
      await this.props.updateTableItem(id)
    } catch (e) {
      console.log(e)
    }
  }

  processEventsDetectionJob = async () => {
    try {
      this.handleMenuClose()

      const id = this.props.row._id
      await this.startJob(id, JOB_TYPES.EVENTS_DETECTION.back, { computeEvents: true })
      await this.props.updateTableItem(id)
    } catch (e) {
      console.log(e)
    }
  }

  processSurfaceJob = async () => {
    try {
      this.handleMenuClose()

      const id = this.props.row._id
      await this.startJob(id, JOB_TYPES.SURFACES.back, { computeSurfaces: true })
      await this.props.updateTableItem(id)
    } catch (e) {
      console.log(e)
    }
  }

  startTrackingFifaFormatJob = async () => {
    try {
      this.handleMenuClose()

      const id = this.props.row._id
      await this.startJob(id, JOB_TYPES.TRACKING_FIFA_FORMAT.back)
      await this.props.updateTableItem(id)
    } catch (e) {
      console.log(e)
    }
  }

  isProcessAtdAvailable = row => {
    return Boolean(
      this.props.isMetricaSportsUser ||
        (this.state.hasWritePermission &&
          row.files.video &&
          !row.files.automated_tracking_data &&
          !this.state.deletingVideo &&
          !this.state.deletingVp &&
          !this.isAtdRunning() &&
          !this.isDataIntegrationRunning() &&
          row.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA &&
          !row.forked_from)
    )
  }

  isProcessCorrectionAvailable = row => {
    return Boolean(
      this.props.isMetricaSportsUser ||
        (this.state.hasWritePermission &&
          row.files.video &&
          !row.files.automated_tracking_data &&
          !this.state.deletingVideo &&
          !this.state.deletingVp &&
          !this.isAtdRunning() &&
          !this.isDataIntegrationRunning() &&
          row.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED &&
          !row.forked_from)
    )
  }

  isProcessEventsDetectionAvailable = row => {
    const isGvp = !row.game.description
    const atdJob = this.props.automatedTrackingDataJobRow
    const fifaJob = this.props.trackingFifaFormatJobRow
    const isAtdStarted =
      atdJob &&
      [Status.STARTING, Status.QUEUED, Status.QUEUED_LOCALLY, Status.ACTIVE].includes(atdJob.status)
    const isAtdDone =
      row.files[JOB_TYPES.AUTOMATED_TRACKING_DATA.file] ||
      (row.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED &&
        row.is_corrected &&
        fifaJob &&
        fifaJob.status === Status.COMPLETE)

    return Boolean(
      (isAtdStarted || isAtdDone) &&
        isGvp &&
        !row.is_playlist &&
        row.is_football_side_view &&
        (this.props.isMetricaSportsUser ||
          (this.state.hasWritePermission &&
            row.files.video &&
            !this.state.deletingVideo &&
            !this.state.deletingVp &&
            row.data_type !== GAMES_DATASET_DATA_TYPE.AUTOMATIC &&
            !row.forked_from))
    )
  }

  isProcessSurfaceAvailable = row => {
    const atdJob = this.props.automatedTrackingDataJobRow
    const fifaJob = this.props.trackingFifaFormatJobRow
    const isAtdStarted =
      atdJob &&
      [Status.STARTING, Status.QUEUED, Status.QUEUED_LOCALLY, Status.ACTIVE].includes(atdJob.status)
    const isAtdDone =
      row.files[JOB_TYPES.AUTOMATED_TRACKING_DATA.file] ||
      (row.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED &&
        row.is_corrected &&
        fifaJob &&
        fifaJob.status === Status.COMPLETE)

    return Boolean(
      (isAtdStarted || isAtdDone) &&
        row.is_football_side_view &&
        (this.props.isMetricaSportsUser ||
          (this.state.hasWritePermission &&
            row.files.video &&
            !this.state.deletingVideo &&
            !this.state.deletingVp &&
            row.data_type !== GAMES_DATASET_DATA_TYPE.AUTOMATIC &&
            !row.forked_from))
    )
  }

  isDataIntegrationJobAvailable = row => {
    if (!this.state.hasWritePermission || !row.is_football_side_view) {
      return false
    }

    if (
      row.data_type === GAMES_DATASET_DATA_TYPE.ELITE ||
      row.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED
    ) {
      return false
    }

    const commonCase =
      row.files.video && !this.state.deletingVideo && !this.state.deletingVp && !row.forked_from
    if (!commonCase) {
      return false
    }

    return Boolean(
      (row.files[JOB_TYPES.TRACKING.file] || this.isTrackingRunning()) &&
        (JOB_TYPES.HOMOGRAPHY.files.some(v => row.files[v]) || this.isFieldRecognitionRunning())
    )
  }

  startDataIntegrationJob = async dataIntegrationType => {
    const id = this.props.row._id
    try {
      this.handleMenuClose()
      const msg =
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.STATS &&
          window.I18n.t('admin.startStatsMessage', { vpName: this.vpName })) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB &&
          window.I18n.t('admin.startTracabMessage', { vpName: this.vpName })) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB_DFL &&
          window.I18n.t('admin.startTracabDflMessage', { vpName: this.vpName })) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.FIFA &&
          window.I18n.t('admin.startFifaMessage', { vpName: this.vpName })) ||
        window.I18n.t('admin.startSsMessage', { vpName: this.vpName })
      toast.info(msg)
      const jobType = JOB_TYPES.DATA_INTEGRATION.back
      const payload = { provider: dataIntegrationType }
      await jobsService.start(id, jobType, payload)
      this.mainPolling.start(jobType)
      const analyticsEvent =
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB &&
          AnalyticsEvents.AUTO_TRACAB_SUBMITTED) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.TRACAB_DFL &&
          AnalyticsEvents.AUTO_TRACAB_DFL_SUBMITTED) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM &&
          AnalyticsEvents.AUTO_SECOND_SPECTRUM_SUBMITTED) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.STATS &&
          AnalyticsEvents.AUTO_STATS_SUBMITTED) ||
        (dataIntegrationType === DATA_INTEGRATION_PROVIDERS.FIFA &&
          AnalyticsEvents.AUTO_FIFA_SUBMITTED)
      amplitude.logEvent(analyticsEvent)
      return Promise.resolve()
    } catch (error) {
      console.error(error)
      this.props.failedJobItem(id, JOB_TYPES.DATA_INTEGRATION.front)
      toast.error(window.I18n.t('admin.dataIntegrationFailed', { vpName: this.vpName }))
    }
  }

  openDataIntegration = () => {
    this.handleMenuClose()
    this.props.openDataIntegration(this.props.row)
  }

  downloadFifaFormatFile = vp => async () => {
    const {
      data: { url: signedUrl }
    } = await gameDatasetsService.getGameDatasetFileSignedURL(vp._id, 'tracking_fifa_format')
    downloadFile(signedUrl, `${this.vpName} FIFA format`)
    this.handleMenuClose()
  }

  downloadPlaylistFile = vp => async play3 => {
    const {
      data: { url: signedUrl }
    } = await gameDatasetsService.getGameDatasetFileSignedURL(
      vp._id,
      play3 ? 'playlist3' : 'playlist'
    )
    downloadFile(signedUrl, this.vpName)
    this.handleMenuClose()
  }

  downloadAtdFile = vp => async () => {
    const {
      data: { url: signedUrl }
    } = await gameDatasetsService.getGameDatasetFileSignedURL(vp._id, 'automated_tracking_data')
    downloadFile(signedUrl, `${this.vpName} - Automated Tracking Data`)
    this.handleMenuClose()
  }

  render() {
    const { row } = this.props
    const isVpCreatedInCloud = this.isVpCreatedInCloud(row)
    row.game = row.game || {}
    const showLoader = this.isAnyJobRunning() && !this.state.isHoverOnMoreOptions

    return (
      <CustomTableRow key={row._id} selected={Boolean(this.state.anchorMenuEl)}>
        {this.state.uncaughtError ? (
          <UncaughtErrorRow deleteVideoProject={this.deleteVideoProject} rowItem={this.props.row} />
        ) : (
          <Fragment>
            <DateCell align="left">{this.formatDate(row.game.date)}</DateCell>
            <CompetitionCell align="left">{_makeName(row.game.competition)}</CompetitionCell>
            <DescriptionCell align="left">
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                {row.isNew && <span css={styles.newLabel}>{window.I18n.t('common.new')}</span>}
                {row.game.description ? (
                  row.game.description
                ) : (
                  <div>
                    {_makeName(row.game.home_team)}
                    <span
                      css={css`
                        margin: 0 15px;
                      `}
                    >
                      {this.makeResult(row)}
                    </span>
                    {_makeName(row.game.away_team)}
                  </div>
                )}
              </div>
            </DescriptionCell>
            <PlaceCell align="left">{_makeName(row.game.stadium)}</PlaceCell>
            <UploadCell align="left">
              {this.state.deletingVp || this.state.deletingVideo ? (
                <div
                  css={css`
                    position: relative;
                    top: 5px;
                    left: 35%;
                    color: ${theme.palette.primary.dark};
                  `}
                >
                  <CircularProgress size={19} thickness={4} />
                </div>
              ) : (
                <ChangePercentage
                  key={row._id}
                  render={({ percentage, updatePercentage }) => (
                    <Fragment>
                      {this.videoMultiPartUI(row, percentage)}
                      <MultipartHiddenUploadForm
                        ref={this.multiPartHiddenVideoUploadFormRef}
                        onProgress={this.multiPartHiddenVideoUploadFormOnProgress(updatePercentage)}
                        onFinish={this.multiPartHiddenVideoUploadFormOnFinish(
                          updatePercentage,
                          row._id
                        )}
                        onError={this.multiPartHiddenVideoUploadFormOnError(updatePercentage)}
                        onStart={this.multiPartHiddenVideoUploadFormOnStart}
                        vpId={row._id}
                        resource="video"
                        gameDatasetCode={row.code}
                        accept={videoFormats}
                      />
                    </Fragment>
                  )}
                />
              )}
            </UploadCell>
            <TrackingCell align="center">
              <AutomatedTrackingCol
                vp={row}
                isMetricaSportsUser={this.props.isMetricaSportsUser}
                preProcessingJob={this.props.preProcessingJobRow}
                trackingJob={this.props.trackingJobRow}
                fieldRecognitionJob={this.props.fieldRecognitionJobRow}
                ballTrackingJob={this.props.ballTrackingJobRow}
                automatedTrackingDataJob={this.props.automatedTrackingDataJobRow}
                dataIntegrationJob={this.props.dataIntegrationJobRow}
                errorTrackingLinkAvailable={this.errorTrackingLinkAvailable}
                isVpCreatedInCloud={isVpCreatedInCloud}
                deletingVp={this.state.deletingVp}
                uploadingVideo={this.state.uploadingVideo}
                processAutomatedTrackingData={this.processAutomatedTrackingDataJob}
                isProcessAtdAvailable={this.isProcessAtdAvailable(row)}
              />
            </TrackingCell>
            <MenuCell align="left">
              {/* Upload surface file */}
              <MultipartHiddenUploadForm
                ref={this.multiPartHiddenSurfaceUploadFormRef}
                beforeUpload={this.beforeUploadSurface}
                onProgress={this.multiPartHiddenSurfaceUploadFormOnProgress}
                onFinish={this.multiPartHiddenSurfaceUploadFormOnFinish(row._id)}
                onError={this.multiPartHiddenSurfaceUploadFormOnError}
                onStart={this.multiPartHiddenSurfaceUploadFormOnStart}
                vpId={row._id}
                gameDatasetCode={row.code}
                resource="surface_"
                accept=".json"
              />
              {/* Upload pattern file */}
              <FileHiddenUploadForm
                ref={el => {
                  this.uploadPatternsInputEl = el
                }}
                vpId={row._id}
                suffix="Patterns"
                resource={'custom_patterns'}
                accept=".patt"
                onFinish={this.hiddenUploadFormOnFinish}
                onError={this.hiddenUploadFormOnError}
                onStart={this.hiddenUploadFormOnStart}
              />
              {showLoader ? (
                <div
                  css={styles.circularProgressWithHover}
                  onMouseEnter={this.hoverInMoreOptions}
                  onMouseLeave={this.hoverOutMoreOptions}
                >
                  <CircularProgress size={19} thickness={4} />
                </div>
              ) : (
                <div css={styles.moreButonWrapper} onMouseLeave={this.hoverOutMoreOptions}>
                  <IconButton
                    aria-owns={this.state.anchorMenuEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  {/* Start Menu */}
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorMenuEl}
                    open={Boolean(this.state.anchorMenuEl)}
                    onClose={this.handleMenuClose}
                    width={'300px'}
                    disableAutoFocusItem
                  >
                    <VideoMenuItem
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      vp={row}
                      uploadVideo={this.uploadVideo}
                      isUploadVideoAvailable={this.isUploadVideoAvailable(row)}
                      cancelVideoUpload={this.cancelVideoUpload}
                      isCancelVideoUploadAvailable={this.isCancelVideoUploadAvailable()}
                      downloadVideo={this.downloadVideo}
                      isDownloadVideoAvailable={this.isDownloadVideoAvailable(row)}
                      deleteVideo={this.deleteVideo}
                      isDeleteVideoAvailable={this.isDeleteVideoAvailable(row)}
                    />
                    <AutomatedTrackingDataMenuItem
                      vp={row}
                      isMetricaSportsUser={this.props.isMetricaSportsUser}
                      trackingJob={this.props.trackingJobRow}
                      automatedTrackingDataJob={this.props.automatedTrackingDataJobRow}
                      cancelProcesses={this.cancelProcesses}
                      errorTrackingLinkAvailable={this.errorTrackingLinkAvailable()}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      uploadingVideo={this.state.uploadingVideo}
                      processAutomatedTrackingData={this.processAutomatedTrackingDataJob}
                      isAnyJobRunning={this.isATDJobsRunning}
                      downloadAtdFile={this.downloadAtdFile(row)}
                      isProcessAtdAvailable={this.isProcessAtdAvailable(row)}
                    />
                    <TrackingMenuItem
                      vp={row}
                      trackingJob={this.props.trackingJobRow}
                      errorTrackingLinkAvailable={this.errorTrackingLinkAvailable()}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      uploadingVideo={this.state.uploadingVideo}
                      processPlayerTracking={this.processPlayerTracking}
                    />
                    <FieldRecognitionMenuItem
                      vp={row}
                      fieldRecognitionJob={this.props.fieldRecognitionJobRow}
                      errorFieldRecognitionLinkAvailable={this.errorFieldRecognitionLinkAvailable()}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      uploadingVideo={this.state.uploadingVideo}
                    />
                    <BallTrackingMenuItem
                      vp={row}
                      ballTrackingJob={this.props.ballTrackingJobRow}
                      errorTrackingLinkAvailable={this.errorTrackingLinkAvailable()}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                    />
                    <CorrectionMenuItem
                      vp={row}
                      isMetricaSportsUser={this.props.isMetricaSportsUser}
                      trackingJob={this.props.trackingJobRow}
                      automatedTrackingDataJob={this.props.automatedTrackingDataJobRow}
                      trackingFifaFormatJob={this.props.trackingFifaFormatJobRow}
                      cancelProcesses={this.cancelProcesses}
                      errorTrackingLinkAvailable={this.errorTrackingLinkAvailable()}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      uploadingVideo={this.state.uploadingVideo}
                      processAutomatedTrackingData={this.processAutomatedTrackingDataJob}
                      isAnyJobRunning={this.isATDJobsRunning}
                      downloadAtdFile={this.downloadAtdFile(row)}
                      isProcessCorrectionAvailable={this.isProcessCorrectionAvailable(row)}
                    />
                    <EventsDetectionMenuItem
                      vp={row}
                      eventsDetectionJob={this.props.eventsDetectionJobRow}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      isEventsDetectionRunning={this.isEventsDetectionRunning()}
                      isProcessEventsDetectionAvailable={this.isProcessEventsDetectionAvailable(
                        row
                      )}
                      processEventsDetection={this.processEventsDetectionJob}
                      cancelEventsDetection={this.cancelEventsDetection}
                    />
                    <SurfaceMenuItem
                      vp={row}
                      uploadSurface={this.uploadSurface}
                      uploadingSurface={this.state.uploadingSurface}
                      isSurfaceUploadAvailable={this.props.canUploadSurface}
                      deletingVp={this.state.deletingVp}
                    />
                    <PitchControlMenuItem
                      vp={row}
                      surfaceJob={this.props.surfaceJobRow}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      deletingVp={this.state.deletingVp}
                      isSurfaceRunning={this.isSurfaceRunning()}
                      isProcessSurfaceAvailable={this.isProcessSurfaceAvailable(row)}
                      processSurface={this.processSurfaceJob}
                      cancelSurface={this.cancelSurface}
                    />
                    <SurfacesMenuItem
                      vp={row}
                      deletingVp={this.state.deletingVp}
                      deleteSurface={this.deleteSurface}
                    />
                    <DataIntegrationMenuItem
                      vp={row}
                      isMetricaSportsUser={this.props.isMetricaSportsUser}
                      openDataIntegration={this.openDataIntegration}
                      dataIntegrationJobRow={this.props.dataIntegrationJobRow}
                      isDataIntegrationJobAvailable={this.isDataIntegrationJobAvailable(row)}
                      isDataIntegrationRunning={this.isDataIntegrationRunning()}
                      isDataIntegrationWaiting={this.isDataIntegrationWaiting()}
                      startDataIntegrationJob={this.startDataIntegrationJob}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      cancelDataIntegration={this.cancelDataIntegration}
                      hasLicence={this.props.hasDataIntegrationLicence}
                    />
                    <PatternsMenuItem
                      vp={row}
                      uploadPatterns={this.uploadPatterns}
                      uploadingPatterns={this.state.uploadingPatterns}
                      isUploadPatternsAvailable={this.isUploadPatternsAvailable(row)}
                      deletePatterns={this.deletePatterns}
                      isDeletePatternsAvailable={this.isDeletePatternsAvailable(row)}
                    />
                    <TrackingFifaFormatMenuItem
                      vp={row}
                      isMetricaSportsUser={this.props.isMetricaSportsUser}
                      trackingFifaFormatFileUploaded={this.trackingFifaFormatFileUploaded(row)}
                      isTrackingFifaFormatRunning={this.isTrackingFifaFormatRunning()}
                      startTrackingFifaFormatJob={this.startTrackingFifaFormatJob}
                      isTrackingFifaFormatAvailable={this.isTrackingFifaFormatAvailable(row)}
                      downloadFifaFormatFile={this.downloadFifaFormatFile(row)}
                      trackingFifaFormatJob={this.props.trackingFifaFormatJobRow}
                      cancelTrackingFifaFormat={this.cancelTrackingFifaFormat}
                    />
                    {row.is_playlist && (
                      <PlaylistMenuItem
                        vp={row}
                        isMetricaSportsUser={this.props.isMetricaSportsUser}
                        playlistFileUploaded={this.playlistFileUploaded(row)}
                        downloadPlaylistFile={this.downloadPlaylistFile(row)}
                      />
                    )}
                    <VideoProjectMenuItem
                      vp={row}
                      isMetricaSportsUser={this.props.isMetricaSportsUser}
                      isVpCreatedInCloud={isVpCreatedInCloud}
                      isEditVideoProjectAvailable={this.isEditVideoProjectAvailable()}
                      isDeleteVideoProjectAvailable={this.isDeleteVideoProjectAvailable()}
                      startProcessingWaitingJobs={this.startProcessingWaitingJobs}
                      editVideoProject={this.editVideoProject}
                      deleteVideoProject={this.deleteVideoProject}
                    />
                  </Menu>
                  {/* End Menu */}
                </div>
              )}
            </MenuCell>
          </Fragment>
        )}
      </CustomTableRow>
    )
  }
}

function _makeName(nameObj) {
  return _.get(nameObj, 'name') || '--'
}

AdminTableRow.defaultProps = {
  preProcessingJobRow: undefined,
  trackingJobRow: undefined,
  fieldRecognitionJobRow: undefined,
  ballTrackingJobRow: undefined,
  automatedTrackingDataJobRow: undefined,
  dataIntegrationJobRow: undefined,
  eventsDetectionJobRow: undefined,
  surfaceJobRow: undefined,
  trackingFifaFormatJobRow: undefined
}

AdminTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  openDialogToEditVp: PropTypes.func.isRequired,
  deleteVp: PropTypes.func.isRequired,
  updateTableItem: PropTypes.func.isRequired,
  preProcessingJobRow: PropTypes.object,
  trackingJobRow: PropTypes.object,
  fieldRecognitionJobRow: PropTypes.object,
  ballTrackingJobRow: PropTypes.object,
  dataIntegrationJobRow: PropTypes.object,
  automatedTrackingDataJobRow: PropTypes.object,
  eventsDetectionJobRow: PropTypes.object,
  surfaceJobRow: PropTypes.object,
  trackingFifaFormatJobRow: PropTypes.object,
  editJobItem: PropTypes.func.isRequired,
  failedJobItem: PropTypes.func.isRequired,
  hasWritePermissionOnOrganization: PropTypes.func.isRequired,
  openDataIntegration: PropTypes.func.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  hasDataIntegrationLicence: PropTypes.bool.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  canUploadSurface: PropTypes.bool.isRequired,
  trackingMinutes: PropTypes.number.isRequired
}

export default AdminTableRow
