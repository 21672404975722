/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { theme, Status, GAMES_DATASET_DATA_TYPE, JOB_TYPES } from '../../../constants'
import Progress from '../../components/Progress'
import AutomatedTrackingDataJob from './AutomatedTrackingDataJob'

const styles = {}
styles.greyRowText = css`
  color: ${theme.palette.common.grey};
  text-transform: none;
  font-size: 12px;
`
styles.connectionFailed = css`
  color: ${theme.palette.error.main};
  text-transform: none;
  font-size: 12px;
`
styles.connectionFailedIcon = {
  color: theme.palette.error.main,
  marginRight: 5,
  fontSize: 22
}
styles.actionLink = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.common.blue};
  text-decoration: underline;
  cursor: pointer;
`
styles.actionError = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.error.main};
  text-decoration: underline;
  cursor: pointer;
`
styles.textError = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.error.main};
`
styles.percentLine = css`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  color: ${theme.palette.common.white};
`
styles.connectionFailedContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
styles.progressBar = {
  borderRadius: 2,
  width: '100%'
}
styles.iconSuccess = {
  color: theme.palette.common.successGreen
}
styles.disableButton = css`
  color: lightgrey;
  pointer-events: none;
  cursor: not-allowed;
`

function AutomatedTrackingCol(props) {
  const [progress, setProgress] = React.useState(0)

  const _getJobProgress = job => {
    return (job && job.progress) || (job && job.status === Status.COMPLETE && 1) || 0
  }

  React.useEffect(() => {
    const dataIntegrationStatus = props.dataIntegrationJob && props.dataIntegrationJob.status
    const postProcessingJob = dataIntegrationStatus
      ? props.dataIntegrationJob
      : props.is_football_side_view && props.automatedTrackingDataJob

    let currentProgress
    if (props.vp.compute_ball) {
      if (postProcessingJob) {
        currentProgress =
          _getJobProgress(props.trackingJob) * 0.25 +
          _getJobProgress(props.fieldRecognitionJob) * 0.15 +
          _getJobProgress(props.ballTrackingJob) * 0.5 +
          _getJobProgress(postProcessingJob) * 0.1
      } else {
        currentProgress =
          _getJobProgress(props.trackingJob) * 0.3 +
          _getJobProgress(props.fieldRecognitionJob) * 0.2 +
          _getJobProgress(props.ballTrackingJob) * 0.5
      }
    } else {
      if (postProcessingJob) {
        currentProgress =
          _getJobProgress(props.trackingJob) * 0.45 +
          _getJobProgress(props.fieldRecognitionJob) * 0.3 +
          _getJobProgress(postProcessingJob) * 0.25
      } else {
        if (props.vp.is_football_side_view) {
          if (props.trackingJob && props.fieldRecognitionJob) {
            currentProgress =
              _getJobProgress(props.trackingJob) * 0.6 +
              _getJobProgress(props.fieldRecognitionJob) * 0.4
          } else if (props.trackingJob) {
            currentProgress = _getJobProgress(props.trackingJob)
          } else {
            currentProgress = _getJobProgress(props.fieldRecognitionJob)
          }
        } else {
          currentProgress = _getJobProgress(props.trackingJob)
        }
      }
    }

    setProgress((Number(currentProgress || 0) * 100).toFixed(0))
  }, [
    props.trackingJob,
    props.fieldRecognitionJob,
    props.ballTrackingJob,
    props.automatedTrackingDataJob,
    props.dataIntegrationJob
  ])

  return (
    <AutomatedTrackingDataJob
      vp={props.vp}
      dataType={GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA}
      automatedTrackingDataJob={props.automatedTrackingDataJob}
      trackingJob={props.trackingJob}
      fieldRecognitionJob={props.fieldRecognitionJob}
      ballTrackingJob={props.ballTrackingJob}
      isVpCreatedInCloud={props.isVpCreatedInCloud}
      deletingVp={props.deletingVp}
      render={automatedTrackingState => {
        if (automatedTrackingState.deletingVp) {
          return (
            <div
              css={css`
                position: relative;
                top: 5px;
                left: 6px;
              `}
            >
              <CircularProgress size={19} thickness={4} />
            </div>
          )
        }

        if (!props.vp.files.video) {
          return onHold(props)
        }

        if (
          automatedTrackingState.hasAutomatedTrackingData ||
          props.vp.data_type === GAMES_DATASET_DATA_TYPE.ELITE ||
          props.vp.data_type === GAMES_DATASET_DATA_TYPE.SYNCED
        ) {
          return done()
        }

        if (
          automatedTrackingState.hasAutomatedTrackingDataJob ||
          props.trackingJob ||
          props.ballTrackingJob ||
          props.fieldRecognitionJob
        ) {
          return hasAutomatedTrackingJobUI(props, progress)
        }

        if (automatedTrackingState.showStartAtd) {
          return startAutomatedTracking(props)
        }

        if (automatedTrackingState.isOnHold) {
          return onHold(props)
        }

        if (props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATIC) {
          return doneOld(props)
        }

        if (
          props.vp.files[JOB_TYPES.TRACKING_FIFA_FORMAT.file] ||
          (props.vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED &&
            props.vp.files.tracking_to_correct) ||
          props.vp.files[JOB_TYPES.AUTOMATED_TRACKING_DATA.file]
        ) {
          return done()
        }

        return ''
      }}
    />
  )
}

function hasAutomatedTrackingJobUI(props, progress) {
  if (
    (props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATIC || !props.vp.is_football_side_view) &&
    progress >= 100.0
  ) {
    return doneOld(props)
  }

  const automatedTrackingDataStatus =
    props.automatedTrackingDataJob && props.automatedTrackingDataJob.status
  if (automatedTrackingDataStatus === Status.COMPLETE) {
    return done()
  }

  const preProcessingStatus = props.preProcessingJob && props.preProcessingJob.status
  const trackingStatus = props.trackingJob && props.trackingJob.status
  const fieldRecognitionStatus = props.fieldRecognitionJob && props.fieldRecognitionJob.status
  const ballTrackingStatus = props.ballTrackingJob && props.ballTrackingJob.status
  if (
    preProcessingStatus === Status.FAILED ||
    trackingStatus === Status.FAILED ||
    fieldRecognitionStatus === Status.FAILED ||
    ballTrackingStatus === Status.FAILED ||
    automatedTrackingDataStatus === Status.FAILED
  ) {
    return errorTryAgain(props)
  }

  if (
    preProcessingStatus === Status.CANCEL ||
    trackingStatus === Status.CANCEL ||
    fieldRecognitionStatus === Status.CANCEL ||
    ballTrackingStatus === Status.CANCEL ||
    automatedTrackingDataStatus === Status.CANCEL
  ) {
    return startAutomatedTracking(props)
  }

  if (
    trackingStatus === Status.ACTIVE ||
    fieldRecognitionStatus === Status.ACTIVE ||
    ballTrackingStatus === Status.ACTIVE ||
    automatedTrackingDataStatus === Status.ACTIVE ||
    progress > 0.0
  ) {
    return (
      <Progress
        percent={progress}
        strokeWidth={16}
        trailWidth={16}
        strokeColor={theme.palette.common.blue}
        trailColor={theme.palette.common.blue2}
        style={styles.progressBar}
      />
    )
  }

  if (
    preProcessingStatus === Status.COMPLETE ||
    trackingStatus === Status.STARTING ||
    fieldRecognitionStatus === Status.STARTING ||
    ballTrackingStatus === Status.STARTING ||
    automatedTrackingDataStatus === Status.STARTING
  ) {
    return <span css={styles.greyRowText}>{window.I18n.t('admin.initializing')}</span>
  }

  if (preProcessingStatus === Status.ACTIVE || preProcessingStatus === Status.STARTING) {
    return <span css={styles.greyRowText}>{window.I18n.t('admin.preProcessingAtd')}</span>
  }

  if (
    [
      preProcessingStatus,
      trackingStatus,
      fieldRecognitionStatus,
      ballTrackingStatus,
      automatedTrackingDataStatus
    ].some(status => status === Status.QUEUED)
  ) {
    return <span css={styles.greyRowText}>{window.I18n.t('admin.settingUpAtd')}</span>
  }

  if (
    [
      preProcessingStatus,
      trackingStatus,
      fieldRecognitionStatus,
      ballTrackingStatus,
      automatedTrackingDataStatus
    ].some(status => status === Status.QUEUED_LOCALLY)
  ) {
    return <span css={styles.greyRowText}>{window.I18n.t('admin.waitingAtd')}</span>
  }

  switch (automatedTrackingDataStatus) {
    case 'CHECKING':
      return <span css={styles.greyRowText}>{window.I18n.t('admin.checking')}</span>
    case Status.FINISHING:
      return <span css={styles.greyRowText}>{window.I18n.t('admin.postProcessingData')}</span>
    case Status.CANCEL:
      return startAutomatedTracking(props)
    case Status.CONNECTIONFAILED:
      return (
        <Tooltip title={window.I18n.t('admin.connectionFailedText')} placement="top-start">
          <div css={styles.connectionFailedContainer}>
            <Icon style={styles.connectionFailedIcon}>report_problem</Icon>
            <span css={styles.connectionFailed}>{window.I18n.t('admin.connectionFailed')}</span>
          </div>
        </Tooltip>
      )
    default:
      return onHold(props)
  }
}

function errorTryAgain(props) {
  if (props.isMetricaSportsUser) {
    return (
      <span
        css={css`
          ${styles.actionError};
          ${props.isProcessAtdAvailable ? '' : styles.disableButton};
        `}
        onClick={() => props.processAutomatedTrackingData()}
      >
        {window.I18n.t('common.errorTryAgain')}
      </span>
    )
  }

  return (
    <span
      css={css`
        ${styles.textError};
        ${props.isProcessAtdAvailable ? '' : styles.disableButton};
      `}
    >
      {window.I18n.t('admin.errorContactSupport')}
    </span>
  )
}

function onHold(props) {
  return (
    <span css={styles.greyRowText}>
      {props.uploadingVideo
        ? window.I18n.t('admin.uploadingVideoFile')
        : window.I18n.t('admin.noVideoFile')}
    </span>
  )
}

function done() {
  return (
    <div
      css={css`
        position: relative;
        top: 4px;
      `}
    >
      <Icon style={styles.iconSuccess}>check_circle_outline</Icon>
    </div>
  )
}

function doneOld(props) {
  const tooltipMessage =
    (props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATIC &&
      window.I18n.t('admin.oldAtdTooltip')) ||
    (!props.vp.is_football_side_view && window.I18n.t('admin.noFootballSideViewTooltip'))
  return (
    <Tooltip title={tooltipMessage} placement="top-start">
      <div
        css={css`
          position: relative;
          top: 4px;
          color: #a9b3b0;
        `}
      >
        <Icon>check_circle_outline</Icon>
      </div>
    </Tooltip>
  )
}

// eslint-disable-next-line
function startAutomatedTracking({ vp, processAutomatedTrackingData, isProcessAtdAvailable }) {
  return (
    <Tooltip
      title={window.I18n.t('admin.forkedGameLimit')}
      placement="top"
      arrow
      disableHoverListener={!vp.forked_from}
    >
      <div>
        <span
          css={css`
            ${styles.actionLink};
            ${isProcessAtdAvailable ? '' : styles.disableButton};
          `}
          // eslint-disable-next-line react/prop-types
          onClick={() => processAutomatedTrackingData()}
        >
          {window.I18n.t('admin.startAtd')}
        </span>
      </div>
    </Tooltip>
  )
}

AutomatedTrackingCol.defaultProps = {
  preProcessingJob: undefined,
  trackingJob: undefined,
  fieldRecognitionJob: undefined,
  ballTrackingJob: undefined,
  automatedTrackingDataJob: undefined,
  dataIntegrationJob: undefined,
  errorAutomatedTrackingLinkAvailable: undefined
}

AutomatedTrackingCol.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  preProcessingJob: PropTypes.object,
  trackingJob: PropTypes.object,
  fieldRecognitionJob: PropTypes.object,
  ballTrackingJob: PropTypes.object,
  automatedTrackingDataJob: PropTypes.object,
  dataIntegrationJob: PropTypes.object,
  errorAutomatedTrackingLinkAvailable: PropTypes.func, // eslint-disable-line
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired,
  uploadingVideo: PropTypes.bool.isRequired, // eslint-disable-line
  processAutomatedTrackingData: PropTypes.func.isRequired, // eslint-disable-line
  isProcessAtdAvailable: PropTypes.bool.isRequired // eslint-disable-line
}

export default AutomatedTrackingCol
