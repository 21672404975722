export default {
  addKeyModal: {
    action: 'Applicare',
    errorMessage:
      'Errore nell’applicazione del comando. Se il problema persiste, contattare il team di supporto di Metrica Sports',
    minutesMessage: 'La tua chiave è stata elaborata. Ora hai %{minutes} minuti disponibili.',
    placeholder: 'Digita un tasto...',
    successMessage: 'Il comando è stato elaborato con successo',
    title: 'Aggiungi un Tasto'
  },
  admin: {
    NoGames: 'Nessun partita, aggiungi la tua prima...',
    NoResults: 'Nessun risultato...',
    addKey: 'Aggiungi tasto',
    atd: 'ATD',
    automatedTrackingData: 'Tracking Dati Automatizzato (ATD)',
    automatedTrackingDataJobSuccess:
      'Il processo di Tracking Dati Automatizzato su %{vpName} è terminato ed è ora disponibile in Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Tracking Dati Automatizzato elaborato',
    awayTeam: 'Squadra in trasferta',
    awayTeamScore: 'Punteggio della squadra in trasferta',
    ballDetection: 'Rilevamento palla',
    ballTracking: 'Tracking della palla',
    ballTrackingProcessedTooltip: 'Rilevamento palla elaborato',
    ballTrackingTooltip:
      'Questo processo rileverà la palla e ti consentirà di visualizzarla dal vivo all’interno della formazione (Campo 2D) in Play. Le coordinate della palla saranno disponibili anche nel file in formato FIFA EPTS.',
    cancelATDProcessSuccessfully:
      'Il processo di Tracking Dati Automatico è stato annullato per %{vpName}',
    cancelDataIntegrationProcessSuccessfully:
      'Il processo di integrazione dei dati è stato annullato  per %{vpName}',
    cancelEventsProcessSuccessfully: 'Il processo di Smart Tagging è stato annullato su %{vpName}',
    cancelProcessTooltip: 'Annulla processo',
    cancelSurfacesProcessSuccessfully:
      'Il processo per la superficie è stato annullato il %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Il processo di Tracking del formato FIFA è stato annullato il %{vpName}',
    category: 'Categoria',
    checking: 'Controllo...',
    cloudMinutes: 'Minuti di Cloud',
    competition: 'Competizione',
    connectionFailed: 'Connessione fallita',
    connectionFailedText:
      'Sembra esserci un problema per recuperare le informazioni sullo stato. Verifica la tua connessione Internet e ricarica la pagina. Se il problema persiste, si prega di contattare l’assistenza',
    correctedTracking: 'Procedura della correzione di Tracking',
    correctionUsage: 'Utilizzerai questo Progetto video per la correzione di Tracking?',
    correctionUsageTooltip:
      "Tracking Correction Manager (TCM) è un modulo di Play orientato all'utente per correggere i file precedentemente generati con il nostro ATD, che genera automaticamente dati senza ID per qualsiasi partita di calcio. Usa TCM per correggere le tracce sbagliate e assegnare loro un'identità. Contattateci per maggiori informazioni.",
    createVideoProject: 'Crea progetto video',
    creatingGame: 'Creazione di un progetto video...',
    dataIntegrationFailed:
      'Integrazione dati non riuscita per %{vpName}. Vai al pannello Integrazione dati, controlla i tuoi file e riprova. Se l’errore persiste, si prega di contattare il team di supporto di Metrica Sports',
    dataIntegrationJobSuccess:
      'Il processo di integrazione dei dati per %{vpName} è stato completato correttamente',
    date: 'Data',
    description: 'Descrizione',
    editedSuccessfully: '%{vpName} è stato modificato correttamente',
    errorContactSupport: 'Errore. Contatta il supporto',
    errorProcessTooltip: 'Errore durante il processo',
    eventsDetection: 'Etichettatura automatica',
    eventsDetectionProcessedTooltip: 'Smart Tagging elaborato',
    eventsDetectionTooltip: {
      footer: '*Disponibile per video di durata superiore ai 30 minuti.',
      header:
        'Sfrutta la nostra tecnologia per rilevare automaticamente una serie di eventi che si verificano nella partita*.',
      improved: 'Migliorato'
    },
    fieldRecognition: 'Rilevamento del campo',
    fieldTrackingProcessedTooltip: 'Rilevamento del campo elaborato',
    fieldTrackingTooltip:
      'Processo di rilevamento del campo per aggiungere visualizzazioni con valori di velocità e distanza e tutti i disegni a seconda del campo in Play.',
    fileDeletedSuccessfully: '%{fileName} eliminato con successo per %{vpName}',
    fileUploadedError: 'Errore durante il caricamento di %{fileName} per %{vpName}',
    fileUploadedSuccessfully: '%{fileName} è stato caricato correttamente per %{vpName}',
    forkedGameLimit:
      'Questo Progetto Video appartiene a un’altra organizzazione, quindi non è possibile modificarlo',
    gameDeletedSuccessfully: '%{game} eliminato con successo',
    gameVideoProject: 'Progetto video della partita',
    getMoreMinutes: 'Ottieni di più',
    homeTeam: 'Squadra di casa',
    homeTeamScore: 'Punteggio della squadra di casa',
    initializing: 'Inizializzazione...',
    jobFailed:
      'Il processo %{process} non è riuscito per %{vpName}. Si prega di contattare il team di supporto di Metrica Sports',
    liveCoding: 'o continuare con il live coding?',
    liveCodingTooltip:
      "È possibile creare un Progetto Video senza selezionare un file video per scopi di Live Coding. Una volta ottenuto il video, puoi aggiungerlo per avere il Tracking Dati Automatico all'interno del Progetto Video codificato.",
    localTeamOnTheLeft:
      'La "Squadra di Casa" si trova sul lato sinistro del campo nel primo tempo?',
    localTeamOnTheLeftTooltip:
      'Per elaborare il Tagging Automatico, specificare in quale lato del campo si trova ogni squadra. Controllare il video che si sta caricando per convalidarlo.',
    noFootballSideViewTooltip:
      'Questo Progetto Video contiene un file video grandangolare o televisivo non di calcio, quindi è stato elaborato solo il rilevamento di Giocatori e Squadre.',
    noMatches: 'Nessuna corrispondenza...',
    noSubscriptionOwnerTooltip:
      "Contatta '%{email} ', l'amministratore del tuo abbonamento, per eseguire l'upgrade o vai alla nostra pagina Piani per crearne uno tuo",
    noVideoFile: 'Nessun file video',
    notPossibleToDeleteThisEntry: 'Impossibile eliminare questa voce',
    numberOfHalves: 'Quanti tempi ha questa partita?',
    numberOfHalvesTooltip:
      'Per elaborare il Tagging automatico, specificare quanti tempi di gioco sono inclusi nel video che si sta caricando.',
    oldAtdTooltip: 'Questo video è stato elaborato con una versione precedente di ATD',
    onRequest: 'Su richiesta',
    organizations: 'Organizzazioni',
    place: 'Posizione',
    playerTracking: 'Rilevamento Giocatori e Squadre',
    playerTrackingProcessedTooltip: 'Rilevamento giocatore elaborato',
    playerTrackingTooltip:
      'Questo processo rileverà i portieri e i 10 giocatori in campo di ogni squadra. Ciò ti consentirà di visualizzare la formazione dal vivo (2D Pitch) e di aggiungere automaticamente le Visualizzazioni dei Giocatori in Play. Le coordinate dei giocatori saranno disponibili anche nel file in formato FIFA EPTS.',
    postProcessingData: 'Invio elaborazione dati...',
    preProcessingAtd: 'Pre-elaborazione...',
    processingTooltip: 'in lavorazione',
    readyToUpload: 'Pronto per il caricamento',
    search: 'Ricerca...',
    selectVideoFile: 'Seleziona file video',
    settingUpAtd: 'Impostazione del rilevamento...',
    settings: 'impostazioni',
    singleVideoProject: 'Progetto video unico',
    startAtd: 'Avvia ATD',
    startFifaMessage:
      'Il processo di integrazione FIFA per %{vpName} è stato avviato. Visualizzeremo una notifica al termine. Attendere prego.',
    startProcessingWaitingJobsFailed: 'Invio di lavori in attesa non riuscito',
    startProcessingWaitingJobsSuccessfully: 'Lavori in attesa inviati con successo',
    startSsMessage:
      'Il secondo processo di integrazione dello spettro per %{vpName} è stato avviato. Visualizzeremo una notifica al termine. Attendere prego.',
    startStatsMessage:
      'Il processo di integrazione delle statistiche per %{vpName} è stato avviato. Visualizzeremo una notifica al termine. Attendere prego.',
    startTracabDflMessage:
      'Il processo di integrazione Tracab DFL per %{vpName} è stato avviato. Visualizzeremo una notifica al termine. Attendere prego.',
    startTracabMessage:
      'Il processo di integrazione Tracab per %{vpName} è stato avviato. Visualizzeremo una notifica al termine. Attendere prego.',
    surfacesJobSuccess: '%{vpName} Il processo di superficie è stato completato con successo',
    trackingCorrectionProcessedTooltip: 'Correzione di Tracking prodotta',
    trackingFifaFormatJobSuccess:
      'Il processo di tracciamento del formato FIFA per %{vpName} è stato completato con successo. Ora puoi scaricare il file facendo clic sulla voce di menu Scarica formato FIFA',
    trackingLicence: 'Non hai la licenza per il Tracking',
    uncaughtErrorRow:
      'Errore di progetto video. Per favore, aggiorna questa pagina. Se il problema persiste, contatta l’assistenza o eliminalo e crealo di nuovo',
    updatingGame: 'Aggiornamento del progetto video...',
    upgradePlan: 'Aggiornamento',
    uploadAndTracking: 'Caricamento e Tracking',
    uploadingVideoFile: 'Caricamento file video',
    videoFile: 'File video',
    videoFootballSideView:
      'Questo file video è un grandangolo o una trasmissione TV di calcio/calcio?',
    videoFootballSideViewTooltip:
      'Il rilevamento del campo è ottimizzato solo per questi tipo di video.',
    videoMultipartStart:
      '%{videoName} è in fase di caricamento su %{vpName}. NON CHIUDERE QUESTA PAGINA fino al termine del caricamento',
    videoPlaylist: 'Questo file video è una playlist?',
    videoPlaylistTooltip:
      'Se selezioni SÌ, verrà eseguito il rilevamento del filmato, per riconoscere quando si sta verificando un taglio nel video, il che lo rende uno strumento perfetto se stai caricando un video contenente più filmati, ad esempio. Dopo aver eseguito questo processo, scarica il Progetto Video in Play e un Coding file sarà lì con un codice che include ogni taglio come evento.',
    videoProjectSettings: 'Impostazioni Progetto Video',
    videoUploadError:
      'Caricamento video di %{videoName} per %{vpName} non riuscito. Per favore riprova',
    videoUploaded: '%{videoName} è stato caricato su %{vpName}',
    vpCreated: '%{vpName} è stato creato',
    waitingAtd: 'In attesa...'
  },
  billing: {
    address: 'Indirizzo',
    billed: 'Fatturato',
    completed: 'Pagato',
    details: 'Dettagli di Fatturazione',
    history: 'Storico Fatturazione',
    invoiceNotFound: 'Fattura non trovata',
    noInvoices: 'Non ci sono fatture.',
    past_due: 'Scaduto',
    taxId: 'Partita IVA'
  },
  checkout: {
    chargeSubscription: 'Addebitare alla sottoscrizione.',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*I codici sconto possono essere applicati al momento del pagamento.',
    excTax: '(Escl. tasse)',
    immediatePayment: 'Pagamento Immediato',
    incTax: '(Inc. tasse)',
    monthlyBilled: 'Fatturato mensilmente',
    nextPayment: 'Prossimo Pagamento',
    noPacks: 'Senza Pacchetto Minuti Cloud',
    onDate: 'il %{date}',
    packs: 'Pacchetti',
    packsTotal: 'Pacchetti Totali',
    perMonth: '/mese',
    perYear: '/anno',
    plans: 'Piani',
    plansTotal: 'Piani Totali',
    proceed: 'Procedere al pagamento',
    subtotal: 'Subtotale',
    taxPayable: 'Tasse da pagare (%{percentage}%)',
    total: 'Totale',
    unpaid:
      'La tua ultima fattura non è stata ancora pagata. Per favore, aggiorna le tue informazioni di pagamento per continuare.',
    updatePaymentMethod: 'Aggiorna Metodo di Pagamento',
    updateSubscription: 'Aggiorna Abbonamento',
    userDevice: '1 utente/dispositivo',
    usersDevices: '%{number} utenti/dispositivi',
    yearlyBilled: 'Fatturato annualmente'
  },
  common: {
    back: 'Indietro',
    cancel: 'Annulla',
    canceling: 'Annullamento...',
    clearAll: 'Cancella tutto',
    edit: 'modificare',
    email: 'E-mail',
    errorMsg:
      'Errore durante l’elaborazione per %{name}. Per richieste si prega di contattare il supporto.',
    errorTryAgain: 'Errore. Riprova',
    genericError:
      'Errore, processo non riuscito. Per richieste si prega di contattare il supporto.',
    loading: 'Caricamento in corso...',
    login: 'Accesso',
    logout: 'Disconnettersi',
    new: 'Nuovo',
    next: 'Prossimo',
    no: 'No',
    noFileChosen: 'Nessun file scelto',
    ok: 'Ok',
    required: 'Necessario',
    reset: 'Resetta la password',
    restart: 'Ricomincia',
    selectFile: 'Seleziona il file',
    sort: 'Ordinare',
    start: 'Inizio',
    today: 'Oggi',
    uploading: 'Caricamento in corso...',
    yes: 'Sì'
  },
  confirmModal: {
    description: 'Sei sicuro?',
    title: 'Conferma'
  },
  dataIntegration: {
    deleteFile: 'Cancella il file',
    featureNotAvailable:
      'Questa funzione non è disponibile nel tuo piano attuale. Devi passare a un altro piano per avere questa funzione. Contattateci per maggiori informazioni',
    notSideView:
      'L’integrazione dei dati non è disponibile per i Progetti Video contenenti un file video grandangolare o di trasmissione TV non di calcio.',
    open: 'Apri integrazione dati',
    processJobsFirst:
      'È necessario iniziare il Tracking Dati Automatizzato (ATD), prima di accedere al pannello di integrazione dei dati',
    processed: 'Integrazione dati elaborata',
    selectFile: 'Seleziona il file',
    startProcess: 'Avvia processo',
    title: 'Integrazione dei dati',
    waiting: 'Processo in attesa...',
    waitingProcess:
      'Il processo di integrazione inizierà una volta completati i processi di rilevamento dei giocatori e del campo.'
  },
  deleteModal: {
    checkboxLabel: 'Voglio anche smettere di ricevere e-mail da Metrica Sports',
    confirmationType: 'Digita %{validateFormKey} per confermare:',
    deleteAction: 'Sì, eliminalo',
    description: 'Sei sicuro di voler eliminare definitivamente il tuo account?',
    information:
      'Perderai tutti i progetti video creati con questo account. Non possiamo recuperarli una volta eliminati.',
    keepAccount: 'Mantieni account',
    suggestion: 'Ti consigliamo di mantenere il tuo account.',
    title: "Eliminare per l'eternità?",
    validateFormKey: 'ELIMINA'
  },
  error: {
    oops: 'Ops!',
    restart: 'Sembra che tu stia cercando di entrare in qualcosa che stiamo ancora sviluppando...'
  },
  eventsDetection: {
    automaticVp:
      'Smart Tagging non disponibile su Progetti Video con una versione precedente di ATD.',
    needsAtd: 'Avvia prima il processo ATD per poter eseguire il processo di Smart Tagging.',
    needsFootballSideView:
      "Smart Tagging non disponibile nei Progetti Video che contengono un file video di calcio non ripreso da un'angolazione laterale ampia o trasmesso in televisione.",
    needsGameVp: 'Smart Tagging è disponibile solo nei Progetti di Video di Partita.',
    needsNoPlaylist:
      'Smart Tagging non disponibile nei Progetti Video che contengono un file video che è una playlist.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Gli utenti amministratori devono utilizzare un endpoint privato per reimpostare la password',
    notFound: 'Utente non trovato. Riprova con un indirizzo e-mail valido',
    success: 'Ripristino della password! Controlla la tua e-mail'
  },
  login: {
    contactUs: 'Contattaci',
    enterYourEmail: 'Inserisci la tua email',
    enterYourPassword: 'Inserisci la tua password',
    forgotPassword: 'Ha dimenticato la password?',
    invalidEmail: 'indirizzo email non valido',
    licenceExpired: 'La tua licenza è scaduta.',
    trialExpired: 'La tua sessione di prova è scaduta.',
    upgradePlan: 'Aggiorna il tuo piano'
  },
  patterns: {
    delete: 'Elimina file modello',
    title: 'File modello personalizzato',
    upload: 'Carica il file del Modello Personalizzato',
    uploaded: 'File di modello caricato',
    uploading: 'Caricamento del file del modello'
  },
  plans: {
    academic: 'Accademico',
    addMoreProducts: '+ Aggiungi altri prodotti',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Mensile',
    basicCodePadYearly: 'Basic+Code Annuale',
    basicMftCodePadMonthly: 'Full Basic Mensile',
    basicMftCodePadYearly: 'Full Basic Annuale',
    basicMftMonthly: 'Basic+MFT Mensile',
    basicMftXmlMonthly: 'Basic MFT + XML Mensile',
    basicMftXmlYearly: 'Basic MFT + XML Annuale',
    basicMftYearly: 'Basic+MFT Annuale',
    basicMonthly: 'Basic Mensile',
    basicXmlMonthly: 'Basic XML Mensile',
    basicXmlYearly: 'Basic XML Annuale',
    basicYearly: 'Basic Annuale',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite Annuale',
    eliteYearly: 'Elite Annuale',
    essentialsCodeMonthly: 'Essentials + Code ∞ Mensile',
    essentialsCodeYearly: 'Essentials + Code ∞ Annuale',
    essentialsMftCodeMonthly: 'Full Essentials Mensile',
    essentialsMftCodeYearly: 'Full Essentials Annuale',
    essentialsMftMonthly: 'Essentials + MFT Mensile',
    essentialsMftYearly: 'Essentials + MFT Annuale',
    essentialsMonthly: 'Essentials Mensile',
    essentialsYearly: 'Essentials Annuale',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Mensile',
    liteYearly: 'Lite Annuale',
    minutes10: 'Pacchetto di 10 Minuti Cloud',
    minutes100: 'Pacchetto 100 Minuti Cloud',
    minutes1000: 'Pacchetto di 1.000 Minuti Cloud',
    minutes10000: 'Pacchetto di 10.000 Minuti Cloud',
    plan: '%{planName} Piano',
    primeMftMonthly: 'Prime + MFT Mensile',
    primeMftYearly: 'Prime + MFT Annuale',
    primeMonthly: 'Prime Mensile',
    primePresetsMftMonthly: 'Full Prime Mensile',
    primePresetsMftYearly: 'Full Prime Annuale',
    primePresetsMonthly: 'Prime + Presets Mensile',
    primePresetsYearly: 'Prime + Presets Annuale',
    primeYearly: 'Prime Annuale',
    pro: 'Pro',
    pro2024Monthly: 'Pro Mensile',
    pro2024Yearly: 'Pro Annuale',
    proYearly: 'Pro Annuale',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Mensile',
    starterCodePadMonthly: 'Code Mensile',
    starterCodePauseMonthly: 'Full Starter Mensile',
    starterCodePauseYearly: 'Full Starter Annuale',
    starterCodeYearly: 'Starter + Code ∞ Annuale',
    starterPauseMonthly: 'Starter + Pause ∞ Mensile',
    starterPauseYearly: 'Starter + Pause ∞ Annuale',
    student: 'Studente',
    trial: 'Trial'
  },
  playlist: {
    download: 'Scarica il file della playlist',
    notProcessed: 'Il file della playlist non è stato prodotto in questo Progetto Video.',
    processed: 'File di playlist prodotto',
    title: 'Playlist',
    titleDownload: 'Scarica la playlist'
  },
  products: {
    addOns: 'Add-on',
    basic: 'Codici e Annotazioni Illimitati',
    code: 'CodePad',
    elite: {
      description:
        'Automatizza e personalizza l\'intero flusso di lavoro di analisi. Questo piano include accesso completo a Play2 con tutte le caratteristiche del Piano "Full Essentials" e accesso a Play3 con tutte le caratteristiche del Piano "Pro".',
      feature1: '✓ Archiviazione Cloud condivisa',
      feature2: '✓ Integrazione dei Dati di Tracking',
      feature3: '✓ Tracking Correction Manager',
      feature4: '✓ RESTful API',
      feature5: '✓ Pacchetto di 5.000 minuti ATD'
    },
    essentials: {
      addon1: 'Funzionalità di Coding illimitate (CodePad, Coding dal Vivo e Importazione XML)',
      addon2: 'Tracking Manuale del Campo',
      essentialsCodeDescription:
        'Questo piano abilita le Visualizzazioni Dinamiche disponibili per aggiungere annotazioni in movimento illimitate durante la riproduzione del video e nelle pause. Questo piano non include il Tracking di Campo Manuale, quindi le visualizzazioni di Velocità e Distanza non sono incluse. Inoltre, sblocca la limitazione delle funzionalità di coding come CodePad, Coding dal Vivo e Importazione XML e rimuove il limite di 4 codici per Progetto Video a codici illimitati.',
      essentialsDescription:
        'Questo piano consente le Visualizzazioni Dinamiche disponibili per aggiungere annotazioni di movimento illimitate durante la riproduzione del video e nelle pause. Questo piano non include il Tracking di Campo Manuale, quindi le visualizzazioni di Velocità e Distanza non sono incluse. Le funzionalità di coding rimangono limitate.',
      essentialsMftCodeDescription:
        'Questo piano abilita le Visualizzazioni Dinamiche disponibili per aggiungere annotazioni in movimento illimitate durante la riproduzione del video e nelle pause, e attiva anche il Tracking di Campo Manuale per aggiungere visualizzazioni di velocità e distanza. Inoltre, sblocca la limitazione delle funzionalità di coding come CodePad, Coding dal Vivo e Importazione XML e rimuove il limite di 4 codici per Progetto Video a codici illimitati.',
      essentialsMftDescription:
        'Questo piano abilita le Visualizzazioni Dinamiche disponibili per aggiungere annotazioni in movimento illimitate durante la riproduzione del video e nelle pause, e attiva anche il Tracking di Campo Manuale per aggiungere visualizzazioni di Velocità e Distanza. Le funzionalità di coding rimangono limitate.',
      feature1: '✓ Codifica post-partita (Code Table con fino a 4 codici)',
      feature2: '✓ Telestrazione illimitata in pausa',
      feature3: '✓ Telestrazione dinamica',
      feature4: '✓ Pacchetto da 3 minuti di ATD'
    },
    featuresIncluded: 'Caratteristiche incluse:',
    freeFeature1: '✓ Codifica post-partita (Code Table con fino a 4 codici)',
    freeFeature2: '✓ Keyframing (Tracking manuale)',
    freeFeature3: '✓ Telestrazione (fino a 150 annotazioni)',
    freeFeature4: '✓ Esportazione video',
    freeFeatures: 'Funzionalità Gratuite',
    fullBasic: 'Tracking Manuale del Campo',
    lite: 'Tracking Automatico di Giocatori e Campo',
    liteDescription:
      'Coding avanzato e visualizzazioni avanzate. Tutto automatico, rendendolo 3 volte più veloce del Full Basic!',
    minutesDescription:
      'Ricarica qualsiasi piano. Ottieni pacchetti di minuti per Dati di Tracking Automatizzato (ATD).',
    minutesPack: 'Pacchetto Minuti Cloud',
    otherDescription:
      'Funzionalità di coding avanzate con telestrazione di base e annotazioni limitate.',
    prime: {
      addon1: 'Tracking Manuale del Campo',
      addon2: 'Visualizzazioni Personalizzate (personalizza e salva le tue visualizzazioni)',
      feature1:
        '✓ Accesso completo a Play2 con tutte le caratteristiche del Piano "Full Essentials"',
      feature2: '✓ Accesso a Play3 con:',
      feature2_1: 'Pause sequenziali',
      feature2_2: 'Telestrazione avanzata',
      feature2_3: 'Esportazione video personalizzata',
      feature3: '✓ Pacchetto da 10 minuti di ATD',
      primeDescription:
        'Questo piano include accesso completo a Play2 con tutte le funzionalità del Piano "Essentials + Code ∞" e abilita Play3 con telestrazioni avanzate.',
      primeMftDescription:
        'Questo piano include accesso completo a Play2 con tutte le funzionalità del Piano "Full Essentials" e abilita Play3 con telestrazione avanzata.',
      primePresetsDescription:
        'Questo piano include accesso completo a Play2 con tutte le funzionalità del Piano "Essentials + Code ∞" e abilita Play3 con telestrazione avanzata e visualizzazioni personalizzate.',
      primePresetsMftDescription:
        'Questo piano include accesso completo a Play2 con tutte le funzionalità del Piano "Full Essentials" e abilita Play3 con telestrazione avanzata e visualizzazioni personalizzate.'
    },
    pro: {
      description:
        'Questo piano include accesso completo a Play2 con tutte le caratteristiche del Piano "Full Essentials" e accesso a Play3 con tutte le caratteristiche del Piano "Full Prime".',
      feature1: '✓ Tracking Automatico dei Giocatori',
      feature2: '✓ Tracking Automatico del Campo',
      feature3:
        '✓ Accesso completo a Play2 con tutte le caratteristiche del Piano "Full Essentials"',
      feature4: '✓ Accesso a Play3 con:',
      feature4_1: 'Pause sequenziali',
      feature4_2: 'Telestrazione avanzata',
      feature4_3: 'Esportazione video personalizzata',
      feature4_4: 'Telestrazione Preimpostata (personalizza e salva le tue visualizzazioni)',
      feature5: '✓ Pacchetto da 100 minuti ATD'
    },
    remove: 'Rimuovere',
    starter: {
      addon1: 'Telestrazione illimitata in pausa',
      addon2: 'Funzionalità di Coding illimitate (CodePad, Coding dal Vivo e Importazione XML)',
      feature1: '✓ Codifica post-partita (Code Table con fino a 4 codici)',
      feature2: '✓ Telestrazione in pausa (fino a 50 annotazioni al mese)',
      feature3: '✓ Esportazione di video',
      starterCodeDescription:
        'Questo piano elimina il limite delle funzionalità di coding come CodePad, Coding dal Vivo e Importazione XML. Rimuove anche il limite di 4 codici per Progetto Video, passando a codici illimitati. Le limitazioni nelle funzionalità di telestrazione rimangono nel Free Starter.',
      starterCodePauseDescription:
        'Questo piano elimina il limite delle funzionalità di coding come CodePad, Coding in Diretta e Importazione XML. Rimuove anche il limite di 4 codici per Progetto Video portandolo a codici illimitati, e sblocca il limite di telestrazioni in pausa da 50 al mese a illimitate.',
      starterPauseDescription:
        'Questo piano rimuove il limite di telestrazione nelle pause da 50 al mese a illimitate. Le limitazioni nelle funzionalità di coding rimangono nel Free Starter.'
    },
    totalPack: 'Totale: %{currency} %{cost}',
    totalPlan: 'Totale: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Fatturazione',
    billingSettings: 'Impostazioni di fatturazione',
    billingSettingsDescription: '(cambia il tuo piano, aggiorna i metodi di pagamento...)',
    cancelAt: 'Il tuo piano viene annullato',
    cancelPlan: 'Annulla il Piano',
    confirmNewPassword: 'Conferma la nuova Password',
    deleteAccount: 'Eliminare l’account',
    details: 'I miei dettagli',
    emailNotActivated: 'La notifica via e-mail è ora attivata',
    emailNotDeactivated: 'La notifica via e-mail è ora disattivata',
    emailNotifications: 'Notifiche via e-mail',
    language: 'Lingua',
    languageChanged: 'La lingua è stata aggiornata',
    newPassword: 'Nuova password',
    organizationLabel: 'Prefisso dell’organizzazione',
    organizationTooltip:
      'Questo prefisso è necessario per importare modelli personalizzati in un progetto video. Contattaci per maggiori informazioni o controlla la documentazione su codeball.metrica-sports.com',
    passwordsDontMatch: 'Le password non corrispondono',
    pauseAt: 'Il tuo piano si mette in pausa il',
    planCanceled:
      'Il tuo piano è stato annullato. Controlla la tua email per ulteriori informazioni',
    plans: 'Piani',
    quantity: 'Quantità:',
    renewAt: 'Il tuo piano si rinnova',
    title: 'Impostazioni',
    upgradePlan: 'Piano di aggiornamento',
    userKey: 'Chiave utente:',
    userKeyUsages: '%{current}/%{max} utilizzi',
    userSettings: 'Impostazioni utente'
  },
  shoppingCart: {
    subtitle: 'Scegli il tuo piano Play e gli add-on per migliorare le tue prestazioni.',
    title: 'Configura il tuo Play.'
  },
  subscription: {
    cancelSubscription: 'Annulla abbonamento',
    errorUpdating:
      "Si è verificato un errore nell'aggiornamento della tua sottoscrizione. Per favore, invia un'email a support@metrica-sports.com e saremo felici di aiutarti.",
    free: 'Gratis',
    isObsolete:
      'La tua sottoscrizione non può essere modificata. Sostituisci i prodotti obsoleti con nuovi per abilitare le modifiche',
    licenseKeys: 'Chiavi di licenza',
    pendingRefundRequest:
      "Non è possibile apportare ulteriori modifiche a questa sottoscrizione mentre c'è una richiesta di rimborso in sospeso.",
    undoCancel: "Annulla la cancellazione dell'abbonamento.",
    undoPause: "Annulla pausa dell'abbonamento",
    updatePaymentMethod: 'Aggiorna metodo di pagamento',
    updatedSuccessfully: 'Sottoscrizione aggiornata con successo',
    yourPlans: 'I tuoi piani',
    yourSubscription: 'La tua sottoscrizione'
  },
  successfulPayment: {
    activateLicence:
      'Attiva la tua licenza o credito Cloud inserendo la chiave di licenza fornita.',
    allTheBest: 'Tutto il meglio,',
    applyKey: 'Applica la chiave a questo account.',
    applyKeyDescription:
      "Vuoi applicare automaticamente la chiave di licenza all'account in cui sei connesso?",
    downloadPlay: '%{pre}Scarica PLAY%{post} tramite il nostro sito web.',
    faq: 'Per aiutarti a iniziare con il tuo ordine, consulta le %{pre}Domande Frequenti%{post} sul nostro sito web. Ecco un breve riassunto delle informazioni che potrebbero essere più rilevanti per te in questa fase:',
    goToSummary:
      'Se no, per favore, consulta il riassunto qui sotto per conoscere le istruzioni di attivazione.',
    happyPlay:
      'Speriamo che tutto sia chiaro, ma per favore non esitare a contattarci se hai ulteriori domande o dubbi. Felice PLAY!',
    helpDesk:
      "Consulta il nostro %{pre}HelpDesk%{post} per informazioni generali sull'uso di PLAY.",
    keyApplied: 'Chiave applicata',
    licenseKey: 'La tua chiave di licenza è: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'Il team di Metrica Sports',
    settingsCloud:
      'Visita il nostro %{pre}Cloud%{post} e naviga fino al menu delle impostazioni per:',
    technicalSupport:
      "Per assistenza tecnica, compila il modulo di contatto disponibile in PLAY/Cloud, o invia un'email a %{pre}support@metrica-sports.com%{post}.",
    termsAndConditions:
      'Si prega di notare che i nostri abbonamenti vengono rinnovati automaticamente fino a quando non vengono cancellati esplicitamente tramite le impostazioni in %{pre}Cloud%{post}, in conformità con i nostri Termini e Condizioni.',
    thanks:
      'Grazie per il tuo ordine! Lo abbiamo elaborato con successo e allegato le tue chiavi di licenza qui sotto.',
    title: "Conferma dell'Ordine",
    updatePayment: 'Aggiorna il tuo metodo di pagamento.',
    updateSubscription: 'Annulla o modifica la tua sottoscrizione.'
  },
  surfaces: {
    automaticVp: 'Superfici non disponibili nei progetti video con una versione ATD precedente',
    delete: 'Elimina il file della superficie',
    needsAtd: 'Avviare prima il processo ATD per eseguire i processi di superficie',
    needsElite:
      'Le superfici personalizzate sono disponibili con il piano Elite. Contattateci per maggiori informazioni',
    needsFootballSideView:
      'Superfici non disponibili su progetti video contenenti un file video grandangolare o di trasmissione TV non di calcio',
    pitchControl:
      'L’esecuzione di questo processo ti consentirà di aggiungere la visualizzazione di Pitch Control in Play.',
    pitchControlProcessed: 'Pitch Control della superficie elaborata',
    pitchControlProcessing: 'Pitch Control in elaborazione',
    processed: 'File di superficie caricato',
    processing: 'File di superficie in elaborazione',
    title: 'Superfici',
    titlePitchControl: 'Pitch Control',
    upload: 'Carica la tua superficie',
    uploading: 'Caricamento superficie in corso...'
  },
  trackingFifaFormat: {
    download: 'Scarica il file in formato FIFA EPTS',
    needsElite:
      'Questa funzione è disponibile solo dopo aver importato i file corrispondenti nel modulo Integrazione dati, Progetti video con dati di tracciamento automatizzato o Progetti video nel piano Elite.',
    processed: 'Formato FIFA EPTS elaborato',
    start:
      'Avvia il processo per generare il file in formato FIFA EPTS. Potrai quindi scaricare il file',
    title: 'Formato FIFA EPTS',
    titleDownload: 'Scarica il formato FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'Ottieni il pacchetto minuti Cloud Engine più adatto a te:',
    minutePackLinks: {
      minutes_10: 'Ottieni 10 minuti per 10 €',
      minutes_100: 'Ottieni 100 minuti per € 90',
      minutes_1000: 'Ottieni 1.000 minuti per € 750',
      minutes_10000: 'Ottieni 10.000 minuti per € 5.000'
    },
    noMinutes: {
      description:
        'Non hai abbastanza minuti disponibili per creare questo progetto video. Sai che puoi ottenere pacchetti minuti qualunque sia il tuo piano?',
      secondDescription: '',
      title: 'Ottieni più minuti per creare un progetto video!'
    },
    notEnoughMinutes: {
      description:
        'Siamo spiacenti di informarti che non hai abbastanza minuti per il processo ATD sui tuoi progetti video.',
      secondDescription:
        'Se desideri elaborare ATD, dovrai aggiungere più minuti al tuo account. I minuti che avrai non scadono!',
      title: 'Minuti disponibili insufficienti'
    }
  },
  video: {
    cancelUpload: 'Annulla caricamento',
    delete: 'Elimina video',
    download: 'Scarica video',
    title: 'File video',
    upload: 'Carica video',
    uploaded: 'Video caricato',
    uploading: 'Caricamento video'
  },
  videoProject: {
    delete: 'Elimina progetto video',
    edit: 'Modifica progetto video',
    processWaitingJobs: 'Elabora lavori in attesa',
    title: 'Progetto video'
  }
}
