/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import { CircularProgress, TextField, withStyles } from '@material-ui/core'
import moment from 'moment/dist/moment'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { theme } from '../../../constants'
import formatPrice from '../../../utils/formatPrice'
import openInNewTab from '../../../utils/openInNewTab'
import orderService from '../../../services/order'

const styles = {}
styles.container = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
`
styles.loadingContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
styles.header = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
`
styles.title = css`
  font-size: 16px;
  font-weight: bold;
`
styles.description = css`
  font-size: 14px;
`
styles.sectionContainer = css`
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid ${theme.palette.common.charolBlack}80;
`
styles.sectionHeader = css`
  display: flex;
  width: 25%;
  padding-left: 16px;
  font-weight: bold;
`
styles.sectionContent = css`
  width: 40%;
  display: flex;
  flex-direction: column;
`
styles.infoContainer = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`
styles.transactionContainer = css`
  display: flex;
  margin-bottom: 12px;
`
styles.noInvoicesText = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
styles.invoiceText = css`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
styles.dateText = css`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 30px;
`
styles.priceText = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  margin: 0px 30px;
`
styles.statusButton = css`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  select: none;
`

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.charolBlack
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 16px'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

const inputText = {
  input: { color: theme.palette.common.charolBlack },
  label: { color: theme.palette.common.charolBlack },
  width: '100%',
  marginTop: 0,
  marginBottom: 0
}

function Transaction(props) {
  const [isDownloading, setIsDownloading] = React.useState(false)

  const buttonStyle = {}
  if (props.status === 'completed') {
    buttonStyle.background = theme.palette.common.main
    buttonStyle.color = theme.palette.common.charolBlack
  } else {
    buttonStyle.background = theme.palette.common.redError
    buttonStyle.color = theme.palette.common.white
  }

  if (isDownloading) {
    buttonStyle.cursor = 'pointer'
    buttonStyle.pointerEvents = 'none'
    buttonStyle.opacity = 0.5
  }

  return (
    <div css={styles.transactionContainer} key={`transaction-${props.id}`}>
      <span css={styles.invoiceText}>{props.invoice_number}</span>
      <span css={styles.dateText}>{moment(props.created_at).format('MMM Do YY')}</span>
      <span css={styles.priceText}>
        {formatPrice(props.details.totals.total, props.currency_code, true)} {props.currency_code}
      </span>
      <span
        css={styles.statusButton}
        style={buttonStyle}
        onClick={async () => {
          try {
            setIsDownloading(true)
            const response = await orderService.getInvoiceByTransactionId(props.id)
            const invoiceUrl = response.data.url
            if (!invoiceUrl) {
              toast.error(window.I18n.t('billing.invoiceNotFound'))
            } else {
              openInNewTab(invoiceUrl)
            }
          } catch (e) {
            // Do nothing.
          } finally {
            setIsDownloading(false)
          }
        }}
      >
        {isDownloading ? (
          <CircularProgress color="white" size={16} thickness={4} />
        ) : (
          window.I18n.t(`billing.${props.status}`)
        )}
      </span>
    </div>
  )
}

Transaction.defaultProps = {}

Transaction.propTypes = {
  id: PropTypes.string.isRequired,
  invoice_number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  currency_code: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired
}

function Billing(props) {
  const [address, setAddress] = React.useState('')

  React.useEffect(() => {
    if (props.address) {
      const {
        first_line: firstLine,
        postal_code: postalCode,
        city,
        country_code: countryCode
      } = props.address
      setAddress(`${firstLine || ''}\n${postalCode} ${city || ''} ${countryCode}`.trim())
    }
  }, [props.address])

  return (
    <div css={styles.container}>
      {!props.customerLoaded && (
        <div css={styles.loadingContainer}>
          <CircularProgress color="primary" size={32} thickness={4} />
        </div>
      )}

      {props.customerLoaded && (
        <React.Fragment>
          {
            // TODO: there's nothing to update in this tab for now, so we'll hide this section.
            /*
        <div css={styles.header}>
          <span css={styles.title}>Payment Method</span>
          <span css={styles.description}>Update your billing details and address</span>
        </div>
        */
          }

          {
            // TODO: Card details are located in each subscription.
            // Paddle will move this to the customer object during 2024.
            /*
        <div css={styles.sectionContainer}>
          <div css={styles.sectionHeader}>Card Details</div>
          <div css={styles.sectionContent}>VISA</div>
        </div>
        */
          }

          <div css={styles.sectionContainer} style={{ paddingTop: 0, borderTop: 0 }}>
            <div css={styles.sectionHeader}>{window.I18n.t('billing.details')}</div>
            <div css={styles.sectionContent}>
              <div css={styles.infoContainer}>
                <span>{window.I18n.t('common.email')}</span>
                <CssTextField
                  variant="outlined"
                  value={props.user.email}
                  type="email"
                  name="email"
                  margin="normal"
                  css={inputText}
                  disabled
                />
              </div>

              <div css={styles.infoContainer}>
                <span>{window.I18n.t('billing.address')}</span>
                <CssTextField
                  variant="outlined"
                  value={address}
                  type="email"
                  name="email"
                  margin="normal"
                  css={inputText}
                  multiline
                  disabled
                />
              </div>

              <div css={styles.infoContainer}>
                <span>{window.I18n.t('billing.taxId')}</span>
                <CssTextField
                  variant="outlined"
                  value={props.business ? props.business.tax_identifier : ''}
                  type="email"
                  name="email"
                  margin="normal"
                  css={inputText}
                  disabled
                />
              </div>
            </div>
          </div>

          <div css={styles.sectionContainer}>
            <div css={styles.sectionHeader}>{window.I18n.t('billing.history')}</div>
            <div css={styles.sectionContent} style={{ width: '60%' }}>
              {props.transactions.length === 0 && (
                <span css={styles.noInvoicesText}>{window.I18n.t('billing.noInvoices')}</span>
              )}

              {props.transactions.map(v => (
                <Transaction key={v.id} {...v} />
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

Billing.defaultProps = {
  customer: undefined,
  address: undefined,
  business: undefined
}

Billing.propTypes = {
  user: PropTypes.object.isRequired,
  customerLoaded: PropTypes.bool.isRequired,
  customer: PropTypes.object,
  address: PropTypes.object,
  business: PropTypes.object,
  transactions: PropTypes.array.isRequired
}

export default Billing
