import * as Paddle from '@paddle/paddle-js'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { toast } from 'react-toastify'
import amplitude from '../../analytics/amplitude'
import * as Constants from '../../constants'
import ActionTypes from '../../constants/actionTypes'
import sessionService from '../../services/session'
import tokenService from '../../services/token'
import userService from '../../services/user'
import fetchProducts from '../../utils/fetchProducts'
import getErrorMessage from '../../utils/getErrorMessage'
import zendeskWidget from '../../utils/zendeskWidget'
import { history } from '../App'
import Admin from '../admin'
import * as Actions from '../admin/actions'
import PrivateRoute from '../components/routes/PrivateRoute'
import Header from '../components/Header'
import SuccessfulPayment from '../components/SuccessfulPayment'
import Settings from '../settings'
import ShoppingCart from '../shopping-cart'
import Subscription from '../subscription'
import getIp from '../../utils/getIp'

// NOTE: order matters.
const adminRoutes = [
  {
    id: 1,
    exact: false,
    path: '/settings',
    component: Settings,
    render: PrivateRoute
  },
  {
    id: 2,
    exact: false,
    path: '/shopping-cart',
    component: ShoppingCart,
    render: PrivateRoute
  },
  {
    id: 3,
    exact: false,
    path: '/subscription',
    component: Subscription,
    render: PrivateRoute
  },
  {
    id: 4,
    exact: false,
    path: '/successful-payment',
    component: SuccessfulPayment,
    render: PrivateRoute
  },
  {
    id: 5,
    exact: false,
    path: '/',
    component: Admin,
    render: PrivateRoute
  }
]

function Home(props) {
  const [paddle, setPaddle] = React.useState(null)

  React.useEffect(() => {
    if (!props.userLoaded) {
      _loadUser(props.dispatch).then(userData => _onUserLoaded(props.dispatch, userData, setPaddle))
    } else {
      _onUserLoaded(props.dispatch, props, setPaddle)
    }
  }, [])

  return (
    <div>
      <Header />
      <Switch>
        {adminRoutes.map(({ exact, path, component, render: Render, id }) => (
          <Render
            exact={exact}
            path={path}
            component={component}
            componentProps={{ paddle, subscriptionInUse: props.subscriptionInUse }}
            key={id}
          />
        ))}
      </Switch>
    </div>
  )
}

function _onUserLoaded(dispatch, userData, setPaddle) {
  dispatch({ type: ActionTypes.FETCHING_CUSTOMER, payload: true })
  userService
    .getCustomer()
    .then(responseCustomer => {
      dispatch({ type: ActionTypes.FETCH_CUSTOMER, payload: responseCustomer.data })
    })
    .finally(() => {
      dispatch({ type: ActionTypes.FETCHING_CUSTOMER, payload: false })
    })

  fetchProducts().then(productsData => {
    dispatch({ type: ActionTypes.FETCH_PRODUCTS, payload: productsData })
  })

  _setupPaddle(userData.user, userData.subscriptionInUse, setPaddle)
  _setupZendesk()
}

async function _setupPaddle(user, subscriptionInUse, setPaddle) {
  const isProduction = process.env.REACT_APP_ENV === 'production'

  const pwAuth = process.env.REACT_APP_PROFITWELL_TOKEN || null
  let pwCustomer = null
  if (pwAuth) {
    if (subscriptionInUse) {
      pwCustomer = { id: subscriptionInUse.customerId }
    } else {
      pwCustomer = { email: user.email }
    }
  }

  // Initialize Paddle in Home page (apart from Login page) once we have ProfitWell data.
  const instance = await Paddle.initializePaddle({
    environment: isProduction ? 'production' : 'sandbox',
    token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
    pwAuth,
    pwCustomer,
    eventCallback: async event => {
      if (event.name === Paddle.CheckoutEventNames.CHECKOUT_COMPLETED) {
        // Go to successful payment page when there was a payment actually,
        // i.e. Paddle Checkout was not used for changing payment method.
        if (event.data.totals.total > 0) {
          toast.success('Successful payment')
          history.push(`/successful-payment?transaction=${event.data.transaction_id}`)
        } else {
          toast.success('Payment method changed successfully')
        }

        instance.Checkout.close()
      }
    }
  })

  if (instance) {
    setPaddle(instance)
  }
}

function _setupZendesk() {
  if (window.zESettings) {
    zendeskWidget.reset()
  } else {
    zendeskWidget.setup()
  }
}

async function _loadUser(dispatch) {
  let data = null

  try {
    dispatch({ type: ActionTypes.FETCHING_USER, payload: true })
    let { token } = tokenService.get()
    const response = await sessionService.check(token)

    data = response.data
    try {
      data.userIp = await getIp()
    } catch (error) {
      console.error(error)
    }

    token = data.token

    const user = data.user
    const subscriptionInUse = data.subscriptionInUse
    tokenService.set(token, user._id)
    dispatch({ type: ActionTypes.MAKE_AUTH, payload: data })

    amplitude.setUser(user)
    amplitude.logEvent(Constants.AnalyticsEvents.CHECK_TOKEN_METRICA_CLOUD)
    Actions.showLicenceReminder(user, subscriptionInUse)
  } catch (error) {
    console.error(error)
    const errorMsg = getErrorMessage(error)

    // We are handling this error on the api service file
    const apiMessages = Constants.API_MESSAGES
    if (errorMsg === apiMessages.trialLicenseInvalid || errorMsg === apiMessages.licenseInvalid) {
      return
    }

    zendeskWidget.openInForm()
    toast.error(errorMsg)
  } finally {
    dispatch({ type: ActionTypes.FETCHING_USER, payload: false })
  }

  return data
}

Home.defaultProps = {
  subscriptionInUse: undefined
}

Home.propTypes = {
  userLoaded: PropTypes.bool.isRequired,
  subscriptionInUse: PropTypes.object,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    userLoaded: state.auth.userLoaded,
    user: state.auth.user,
    subscriptionInUse: state.auth.subscriptionInUse
  }
}

export default connect(mapStateToProps)(Home)
