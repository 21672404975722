import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import * as Constants from '../constants'
import tokenService from './token'
import { history } from '../modules/App'
import getErrorMessage from '../utils/getErrorMessage'
import zendeskWidget from '../utils/zendeskWidget'

const callToActionStyle = {
  cursor: 'default',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontSize: 16,
  color: '#fff'
}

function getErrorMsg(error) {
  if (!error.response || (error.response.status !== 401 && error.response.status !== 403)) {
    return error
  }

  tokenService.delete()
  history.replace('/login')

  const apiMessages = Constants.API_MESSAGES
  const msgString = getErrorMessage(error)
  if (msgString === apiMessages.trialLicenseInvalid || msgString === apiMessages.licenseInvalid) {
    toast.error(({ closeToast }) => {
      if (msgString === apiMessages.trialLicenseInvalid) {
        return (
          <div onClick={closeToast}>
            {window.I18n.t('login.trialExpired')}
            <br />
            <a
              style={callToActionStyle}
              target="_blank"
              rel="noopener noreferrer"
              href="https://metrica-sports.com/pricing/"
            >
              {window.I18n.t('login.upgradePlan')}
            </a>
          </div>
        )
      } else if (msgString === apiMessages.licenseInvalid) {
        return (
          <div onClick={closeToast}>
            {window.I18n.t('login.licenceExpired')}
            <br />
            <a style={callToActionStyle} href="mailto:support@metrica-sports.com">
              {window.I18n.t('login.contactUs')}
            </a>
          </div>
        )
      }
    })

    zendeskWidget.openInForm()
  }

  return error
}

const api = axios.create({
  baseURL: Constants.apiBaseUrl[process.env.REACT_APP_ENV],
  timeout: Constants.apiTimeout,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  config => {
    // Do something before request is sent
    const user = tokenService.get()
    if (user) {
      config.headers.common.Authorization = `Bearer ${user.token}`
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
api.interceptors.response.use(
  response => {
    // Do something with response data
    return response
  },
  error => {
    // Do something with response error
    return Promise.reject(getErrorMsg(error))
  }
)

export default api
