import findIndex from 'lodash/findIndex'
import ActionTypes from '../constants/actionTypes'
import { Status, JOB_TYPES } from '../constants'

const initialState = {
  tableList: [],
  search: '',
  stadiums: [],
  competitions: [],
  teams: [],
  organizations: [],
  monthlyProducts: [],
  yearlyProducts: [],
  oneTimeProducts: [],
  preProcessingJobs: {},
  trackingJobs: {},
  fieldRecognitionJobs: {},
  ballTrackingJobs: {},
  automatedTrackingDataJobs: {},
  eventsDetectionJobs: {},
  surfaceJobs: {},
  dataIntegrationJobs: {},
  notEnoughMinutes: false,
  trackingFifaFormatJobs: {},
  addKeyModal: undefined
}

export default function admin(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_TABLE_LIST: {
      return {
        ...state,
        tableList: action.payload,
        preProcessingJobs: {
          ...state.preProcessingJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.PRE_PROCESSING.back)
        },
        trackingJobs: {
          ...state.trackingJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.TRACKING.back)
        },
        fieldRecognitionJobs: {
          ...state.fieldRecognitionJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.HOMOGRAPHY.back)
        },
        ballTrackingJobs: {
          ...state.ballTrackingJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.BALL_TRACKING.back)
        },
        automatedTrackingDataJobs: {
          ...state.automatedTrackingDataJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
        },
        eventsDetectionJobs: {
          ...state.eventsDetectionJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.EVENTS_DETECTION.back)
        },
        surfaceJobs: {
          ...state.surfaceJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.SURFACES.back)
        },
        dataIntegrationJobs: {
          ...state.dataIntegrationJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.DATA_INTEGRATION.back)
        },
        trackingFifaFormatJobs: {
          ...state.trackingFifaFormatJobs,
          ...buildJobsObj(action.payload, JOB_TYPES.TRACKING_FIFA_FORMAT.back)
        }
      }
    }

    case ActionTypes.GAMES_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }

    case ActionTypes.FETCH_STADIUMS: {
      return {
        ...state,
        stadiums: action.payload
      }
    }

    case ActionTypes.FETCH_COMPETITIONS: {
      return {
        ...state,
        competitions: action.payload
      }
    }

    case ActionTypes.TOGGLE_ADD_KEY_MODAL: {
      return {
        ...state,
        addKeyModal: action.payload
      }
    }

    case ActionTypes.FETCH_TEAMS: {
      return {
        ...state,
        teams: action.payload
      }
    }

    case ActionTypes.FETCH_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.payload
      }
    }

    case ActionTypes.FETCH_PRODUCTS: {
      return {
        ...state,
        monthlyProducts: action.payload.monthly,
        yearlyProducts: action.payload.yearly,
        oneTimeProducts: action.payload.oneTime
      }
    }

    case ActionTypes.NOT_ENOUGH_MINUTES: {
      return {
        ...state,
        notEnoughMinutes: action.payload
      }
    }

    case ActionTypes.CHANGE_JOBS_ITEM: {
      const { job } = action // JOB_TYPES.front
      const jobType = `${job}Jobs`

      return {
        ...state,
        [jobType]: { ...state[jobType], ...action.payload }
      }
    }

    case ActionTypes.REMOVE_JOBS_ITEM: {
      const { job } = action // JOB_TYPES.front
      const jobType = `${job}Jobs`

      const itemJobs = { ...state[jobType] }
      delete itemJobs[action.payload]

      return {
        ...state,
        [jobType]: itemJobs
      }
    }

    case ActionTypes.DELETE_VP: {
      const newPreProcessingJobs = { ...state.preProcessingJobs }
      const newTracking = { ...state.trackingJobs }
      const newFieldRecognitionJobs = { ...state.fieldRecognitionJobs }
      const newAutomatedTrackingDataJobs = { ...state.automatedTrackingDataJobs }
      const newEventsDetectionJobs = { ...state.eventsDetectionJobs }
      const newSurfaceJobs = { ...state.surfaceJobs }
      const newBallTrackingJobs = { ...state.ballTrackingJobs }
      const newDataIntegrationJobs = { ...state.dataIntegrationJobs }
      const newTrackingFifaFormatJobs = { ...state.trackingFifaFormatJobs }
      delete newPreProcessingJobs[action.payload]
      delete newTracking[action.payload]
      delete newFieldRecognitionJobs[action.payload]
      delete newEventsDetectionJobs[action.payload]
      delete newSurfaceJobs[action.payload]
      delete newBallTrackingJobs[action.payload]
      delete newAutomatedTrackingDataJobs[action.payload]
      delete newDataIntegrationJobs[action.payload]
      delete newTrackingFifaFormatJobs[action.payload]

      return {
        ...state,
        preProcessingJobs: newPreProcessingJobs,
        trackingJobs: newTracking,
        fieldRecognitionJobs: newFieldRecognitionJobs,
        eventsDetectionJobs: newEventsDetectionJobs,
        surfaceJobs: newSurfaceJobs,
        ballTrackingJobs: newBallTrackingJobs,
        automatedTrackingDataJobs: newAutomatedTrackingDataJobs,
        dataIntegrationJobs: newDataIntegrationJobs,
        trackingFifaFormatJobs: newTrackingFifaFormatJobs,
        tableList: state.tableList.filter(vp => vp._id !== action.payload)
      }
    }

    case ActionTypes.UPDATE_TABLE_LIST_ITEM: {
      const vpIdx = findIndex(state.tableList, vp => vp._id === action.payload._id)
      if (vpIdx === -1) {
        return state
      }
      const tableList = [...state.tableList]
      tableList[vpIdx] = tableList[vpIdx].isNew
        ? { ...action.payload, isNew: true }
        : action.payload

      return {
        ...state,
        tableList,
        preProcessingJobs: {
          ...state.preProcessingJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.PRE_PROCESSING.back)
        },
        trackingJobs: {
          ...state.trackingJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.TRACKING.back)
        },
        fieldRecognitionJobs: {
          ...state.fieldRecognitionJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.HOMOGRAPHY.back)
        },
        ballTrackingJobs: {
          ...state.ballTrackingJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.BALL_TRACKING.back)
        },
        automatedTrackingDataJobs: {
          ...state.automatedTrackingDataJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.AUTOMATED_TRACKING_DATA.back)
        },
        eventsDetectionJobs: {
          ...state.eventsDetectionJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.EVENTS_DETECTION.back)
        },
        surfaceJobs: {
          ...state.surfaceJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.SURFACES.back)
        },
        dataIntegrationJobs: {
          ...state.dataIntegrationJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.DATA_INTEGRATION.back)
        },
        trackingFifaFormatJobs: {
          ...state.trackingFifaFormatJobs,
          ...buildJobsObj([action.payload], JOB_TYPES.TRACKING_FIFA_FORMAT.back)
        }
      }
    }

    case ActionTypes.ADD_TABLE_LIST_ITEM: {
      return {
        ...state,
        tableList: [action.payload, ...state.tableList]
      }
    }

    case ActionTypes.LOGOUT: {
      return {
        ...state,
        ...initialState
      }
    }

    default:
      return state
  }
}

function buildJobsObj(vps, type) {
  return vps.reduce((hash, item) => {
    const job = item.jobsStatus && item.jobsStatus[type]
    if (job) {
      // If the status is UNKNOWN and !hasFile the process failed somehow
      const status = job.status === Status.UNKNOWN ? Status.FAILED : job.status
      hash[item._id] = { status, progress: job.progress, payload: job.payload }
    }
    return hash
  }, {})
}
