export default {
  common: {
    logout: 'Logout',
    login: 'Login',
    required: 'Required',
    loading: 'Loading...',
    email: 'Email',
    cancel: 'Cancel',
    canceling: 'Canceling...',
    ok: 'Ok',
    today: 'Today',
    sort: 'Sort',
    no: 'No',
    yes: 'Yes',
    next: 'Next',
    edit: 'Edit',
    restart: 'Restart',
    uploading: 'Uploading...',
    clearAll: 'Clear All',
    new: 'New',
    errorTryAgain: 'Error. Try again',
    errorMsg: 'Error processing %{name}. For inquiries please contact support.',
    genericError: 'Error, process failed. For inquiries please contact support.',
    back: 'Back',
    selectFile: 'Select File',
    noFileChosen: 'No file chosen',
    reset: 'Reset Password',
    start: 'Start'
  },
  error: {
    oops: 'OOPS!',
    restart: "Looks you're trying to get into something we are still developing..."
  },
  login: {
    enterYourEmail: 'Enter your email',
    enterYourPassword: 'Enter your password',
    invalidEmail: 'Invalid email address',
    forgotPassword: 'Forgot Password?',
    trialExpired: 'Your trial has expired.',
    licenceExpired: 'Your license has expired.',
    upgradePlan: 'Upgrade your plan',
    contactUs: 'Contact us'
  },
  forgotPassword: {
    success: 'Password reset! Please check your email',
    notFound: 'User not found. Please try again with a valid email',
    adminNoResetPassword: 'Admin users have to use a private endpoint to reset password'
  },
  settings: {
    title: 'Settings',
    details: 'My details',
    billing: 'Billing',
    plans: 'Plans',
    userSettings: 'User Settings',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm new Password',
    passwordsDontMatch: "Passwords don't Match",
    emailNotifications: 'Email notifications',
    emailNotActivated: 'Email notification is now activated',
    emailNotDeactivated: 'Email notification is now deactivated',
    language: 'Language',
    languageChanged: 'Language has been updated',
    organizationTooltip:
      'This prefix is needed for importing custom patterns into a Video Project. Contact us for more information or check the documentation at codeball.metrica-sports.com',
    organizationLabel: 'Organisation prefix',
    upgradePlan: 'Upgrade Plan',
    quantity: 'Quantity:',
    renewAt: 'Your plan renews on',
    pauseAt: 'Your plan pauses on',
    cancelAt: 'Your plan cancels on',
    userKey: 'User Key:',
    userKeyUsages: '%{current}/%{max} usages',
    billingSettings: 'Billing Settings',
    billingSettingsDescription: '(change your plan, update payment methods...)',
    cancelPlan: 'Cancel Plan',
    planCanceled: 'Your plan has been cancelled. Check your email for more information',
    deleteAccount: 'Delete Account'
  },
  billing: {
    details: 'Billing Details',
    history: 'Billing History',
    address: 'Address',
    taxId: 'Tax ID',
    invoiceNotFound: 'Invoice not found',
    noInvoices: 'No invoices',
    billed: 'Billed',
    completed: 'Paid',
    past_due: 'Past due'
  },
  successfulPayment: {
    title: 'Order Confirmation',
    thanks:
      'Thank you for your order! We have processed it successfully and attached your license keys below.',
    licenseKey: 'Your license key is: %{pre}%{licenseKey}%{post}',
    applyKeyDescription:
      "Would you like to apply the license key for the account you're logged automatically?",
    keyApplied: 'Key applied',
    applyKey: 'Apply key to this account',
    goToSummary: 'If not, please refer to the summary below for activation instructions.',
    faq: "To help you get started with your order, check out the %{pre}Frequently Asked Questions%{post} on our website. Here's a quick summary of the info that may be most relevant to you at this stage:",
    downloadPlay: '%{pre}Download PLAY%{post} via our website.',
    helpDesk: 'Check out our %{pre}HelpDesk%{post} for general info using PLAY.',
    settingsCloud: 'Visit our %{pre}Cloud%{post} and navigate to the settings menu to:',
    updateSubscription: 'Cancel or modify your subscription.',
    updatePayment: 'Update your payment method.',
    activateLicence: 'Activate your license or Cloud credit by entering the provided license key.',
    technicalSupport:
      'For technical support, fill in the contact form available in PLAY/Cloud, or email support@metrica-sports.com',
    termsAndConditions:
      "Please note that our subscriptions are renewed automatically until explicitly cancelled via our %{pre}Cloud%{post}'s settings, in accordance with our Terms and Conditions.",
    happyPlay:
      "We hope everything is clear, but please don't hesitate to reach out to us if you have any further questions or doubts. Happy PLAY!",
    allTheBest: 'All the best,',
    metricaSportsTeam: 'The Metrica Sports team'
  },
  plans: {
    plan: '%{planName} Plan',
    starter: 'Starter',
    trial: 'Trial',
    student: 'Student',
    academic: 'Academic',
    influencer: 'Influencer',
    code: 'Code',
    basic: 'Basic',
    lite: 'Lite',
    pro: 'Pro',
    elite: 'Elite',
    admin: 'Admin',
    starterCodePadMonthly: 'Code Monthly',
    basicMonthly: 'Basic Monthly',
    basicYearly: 'Basic Yearly',
    basicCodePadMonthly: 'Basic+Code Monthly',
    basicCodePadYearly: 'Basic+Code Yearly',
    basicXmlMonthly: 'Basic XML Monthly',
    basicXmlYearly: 'Basic XML Yearly',
    basicMftMonthly: 'Basic+MFT Monthly',
    basicMftYearly: 'Basic+MFT Yearly',
    basicMftCodePadMonthly: 'Full Basic Monthly',
    basicMftCodePadYearly: 'Full Basic Yearly',
    basicMftXmlMonthly: 'Basic MFT+XML Monthly',
    basicMftXmlYearly: 'Basic MFT+XML Yearly',
    liteMonthly: 'Lite Monthly',
    liteYearly: 'Lite Yearly',
    proYearly: 'Pro Yearly',
    eliteYearly: 'Elite Yearly',
    addMoreProducts: '+ Add more products',
    minutes10: '10 Cloud Minutes Pack',
    minutes100: '100 Cloud Minutes Pack',
    minutes1000: '1.000 Cloud Minutes Pack',
    minutes10000: '10.000 Cloud Minutes Pack',
    starterPauseMonthly: 'Starter + Pause ∞ Monthly',
    starterPauseYearly: 'Starter + Pause ∞ Yearly',
    starterCodeMonthly: 'Starter + Code ∞ Monthly',
    starterCodeYearly: 'Starter + Code ∞ Yearly',
    starterCodePauseMonthly: 'Full Starter Monthly',
    starterCodePauseYearly: 'Full Starter Yearly',
    essentialsMonthly: 'Essentials Monthly',
    essentialsYearly: 'Essentials Yearly',
    essentialsCodeMonthly: 'Essentials + Code ∞ Monthly',
    essentialsCodeYearly: 'Essentials + Code ∞ Yearly',
    essentialsMftMonthly: 'Essentials + MFT Monthly',
    essentialsMftYearly: 'Essentials + MFT Yearly',
    essentialsMftCodeMonthly: 'Full Essentials Monthly',
    essentialsMftCodeYearly: 'Full Essentials Yearly',
    primeMonthly: 'Prime Monthly',
    primeYearly: 'Prime Yearly',
    primeMftMonthly: 'Prime + MFT Monthly',
    primeMftYearly: 'Prime + MFT Yearly',
    primePresetsMonthly: 'Prime + Presets Monthly',
    primePresetsYearly: 'Prime + Presets Yearly',
    primePresetsMftMonthly: 'Full Prime Monthly',
    primePresetsMftYearly: 'Full Prime Yearly',
    pro2024Monthly: 'Pro Monthly',
    pro2024Yearly: 'Pro Yearly',
    elite2024Yearly: 'Elite Yearly'
  },
  admin: {
    search: 'Search...',
    cloudMinutes: 'Cloud Minutes',
    getMoreMinutes: 'Get more',
    upgradePlan: 'Upgrade',
    noSubscriptionOwnerTooltip:
      "Contact '%{email}', the admin of your subscription, to upgrade or go to our Plans page to create your own",
    settings: 'Settings',
    addKey: 'Add Key',
    createVideoProject: 'Create Video Project',
    gameVideoProject: 'Game Video Project',
    singleVideoProject: 'Single Video Project',
    date: 'Date',
    competition: 'Competition',
    category: 'Category',
    description: 'Description',
    organizations: 'Organizations',
    place: 'Location',
    videoFile: 'Video File',
    atd: 'ATD',
    awayTeamScore: 'Away Team Score',
    homeTeamScore: 'Home Team Score',
    homeTeam: 'Home Team',
    awayTeam: 'Away Team',
    editedSuccessfully: '%{vpName} has been edited successfully',
    startProcessingWaitingJobsSuccessfully: 'Waiting jobs sent successfully',
    startProcessingWaitingJobsFailed: 'Sending waiting jobs failed',
    gameDeletedSuccessfully: '%{game} deleted successfully',
    fileUploadedSuccessfully: '%{fileName} uploaded successfully for %{vpName}',
    fileUploadedError: 'Error uploading %{fileName} for %{vpName}',
    fileDeletedSuccessfully: '%{fileName} deleted successfully for %{vpName}',
    noMatches: 'No Matches...',
    NoGames: 'No games, add your first one...',
    NoResults: 'No Results...',
    readyToUpload: 'Ready to upload',
    noVideoFile: 'No video file',
    startAtd: 'Start ATD',
    errorContactSupport: 'Error. Contact support',
    vpCreated: '%{vpName} has been created',
    videoUploaded: '%{videoName} has been uploaded on %{vpName}',
    videoUploadError: 'Video upload of %{videoName} for %{vpName} failed. Please try again',
    jobFailed:
      'The %{process} process failed for %{vpName}. Please contact Metrica Sports support team',
    dataIntegrationFailed:
      'Data Integration failed on %{vpName}. Go to Data Integration panel, check your files and try again. If error continues, please contact Metrica Sports support team',
    surfacesJobSuccess: '%{vpName} Surface Process has been finished successfully',
    automatedTrackingDataJobSuccess:
      'Automated Tracking Data process on %{vpName} has finished and is now available in Metrica Play',
    dataIntegrationJobSuccess:
      'Data Integration process for %{vpName} has been finished successfully',
    trackingFifaFormatJobSuccess:
      'Tracking FIFA format process for %{vpName} has been finished successfully. You can now download the file by clicking on the Download FIFA format menu item',
    checking: 'Checking...',
    settingUpAtd: 'Setting up detection...',
    preProcessingAtd: 'Pre-processing...',
    waitingAtd: 'Waiting...',
    initializing: 'Initializing...',
    uncaughtErrorRow:
      'Video Project error. Please, refresh this page. If the problem persists contact support or delete it and create it again',
    videoMultipartStart:
      '%{videoName} is being uploaded on %{vpName}. DO NOT CLOSE THIS PAGE until upload is finished',
    connectionFailed: 'Connection failed',
    connectionFailedText:
      'There seems to be a problem to retrieve the status information. Please check your internet connection and reload the page. If problem persists, please contact support',
    notPossibleToDeleteThisEntry: 'Not possible to delete this entry',
    cancelDataIntegrationProcessSuccessfully: 'Data Integration process was cancelled on %{vpName}',
    cancelATDProcessSuccessfully: 'Automated Tracking Data process was cancelled on %{vpName}',
    cancelEventsProcessSuccessfully: 'Smart Tagging process was cancelled on %{vpName}',
    cancelSurfacesProcessSuccessfully: 'Surface process was cancelled on %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Tracking FIFA format process was cancelled on %{vpName}',
    videoProjectSettings: 'Video Project Settings',
    uploadAndTracking: 'Upload & Tracking',
    fieldRecognition: 'Field Detection',
    ballTracking: 'Ball Tracking',
    creatingGame: 'Creating Video Project...',
    updatingGame: 'Updating Video Project...',
    correctionUsage: 'Will you be using this Video Project for Tracking Correction?',
    playerTracking: 'Player & Team Detection',
    uploadingVideoFile: 'Uploading video file',
    eventsDetection: 'Smart Tagging',
    ballDetection: 'Ball Detection',
    correctedTracking: 'Tracking Correction Processing',
    automatedTrackingData: 'Automated Tracking Data (ATD)',
    postProcessingData: 'Post processing data...',
    trackingLicence: "You don't have Tracking License",
    selectVideoFile: 'Select video file',
    videoPlaylist: 'Is this video file a playlist?',
    videoFootballSideView: 'Is this video file a football/soccer wide-angle or TV broadcast?',
    localTeamOnTheLeft: "Is 'Home Team' located on the left side of the field on first half?",
    numberOfHalves: 'How many halves this game has?',
    liveCoding: 'or continue for live coding?',
    onRequest: 'On request',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM) is a user-oriented module within Play to correct tracks previously generated with our ATD, that generates data without IDs automatically for any football game. Use TCM to correct wrong tracks and assign them an identity. Contact us for more information.',
    videoPlaylistTooltip:
      'If you select YES, Clip Detection will be executed, to recognize when a cut is taking place in the video, which makes it a perfect tool if you are uploading a video containing several clips, for example. After running this process, download the Video Project in Play and a Clip Detection coding file will be there with a code including every cut as an event.',
    videoFootballSideViewTooltip: 'Field Detection is optimized for these type of videos only.',
    localTeamOnTheLeftTooltip:
      'In order to process Smart Tagging, specify in which side of the pitch each team is located. Check out the video you are uploading to validate it.',
    numberOfHalvesTooltip:
      "In order to process Smart Tagging, specify how many game periods are included on the video you're uploading.",
    liveCodingTooltip:
      'You can create a Video Project without selecting a video file for Live Coding purposes. Once you have the video you can add it to have Automatic Tracking Data within the coded Video Project.',
    oldAtdTooltip: 'This video has been processed with an older version of ATD',
    noFootballSideViewTooltip:
      'This Video Project contains a non football/soccer wide-angle or TV broadcast video file, so only Player & Team Detection has been processed.',
    playerTrackingTooltip:
      'This process will detect the goalkeepers and the 10 field players of each team. This will allow you to visualize the Live Formation (2D Pitch) and add Player Visualizations automatically in Play. Player coordinates will be also available in the FIFA EPTS format file.',
    playerTrackingProcessedTooltip: 'Player Detection processed',
    fieldTrackingTooltip:
      'Process Field Detection to add visualizations with speed and distance values and all drawings depending on the field in Play.',
    fieldTrackingProcessedTooltip: 'Field Detection processed',
    eventsDetectionTooltip: {
      header:
        'Take advantage of our technology to automatically detect a series of events that occur in the game*:',
      improved: 'Improved',
      footer: '*Available for videos longer than 30 minutes'
    },
    ballTrackingTooltip:
      'This process will detect the ball and will allow you to visualize it within the Live Formation (2D Pitch) in Play. Ball coordinates will be also available in the FIFA EPTS format file.',
    ballTrackingProcessedTooltip: 'Ball Detection processed',
    automatedTrackingDataProcessedTooltip: 'Automated Tracking Data processed',
    trackingCorrectionProcessedTooltip: 'Tracking Correction processed',
    eventsDetectionProcessedTooltip: 'Smart Tagging processed',
    processingTooltip: 'Processing',
    cancelProcessTooltip: 'Cancel process',
    errorProcessTooltip: 'Error on process',
    startTracabMessage:
      "Tracab Integration process for %{vpName} has started. We'll display a notification once it's done. Please wait.",
    startStatsMessage:
      "Stats Integration process for %{vpName} has started. We'll display a notification once it's done. Please wait.",
    startTracabDflMessage:
      "Tracab DFL Integration process for %{vpName} has started. We'll display a notification once it's done. Please wait.",
    startSsMessage:
      "Second Spectrum Integration process for %{vpName} has started. We'll display a notification once it's done. Please wait.",
    startFifaMessage:
      "FIFA Integration process for %{vpName} has started. We'll display a notification once it's done. Please wait.",
    forkedGameLimit:
      "This Video Project belongs to another Organization, so it's not possible to modify it"
  },
  video: {
    title: 'Video File',
    delete: 'Delete video',
    download: 'Download video',
    upload: 'Upload video',
    uploading: 'Uploading video',
    uploaded: 'Video uploaded',
    cancelUpload: 'Cancel upload'
  },
  patterns: {
    title: 'Custom Pattern File',
    upload: 'Upload Custom Pattern file',
    uploading: 'Uploading Pattern file',
    uploaded: 'Pattern file uploaded',
    delete: 'Delete Pattern file'
  },
  videoProject: {
    title: 'Video Project',
    edit: 'Edit Video Project',
    delete: 'Delete Video Project',
    processWaitingJobs: 'Process waiting jobs'
  },
  dataIntegration: {
    title: 'Data Integration',
    open: 'Open Data Integration',
    waiting: 'Waiting process...',
    processed: 'Data Integration processed',
    selectFile: 'Select File',
    deleteFile: 'Delete file',
    startProcess: 'Start Process',
    featureNotAvailable:
      'This feature is not available in your current plan. You need to upgrade to another plan to have this feature. Contact us for more information',
    waitingProcess:
      'The integration process will begin once Player and Field Detection processes are done.',
    notSideView:
      'Data Integration is not available on Video Projects containing a non football/soccer wide-angle or TV broadcast video file.',
    processJobsFirst:
      'You need to start processing Automated Tracking Data (ATD) first, before accessing the Data Integration panel'
  },
  eventsDetection: {
    needsFootballSideView:
      'Smart Tagging not available on Video Projects containing a non football/soccer wide-angle or TV broadcast video file',
    needsGameVp: 'Smart Tagging is only available on Game Video Projects',
    needsAtd: 'Start ATD process first to run Smart Tagging process',
    needsNoPlaylist:
      'Smart Tagging not available on Video Projects containing a playlist video file',
    automaticVp: 'Smart Tagging not available on Video Projects with an older ATD version'
  },
  surfaces: {
    title: 'Surfaces',
    titlePitchControl: 'Pitch Control',
    pitchControl:
      'By running this process will allow you to add Pitch Control visualization in Play.',
    pitchControlProcessing: 'Processing Pitch Control',
    pitchControlProcessed: 'Pitch Control Surface processed',
    processing: 'Processing Surface file',
    processed: 'Surface file uploaded',
    delete: 'Delete Surface file',
    upload: 'Upload your own Surface',
    uploading: 'Uploading Surface...',
    needsElite: 'Custom Surfaces is available on Elite plan. Contact us for more information',
    needsFootballSideView:
      'Surfaces not available on Video Projects containing a non football/soccer wide-angle or TV broadcast video file',
    needsAtd: 'Start ATD process first to run Surface processes',
    automaticVp: 'Surfaces not available on Video Projects with an older ATD version'
  },
  trackingFifaFormat: {
    title: 'FIFA EPTS Format',
    titleDownload: 'Download FIFA EPTS Format',
    start:
      'Start process to generate FIFA EPTS format file. You will then be able to download the file',
    needsElite:
      'This function is only available after importing the corresponding files into Data Integration module, Video Projects with Automated Tracking Data or Video Projects in Elite plan.',
    processed: 'FIFA EPTS format processed',
    download: 'Download FIFA EPTS format file'
  },
  playlist: {
    title: 'Playlist',
    titleDownload: 'Download Playlist',
    notProcessed: 'Playlist file was not processed in this Video Project.',
    processed: 'Playlist file processed',
    download: 'Download Playlist file'
  },
  upgradeModal: {
    notEnoughMinutes: {
      title: 'Not enough minutes available',
      description:
        "We're sorry to let you know that you don't have enough minutes for ATD process on your Video Projects.",
      secondDescription:
        "If you want to process ATD, you'll need to add more minutes to your account. The minutes you'll get don't expire!"
    },
    noMinutes: {
      title: 'Get more minutes to create a Video Project!',
      description:
        "You don't have enough available minutes to create this Video Project. Do you know you can obtain minute packs whatever your plan is?",
      secondDescription: ''
    },
    linksDescription: 'Get the Cloud Engine minute pack that best suits you:',
    minutePackLinks: {
      minutes_10: 'Get 10 minutes for €10',
      minutes_100: 'Get 100 minutes for €90',
      minutes_1000: 'Get 1.000 minutes for €750',
      minutes_10000: 'Get 10.000 minutes for €5.000'
    }
  },
  confirmModal: {
    title: 'Confirm',
    description: 'Are you sure?'
  },
  deleteModal: {
    title: 'Delete for all eternity?',
    description: 'Are you sure you want to permanently delete your account?',
    information:
      "You'll lose all Video Projects created with this account. We can't recover them once you delete.",
    suggestion: 'We suggest keeping your account.',
    checkboxLabel: 'I also want to stop receiving emails from Metrica Sports',
    validateFormKey: 'DELETE',
    confirmationType: 'Type %{validateFormKey} to confirm:',
    deleteAction: 'Yes, Delete it',
    keepAccount: 'Keep Account'
  },
  addKeyModal: {
    title: 'Add a Key',
    placeholder: 'Type a key...',
    action: 'Apply',
    successMessage: 'Key has been successfully processed',
    errorMessage:
      'Error applying key. If the problem persists, contact Metrica Sports support team',
    minutesMessage: 'Your key has been processed. You now have %{minutes} minutes available'
  },
  checkout: {
    plans: 'Plans',
    perMonth: '/month',
    perYear: '/year',
    monthlyBilled: 'Billed monthly',
    yearlyBilled: 'Billed yearly',
    userDevice: '1 user/device',
    usersDevices: '%{number} users/devices',
    plansTotal: 'Plans Total',
    packs: 'Packs',
    noPacks: 'No Cloud Minutes Pack',
    packsTotal: 'Packs Total',
    incTax: '(Inc. tax)',
    excTax: '(Exc. tax)',
    subtotal: 'Subtotal',
    taxPayable: 'Tax payable (%{percentage}%)',
    total: 'Total',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    costOneTime: '%{currency} %{cost}',
    immediatePayment: 'Immediate Payment',
    nextPayment: 'Next Payment',
    onDate: 'on %{date}',
    proceed: 'Proceed to checkout',
    chargeSubscription: 'Charge to subscription',
    updatePaymentMethod: 'Update Payment Method',
    updateSubscription: 'Update Subscription',
    unpaid:
      'Your latest invoice has not yet been paid. Please update your payment information to continue',
    discountCodes: '*Discount codes can be applied at the checkout'
  },
  subscription: {
    yourSubscription: 'Your Subscription',
    yourPlans: 'Your Plans',
    isObsolete:
      'Your subscription cannot be modified. Replace obsolete products by new ones to enable changes',
    free: 'Free',
    licenseKeys: 'License keys',
    updatePaymentMethod: 'Update payment method',
    cancelSubscription: 'Cancel subscription',
    undoCancel: 'Undo subscription cancellation',
    undoPause: 'Undo subscription pause',
    updatedSuccessfully: 'Subscription updated successfully',
    pendingRefundRequest:
      'Cannot make further changes to this subscription while there is a pending refund request',
    errorUpdating:
      "There was an error updating your subscription. Please send an email to support@metrica-sports.com and we'll be happy to help you"
  },
  products: {
    code: 'CodePad',
    basic: 'Unlimited Codes & Annotations',
    fullBasic: 'Manual Field Tracking',
    lite: 'Automatic Player & Field Tracking',
    featuresIncluded: 'Features included:',
    freeFeatures: 'Free Features',
    freeFeature1: '✓ Post-game coding (Code Table with up to 4 codes)',
    freeFeature2: '✓ Keyframing',
    freeFeature3: '✓ Telestration (up to 50 annotations per month)',
    freeFeature4: '✓ Video Exports',
    addOns: 'Add-ons',
    liteDescription:
      'Advanced coding & advanced visualizations. All automatic, making it 3x faster than Full Basic!',
    otherDescription: 'Advanced coding features with basic telestration and limited annotations',
    remove: 'Remove',
    minutesPack: 'Cloud Minutes Pack',
    minutesDescription: 'Top up any plan. Get minute packs for Automated Tracking Data',
    totalPlan: 'Total: %{currency} %{cost} %{cycle}',
    totalPack: 'Total: %{currency} %{cost}',
    starter: {
      addon1: 'Unlimited Telestration in pauses',
      addon2: 'Unlimited Coding features (CodePad, Live Coding and XML Import)',
      feature1: '✓ Post-game coding (Code Table with up to 4 codes)',
      feature2: '✓ Telestration in pauses (up to 50 annotations per month)',
      feature3: '✓ Video Exports',
      starterPauseDescription:
        'This plan unlocks the limitation of telestration in pauses from 50 per month to unlimited. The limitations in coding features remains from Free Starter.',
      starterCodeDescription:
        'This plan unlocks the limitation of coding features such as CodePad, Live Coding and XML Import. It also releases the limit of 4 codes per Video Project to unlimited codes. The limitations in telestration features remains from Free Starter.',
      starterCodePauseDescription:
        'This plan unlocks the limitation of coding features such as CodePad, Live Coding and XML Import. It also releases the limit of 4 codes per Video Project to unlimited codes, and unlocks the limitation of telestration in pauses from 50 per month to unlimited.'
    },
    essentials: {
      addon1: 'Unlimited Coding features (CodePad, Live Coding and XML Import)',
      addon2: 'Manual Field Tracking',
      feature1: '✓ Post-game coding (Code Table with up to 4 codes)',
      feature2: '✓ Unlimited telestration in pauses',
      feature3: '✓ Dynamic Telestration',
      feature4: '✓ 3 ATD minutes pack',
      essentialsDescription:
        "This plan enables Dynamic Telestration available to add unlimited motion visualizations when playing the video and in pauses. This plan doesn't include Manual Field Tracking, so Speed and Distance visualizations are not included. The coding features remain limited.",
      essentialsCodeDescription:
        "This plan enables Dynamic Telestration available to add unlimited motion visualizations when playing the video and in pauses. This plan doesn't include Manual Field Tracking, so Speed and Distance visualizations are not included. It also unlocks the limitation of coding features such as CodePad, Live Coding and XML Import and releases the limit of 4 codes per Video Project to unlimited codes.",
      essentialsMftDescription:
        'This plan enables Dynamic Telestration available to add unlimited motion visualizations when playing the video and in pauses, and also activates Manuel Field Tracking to add speed and distances visualizations. The coding features remain limited.',
      essentialsMftCodeDescription:
        'This plan enables Dynamic Telestration available to add unlimited motion visualizations when playing the video and in pauses, and also activates Manuel Field Tracking to add speed and distances visualizations. It also unlocks the limitation of coding features such as CodePad, Live Coding and XML Import and releases the limit of 4 codes per Video Project to unlimited codes.'
    },
    prime: {
      addon1: 'Manual Field Tracking',
      addon2: 'Preset Telestration (customize and save your own visualizations)',
      feature1: '✓ Full access to Play2 with all the "Full Essentials" Plan features',
      feature2: '✓ Access to Play3 with:',
      feature2_1: 'Sequential Pauses',
      feature2_2: 'Advanced Telestration',
      feature2_3: 'Custom Video Export',
      feature3: '✓ 10 ATD minutes pack',
      primeDescription:
        'This plan includes full access to Play2 with all the "Essentials + Code ∞" Plan features and enables Play3 with advanced telestration.',
      primeMftDescription:
        'This plan includes full access to Play2 with all the "Full Essentials" Plan features and enables Play3 with advanced telestration.',
      primePresetsDescription:
        'This plan includes full access to Play2 with all the "Essentials + Code ∞" Plan features and enables Play3 with advanced telestration.',
      primePresetsMftDescription:
        'This plan includes full access to Play2 with all the "Full Essentials" Plan features and enables Play3 with advanced telestration.'
    },
    pro: {
      feature1: '✓ Automatic Player Tracking',
      feature2: '✓ Automatic Field Tracking',
      feature3: '✓ Full access to Play2 with all the "Full Essentials" Plan features',
      feature4: '✓ Access to Play3 with:',
      feature4_1: 'Sequential Pauses',
      feature4_2: 'Advanced Telestration',
      feature4_3: 'Custom Video Export',
      feature4_4: 'Preset Telestration (customize and save your own visualizations)',
      feature5: '✓ 100 ATD minutes pack',
      description:
        'This plan includes full access to Play2 with all the "Full Essentials" Plan features and access to Play3 with all the "Full Prime" Plan features.'
    },
    elite: {
      feature1: '✓ Shared Cloud Storage',
      feature2: '✓ Tracking Data Integration',
      feature3: '✓ Correction Manager',
      feature4: '✓ RESTful API',
      feature5: '✓ 5.000 ATD minutes pack',
      description:
        'Automate and customize your entire analysis workflow. This plan includes full access to Play2 with all the "Full Essentials" Plan features and access to Play3 with all the "Pro" Plan features.'
    }
  },
  shoppingCart: {
    title: 'Set up your Play',
    subtitle: 'Choose your Play plan and add-ons to elevate your performance.'
  }
}
