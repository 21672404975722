/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
// import PropTypes                            from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import userService from '../../services/user'
import * as Constants from '../../constants'
import ForgotForm from './components/ForgotForm'
import getErrorMessage from '../../utils/getErrorMessage'

const theme = Constants.theme
const styles = {}
styles.container = css`
  height: 100%;
  width: 100%;
  background: linear-gradient(#fff, #cccccc);
  display: flex;
  flex-direction: center;
  align-items: center;
`
styles.loginBox = css`
  width: 460px;
  height: 530px;
  background: linear-gradient(#e9f75f, #b4ed4b);
  border-radius: 5px;
  margin: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative;
`
styles.title = css`
  text-align: center;
  color: ${theme.palette.common.charolBlack};
  text-transform: uppercase;
  font-weight: lighter;
  margin-top: 40px;
`

const theme2 = createMuiTheme({
  palette: {
    primary: {
      light: theme.palette.common.white,
      main: theme.palette.common.white,
      dark: theme.palette.common.white,
      contrastText: theme.palette.common.white
    },
    secondary: {
      light: theme.palette.primary.light,
      main: theme.palette.common.white,
      dark: theme.palette.primary.dark,
      contrastText: theme.palette.common.white
    },
    common: {
      black: theme.palette.common.charolBlack,
      white: theme.palette.common.white
    },
    text: {
      primary: theme.palette.common.white,
      secondary: theme.palette.common.white,
      hint: theme.palette.common.white
    },
    error: { main: theme.palette.error.dark }
  },
  typography: {
    useNextVariants: true
  }
})

class Forgot extends Component {
  onSubmitReset = async (values, { setSubmitting }) => {
    try {
      await userService.generateNewPassword(values.email)
      toast.success(window.I18n.t('forgotPassword.success'))
    } catch (error) {
      console.error(error)

      const errorMsg = getErrorMessage(error)
      let toastMessage
      if (errorMsg === Constants.API_MESSAGES.adminNoResetPassword) {
        toastMessage = window.I18n.t('forgotPassword.adminNoResetPassword')
      } else {
        toastMessage = window.I18n.t('forgotPassword.notFound')
      }

      toast.error(toastMessage)
    } finally {
      setSubmitting(false)
    }
  }

  render() {
    return (
      <div css={styles.container}>
        <div css={styles.loginBox}>
          <h1 css={styles.title}>Cloud Engine</h1>
          <MuiThemeProvider theme={theme2}>
            <ForgotForm onSubmit={this.onSubmitReset} />
          </MuiThemeProvider>
        </div>
      </div>
    )
  }
}

export default connect()(Forgot)
