/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import { toast } from 'react-toastify'
import userService from '../../../services/user'
import ActionTypes from '../../../constants/actionTypes'
import { history } from '../../App'
import SettingsForm from './DetailsForm'
import DeleteModal from '../../admin/components/DeleteUserModal'
import tokenService from '../../../services/token'
import getHighestPriorityOrganization from '../../../utils/getHighestPriorityOrganization'

const styles = {}
styles.container = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
styles.loginBox = css`
  width: 100%;
  position: relative;
`

class Details extends Component {
  state = {
    showDeleteModal: false
  }

  onSubmit = async (values, { setSubmitting }) => {
    try {
      if (values.password) {
        const payload = { password: values.password }
        await userService.update(this.props.user._id, payload)
      }

      history.push('/')
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg'))
    } finally {
      setSubmitting(false)
    }
  }

  persistNotification = async payload => {
    try {
      await userService.update(this.props.user._id, payload)
      const user = { ...this.props.user, email_notifications: payload.email_notifications }
      this.props.dispatch({ type: ActionTypes.UPDATE_USER, payload: { user } })
      const mssg = payload.email_notifications
        ? window.I18n.t('settings.emailNotActivated')
        : window.I18n.t('settings.emailNotDeactivated')
      toast.info(mssg)
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.genericError'))
    }
  }

  persistLanguage = async payload => {
    try {
      await userService.update(this.props.user._id, payload)
      const user = { ...this.props.user, language: payload.language }
      this.props.dispatch({ type: ActionTypes.UPDATE_USER, payload: { user } })
      toast.info(window.I18n.t('settings.languageChanged'))
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.genericError'))
    }
  }

  deleteUser = async payload => {
    try {
      await userService.archive(this.props.user._id, payload)
      tokenService.delete()
      this.props.dispatch({ type: ActionTypes.LOGOUT })
      history.replace('/login')
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.genericError'))
    }
  }

  goBack = () => {
    history.push('/')
  }

  getOrganizationPrefix = () => {
    const highestPriorityOrganization = getHighestPriorityOrganization(this.props.user)

    return (
      highestPriorityOrganization &&
      highestPriorityOrganization.organization &&
      highestPriorityOrganization.organization.prefix
    )
  }

  toggleDeleteUserModal = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal })
  }

  render() {
    return (
      <div css={styles.container}>
        <div css={styles.loginBox}>
          <SettingsForm
            goBack={this.goBack}
            onSubmit={this.onSubmit}
            user={this.props.user}
            subscriptions={this.props.subscriptions}
            persistNotification={this.persistNotification}
            persistLanguage={this.persistLanguage}
            prefix={this.getOrganizationPrefix()}
            deleteUserModal={this.toggleDeleteUserModal}
          />
        </div>

        {this.state.showDeleteModal && (
          <DeleteModal
            title={window.I18n.t('deleteModal.title')}
            description={window.I18n.t('deleteModal.description')}
            onClose={this.toggleDeleteUserModal}
            onDelete={this.deleteUser}
          />
        )}
      </div>
    )
  }
}

Details.defaultProps = {}

Details.propTypes = {
  user: PropTypes.object.isRequired,
  subscriptions: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default Details
