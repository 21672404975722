import { CurrenciesWithoutDecimals } from '../constants'

const normalizePrice = (v, currencyCode) => {
  const decimals = CurrenciesWithoutDecimals.includes(currencyCode) ? 0 : 2
  const factor = 10 ** decimals

  return Number(v) / factor
}

export default normalizePrice
