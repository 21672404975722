import lscache from 'lscache'

// enable warnings
if (process.env.NODE_ENV !== 'production') {
  lscache.enableWarnings(true)
}

const tokenKey = 'MetricaSportsCloud'
const tokenService = {}

// Set token
tokenService.set = (token, id) => lscache.set(tokenKey, { token, id })

// Get token
tokenService.get = () => lscache.get(tokenKey)

// Delete token
tokenService.delete = () => lscache.flush()

export default tokenService
