/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Formik } from 'formik'
import { theme } from '../../../constants'
import logo from '../../../assets/logo-black.png'

const styles = {}
styles.inputWrapper = css`
  text-align: center;
  margin: auto;
  padding: 0 60px;
`
styles.poweredBy = css`
  font-size: 18px;
  font-weight: lighter;
  color: ${theme.palette.common.greyMetrica};
  margin-right: 7px;
`
styles.footerWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`
styles.progressBarWrapper = css`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`
styles.buttonStyle = { background: theme.palette.common.electricBlue, color: 'white' }

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.charolBlack
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

const inputPropsEmail = { autoComplete: 'new-email' }
const inputColorText = {
  input: { color: theme.palette.common.black },
  inputFocused: { color: theme.palette.common.black },
  label: { color: theme.palette.common.black }
}

class ForgotForm extends Component {
  formInitialValues = { email: '' }

  validateForm = values => {
    const errors = {}
    if (!values.email) {
      errors.email = window.I18n.t('common.required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = window.I18n.t('login.invalidEmail')
    }
    return errors
  }

  render() {
    return (
      <Formik
        initialValues={this.formInitialValues}
        validate={this.validateForm}
        onSubmit={this.props.onSubmit}
      >
        {formik => (
          <form noValidate onSubmit={formik.handleSubmit} method="POST">
            <div
              css={[
                styles.inputWrapper,
                css`
                  margin-top: 90px;
                `
              ]}
            >
              <CssTextField
                label={window.I18n.t('login.enterYourEmail')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                type="email"
                name="email"
                inputProps={inputPropsEmail}
                margin="normal"
                css={inputColorText}
                error={formik.errors.email && formik.touched.email && !!formik.errors.email}
                helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                fullWidth
              />
            </div>
            <div
              css={css`
                padding: 0 90px;
                margin-top: 35px;
              `}
            >
              <Button
                variant="contained"
                fullWidth
                disabled={formik.isSubmitting}
                style={styles.buttonStyle}
                type="submit"
              >
                {window.I18n.t('common.reset')}
              </Button>
            </div>
            <div css={styles.progressBarWrapper}>
              {formik.isSubmitting && <CircularProgress color="primary" size={30} thickness={4} />}
            </div>
            <div css={styles.footerWrapper}>
              <span css={styles.poweredBy}>Powered by</span>
              <img
                src={logo}
                alt="Logo"
                css={css`
                  width: 160px;
                  margin-left: 7px;
                `}
              />
            </div>
          </form>
        )}
      </Formik>
    )
  }
}

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default ForgotForm
