/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import surfaceLogo from '../../../../assets/surface-icon.svg'
import { theme } from '../../../../constants'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logoImg: css`
    width: 18px;
  `,
  disableIcon: css`
    color: lightgrey;
  `
}

function SurfaceMenuItem(props) {
  let tooltipMessage = ''
  if (props.uploadingSurface) {
    tooltipMessage = window.I18n.t('surfaces.uploading')
  } else if (!props.isSurfaceUploadAvailable) {
    tooltipMessage = window.I18n.t('surfaces.needsElite')
  } else if (!props.vp.is_football_side_view) {
    tooltipMessage = window.I18n.t('surfaces.needsFootballSideView')
  } else if (!props.deletingVp) {
    tooltipMessage = window.I18n.t('surfaces.upload')
  }

  const disabledItem =
    props.uploadingSurface ||
    !props.isSurfaceUploadAvailable ||
    props.deletingVp ||
    !props.vp.is_football_side_view
  return (
    <div
      css={css`
        ${styles.itemContainer};
      `}
    >
      <div
        css={css`
          ${styles.icon};
        `}
      >
        <span
          css={css`
            ${styles.innerIcon};
            opacity: 1;
            transform: rotate(180deg);
          `}
        >
          <img src={surfaceLogo} css={styles.logoImg} />
        </span>
      </div>
      <div css={styles.content} className="video-menu-item-content">
        <div
          css={css`
            ${styles.name};
            color: ${theme.palette.common.black};
          `}
        >
          {window.I18n.t('surfaces.title')}
        </div>
        <div css={styles.icons}>
          <Tooltip title={tooltipMessage} enterDelay={400} placement="top">
            <Icon
              css={css`
                ${styles.iconTag};
                ${styles.iconButton};
                color: ${disabledItem ? 'lightgrey' : theme.palette.secondary.main};
              `}
              onClick={() => {
                if (!disabledItem) {
                  props.uploadSurface()
                }
              }}
            >
              cloud_upload
            </Icon>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

SurfaceMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  uploadingSurface: PropTypes.bool.isRequired,
  isSurfaceUploadAvailable: PropTypes.bool.isRequired,
  uploadSurface: PropTypes.func.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default SurfaceMenuItem
