import React from 'react'
import Autocomplete from '../../components/Autocomplete'

const autocompleteOnChange = (form, field) => changes =>
  form.setFieldValue(field.name, changes.value || changes, true)

function AutocompleteField({ field, form, ...other }) {
  const currentError = form.errors[field.name]

  return (
    <Autocomplete
      name={field.name}
      error={form.touched[field.name] && Boolean(currentError)}
      errorText={form.touched[field.name] && currentError}
      onBlur={field.onBlur}
      onChange={autocompleteOnChange(form, field)}
      {...other}
    />
  )
}

export default AutocompleteField
