import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Switch } from '@material-ui/core'
import { theme } from '../constants'

const useStyles = (
  enabledText,
  enabledColor,
  enabledBackground,
  disabledText,
  disabledColor,
  disabledBackground
) => {
  return makeStyles({
    root: {
      width: '60px',
      height: '24px',
      padding: '0px'
    },
    container: {
      position: 'relative'
    },
    switchBase: {
      color: '#818181',
      padding: '1px'
    },
    thumb: {
      color: 'white',
      width: '19px',
      height: '19px',
      margin: '1px'
    },
    trackOn: {
      borderRadius: '20px',
      backgroundColor: enabledBackground,
      display: 'flex',
      alignItems: 'center',
      opacity: '1',
      '&:after, &:before': {
        fontSize: '11px',
        position: 'absolute'
      },
      '&:after': {
        content: `'${enabledText.toUpperCase()}'`,
        fontSize: '11px',
        color: enabledColor,
        fontWeight: 'bold',
        left: `${[20, 16, 12, 6, 4][Math.min(Math.max(1, enabledText.length), 5) - 1]}px`
      }
    },
    trackOff: {
      borderRadius: '20px',
      backgroundColor: disabledBackground,
      display: 'flex',
      alignItems: 'center',
      opacity: '1',
      '&:after, &:before': {
        color: disabledColor,
        position: 'absolute'
      },
      '&:before': {
        content: `'${disabledText.toUpperCase()}'`,
        fontSize: '11px',
        fontWeight: 'bold',
        right: `${[20, 14, 10, 6, 4][Math.min(Math.max(1, disabledText.length), 5) - 1]}px`
      }
    },
    checked: {
      color: '#23bf58 !important',
      right: '1px'
    }
  })
}
function TextSwitch(props) {
  const classes = useStyles(
    props.enabledText || window.I18n.t('common.yes'),
    props.enabledColor,
    props.enabledBackground,
    props.disabledText || window.I18n.t('common.no'),
    props.disabledColor,
    props.disabledBackground
  )()

  return (
    <div className={classes.container} style={props.containerStyles}>
      <Switch
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        color={props.color}
        size="medium"
        disabled={props.disabled}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: props.checked ? classes.trackOn : classes.trackOff,
          checked: classes.checked
        }}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  )
}

TextSwitch.defaultProps = {
  disabled: false,
  checked: false,
  enabledText: '',
  enabledColor: theme.palette.common.darkGrey,
  enabledBackground: theme.palette.common.mainGreen,
  disabledText: '',
  disabledColor: '#FFFFFF',
  disabledBackground: '#818181',
  containerStyles: {}
}

TextSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  enabledText: PropTypes.string,
  enabledColor: PropTypes.string,
  enabledBackground: PropTypes.string,
  disabledText: PropTypes.string,
  disabledColor: PropTypes.string,
  disabledBackground: PropTypes.string,
  containerStyles: PropTypes.object,
  disabled: PropTypes.bool
}

export default TextSwitch
