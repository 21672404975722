/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  withStyles
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment/dist/moment'
import { toast } from 'react-toastify'
import { DefaultCurrencyCode, theme } from '../../../constants'
import { ProductNames2024 } from '../../../constants/payments'
import orderService from '../../../services/order'
import * as Actions from '../../admin/actions'
import Checkout from '../../components/Checkout'
import RecurrentProduct from '../../components/RecurrentProduct'
import sleep from '../../../utils/sleep'
import { history } from '../../App'

const styles = {}
styles.container = css`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
`
styles.loadingContainer = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.5;
  z-index: 1;
`
styles.header = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 1px solid ${theme.palette.common.charolBlack}80;
  border-bottom: 1px solid ${theme.palette.common.charolBlack}80;
`
styles.title = css`
  font-size: 16px;
  font-weight: bold;
`
styles.description = css`
  font-size: 14px;
`
styles.deprecated = css`
  font-size: 12px;
  font-style: italic;
`
styles.content = css`
  display: flex;
  padding-top: 24px;
`
styles.dateContainer = css`
  display: flex;
  flex-direction: column;
`
styles.actionsContainer = css`
  display: flex;
`
styles.buttonContainer = css`
  margin-left: 16px;
`
styles.newProductButtonContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`
styles.productsContainer = css`
  width: 700px;
  display: flex;
  flex-direction: column;
  margin-right: 32px;
`
styles.checkoutContainer = css`
  width: 400px;
  height: fit-content;
  display: flex;
`
styles.checkoutPlanContainer = css`
  display: flex;
  flex-direction: column;
`
styles.checkoutPlanTitle = css`
  font-size: 16px;
  font-weight: bold;
`
styles.checkoutPaymentContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 16px;
`
styles.checkoutTotalContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`
styles.checkoutTotalText = css`
  font-size: 16px;
  font-weight: bold;
`
styles.vatText = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`
styles.checkoutButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
`
styles.actionButton = {
  fontSize: '12px',
  textTransform: 'none'
}
styles.newProductButton = {
  color: theme.palette.common.charolBlack,
  textTransform: 'none'
}
styles.checkoutButton = {
  height: '32px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.blue,
  textTransform: 'none',
  borderRadius: '2px',
  padding: '0px 6px'
}

const CssProductSelect = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.mainGreen,
      padding: '0px 32px 0px 12px',
      height: '32px',
      borderRadius: '4px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

function Plan(props) {
  const [dropdownProducts, setDropdownProducts] = React.useState([])
  const [isMonthly, setIsMonthly] = React.useState(true)
  const [cycle, setCycle] = React.useState('/month')
  const [countryTaxRate, setCountryTaxRate] = React.useState(0)
  const [countryCurrencyCode, setCountryCurrencyCode] = React.useState('')
  const [plansOriginal, setPlansOriginal] = React.useState([])
  const [plansCheckout, setPlansCheckout] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [enableUpdateSubscription, setEnableUpdateSubscription] = React.useState(false)
  const [enableUpdatePaymentMethod, setEnableUpdatePaymentMethod] = React.useState(false)

  React.useEffect(() => {
    if (!props.paddle || props.products.length === 0) {
      return
    }

    const previewRequest = {
      items: [{ priceId: props.products[0].priceId, quantity: 1 }],
      customerIpAddress: props.userIp
    }

    if (props.customer && props.customer.id) {
      previewRequest.customerId = props.customer.id
    }

    props.paddle.PricePreview(previewRequest).then(preview => {
      setCountryCurrencyCode(preview.data.currencyCode)
      setCountryTaxRate(Number(preview.data.details.lineItems[0].taxRate) * 100.0)
    })
  }, [props.paddle, props.customer, props.user, props.userIp, props.products])

  React.useEffect(() => {
    if (props.monthlyProducts.length > 0 || props.yearlyProducts.length > 0) {
      setLoading(false)
    }
  }, [props.monthlyProducts, props.yearlyProducts])

  React.useEffect(() => {
    if (props.subscription.order) {
      // Read items from order and link them to the products according to current billing cycle.
      let planProducts = isMonthly ? props.monthlyProducts : props.yearlyProducts
      let newPlansCheckout = props.subscription.order.items.reduce((acc, v) => {
        const product = planProducts.find(p => p.priceId === v.id)
        if (product) {
          acc.push({ ...product, quantity: v.quantity })
        }

        return acc
      }, [])

      // If there are no products linked for the current cycle, try the other one.
      if (newPlansCheckout.length === 0) {
        planProducts = !isMonthly ? props.monthlyProducts : props.yearlyProducts
        newPlansCheckout = props.subscription.order.items.reduce((acc, v) => {
          const product = planProducts.find(p => p.priceId === v.id)
          if (product) {
            acc.push({ ...product, quantity: v.quantity })
          }

          return acc
        }, [])

        // If there are products in the other cycle, switch to it.
        if (newPlansCheckout.length > 0) {
          setIsMonthly(!isMonthly)
        }
      }

      setPlansOriginal(newPlansCheckout)
      setPlansCheckout(_.cloneDeep(newPlansCheckout))
    }

    updateDropdownProducts(isMonthly ? props.monthlyProducts : props.yearlyProducts)
  }, [props.subscription, props.monthlyProducts, props.yearlyProducts])

  React.useEffect(() => {
    setCycle(isMonthly ? '/month' : '/year')

    // Replace existing items with the products that are available in the new cycle.
    if (plansCheckout.length > 0) {
      const planProducts = isMonthly ? props.monthlyProducts : props.yearlyProducts
      const newPlansCheckout = plansCheckout.reduce((acc, v) => {
        const product = planProducts.find(p => p.productCode === v.productCode)
        if (product && product.priceId) {
          acc.unshift({ ...product, quantity: v.quantity })
        }

        return acc
      }, [])
      setPlansCheckout(newPlansCheckout)
    }

    updateDropdownProducts(isMonthly ? props.monthlyProducts : props.yearlyProducts)
  }, [isMonthly])

  React.useEffect(() => {
    return setEnableUpdatePaymentMethod(
      props.subscription.order && props.subscription.order.status === 'past_due'
    )
  }, [props.subscription])

  React.useEffect(() => {
    return setEnableUpdateSubscription(
      _.differenceWith(plansCheckout, plansOriginal, _.isEqual).length !== 0 ||
        _.differenceWith(plansOriginal, plansCheckout, _.isEqual).length !== 0
    )
  }, [plansCheckout, plansOriginal])

  function updateDropdownProducts(newProducts) {
    const newDropdownProducts = newProducts
      .filter(v => !v.isDeprecated)
      .map(v => {
        const product = ProductNames2024.find(w => w.value === v.productCode)

        return {
          value: v.priceId,
          label: window.I18n.t('plans.plan', {
            planName: product.translationKey
              ? window.I18n.t(`plans.${product.translationKey}`)
              : product.translation
          })
        }
      })
    newDropdownProducts.unshift({
      value: ProductNames2024[0].value,
      label: window.I18n.t(`plans.${ProductNames2024[0].translationKey}`)
    })
    setDropdownProducts(newDropdownProducts)
  }

  function handleSelectProduct(e) {
    const productId = e.target.value
    const product = props.products.find(v => v.priceId === productId)
    if (!product) {
      return
    }

    const itemIndex = plansCheckout.findIndex(v => v.priceId === product.priceId)
    if (itemIndex !== -1) {
      const newPlansCheckout = [...plansCheckout]
      newPlansCheckout[itemIndex].quantity = Math.min(99, newPlansCheckout[itemIndex].quantity + 1)
      setPlansCheckout(newPlansCheckout)
    } else {
      // If there's only one deprecated product, replace it with the new one.
      const newPlansCheckout = [{ ...product, quantity: 1 }]
      if (plansCheckout.length === 1 && plansCheckout[0].isDeprecated) {
        newPlansCheckout[0].quantity = plansCheckout[0].quantity
      } else {
        newPlansCheckout.push(...plansCheckout)
      }

      setPlansCheckout(newPlansCheckout)
    }
  }

  function handleUpdatePlans(plans) {
    const newPlansCheckout = [...plansCheckout]

    let lastIndex = plans.length - 1
    plans.forEach(product => {
      const index = newPlansCheckout.findIndex(v => v.priceId === product.priceId)
      if (index === -1) {
        newPlansCheckout.splice(lastIndex, 0, product)
      } else {
        newPlansCheckout[index] = product
        lastIndex = index
      }
    })

    // Remove empty products.
    setPlansCheckout(newPlansCheckout.filter(v => v.quantity > 0))
  }

  async function handlePaymentMethod() {
    const response = await orderService.updatePaymentMethodSubscription(
      props.subscription.order.code
    )

    props.paddle.Checkout.open({
      settings: {
        allowLogout: false,
        locale: props.user.language,
        theme: 'dark'
      },
      transactionId: response.data.id
    })
  }

  async function handleCheckout() {
    // If user doesn't have a subscription, create it opening Paddle Checkout to fill card details.
    if (props.subscription.order) {
      try {
        setLoading(true)

        const items = plansCheckout.map(v => ({ id: v.priceId, quantity: v.quantity }))
        const response = await orderService.updateSubscription(props.subscription.order.code, items)
        const subscription = response.data
        if (subscription) {
          await sleep(5000)
          await Actions.updateUser()

          toast.info(window.I18n.t('subscription.updatedSuccessfully'))
        } else {
          throw new Error(window.I18n.t('subscription.pendingRefundRequest'))
        }
      } catch (e) {
        toast.error(window.I18n.t('subscription.errorUpdating'))
      } finally {
        await Actions.updateUser()
        setLoading(false)
      }
    } else {
      const items = plansCheckout.map(v => ({ priceId: v.priceId, quantity: v.quantity }))
      const checkoutSettings = {
        settings: {
          allowLogout: false,
          locale: props.user.language,
          theme: 'dark'
        },
        customer: {},
        items
      }

      if (props.customer && props.customer.id) {
        checkoutSettings.customer.id = props.customer.id
      } else {
        checkoutSettings.customer.email = props.user.email
      }

      props.paddle.Checkout.open(checkoutSettings)
    }
  }

  const showUndoButton =
    props.subscription.order &&
    !props.subscription.order.renewal_at &&
    (props.subscription.order.cancel_at || props.subscription.order.pause_at)
  const showPauseOrCancelButton = !showUndoButton

  let buttonText = window.I18n.t('checkout.proceed')
  let hintText = window.I18n.t('checkout.discountCodes')

  if (props.subscription.order) {
    if (!enableUpdatePaymentMethod) {
      buttonText = window.I18n.t('checkout.updateSubscription')
      hintText = ''
    } else {
      buttonText = window.I18n.t('checkout.updatePaymentMethod')
      hintText = window.I18n.t('checkout.unpaid')
    }
  }

  return (
    <div css={styles.container}>
      {loading && (
        <div css={styles.loadingContainer}>
          <CircularProgress size={32} thickness={4} />
        </div>
      )}

      <div css={styles.header} style={{ pointerEvents: loading ? 'none' : 'inherit' }}>
        <div css={styles.dateContainer}>
          <span css={styles.title}>{window.I18n.t('subscription.yourSubscription')}</span>
          <span css={styles.description}>
            {!props.subscription.order && <span>{window.I18n.t('subscription.free')}</span>}

            {props.subscription.order && props.subscription.order.pause_at && (
              <span>
                <span css={styles.infoPlanDescription}>{window.I18n.t('settings.pauseAt')}</span>
                <span> </span>
                <span css={styles.fontBolder}>
                  {_getDateFormatted(props.subscription.order.pause_at)}
                </span>
              </span>
            )}

            {props.subscription.order && props.subscription.order.cancel_at && (
              <span>
                <span css={styles.infoPlanDescription}>{window.I18n.t('settings.cancelAt')}</span>
                <span> </span>
                <span css={styles.fontBolder}>
                  {_getDateFormatted(props.subscription.order.cancel_at)}
                </span>
              </span>
            )}

            {props.subscription.order && showPauseOrCancelButton && (
              <span>
                <span css={styles.infoPlanDescription}>{window.I18n.t('settings.renewAt')}</span>
                <span> </span>
                <span css={styles.fontBolder}>
                  {_getDateFormatted(props.subscription.order.renewal_at)}
                </span>
              </span>
            )}
          </span>

          {plansCheckout.some(v => v.isDeprecated) && (
            <span css={styles.deprecated}>{window.I18n.t('subscription.isObsolete')}</span>
          )}
        </div>

        <div css={styles.actionsContainer}>
          {props.subscription.order && (
            <div css={styles.buttonContainer}>
              <Button
                variant="outlined"
                type="submit"
                style={styles.actionButton}
                onClick={() => {
                  history.push(`/successful-payment?order=${props.subscription.order.code}`)
                }}
              >
                {window.I18n.t('subscription.licenseKeys')}
              </Button>
            </div>
          )}

          {props.subscription.order && showPauseOrCancelButton && (
            <div css={styles.buttonContainer}>
              <Button
                variant="outlined"
                type="submit"
                style={styles.actionButton}
                onClick={handlePaymentMethod}
              >
                {window.I18n.t('subscription.updatePaymentMethod')}
              </Button>
            </div>
          )}

          {props.subscription.order && showPauseOrCancelButton && (
            <div css={styles.buttonContainer}>
              <Button
                variant="outlined"
                type="submit"
                style={styles.actionButton}
                onClick={async () => {
                  if (!window.profitwell) {
                    setLoading(true)
                    await orderService.cancelSubscription(props.subscription.order.code)
                    await sleep(5000)
                    await Actions.updateUser()
                    toast.info(window.I18n.t('settings.planCanceled'))
                    setLoading(false)

                    return
                  }

                  const result = await window.profitwell('init_cancellation_flow', {
                    user_id: props.subscription.order.customer_id,
                    subscription_id: props.subscription.order.code
                  })

                  // This means the customer accepted a salvage attempt or salvage offer.
                  // Thus, we have to update the user in case he has downgrade the plan.
                  if (result.status === 'retained') {
                    await sleep(5000)
                    await Actions.updateUser()
                    return
                  }

                  // This means the customer either aborted the flow (i.e. they clicked on
                  // "never mind, I don't want to cancel"), or could also be the case the
                  // widget couldn't be shown properly for some reason.
                  if (result.status === 'aborted' || result.status === 'error') {
                    return
                  }

                  // At this point, the customer ended deciding to cancel (i.e.
                  // they rejected the salvage attempts and the salvage offer).
                  setLoading(true)
                  await orderService.cancelSubscription(props.subscription.order.code)
                  await sleep(5000)
                  await Actions.updateUser()
                  toast.info(window.I18n.t('settings.planCanceled'))
                  setLoading(false)
                }}
              >
                {window.I18n.t('subscription.cancelSubscription')}
              </Button>
            </div>
          )}

          {props.subscription.order && showUndoButton && (
            <div css={styles.buttonContainer}>
              <Button
                variant="outlined"
                type="submit"
                style={styles.actionButton}
                onClick={async () => {
                  setLoading(true)
                  await orderService.undoSchedulesSubscription(props.subscription.order.code)
                  await sleep(5000)
                  await Actions.updateUser()
                  toast.info('Subscription cancellation disabled')
                  setLoading(false)
                }}
              >
                {window.I18n.t(
                  `subscription.${props.subscription.order.cancel_at ? 'undoCancel' : 'undoPause'}`
                )}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div css={styles.content} style={{ pointerEvents: loading ? 'none' : 'inherit' }}>
        <div css={styles.productsContainer}>
          <div css={styles.newProductButtonContainer}>
            <span css={styles.title}>{window.I18n.t('subscription.yourPlans')}</span>
            <FormControl>
              <CssProductSelect
                select
                variant="outlined"
                name="product"
                value="add"
                onChange={handleSelectProduct}
              >
                {dropdownProducts.map(v => {
                  if (!v.value) {
                    return null
                  }

                  return (
                    <MenuItem key={`menu-item-${v.value}`} value={v.value}>
                      <span css={styles.productDropdownLabel}>{v.label}</span>
                    </MenuItem>
                  )
                })}
              </CssProductSelect>
            </FormControl>
          </div>

          {
            // Display free plan.
            (props.monthlyProducts.length > 0 || props.yearlyProducts.length > 0) &&
              plansCheckout.length === 0 && (
                <RecurrentProduct currencyCode={countryCurrencyCode} cycle={cycle} />
              )
          }

          {plansCheckout.map(v => (
            <RecurrentProduct
              key={`recurrent-product-${v.id}`}
              product={v}
              // If the subscription exists and currency-code field is empty, it means it's in EUR.
              // Otherwise, use the country's currency-code.
              currencyCode={
                props.subscription.order
                  ? props.subscription.order.currency_code || DefaultCurrencyCode
                  : countryCurrencyCode
              }
              isMonthly={isMonthly}
              isShopping={false}
              cycle={cycle}
              showRemove={plansCheckout.length > 1}
              monthlyProducts={props.monthlyProducts}
              yearlyProducts={props.yearlyProducts}
              onUpdate={handleUpdatePlans}
            />
          ))}
        </div>

        <div css={styles.checkoutContainer}>
          <Checkout
            paddle={props.paddle}
            customer={props.customer}
            address={props.address}
            userIp={props.userIp}
            user={props.user}
            products={props.products}
            buttonDisabled={!enableUpdateSubscription && !enableUpdatePaymentMethod}
            buttonText={buttonText}
            hintText={hintText}
            totalsDisabled={enableUpdatePaymentMethod}
            subscription={props.subscription}
            plansCheckout={plansCheckout}
            // If the subscription exists and currency-code field is empty, it means it's in EUR.
            // Otherwise, use the country's currency-code.
            currencyCode={
              props.subscription.order
                ? props.subscription.order.currency_code || DefaultCurrencyCode
                : countryCurrencyCode
            }
            taxRate={
              props.subscription.order && Number.isFinite(props.subscription.order.tax_rate)
                ? props.subscription.order.tax_rate * 100.0
                : countryTaxRate
            }
            isMonthly={isMonthly}
            isShopping={false}
            onBillingCycle={setIsMonthly}
            onCheckout={enableUpdatePaymentMethod ? handlePaymentMethod : handleCheckout}
          />
        </div>
      </div>
    </div>
  )
}

function _getDateFormatted(date) {
  return moment(date).format('LL')
}

Plan.defaultProps = {
  paddle: undefined,
  customer: undefined,
  address: undefined
}

Plan.propTypes = {
  paddle: PropTypes.object,
  products: PropTypes.array.isRequired,
  monthlyProducts: PropTypes.array.isRequired,
  yearlyProducts: PropTypes.array.isRequired,
  subscription: PropTypes.object.isRequired,
  userIp: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  customer: PropTypes.object,
  address: PropTypes.object
}

export default Plan
