export default {
  addKeyModal: {
    action: 'להגיש מועמדות',
    errorMessage: 'שגיאה בהחלת מפתח. אם הבעיה נמשכת, צור קשר עם צוות התמיכה של Metrica Sports',
    minutesMessage: 'מפתחך עובד. כעת יש לך %{minutes} דקות זמינות.',
    placeholder: 'הקלד מפתח...',
    successMessage: 'המפתח עובד בהצלחה',
    title: 'הוסף מפתח'
  },
  admin: {
    NoGames: 'אין משחקים, הוסף את הראשון שלך...',
    NoResults: 'אין תוצאות...',
    addKey: 'הוסף מפתח',
    atd: 'וכו',
    automatedTrackingData: 'נתוני מעקב אוטומטיים (ATD)',
    automatedTrackingDataJobSuccess:
      'תהליך נתוני מעקב אוטומטי ב- %{vpName} הסתיים והוא זמין כעת ב- Metrica Play',
    automatedTrackingDataProcessedTooltip: 'נתוני מעקב אוטומטי מעובדים',
    awayTeam: 'צוות חוץ',
    awayTeamScore: 'ציון קבוצת חוץ',
    ballDetection: 'איתור כדורים',
    ballTracking: 'מעקב אחר כדורים',
    ballTrackingProcessedTooltip: 'זיהוי הכדור עובד',
    ballTrackingTooltip:
      'תהליך זה יזהה את הכדור ויאפשר לך לדמיין אותו בתוך ה-Live Formation (2D Pitch) ב-Play. קואורדינטות כדור יהיו זמינות גם בקובץ פורמט FIFA EPTS.',
    cancelATDProcessSuccessfully: 'תהליך נתוני מעקב אוטומטי בוטל ב- %{vpName}',
    cancelDataIntegrationProcessSuccessfully: 'תהליך שילוב הנתונים בוטל ב- %{vpName}',
    cancelEventsProcessSuccessfully: 'תהליך ה-Smart Tagging בוטל ב-%{vpName}.',
    cancelProcessTooltip: 'בטל תהליך',
    cancelSurfacesProcessSuccessfully: 'תהליך השטח בוטל ב- %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully: 'תהליך המעקב בפורמט FIFA בוטל ב- %{vpName}',
    category: 'קטגוריה',
    checking: 'בודק...',
    cloudMinutes: 'דקות ענן',
    competition: 'תַחֲרוּת',
    connectionFailed: 'חיבור נכשל',
    connectionFailedText:
      'נראה שיש בעיה לאחזר את פרטי המצב. אנא בדוק את חיבור האינטרנט שלך וטען מחדש את הדף. אם הבעיה נמשכת, אנא צור קשר עם התמיכה',
    correctedTracking: 'עיבוד תיקון מעקב',
    correctionUsage: 'האם תשתמש בפרויקט וידאו זה לתיקון מעקב?',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM) הוא מודול מונחה משתמש בתוך Play לתיקון רצועות שנוצרו בעבר עם ה-ATD שלנו, שיוצר נתונים ללא מזהים באופן אוטומטי עבור כל משחק כדורגל. השתמש ב-TCM כדי לתקן מסלולים שגויים ולהקצות להם זהות. צור איתנו קשר למידע נוסף.',
    createVideoProject: 'צור פרויקט וידאו',
    creatingGame: 'יוצר פרויקט וידאו...',
    dataIntegrationFailed:
      'שילוב הנתונים נכשל ב- %{vpName}. עבור לחלונית Data Integration, בדוק את הקבצים שלך ונסה שוב. אם השגיאה נמשכת, אנא צור קשר עם צוות התמיכה של Metrica Sports',
    dataIntegrationJobSuccess: 'תהליך שילוב הנתונים עבור %{vpName} הסתיים בהצלחה',
    date: 'תַאֲרִיך',
    description: 'תיאור',
    editedSuccessfully: '%{vpName} נערך בהצלחה',
    errorContactSupport: 'שְׁגִיאָה. צור קשר עם תמיכה',
    errorProcessTooltip: 'שגיאה בתהליך',
    eventsDetection: 'תיוג אוטומטי',
    eventsDetectionProcessedTooltip: 'Smart Tagging עובד',
    eventsDetectionTooltip: {
      footer: '*זמין לסרטונים שאורכם מעל 30 דקות',
      header: 'נצל את הטכנולוגיה שלנו כדי לזהות אוטומטית סדרת אירועים שמתרחשים במשחק*.',
      improved: 'משופר'
    },
    fieldRecognition: 'זיהוי שדה',
    fieldTrackingProcessedTooltip: 'זיהוי שדה עובד',
    fieldTrackingTooltip:
      'תהליך זיהוי שדות להוספת הדמיות עם ערכי מהירות ומרחק וכל השרטוטים בהתאם לשדה ב-Play.',
    fileDeletedSuccessfully: '%{fileName} נמחק בהצלחה עבור %{vpName}',
    fileUploadedError: 'שגיאה בהעלאת %{fileName} עבור %{vpName}',
    fileUploadedSuccessfully: '%{fileName} הועלה בהצלחה עבור %{vpName}',
    forkedGameLimit: 'פרויקט וידאו זה שייך לארגון אחר, כך שלא ניתן לשנות אותו',
    gameDeletedSuccessfully: '%{game} נמחק בהצלחה',
    gameVideoProject: 'פרויקט וידאו משחק',
    getMoreMinutes: 'לקבל יותר',
    homeTeam: 'קבוצת הבית',
    homeTeamScore: 'ציון קבוצת הבית',
    initializing: 'מאתחל...',
    jobFailed: 'תהליך %{process} נכשל עבור %{vpName}. אנא צור קשר עם צוות התמיכה של Metrica Sports',
    liveCoding: 'או להמשיך לקידוד חי?',
    liveCodingTooltip:
      'אתה יכול ליצור פרויקט וידאו מבלי לבחור קובץ וידאו למטרות קידוד חי. ברגע שיש לך את הסרטון תוכל להוסיף אותו כדי לקבל נתוני מעקב אוטומטיים בתוך פרויקט הווידאו המקודד.',
    localTeamOnTheLeft: 'האם קבוצת הבית ממוקמת בצד שמאל של המגרש במחצית הראשונה?',
    localTeamOnTheLeftTooltip:
      'על מנת לעבד תיוג אוטומטי, ציין באיזה צד של המגרש כל קבוצה נמצאת. בדוק את הסרטון שאתה מעלה כדי לאמת אותו.',
    noFootballSideViewTooltip:
      'פרויקט וידאו זה מכיל קובץ וידאו שאינו כדורגל/כדורגל עם זווית רחבה או שידור טלוויזיה, כך שרק זיהוי שחקן וצוות עובד.',
    noMatches: 'אין התאמה...',
    noSubscriptionOwnerTooltip:
      "צור קשר עם '%{email}', מנהל המנוי שלך, כדי לשדרג או עבור לדף התוכניות שלנו כדי ליצור משלך",
    noVideoFile: 'אין קובץ וידאו',
    notPossibleToDeleteThisEntry: 'לא ניתן למחוק ערך זה',
    numberOfHalves: 'כמה חצאים במשחק הזה יש?',
    numberOfHalvesTooltip: 'כדי לעבד תיוג אוטומטי, ציין כמה פרקי משחק ייכללו בסרטון שאתה מעלה.',
    oldAtdTooltip: 'סרטון זה עובד עם גרסה ישנה יותר של ATD',
    onRequest: 'בבקשה',
    organizations: 'ארגונים',
    place: 'מקום',
    playerTracking: 'זיהוי שחקנים וצוות',
    playerTrackingProcessedTooltip: 'זיהוי הנגן עובד',
    playerTrackingTooltip:
      'תהליך זה יאתר את השוערים ואת 10 שחקני השדה של כל קבוצה. זה יאפשר לך לדמיין את ה-Live Formation (2D Pitch) ולהוסיף חזותי Player באופן אוטומטי ב-Play. קואורדינטות השחקנים יהיו זמינות גם בקובץ פורמט FIFA EPTS.',
    postProcessingData: 'לאחר עיבוד נתונים...',
    preProcessingAtd: 'עיבוד מוקדם...',
    processingTooltip: 'מעבד',
    readyToUpload: 'מוכן להעלאה',
    search: 'לחפש...',
    selectVideoFile: 'בחר קובץ וידאו',
    settingUpAtd: 'מגדיר זיהוי...',
    settings: 'הגדרות',
    singleVideoProject: 'פרויקט וידאו בודד',
    startAtd: 'התחל את ATD',
    startFifaMessage:
      'תהליך השילוב של FIFA עבור %{vpName} החל. נציג הודעה ברגע שזה יסתיים. המתן בבקשה.',
    startProcessingWaitingJobsFailed: 'שליחת עבודות ממתינות נכשלה',
    startProcessingWaitingJobsSuccessfully: 'עבודות ממתינות שנשלחו בהצלחה',
    startSsMessage:
      'תהליך שילוב ספקטרום שני עבור %{vpName} החל. נציג הודעה ברגע שזה יסתיים. המתן בבקשה.',
    startStatsMessage:
      'תהליך שילוב סטטיסטיקה עבור %{vpName} החל. נציג הודעה ברגע שזה יסתיים. המתן בבקשה.',
    startTracabDflMessage:
      'תהליך שילוב Tracab DFL עבור %{vpName} החל. נציג הודעה ברגע שזה יסתיים. המתן בבקשה.',
    startTracabMessage:
      'תהליך השילוב של Tracab עבור %{vpName} החל. נציג הודעה ברגע שזה יסתיים. המתן בבקשה.',
    surfacesJobSuccess: 'תהליך השטח %{vpName} הסתיים בהצלחה',
    trackingCorrectionProcessedTooltip: 'תיקון מעקב מעובד',
    trackingFifaFormatJobSuccess:
      'תהליך המעקב אחר פורמט FIFA עבור %{vpName} הסתיים בהצלחה. כעת תוכל להוריד את הקובץ על ידי לחיצה על פריט התפריט הורד את פורמט FIFA',
    trackingLicence: 'אין לך רישיון מעקב',
    uncaughtErrorRow:
      'שגיאת פרויקט וידאו. אנא, רענן את הדף הזה. אם הבעיה נמשכת פנה לתמיכה או מחק אותה וצור אותה שוב',
    updatingGame: 'מעדכן את פרויקט הווידאו...',
    upgradePlan: 'שדרוג',
    uploadAndTracking: 'העלאה ומעקב',
    uploadingVideoFile: 'מעלה קובץ וידאו',
    videoFile: 'קובץ וידיאו',
    videoFootballSideView: 'האם קובץ הווידאו הזה הוא שידור כדורגל/כדורגל בזווית רחבה או בטלוויזיה?',
    videoFootballSideViewTooltip: 'זיהוי שדות מותאם לסוגים אלה של סרטונים בלבד.',
    videoMultipartStart: '%{videoName} מועלה ב- %{vpName}. אל תסגור עמוד זה עד לסיום ההעלאה',
    videoPlaylist: 'האם קובץ הסרטון הזה הוא רשימת השמעה?',
    videoPlaylistTooltip:
      'אם תבחר YES, Clip Detection יבוצע, כדי לזהות מתי מתרחש חיתוך בסרטון, מה שהופך אותו לכלי מושלם אם אתה מעלה סרטון המכיל כמה קליפים, למשל. לאחר הפעלת תהליך זה, הורד את פרויקט הווידאו ב-Play וקובץ קידוד של זיהוי קליפים יהיה שם עם קוד הכולל כל חיתוך כאירוע.',
    videoProjectSettings: 'הגדרות פרויקט וידאו',
    videoUploadError: 'העלאת הסרטון של %{videoName} עבור %{vpName} נכשלה. בבקשה נסה שוב',
    videoUploaded: '%{videoName} הועלה ב- %{vpName}',
    vpCreated: '%{vpName} נוצר',
    waitingAtd: 'הַמתָנָה...'
  },
  billing: {
    address: 'כתובת',
    billed: 'חויב',
    completed: 'שולם',
    details: 'פרטי חיוב',
    history: 'היסטוריית חיובים',
    invoiceNotFound: 'חשבונית לא נמצאה',
    noInvoices: 'אין חשבוניות',
    past_due: 'פג תוקף',
    taxId: 'מספר זיהוי מס'
  },
  checkout: {
    chargeSubscription: 'לחייב את המנוי',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: 'ניתן להחיל קודי הנחה בקופה',
    excTax: '(לא כולל מס)',
    immediatePayment: 'תשלום מיידי',
    incTax: '(כולל מס)',
    monthlyBilled: 'חויב חודשית',
    nextPayment: 'התשלום הבא',
    noPacks: 'ללא חבילת דקות Cloud',
    onDate: 'ב-%{date}',
    packs: 'פקדים',
    packsTotal: 'חבילות כוללות',
    perMonth: '‎/חודש',
    perYear: '‎/שנה',
    plans: 'תוכניות',
    plansTotal: 'סה"כ תוכניות',
    proceed: 'להמשיך לתשלום',
    subtotal: 'סובטוטל',
    taxPayable: 'מס לתשלום (%{percentage}%)',
    total: 'סה"כ',
    unpaid: 'החשבונית האחרונה שלך עדיין לא שולמה. אנא עדכן את פרטי התשלום שלך כדי להמשיך.',
    updatePaymentMethod: 'עדכון שיטת תשלום',
    updateSubscription: 'עדכון מנוי',
    userDevice: 'משתמש/מכשיר 1',
    usersDevices: '%{number} משתמשים/מכשירים',
    yearlyBilled: 'חויב בתשלום שנתי'
  },
  common: {
    back: 'חזור',
    cancel: 'לְבַטֵל',
    canceling: 'מבטל...',
    clearAll: 'נקה הכל',
    edit: 'לַעֲרוֹך',
    email: 'אימייל',
    errorMsg: 'שגיאה בעיבוד %{name}. לשאלות נא לפנות לתמיכה.',
    errorTryAgain: 'שְׁגִיאָה. נסה שוב',
    genericError: 'שגיאה, התהליך נכשל. לשאלות נא לפנות לתמיכה.',
    loading: 'טוען...',
    login: 'התחברות',
    logout: 'להתנתק',
    new: 'חָדָשׁ',
    next: 'הַבָּא',
    no: 'לא',
    noFileChosen: 'לא נבחר קובץ',
    ok: 'בסדר',
    required: 'נדרש',
    reset: 'לאפס את הסיסמה',
    restart: 'איתחול',
    selectFile: 'בחר קובץ',
    sort: 'סוג',
    start: 'הַתחָלָה',
    today: 'היום',
    uploading: 'מעלה...',
    yes: 'כן'
  },
  confirmModal: {
    description: 'האם אתה בטוח?',
    title: 'אשר'
  },
  dataIntegration: {
    deleteFile: 'מחק קובץ',
    featureNotAvailable:
      'תכונה זו אינה זמינה בתוכנית הנוכחית שלך. עליך לשדרג לתוכנית אחרת כדי לקבל תכונה זו. צור איתנו קשר למידע נוסף',
    notSideView:
      'שילוב נתונים אינו זמין בפרויקטי וידאו המכילים קובץ וידאו שאינו כדורגל/כדורגל עם זווית רחבה או שידור טלוויזיה.',
    open: 'פתח שילוב נתונים',
    processJobsFirst:
      'תחילה עליך להתחיל בעיבוד נתוני מעקב אוטומטי (ATD), לפני גישה לחלונית שילוב הנתונים',
    processed: 'עיבוד נתונים אינטגרציה',
    selectFile: 'בחר קובץ',
    startProcess: 'התחל תהליך',
    title: 'שילוב נתונים',
    waiting: 'תהליך המתנה...',
    waitingProcess: 'תהליך האינטגרציה יתחיל לאחר ביצוע תהליכי זיהוי נגן ושדה.'
  },
  deleteModal: {
    checkboxLabel: 'אני גם רוצה להפסיק לקבל מיילים מ-Metrica Sports',
    confirmationType: 'הקלד %{validateFormKey} כדי לאשר:',
    deleteAction: 'כן, מחק את זה',
    description: 'האם אתה בטוח שברצונך למחוק לצמיתות את חשבונך?',
    information: 'תאבד את כל פרויקטי הווידאו שנוצרו עם חשבון זה. לא נוכל לשחזר אותם לאחר המחיקה.',
    keepAccount: 'שמור חשבון',
    suggestion: 'אנו מציעים לשמור את החשבון שלך.',
    title: 'למחוק לנצח נצחים?',
    validateFormKey: 'לִמְחוֹק'
  },
  error: {
    oops: 'אופס!',
    restart: 'נראה שאתה מנסה להיכנס למשהו שאנחנו עדיין מפתחים...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging אינו זמין בפרויקטי וידאו עם גרסת ATD ישנה יותר',
    needsAtd: 'התחל תהליך ATD ראשון כדי להפעיל את תהליך ה-Smart Tagging.',
    needsFootballSideView:
      'Smart Tagging אינו זמין בפרויקטי וידאו המכילים קובץ וידאו שאינו של כדורגל מזווית רחבה או שידור טלוויזיה.',
    needsGameVp: 'Smart Tagging זמין רק בפרויקטי וידאו של משחק',
    needsNoPlaylist:
      'Smart Tagging אינו זמין בפרויקטים של וידאו המכילים קובץ וידאו שהוא רשימת השמעה.'
  },
  forgotPassword: {
    adminNoResetPassword: 'משתמשי מנהל מערכת צריכים להשתמש בנקודת קצה פרטית כדי לאפס את הסיסמה',
    notFound: 'המשתמש לא נמצא. אנא נסה שוב עם דוא"ל חוקי',
    success: 'איפוס סיסמה! אנא בדוק את האימייל שלך'
  },
  login: {
    contactUs: 'צור קשר',
    enterYourEmail: 'הכנס את האימייל שלך',
    enterYourPassword: 'הכנס את הסיסמה שלך',
    forgotPassword: 'שכחת את הסיסמא?',
    invalidEmail: 'כתובת דוא&quot;ל לא חוקית',
    licenceExpired: 'פג תוקפו של הרישיון שלך.',
    trialExpired: 'פג תוקפה של גרסת הניסיון שברשותך.',
    upgradePlan: 'שדרג את התוכנית שלך'
  },
  patterns: {
    delete: 'מחק קובץ דפוס',
    title: 'קובץ דפוס מותאם אישית',
    upload: 'העלה קובץ דפוס מותאם אישית',
    uploaded: 'קובץ הדפוס הועלה',
    uploading: 'מעלה קובץ דפוס'
  },
  plans: {
    academic: 'אקדמי',
    addMoreProducts: '+ הוסף מוצרים נוספים',
    admin: 'מנהל מערכת',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code חודשי',
    basicCodePadYearly: 'Basic+Code שנתי',
    basicMftCodePadMonthly: 'Full Basic חודשי',
    basicMftCodePadYearly: 'Full Basic שנתי',
    basicMftMonthly: 'Basic+MFT חודשי',
    basicMftXmlMonthly: 'Basic MFT + XML חודשי',
    basicMftXmlYearly: 'Basic MFT + XML שנתי',
    basicMftYearly: 'Basic+MFT שנתי',
    basicMonthly: 'Basic חודשי',
    basicXmlMonthly: 'Basic XML חודשי',
    basicXmlYearly: 'Basic XML שנתי',
    basicYearly: 'Basic שנתי',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'אליט שנתי',
    eliteYearly: 'Elite שנתי',
    essentialsCodeMonthly: 'Essentials + Code ∞ חודשי',
    essentialsCodeYearly: 'Essentials + Code ∞ שנתי',
    essentialsMftCodeMonthly: 'Full Essentials חודשי',
    essentialsMftCodeYearly: 'פול אסנשיאלס שנתי',
    essentialsMftMonthly: 'Essentials + MFT חודשי',
    essentialsMftYearly: 'Essentials + MFT שנתי',
    essentialsMonthly: 'Essentials חודשי',
    essentialsYearly: 'Essentials שנתי',
    influencer: 'משפיע',
    lite: 'Lite',
    liteMonthly: 'Lite חודשי',
    liteYearly: 'Lite שנתי',
    minutes10: 'חבילת 10 דקות ענן',
    minutes100: 'חבילת 100 דקות ענן',
    minutes1000: 'חבילת 1,000 דקות Cloud',
    minutes10000: 'חבילת 10,000 דקות Cloud',
    plan: '%{planName} תוכנית',
    primeMftMonthly: 'Prime + MFT חודשי',
    primeMftYearly: 'פריים + MFT שנתי',
    primeMonthly: 'פריים חודשי',
    primePresetsMftMonthly: 'פריים מלא חודשי',
    primePresetsMftYearly: 'פריים מלא שנתי',
    primePresetsMonthly: 'פריים + פריסטים חודשי',
    primePresetsYearly: 'פריים + פריסטים שנתי',
    primeYearly: 'פריים שנתי',
    pro: 'Pro',
    pro2024Monthly: 'פרו חודשי',
    pro2024Yearly: 'פרו שנתי',
    proYearly: 'Pro שנתי',
    starter: 'מַתנֵעַ',
    starterCodeMonthly: 'Starter + Code ∞ חודשי',
    starterCodePadMonthly: 'Code חודשי',
    starterCodePauseMonthly: 'פול סטארטר חודשי',
    starterCodePauseYearly: 'פול סטארטר שנתי',
    starterCodeYearly: 'Starter + Code ∞ שנתי',
    starterPauseMonthly: 'Starter + Pause ∞ חודשי',
    starterPauseYearly: 'Starter + Pause ∞ שנתי',
    student: 'סטוּדֶנט',
    trial: 'ניסוי'
  },
  playlist: {
    download: 'הורד קובץ פלייליסט',
    notProcessed: 'קובץ הפלייליסט לא עובד בפרויקט וידאו זה.',
    processed: 'קובץ פלייליסט מעובד',
    title: 'רשימת השמעה',
    titleDownload: 'הורד את רשימת ההשמעה'
  },
  products: {
    addOns: 'תוספות',
    basic: 'קודים והערות ללא הגבלה',
    code: 'CodePad',
    elite: {
      description:
        'אוטומט והתאם אישית את כל תהליך הניתוח שלך. תוכנית זו כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Full Essentials" וגישה ל-Play3 עם כל תכונות התוכנית "Pro".',
      feature1: '✓ אחסון ענן משותף',
      feature2: '✓ אינטגרציה של נתוני טראקינג',
      feature3: '✓ מנהל תיקוני מעקב',
      feature4: '✓ RESTful API',
      feature5: '✓ חבילת 5,000 דקות ATD'
    },
    essentials: {
      addon1: 'תכונות קידוד ללא הגבלה (CodePad, קידוד חי וייבוא XML)',
      addon2: 'טראקינג שדה ידני',
      essentialsCodeDescription:
        'התוכנית הזו מאפשרת את השימוש בוויזואליזציות דינמיות להוספת הערות תנועה בלתי מוגבלות בעת השמעת הווידאו ובהפסקות. התוכנית הזו אינה כוללת את המעקב הידני בשטח, ולכן הוויזואליזציות של מהירות ומרחק אינן כלולות. כמו כן, היא משחררת את ההגבלה על תכונות ה-coding כמו CodePad, Coding חי ויבוא XML ומבטלת את ההגבלה של 4 קודים לפרויקט וידאו לקודים בלתי מוגבלים.',
      essentialsDescription:
        'תוכנית זו מאפשרת ויזואליזציות דינמיות זמינות להוספת הערות תנועה בלתי מוגבלות בעת השמעת הווידאו ובהפסקות. תוכנית זו אינה כוללת Tracking של שטח ידני, ולכן הוויזואליזציות של מהירות ומרחק אינן כלולות. התכונות של coding נשארות מוגבלות.',
      essentialsMftCodeDescription:
        'תוכנית זו מאפשרת ויזואליזציות דינמיות שניתן להוסיף אנוטציות בתנועה ללא הגבלה בעת השמעת הווידאו ובהפסקות, וכן מפעילה את ניתוב השדה הידני להוספת ויזואליזציות של מהירות ומרחק. כמו כן, היא משחררת את ההגבלה על תכונות ה-coding כגון CodePad, Coding בזמן אמת ויבוא XML ומבטלת את ההגבלה של 4 קודים לפרויקט וידאו לקודים ללא הגבלה.',
      essentialsMftDescription:
        'תוכנית זו מאפשרת הצגות דינמיות שניתן להוסיף אליהן אנימציות תנועה ללא הגבלה בעת השמעת הווידאו ובהפסקות, וכן מפעילה את Tracking של שדה ידני כדי להוסיף הצגות של מהירות ומרחק. התכונות של coding נשארות מוגבלות.',
      feature1: '✓ קידוד אחרי משחק (Code Table עם עד 4 קודים)',
      feature2: '✓ ציורים בלתי מוגבלים בהשהיות',
      feature3: '✓ טלסטרציה דינמית',
      feature4: '✓ חבילת 3 דקות ATD'
    },
    featuresIncluded: 'תכונות כלולות:',
    freeFeature1: '✓ קידוד אחרי משחק (Code Table עם עד 4 קודים)',
    freeFeature2: '✓ עקיבה ידנית',
    freeFeature3: '✓ טלסטרציה (עד 150 הערות)',
    freeFeature4: '✓ ייצוא וידאו',
    freeFeatures: 'תכונות חינם',
    fullBasic: 'טראקינג שדה ידני',
    lite: 'טראקינג אוטומטי של שחקנים ומגרש',
    liteDescription:
      'קידוד מתקדם וויזואליזציות מתקדמות. הכל אוטומטי, מה שהופך את זה לשלוש פעמים מהיר יותר מ-Full Basic!',
    minutesDescription: 'חדש כל תוכנית. קבל חבילות דקות לנתוני Tracking אוטומטיים.',
    minutesPack: 'חבילת דקות Cloud',
    otherDescription: 'תכונות קידוד מתקדמות עם הדגמות בסיסיות והערות מוגבלות',
    prime: {
      addon1: 'טראקינג שדה ידני',
      addon2: 'הגדרות מותאמות אישית להדגמה (התאם ושמור את הוויזואליזציות שלך)',
      feature1: '✓ גישה מלאה ל-Play2 עם כל התכונות של התוכנית "Full Essentials"',
      feature2: '✓ גישה ל-Play3 עם:',
      feature2_1: 'הפסקות רצפיות',
      feature2_2: 'ויזואליזציות מתקדמות',
      feature2_3: 'ייצוא וידאו מותאם אישית',
      feature3: '✓ חבילת 10 דקות ATD',
      primeDescription:
        'התוכנית כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Essentials + Code ∞" ומאפשרת את Play3 עם הדמיות מתקדמות.',
      primeMftDescription:
        'התוכנית כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Full Essentials" ומאפשרת את Play3 עם טלסטרציה מתקדמת.',
      primePresetsDescription:
        'התוכנית כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Essentials + Code ∞" ומאפשרת את Play3 עם תצוגות מתקדמות ואישיות.',
      primePresetsMftDescription:
        'התוכנית כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Full Essentials" ומאפשרת את Play3 עם תצוגות מתקדמות ואישיות.'
    },
    pro: {
      description:
        'התוכנית כוללת גישה מלאה ל-Play2 עם כל תכונות התוכנית "Full Essentials" וגישה ל-Play3 עם כל תכונות התוכנית "Full Prime".',
      feature1: '✓ עקיבה אוטומטית אחרי שחקנים',
      feature2: '✓ עקיבה אוטומטית אחר המגרש',
      feature3: '✓ גישה מלאה ל-Play2 עם כל התכונות של התוכנית "Full Essentials"',
      feature4: '✓ גישה ל-Play3 עם:',
      feature4_1: 'הפסקות רצופות',
      feature4_2: 'איורים מתקדמים',
      feature4_3: 'ייצוא וידאו מותאם אישית',
      feature4_4: 'הגדרות מראש להדגמה ויזואלית (התאמה אישית ושמירה של הוויזואליזציות שלך)',
      feature5: '✓ חבילת 100 דקות ATD'
    },
    remove: 'הסר',
    starter: {
      addon1: 'הצגות ללא הגבלה בהשהיות',
      addon2: 'תכונות קידוד ללא הגבלה (CodePad, קידוד חי וייבוא XML)',
      feature1: '✓ קידוד לאחר משחק (Code Table עם עד 4 קודים)',
      feature2: '✓ טלסטרציה בהשהיות (עד 50 הערות בחודש)',
      feature3: '✓ ייצוא וידאו',
      starterCodeDescription:
        'תוכנית זו משחררת את ההגבלה על תכונות הקידוד כמו CodePad, קידוד חי ויבוא XML. היא גם משחררת את ההגבלה של 4 קודים לפרויקט וידאו לקודים בלתי מוגבלים. ההגבלות על תכונות ההדגמה נשארות כפי שהן ב-Free Starter.',
      starterCodePauseDescription:
        'תוכנית זו משחררת את ההגבלה על תכונות הקידוד כמו CodePad, קידוד חי ויבוא XML. היא גם משחררת את ההגבלה של 4 קודים לפרויקט וידאו לקודים בלתי מוגבלים, ומשחררת את ההגבלה על טלסטרציה בהשהיות מ-50 לחודש לבלתי מוגבלות.',
      starterPauseDescription:
        'תוכנית זו משחררת את ההגבלה על טלסטרציה בהשהיות מ-50 לחודש ללא הגבלה. ההגבלות על תכונות ה-coding נשארות כמו ב-Free Starter.'
    },
    totalPack: 'סה"כ: %{currency} %{cost}',
    totalPlan: 'סה"כ: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'חיוב',
    billingSettings: 'הגדרות חיוב',
    billingSettingsDescription: '(שנה את התוכנית שלך, עדכן אמצעי תשלום...)',
    cancelAt: 'התוכנית שלך מבוטלת ב-',
    cancelPlan: 'בטל תוכנית',
    confirmNewPassword: 'תאשר סיסמא חדשה',
    deleteAccount: 'מחק חשבון',
    details: 'פרטיי',
    emailNotActivated: 'הודעת דוא&quot;ל מופעלת כעת',
    emailNotDeactivated: 'הודעת דוא&quot;ל מושבתת כעת',
    emailNotifications: 'התראות באימייל',
    language: 'שפה',
    languageChanged: 'השפה עודכנה',
    newPassword: 'סיסמה חדשה',
    organizationLabel: 'קידומת ארגון',
    organizationTooltip:
      'קידומת זו נחוצה לייבוא דפוסים מותאמים אישית לפרויקט וידאו. צור איתנו קשר למידע נוסף או עיין בתיעוד בכתובת codeball.metrica-sports.com',
    passwordsDontMatch: 'סיסמאות אינן תואמות',
    pauseAt: 'התוכנית שלך מושהית ב',
    planCanceled: 'התוכנית שלך בוטלה. בדוק את תיבת הדוא"ל שלך למידע נוסף',
    plans: 'תוכניות',
    quantity: 'כַּמוּת:',
    renewAt: 'התוכנית שלך מתחדשת',
    title: 'הגדרות',
    upgradePlan: 'תוכנית שדרוג',
    userKey: 'מפתח משתמש:',
    userKeyUsages: '%{current}/%{max} שימושים',
    userSettings: 'הגדרות משתמש'
  },
  shoppingCart: {
    subtitle: 'בחר את תוכנית ה-Play שלך ותוספות כדי לשפר את הביצועים שלך.',
    title: 'הגדר את ה-Play שלך'
  },
  subscription: {
    cancelSubscription: 'ביטול מנוי',
    errorUpdating:
      'התרחשה שגיאה בעת עדכון המנוי שלך. אנא שלח דוא"ל ל-support@metrica-sports.com ונשמח לעזור לך.',
    free: 'חינם',
    isObsolete: 'המנוי שלך לא ניתן לשינוי. החלף מוצרים ישנים בחדשים כדי לאפשר שינויים',
    licenseKeys: 'מפתחות רישיון',
    pendingRefundRequest:
      'לא ניתן לבצע שינויים נוספים במנוי זה כל עוד קיימת בקשת החזר כספי בתהליך.',
    undoCancel: 'ביטול ביטול מנוי',
    undoPause: 'ביטול השהייה של המנוי',
    updatePaymentMethod: 'עדכן שיטת תשלום',
    updatedSuccessfully: 'המנוי עודכן בהצלחה',
    yourPlans: 'תוכניות שלך',
    yourSubscription: 'המנוי שלך'
  },
  successfulPayment: {
    activateLicence: 'הפעל את הרישיון או את הקרדיט של Cloud על ידי הזנת מפתח הרישיון שסופק.',
    allTheBest: 'כל טוב,',
    applyKey: 'החל מפתח על חשבון זה',
    applyKeyDescription: 'האם תרצה להחיל את מפתח הרישיון על החשבון שאתה מחובר אליו אוטומטית?',
    downloadPlay: '%{pre}הורד את PLAY%{post} דרך האתר שלנו.',
    faq: 'כדי לעזור לך להתחיל עם ההזמנה שלך, בדוק את ה%{pre}שאלות הנפוצות%{post} באתר שלנו. הנה סיכום מהיר של המידע שיכול להיות הכי רלוונטי עבורך בשלב זה:',
    goToSummary: 'אם לא, אנא עיין בסיכום שלהלן להוראות הפעלה.',
    happyPlay:
      'אנו מקווים שהכל ברור, אך אנא אל תהסס לפנות אלינו אם יש לך שאלות או ספקות נוספים. שמחים ב-PLAY!',
    helpDesk: 'בדוק את ה-%{pre}HelpDesk%{post} שלנו למידע כללי על שימוש ב-PLAY.',
    keyApplied: 'מפתח הוחל',
    licenseKey: 'מפתח הרישיון שלך הוא: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'צוות מטריקה ספורט',
    settingsCloud: 'בקר ב-%{pre}Cloud%{post} שלנו ונווט אל תפריט ההגדרות כדי:',
    technicalSupport:
      'לתמיכה טכנית, מלא את טופס הקשר הזמין ב-PLAY/Cloud, או שלח דוא"ל ל-%{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'שים לב שהמנויים שלנו מתחדשים אוטומטית עד לביטול מפורש דרך ההגדרות ב-%{pre}Cloud%{post}, בהתאם לתנאי השימוש שלנו.',
    thanks: 'תודה על הזמנתך! עיבדנו אותה בהצלחה וצירפנו למטה את מפתחות הרישיון שלך.',
    title: 'אישור הזמנה',
    updatePayment: 'עדכן את שיטת התשלום שלך.',
    updateSubscription: 'בטל או שנה את המנוי שלך.'
  },
  surfaces: {
    automaticVp: 'משטחים אינם זמינים ב-Video Projects עם גרסת ATD ישנה יותר',
    delete: 'מחק קובץ Surface',
    needsAtd: 'התחל תחילה את תהליך ATD כדי להפעיל תהליכי Surface',
    needsElite: 'משטחים מותאמים אישית זמינים בתוכנית Elite. צור איתנו קשר למידע נוסף',
    needsFootballSideView:
      'משטחים שאינם זמינים בפרויקטי וידאו המכילים קובץ וידאו שאינו כדורגל/כדורגל עם זווית רחבה או שידור טלוויזיה',
    pitchControl: 'על ידי הפעלת תהליך זה יאפשר לך להוסיף הדמיית Pitch Control ב-Play.',
    pitchControlProcessed: 'משטח בקרת גובה עיבוד',
    pitchControlProcessing: 'עיבוד Pitch Control',
    processed: 'הועלה קובץ משטח',
    processing: 'מעבד קובץ Surface',
    title: 'משטחים',
    titlePitchControl: 'שליטת המגרש',
    upload: 'העלה את Surface משלך',
    uploading: 'מעלה את משטח...'
  },
  trackingFifaFormat: {
    download: 'הורד קובץ בפורמט FIFA EPTS',
    needsElite:
      'פונקציה זו זמינה רק לאחר ייבוא הקבצים המתאימים למודול שילוב נתונים, פרויקטי וידאו עם נתוני מעקב אוטומטיים או פרויקטי וידאו בתוכנית עילית.',
    processed: 'פורמט EPTS של FIFA עובד',
    start: 'התחל תהליך ליצירת קובץ בפורמט FIFA EPTS. לאחר מכן תוכל להוריד את הקובץ',
    title: 'פורמט פיפ&quot;א EPTS',
    titleDownload: 'הורד את פורמט FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'קבל את חבילת הדקות של Cloud Engine המתאימה לך ביותר:',
    minutePackLinks: {
      minutes_10: 'קבל 10 דקות תמורת 10 אירו',
      minutes_100: 'קבל 100 דקות תמורת 90 אירו',
      minutes_1000: 'קבל 1,000 דקות תמורת 750 אירו',
      minutes_10000: 'קבל 10,000 דקות תמורת 5,000 אירו'
    },
    noMinutes: {
      description:
        'אין לך מספיק דקות פנויות כדי ליצור פרויקט וידאו זה. האם אתה יודע שאתה יכול להשיג חבילות דקות מה התוכנית שלך?',
      secondDescription: '',
      title: 'קבל יותר דקות ליצור פרויקט וידאו!'
    },
    notEnoughMinutes: {
      description: 'אנו מצטערים להודיע לך שאין לך מספיק דקות לתהליך ATD בפרויקטי הווידאו שלך.',
      secondDescription:
        'אם ברצונך לעבד ATD, תצטרך להוסיף דקות נוספות לחשבונך. הדקות שתקבל לא יפוג!',
      title: 'אין מספיק דקות פנויות'
    }
  },
  video: {
    cancelUpload: 'בטל העלאה',
    delete: 'מחק סרטון',
    download: 'הורד סרטון',
    title: 'קובץ וידאו',
    upload: 'העלה סרטון',
    uploaded: 'הועלה סרטון',
    uploading: 'מעלה סרטון'
  },
  videoProject: {
    delete: 'מחק פרויקט וידאו',
    edit: 'ערוך פרויקט וידאו',
    processWaitingJobs: 'עיבוד עבודות המתנה',
    title: 'פרויקט וידאו'
  }
}
