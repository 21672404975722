import api from './api'

const sessionService = {}

// Create Session
sessionService.create = (username, password) =>
  api.post('/auth/metrica-cloud/token', { username, password })

// Check Session
sessionService.check = token => api.get(`/auth/metrica-cloud/token/${token}`)

export default sessionService
