import React from 'react'
import PropTypes from 'prop-types'
import { Status, GAMES_DATASET_DATA_TYPE } from '../../../constants'

const initialState = {
  deletingVp: false,
  disableActions: false,
  hasSurface: false,
  hasSurfaceJob: false,
  showStartSurface: false,
  isOnHold: false,
  errorTryAgain: false
}

function SurfaceJob(props) {
  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    const { vp, deletingVp } = props
    const surfacesArray = vp.files && vp.files.surfaces
    const hasPitchControl = surfacesArray && surfacesArray.find(v => v.name === 'Pitch Control')
    const hasSurfaceJob = !hasPitchControl && props.surfaceJob
    const errorTryAgain = hasSurfaceJob && props.surfaceJob.status === Status.FAILED
    const showStartSurface =
      vp.files.video &&
      !hasPitchControl &&
      props.isVpCreatedInCloud &&
      vp.data_type !== GAMES_DATASET_DATA_TYPE.AUTOMATIC

    if (deletingVp) {
      setState({
        ...initialState,
        deletingVp: true
      })
      return
    }

    if (hasPitchControl) {
      setState({
        ...initialState,
        hasSurface: true
      })
      return
    }

    if (hasSurfaceJob) {
      setState({
        ...initialState,
        hasSurfaceJob: true
      })
      return
    }

    if (showStartSurface) {
      setState({
        ...initialState,
        showStartSurface: true
      })
      return
    }

    if (errorTryAgain) {
      setState({
        ...initialState,
        errorTryAgain: true
      })
      return
    }

    setState({ ...initialState })
  }, [props.vp, props.deletingVp, props.surfaceJob, props.isVpCreatedInCloud])

  const renderFn = props.render || props.children // eslint-disable-line
  return renderFn(state)
}

SurfaceJob.propTypes = {
  vp: PropTypes.object.isRequired,
  surfaceJob: PropTypes.object, // Don't use {} as a default prop for this
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default SurfaceJob
