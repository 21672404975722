/** @jsx jsx */
// eslint-disable-next-line
import React, { Component, Fragment } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { theme } from '../../constants'

const styles = {}
styles.container = css`
  position: relative;
  width: 100%;
  overflow: hidden;
`
styles.stroke = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
`
styles.label = css`
  color: white;
  position: relative;
  z-index: 5;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Progress(props) {
  return (
    <div
      css={css`
        ${styles.container};
        background: ${props.trailColor};
        height: ${props.trailWidth}px;
        ${props.style};
      `}
    >
      <div
        css={css`
          ${styles.stroke};
          background: ${props.strokeColor};
          height: ${props.strokeWidth}px;
          width: ${props.percent}%;
          ${props.strokeStyle}
        `}
      />
      <div
        css={css`
          ${styles.label};
          ${props.labelStyle};
          height: ${props.trailWidth}px;
        `}
      >
        {props.percent}%
      </div>
    </div>
  )
}

Progress.defaultProps = {
  strokeWidth: 15,
  trailWidth: 15,
  strokeColor: theme.palette.common.blue,
  trailColor: theme.palette.common.blue2,
  style: {},
  strokeStyle: {},
  labelStyle: {}
}

Progress.propTypes = {
  percent: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  trailWidth: PropTypes.number,
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  style: PropTypes.object,
  strokeStyle: PropTypes.object,
  labelStyle: PropTypes.object
}

export default Progress
