/** @jsx jsx */
// eslint-disable-next-line
import React, { PureComponent } from 'react'
import { jsx, css } from '@emotion/core'
import Table from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { theme } from '../../../constants'
import atdIcon from '../../../assets/atd-icon.svg'
import AdminTableRow from './AdminTableRow'

const styles = {}
styles.logoImg = css`
  transform: rotate(270deg);
  width: 23px;
`

const cellStyles = {
  head: {
    color: theme.palette.secondary.main,
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingRight: 10,
    paddingLeft: 10
  }
}

const CustomCell = withStyles(() => ({ ...cellStyles }))(TableCell)

class AdminTable extends PureComponent {
  state = {
    tableSortdesc: true
  }

  sortTable = () => {
    this.setState(prevState => ({
      ...prevState,
      tableSortdesc: !prevState.tableSortdesc
    }))
    this.props.sortTable()
  }

  startUploadVideo = (gameId, fileObject, automatedTrackingData) => {
    this[`tableRowEl_${gameId}`].startUploadVideoExternal(fileObject, automatedTrackingData)
  }

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <CustomCell align="left">
              <Tooltip title={window.I18n.t('common.sort')} enterDelay={200} placement="top">
                <TableSortLabel
                  active
                  direction={this.state.tableSortdesc ? 'desc' : 'asc'}
                  onClick={this.sortTable}
                >
                  {window.I18n.t('admin.date')}
                </TableSortLabel>
              </Tooltip>
            </CustomCell>
            <CustomCell align="left">
              {`${window.I18n.t('admin.competition')}/${window.I18n.t('admin.category')}`}
            </CustomCell>
            <CustomCell align="left">{window.I18n.t('admin.description')}</CustomCell>
            <CustomCell align="left">{window.I18n.t('admin.place')}</CustomCell>
            <CustomCell align="left">{window.I18n.t('admin.videoFile')}</CustomCell>
            <CustomCell align="center">
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {window.I18n.t('admin.atd')}
                <img src={atdIcon} css={styles.logoImg} />
              </div>
            </CustomCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.rows.map(row => (
            <AdminTableRow
              ref={el => {
                this[`tableRowEl_${row._id}`] = el
              }}
              key={row._id}
              openDialogToEditVp={this.props.openDialogToEditVp}
              row={row}
              preProcessingJobRow={this.props.preProcessingJobs[row._id]}
              trackingJobRow={this.props.trackingJobs[row._id]}
              fieldRecognitionJobRow={this.props.fieldRecognitionJobs[row._id]}
              dataIntegrationJobRow={this.props.dataIntegrationJobs[row._id]}
              trackingFifaFormatJobRow={this.props.trackingFifaFormatJobs[row._id]}
              automatedTrackingDataJobRow={this.props.automatedTrackingDataJobs[row._id]}
              eventsDetectionJobRow={this.props.eventsDetectionJobs[row._id]}
              surfaceJobRow={this.props.surfaceJobs[row._id]}
              ballTrackingJobRow={this.props.ballTrackingJobs[row._id]}
              deleteVp={this.props.deleteVp}
              updateTableItem={this.props.updateTableItem}
              editJobItem={this.props.editJobItem}
              failedJobItem={this.props.failedJobItem}
              openDataIntegration={this.props.openDataIntegration}
              hasDataIntegrationLicence={this.props.hasDataIntegrationLicence}
              trackingMinutes={this.props.trackingMinutes}
              openUpgradeModal={this.props.openUpgradeModal}
              isMetricaSportsUser={this.props.isMetricaSportsUser}
              canUploadSurface={this.props.canUploadSurface}
              hasWritePermissionOnOrganization={this.props.hasWritePermissionOnOrganization}
            />
          ))}
        </TableBody>
      </Table>
    )
  }
}

AdminTable.propTypes = {
  rows: PropTypes.array.isRequired,
  sortTable: PropTypes.func.isRequired,
  openDialogToEditVp: PropTypes.func.isRequired,
  preProcessingJobs: PropTypes.object.isRequired,
  trackingJobs: PropTypes.object.isRequired,
  fieldRecognitionJobs: PropTypes.object.isRequired,
  dataIntegrationJobs: PropTypes.object.isRequired,
  trackingFifaFormatJobs: PropTypes.object.isRequired,
  automatedTrackingDataJobs: PropTypes.object.isRequired,
  eventsDetectionJobs: PropTypes.object.isRequired,
  surfaceJobs: PropTypes.object.isRequired,
  ballTrackingJobs: PropTypes.object.isRequired,
  deleteVp: PropTypes.func.isRequired,
  updateTableItem: PropTypes.func.isRequired,
  editJobItem: PropTypes.func.isRequired,
  failedJobItem: PropTypes.func.isRequired,
  openDataIntegration: PropTypes.func.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  hasDataIntegrationLicence: PropTypes.bool.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  canUploadSurface: PropTypes.bool.isRequired,
  hasWritePermissionOnOrganization: PropTypes.func.isRequired,
  trackingMinutes: PropTypes.number.isRequired
}

export default AdminTable
