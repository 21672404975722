import englishSvg from '../assets/languages/en.svg'
import spanishSvg from '../assets/languages/es.svg'
import portugueseSvg from '../assets/languages/pt.svg'
import arabicSvg from '../assets/languages/ar.svg'
import chineseSvg from '../assets/languages/zh.svg'
import japaneseSvg from '../assets/languages/ja.svg'
import frenchSvg from '../assets/languages/fr.svg'
import turkishSvg from '../assets/languages/tr.svg'
import polishSvg from '../assets/languages/pl.svg'
import italianSvg from '../assets/languages/it.svg'
// import germanSvg from '../assets/languages/de.svg'
import russianSvg from '../assets/languages/ru.svg'
import dutchSvg from '../assets/languages/nl.svg'
import hebrewSvg from '../assets/languages/he.svg'
import catalaSvg from '../assets/languages/ca.svg'
// import indonesianSvg from '../assets/languages/id.svg'

export const headerHeight = 64
export const checkProgressInterval = 10000
export const apiTimeout = 1000 * 60 * 35
export const shortInputMaxLength = 22
export const longInputMaxLength = 28
export const videoFormats = '.avi,.mov,.mp4,.mpeg,.mpg,.ogg,.wmv,.mkv,.flv,.mts,.mxf,.webm,.m4v'
export const zendeskKeyframing = 'https://help.metrica-sports.com/hc/en-us/articles/360051532832'
export const NOT_ENOUGH_MINUTES_KEY = 'NOT_ENOUGH_MINUTES'

export const apiBaseUrl = {
  production: 'https://api.metrica-sports.com/v2/api/',
  development: 'http://api-dev.metrica-sports.com/api/',
  local: 'http://localhost:8082/'
}

export const paymentsBaseUrl = {
  production: 'https://billing.metrica-sports.com/',
  development: 'http://billing-dev.metrica-sports.com/',
  local: 'http://localhost:4242/'
}

export const DefaultCurrencyCode = 'EUR'

export const CurrenciesWithoutDecimals = ['JPY', 'KRW']

export const Languages = [
  { value: 'en', label: 'English', icon: englishSvg },
  { value: 'es', label: 'Español', icon: spanishSvg },
  { value: 'pt', label: 'Português', icon: portugueseSvg },
  { value: 'ar', label: 'عربي', icon: arabicSvg },
  { value: 'zh', label: '中文 (简体)', icon: chineseSvg },
  { value: 'ja', label: '日本', icon: japaneseSvg },
  { value: 'fr', label: 'Français', icon: frenchSvg },
  { value: 'tr', label: 'Türkçe', icon: turkishSvg },
  { value: 'pl', label: 'Polski', icon: polishSvg },
  { value: 'it', label: 'Italiano', icon: italianSvg },
  // { value: 'de', label: 'Deutsch', icon: germanSvg },
  { value: 'ru', label: 'Русский', icon: russianSvg },
  { value: 'nl', label: 'Nederlands', icon: dutchSvg },
  { value: 'he', label: 'עִברִית', icon: hebrewSvg },
  { value: 'ca', label: 'Català', icon: catalaSvg }
  // { value: 'id', label: 'Bahasa Indonesia', icon: indonesianSvg }
]

export const Status = {
  QUEUED_LOCALLY: 'QUEUED_LOCALLY',
  QUEUED: 'QUEUED',
  STARTING: 'STARTING',
  ACTIVE: 'ACTIVE',
  FINISHING: 'FINISHING',
  FAILED: 'FAILED',
  UNKNOWN: 'UNKNOWN',
  CANCEL: 'CANCEL',
  COMPLETE: 'COMPLETE',
  CONNECTIONFAILED: 'CONNECTIONFAILED' // This status just exist on the front end
}

export const GAMES_DATASET_DATA_TYPE = {
  AUTOMATED_TRACKING_DATA: 'automated_tracking_data',
  AUTOMATIC: 'automatic',
  CORRECTED: 'corrected',
  ELITE: 'elite',
  SYNCED: 'synced'
}

export const API_MESSAGES = {
  trialLicenseInvalid: 'Trial license invalid or expired',
  licenseInvalid: 'License invalid or expired',
  adminNoResetPassword: 'Admin users have to use a private endpoint to reset password'
}

export const JOB_TYPES = {
  PRE_PROCESSING: { back: 'pre_processing', front: 'preProcessing' },
  TRACKING: { back: 'tracking', front: 'tracking', file: 'tracking' },
  BALL_TRACKING: { back: 'tracking_ball', front: 'ballTracking', file: 'detections_ball' },
  EVENTS_DETECTION: { back: 'analyst', front: 'eventsDetection', file: 'patterns' },
  SURFACES: { back: 'analyst', front: 'surface', file: 'surfaces' },
  HOMOGRAPHY: {
    back: 'homography',
    front: 'fieldRecognition',
    files: ['homography_matrix', 'calibration']
  },
  DATA_INTEGRATION: { back: 'data_integration', front: 'dataIntegration', file: 'json_tracking' },
  AUTOMATED_TRACKING_DATA: {
    back: 'automated_tracking_data',
    front: 'automatedTrackingData',
    file: 'automated_tracking_data'
  },
  TRACKING_FIFA_FORMAT: {
    back: 'tracking_fifa_format',
    front: 'trackingFifaFormat',
    file: 'tracking_fifa_format'
  }
}

export const DATA_INTEGRATION_PROVIDERS = {
  TRACAB: 'tracab',
  TRACAB_DFL: 'tracab_dfl',
  SECOND_SPECTRUM: 'second_spectrum',
  STATS: 'stats',
  FIFA: 'fifa'
}

export const theme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      grey: '#929292',
      blue: '#2196f3',
      blue2: '#8fcaf9',
      charolBlack: '#333333',
      successGreen: '#16ac80',
      main: '#E9F75F',
      mainGreen: '#CDF256',
      dark: '#B4ED4B',
      darkGrey: '#656565',
      redError: '#D05665',
      electricBlue: '#245AF3',
      greyMetrica: '#404040',
      mainGradient: 'linear-gradient(212.95deg, #E9F75F 0.78%, #B4ED4B 100%)',
      greyHover: '#e8e8e8'
    },
    background: {
      paper: '#fff',
      default: 'rgba(250, 250, 250, 1)'
    },
    primary: {
      light: 'rgba(0, 240, 169, 1)',
      main: 'rgba(233, 247, 95, 1)',
      dark: 'rgba(180, 237, 75, 1)',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgba(102, 102, 102, 1)',
      main: 'rgba(68, 68, 68, 1)',
      dark: 'rgba(27, 27, 27, 1)',
      contrastText: '#fff'
    },
    error: {
      light: 'rgba(230, 110, 126, 1)',
      main: 'rgba(208, 86, 101, 1)',
      dark: 'rgba(150, 52, 62, 1)',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(
      ','
    )
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#333333'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#333333'
      },
      isSelected: {
        backgroundColor: '#333333'
      },
      current: {
        color: '#333333'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#333333'
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#16ac80'
      }
    }
  }
}

export const gamesGetAllQuery = {
  select:
    'game lastGeneralUpdate video files lastVideoUpdate lastTrackingUpdate date organizations created_by code scenes data_type forked_from is_playlist compute_surfaces compute_ball compute_events is_football_side_view is_corrected jobs',
  sort: '-date',
  populate: [
    {
      path: 'game',
      select: 'result date home_team away_team competition stadium description',
      populate: [
        { path: 'home_team', select: 'name full_name logo_url' },
        { path: 'away_team', select: 'name full_name logo_url' },
        { path: 'competition', select: 'name' },
        { path: 'stadium', select: 'name' }
      ]
    },
    { path: 'organizations', select: 'name' }
  ]
}

export const fetchModelsParams = {
  offset: 0,
  limit: 999
}

export const AnalyticsEvents = {
  // GameDataset
  CREATE_GAME_DATASET: 'CREATE_GAME_DATASET',
  UPDATE_GAME_DATASET: 'UPDATE_GAME_DATASET',
  // Video
  VIDEO_UPLOAD_STARTED: 'VIDEO_UPLOAD_STARTED',
  VIDEO_UPLOAD_COMPLETED: 'VIDEO_UPLOAD_COMPLETED',
  VIDEO_UPLOAD_CANCELED: 'VIDEO_UPLOAD_CANCELED',
  // Multipart (surface)
  MULTIPART_UPLOAD_STARTED: 'MULTIPART_UPLOAD_STARTED',
  MULTIPART_UPLOAD_COMPLETED: 'MULTIPART_UPLOAD_COMPLETED',
  MULTIPART_UPLOAD_CANCELED: 'MULTIPART_UPLOAD_CANCELED',
  // Tracking
  AUTO_TRACKING_SUBMITTED: 'AUTO_TRACKING_SUBMITTED',
  AUTO_TRACKING_FINISHED: 'AUTO_TRACKING_FINISHED',
  AUTO_TRACKING_FAILED: 'AUTO_TRACKING_FAILED',
  AUTO_TRACKING_STOPPED: 'AUTO_TRACKING_STOPPED',
  // Homography
  AUTO_HOMOGRAPHY_SUBMITTED: 'AUTO_HOMOGRAPHY_SUBMITTED',
  AUTO_HOMOGRAPHY_FINISHED: 'AUTO_HOMOGRAPHY_FINISHED',
  AUTO_HOMOGRAPHY_FAILED: 'AUTO_HOMOGRAPHY_FAILED',
  AUTO_HOMOGRAPHY_STOPPED: 'AUTO_HOMOGRAPHY_STOPPED',
  // Tracab
  AUTO_TRACAB_SUBMITTED: 'AUTO_TRACAB_SUBMITTED',
  AUTO_TRACAB_FINISHED: 'AUTO_TRACAB_FINISHED',
  AUTO_TRACAB_FAILED: 'AUTO_TRACAB_FAILED',
  AUTO_TRACAB_STOPPED: 'AUTO_TRACAB_STOPPED',
  // Tracab DFL
  AUTO_TRACAB_DFL_SUBMITTED: 'AUTO_TRACAB_DFL_SUBMITTED',
  AUTO_TRACAB_DFL_FINISHED: 'AUTO_TRACAB_DFL_FINISHED',
  AUTO_TRACAB_DFL_FAILED: 'AUTO_TRACAB_DFL_FAILED',
  AUTO_TRACAB_DFL_STOPPED: 'AUTO_TRACAB_DFL_STOPPED',
  // Second Spectrum
  AUTO_SECOND_SPECTRUM_SUBMITTED: 'AUTO_SECOND_SPECTRUM_SUBMITTED',
  AUTO_SECOND_SPECTRUM_FINISHED: 'AUTO_SECOND_SPECTRUM_FINISHED',
  AUTO_SECOND_SPECTRUM_FAILED: 'AUTO_SECOND_SPECTRUM_FAILED',
  AUTO_SECOND_SPECTRUM_STOPPED: 'AUTO_SECOND_SPECTRUM_STOPPED',
  // STATS
  AUTO_STATS_SUBMITTED: 'AUTO_STATS_SUBMITTED',
  AUTO_STATS_FINISHED: 'AUTO_STATS_FINISHED',
  AUTO_STATS_FAILED: 'AUTO_STATS_FAILED',
  AUTO_STATS_STOPPED: 'AUTO_STATS_STOPPED',
  // FIFA
  AUTO_FIFA_SUBMITTED: 'AUTO_FIFA_SUBMITTED',
  AUTO_FIFA_FINISHED: 'AUTO_FIFA_FINISHED',
  AUTO_FIFA_FAILED: 'AUTO_FIFA_FAILED',
  AUTO_FIFA_STOPPED: 'AUTO_FIFA_STOPPED',
  // Login
  LOGIN_METRICA_CLOUD: 'LOGIN_METRICA_CLOUD',
  CHECK_TOKEN_METRICA_CLOUD: 'CHECK_TOKEN_METRICA_CLOUD',
  // Tracking Fifa Format
  AUTO_TRACKING_FIFA_FORMAT_SUBMITTED: 'AUTO_TRACKING_FIFA_FORMAT_SUBMITTED',
  AUTO_TRACKING_FIFA_FORMAT_FINISHED: 'AUTO_TRACKING_FIFA_FORMAT_FINISHED',
  AUTO_TRACKING_FIFA_FORMAT_FAILED: 'AUTO_TRACKING_FIFA_FORMAT_FAILED',
  AUTO_TRACKING_FIFA_FORMAT_STOPPED: 'AUTO_TRACKING_FIFA_FORMAT_STOPPED',
  // Ball Tracking Format
  AUTO_BALL_TRACKING_SUBMITTED: 'AUTO_BALL_TRACKING_SUBMITTED',
  AUTO_BALL_TRACKING_FINISHED: 'AUTO_BALL_TRACKING_FINISHED',
  AUTO_BALL_TRACKING_FAILED: 'AUTO_BALL_TRACKING_FAILED',
  AUTO_BALL_TRACKING_STOPPED: 'AUTO_BALL_TRACKING_STOPPED',
  // Events Detection Format
  AUTO_EVENTS_DETECTION_SUBMITTED: 'AUTO_EVENTS_DETECTION_SUBMITTED',
  AUTO_EVENTS_DETECTION_FINISHED: 'AUTO_EVENTS_DETECTION_FINISHED',
  AUTO_EVENTS_DETECTION_FAILED: 'AUTO_EVENTS_DETECTION_FAILED',
  AUTO_EVENTS_DETECTION_STOPPED: 'AUTO_EVENTS_DETECTION_STOPPED',
  // Surfaces Format
  AUTO_SURFACES_SUBMITTED: 'AUTO_SURFACES_SUBMITTED',
  AUTO_SURFACES_FINISHED: 'AUTO_SURFACES_FINISHED',
  AUTO_SURFACES_FAILED: 'AUTO_SURFACES_FAILED',
  AUTO_SURFACES_STOPPED: 'AUTO_SURFACES_STOPPED',
  // Automated Tracking Data
  AUTO_AUTOMATED_TRACKING_DATA_SUBMITTED: 'AUTO_AUTOMATED_TRACKING_DATA_SUBMITTED',
  AUTO_AUTOMATED_TRACKING_DATA_FINISHED: 'AUTO_AUTOMATED_TRACKING_DATA_FINISHED',
  AUTO_AUTOMATED_TRACKING_DATA_FAILED: 'AUTO_AUTOMATED_TRACKING_DATA_FAILED',
  AUTO_AUTOMATED_TRACKING_DATA_STOPPED: 'AUTO_AUTOMATED_TRACKING_DATA_STOPPED'
}
