/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { theme } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    cursor: default;
    outline: 0;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 15px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function deletePatterns(props) {
  confirmAlert()
    .then(() => {
      props.deletePatterns(props.vp)()
    })
    .catch(() => {})
}

function PatternsMenuItem(props) {
  const hasPatterns = !!props.vp.files.custom_patterns
  const isDeletePatternsAvailable = props.isDeletePatternsAvailable
  let disabledItem
  if (props.uploadingPatterns) {
    disabledItem = false
  } else {
    disabledItem = hasPatterns ? !isDeletePatternsAvailable : !props.isUploadPatternsAvailable
  }

  return (
    <div>
      <div
        css={css`
          ${styles.itemContainer};
          pointer-events: ${disabledItem ? 'none' : 'all'};
        `}
      >
        <div css={styles.icon} />
        <div css={styles.content} className="video-menu-item-content">
          <div
            css={css`
              ${styles.name};
              color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
            `}
          >
            {window.I18n.t('patterns.title')}
          </div>
          <div css={styles.icons}>
            {patternsMenuItemsUI(
              props,
              isDeletePatternsAvailable,
              hasPatterns,
              props.uploadingPatterns,
              props.isUploadPatternsAvailable,
              props.uploadPatterns
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function patternsMenuItemsUI(
  props,
  isDeletePatternsAvailable,
  hasPatterns,
  uploadingPatterns,
  isUploadPatternsAvailable,
  uploadPatterns
) {
  return (
    <React.Fragment>
      {/* Delete patterns */}
      {isDeletePatternsAvailable && (
        <Tooltip title={window.I18n.t('patterns.delete')} enterDelay={400} placement="top">
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onClick={() => deletePatterns(props)}
          >
            delete
          </Icon>
        </Tooltip>
      )}

      {/* Uploading patterns */}
      {uploadingPatterns && (
        <Tooltip title={window.I18n.t('patterns.uploading')} enterDelay={400} placement="top">
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
          />
        </Tooltip>
      )}

      {/* Upload patterns */}
      {isUploadPatternsAvailable && !hasPatterns && (
        <Tooltip title={window.I18n.t('patterns.upload')} enterDelay={400} placement="top">
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.secondary.main};
            `}
            onClick={uploadPatterns}
          >
            cloud_upload
          </Icon>
        </Tooltip>
      )}

      {/* Patterns uploaded */}
      {hasPatterns && (
        <Tooltip title={window.I18n.t('patterns.uploaded')} enterDelay={400} placement="top">
          <Icon
            css={css`
              ${styles.iconTag};
              color: ${theme.palette.common.successGreen};
            `}
          >
            check_circle_outline
          </Icon>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

PatternsMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  uploadingPatterns: PropTypes.bool.isRequired,
  uploadPatterns: PropTypes.func.isRequired,
  isUploadPatternsAvailable: PropTypes.bool.isRequired,
  deletePatterns: PropTypes.func.isRequired, // eslint-disable-line
  isDeletePatternsAvailable: PropTypes.bool.isRequired
}

export default PatternsMenuItem
