/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core'
import { Formik } from 'formik'
import { theme } from '../../../constants'
import logo from '../../../assets/logo-black.png'

const styles = {}
styles.inputWrapper = css`
  text-align: center;
  margin: auto;
  padding: 0 60px;
`
styles.poweredBy = css`
  font-size: 18px;
  font-weight: lighter;
  color: ${theme.palette.common.greyMetrica};
  margin-right: 7px;
`
styles.footerWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`
styles.goToLink = css`
  cursor: pointer;
  color: ${theme.palette.common.black};
  padding: 0 92px;
  font-size: 13px;
  margin-top: 13px;
`
styles.buttonStyle = { background: theme.palette.common.electricBlue, color: 'white' }

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.charolBlack
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: false
    }

    this.formInitialValues = { email: '', password: '' }
  }

  onToggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  validateForm = values => {
    const errors = {}
    if (!values.password) {
      errors.password = window.I18n.t('common.required')
    }

    if (!values.email) {
      errors.email = window.I18n.t('common.required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = window.I18n.t('login.invalidEmail')
    }

    return errors
  }

  render() {
    return (
      <Formik
        initialValues={this.formInitialValues}
        validate={this.validateForm}
        onSubmit={this.props.onSubmit}
      >
        {formik => (
          <form noValidate onSubmit={formik.handleSubmit} method="POST">
            <div
              css={[
                styles.inputWrapper,
                css`
                  margin-top: 70px;
                `
              ]}
            >
              <CssTextField
                fullWidth
                label={window.I18n.t('login.enterYourEmail')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                type="email"
                name="email"
                InputProps={{ autoComplete: 'new-email' }}
                margin="normal"
                error={formik.errors.email && formik.touched.email && !!formik.errors.email}
                helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                css={{
                  input: { color: theme.palette.common.charolBlack },
                  label: { color: theme.palette.common.charolBlack }
                }}
              />
            </div>
            <div css={styles.inputWrapper}>
              <CssTextField
                fullWidth
                label={window.I18n.t('login.enterYourPassword')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                margin="normal"
                error={
                  formik.errors.password && formik.touched.password && !!formik.errors.password
                }
                helperText={
                  formik.errors.password && formik.touched.password && formik.errors.password
                }
                css={{
                  input: { color: theme.palette.common.charolBlack, paddingRight: '30px' },
                  label: { color: theme.palette.common.charolBlack }
                }}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end" css={{ position: 'absolute', right: '-10px' }}>
                      {formik.values.password && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.onToggleShowPassword}
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div
              css={css`
                padding: 0 90px;
                margin-top: 35px;
              `}
            >
              <Button
                variant="contained"
                fullWidth
                disabled={formik.isSubmitting}
                type="submit"
                style={styles.buttonStyle}
              >
                {formik.isSubmitting ? (
                  <CircularProgress color="primary" size={24} thickness={4} />
                ) : (
                  <span>{window.I18n.t('common.login')}</span>
                )}
              </Button>
            </div>
            <div css={styles.goToLink} onClick={() => this.props.goToReset()}>
              {window.I18n.t('login.forgotPassword')}
            </div>
            <div
              css={styles.goToLink}
              style={{ marginTop: '8px' }}
              onClick={() => this.props.goToSignUp()}
            >
              Sign Up
            </div>
            <div css={styles.footerWrapper}>
              <span css={styles.poweredBy}>Powered by</span>
              <img
                src={logo}
                alt="Logo"
                css={css`
                  width: 160px;
                  margin-left: 7px;
                `}
              />
            </div>
          </form>
        )}
      </Formik>
    )
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  goToReset: PropTypes.func.isRequired,
  goToSignUp: PropTypes.func.isRequired
}

export default LoginForm
