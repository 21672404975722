export default {
  addKeyModal: {
    action: 'Toepassen',
    errorMessage:
      'Fout bij toepassing van sleutel. Als het probleem aanhoudt, neem dan contact op met het Metrica Sports support-team',
    minutesMessage: 'Je sleutel is verwerkt. Je hebt nu %{minutes} minuten beschikbaar.',
    placeholder: 'Typ een sleutel...',
    successMessage: 'Sleutel is succesvol verwerkt',
    title: 'Voeg een Sleutel toe'
  },
  admin: {
    NoGames: 'Geen games, voeg je eerste toe...',
    NoResults: 'Geen Resultaten...',
    addKey: 'Sleutel toevoegen',
    atd: 'ATD',
    automatedTrackingData: 'Automatische Trackingdata (ATD)',
    automatedTrackingDataJobSuccess:
      'Automatische Trackingdata-proces op %{vpName} is voltooid en is nu beschikbaar in Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Automatische Trackingdata verwerkt',
    awayTeam: 'Uitploeg',
    awayTeamScore: 'Score Uitploeg',
    ballDetection: 'Baldetectie',
    ballTracking: 'Baltracking',
    ballTrackingProcessedTooltip: 'Baldetectie verwerkt',
    ballTrackingTooltip:
      'Dit proces zal de bal detecteren en je in staat stellen deze te visualiseren in de Live-formatie (2D-veld) in Play. Balcoördinaten zijn ook beschikbaar in het FIFA EPTS-bestand.',
    cancelATDProcessSuccessfully: 'Automatische Trackingdata is geannuleerd op %{vpName}',
    cancelDataIntegrationProcessSuccessfully: 'Data-integratieproces is geannuleerd op %{vpName}',
    cancelEventsProcessSuccessfully: 'Het Smart Tagging-proces is geannuleerd op %{vpName}.',
    cancelProcessTooltip: 'Proces annuleren',
    cancelSurfacesProcessSuccessfully: 'Oppervlakken-proces is geannuleerd op %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Verwerken van Tracking FIFA-indeling is geannuleerd op %{vpName}',
    category: 'Categorie',
    checking: 'Bezig met controleren...',
    cloudMinutes: 'Cloudminuten',
    competition: 'Competitie',
    connectionFailed: 'Verbinding mislukt',
    connectionFailedText:
      'Er lijkt een probleem te zijn met het ophalen van de statusinformatie. Controleer je internetverbinding en laad de pagina opnieuw. Als het probleem aanhoudt, neem dan contact op met support',
    correctedTracking: 'Verwerking Trackingcorrectie',
    correctionUsage: 'Ga je dit Video Project gebruiken voor Trackingcorrectie?',
    correctionUsageTooltip:
      "De Trackingcorrectiemanager (TCM) is een gebruikersgerichte module binnen Play om tracks te corrigeren die middels onze ATD zijn gegenereerd. Deze ATD genereert automatisch data zonder ID's voor een voetbalwedstrijd. Gebruik de TCM voor het corrigeren van verkeerde tracks en hen een identiteit toe te kennen. Neem contact met ons op voor meer informatie.",
    createVideoProject: 'Video Project maken',
    creatingGame: 'Video Project aan het maken...',
    dataIntegrationFailed:
      'Data-integratie is mislukt op %{vpName}. Ga naar het Data-integratiepaneel, controleer je bestanden en probeer het opnieuw. Als de fout zich blijft voordoen, neem dan contact op met het Metrica Sports support-team',
    dataIntegrationJobSuccess: 'Data-integratieproces voor %{vpName} is met succes voltooid',
    date: 'Datum',
    description: 'Omschrijving',
    editedSuccessfully: '%{vpName} is succesvol bewerkt',
    errorContactSupport: 'Fout. Neem contact op met support',
    errorProcessTooltip: 'Fout bij proces',
    eventsDetection: 'Automatische Tagging',
    eventsDetectionProcessedTooltip: 'Smart Tagging verwerkt',
    eventsDetectionTooltip: {
      footer: "*Beschikbaar voor video's langer dan 30 minuten.",
      header:
        'Maak gebruik van onze technologie om automatisch een reeks gebeurtenissen te detecteren die zich voordoen in de wedstrijd*:',
      improved: 'Verbeterd'
    },
    fieldRecognition: 'Velddetectie',
    fieldTrackingProcessedTooltip: 'Velddetectie verwerkt',
    fieldTrackingTooltip:
      'Verwerk Velddetectie om visualisaties toe te voegen met snelheids- en afstandswaarden en alle tekeningen die veldafhankelijk zijn in Play.',
    fileDeletedSuccessfully: '%{fileName} succesvol verwijderd voor %{vpName}',
    fileUploadedError: 'Fout bij uploaden van %{fileName} voor %{vpName}',
    fileUploadedSuccessfully: '%{fileName} is succesvol geüpload voor %{vpName}',
    forkedGameLimit:
      'Dit Video Project is van een andere Organisatie, dus het is niet mogelijk om deze te wijzigen',
    gameDeletedSuccessfully: '%{game} succesvol verwijderd',
    gameVideoProject: 'Wedstrijd-Video Project',
    getMoreMinutes: 'Neem er meer',
    homeTeam: 'Thuisploeg',
    homeTeamScore: 'Score Thuisploeg',
    initializing: 'Initialiseren...',
    jobFailed:
      'Het %{process}-proces is mislukt voor %{vpName}. Neem contact op met het Metrica Sports support-team',
    liveCoding: 'of doorgaan voor live coding?',
    liveCodingTooltip:
      'Je kunt een Video Project maken zonder een videobestand te selecteren, voor Live Coding-doeleinden. Zodra je de video hebt, kun je deze toevoegen om Automatische Trackingdata te krijgen binnen het gecodeerde Video Project.',
    localTeamOnTheLeft:
      "Bevindt de 'Thuisploeg' zich aan de linkerkant van het veld in de eerste helft?",
    localTeamOnTheLeftTooltip:
      'Om Automatische Tagging te verwerken, specificeer aan welke kant van het veld elk team zich bevindt. Bekijk de video die je uploadt om dit te valideren.',
    noFootballSideViewTooltip:
      'Dit Video Project bevat een videobestand van een niet-voetbal groothoek- of tv-uitzending, dus alleen Speler- en Teamdetectie is verwerkt.',
    noMatches: 'Geen Overeenkomsten...',
    noSubscriptionOwnerTooltip:
      "Neem contact op met '%{email}', de beheerder van jouw abonnement, om te upgraden, of ga naar onze Plannenpagina om je eigen abonnement aan te maken",
    noVideoFile: 'Geen videobestand',
    notPossibleToDeleteThisEntry: 'Dit item kan niet worden verwijderd',
    numberOfHalves: 'Hoeveel helften worden gedurende deze wedstrijd gespeeld?',
    numberOfHalvesTooltip:
      'Om Automatische Tagging te verwerken, specificeer hoeveel spelperiodes de video die je uploadt bevat.',
    oldAtdTooltip: 'Deze video is verwerkt met een oudere versie van ATD',
    onRequest: 'Op verzoek',
    organizations: 'Organisaties',
    place: 'Locatie',
    playerTracking: 'Speler- & Teamdetectie',
    playerTrackingProcessedTooltip: 'Spelerdetectie verwerkt',
    playerTrackingTooltip:
      'Dit proces detecteert de keepers en de 10 veldspelers van elk team. Hierdoor kun je de Live Formatie (2D-veld) visualiseren en automatisch Spelervisualizations toevoegen in Play. Spelerscoördinaten zijn ook beschikbaar in het FIFA EPTS-bestand.',
    postProcessingData: 'Nabewerking van data...',
    preProcessingAtd: 'Voorbewerking...',
    processingTooltip: 'Wordt verwerkt…',
    readyToUpload: 'Klaar om te uploaden',
    search: 'Zoeken…',
    selectVideoFile: 'Selecteer videobestand',
    settingUpAtd: 'Detectie aan het opstarten',
    settings: 'Instellingen',
    singleVideoProject: 'Enkel-Video Project',
    startAtd: 'ATD starten',
    startFifaMessage:
      'FIFA-integratieproces voor %{vpName} is gestart. We geven een melding weer als het klaar is. Even geduld aub.',
    startProcessingWaitingJobsFailed: 'Het verzenden van wachtende taken is mislukt',
    startProcessingWaitingJobsSuccessfully: 'Wachtende taken zijn succesvol verzonden',
    startSsMessage:
      'Second Spectrum-integratieproces voor %{vpName} is gestart. We geven een melding weer als het klaar is. Even geduld aub.',
    startStatsMessage:
      'Stats-integratieproces voor %{vpName} is gestart. We geven een melding weer als het klaar is. Even geduld aub.',
    startTracabDflMessage:
      'Tracab DFL-integratieproces voor %{vpName} is gestart. We geven een melding weer als het klaar is. Even geduld aub.',
    startTracabMessage:
      'Tracab-integratieproces voor %{vpName} is gestart. We geven een melding weer als het klaar is. Even geduld aub.',
    surfacesJobSuccess: '%{vpName} Oppervlakkenproces is met succes voltooid',
    trackingCorrectionProcessedTooltip: 'Trackingcorrectie is verwerkt',
    trackingFifaFormatJobSuccess:
      'Verwerken van Tracking FIFA-indeling voor %{vpName} is voltooid. Je kunt het bestand nu downloaden door op het menu-item FIFA EPTS-indeling downloaden te klikken',
    trackingLicence: 'Je hebt geen Trackinglicentie',
    uncaughtErrorRow:
      'Fout in Video Project. Gelieve deze pagina te verversen. Als het probleem aanhoudt, neem dan contact op met support of verwijder het Video Project en maak deze opnieuw aan',
    updatingGame: 'Video Project aan het updaten...',
    upgradePlan: 'Upgraden',
    uploadAndTracking: 'Uploaden & Tracken',
    uploadingVideoFile: 'Videobestand aan het uploaden',
    videoFile: 'Videobestand',
    videoFootballSideView:
      'Is dit een videobestand van een groothoek- of tv-uitzending voor voetbal?',
    videoFootballSideViewTooltip: "Velddetectie is alleen geoptimaliseerd voor dit soort video's",
    videoMultipartStart:
      '%{videoName} wordt geüpload op %{vpName}. SLUIT DEZE PAGINA NIET totdat het uploaden is voltooid',
    videoPlaylist: 'Is dit videobestand een afspeellijst?',
    videoPlaylistTooltip:
      'Als je JA selecteert, wordt Clipdetectie uitgevoerd om te herkennen wanneer er een overgang in de video plaatsvindt, wat hiervan een perfect hulpmiddel maakt wanneer je bijvoorbeeld een video uploadt die meerdere clips bevat. Nadat je dit proces hebt uitgevoerd, download dan het Video Project in Play. Daar vind je een codingsbestand voor Clipdetectie met daarin een code die elke overgang bevat als een event.',
    videoProjectSettings: 'Instellingen Video Project',
    videoUploadError:
      'Video-upload van %{videoName} voor %{vpName} is mislukt. Probeer het opnieuw',
    videoUploaded: '%{videoName} is geüpload op %{vpName}',
    vpCreated: '%{vpName} is aangemaakt',
    waitingAtd: 'Aan het wachten...'
  },
  billing: {
    address: 'Adres',
    billed: 'Gefactureerd',
    completed: 'Betaald',
    details: 'Factuurgegevens',
    history: 'Factureringsgeschiedenis',
    invoiceNotFound: 'Factuur niet gevonden',
    noInvoices: 'Geen facturen',
    past_due: 'Achterstallig',
    taxId: 'Fiscaal Identificatienummer'
  },
  checkout: {
    chargeSubscription: 'In rekening brengen op abonnement',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*Kortingscodes kunnen worden toegepast bij het afrekenen.',
    excTax: '(Excl. btw)',
    immediatePayment: 'Directe Betaling',
    incTax: '(Incl. btw)',
    monthlyBilled: 'Maandelijks gefactureerd',
    nextPayment: 'Volgende Betaling',
    noPacks: 'Geen Cloud Minuten Pakket',
    onDate: 'op %{date}',
    packs: 'Pakketten',
    packsTotal: 'Totaalpakketten',
    perMonth: '/maand',
    perYear: '/jaar',
    plans: 'Abonnementen',
    plansTotal: 'Totaal Plannen',
    proceed: 'Ga door naar betalen',
    subtotal: 'Subtotaal',
    taxPayable: 'Te betalen belasting (%{percentage}%)',
    total: 'Totaal',
    unpaid:
      'Je laatste factuur is nog niet betaald. Gelieve je betalingsinformatie bij te werken om verder te gaan.',
    updatePaymentMethod: 'Betaalmethode bijwerken',
    updateSubscription: 'Abonnement bijwerken',
    userDevice: '1 gebruiker/apparaat',
    usersDevices: '%{number} gebruikers/apparaten',
    yearlyBilled: 'Jaarlijks gefactureerd'
  },
  common: {
    back: 'Terug',
    cancel: 'Annuleren',
    canceling: 'Annuleren...',
    clearAll: 'Alles Wissen',
    edit: 'Bewerken',
    email: 'E-mail',
    errorMsg: 'Fout bij het verwerken van %{name}. Neem voor vragen contact op met support.',
    errorTryAgain: 'Fout. Probeer het nog eens',
    genericError: 'Fout, proces mislukt. Neem voor vragen contact op met support.',
    loading: 'Bezig met laden...',
    login: 'Inloggen',
    logout: 'Uitloggen',
    new: 'Nieuw',
    next: 'Volgende',
    no: 'Nee',
    noFileChosen: 'Geen bestand gekozen',
    ok: 'OK',
    required: 'Verplicht',
    reset: 'Wachtwoord herstellen',
    restart: 'Herstarten',
    selectFile: 'Bestand selecteren',
    sort: 'Sorteren',
    start: 'Starten',
    today: 'Vandaag',
    uploading: 'Bezig met uploaden...',
    yes: 'Ja'
  },
  confirmModal: {
    description: 'Weet je het zeker?',
    title: 'Bevestigen'
  },
  dataIntegration: {
    deleteFile: 'Bestand verwijderen',
    featureNotAvailable:
      'Deze functie is niet beschikbaar in je huidige Plan. Je dient te upgraden naar een ander Plan om deze functie te krijgen. Neem contact met ons op voor meer informatie',
    notSideView:
      'Data-integratie is niet beschikbaar voor Video Projecten die een videobestand bevatten van een niet-voetbal groothoek- of tv-uitzending.',
    open: 'Data-integratie openen',
    processJobsFirst:
      'Je dient eerst het verwerken van Automatische Trackingdata (ATD) te starten voordat je het Data-integratiepaneel opent',
    processed: 'Data-integratie verwerkt',
    selectFile: 'Bestand selecteren',
    startProcess: 'Proces starten',
    title: 'Data-integratie',
    waiting: 'Wachten op proces...',
    waitingProcess:
      'Het integratieproces begint zodra de Speler- en Velddetectieprocessen zijn voltooid.'
  },
  deleteModal: {
    checkboxLabel: 'Ik wil ook geen e-mails meer ontvangen van Metrica Sports',
    confirmationType: 'Typ %{validateFormKey} om te bevestigen:',
    deleteAction: 'Ja, Verwijderen',
    description: 'Weet je zeker dat je je account definitief wilt verwijderen?',
    information:
      'Je verliest alle Video Projecten die met dit account zijn gemaakt. We kunnen ze niet meer herstellen nadat ze zijn verwijderd.',
    keepAccount: 'Account behouden',
    suggestion: 'We raden je aan om je account te behouden.',
    title: 'Voor eeuwig verwijderen?',
    validateFormKey: 'VERWIJDEREN'
  },
  error: {
    oops: 'Oeps!',
    restart:
      'Het lijkt erop dat je ergens in probeert te komen wat we nog aan het ontwikkelen zijn...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging niet beschikbaar op Videoprojecten met een oudere ATD-versie.',
    needsAtd: 'Start eerst het ATD-proces om het Smart Tagging-proces uit te kunnen voeren.',
    needsFootballSideView:
      'Smart Tagging niet beschikbaar op Videoprojecten die een videobestand bevatten dat geen voetbal is vanuit een breedhoek of tv-uitzending.',
    needsGameVp: 'Smart Tagging is alleen beschikbaar bij Game Video Projecten.',
    needsNoPlaylist:
      'Smart Tagging niet beschikbaar op Videoprojecten die een afspeellijst videobestand bevatten.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Admin-gebruikers dienen een privé endpoint te gebruiken om wachtwoord te resetten',
    notFound: 'Gebruiker niet gevonden. Probeer het opnieuw met een geldig e-mailadres',
    success: 'Wachtwoord is gereset! Check je e-mail'
  },
  login: {
    contactUs: 'Neem contact met ons op',
    enterYourEmail: 'Vul je e-mailadres in',
    enterYourPassword: 'Voer je wachtwoord in',
    forgotPassword: 'Wachtwoord vergeten?',
    invalidEmail: 'Ongeldig e-mailadres',
    licenceExpired: 'Je licentie is verlopen.',
    trialExpired: 'Je proeflicentie is verlopen.',
    upgradePlan: 'Upgrade jouw Plan'
  },
  patterns: {
    delete: 'Patroonbestand verwijderen',
    title: 'Aangepaste Patronenbestand',
    upload: 'Aangepaste Patronenbestand uploaden',
    uploaded: 'Patroonbestand geüpload',
    uploading: 'Patroonbestand aan het uploaden'
  },
  plans: {
    academic: 'Academisch',
    addMoreProducts: '+ Meer producten toevoegen',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Maandelijks',
    basicCodePadYearly: 'Basic+Code Jaarlijks',
    basicMftCodePadMonthly: 'Full Basic Maandelijks',
    basicMftCodePadYearly: 'Full Basic Jaarlijks',
    basicMftMonthly: 'Basic+MFT Maandelijks',
    basicMftXmlMonthly: 'Basic MFT + XML Maandelijks',
    basicMftXmlYearly: 'Basic MFT + XML Jaarlijks',
    basicMftYearly: 'Basic+MFT Jaarlijks',
    basicMonthly: 'Basic Maandelijks',
    basicXmlMonthly: 'Basic XML Maandelijks',
    basicXmlYearly: 'Basic XML Jaarlijks',
    basicYearly: 'Basic Jaarlijks',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite Jaarlijks',
    eliteYearly: 'Elite Jaarlijks',
    essentialsCodeMonthly: 'Essentials + Code ∞ Maandelijks',
    essentialsCodeYearly: 'Essentials + Code ∞ Jaarlijks',
    essentialsMftCodeMonthly: 'Volledige Essentials Maandelijks',
    essentialsMftCodeYearly: 'Volledige Essentials Jaarlijks',
    essentialsMftMonthly: 'Essentials + MFT Maandelijks',
    essentialsMftYearly: 'Essentials + MFT Jaarlijks',
    essentialsMonthly: 'Essentials Maandelijks',
    essentialsYearly: 'Essentials Jaarlijks',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Maandelijks',
    liteYearly: 'Lite Jaarlijks',
    minutes10: 'Pakket van 10 Cloud Minuten',
    minutes100: 'Pakket van 100 Cloud Minuten',
    minutes1000: 'Pack van 1.000 Cloud Minuten',
    minutes10000: 'Pack van 10.000 Cloud Minuten',
    plan: '%{planName}-Plan',
    primeMftMonthly: 'Prime + MFT Maandelijks',
    primeMftYearly: 'Prime + MFT Jaarlijks',
    primeMonthly: 'Prime Maandelijks',
    primePresetsMftMonthly: 'Full Prime Maandelijks',
    primePresetsMftYearly: 'Volledig Prime Jaarlijks',
    primePresetsMonthly: 'Prime + Presets Maandelijks',
    primePresetsYearly: 'Prime + Presets Jaarlijks',
    primeYearly: 'Prime Jaarlijks',
    pro: 'Pro',
    pro2024Monthly: 'Pro Maandelijks',
    pro2024Yearly: 'Pro Jaarlijks',
    proYearly: 'Pro Jaarlijks',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Maandelijks',
    starterCodePadMonthly: 'Code Maandelijks',
    starterCodePauseMonthly: 'Volledige Starter Maandelijks',
    starterCodePauseYearly: 'Full Starter Jaarlijks',
    starterCodeYearly: 'Starter + Code ∞ Jaarlijks',
    starterPauseMonthly: 'Starter + Pause ∞ Maandelijks',
    starterPauseYearly: 'Starter + Pause ∞ Jaarlijks',
    student: 'Student',
    trial: 'Proefversie'
  },
  playlist: {
    download: 'Afspeellijstbestand downloaden',
    notProcessed: 'Afspeellijstbestand is niet verwerkt in dit Video Project.',
    processed: 'Afspeellijstbestand is verwerkt',
    title: 'Afspeellijst',
    titleDownload: 'Afspeellijst downloaden'
  },
  products: {
    addOns: 'Add-ons',
    basic: 'Onbeperkte Codes & Aantekeningen',
    code: 'CodePad',
    elite: {
      description:
        'Automatiseer en personaliseer je volledige analyseworkflow. Dit plan biedt volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan en toegang tot Play3 met alle functies van het "Pro" Plan.',
      feature1: '✓ Gedeelde Cloud Opslag',
      feature2: '✓ Integratie van Trackinggegevens',
      feature3: '✓ Tracking Correction Manager',
      feature4: '✓ RESTful API',
      feature5: '✓ 5.000 ATD minutenpakket'
    },
    essentials: {
      addon1: 'Onbeperkte Coding-functies (CodePad, Live Coding en XML-import)',
      addon2: 'Handmatige Veldtracking',
      essentialsCodeDescription:
        'Dit plan maakt Dynamische Telestratie beschikbaar om onbeperkte bewegingsvisualisaties toe te voegen tijdens het afspelen van de video en in pauzes. Dit plan bevat geen Handmatige Veldtracking, dus visualisaties van Snelheid en Afstand zijn niet inbegrepen. Het ontgrendelt ook de beperking van coding-functies zoals CodePad, Live Coding en XML-import en heft de limiet van 4 codes per Videoproject op naar onbeperkte codes.',
      essentialsDescription:
        'Dit plan maakt Dynamische Telestratie beschikbaar om onbeperkte bewegingsvisualisaties toe te voegen tijdens het afspelen van de video en in pauzes. Dit plan bevat geen Handmatige Veldtracking, dus visualisaties van Snelheid en Afstand zijn niet inbegrepen. De coding-functies blijven beperkt.',
      essentialsMftCodeDescription:
        'Dit plan maakt Dynamische Telestratie beschikbaar om onbeperkte bewegingsvisualisaties toe te voegen tijdens het afspelen van de video en in pauzes, en activeert ook Manuel Field Tracking om snelheids- en afstandsvisualisaties toe te voegen. Het ontgrendelt ook de beperking van coding-functies zoals CodePad, Live Coding en XML-import en heft de limiet van 4 codes per Video Project op naar onbeperkte codes.',
      essentialsMftDescription:
        'Dit abonnement maakt Dynamische Telestratie beschikbaar om onbeperkte bewegingsvisualisaties toe te voegen tijdens het afspelen van de video en in pauzes, en activeert ook Manuel Field Tracking om snelheids- en afstandsvisualisaties toe te voegen. De coding-functies blijven beperkt.',
      feature1: '✓ Post-game codering (Code Table met maximaal 4 codes)',
      feature2: '✓ Onbeperkte telestratie tijdens pauzes',
      feature3: '✓ Dynamische Telestratie',
      feature4: '✓ 3 minuten ATD-pakket'
    },
    featuresIncluded: 'Inbegrepen functies:',
    freeFeature1: '✓ Post-wedstrijd coding (Code Table met tot 4 codes)',
    freeFeature2: '✓ Handmatige tracking',
    freeFeature3: '✓ Telestratie (tot 150 annotaties)',
    freeFeature4: '✓ Video-exporteren',
    freeFeatures: 'Gratis Functies',
    fullBasic: 'Handmatige Veldtracking',
    lite: 'Automatische Speler- & Veldtracking',
    liteDescription:
      'Geavanceerde coding & geavanceerde visualisaties. Alles automatisch, waardoor het 3x sneller is dan Full Basic!',
    minutesDescription:
      'Vul elk plan aan. Krijg minutenpakketten voor Geautomatiseerde Tracking Data.',
    minutesPack: 'Cloud Minuten Pakket',
    otherDescription: 'Geavanceerde coding-functies met basis telestratie en beperkte annotaties.',
    prime: {
      addon1: 'Handmatige Veldtracking',
      addon2: 'Aangepaste Telestratie (pas je eigen visualisaties aan en sla ze op)',
      feature1: '✓ Volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan',
      feature2: '✓ Toegang tot Play3 met:',
      feature2_1: 'Sequentiële Pauzes',
      feature2_2: 'Geavanceerde Telestratie',
      feature2_3: 'Aangepaste video-export',
      feature3: '✓ 10 minuten ATD-pakket',
      primeDescription:
        'Dit plan omvat volledige toegang tot Play2 met alle functies van het "Essentials + Code ∞" Plan en maakt Play3 mogelijk met geavanceerde telestratie.',
      primeMftDescription:
        'Dit plan biedt volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan en maakt Play3 mogelijk met geavanceerde telestratie.',
      primePresetsDescription:
        'Dit plan biedt volledige toegang tot Play2 met alle functies van het "Essentials + Code ∞" Plan en maakt Play3 mogelijk met geavanceerde telestratie en aangepaste visualisaties.',
      primePresetsMftDescription:
        'Dit plan biedt volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan en stelt Play3 in staat met geavanceerde telestratie en aangepaste visualisaties.'
    },
    pro: {
      description:
        'Dit plan omvat volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan en toegang tot Play3 met alle functies van het "Full Prime" Plan.',
      feature1: '✓ Automatische Speler Tracking',
      feature2: '✓ Automatische Veldtracking',
      feature3: '✓ Volledige toegang tot Play2 met alle functies van het "Full Essentials" Plan',
      feature4: '✓ Toegang tot Play3 met:',
      feature4_1: 'Sequentiële pauzes',
      feature4_2: 'Geavanceerde Telestratie',
      feature4_3: 'Aangepaste video-export',
      feature4_4: 'Aangepaste Visualisaties (pas aan en bewaar je eigen visualisaties)',
      feature5: '✓ 100 ATD minuten pakket'
    },
    remove: 'Verwijderen',
    starter: {
      addon1: 'Onbeperkte telestratie tijdens pauzes',
      addon2: 'Onbeperkte Coding-functies (CodePad, Live Coding en XML-import)',
      feature1: '✓ Post-wedstrijd codering (Code Table met maximaal 4 codes)',
      feature2: '✓ Telestratie tijdens pauzes (tot 50 annotaties per maand)',
      feature3: '✓ Video-export',
      starterCodeDescription:
        'Dit plan ontgrendelt de beperking van coding-functies zoals CodePad, Live Coding en XML-import. Het heft ook de limiet van 4 codes per Videoproject op naar onbeperkte codes. De beperkingen in de functies voor telestratie blijven bestaan bij Free Starter.',
      starterCodePauseDescription:
        'Dit abonnement heft de beperking van coding-functies op zoals CodePad, Live Coding en XML-import. Het verwijdert ook de limiet van 4 codes per Videoproject naar onbeperkte codes, en heft de beperking van telestratie in pauzes op van 50 per maand naar onbeperkt.',
      starterPauseDescription:
        'Dit plan heft de beperking van telestratie in pauzes op van 50 per maand naar onbeperkt. De beperkingen in de coding-functies blijven bestaan in Free Starter.'
    },
    totalPack: 'Totaal: %{currency} %{cost}',
    totalPlan: 'Totaal: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Facturering',
    billingSettings: 'Factureringsinstellingen',
    billingSettingsDescription: '(wijzig je Plan, update betaalmethoden...)',
    cancelAt: 'Je Plan stopt op',
    cancelPlan: 'Plan annuleren',
    confirmNewPassword: 'Bevestig nieuw Wachtwoord',
    deleteAccount: 'Account verwijderen',
    details: 'Mijn gegevens',
    emailNotActivated: 'E-mailnotificaties zijn nu geactiveerd',
    emailNotDeactivated: 'E-mailnotificaties zijn nu gedeactiveerd',
    emailNotifications: 'E-mailnotificaties',
    language: 'Taal',
    languageChanged: 'Taal is bijgewerkt',
    newPassword: 'Nieuw Wachtwoord',
    organizationLabel: 'Voorvoegsel Organisatie',
    organizationTooltip:
      'Dit voorvoegsel is nodig voor het importeren van aangepaste patronen naar een Video Project. Neem contact met ons op voor meer informatie of bekijk de documentatie op codeball.metrica-sports.com',
    passwordsDontMatch: 'Wachtwoorden komen niet overeen',
    pauseAt: 'Je abonnement wordt gepauzeerd op',
    planCanceled: 'Je plan is geannuleerd. Check je e-mail voor meer informatie',
    plans: 'Abonnementen',
    quantity: 'Hoeveelheid:',
    renewAt: 'Je Plan wordt verlengd op',
    title: 'Instellingen',
    upgradePlan: 'Plan upgraden',
    userKey: 'Gebruikerssleutel:',
    userKeyUsages: '%{current}/%{max} gebruikt',
    userSettings: 'Gebruikersinstellingen'
  },
  shoppingCart: {
    subtitle: 'Kies je Play plan en add-ons om je prestaties te verbeteren.',
    title: 'Stel je Play in.'
  },
  subscription: {
    cancelSubscription: 'Abonnement opzeggen',
    errorUpdating:
      'Er was een fout bij het bijwerken van je abonnement. Stuur een e-mail naar support@metrica-sports.com en we helpen je graag verder.',
    free: 'Gratis',
    isObsolete:
      'Je abonnement kan niet worden gewijzigd. Vervang verouderde producten door nieuwe om wijzigingen mogelijk te maken',
    licenseKeys: 'Licentiesleutels',
    pendingRefundRequest:
      'Er kunnen geen verdere wijzigingen aan dit abonnement worden gemaakt zolang er een terugbetalingsverzoek in behandeling is.',
    undoCancel: 'Annulering van abonnement ongedaan maken',
    undoPause: 'Abonnement pauze ongedaan maken',
    updatePaymentMethod: 'Betaalmethode bijwerken',
    updatedSuccessfully: 'Abonnement succesvol bijgewerkt',
    yourPlans: 'Jouw Plannen',
    yourSubscription: 'Jouw Abonnement'
  },
  successfulPayment: {
    activateLicence:
      'Activeer je licentie of Cloud-tegoed door de verstrekte licentiesleutel in te voeren.',
    allTheBest: 'Al het beste,',
    applyKey: 'Sleutel toepassen op dit account',
    applyKeyDescription:
      'Wilt u de licentiesleutel automatisch toepassen op het account waarop u bent ingelogd?',
    downloadPlay: '%{pre}Download PLAY%{post} via onze website.',
    faq: 'Om je te helpen met het starten van je bestelling, bekijk de %{pre}Veelgestelde Vragen%{post} op onze website. Hier is een snelle samenvatting van de informatie die op dit moment het meest relevant voor je kan zijn:',
    goToSummary:
      'Zo niet, raadpleeg dan de onderstaande samenvatting voor de activeringsinstructies.',
    happyPlay:
      'We hopen dat alles duidelijk is, maar aarzel niet om contact met ons op te nemen als je nog vragen of twijfels hebt. Gelukkig PLAY!',
    helpDesk:
      'Raadpleeg onze %{pre}HelpDesk%{post} voor algemene informatie over het gebruik van PLAY.',
    keyApplied: 'Sleutel toegepast',
    licenseKey: 'Je licentiesleutel is: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'Het team van Metrica Sports',
    settingsCloud: 'Bezoek onze %{pre}Cloud%{post} en navigeer naar het instellingenmenu om:',
    technicalSupport:
      'Voor technische ondersteuning, vul het contactformulier in dat beschikbaar is in PLAY/Cloud, of stuur een e-mail naar %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'Houd er rekening mee dat onze abonnementen automatisch worden verlengd totdat ze expliciet worden geannuleerd via de instellingen in %{pre}Cloud%{post}, in overeenstemming met onze Algemene Voorwaarden.',
    thanks:
      'Bedankt voor je bestelling! We hebben deze succesvol verwerkt en je licentiesleutels hieronder bijgevoegd.',
    title: 'Bestelbevestiging',
    updatePayment: 'Werk je betaalmethode bij.',
    updateSubscription: 'Annuleer of wijzig je abonnement.'
  },
  surfaces: {
    automaticVp:
      'Oppervlakken zijn niet beschikbaar voor Video Projecten met een oudere ATD-versie',
    delete: 'Oppervlakkenbestand verwijderen',
    needsAtd: 'Start eerst het ATD-proces om daarna Oppervlakken-processen uit te voeren',
    needsElite:
      'Aangepaste Oppervlakken zijn beschikbaar bij het Elite-Plan. Neem contact met ons op voor meer informatie',
    needsFootballSideView:
      'Oppervlakken zijn niet beschikbaar voor Video Projecten die een videobestand bevatten van een niet-voetbal groothoek- of tv-uitzending',
    pitchControl:
      'Door dit proces uit te voeren, kun je Pitch Control-visualisaties toevoegen in Play.',
    pitchControlProcessed: 'Pitch Control-oppervlak verwerkt',
    pitchControlProcessing: 'Pitch Control aan het verwerken',
    processed: 'Oppervlak-bestand geüpload',
    processing: 'Oppervlak-bestand aan het verwerken',
    title: 'Oppervlakken',
    titlePitchControl: 'Pitch Control',
    upload: 'Upload je eigen Oppervlak',
    uploading: 'Oppervlak aan het uploaden...'
  },
  trackingFifaFormat: {
    download: 'Bestand met FIFA EPTS-indeling downloaden',
    needsElite:
      'Deze functie is alleen beschikbaar na het importeren van de bijbehorende bestanden in de Data-integratiemodule, in Video Projecten met Automatische Trackingdata of Video Projecten binnen het Elite-Plan.',
    processed: 'FIFA EPTS-indeling verwerkt',
    start:
      'Start het proces om een bestand met FIFA EPTS-indeling te genereren. Je kunt daarna het bestand downloaden',
    title: 'FIFA EPTS-indeling',
    titleDownload: 'FIFA EPTS-indeling downloaden'
  },
  upgradeModal: {
    linksDescription: 'Neem het Cloud Engine-minutenpakket dat het beste bij je past:',
    minutePackLinks: {
      minutes_10: 'Krijg 10 minuten voor €10',
      minutes_100: 'Krijg 100 minuten voor €90',
      minutes_1000: 'Krijg 1.000 minuten voor €750',
      minutes_10000: 'Krijg 10.000 minuten voor €5.000'
    },
    noMinutes: {
      description:
        'Je hebt niet genoeg minuten beschikbaar om dit Video Project te maken. Wist je dat je minutenpakketten kunt krijgen, ongeacht welk Plan je hebt?',
      secondDescription: '',
      title: 'Neem meer minuten om een Video Project te maken!'
    },
    notEnoughMinutes: {
      description:
        'Helaas moeten we je mededelen dat je niet genoeg minuten hebt voor het ATD-proces van je Video Projecten.',
      secondDescription:
        'Als je ATD wilt verwerken, zal je meer minuten aan je account moeten toevoegen. De minuten die je neemt, verlopen niet!',
      title: 'Niet genoeg minuten beschikbaar'
    }
  },
  video: {
    cancelUpload: 'Upload annuleren',
    delete: 'Video verwijderen',
    download: 'Video downloaden',
    title: 'Videobestand',
    upload: 'Video uploaden',
    uploaded: 'Video geüpload',
    uploading: 'Video aan het uploaden'
  },
  videoProject: {
    delete: 'Videoproject verwijderen',
    edit: 'Videoproject bewerken',
    processWaitingJobs: 'Wachtende taken verwerken',
    title: 'Video Project'
  }
}
