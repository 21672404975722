import ActionTypes from '../constants/actionTypes'

const initialState = {
  confirmAlertOpen: false,
  confirmAlertOptions: {
    title: 'confirmModal.title',
    body: 'confirmModal.description',
    confirmButtonText: 'common.yes',
    cancelButtonText: 'common.cancel',
    confirmation: null
  }
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_CONFIRM_ALERT_NOTIFICATION: {
      return {
        ...state,
        confirmAlertOpen: true,
        confirmAlertOptions: {
          ...state.confirmAlertOptions,
          ...action.payload.options,
          confirmation: null
        }
      }
    }

    case ActionTypes.CLOSE_CONFIRM_ALERT_NOTIFICATION: {
      return {
        ...state,
        confirmAlertOpen: false,
        confirmAlertOptions: {
          ...initialState.confirmAlertOptions,
          confirmation: action.payload.confirmation
        }
      }
    }

    default:
      return state
  }
}
