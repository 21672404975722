/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import eventsDetectionLogo from '../../../../assets/events-detection-icon.png'
import EventsDetectionJob from '../EventsDetectionJob'
import { theme, Status, GAMES_DATASET_DATA_TYPE } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    border-bottom: 1px solid ${grey};
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logoImg: css`
    width: 18px;
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

const CustomBadge = withStyles(() => ({
  root: {
    '& .MuiBadge-colorPrimary': {
      position: 'relative',
      backgroundColor: theme.palette.common.blue,
      textTransform: 'uppercase',
      fontSize: '9px',
      height: '14px',
      marginLeft: '10px'
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'unset',
      padding: '0px 10px'
    }
  }
}))(Badge)

function shouldDisableItem(eventsDetectionState, props) {
  const dataType = props.vp.data_type
  if (
    eventsDetectionState.deletingVp ||
    dataType === GAMES_DATASET_DATA_TYPE.AUTOMATIC ||
    !props.isProcessEventsDetectionAvailable
  ) {
    return true
  }

  return false
}

function EventsDetectionMenuItem(props) {
  const [showStartTooltip, setShowStartTooltip] = React.useState(false)
  const [hideStartTooltip, setHideStartTooltip] = React.useState(false)

  let tooltipMessage = ''
  if (props.vp.data_type === GAMES_DATASET_DATA_TYPE.AUTOMATIC) {
    tooltipMessage = window.I18n.t('eventsDetection.automaticVp')
  } else if (props.vp.game.description) {
    tooltipMessage = window.I18n.t('eventsDetection.needsGameVp')
  } else if (props.vp.is_playlist) {
    tooltipMessage = window.I18n.t('eventsDetection.needsNoPlaylist')
  } else if (!props.vp.is_football_side_view) {
    tooltipMessage = window.I18n.t('eventsDetection.needsFootballSideView')
  } else if (!props.isProcessEventsDetectionAvailable) {
    tooltipMessage = window.I18n.t('eventsDetection.needsAtd')
  }

  const startJob = props.processEventsDetection

  return (
    <EventsDetectionJob
      vp={props.vp}
      eventsDetectionJob={props.eventsDetectionJob}
      isVpCreatedInCloud={props.isVpCreatedInCloud}
      deletingVp={props.deletingVp}
      render={eventsDetectionState => {
        const disabledItem = shouldDisableItem(eventsDetectionState, props)

        return (
          <div css={styles.itemContainer}>
            <div css={styles.icon}>
              <span
                css={css`
                  ${styles.innerIcon};
                  opacity: ${disabledItem ? 0.35 : 1};
                `}
              >
                <img src={eventsDetectionLogo} css={styles.logoImg} />
              </span>
            </div>
            <Tooltip
              title={tooltipMessage}
              placement="top"
              open={showStartTooltip && !hideStartTooltip}
              onOpen={() => setShowStartTooltip(true)}
              onClose={() => setShowStartTooltip(false)}
            >
              <div css={styles.content} className="video-menu-item-content">
                <div
                  css={css`
                    ${styles.name};
                    color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
                  `}
                >
                  Smart Tagging
                  <Tooltip
                    title={
                      <React.Fragment>
                        <span>{window.I18n.t('admin.eventsDetectionTooltip.header')}</span>
                        <ul>
                          <li>ATTACKING PLAYERS IN THE BOX</li>
                          <li>BALL IN FINAL THIRD</li>
                          <li>BUILD UPS ({window.I18n.t('common.new')})</li>
                          <li>COUNTER ATTACKS ({window.I18n.t('common.new')})</li>
                          <li>DIRECT ATTACKS ({window.I18n.t('common.new')})</li>
                          <li>GOALS</li>
                          <li>ORGANIZED ATTACKS ({window.I18n.t('common.new')})</li>
                          <li>SET PIECES - THROW-IN ({window.I18n.t('common.new')})</li>
                          <li>
                            SET PIECES - GOAL KICK (
                            {window.I18n.t('admin.eventsDetectionTooltip.improved')})
                          </li>
                          <li>
                            SET PIECES - CORNERS (
                            {window.I18n.t('admin.eventsDetectionTooltip.improved')})
                          </li>
                          <li>SHOTS AND CROSSES ({window.I18n.t('common.new')})</li>
                          <li>SPRINTS</li>
                        </ul>
                        <span>{window.I18n.t('admin.eventsDetectionTooltip.footer')}</span>
                      </React.Fragment>
                    }
                    placement="left"
                    onOpen={() => setHideStartTooltip(true)}
                    onClose={() => setHideStartTooltip(false)}
                  >
                    <Icon
                      style={{
                        color: 'grey',
                        fontSize: '18px',
                        marginLeft: '10px',
                        top: '6px',
                        left: '5px',
                        transform: 'rotate(5deg)'
                      }}
                    >
                      info
                    </Icon>
                  </Tooltip>
                  <CustomBadge badgeContent={window.I18n.t('common.new')} color="primary" />
                </div>
                <div css={styles.icons}>
                  {eventsDetectionJobUI(props, eventsDetectionState, startJob)}
                </div>
              </div>
            </Tooltip>
          </div>
        )
      }}
    />
  )
}

function eventsDetectionJobUI(props, eventsDetectionState, startJob) {
  if (props.isEventsDetectionRunning) {
    return <RunningUi vpId={props.vp._id} cancelEventsDetection={props.cancelEventsDetection} />
  }

  if (eventsDetectionState.hasEventsDetection) {
    return done()
  }

  if (!props.vp.files.video) {
    return null
  }

  if (!props.vp.compute_events || props.vp.is_playlist || !props.vp.is_football_side_view) {
    return startUI(props, startJob)
  }

  if (props.eventsDetectionJob) {
    const eventsDetectionJob = props.eventsDetectionJob
    const status = eventsDetectionJob && eventsDetectionJob.status

    switch (status) {
      case 'CHECKING':
      case Status.QUEUED:
      case Status.QUEUED_LOCALLY:
      case Status.STARTING:
      case Status.FINISHING:
      case Status.CONNECTIONFAILED:
      case Status.ACTIVE:
        return jobInProgress(props)
      case Status.FAILED:
        return errorUI(props)
      case Status.COMPLETE: // In this case is complete, but doesnt have surfaces
      case Status.CANCEL:
        return startUI(props, startJob)
      default:
        return null
    }
  }

  if (eventsDetectionState.showStartEventsDetection) {
    return startUI(props, startJob)
  }

  if (eventsDetectionState.errorTryAgain) {
    return errorUI(props, startJob)
  }

  return null
}

function jobInProgress(props) {
  return <RunningUi vp={props.vp} cancelEventsDetection={props.cancelEventsDetection} />
}

function done() {
  return (
    <React.Fragment>
      <Tooltip
        title={window.I18n.t('admin.eventsDetectionProcessedTooltip')}
        enterDelay={400}
        placement="top"
      >
        <Icon
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.common.successGreen};
          `}
        >
          check_circle_outline
        </Icon>
      </Tooltip>
    </React.Fragment>
  )
}

function errorUI(props) {
  return (
    <span
      css={css`
        ${styles.iconTag};
        color: ${theme.palette.error.main};
        font-size: 12px;
        ${props.isProcessEventsDetectionAvailable ? '' : styles.disableIcon};
      `}
    >
      {window.I18n.t('admin.errorContactSupport')}
    </span>
  )
}

function startUI(props, startJob) {
  return (
    <span
      css={css`
        ${styles.iconTag};
        ${styles.iconButton};
        color: ${theme.palette.common.blue};
        font-size: 12px;
        text-decoration: underline;
        ${props.isProcessEventsDetectionAvailable ? '' : styles.disableIcon};
      `}
      onClick={startJob}
    >
      {window.I18n.t('common.start')}
    </span>
  )
}

class RunningUi extends React.PureComponent {
  state = {
    isHovered: false
  }

  setHover = isHovered => () => {
    this.setState({ isHovered })
  }

  cancelProcess = () => {
    confirmAlert()
      .then(() => {
        this.props.cancelEventsDetection(this.props.vpId)
      })
      .catch(() => {})
  }

  render() {
    return (
      <Tooltip title={window.I18n.t('admin.cancelProcessTooltip')} enterDelay={400} placement="top">
        {!this.state.isHovered ? (
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
            onMouseEnter={this.setHover(true)}
            onMouseLeave={this.setHover(false)}
          />
        ) : (
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onMouseLeave={this.setHover(false)}
            onClick={this.cancelProcess}
          >
            cancel
          </Icon>
        )}
      </Tooltip>
    )
  }
}

EventsDetectionMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  eventsDetectionJob: PropTypes.object,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired,
  isEventsDetectionRunning: PropTypes.func.isRequired,
  isProcessEventsDetectionAvailable: PropTypes.bool.isRequired,
  processEventsDetection: PropTypes.func.isRequired,
  cancelEventsDetection: PropTypes.func.isRequired
}

export default EventsDetectionMenuItem
