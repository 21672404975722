/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import { connect } from 'react-redux'
import Fab from '@material-ui/core/Fab'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import debounce from 'lodash/debounce'
import { toast } from 'react-toastify'
import CreateUpdateGameBySteps from './components/CreateUpdateGameBySteps'
import SearchBar from './components/SearchBar'
import AdminTable from './components/AdminTable'
import ActionTypes from '../../constants/actionTypes'
import modelsService from '../../services/models'
import gamesService from '../../services/games'
import DataIntegrationUploadModal from '../dataIntegration/DataIntegrationUploadModal'
import videoProjectLogo from '../../assets/timer.png'
import zendeskWidget from '../../utils/zendeskWidget'
import { headerHeight, fetchModelsParams, Status, theme } from '../../constants'
import tokenService from '../../services/token'
import { fetchGames, getVp } from './actions'
import getErrorMessage from '../../utils/getErrorMessage'
import UpgradeModal from './components/UpgradeModal'
import AddKeyModal from './components/AddKeyModal'
import {
  stadiumsOptions,
  competitionsOptions,
  teamsOptions,
  organizationsOptions,
  nonCorrectedOrganizationsOptions
} from './selectors'

const styles = {}
styles.tableContainer = css`
  padding: 15px;
  position: relative;
  height: calc(100vh - ${headerHeight * 2}px);
  overflow: scroll;
`
styles.addWrapper = css`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
`
styles.addButton = {
  height: 50,
  width: 50,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.charolBlack,
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.3)',
  '&:hover': {
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.6)',
    backgroundColor: theme.palette.primary.main
  }
}
styles.dialogContent = css`
  h6 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  label span {
    font-size: 17px;
  }
`
styles.dialogBody = css`
  width: 300px;
  height: 40px;
`
styles.progressBarWrapper = css`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
`

class Admin extends Component {
  state = {
    singleVpDialogOpen: false,
    gameVpDialogOpen: false,
    gameByStepsOpen: false,
    upgradeModalKey: '',
    typeOfVp: '',
    fetchingTableList: false,
    isFetchingAfterScroll: false,
    pageNumber: 0,
    sortDateAscend: false,
    sort: '-date',
    search: '',
    notMoreGames: false,
    editingVp: null,
    dataFilesIsOpen: false,
    dataFileOpenVp: null
  }

  tableContainerRef = React.createRef()

  componentDidMount() {
    this.tableListOnMount()
    this.fetchTeams()
    this.fetchCompetitions()
    this.fetchStadiums()
    this.fetchOrganizations()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notEnoughMinutes && !this.props.notEnoughMinutes) {
      this.openUpgradeModal('notEnoughMinutes')
    }
  }

  fetchTableList = async (pageNumber = 0, search = '', sort = { sort: this.state.sort }) => {
    this.setState({ fetchingTableList: true })
    try {
      const response = await fetchGames(this.props.dispatch, pageNumber, search, sort)
      const games = response.data.docs
      this.props.dispatch({ type: ActionTypes.FETCH_TABLE_LIST, payload: games })
    } finally {
      this.setState({ fetchingTableList: false })
    }
  }

  fetchTableListOnScroll = async (
    pageNumber = 0,
    search = '',
    sort = { sort: this.state.sort }
  ) => {
    this.setState({ fetchingTableList: true })
    let notMoreGames = false
    try {
      const response = await fetchGames(this.props.dispatch, pageNumber, search, sort)
      const games = response.data.docs
      this.props.dispatch({
        type: ActionTypes.FETCH_TABLE_LIST,
        payload: [...this.props.tableList, ...games]
      })
      if (!games.length) notMoreGames = true
    } finally {
      this.setState({ fetchingTableList: false, isFetchingAfterScroll: false, notMoreGames })
    }
  }

  searchGames = debounce(this.fetchTableList, 300)

  tableListOnMount = async (search = '', sort = { sort: this.state.sort }) => {
    this.setState({ fetchingTableList: true })
    try {
      const firstResponse = await fetchGames(this.props.dispatch, 0, search, sort)
      const firstPage = firstResponse.data.docs
      this.props.dispatch({
        type: ActionTypes.FETCH_TABLE_LIST,
        payload: firstPage
      })
    } finally {
      this.setState({ fetchingTableList: false })
    }
  }

  deleteVp = vpId => {
    this.props.dispatch({
      type: ActionTypes.DELETE_VP,
      payload: vpId
    })
  }

  onApplyKey = async () => {
    await this.fetchTableList(this.state.pageNumber, this.state.search)
  }

  updateTableItem = async (vpId, isNew) => {
    const { data } = await getVp(vpId)
    const type = isNew ? ActionTypes.ADD_TABLE_LIST_ITEM : ActionTypes.UPDATE_TABLE_LIST_ITEM
    const payload = isNew ? { ...data, isNew } : data
    this.props.dispatch({ type, payload })
    return Promise.resolve(data)
  }

  closeUpgradeModal = () => {
    zendeskWidget.hide()
    this.setState({ upgradeModalKey: '' })
    this.props.dispatch({
      type: ActionTypes.NOT_ENOUGH_MINUTES,
      payload: false
    })
  }

  openUpgradeModal = key => {
    zendeskWidget.openInForm()
    this.setState({ upgradeModalKey: key })
  }

  openSteps = () => {
    if (this.props.trackingMinutes !== -1 && this.props.trackingMinutes <= 0) {
      this.openUpgradeModal('noMinutes')
      return
    }

    this.setState({ gameByStepsOpen: true })
  }

  fetchStadiums = async () => {
    fetchModelsParams.user = tokenService.get() && tokenService.get().id
    const {
      data: { docs: stadiums }
    } = await modelsService.getStadiums(fetchModelsParams)
    this.props.dispatch({
      type: ActionTypes.FETCH_STADIUMS,
      payload: stadiums
    })
  }

  fetchCompetitions = async () => {
    fetchModelsParams.user = tokenService.get() && tokenService.get().id
    const {
      data: { docs: competitions }
    } = await modelsService.getCompetitions(fetchModelsParams)
    this.props.dispatch({
      type: ActionTypes.FETCH_COMPETITIONS,
      payload: competitions
    })
  }

  fetchTeams = async () => {
    fetchModelsParams.user = tokenService.get() && tokenService.get().id
    const {
      data: { docs: teams }
    } = await modelsService.getTeams(fetchModelsParams)
    this.props.dispatch({
      type: ActionTypes.FETCH_TEAMS,
      payload: teams
    })
  }

  fetchOrganizations = async () => {
    const params = { ...fetchModelsParams }
    delete params.user
    const {
      data: { docs: organizations }
    } = await modelsService.getOrganizations(params)
    this.props.dispatch({
      type: ActionTypes.FETCH_ORGANIZATIONS,
      payload: organizations
    })
  }

  closeDialog = () => {
    this.setState({ gameByStepsOpen: false, dataFilesIsOpen: false, editingVp: null })
  }

  sortTable = () => {
    if (this.state.fetchingTableList) {
      return
    }

    const type = this.state.sortDateAscend ? '-' : ''
    this.fetchTableList(0, this.state.search, { sort: `${type}date` })
    this.setState(prevState => ({
      sortDateAscend: !prevState.sortDateAscend,
      sort: `${type}date`,
      notMoreGames: false
    }))
  }

  changeSearchValue = e => {
    e.persist()
    const val = e.target.value
    this.setState({ search: val, notMoreGames: false }, () => {
      if (val) {
        this.props.dispatch({ type: ActionTypes.GAMES_SEARCH, payload: val })
        this.searchGames(0, val, { sort: this.state.sort })
      } else {
        this.clearSearch()
      }
    })
  }

  clearSearch = () => {
    this.props.dispatch({ type: ActionTypes.GAMES_SEARCH, payload: '' })
    this.setState({ search: '', notMoreGames: false }, () => this.searchGames(0, ''))
  }

  onGamesScroll = e => {
    const scroll = e.currentTarget.scrollTop
    const contentHeight = e.currentTarget.scrollHeight
    const visibleHeight = e.currentTarget.offsetHeight
    const scrollOffset = 500 // Change this to start fetching before reaching the end of the scroll

    if (
      visibleHeight + scroll > contentHeight - scrollOffset &&
      !this.state.isFetchingAfterScroll &&
      !this.state.notMoreGames
    ) {
      this.fetchTableListOnScroll(this.state.pageNumber + 1, this.state.search, {
        sort: this.state.sort
      })
      this.setState({ isFetchingAfterScroll: true, pageNumber: this.state.pageNumber + 1 })
    }
  }

  createUpdateGame = payload => {
    return this.state.editingVp
      ? gamesService.update(this.state.editingVp._id, payload)
      : gamesService.create(payload)
  }

  openDialogToEditVp = vp => {
    this.setState({ gameByStepsOpen: true, editingVp: vp })
  }

  onCreateUpdate = async (vpId, isUpdating) => {
    this.fetchTeams()
    this.fetchCompetitions()
    this.fetchStadiums()
    const isNew = !isUpdating
    const newTableItem = await this.updateTableItem(vpId, isNew)
    if (isNew) {
      this.tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return newTableItem
  }

  editJobItem = (item, job) => {
    this.props.dispatch({
      type: ActionTypes.CHANGE_JOBS_ITEM,
      job,
      payload: item
    })
  }

  failedJobItem = (vpId, job) => {
    this.props.dispatch({
      type: ActionTypes.CHANGE_JOBS_ITEM,
      job,
      payload: {
        [vpId]: {
          status: Status.FAILED,
          progress: null
        }
      }
    })
  }

  onOptionDelete = async (request, id, fetch) => {
    try {
      await request(id)
    } catch (error) {
      console.error(error)
      const deleteMessage = window.I18n.t('admin.notPossibleToDeleteThisEntry')
      const errorMessage = getErrorMessage(error)
      toast.error(() => (
        <React.Fragment>
          {deleteMessage}
          <br />
          {errorMessage}
        </React.Fragment>
      ))
    }
    await this[fetch]()
  }

  startUploadVideo = (gameId, fileObject, automatedTrackingData) => {
    this.adminTableEl.startUploadVideo(gameId, fileObject, automatedTrackingData)
  }

  adminTableRef = el => {
    this.adminTableEl = el
  }

  hasTrackingLicense = user => {
    const userOrganizations = user.organizations
    return userOrganizations.some(
      ({ data_access }) => data_access.tracking === 'both' || data_access.tracking === 'own'
    )
  }

  hasFRecognitionLicense = user => {
    const userOrganizations = user.organizations
    return userOrganizations.some(({ data_access }) => data_access.field_recognition)
  }

  hasEventingLicense = user => {
    const userOrganizations = user.organizations
    return userOrganizations.some(
      ({ data_access }) => data_access.eventing === 'both' || data_access.eventing === 'own'
    )
  }

  openDataIntegration = dataFileOpenVp => {
    this.setState({ dataFilesIsOpen: true, dataFileOpenVp })
  }

  startJobFromDataIntegration = dataIntegrationType => {
    this.adminTableEl[`tableRowEl_${this.state.dataFileOpenVp._id}`].startDataIntegrationJob(
      dataIntegrationType
    )
    this.setState({ dataFilesIsOpen: false, dataFileOpenVp: null })
  }

  hasLicenceConfigurationFor = type => {
    const licenceConfiguration = this.props.user && this.props.user.licence_configuration
    if (!licenceConfiguration) {
      return false
    }

    if (licenceConfiguration[type]) {
      return true
    }

    return false
  }

  hasWritePermissionOnOrganization = organizations => {
    // Metrica Sports users have full access permissions.
    if (this.props.isMetricaSportsUser) {
      return true
    }

    // VP could have more than one organization
    // User could have more than one write organizations
    // We need to intersect both array for check if he can write
    const vpOrganizationIds = organizations.map(o => o._id)
    const userWriteOrganizationIds = this.props.user.organizations
      .filter(o => o.permission.code === 'WRITE')
      .map(o => o.organization._id)

    const filteredArray = vpOrganizationIds.filter(value =>
      userWriteOrganizationIds.includes(value)
    )
    return filteredArray.length > 0
  }

  render() {
    return (
      <div>
        <SearchBar
          searchValue={this.state.search}
          changeSearchValue={this.changeSearchValue}
          clearSearchValue={this.clearSearch}
        />
        <div css={styles.tableContainer} onScroll={this.onGamesScroll} ref={this.tableContainerRef}>
          {this.props.tableList.length ? (
            <AdminTable
              rows={this.props.tableList}
              sortTable={this.sortTable}
              openDialogToEditVp={this.openDialogToEditVp}
              preProcessingJobs={this.props.preProcessingJobs}
              trackingJobs={this.props.trackingJobs}
              fieldRecognitionJobs={this.props.fieldRecognitionJobs}
              dataIntegrationJobs={this.props.dataIntegrationJobs}
              trackingFifaFormatJobs={this.props.trackingFifaFormatJobs}
              automatedTrackingDataJobs={this.props.automatedTrackingDataJobs}
              eventsDetectionJobs={this.props.eventsDetectionJobs}
              surfaceJobs={this.props.surfaceJobs}
              ballTrackingJobs={this.props.ballTrackingJobs}
              deleteVp={this.deleteVp}
              updateTableItem={this.updateTableItem}
              editJobItem={this.editJobItem}
              failedJobItem={this.failedJobItem}
              openDataIntegration={this.openDataIntegration}
              ref={this.adminTableRef}
              hasDataIntegrationLicence={this.hasLicenceConfigurationFor('data_integration')}
              openUpgradeModal={this.openUpgradeModal}
              isMetricaSportsUser={this.props.isMetricaSportsUser}
              canUploadSurface={this.hasLicenceConfigurationFor('custom_surfaces')}
              trackingMinutes={this.props.trackingMinutes}
              hasWritePermissionOnOrganization={this.hasWritePermissionOnOrganization}
            />
          ) : (
            this.state.fetchingTableList || (
              <div
                css={css`
                  font-size: 18px;
                `}
              >
                {this.props.search
                  ? window.I18n.t('admin.NoResults')
                  : window.I18n.t('admin.NoGames')}
              </div>
            )
          )}
          <div css={styles.progressBarWrapper}>
            {this.state.fetchingTableList && (
              <CircularProgress color="primary" size={35} thickness={4} />
            )}
          </div>
        </div>
        <div css={styles.addWrapper}>
          <Fab color="primary" aria-label="Add" css={styles.addButton} onClick={this.openSteps}>
            <Icon>add</Icon>
          </Fab>
        </div>
        {this.state.gameByStepsOpen && (
          <CreateUpdateGameBySteps
            open={this.state.gameByStepsOpen}
            close={this.closeDialog}
            stadiums={this.props.stadiumsOptions}
            competitions={this.props.competitionsOptions}
            teams={this.props.teamsOptions}
            organizations={this.props.organizationsOptions}
            nonCorrectedOrganizations={this.props.nonCorrectedOrganizationsOptions}
            isMetricaSportsUser={this.props.isMetricaSportsUser}
            userOrganizations={this.props.user.organizations}
            createUpdateGame={this.createUpdateGame}
            videoProject={this.state.editingVp}
            onCreateUpdate={this.onCreateUpdate}
            onOptionDelete={this.onOptionDelete}
            startUploadVideo={this.startUploadVideo}
            hasTrackingLicense={this.hasTrackingLicense(this.props.user)}
            hasFRecognitionLicense={this.hasFRecognitionLicense(this.props.user)}
            hasBallTrackingLicence={this.hasLicenceConfigurationFor('ball_tracking')}
            hasEventingLicense={this.hasEventingLicense(this.props.user)}
          />
        )}
        {this.props.addKeyModal && <AddKeyModal user={this.props.user} onApply={this.onApplyKey} />}
        {this.state.upgradeModalKey && (
          <UpgradeModal
            title={window.I18n.t(`upgradeModal.${this.state.upgradeModalKey}.title`)}
            description={window.I18n.t(`upgradeModal.${this.state.upgradeModalKey}.description`)}
            secondDescription={window.I18n.t(
              `upgradeModal.${this.state.upgradeModalKey}.secondDescription`
            )}
            icon={videoProjectLogo}
            onClose={this.closeUpgradeModal}
          />
        )}
        {this.state.dataFilesIsOpen && (
          <DataIntegrationUploadModal
            open={this.state.dataFilesIsOpen}
            close={this.closeDialog}
            vp={this.state.dataFileOpenVp}
            startJobFromDataIntegration={this.startJobFromDataIntegration}
            updateTableItem={this.updateTableItem}
          />
        )}
      </div>
    )
  }
}

Admin.defaultProps = {
  addKeyModal: false
}

Admin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tableList: PropTypes.array.isRequired,
  stadiumsOptions: PropTypes.array.isRequired,
  competitionsOptions: PropTypes.array.isRequired,
  teamsOptions: PropTypes.array.isRequired,
  organizationsOptions: PropTypes.array.isRequired,
  nonCorrectedOrganizationsOptions: PropTypes.array.isRequired,
  preProcessingJobs: PropTypes.object.isRequired,
  trackingJobs: PropTypes.object.isRequired,
  fieldRecognitionJobs: PropTypes.object.isRequired,
  dataIntegrationJobs: PropTypes.object.isRequired,
  addKeyModal: PropTypes.bool,
  trackingFifaFormatJobs: PropTypes.object.isRequired,
  automatedTrackingDataJobs: PropTypes.object.isRequired,
  eventsDetectionJobs: PropTypes.object.isRequired,
  surfaceJobs: PropTypes.object.isRequired,
  ballTrackingJobs: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  notEnoughMinutes: PropTypes.bool.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  trackingMinutes: PropTypes.number.isRequired
}

function mapStateToProps(state) {
  return {
    tableList: state.admin.tableList,
    stadiumsOptions: stadiumsOptions(state),
    competitionsOptions: competitionsOptions(state),
    teamsOptions: teamsOptions(state),
    organizationsOptions: organizationsOptions(state),
    nonCorrectedOrganizationsOptions: nonCorrectedOrganizationsOptions(state),
    preProcessingJobs: state.admin.preProcessingJobs,
    trackingJobs: state.admin.trackingJobs,
    fieldRecognitionJobs: state.admin.fieldRecognitionJobs,
    dataIntegrationJobs: state.admin.dataIntegrationJobs,
    notEnoughMinutes: state.admin.notEnoughMinutes,
    addKeyModal: state.admin.addKeyModal,
    trackingFifaFormatJobs: state.admin.trackingFifaFormatJobs,
    automatedTrackingDataJobs: state.admin.automatedTrackingDataJobs,
    eventsDetectionJobs: state.admin.eventsDetectionJobs,
    surfaceJobs: state.admin.surfaceJobs,
    ballTrackingJobs: state.admin.ballTrackingJobs,
    search: state.admin.search,
    isMetricaSportsUser: state.auth.isMetricaSportsUser,
    user: state.auth.user,
    trackingMinutes: state.auth.trackingMinutes
  }
}

export default connect(mapStateToProps)(React.memo(UserLoading))

function UserLoading(props) {
  return props.user.name ? (
    <Admin {...props} />
  ) : (
    <div css={styles.progressBarWrapper}>
      <CircularProgress color="primary" size={35} thickness={4} />
    </div>
  )
}

UserLoading.propTypes = {
  user: PropTypes.object.isRequired
}
