import React from 'react'
import PropTypes from 'prop-types'
import { Status, JOB_TYPES } from '../../../constants'

const initialState = {
  deletingVp: false,
  disableActions: false,
  hasBallTracking: false,
  hasScenes: false,
  hasBallTrackingJob: false,
  showStartTracking: false,
  isOnHold: false,
  errorTryAgain: false
}

function BallTrackingJob(props) {
  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    const { vp, deletingVp } = props
    const hasBallTracking = vp.files[JOB_TYPES.BALL_TRACKING.file]
    const hasScenes = Boolean(vp.scenes && vp.scenes.length)
    const hasBallTrackingJob = !vp.files[JOB_TYPES.BALL_TRACKING.file] && props.trackingJob
    const showStartTracking = false // Users can not be able to start this job
    const isOnHold =
      !vp.files.video && !vp.files[JOB_TYPES.BALL_TRACKING.file] && !props.trackingJob
    const errorTryAgain =
      hasBallTrackingJob && props.trackingJob && props.trackingJob.status === Status.FAILED

    if (deletingVp) {
      setState({
        ...initialState,
        deletingVp: true
      })
      return
    }

    if (hasBallTracking) {
      // this two states have to be combined for use on SceneMenuItem.js
      setState({
        ...initialState,
        hasBallTracking: true,
        hasScenes
      })
      return
    }

    if (hasBallTrackingJob) {
      // this two states have to be combined for use on SceneMenuItem.js
      setState({
        ...initialState,
        hasBallTrackingJob: true,
        hasScenes
      })
      return
    }

    if (errorTryAgain) {
      setState({
        ...initialState,
        errorTryAgain: true
      })
      return
    }

    if (showStartTracking) {
      setState({
        ...initialState,
        showStartTracking: true
      })
      return
    }

    if (isOnHold) {
      setState({
        ...initialState,
        isOnHold: true
      })
      return
    }

    setState({ ...initialState, disableActions: true })
  }, [props.vp, props.deletingVp, props.trackingJob, props.isVpCreatedInCloud])

  const renderFn = props.render || props.children // eslint-disable-line
  return renderFn(state)
}

BallTrackingJob.propTypes = {
  vp: PropTypes.object.isRequired,
  trackingJob: PropTypes.object, // Don't use {} as a default prop for this
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default BallTrackingJob
