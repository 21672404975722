import React, { PureComponent } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import gameDatasetsService from '../../services/gameDatasets'
import getErrorMessage from '../../utils/getErrorMessage'

const FILE_CHUNK_SIZE = 10000000 // 10MB
const QUEUE_CONCURRENCY = 10
const MAX_AMOUNT_OF_TRIES = 10

const initialState = {
  selectedFile: undefined,
  fileName: undefined,
  numberOfChunks: undefined,
  inputValue: '',
  s3Path: '',
  s3Bucket: '',
  queue: [],
  inProgress: [],
  uploadedParts: [],
  startAtd: false
}

class MultipartHiddenUploadForm extends PureComponent {
  state = { ...initialState }

  queueUpdated() {
    this.onProgress()
    // When the queue length changes we'll check if we need to process a new item.
    // We'll process a new item if there are less items in progress
    // than the QUEUE_CONCURRENCY number.
    if (this.state.queue.length && this.state.inProgress.length < QUEUE_CONCURRENCY) {
      this.processNextInQueue()
    }

    // Finish upload after all the parts are uploaded
    if (this.state.uploadedParts.length === this.state.numberOfChunks) {
      this.completeMultipartUpload()
    }
  }

  openFileSelector = () => {
    this.input.click()
  }

  startUploadVideoExternal = ({ selectedFile, fileName }, startAtd) => {
    try {
      if (this.props.onStart) {
        this.props.onStart(fileName)
      }

      this.setState(
        {
          ...initialState,
          selectedFile,
          fileName,
          startAtd
        },
        () => {
          this.startUpload()
        }
      )
    } catch (err) {
      this.onError(err)
    }
  }

  fileChangedHandler = event => {
    try {
      event.persist()
      const inputValue = event.target.value
      const selectedFile = event.target.files[0]
      const fileName = selectedFile.name
      if (this.props.onStart) {
        this.props.onStart(fileName)
      }

      this.setState({ ...initialState, selectedFile, fileName, inputValue }, async () => {
        if (this.props.beforeUpload) {
          try {
            await this.props.beforeUpload(selectedFile, fileName)
          } catch (err) {
            this.onError(err)
            return
          }
        }

        this.startUpload()
      })
    } catch (err) {
      this.onError(err)
    }
  }

  uploadMultipartFile = async () => {
    const queue = []
    // eslint-disable-next-line
    for (let index = 1; index < this.state.numberOfChunks + 1; index++) {
      queue.push({ index, tries: 0 })
    }

    this.setState({ queue }, this.queueUpdated)
  }

  getResourceName = () => {
    if (this.props.resource === 'surface_') {
      return `${this.props.resource}${this.state.fileName}`
    }

    return this.props.resource
  }

  startUpload = async () => {
    try {
      const resp = await gameDatasetsService.startMultipartUpload(this.getResourceName(), {
        filename: this.state.fileName,
        fileType: this.state.selectedFile.type,
        gameDatasetCode: this.props.gameDatasetCode,
        id: this.props.vpId
      })
      const numberOfChunks = Math.floor(this.state.selectedFile.size / FILE_CHUNK_SIZE) + 1
      const { uploadId, s3Path, s3Bucket } = resp.data

      this.setState({ uploadId, numberOfChunks, s3Path, s3Bucket }, () => {
        this.uploadMultipartFile()
      })
    } catch (err) {
      this.onError(err)
    }
  }

  processNextInQueue = () => {
    const queue = [...this.state.queue]
    const inProgress = [...this.state.inProgress]

    // Remove from queue and start processing as many items as the inProgress
    // array should git base on QUEUE_CONCURRENCY
    // eslint-disable-next-line
    for (let i = 0; i < QUEUE_CONCURRENCY - this.state.inProgress.length; i++) {
      const item = { ...queue[0] }
      if (item && queue.length > 0) {
        if (item.tries < MAX_AMOUNT_OF_TRIES) {
          inProgress.push(item)
          queue.shift()
          this.uploadPart(item)
        } else {
          this.abortUploadWithError()
        }
      }
    }

    this.setState({ inProgress, queue }, () => {
      this.queueUpdated()
    })
  }

  abortUploadWithError = () => {
    this.cancelUpload()
    this.onError()
  }

  cancelUpload = async () => {
    try {
      await gameDatasetsService.cancelMultipartUpload(this.getResourceName(), {
        uploadId: this.state.uploadId,
        s3Path: this.state.s3Path,
        s3Bucket: this.state.s3Bucket
      })

      this.setState({ ...initialState })
      return Promise.resolve()
    } catch (error) {
      this.onError(error)
      return Promise.reject()
    }
  }

  uploadPart = async data => {
    try {
      // Cut the part of the file we want to upload
      const index = data.index
      const start = (index - 1) * FILE_CHUNK_SIZE
      const end = index * FILE_CHUNK_SIZE
      const blob =
        index < this.state.numberOfChunks
          ? this.state.selectedFile.slice(start, end)
          : this.state.selectedFile.slice(start)

      // Call the api in order to get a singed URL we can use to upload the part directly to S3.
      const getUploadUrlResponse = await gameDatasetsService.getPartUploadUrl(
        this.getResourceName(),
        {
          params: {
            partNumber: index,
            uploadId: this.state.uploadId,
            s3Path: this.state.s3Path,
            s3Bucket: this.state.s3Bucket
          }
        }
      )

      const { preSignedUrl } = getUploadUrlResponse.data

      // Once we get the URL, upload the part to that URL.
      const uploadPartToS3Response = await axios.put(preSignedUrl, blob, {
        headers: { 'Content-Type': this.state.selectedFile.type }
      })

      // S3 will respond with an etag containing an ID for the uploaded part.
      // We'll save all the ID's with the part number in order to send this to the API
      // when finishing the upload
      const uploadData = {
        ETag: uploadPartToS3Response.headers.etag,
        PartNumber: index
      }

      const uploadedParts = [...this.state.uploadedParts, uploadData]
      const inProgress = this.state.inProgress.filter(d => d.index !== data.index)

      // Update state removing this item from inProgress array and adding the data to uploaded parts
      this.setState({ uploadedParts, inProgress }, this.queueUpdated)
    } catch (err) {
      // In case the part fails to upload, add the part back to the queue and increment
      // the tries counter
      const queue = [...this.state.queue, { ...data, tries: data.tries + 1 }]
      this.setState({ queue }, this.queueUpdated)
    }
  }

  completeMultipartUpload = async () => {
    try {
      await gameDatasetsService.completeMultipartUpload(this.getResourceName(), {
        parts: this.state.uploadedParts,
        uploadId: this.state.uploadId,
        s3Path: this.state.s3Path,
        s3Bucket: this.state.s3Bucket,
        id: this.props.vpId
      })

      this.props.onFinish(this.state.fileName, this.state.startAtd)
      this.setState({ ...initialState })
    } catch (err) {
      console.error('Error on complete upload')
      this.onError(err)
    }
  }

  onProgress = () => {
    const progress = this.state.uploadedParts.length / this.state.numberOfChunks
    if (this.props.onProgress) {
      this.props.onProgress(progress)
    }

    console.log(`${progress * 100}%`)
  }

  onError = error => {
    if (error) {
      console.error(getErrorMessage(error))
    }

    if (this.props.onError) {
      this.props.onError(this.state.fileName || '')
    }
  }

  render() {
    return (
      <input
        ref={element => {
          this.input = element
        }}
        style={{ display: 'none' }}
        type="file"
        id="file"
        value={this.state.inputValue}
        onChange={this.fileChangedHandler}
        accept={this.props.accept}
      />
    )
  }
}

MultipartHiddenUploadForm.defaultProps = {
  onProgress: () => ({}),
  onError: () => ({}),
  onFinish: () => ({}),
  onStart: () => ({}),
  beforeUpload: undefined,
  accept: '*'
}

MultipartHiddenUploadForm.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
  beforeUpload: PropTypes.func,
  resource: PropTypes.string.isRequired,
  vpId: PropTypes.string.isRequired,
  gameDatasetCode: PropTypes.string.isRequired,
  accept: PropTypes.string
}

export default MultipartHiddenUploadForm
