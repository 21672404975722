export const ProductNames2024 = [
  { value: 'add', label: '+ Add more products', translationKey: 'addMoreProducts' },
  { value: 'starter_pause', translation: 'Starter + Pause ∞' },
  { value: 'starter_code', translation: 'Starter + Code ∞' },
  { value: 'starter_code_pause', translation: 'Full Starter' },
  { value: 'essentials', translation: 'Essentials' },
  { value: 'essentials_code', translation: 'Essentials + Code ∞' },
  { value: 'essentials_mft', translation: 'Essentials + MFT' },
  { value: 'essentials_mft_code', translation: 'Full Essentials' },
  { value: 'prime', translation: 'Prime' },
  { value: 'prime_mft', translation: 'Prime + MFT' },
  { value: 'prime_presets', translation: 'Prime + Presets' },
  { value: 'prime_presets_mft', translation: 'Full Prime' },
  { value: 'pro_2024', translation: 'Pro' },
  { value: 'elite_2024', translation: 'Elite' },
  { value: 'minutes_10_one_time', translationKey: 'minutes10' },
  { value: 'minutes_100_one_time', translationKey: 'minutes100' },
  {
    value: 'minutes_1000_one_time',
    label: '1.000 Cloud Minutes Pack',
    translationKey: 'minutes1000'
  },
  {
    value: 'minutes_10000_one_time',
    label: '10.000 Cloud Minutes Pack',
    translationKey: 'minutes10000'
  }
]

export const ProductNames2023 = [
  { value: 'codepad', label: 'Code Plan', translation: 'Code' },
  { value: 'basic', label: 'Basic Plan', translation: 'Basic' },
  { value: 'basic_codepad', label: 'Basic+Code Plan', translation: 'Basic+Code' },
  { value: 'basic_mft', label: 'Basic+MFT Plan', translation: 'Basic+MFT' },
  { value: 'basic_mft_codepad', label: 'Full Basic Plan', translation: 'Full Basic' },
  { value: 'lite', label: 'Lite Plan', translation: 'Lite' },
  { value: 'pro', label: 'Pro Plan', translation: 'Pro' },
  { value: 'elite', label: 'Elite Plan', translation: 'Elite' }
]

export const ProductNames = [...ProductNames2024, ...ProductNames2023]
