import getHighestPriorityOrganization from './getHighestPriorityOrganization'

function getTrackingMinutes(user) {
  const highestPriorityOrganization = getHighestPriorityOrganization(user)

  return (
    (highestPriorityOrganization &&
      highestPriorityOrganization.organization &&
      highestPriorityOrganization.organization.tracking_minutes) ||
    0
  )
}

export default getTrackingMinutes
