/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import { withStyles } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import getSymbolFromCurrency from 'currency-symbol-map'
import PropTypes from 'prop-types'
import TextSwitch from '../../utils/textSwitch'
import { theme } from '../../constants'
import { ProductNames } from '../../constants/payments'
import formatPrice from '../../utils/formatPrice'
import localizePrice from '../../utils/localizePrice'

const styles = {}
styles.container = css`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #cecece;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -16px #767676;
  padding: 16px;
  padding-right: 32px;
  margin-bottom: 16px;
`
styles.planContainer = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`
styles.title = css`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`
styles.description = css``
styles.featuresContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
styles.featureTitle = css`
  font-weight: bold;
`
styles.featureDescription = css``
styles.subFeaturesIndent = css`
  padding-left: 32px;
  margin-top: 4px;
  margin-bottom: 4px;
`
styles.addonsContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
styles.addonTitle = css`
  font-size: 14px;
  margin-bottom: 8px;
`
styles.addonDescription = css`
  margin-left: 12px;
`
styles.addonPrice = css`
  min-width: 100px;
  text-align: right;
`
styles.addonContainer = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`
styles.addonToggleContainer = css`
  display: flex;
  align-items: center;
`
styles.totalContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`
styles.removeButton = css`
  cursor: pointer;
  color: grey;
  :hover {
    text-decoration: underline;
  }
`
styles.totalDescription = css`
  font-size: 16px;
  font-weight: bold;
`
styles.planQuantityContainer = css`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 16px;
  right: 16px;
`
styles.packQuantityContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
styles.quantity = css`
  margin: 0px 12px;
  user-select: none;
`
styles.quantityButton = css`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${theme.palette.common.charolBlack};
  background-color: ${theme.palette.common.mainGreen};
  cursor: pointer;
  user-select: none;
`
styles.disabled = css`
  pointer-events: none;
  opacity: 0.5;
`

const DialogContent = withStyles(() => ({
  root: {
    flex: 'unset !important',
    padding: '0px !important',
    '& .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 0.5
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 1
    },
    '& .MuiSwitch-thumb': {
      color: theme.palette.common.white
    }
  }
}))(MuiDialogContent)

const AddOns = {
  starter: [
    { id: 'starter_pause', translationKey: 'starter.addon1' },
    { id: 'starter_code', translationKey: 'starter.addon2' }
  ],
  essentials: [
    { id: 'essentials_code', translationKey: 'essentials.addon1' },
    { id: 'essentials_mft', translationKey: 'essentials.addon2' }
  ],
  prime: [
    { id: 'prime_mft', translationKey: 'prime.addon1' },
    { id: 'prime_presets', translationKey: 'prime.addon2' }
  ],
  pro: [],
  elite: []
}

const AddOns2023 = [
  { id: 'codepad', previousId: '', value: 'codepad', translationKey: 'code' },
  { id: 'basic', previousId: '', value: 'unlimited', translationKey: 'basic' },
  {
    id: 'basic_mft_codepad',
    previousId: 'basic_codepad',
    value: 'mft',
    translationKey: 'fullBasic'
  },
  {
    id: 'lite',
    value: 'automatic',
    previousId: 'basic_mft_codepad',
    translationKey: 'lite'
  }
]

function RecurrentProduct(props) {
  const [currencySymbol, setCurrencySymbol] = React.useState(
    getSymbolFromCurrency(props.currencyCode)
  )

  React.useEffect(() => {
    setCurrencySymbol(getSymbolFromCurrency(props.currencyCode))
  }, [props.currencyCode])

  const productInfo = props.product
    ? ProductNames.find(v => v.value === props.product.productCode)
    : null
  const label = window.I18n.t('plans.plan', {
    planName: productInfo
      ? productInfo.translationKey
        ? window.I18n.t(`plans.${productInfo.translationKey}`)
        : productInfo.translation
      : 'Starter'
  })

  const Descriptions = {
    starter_pause: window.I18n.t('products.starter.starterPauseDescription'),
    starter_code: window.I18n.t('products.starter.starterCodeDescription'),
    starter_code_pause: window.I18n.t('products.starter.starterCodePauseDescription'),
    essentials: window.I18n.t('products.essentials.essentialsDescription'),
    essentials_code: window.I18n.t('products.essentials.essentialsCodeDescription'),
    essentials_mft: window.I18n.t('products.essentials.essentialsMftDescription'),
    essentials_mft_code: window.I18n.t('products.essentials.essentialsMftCodeDescription'),
    prime: window.I18n.t('products.prime.primeDescription'),
    prime_mft: window.I18n.t('products.prime.primeMftDescription'),
    prime_presets: window.I18n.t('products.prime.primePresetsDescription'),
    prime_presets_mft: window.I18n.t('products.prime.primePresetsMftDescription'),
    pro_2024: window.I18n.t('products.pro.description'),
    elite_2024: window.I18n.t('products.elite.description'),
    // Deprecated plans.
    lite: window.I18n.t('products.liteDescription')
  }

  const Features = {
    free: [
      window.I18n.t('products.freeFeature1'),
      window.I18n.t('products.freeFeature2'),
      window.I18n.t('products.freeFeature3'),
      window.I18n.t('products.freeFeature4')
    ],
    starter: [
      window.I18n.t('products.starter.feature1'),
      window.I18n.t('products.starter.feature2'),
      window.I18n.t('products.starter.feature3')
    ],
    essentials: [
      window.I18n.t('products.essentials.feature1'),
      window.I18n.t('products.essentials.feature2'),
      window.I18n.t('products.essentials.feature3'),
      window.I18n.t('products.essentials.feature4')
    ],
    prime: [
      window.I18n.t('products.prime.feature1'),
      window.I18n.t('products.prime.feature2'),
      [
        window.I18n.t('products.prime.feature2_1'),
        window.I18n.t('products.prime.feature2_2'),
        window.I18n.t('products.prime.feature2_3')
      ],
      window.I18n.t('products.prime.feature3')
    ],
    pro: [
      window.I18n.t('products.pro.feature1'),
      window.I18n.t('products.pro.feature2'),
      window.I18n.t('products.pro.feature3'),
      window.I18n.t('products.pro.feature4'),
      [
        window.I18n.t('products.pro.feature4_1'),
        window.I18n.t('products.pro.feature4_2'),
        window.I18n.t('products.pro.feature4_3'),
        window.I18n.t('products.pro.feature4_4')
      ],
      window.I18n.t('products.pro.feature5')
    ],
    elite: [
      window.I18n.t('products.elite.feature1'),
      window.I18n.t('products.elite.feature2'),
      window.I18n.t('products.elite.feature3'),
      window.I18n.t('products.elite.feature4'),
      window.I18n.t('products.elite.feature5')
    ]
  }

  let keyAddOns = 'free'
  if (props.product && !props.product.isDeprecated) {
    if (props.product.productCode.includes('starter')) {
      keyAddOns = 'starter'
    } else if (props.product.productCode.includes('essentials')) {
      keyAddOns = 'essentials'
    } else if (props.product.productCode.includes('prime')) {
      keyAddOns = 'prime'
    } else if (props.product.productCode.includes('pro')) {
      keyAddOns = 'pro'
    } else if (props.product.productCode.includes('elite')) {
      keyAddOns = 'elite'
    }
  }

  return (
    <DialogContent>
      <div css={styles.container}>
        {props.product && !props.product.isDeprecated && (
          <div css={styles.planContainer}>
            <span
              css={css`
                ${styles.quantityButton}
                ${props.product.productCode.includes('starter') ? styles.disabled : ''}
              `}
              onClick={() => {
                const products = props.isMonthly ? props.monthlyProducts : props.yearlyProducts
                let previousProductCode = ''
                if (props.product.productCode.includes('elite')) {
                  previousProductCode = 'pro_2024'
                } else if (props.product.productCode.includes('pro')) {
                  previousProductCode = 'prime'
                } else if (props.product.productCode.includes('prime')) {
                  previousProductCode = 'essentials'
                } else if (props.product.productCode.includes('essentials')) {
                  previousProductCode = 'starter_pause'
                }

                props.onUpdate([
                  { ...props.product, quantity: 0 },
                  {
                    ...products.find(p => p.productCode === previousProductCode),
                    quantity: props.product.quantity
                  }
                ])
              }}
            >
              {'<'}
            </span>
            <span css={styles.title} style={{ padding: '0px 12px' }}>
              {label}
            </span>
            <span
              css={css`
                ${styles.quantityButton}
                ${props.product.productCode.includes(props.cycle === '/month' ? 'pro' : 'elite')
                  ? styles.disabled
                  : ''}
              `}
              onClick={() => {
                const products = props.isMonthly ? props.monthlyProducts : props.yearlyProducts
                let nextProductCode = ''
                if (props.product.productCode.includes('starter')) {
                  nextProductCode = 'essentials'
                } else if (props.product.productCode.includes('essentials')) {
                  nextProductCode = 'prime'
                } else if (props.product.productCode.includes('prime')) {
                  nextProductCode = 'pro_2024'
                } else if (props.product.productCode.includes('pro')) {
                  nextProductCode = 'elite_2024'
                }

                props.onUpdate([
                  { ...props.product, quantity: 0 },
                  {
                    ...products.find(p => p.productCode === nextProductCode),
                    quantity: props.product.quantity
                  }
                ])
              }}
            >
              {'>'}
            </span>
          </div>
        )}

        {props.product && props.product.isDeprecated && <span css={styles.title}>{label}</span>}

        {props.product && (
          <span css={styles.description}>
            {Descriptions[props.product.productCode] || window.I18n.t('products.otherDescription')}
          </span>
        )}

        {props.isShopping && (
          <div css={styles.featuresContainer}>
            <span css={styles.featureTitle}>{window.I18n.t('products.featuresIncluded')}</span>
            {Features[keyAddOns].map(v => {
              return (
                <span css={styles.featureDescription}>
                  {Array.isArray(v) ? (
                    <ul css={styles.subFeaturesIndent}>
                      {v.map(vv => (
                        <li>{vv}</li>
                      ))}
                    </ul>
                  ) : (
                    v
                  )}
                </span>
              )
            })}
          </div>
        )}

        {props.product && !props.product.isDeprecated && (
          <div css={styles.addonsContainer}>
            {AddOns[keyAddOns].length > 0 && (
              <span css={styles.addonTitle}>{window.I18n.t('products.addOns')}</span>
            )}
            {AddOns[keyAddOns].map(v => {
              const products = props.isMonthly ? props.monthlyProducts : props.yearlyProducts
              const addonPrice = 0.0

              let isChecked = false
              if (keyAddOns === 'starter') {
                if (v.id === 'starter_pause') {
                  isChecked =
                    props.product.productCode === 'starter_pause' ||
                    props.product.productCode === 'starter_code_pause'
                } else if (v.id === 'starter_code') {
                  isChecked =
                    props.product.productCode === 'starter_code' ||
                    props.product.productCode === 'starter_code_pause'
                }
              } else if (keyAddOns === 'essentials') {
                if (v.id === 'essentials_code') {
                  isChecked =
                    props.product.productCode === 'essentials_code' ||
                    props.product.productCode === 'essentials_mft_code'
                } else if (v.id === 'essentials_mft') {
                  isChecked =
                    props.product.productCode === 'essentials_mft' ||
                    props.product.productCode === 'essentials_mft_code'
                }
              } else if (keyAddOns === 'prime') {
                if (v.id === 'prime_mft') {
                  isChecked =
                    props.product.productCode === 'prime_mft' ||
                    props.product.productCode === 'prime_presets_mft'
                } else if (v.id === 'prime_presets') {
                  isChecked =
                    props.product.productCode === 'prime_presets' ||
                    props.product.productCode === 'prime_presets_mft'
                }
              }

              return (
                <div key={`addon-${v.id}`} css={styles.addonContainer}>
                  <div css={styles.addonToggleContainer}>
                    <TextSwitch
                      name={v.id}
                      checked={isChecked}
                      color="primary"
                      onChange={() => {
                        let newProductId

                        switch (props.product.productCode) {
                          case 'starter_pause':
                            if (v.id === 'starter_pause') {
                              newProductId = 'starter_code'
                            } else if (v.id === 'starter_code') {
                              newProductId = 'starter_code_pause'
                            }
                            break

                          case 'starter_code':
                            if (v.id === 'starter_pause') {
                              newProductId = 'starter_code_pause'
                            } else if (v.id === 'starter_code') {
                              newProductId = 'starter_pause'
                            }
                            break

                          case 'starter_code_pause':
                            if (v.id === 'starter_pause') {
                              newProductId = 'starter_code'
                            } else if (v.id === 'starter_code') {
                              newProductId = 'starter_pause'
                            }
                            break

                          case 'essentials':
                            if (v.id === 'essentials_code') {
                              newProductId = 'essentials_code'
                            } else if (v.id === 'essentials_mft') {
                              newProductId = 'essentials_mft'
                            }
                            break

                          case 'essentials_code':
                            if (v.id === 'essentials_code') {
                              newProductId = 'essentials'
                            } else if (v.id === 'essentials_mft') {
                              newProductId = 'essentials_mft_code'
                            }
                            break

                          case 'essentials_mft':
                            if (v.id === 'essentials_code') {
                              newProductId = 'essentials_mft_code'
                            } else if (v.id === 'essentials_mft') {
                              newProductId = 'essentials'
                            }
                            break

                          case 'essentials_mft_code':
                            if (v.id === 'essentials_code') {
                              newProductId = 'essentials_mft'
                            } else if (v.id === 'essentials_mft') {
                              newProductId = 'essentials_code'
                            }
                            break

                          case 'prime':
                            if (v.id === 'prime_mft') {
                              newProductId = 'prime_mft'
                            } else if (v.id === 'prime_presets') {
                              newProductId = 'prime_presets'
                            }
                            break

                          case 'prime_mft':
                            if (v.id === 'prime_mft') {
                              newProductId = 'prime'
                            } else if (v.id === 'prime_presets') {
                              newProductId = 'prime_presets_mft'
                            }
                            break

                          case 'prime_presets':
                            if (v.id === 'prime_mft') {
                              newProductId = 'prime_presets_mft'
                            } else if (v.id === 'prime_presets') {
                              newProductId = 'prime'
                            }
                            break

                          case 'prime_presets_mft':
                            if (v.id === 'prime_mft') {
                              newProductId = 'prime_presets'
                            } else if (v.id === 'prime_presets') {
                              newProductId = 'prime_mft'
                            }
                            break

                          default:
                        }

                        props.onUpdate([
                          { ...props.product, quantity: 0 },
                          {
                            ...products.find(p => p.productCode === newProductId),
                            quantity: props.product.quantity
                          }
                        ])
                      }}
                    />
                    <span css={styles.addonDescription}>
                      {window.I18n.t(`products.${v.translationKey}`)}
                    </span>
                  </div>
                  {addonPrice > 0 && (
                    <span
                      css={css`
                        ${styles.addonPrice}
                        ${isChecked ? '' : styles.disabled}
                      `}
                    >
                      {props.currencyCode} {formatPrice(addonPrice, props.currencyCode)}{' '}
                      {window.I18n.t(
                        `checkout.${props.cycle === '/month' ? 'perMonth' : 'perYear'}`
                      )}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {props.product && props.product.isDeprecated && (
          <div css={styles.addonsContainer}>
            <span css={styles.addonTitle}>{window.I18n.t('products.addOns')}</span>
            {AddOns2023.map(v => {
              const products = props.isMonthly ? props.monthlyProducts : props.yearlyProducts
              const product = products.find(p => p.productCode === v.id)
              const previousProduct = v.previousId
                ? products.find(p => p.productCode === v.previousId)
                : null

              const productPrice = localizePrice(product, props.currencyCode)
              const addonPrice = previousProduct
                ? productPrice - localizePrice(previousProduct, props.currencyCode)
                : productPrice

              let isChecked
              if (v.value === 'codepad') {
                isChecked =
                  props.product.productCode !== 'basic' && props.product.productCode !== 'basic_mft'
              } else if (v.value === 'unlimited') {
                isChecked = props.product.productCode !== 'codepad'
              } else if (v.value === 'mft') {
                isChecked = ['basic_mft', 'basic_mft_codepad', 'lite'].includes(
                  props.product.productCode
                )
              } else if (v.value === 'automatic') {
                isChecked = props.product.productCode === 'lite'
              }

              // Deprecated plans have everything disabled.
              const isDisabled = true

              return (
                <div key={`addon-${v.id}`} css={styles.addonContainer}>
                  <div css={styles.addonToggleContainer}>
                    <TextSwitch
                      name={v.id}
                      checked={isChecked}
                      color="primary"
                      disabled={isDisabled}
                    />
                    <span css={styles.addonDescription}>
                      {window.I18n.t(`products.${v.translationKey}`)}
                    </span>
                  </div>
                  {addonPrice > 0 && (
                    <span
                      css={css`
                        ${styles.addonPrice}
                        ${isChecked ? '' : styles.disabled}
                      `}
                    >
                      {props.currencyCode} {formatPrice(addonPrice, props.currencyCode)}{' '}
                      {window.I18n.t(
                        `checkout.${props.cycle === '/month' ? 'perMonth' : 'perYear'}`
                      )}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {props.product && (
          <div css={styles.planQuantityContainer}>
            <span
              css={css`
                ${styles.quantityButton}
                ${props.product.isDeprecated || props.product.quantity <= 1 ? styles.disabled : ''}
              `}
              onClick={() => {
                props.onUpdate([
                  { ...props.product, quantity: Math.max(0, props.product.quantity - 1) }
                ])
              }}
            >
              -
            </span>
            <span css={styles.quantity}>{props.product.quantity}</span>
            <span
              css={css`
                ${styles.quantityButton}
                ${props.product.isDeprecated || props.product.quantity >= 99 ? styles.disabled : ''}
              `}
              onClick={() => {
                props.onUpdate([
                  { ...props.product, quantity: Math.min(99, props.product.quantity + 1) }
                ])
              }}
            >
              +
            </span>
          </div>
        )}

        {props.product ? (
          <div css={styles.totalContainer}>
            {props.showRemove ? (
              <a
                css={styles.removeButton}
                onClick={() => props.onUpdate([{ ...props.product, quantity: 0 }])}
              >
                {window.I18n.t('products.remove')}
              </a>
            ) : (
              <span> </span>
            )}

            <span css={styles.totalDescription}>
              {window.I18n.t('products.totalPlan', {
                currency: props.currencyCode,
                cost: formatPrice(
                  localizePrice(props.product, props.currencyCode) * props.product.quantity,
                  props.currencyCode
                ),
                cycle: window.I18n.t(
                  `checkout.${props.cycle === '/month' ? 'perMonth' : 'perYear'}`
                )
              })}
            </span>
          </div>
        ) : (
          <div css={styles.totalContainer}>
            <span> </span>
            <span css={styles.totalDescription}>
              {window.I18n.t('products.totalPlan', {
                currency: props.currencyCode,
                cost: formatPrice(0, props.currencyCode),
                cycle: window.I18n.t(
                  `checkout.${props.cycle === '/month' ? 'perMonth' : 'perYear'}`
                )
              })}
            </span>
          </div>
        )}
      </div>
    </DialogContent>
  )
}

RecurrentProduct.defaultProps = {
  product: undefined,
  monthlyProducts: undefined,
  yearlyProducts: undefined,
  isShopping: true,
  isMonthly: false,
  showRemove: true,
  onUpdate: () => {}
}

RecurrentProduct.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  product: PropTypes.object,
  monthlyProducts: PropTypes.array,
  yearlyProducts: PropTypes.array,
  isShopping: PropTypes.bool,
  isMonthly: PropTypes.bool,
  cycle: PropTypes.string.isRequired,
  showRemove: PropTypes.bool,
  onUpdate: PropTypes.func
}

export default RecurrentProduct
