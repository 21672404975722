import React from 'react'
import PropTypes from 'prop-types'
import { Status, JOB_TYPES, GAMES_DATASET_DATA_TYPE } from '../../../constants'

const initialState = {
  deletingVp: false,
  disableActions: false,
  hasAutomatedTrackingData: false,
  hasAutomatedTrackingDataJob: false,
  showStartAtd: false,
  isOnHold: false,
  errorTryAgain: false
}

function AutomatedTrackingDataJob(props) {
  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    const { vp, deletingVp, dataType } = props

    const job =
      dataType === GAMES_DATASET_DATA_TYPE.CORRECTED
        ? props.trackingFifaFormatJob
        : props.automatedTrackingDataJob

    const hasAtdFile = vp.files[JOB_TYPES.AUTOMATED_TRACKING_DATA.file]
    const hasAtdJob = !hasAtdFile && job
    const errorTryAgain = hasAtdJob && job.status === Status.FAILED
    const showStartAtd =
      vp.files.video && !hasAtdJob && props.isVpCreatedInCloud && vp.data_type === dataType

    if (deletingVp) {
      setState({
        ...initialState,
        deletingVp: true
      })
      return
    }

    if (hasAtdFile) {
      setState({
        ...initialState,
        hasAutomatedTrackingData: true
      })
      return
    }

    if (hasAtdJob) {
      setState({
        ...initialState,
        hasAutomatedTrackingDataJob: true
      })
      return
    }

    if (showStartAtd) {
      setState({
        ...initialState,
        showStartAtd: true
      })
      return
    }

    if (errorTryAgain) {
      setState({
        ...initialState,
        errorTryAgain: true
      })
      return
    }

    setState({ ...initialState })
  }, [
    props.vp,
    props.deletingVp,
    props.automatedTrackingDataJob,
    props.isVpCreatedInCloud,
    props.fieldRecognitionJob,
    props.ballTrackingJob,
    props.trackingJob,
    props.trackingFifaFormatJob
  ])

  const renderFn = props.render || props.children // eslint-disable-line
  return renderFn(state)
}

AutomatedTrackingDataJob.propTypes = {
  vp: PropTypes.object.isRequired,
  automatedTrackingDataJob: PropTypes.object, // Don't use {} as a default prop for this
  trackingJob: PropTypes.object, // Don't use {} as a default prop for this
  fieldRecognitionJob: PropTypes.object, // Don't use {} as a default prop for this
  ballTrackingJob: PropTypes.object, // Don't use {} as a default prop for this
  trackingFifaFormatJob: PropTypes.object, // Don't use {} as a default prop for this
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default AutomatedTrackingDataJob
