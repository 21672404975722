/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import {
  Box,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import Details from './components/Details'
import Billing from './components/Billing'
import Plans from './components/Plans'
import { headerHeight, theme } from '../../constants'
import AddKeyModal from '../admin/components/AddKeyModal'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const styles = {}
styles.background = css`
  display: flex;
  width: 100%;
  min-height: calc(100vh - ${headerHeight}px);
  height: 100%;
  background: ${theme.palette.background.paper};
`

styles.container = css`
  width: 1169px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px;
`
styles.header = css`
  font-size: 24px;
  font-weight: bold;
  margin: 48px 0px;
`
styles.loadingContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    '& .MuiTab-root': {
      minWidth: 'unset'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'none'
    }
  }
})

const CssTabs = withStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.common.charolBlack}80`
  }
})(Tabs)

const CssTab = withStyles(() => ({
  selected: {
    fontWeight: 'bold'
  }
}))(Tab)

const tabNames = ['details', 'billing', 'plans']

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

function Settings(props) {
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const updateTab = location => {
    // Get tab from URL.
    const url = location.pathname
    const urlPaths = url.split('/')
    const componentName = 'settings'
    const tabUrlIndex = urlPaths.findIndex(v => v === componentName)
    if (tabUrlIndex > -1) {
      const tabIndex = tabNames.findIndex(v => v === urlPaths[tabUrlIndex + 1])
      setValue(Math.max(0, tabIndex))
    }
  }

  React.useEffect(() => {
    updateTab(window.location)
  }, [])

  React.useEffect(() => {
    history.listen(updateTab)
  }, [history])

  const handleChange = (event, newValue) => {
    if (newValue === value) {
      return
    }

    history.push(`/settings/${tabNames[newValue]}`, { shallow: true })
  }

  return (
    <div css={styles.background}>
      <div css={styles.container}>
        <span css={styles.header}>{window.I18n.t('settings.title')}</span>
        <Paper className={classes.root}>
          <CssTabs value={value} onChange={handleChange}>
            <CssTab label={window.I18n.t('settings.details')} {...a11yProps(0)} />
            <CssTab label={window.I18n.t('settings.billing')} {...a11yProps(1)} />
            <CssTab label={window.I18n.t('settings.plans')} {...a11yProps(2)} />
          </CssTabs>
          {props.userLoaded ? (
            <React.Fragment>
              <TabPanel value={value} index={0}>
                <Details
                  user={props.user}
                  subscriptions={props.subscriptions}
                  dispatch={props.dispatch}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Billing
                  user={props.user}
                  customerLoaded={props.customerLoaded}
                  customer={props.customer}
                  address={props.address}
                  business={props.business}
                  transactions={props.transactions}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Plans
                  paddle={props.paddle}
                  userIp={props.userIp}
                  user={props.user}
                  subscriptions={props.subscriptions}
                  subscriptionInUse={props.subscriptionInUse}
                  customer={props.customer}
                  address={props.address}
                  monthlyProducts={props.monthlyProducts}
                  yearlyProducts={props.yearlyProducts}
                />
              </TabPanel>
            </React.Fragment>
          ) : (
            <div css={styles.loadingContainer}>
              <CircularProgress color="primary" size={32} thickness={4} />
            </div>
          )}
        </Paper>
      </div>
      {props.addKeyModal && <AddKeyModal user={props.user} />}
    </div>
  )
}

Settings.defaultProps = {
  paddle: undefined,
  subscriptionInUse: undefined,
  customer: undefined,
  address: undefined,
  business: undefined,
  transactions: undefined,
  addKeyModal: false
}

Settings.propTypes = {
  paddle: PropTypes.object,
  userLoaded: PropTypes.bool.isRequired,
  userIp: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  subscriptions: PropTypes.array.isRequired,
  subscriptionInUse: PropTypes.object,
  customerLoaded: PropTypes.bool.isRequired,
  customer: PropTypes.object,
  address: PropTypes.object,
  business: PropTypes.object,
  transactions: PropTypes.array,
  addKeyModal: PropTypes.bool,
  monthlyProducts: PropTypes.array.isRequired,
  yearlyProducts: PropTypes.array.isRequired,
  oneTimeProducts: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    userLoaded: state.auth.userLoaded,
    userIp: state.auth.userIp,
    user: state.auth.user,
    subscriptions: state.auth.subscriptions,
    subscriptionInUse: state.auth.subscriptionInUse,
    customerLoaded: state.auth.customerLoaded,
    customer: state.auth.customer,
    address: state.auth.address,
    transactions: state.auth.transactions,
    addKeyModal: state.admin.addKeyModal,
    monthlyProducts: state.admin.monthlyProducts,
    yearlyProducts: state.admin.yearlyProducts,
    oneTimeProducts: state.admin.oneTimeProducts
  }
}

export default connect(mapStateToProps)(Settings)
