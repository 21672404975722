import React from 'react'
import qs from 'qs'
import { toast } from 'react-toastify'
import gamesService from '../../services/games'
import gameDatasetsService from '../../services/gameDatasets'
import { gamesGetAllQuery } from '../../constants'
import store from '../../store'
import userService from '../../services/user'
import ActionTypes from '../../constants/actionTypes'
import isTrialUser from '../../utils/isTrialUser'
import diffDays from '../../utils/diffDays'
import tokenService from '../../services/token'
import sessionService from '../../services/session'

const callToActionStyle = {
  cursor: 'default',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontSize: 16,
  color: '#fff'
}

export const fetchGames = (dispatch, pageNumber = 0, search, sort = { sort: '-date' }) => {
  const limit = 50
  const offset = limit * pageNumber
  const payload = {
    params: {
      options: {
        attachStatus: true,
        excludeFields: true
      },
      offset,
      limit,
      ...gamesGetAllQuery,
      ...sort
    },
    paramsSerializer: params => qs.stringify(params)
  }
  return gamesService.getList(search, payload)
}

export const getVp = vpId => {
  const payload = {
    params: {
      options: {
        attachStatus: true
      },
      ...gamesGetAllQuery
    },
    paramsSerializer: params => qs.stringify(params)
  }

  return gameDatasetsService.getVp(vpId, payload)
}

export const updateOrganizationMinutes = async () => {
  try {
    const user = store.getState().auth.user
    const response = await userService.getById(user._id)
    store.dispatch({ type: ActionTypes.UPDATE_USER, payload: { user: response.data } })
  } catch (error) {
    console.log('Error trying to update tracking minutes')
    console.log(error)
  }
}

export const showLicenceReminder = (user, subscriptionInUse) => {
  if (isTrialUser(user)) {
    const date = new Date()
    const diff = user.licence_configuration.expiration_date
      ? diffDays(date, new Date(user.licence_configuration.expiration_date))
      : 'Unlimited'
    const msg = `${diff} ${diff === 1 ? 'day' : 'days'} left of trial.`

    let upgradeLink = 'https://metrica-sports.com/pricing/'
    if (subscriptionInUse) {
      const link = subscriptionInUse.linkUpdatePlan || subscriptionInUse.linkCustomerPortal
      if (link) {
        upgradeLink = link
      }
    }

    toast.info(({ closeToast }) => (
      <div onClick={closeToast}>
        {msg} <br />
        <a style={callToActionStyle} target="_blank" rel="noopener noreferrer" href={upgradeLink}>
          Upgrade
        </a>
      </div>
    ))
  }
}

export const updateUser = async (callback, updateCustomer = true) => {
  try {
    store.dispatch({ type: ActionTypes.FETCHING_USER, payload: true })
    const { token } = tokenService.get()
    const response = await sessionService.check(token)
    tokenService.set(response.data.token, response.data.user._id)
    store.dispatch({ type: ActionTypes.UPDATE_USER, payload: response.data })

    // No need to wait for this response.
    if (updateCustomer) {
      store.dispatch({ type: ActionTypes.FETCHING_CUSTOMER, payload: true })
      userService
        .getCustomer()
        .then(responseCustomer => {
          store.dispatch({ type: ActionTypes.FETCH_CUSTOMER, payload: responseCustomer.data })
        })
        .finally(() => {
          store.dispatch({ type: ActionTypes.FETCHING_CUSTOMER, payload: false })
        })
    }

    if (callback) {
      await callback()
    }

    return response.data.user
  } finally {
    store.dispatch({ type: ActionTypes.FETCHING_USER, payload: false })
  }
}

export const closeAddKeyModal = () => {
  store.dispatch({ type: ActionTypes.TOGGLE_ADD_KEY_MODAL, payload: false })
}
