import api from './api'

const userService = {}

// Update User
userService.update = (id, payload) => api.put(`/users/${id}/profile`, payload)

// Delete User
userService.archive = (id, payload) => api.put(`/users/${id}/archive/${payload}/profile`)

// Add user-key
userService.addKey = (id, payload) => api.put(`/users/${id}/add-key`, payload)

// Get User by id
userService.getById = id =>
  api.get(`/users/${id}/profile`, {
    params: {
      populate:
        'licence_configuration.base_template organizations.organization organizations.permission'
    }
  })

// Generate new Password
userService.generateNewPassword = email => api.post('/users/generate-new-password', { email })

// Get merchant customer
userService.getCustomer = () => api.get('/users/customer')

export default userService
