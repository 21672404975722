import normalizePrice from './normalizePrice'

const localizePrice = (price, currencyCode) => {
  let unitPrice = price.basePrice
  if (price.localizedPrices) {
    const localizedPrice = price.localizedPrices.find(
      v => v.unit_price.currency_code === currencyCode
    )
    if (localizedPrice) {
      unitPrice = localizedPrice.unit_price
    }
  }

  return normalizePrice(unitPrice.amount, currencyCode)
}

export default localizePrice
