import api from './api'

const jobs = {}

// Start job
jobs.start = (id, jobType, payload = {}) =>
  api.put('/batch/job/start', {
    id,
    jobType,
    ...payload
  })

// Stop job
jobs.stop = (id, jobType, payload) => api.put('/batch/job/stop', { id, jobType, ...payload })

// Get progress by job
jobs.progress = id => api.get(`/batch/job/progress/${id}`)

export default jobs
