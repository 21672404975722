import React from 'react'
import PropTypes from 'prop-types'
import { Status, JOB_TYPES } from '../../../constants'

const initialState = {
  deletingVp: false,
  disableActions: false,
  hasFieldRecognition: false,
  hasFieldRecognitionJob: false,
  showStartFieldRecognition: false,
  isOnHold: false,
  errorTryAgain: false
}

function FieldRecognitionJob(props) {
  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    const { vp, deletingVp } = props
    const hasFieldRecognition = JOB_TYPES.HOMOGRAPHY.files.some(v => vp.files[v])
    const hasFieldRecognitionJob = !hasFieldRecognition && props.fieldRecognitionJob
    const showStartFieldRecognition = false // User can't not start job
    const isOnHold = !vp.files.video && !hasFieldRecognition && !props.fieldRecognitionJob
    const errorTryAgain =
      hasFieldRecognitionJob && props.fieldRecognitionJob.status === Status.FAILED

    if (deletingVp) {
      setState({
        ...initialState,
        deletingVp: true
      })
      return
    }

    if (hasFieldRecognition) {
      setState({
        ...initialState,
        hasFieldRecognition: true
      })
      return
    }

    if (hasFieldRecognitionJob) {
      setState({
        ...initialState,
        hasFieldRecognitionJob: true
      })
      return
    }

    if (errorTryAgain) {
      setState({
        ...initialState,
        errorTryAgain: true
      })
      return
    }

    if (showStartFieldRecognition) {
      setState({
        ...initialState,
        showStartFieldRecognition: true
      })
      return
    }

    if (isOnHold) {
      setState({
        ...initialState,
        isOnHold: true
      })
      return
    }

    setState({ ...initialState, disableActions: true })
  }, [props.vp, props.deletingVp, props.fieldRecognitionJob, props.isVpCreatedInCloud])

  const renderFn = props.render || props.children // eslint-disable-line
  return renderFn(state)
}

FieldRecognitionJob.propTypes = {
  vp: PropTypes.object.isRequired,
  fieldRecognitionJob: PropTypes.object, // Don't use {} as a default prop for this
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  deletingVp: PropTypes.bool.isRequired
}

export default FieldRecognitionJob
