import { theme } from '../constants/index'
import store from '../store'
import sleep from './sleep'

/* eslint-disable no-undef */
// Click the Admin icon in the sidebar (gear icon), then select Channels > Widget.
// Click the Setup tab, if it is not already selected.
// Under the code box, the src for the script element is this URL
const URL = 'https://static.zdassets.com/ekr/snippet.js?key=8a57661c-1827-4e13-93a1-e80e71cb3410'

// Zendesk WEB WIDGET docs
// https://developer.zendesk.com/embeddables/docs/widget/introduction
const zendeskWidget = () => ({
  setup(options, showDefaultTrigger = false) {
    if (window.zESettings) {
      return
    }

    this.applySettings(options)
    const script = document.createElement('script')
    const head = document.getElementsByTagName('head')[0]
    script.id = 'ze-snippet'
    script.type = 'text/javascript'
    script.src = `${URL}?n=${Math.random().toString(36).slice(-5)}`
    script.addEventListener('load', () => {
      if (!showDefaultTrigger) {
        this.setSuggestions('type:entry Welcome to the Help Center')
        this.hide()
      }

      const authState = store.getState().auth

      // For some reason widget has started to open without any user action.
      // We force to hide it the first time.
      if (authState.userLoaded) {
        let firstTime = true
        zE('webWidget:on', 'open', () => {
          if (firstTime) {
            this.hide()
            firstTime = false
          }
        })
      }

      const user = authState.user
      this.updateUser(user)

      const language = authState.language
      this.updateLanguage(language)
    })

    head.appendChild(script)
  },

  applySettings(options = {}) {
    // https://developer.zendesk.com/embeddables/docs/widget/core#settings
    const settings = {
      color: {
        theme: '#444444',
        button: theme.palette.common.electricBlue,
        header: theme.palette.common.electricBlue,
        articleLinks: theme.palette.common.electricBlue
      },
      position: { horizontal: 'right', vertical: 'top' },
      offset: {
        horizontal: '0px',
        vertical: '65px'
      }
    }

    const zESettings = {
      webWidget: {
        ...settings,
        ...options
      }
    }

    window.zESettings = zESettings
  },

  updateUser(user) {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'prefill', {
      name: { value: user ? user.name : '' },
      email: { value: user ? user.email : '' }
    })
  },

  updateLanguage(locale) {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    if (locale === 'en') {
      locale = 'en-us'
    } else if (locale === 'zh') {
      locale = 'zh-cn'
    }

    zE('webWidget', 'setLocale', locale)
  },

  updateSettings(settings) {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'updateSettings', {
      webWidget: {
        ...settings
      }
    })
  },

  activate(hideOnClose = true) {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE(() => zE.activate({ hideOnClose }))
  },

  setSuggestions(suggestions) {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'helpCenter:setSuggestions', { search: suggestions, url: true })
  },

  hide() {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    // Hide: just hide the zendesk button so if you perform toggle later it wont work.
    // That's why every time we hide the button we need to activate it and the toggle back again
    // This is necessary to use toggle again
    zE('webWidget', 'hide')
    this.activate()
    zE('webWidget', 'toggle')
  },

  show() {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'show')
  },

  toggle() {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'toggle')
    this._clickOnElemnt('ol li a')
  },

  reset() {
    if (!window.zE) {
      return console.warn('Zendesk was not initiated')
    }

    zE('webWidget', 'reset')

    this.setSuggestions('type:entry Welcome to the Help Center')

    const authState = store.getState().auth
    const user = authState.user
    this.updateUser(user)

    const language = authState.language
    this.updateLanguage(language)
  },

  openInForm() {
    this.activate()
    this._clickOnElemnt('button[data-garden-id="buttons.button"]')
  },

  _clickOnElemnt(elementSelector) {
    setTimeout(async () => {
      let count = 0
      let el = this._getIframeElement(elementSelector)
      while (!el && count < 6) {
        await sleep(100)
        // eslint-disable-next-line no-plusplus
        count++
        el = this._getIframeElement(elementSelector)
      }

      if (el) {
        el.click()
      }
    }, 500)
  },

  _getIframeElement(element) {
    const ifrm = document.getElementById('webWidget')
    if (!ifrm) {
      return
    }

    const doc = ifrm.contentDocument ? ifrm.contentDocument : ifrm.contentWindow.document
    if (!doc) {
      return
    }

    return doc.querySelector(element)
  }
})

export default zendeskWidget()
