import * as DateFns from 'date-fns'
import toTimestamp from './toTimestamp'

function isTrialUser(user) {
  const expirationDate = user.licence_configuration && user.licence_configuration.expiration_date
  const isTrialUserLicence =
    user.licence_configuration &&
    user.licence_configuration.base_template &&
    user.licence_configuration.base_template.code === 'TRIAL'

  return !!(
    isTrialUserLicence &&
    (!expirationDate ||
      toTimestamp(Date()) <= toTimestamp(DateFns.endOfDay(new Date(expirationDate))))
  )
}

export default isTrialUser
