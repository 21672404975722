import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ActionTypes from '../../constants/actionTypes'
import { theme } from '../../constants'

const styles = {
  fontBolder: {
    fontWeight: 'bolder',
    color: theme.palette.common.charolBlack
  }
}

function ConfirmAlert(props) {
  const handleClose = confirmation => () => {
    props.dispatch({
      type: ActionTypes.CLOSE_CONFIRM_ALERT_NOTIFICATION,
      payload: { confirmation }
    })
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <div style={styles.fontBolder}>{window.I18n.t(props.options.title)}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ minWidth: '400px', color: '#444444' }}>
            {window.I18n.t(props.options.body)}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose('cancel')} style={styles.fontBolder}>
          {window.I18n.t(props.options.cancelButtonText)}
        </Button>
        <Button
          onClick={handleClose('ok')}
          color="primary"
          variant="contained"
          style={styles.fontBolder}
          autoFocus
        >
          {window.I18n.t(props.options.confirmButtonText)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmAlert.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    open: state.notifications.confirmAlertOpen,
    options: state.notifications.confirmAlertOptions
  }
}

export default connect(mapStateToProps)(ConfirmAlert)
