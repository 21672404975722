export default {
  // Auth
  MAKE_AUTH: 'MAKE_AUTH',
  LOGOUT: 'LOGOUT',
  FETCHING_USER: 'FETCHING_USER',
  UPDATE_USER: 'UPDATE_USER',
  // Admin
  FETCH_TABLE_LIST: 'FETCH_TABLE_LIST',
  GAMES_SEARCH: 'GAMES_SEARCH',
  FETCH_STADIUMS: 'FETCH_STADIUMS',
  FETCH_COMPETITIONS: 'FETCH_COMPETITIONS',
  FETCH_TEAMS: 'FETCH_TEAMS',
  FETCH_ORGANIZATIONS: 'FETCH_ORGANIZATIONS',
  FETCHING_CUSTOMER: 'FETCHING_CUSTOMER',
  FETCH_CUSTOMER: 'FETCH_CUSTOMER',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  CHANGE_JOBS_ITEM: 'CHANGE_JOBS_ITEM',
  REMOVE_JOBS_ITEM: 'REMOVE_TRACKING_JOBS_ITEM',
  DELETE_VP: 'DELETE_VP',
  UPDATE_TABLE_LIST_ITEM: 'UPDATE_TABLE_LIST_ITEM',
  ADD_TABLE_LIST_ITEM: 'ADD_TABLE_LIST_ITEM',
  NOT_ENOUGH_MINUTES: 'NOT_ENOUGH_MINUTES',
  TOGGLE_ADD_KEY_MODAL: 'TOGGLE_ADD_KEY_MODAL',
  // Notifications
  OPEN_CONFIRM_ALERT_NOTIFICATION: 'OPEN_CONFIRM_ALERT_NOTIFICATION',
  CLOSE_CONFIRM_ALERT_NOTIFICATION: 'CLOSE_CONFIRM_ALERT_NOTIFICATION'
}
