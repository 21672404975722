import * as DateFns from 'date-fns'
import toTimestamp from './toTimestamp'

function isAdminUser(user) {
  const expirationDate = user.licence_configuration && user.licence_configuration.expiration_date
  const isAdminUserLicence =
    user.licence_configuration &&
    user.licence_configuration.base_template &&
    user.licence_configuration.base_template.code === 'METRICA TEAM'

  return !!(
    isAdminUserLicence ||
    (expirationDate &&
      toTimestamp(DateFns.endOfDay(new Date(expirationDate))) < toTimestamp(Date()))
  )
}

export default isAdminUser
