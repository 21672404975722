/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Subscription extends Component {
  render() {
    if (!this.props.userLoaded) {
      return null
    }

    return <Redirect to={'/settings/plans'} />
  }
}

Subscription.defaultProps = {}

Subscription.propTypes = {
  userLoaded: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    userLoaded: state.auth.userLoaded
  }
}

export default connect(mapStateToProps)(Subscription)
