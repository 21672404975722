import store from '../../store'
import ActionTypes from '../../constants/actionTypes'

export const confirmAlert = options =>
  new Promise((resolve, reject) => {
    store.subscribe(handleChange)
    store.dispatch({
      type: ActionTypes.OPEN_CONFIRM_ALERT_NOTIFICATION,
      payload: {
        options
      }
    })
    let currentValue
    function handleChange() {
      const previousValue = currentValue
      currentValue = store.getState().notifications.confirmAlertOptions.confirmation
      if (previousValue !== currentValue) {
        if (currentValue === 'cancel') return reject()
        if (currentValue === 'ok') return resolve()
      }
    }
  })
