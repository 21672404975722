export default {
  addKeyModal: {
    action: 'Aplicar',
    errorMessage:
      'Error al aplicar la clave. Si el problema persiste, contacta con el equipo de soporte de Metrica Sports',
    minutesMessage: 'Tu clave ha sido procesada. Ahora tienes %{minutes} minutos disponibles',
    placeholder: 'Escribe una clave...',
    successMessage: 'La clave ha sido procesada con éxito',
    title: 'Agregar una clave'
  },
  admin: {
    NoGames: 'No hay partidos, agrega el primero...',
    NoResults: 'No hay resultados...',
    addKey: 'Agregar clave',
    atd: 'ATD',
    automatedTrackingData: 'Datos de Tracking Automatizado (ATD)',
    automatedTrackingDataJobSuccess:
      'El proceso de Datos de Tracking Automatizado ATD en %{vpName} ha finalizado y ahora está disponible en Play',
    automatedTrackingDataProcessedTooltip: 'ATD procesado',
    awayTeam: 'Equipo Visitante',
    awayTeamScore: 'Marcador Visitante',
    ballDetection: 'Detección de Pelota',
    ballTracking: 'Tracking de Pelota',
    ballTrackingProcessedTooltip: 'Tracking de Pelota está procesado',
    ballTrackingTooltip:
      'Este proceso detectará la pelota y te permitirá visualizarla dentro de la Formación en Vivo (campo 2D) en Play. Las coordenadas del balón también estarán disponibles en el archivo de formato FIFA EPTS.',
    cancelATDProcessSuccessfully:
      'El proceso de Datos de Tracking Automatizado ATD se canceló en %{vpName}',
    cancelDataIntegrationProcessSuccessfully:
      'El proceso de Integración de Datos se canceló en %{vpName}',
    cancelProcessTooltip: 'Cancelar proceso',
    cancelEventsProcessSuccessfully: 'El proceso de Smart Tagging se canceló en %{vpName}',
    cancelSurfacesProcessSuccessfully: 'El proceso de superficie se canceló en %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'El proceso del archivo de formato FIFA se canceló en %{vpName}',
    category: 'Categoría',
    checking: 'Comprobando...',
    cloudMinutes: 'Minutos de Cloud',
    competition: 'Competición',
    connectionFailed: 'La conexión falló',
    connectionFailedText:
      'Parece que hay un problema para recuperar la información de estado. Por favor, comprueba tu conexión a internet y vuelve a cargar la página. Si el problema persiste, por favor, ponte en contacto con el equipo de soporte.',
    correctedTracking: 'Preocesando la corrección del Tracking',
    correctionUsage: '¿Utilizarás este Proyecto de Vídeo para la Tracking Correction?',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM) es un módulo de Play orientado al usuario para corregir los jugadores generados previamente con nuestro ATD, el cual genera datos sin identificadores de forma automática para cualquier partido de fútbol. Usa TCM para corregir los jugadores incorrectos y asignarles una identidad. Ponte en contacto con nosotros para obtener más información.',
    createVideoProject: 'Crear Proyecto de Vídeo',
    creatingGame: 'Creando Proyecto de Vídeo...',
    dataIntegrationFailed:
      'La Integración de Datos falló en %{vpName}. Ve al panel de Integración de Datos, verifica tus archivos y vuelve a intentarlo. Si el error continúa, por favor, contacta con el equipo de soporte de Metrica Sports',
    dataIntegrationJobSuccess:
      'El proceso de Integración de Datos para %{vpName} se ha completado con éxito',
    date: 'Fecha',
    description: 'Descripción',
    editedSuccessfully: '%{vpName} se ha editado correctamente',
    errorContactSupport: 'Error. Ponte en contacto con el equipo de soporte',
    errorProcessTooltip: 'Error en el proceso',
    eventsDetection: 'Smart Tagging',
    eventsDetectionTooltip: {
      header:
        'Aprovecha nuestra tecnología para detectar automáticamente una serie de eventos que se producen en el partido*:',
      improved: 'Mejorado',
      footer: '*Disponible para vídeos de más de 30 minutos'
    },
    eventsDetectionProcessedTooltip: 'Smart Tagging procesado',
    fieldRecognition: 'Detección de Campo',
    fieldTrackingProcessedTooltip: 'Detección de Campo procesada',
    fieldTrackingTooltip:
      'Procesa la Detección de Campo para agregar visualizaciones con valores de velocidad y distancia, y todos los dibujos con relación al campo en Play.',
    fileDeletedSuccessfully: '%{fileName} se ha eliminado correctamente para %{vpName}',
    fileUploadedError: 'Error al cargar %{fileName} para %{vpName}',
    fileUploadedSuccessfully: '%{fileName} se ha cargado correctamente para %{vpName}',
    forkedGameLimit:
      'Este Proyecto de Vídeo pertenece a otra Organización, por lo que no es posible modificarlo',
    gameDeletedSuccessfully: '%{game} ha sido eliminado con éxito',
    gameVideoProject: 'Proyecto de Vídeo de Partido',
    getMoreMinutes: 'Obtener más',
    homeTeam: 'Equipo Local',
    homeTeamScore: 'Marcador Local',
    initializing: 'Inicializando...',
    jobFailed:
      'El proceso %{process} ha fallado para %{vpName}. Por favor, ponte en contacto con el equipo de soporte de Metrica Sports',
    liveCoding: '¿o continuar para hacer Coding en Vivo?',
    liveCodingTooltip:
      'Puedes crear un Proyecto de Vídeo sin seleccionar un archivo de vídeo en caso de querer hacer Coding en Vivo. Una vez que tengas el vídeo, puedes agregarlo para tener Datos de Tracking Automatizado ATD dentro del Proyecto de Vídeo codificado.',
    localTeamOnTheLeft:
      '¿Está el "Equipo Local" situado en el lado izquierdo del campo en el primer tiempo?',
    localTeamOnTheLeftTooltip:
      'Para procesar Smart Tagging, especifica en qué lado del campo se encuentra cada equipo. Comprueba el vídeo que estás subiendo para validarlo.',
    noFootballSideViewTooltip:
      'Este Proyecto de Vídeo tiene un archivo de vídeo cuyo contenido no es fútbol desde un punto de vista lateral o retransmitido por televisión, por lo que sólo se ha procesado la Detección de Jugadores y Equipos.',
    noMatches: 'No hay coincidencias...',
    noSubscriptionOwnerTooltip:
      "Ponte en contacto con '%{email}', el administrador de tu suscripción, para actualizar o ve a nuestra página de Planes para crear el tuyo propio",
    noVideoFile: 'Sin archivo de vídeo',
    notPossibleToDeleteThisEntry: 'No es posible eliminar esta entrada',
    numberOfHalves: '¿Cuántos periodos tiene este partido?',
    numberOfHalvesTooltip:
      'Para procesar Smart Tagging, especifica cuántos periodos del partido están incluidos en el vídeo que estás cargando.',
    oldAtdTooltip: 'Este vídeo ha sido procesado con una versión anterior de ATD',
    onRequest: 'Bajo demanda',
    organizations: 'Organizaciones',
    place: 'Lugar',
    playerTracking: 'Detección de Jugadores y Equipos',
    playerTrackingProcessedTooltip: 'Detección de Jugadores procesada',
    playerTrackingTooltip:
      'Este proceso detectará los porteros y los 10 jugadores de campo de cada equipo. Esto te permitirá visualizar la Formación en Vivo (campo 2D) y agregar visualizaciones de jugadores automáticamente en Play. Las coordenadas de los jugadores también estarán disponibles en el archivo de formato FIFA EPTS.',
    postProcessingData: 'Posprocesamiento de datos...',
    preProcessingAtd: 'Preprocesamiento...',
    processingTooltip: 'Procesando',
    readyToUpload: 'Listo para cargar',
    search: 'Buscar...',
    selectVideoFile: 'Seleccionar archivo de vídeo',
    settingUpAtd: 'Configurando la detección...',
    settings: 'Configuración',
    singleVideoProject: 'Proyecto de Vídeo Simple',
    startAtd: 'Iniciar ATD',
    startFifaMessage:
      'Se ha iniciado el proceso de Integración de FIFA para %{vpName}. Mostraremos una notificación una vez que haya terminado. Por favor, espera.',
    startProcessingWaitingJobsFailed: 'Error al enviar trabajos en espera',
    startProcessingWaitingJobsSuccessfully: 'Trabajos en espera enviados con éxito',
    startSsMessage:
      'Se ha iniciado el proceso de Integración de Second Spectrum para %{vpName}. Mostraremos una notificación una vez que haya terminado. Por favor, espera.',
    startStatsMessage:
      'Se ha iniciado el proceso de Integración de Stats para %{vpName}. Mostraremos una notificación una vez que haya terminado. Por favor, espera.',
    startTracabDflMessage:
      'Se ha iniciado el proceso de Integración de Tracab DFL para %{vpName}. Mostraremos una notificación una vez que haya terminado. Por favor, espera.',
    startTracabMessage:
      'Se ha iniciado el proceso de Integración de Tracab para %{vpName}. Mostraremos una notificación una vez que haya terminado. Por favor, espera.',
    surfacesJobSuccess: 'El proceso de superficie de %{vpName} se ha completado con éxito',
    trackingCorrectionProcessedTooltip: 'Corrección de Tracking procesado',
    trackingFifaFormatJobSuccess:
      'El archivo de formato FIFA para %{vpName} se ha completado con éxito. Ahora puedes descargar el archivo haciendo clic en Descargar formato FIFA en el menú',
    trackingLicence: 'No tienes licencia para realizar Tracking',
    uncaughtErrorRow:
      'Error en el Proyecto de Vídeo. Por favor, actualiza esta página. Si el problema persiste, ponte en contacto con el equipo de soporte o elimínalo y créalo de nuevo.',
    updatingGame: 'Actualizando Proyecto de Vídeo...',
    upgradePlan: 'Actualizar',
    uploadAndTracking: 'Carga y Tracking',
    uploadingVideoFile: 'Cargando archivo de vídeo',
    videoFile: 'Archivo de vídeo',
    videoFootballSideView:
      '¿Este archivo de vídeo contiene fútbol desde un punto de vista lateral o retransmitido por televisión?',
    videoFootballSideViewTooltip:
      'La Detección de Campo está optimizada sólo para este tipo de vídeos.',
    videoMultipartStart:
      '%{videoName} se está cargando en %{vpName}. NO CIERRE ESTA PÁGINA hasta que finalice la carga',
    videoPlaylist: '¿Este archivo de vídeo es una lista de reproducción?',
    videoPlaylistTooltip:
      'Si seleccionas SÍ, se ejecutará la Detección de Clips para reconocer cuándo se produce un corte en el vídeo, lo que la convierte en una herramienta perfecta si está cargando un vídeo que contiene varios clips, por ejemplo. Después de ejecutar este proceso, descarga el Proyecto de Vídeo en Play y aparecerá un archivo de Coding de Detección de Clips con un código que incluye cada corte como un evento.',
    videoProjectSettings: 'Configuración del Proyecto de Vídeo',
    videoUploadError:
      'Error al cargar el vídeo de %{videoName} para %{vpName}. Por favor, inténtalo de nuevo',
    videoUploaded: '%{videoName} se ha cargado en %{vpName}',
    vpCreated: 'Se ha creado %{vpName}',
    waitingAtd: 'Esperando...'
  },
  common: {
    back: 'Atrás',
    cancel: 'Cancelar',
    canceling: 'Cancelando…',
    clearAll: 'Limpiar Todo',
    edit: 'Editar',
    email: 'Correo Electrónico',
    errorMsg:
      'Error al procesar %{name}. Para consultas, por favor, ponte en contacto con el equipo de soporte.',
    errorTryAgain: 'Error. Inténtalo de nuevo',
    genericError:
      'Error, el proceso ha fallado. Para consultas, por favor, ponte en contacto con el equipo de soporte.',
    loading: 'Cargando...',
    login: 'Iniciar Sesión',
    logout: 'Cerrar sesión',
    new: 'Nuevo',
    next: 'Siguiente',
    no: 'No',
    noFileChosen: 'Ningún archivo seleccionado',
    ok: 'Ok',
    required: 'Requerido',
    reset: 'Restablecer la contraseña',
    restart: 'Reanudar',
    selectFile: 'Seleccionar archivo',
    sort: 'Ordenar',
    start: 'Iniciar',
    today: 'Hoy',
    uploading: 'Cargando…',
    yes: 'Sí'
  },
  confirmModal: {
    description: '¿Estás seguro?',
    title: 'Confirmar'
  },
  dataIntegration: {
    deleteFile: 'Borrar archivo',
    featureNotAvailable:
      'Esta función no está disponible en tu plan actual. Debes actualizar a otro plan para tener esta función. Contáctanos para más información',
    notSideView:
      'La Integración de Datos no está disponible en Proyectos de Vídeo que tengan un archivo de vídeo cuyo contenido no sea fútbol desde un punto de vista lateral o retransmitido por televisión.',
    open: 'Abrir Integración de Datos',
    processJobsFirst:
      'Antes de acceder al panel de Integración de Datos tienes que procesar los Datos de Tracking Automatizado (ATD)',
    processed: 'Integración de Datos procesada',
    selectFile: 'Seleccionar Archivo',
    startProcess: 'Iniciar Proceso',
    title: 'Integración de Datos',
    waiting: 'Proceso en espera...',
    waitingProcess:
      'El proceso de Integración de Datos comenzará una vez que se completen los procesos de Detección de Jugador y Campo.'
  },
  deleteModal: {
    checkboxLabel: 'También quiero dejar de recibir correos de Metrica Sports',
    confirmationType: 'Escribe %{validateFormKey} para confirmar:',
    deleteAction: 'Sí, elimínalo',
    description: '¿Estás seguro de que quieres eliminar tu cuenta de forma permanente?',
    information:
      'Perderás todos los Proyectos de Vídeo creados con esta cuenta. No podremos recuperarlos una vez que los elimines.',
    keepAccount: 'Conservar Cuenta',
    suggestion: 'Te sugerimos conservar tu cuenta.',
    title: '¿Eliminar para toda la eternidad?',
    validateFormKey: 'ELIMINAR'
  },
  error: {
    oops: '¡UPS!',
    restart: 'Parece que estás tratando de usar algo que aún estamos desarrollando...'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Los administradores tienen que utilizar una ruta privada para restablecer la contraseña',
    notFound:
      'Usuario no encontrado. Por favor, inténtalo de nuevo con un correo electrónico válido',
    success: '¡Contraseña reestablecida! Por favor, revisa tu correo electrónico'
  },
  login: {
    contactUs: 'Contáctanos',
    enterYourEmail: 'Introduce tu correo electrónico',
    enterYourPassword: 'Introduce tu contraseña',
    forgotPassword: '¿Has olvidado tu contraseña?',
    invalidEmail: 'Dirección de correo electrónico inválida',
    licenceExpired: 'Tu licencia ha caducado.',
    trialExpired: 'Tu periodo de prueba ha expirado.',
    upgradePlan: 'Actualiza tu plan'
  },
  patterns: {
    delete: 'Eliminar archivo de Patrones',
    title: 'Archivo de Patrones Personalizados',
    upload: 'Cargar archivo de Patrones Personalizados',
    uploaded: 'Archivo de Patrones cargado',
    uploading: 'Cargando archivo de Patrones'
  },
  plans: {
    academic: 'Académico',
    addMoreProducts: '+ Añadir más productos',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Mensual',
    basicCodePadYearly: 'Basic+Code Anual',
    basicMftCodePadMonthly: 'Full Basic Mensual',
    basicMftCodePadYearly: 'Full Basic Anual',
    basicMftMonthly: 'Basic+MFT Mensual',
    basicMftXmlMonthly: 'Basic MFT+XML Mensual',
    basicMftXmlYearly: 'Basic MFT+XML Anual',
    basicMftYearly: 'Basic+MFT Anual',
    basicMonthly: 'Basic Mensual',
    basicXmlMonthly: 'Basic XML Mensual',
    basicXmlYearly: 'Basic XML Anual',
    basicYearly: 'Basic Anual',
    code: 'Code',
    elite: 'Elite',
    eliteYearly: 'Elite Anual',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Mensual',
    liteYearly: 'Lite Anual',
    minutes10: 'Pack de 10 Minutos de Cloud',
    minutes100: 'Pack de 100 Minutos de Cloud',
    minutes1000: 'Pack de 1.000 Minutos de Cloud',
    minutes10000: 'Pack de 10.000 Minutos de Cloud',
    plan: 'Plan %{planName}',
    pro: 'Pro',
    proYearly: 'Pro Anual',
    starter: 'Starter',
    starterCodePadMonthly: 'Code Mensual',
    student: 'Estudiante',
    trial: 'Trial',
    starterPauseMonthly: 'Starter + Pause ∞ Mensual',
    starterPauseYearly: 'Starter + Pause ∞ Anual',
    starterCodeMonthly: 'Starter + Code ∞ Mensual',
    starterCodeYearly: 'Starter + Code ∞ Anual',
    starterCodePauseMonthly: 'Full Starter Mensual',
    starterCodePauseYearly: 'Full Starter Anual',
    essentialsMonthly: 'Essentials Mensual',
    essentialsYearly: 'Essentials Anual',
    essentialsCodeMonthly: 'Essentials + Code ∞ Mensual',
    essentialsCodeYearly: 'Essentials + Code ∞ Anual',
    essentialsMftMonthly: 'Essentials + MFT Mensual',
    essentialsMftYearly: 'Essentials + MFT Anual',
    essentialsMftCodeMonthly: 'Full Essentials Mensual',
    essentialsMftCodeYearly: 'Full Essentials Anual',
    primeMonthly: 'Prime Mensual',
    primeYearly: 'Prime Anual',
    primeMftMonthly: 'Prime + MFT Mensual',
    primeMftYearly: 'Prime + MFT Anual',
    primePresetsMonthly: 'Prime + Presets Mensual',
    primePresetsYearly: 'Prime + Presets Anual',
    primePresetsMftMonthly: 'Full Prime Mensual',
    primePresetsMftYearly: 'Full Prime Anual',
    pro2024Monthly: 'Pro Mensual',
    pro2024Yearly: 'Pro Anual',
    elite2024Yearly: 'Elite Anual'
  },
  playlist: {
    download: 'Descargar archivo de Lista de Reproducción',
    notProcessed:
      'El archivo de Lista de Reproducción no se ha procesado en este Proyecto de Vídeo.',
    processed: 'Archivo de Lista de Reproducción procesado',
    title: 'Lista de Reproducción',
    titleDownload: 'Descargar Lista de Reproducción'
  },
  settings: {
    title: 'Configuración',
    details: 'Mis detalles',
    billing: 'Facturación',
    plans: 'Planes',
    billingSettings: 'Configuración de Facturación',
    billingSettingsDescription: '(cambiar de plan, actualizar métodos de pago...)',
    cancelAt: 'Tu plan se cancela el',
    cancelPlan: 'Cancelar Plan',
    confirmNewPassword: 'Confirmar nueva contraseña',
    deleteAccount: 'Eliminar Cuenta',
    emailNotActivated: 'La notificación por correo electrónico ahora está activada',
    emailNotDeactivated: 'La notificación por correo electrónico ahora está desactivada',
    emailNotifications: 'Notificaciones por correo electrónico',
    language: 'Idioma',
    languageChanged: 'El idioma ha sido actualizado',
    newPassword: 'Nueva contraseña',
    organizationLabel: 'Prefijo de organización',
    organizationTooltip:
      'Este prefijo es necesario para importar patrones personalizados a un Proyecto de Vídeo. Contáctanos para más información o consulta la documentación en codeball.metrica-sports.com',
    passwordsDontMatch: 'Las contraseñas no coinciden',
    planCanceled: 'Tu plan ha sido cancelado. Revisa tu correo electrónico para más información',
    quantity: 'Cantidad:',
    pauseAt: 'Tu plan se pause el',
    renewAt: 'Tu plan se renueva el',
    upgradePlan: 'Actualizar Plan',
    userKey: 'Clave de usuario:',
    userKeyUsages: '%{current}/%{max} usos',
    userSettings: 'Configuración de Usuario'
  },
  billing: {
    details: 'Detalles de Facturación',
    history: 'Historial de Facturación',
    address: 'Dirección',
    taxId: 'Identificador Fiscal',
    invoiceNotFound: 'Factura no encontrada',
    noInvoices: 'No hay facturas',
    billed: 'Facturado',
    completed: 'Pagado',
    past_due: 'Vencido'
  },
  successfulPayment: {
    title: 'Confirmación del Pedido',
    thanks:
      '¡Gracias por tu pedido! Lo hemos procesado con éxito y adjuntamos tus claves de licencia a continuación.',
    licenseKey: 'Tu clave de licencia es: %{pre}%{licenseKey}%{post}',
    applyKeyDescription:
      '¿Te gustaría aplicar la clave de licencia en la cuenta en la que estás conectado automáticamente?',
    keyApplied: 'Clave aplicada',
    applyKey: 'Aplicar clave a esta cuenta',
    goToSummary:
      'Si no, por favor, consulta el resumen de abajo para conocer las instrucciones de activación.',
    faq: 'Para ayudarte a empezar con tu pedido, consulta las %{pre}Preguntas Frecuentes%{post} en nuestra web. Aquí tienes un resumen rápido de la información que puede ser más relevante para ti en esta etapa:',
    downloadPlay: '%{pre}Descarga PLAY%{post} a través de nuestra web.',
    helpDesk:
      'Consulta nuestro %{pre}HelpDesk%{post} para obtener información general sobre el uso de PLAY.',
    settingsCloud:
      'Visita nuestro %{pre}Cloud%{post} y navega hasta el menú de configuración para:',
    updateSubscription: 'Cancela o modifica tu suscripción.',
    updatePayment: 'Actualiza tu método de pago.',
    activateLicence:
      'Activa tu licencia o crédito de Cloud introduciendo la clave de licencia proporcionada.',
    technicalSupport:
      'Para soporte técnico, rellena el formulario de contacto disponible en PLAY/Cloud, o envía un correo electrónico a %{pre}support@metrica-sports.com%{post}',
    termsAndConditions:
      'Por favor, ten en cuenta que nuestras suscripciones se renuevan automáticamente hasta que se cancelen explícitamente a través de la configuración en %{pre}Cloud%{post}, de acuerdo con nuestros Términos y Condiciones.',
    happyPlay:
      'Esperamos que todo esté claro, pero por favor no dudes en contactarnos si tienes alguna pregunta o duda. ¡Feliz PLAY!',
    allTheBest: 'Todo lo mejor,',
    metricaSportsTeam: 'El equipo de Metrica Sports'
  },
  eventsDetection: {
    needsFootballSideView:
      'Smart Tagging no disponible en Proyectos de Vídeo que tengan un archivo de vídeo cuyo contenido no sea fútbol desde un punto de vista lateral o retransmitido por televisión',
    needsGameVp: 'Smart Tagging está sólo disponible en Proyectos de Vídeo de Partido',
    needsAtd: 'Inicia el proceso ATD primero para poder ejecutar el proceso de Smart Taggging',
    needsNoPlaylist:
      'Smart Tagging no disponible en Proyectos de Vídeo que tengan un archivo de vídeo que sea una lista de reproducción',
    automaticVp: 'Smart Tagging no disponible en Proyectos de Vídeo con una versión anterior de ATD'
  },
  checkout: {
    plans: 'Planes',
    perMonth: '/mes',
    perYear: '/año',
    monthlyBilled: 'Facturado mensualmente',
    yearlyBilled: 'Facturado anualmente',
    userDevice: '1 usuario/dispositivo',
    usersDevices: '%{number} usuarios/dispositivos',
    plansTotal: 'Total Planes',
    packs: 'Packs',
    noPacks: 'Sin Pack de Minutos Cloud',
    packsTotal: 'Total Packs',
    incTax: '(Inc. impuestos)',
    excTax: '(Exc. impuestos)',
    subtotal: 'Subtotal',
    taxPayable: 'Impuestos (%{percentage}%)',
    total: 'Total',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    costOneTime: '%{currency} %{cost}',
    immediatePayment: 'Pago Immediato',
    nextPayment: 'Siguiente Pago',
    onDate: 'el %{date}',
    proceed: 'Proceder al pago',
    chargeSubscription: 'Cargar a la suscripción',
    updatePaymentMethod: 'Actualizar Método de Pago',
    updateSubscription: 'Actualizar Suscripción',
    unpaid:
      'Tu última factura aún no ha sido pagada. Por favor, actualiza tu información de pago para continuar',
    discountCodes: '*Los códigos de descuento se pueden aplicar al pagar'
  },
  subscription: {
    yourSubscription: 'Tu Suscripción',
    yourPlans: 'Tus Planes',
    isObsolete:
      'Tu suscripción no puede ser modificada. Reemplaza los productos obsoletos por nuevos para habilitar los cambios',
    free: 'Gratis',
    licenseKeys: 'Claves de licencia',
    updatePaymentMethod: 'Actualizar método de pago',
    cancelSubscription: 'Cancelar suscripción',
    undoCancel: 'Deshacer cancelación de suscripción',
    undoPause: 'Deshacer pause de suscripción',
    updatedSuccessfully: 'Suscripción actualizada con éxito',
    pendingRefundRequest:
      'No se pueden realizar más cambios en esta suscripción mientras haya una solicitud de reembolso pendiente',
    errorUpdating:
      'Hubo un error al actualizar tu suscripción. Por favor, envía un correo electrónico a support@metrica-sports.com y estaremos encantados de ayudarte'
  },
  products: {
    code: 'CodePad',
    basic: 'Códigos y Anotaciones Ilimitadas',
    fullBasic: 'Tracking de Campo Manual',
    lite: 'Tracking de Jugadores y Campo Automático',
    featuresIncluded: 'Características incluidas:',
    freeFeatures: 'Características Gratuitas',
    freeFeature1: '✓ Coding post-partido (Code Table con hasta 4 códigos)',
    freeFeature2: '✓ Tracking manual',
    freeFeature3: '✓ Visualizaciones (hasta 50 anotaciones por mes)',
    freeFeature4: '✓ Exportación de vídeo',
    addOns: 'Complementos',
    liteDescription:
      'Coding avanzado y visualizaciones avanzadas. ¡Todo automático, haciéndolo 3 veces más rápido que Full Basic!',
    otherDescription:
      'Características de coding avanzado con visualizaciones básicas y anotaciones limitadas',
    remove: 'Eliminar',
    minutesPack: 'Pack de Minutos de Cloud',
    minutesDescription:
      'Recarga cualquier plan. Consigue packs de minutos para Datos de Tracking Automatizado (ATD)',
    totalPlan: 'Total: %{currency} %{cost} %{cycle}',
    totalPack: 'Total: %{currency} %{cost}',
    starter: {
      addon1: 'Visualizaciones en pausa ilimitadas',
      addon2: 'Características de Coding ilimitadas (CodePad, Coding en Vivo e Importación XML)',
      feature1: '✓ Coding post-partido (Code Table con hasta 4 códigos)',
      feature2: '✓ Visualizaciones en pausa (hasta 50 anotaciones por mes)',
      feature3: '✓ Exportación de vídeos',
      starterPauseDescription:
        'Este plan desbloquea la limitación de visualizaciones en pausa de 50 por mes a ilimitadas. Las limitaciones en las características de coding permanecen en Free Starter.',
      starterCodeDescription:
        'Este plan desbloquea la limitación de características de coding como CodePad, Coding en Vivo e Importación XML. También libera el límite de 4 códigos por Proyecto de Vídeo a códigos ilimitados. Las limitaciones en las características de visualización permanecen en Free Starter.',
      starterCodePauseDescription:
        'Este plan desbloquea la limitación de características de coding como CodePad, Coding en Vivo e Importación XML. También libera el límite de 4 códigos por Proyecto de Vídeo a códigos ilimitados y desbloquea la limitación de visualizaciones en pausa de 50 por mes a ilimitadas.'
    },
    essentials: {
      addon1: 'Características de Coding ilimitadas (CodePad, Coding en Vivo e Importación XML)',
      addon2: 'Tracking de Campo Manual',
      feature1: '✓ Coding post-partido (Code Table con hasta 4 códigos)',
      feature2: '✓ Visualizaciones ilimitadas en pausa',
      feature3: '✓ Visualizaciones dinámicaas',
      feature4: '✓ 3 minutos de ATD',
      essentialsDescription:
        'Este plan habilita las Visualizaciones Dinámicas disponibles para añadir anotaciones en movimiento ilimitadas al reproducir el vídeo y en pausas. Este plan no incluye Tracking de Campo Manual, por lo que las visualizaciones de Velocidad y Distancia no están incluidas. Las características de coding permanecen limitadas.',
      essentialsCodeDescription:
        'Este plan habilita las Visualizaciones Dinámicas disponibles para añadir anotaciones en movimiento ilimitadas al reproducir el vídeo y en pausas. Este plan no incluye Tracking de Campo Manual, por lo que las visualizaciones de Velocidad y Distancia no están incluidas. También desbloquea la limitación de características de coding como CodePad, Coding en Vivo e Importación XML y libera el límite de 4 códigos por Proyecto de Vídeo a códigos ilimitados.',
      essentialsMftDescription:
        'Este plan habilita las Visualizaciones Dinámicas disponibles para añadir anotaciones en movimiento ilimitadas al reproducir el vídeo y en pausas, y también activa el Tracking de Campo Manual para añadir visualizaciones de Velocidad y Distancia. Las características de coding permanecen limitadas.',
      essentialsMftCodeDescription:
        'Este plan habilita las Visualizaciones Dinámicas disponibles para añadir anotaciones en movimiento ilimitadas al reproducir el vídeo y en pausas, y también activa el Tracking de Campo Manual para añadir visualizaciones de Velocidad y Distancia. También desbloquea la limitación de características de coding como CodePad, Coding en Vivo e Importación XML y libera el límite de 4 códigos por Proyecto de Vídeo a códigos ilimitados.'
    },
    prime: {
      addon1: 'Tracking de Campo Manual',
      addon2: 'Visualizaciones Personalizadas (personaliza y guarda tus propias visualizaciones)',
      feature1:
        '✓ Acceso completo a Play2 con todas las características del Plan "Essentials + Code ∞"',
      feature2: '✓ Acceso a Play3 con:',
      feature2_1: 'Pausas secuenciales',
      feature2_2: 'Visualizaciones avanzadas',
      feature2_3: 'Exportación de vídeos personalizada',
      feature3: '✓ 10 minutos de ATD',
      primeDescription:
        'Este plan incluye acceso completo a Play2 con todas las características del Plan "Essentials + Code ∞" y habilita Play3 con visualizaciones avanzadas.',
      primeMftDescription:
        'Este plan incluye acceso completo a Play2 con todas las características del Plan "Full Essentials" y habilita Play3 con visualizaciones avanzadas.',
      primePresetsDescription:
        'Este plan incluye acceso completo a Play2 con todas las características del Plan "Essentials + Code ∞" y habilita Play3 con visualizaciones avanzadas y visualizaciones personalizadas.',
      primePresetsMftDescription:
        'Este plan incluye acceso completo a Play2 con todas las características del Plan "Full Essentials" y habilita Play3 con visualizaciones avanzadas y visualizaciones personalizadas.'
    },
    pro: {
      feature1: '✓ Tracking de Jugadores Automático',
      feature2: '✓ Tracking de Campo Automático',
      feature3:
        '✓ Acceso completo a Play2 con todas las características del Plan "Full Essentials"',
      feature4: '✓ Acceso a Play3 con:',
      feature4_1: 'Pausas secuenciales',
      feature4_2: 'Visualizaciones avanzadas',
      feature4_3: 'Exportación de vídeos personalizada',
      feature4_4:
        'Visualizaciones Personalizadas (personaliza y guarda tus propias visualizaciones)',
      feature5: '✓ 100 minutos de ATD',
      description:
        'Este plan incluye acceso completo a Play2 con todas las características del Plan "Full Essentials" y accesp a Play3 con todas las características del Plan "Full Prime".'
    },
    elite: {
      feature1: '✓ Almacenamiento de Cloud compartido',
      feature2: '✓ Integración de Datos de Tracking',
      feature3: '✓ Tracking Correction Manager',
      feature4: '✓ RESTful API',
      feature5: '✓ 5.000 minutos de ATD',
      description:
        'Automatiza y personaliza todo tu flujo de trabajo de análisis. Este plan incluye acceso completo a Play2 con todas las características del Plan "Full Essentials" y acceso a Play3 con todas las características del Plan "Pro".'
    }
  },
  shoppingCart: {
    title: 'Configura tu Play',
    subtitle: 'Elige tu plan de Play y complementos para elevar tu rendimiento.'
  },
  surfaces: {
    automaticVp: 'Superficies no disponibles en Proyectos de Vídeo con una versión anterior de ATD',
    delete: 'Eliminar archivo de Superficie',
    needsAtd: 'Inicia el proceso ATD primero para poder ejecutar procesos de Superficie',
    needsElite:
      'Las Superficies Personalizadas están disponibles en el Plan Elite. Contacta con nosotros para más información',
    needsFootballSideView:
      'Superficies no disponibles en Proyectos de Vídeo que tengan un archivo de vídeo cuyo contenido no sea fútbol desde un punto de vista lateral o retransmitido por televisión',
    pitchControl:
      'Al ejecutar este proceso, podrás agregar la visualización de Pitch Control en Play.',
    pitchControlProcessed: 'Superficie de Pitch Control procesada',
    pitchControlProcessing: 'Procesando Pitch Control',
    processed: 'Archivo de Superficie cargado',
    processing: 'Procesando archivo de Superficie',
    title: 'Superficies',
    titlePitchControl: 'Pitch Control',
    upload: 'Carga tu propia Superficie',
    uploading: 'Cargando Superficie...'
  },
  trackingFifaFormat: {
    download: 'Descargar archivo de formato FIFA EPTS',
    needsElite:
      'Esta función sólo está disponible después de importar los archivos correspondientes en el módulo de Integración de Datos, Proyectos de Vídeo con Datos de Tracking Automatizados (ATD) o Proyectos de Vídeo en el Plan Elite.',
    processed: 'Archivo de formato FIFA EPTS procesado',
    start:
      'Iniciar proceso para generar archivo de formato FIFA EPTS. Después podrás descargar el archivo.',
    title: 'Formato FIFA EPTS',
    titleDownload: 'Descargar formato FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'Consigue el pack de minutos de Cloud Engine que mejor se adapte a ti:',
    minutePackLinks: {
      minutes_10: 'Consigue 10 minutos por 10€',
      minutes_100: 'Consigue 100 minutos por 90€',
      minutes_1000: 'Consigue 1.000 minutos por 750€',
      minutes_10000: 'Consigue 10.000 minutos por 5.000€'
    },
    noMinutes: {
      description:
        'No tienes suficientes minutos disponibles para crear este Proyecto de Vídeo. ¿Sabes que puedes conseguir packs de minutos sea cual sea tu plan?',
      secondDescription: '',
      title: '¡Obtén más minutos para crear un Proyecto de Vídeo!'
    },
    notEnoughMinutes: {
      description:
        'Sentimos informarte que no tienes suficientes minutos para el proceso ATD en tus Proyectos de Vídeo.',
      secondDescription:
        'Si deseas procesar ATD, tendrás que agregar más minutos a tu cuenta. ¡Los minutos que obtengas no caducan!',
      title: 'No hay suficientes minutos disponibles'
    }
  },
  video: {
    cancelUpload: 'Cancelar carga',
    delete: 'Eliminar vídeo',
    download: 'Descargar vídeo',
    title: 'Archivo de vídeo',
    upload: 'Cargar vídeo',
    uploaded: 'Vídeo cargado',
    uploading: 'Cargando vídeo'
  },
  videoProject: {
    delete: 'Eliminar Proyecto de Vídeo',
    edit: 'Editar Proyecto de Vídeo',
    processWaitingJobs: 'Procesar trabajos en espera',
    title: 'Proyecto de Vídeo'
  }
}
