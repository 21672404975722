/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { theme } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    cursor: default;
    outline: 0;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
    margin-left: 30px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 15px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function deleteSurface(props, name) {
  confirmAlert()
    .then(() => {
      props.deleteSurface(props.vp)(name)
    })
    .catch(() => {})
}

function SurfacesMenuItem(props) {
  const surfaces = props.vp.files.surfaces || []
  const customSurfaces = surfaces.filter(v => v.name !== 'Pitch Control')
  const disabledItem = props.deletingVp

  return customSurfaces.map(v => {
    const surfaceName = v.name
    const hasSurface = !!v.path
    const processingSurface = !hasSurface
    return (
      <div
        css={css`
          ${styles.itemContainer};
          pointer-events: ${disabledItem ? 'none' : 'all'};
        `}
      >
        <div css={styles.icon} />
        <div css={styles.content} className="video-menu-item-content">
          <div
            css={css`
              ${styles.name};
              color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
            `}
          >
            {surfaceName}
          </div>
          <div css={styles.icons}>
            {SurfaceMenuItemsUI(props, surfaceName, hasSurface, processingSurface)}
          </div>
        </div>
      </div>
    )
  })
}

function SurfaceMenuItemsUI(props, surfaceName, hasSurface, processingSurface) {
  return (
    <React.Fragment>
      {/* Delete surface */}
      {hasSurface && (
        <Tooltip title={window.I18n.t('surfaces.delete')} enterDelay={400} placement="top">
          <Icon
            css={css`
              ${styles.iconTag};
              ${styles.iconButton};
              color: ${theme.palette.error.main};
            `}
            onClick={() => deleteSurface(props, surfaceName)}
          >
            delete
          </Icon>
        </Tooltip>
      )}

      {/* Processing surface */}
      {processingSurface && (
        <Tooltip title={window.I18n.t('surfaces.processing')} enterDelay={400} placement="top">
          <CircularProgress
            css={css`
              ${styles.iconTag};
            `}
            size={14}
            thickness={4}
            color="primary"
          />
        </Tooltip>
      )}

      {/* Surface uploaded */}
      {hasSurface && (
        <Tooltip title={window.I18n.t('surfaces.processed')} enterDelay={400} placement="top">
          <Icon
            css={css`
              ${styles.iconTag};
              color: ${theme.palette.common.successGreen};
            `}
          >
            check_circle_outline
          </Icon>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

SurfacesMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  deletingVp: PropTypes.bool.isRequired,
  deleteSurface: PropTypes.func.isRequired // eslint-disable-line
}

export default SurfacesMenuItem
