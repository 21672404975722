import _ from 'lodash'

function getHighestPriorityOrganization(user) {
  const highestPriorityOrganization =
    user.organizations &&
    _.maxBy(
      user.organizations.filter(o => o.permission && o.permission.code === 'WRITE'),
      o => o.priority
    )

  return highestPriorityOrganization
}

export default getHighestPriorityOrganization
