import { createSelector } from 'reselect'

const mapOptions = ({ _id, name }) => ({ value: _id, label: name })
const sortOptions = (a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
}

const stadiums = state => state.admin.stadiums
const competitions = state => state.admin.competitions
const teams = state => state.admin.teams
const organizations = state => state.admin.organizations

export const stadiumsOptions = createSelector([stadiums], stadiumsList =>
  stadiumsList.map(mapOptions).sort(sortOptions)
)

export const competitionsOptions = createSelector([competitions], competitionsList =>
  competitionsList.map(mapOptions).sort(sortOptions)
)

export const teamsOptions = createSelector([teams], teamsList =>
  teamsList.map(mapOptions).sort(sortOptions)
)

export const organizationsOptions = createSelector([organizations], organizationsList =>
  organizationsList.map(mapOptions).sort(sortOptions)
)

export const nonCorrectedOrganizationsOptions = createSelector([organizations], organizationsList =>
  organizationsList
    .filter(v => !v.correction_usage)
    .map(mapOptions)
    .sort(sortOptions)
)
