import { CurrenciesWithoutDecimals } from '../constants'
import normalizePrice from './normalizePrice'

const formatPrice = (v, currencyCode, normalize = false) => {
  if (normalize) {
    v = normalizePrice(v, currencyCode)
  }

  const decimals = CurrenciesWithoutDecimals.includes(currencyCode) ? 0 : 2
  return v.toLocaleString(undefined, { minimumFractionDigits: decimals })
}

export default formatPrice
