export default {
  addKeyModal: {
    action: 'تطبيق',
    errorMessage: 'خطأ في تطبيق المفتاح. إذا استمرت المشكلة ، فاتصل بفريق دعم متريكا',
    minutesMessage: 'تمت معالجة مفتاحك. الآن لديك %{minutes} دقائق متاحة.',
    placeholder: 'اكتب مفتاحا...',
    successMessage: 'تم معالجة المفتاح بنجاح',
    title: 'أضف مفتاحًا'
  },
  admin: {
    NoGames: 'لا توجد ألعاب ، أضف لعبتك الأولى ...',
    NoResults: 'لا نتائج...',
    addKey: 'أضف مفتاح',
    atd: 'ATD',
    automatedTrackingData: 'بيانات التتبع التلقائي (ATD)',
    automatedTrackingDataJobSuccess:
      'انتهت عملية بيانات التتبع التلقائي على %{vpName} وهي متاحة الآن في Metrica Play',
    automatedTrackingDataProcessedTooltip: 'معالجة بيانات التتبع التلقائي',
    awayTeam: 'الفريق الضيف',
    awayTeamScore: 'نقاط الفريق الضيف',
    ballDetection: 'كشف الكرة',
    ballTracking: 'تتبع الكرة',
    ballTrackingProcessedTooltip: 'تم معالجة الكشف عن الكرة',
    ballTrackingTooltip:
      'ستكتشف هذه العملية الكرة وستتيح لك تصورها داخل التشكيل المباشر (الملعب ثنائي الأبعاد) في اللعب. ستكون إحداثيات الكرة متاحة أيضًا في ملف تنسيق EPTS FIFA.',
    cancelATDProcessSuccessfully: 'تم إلغاء عملية بيانات التتبع التلقائي على %{vpName}',
    cancelDataIntegrationProcessSuccessfully: 'تم إلغاء عملية تكامل البيانات في %{vpName}',
    cancelEventsProcessSuccessfully: 'تم إلغاء عملية Smart Tagging في %{vpName}.',
    cancelProcessTooltip: 'إلغاء العملية',
    cancelSurfacesProcessSuccessfully: 'تم إلغاء عملية الاظهار الخارجي في %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully: 'تم إلغاء تتبع عملية تنسيق FIFA في %{vpName}',
    category: 'فئة',
    checking: 'تدقيق...',
    cloudMinutes: 'دقائق السحابة',
    competition: 'منافسة',
    connectionFailed: 'فشل الاتصال',
    connectionFailedText:
      'يبدو أن هناك مشكلة في استرداد معلومات الحالة. يرجى التحقق من اتصالك بالإنترنت وإعادة تحميل الصفحة. إذا استمرت المشكلة يرجى الاتصال بالدعم',
    correctedTracking: 'معالجة تصحيح التتبع',
    correctionUsage: 'هل ستستخدم مشروع الفيديو هذا لتصحيح التتبع؟',
    correctionUsageTooltip:
      'مدير تصحيح التتبع (TCM) عبارة عن وحدة موجهة للمستخدم داخل Play لتصحيح المسارات التي تم إنشاؤها مسبقًا باستخدام ATD الخاص بنا، والذي يقوم بإنشاء البيانات تلقائيًا لأي لعبة كرة قدم بدون معرفات، استخدم TCM لتصحيح المسارات الخاطئة وتعيين هوية لها، للمزيد من المعلومات تواصل معنا.',
    createVideoProject: 'إنشاء مشروع فيديو',
    creatingGame: 'إنشاء مشروع فيديو...',
    dataIntegrationFailed:
      'فشل تكامل البيانات على %{vpName} . انتقل إلى لوحة تكامل البيانات ، وتحقق من ملفاتك وحاول مرة أخرى. إذا استمر الخطأ ، يرجى الاتصال بفريق دعم Metrica Sports',
    dataIntegrationJobSuccess: 'تم الانتهاء من عملية تكامل البيانات لـ %{vpName} بنجاح',
    date: 'تاريخ',
    description: 'وصف',
    editedSuccessfully: 'تم تحرير %{vpName} بنجاح',
    errorContactSupport: 'خطأ. اتصل بالدعم',
    errorProcessTooltip: 'خطأ في العملية',
    eventsDetection: 'وضع العلامات التلقائي',
    eventsDetectionProcessedTooltip: 'Smart Tagging تمت معالجته',
    eventsDetectionTooltip: {
      footer: '*متاح للفيديوهات التي تزيد مدتها عن 30 دقيقة',
      header: 'استفد من تقنيتنا للكشف تلقائيًا عن سلسلة من الأحداث التي تحدث في المباراة*.',
      improved: 'محسّن'
    },
    fieldRecognition: 'كشف المعلب',
    fieldTrackingProcessedTooltip: 'معالجة كشف لملعب',
    fieldTrackingTooltip:
      'عملية اكتشاف الملعب لإضافة مرئيات بقيم السرعة والمسافة وجميع الرسومات اعتمادا على الملعب لتلك اللعبة.',
    fileDeletedSuccessfully: 'تم حذف %{fileName} بنجاح لـ %{vpName}',
    fileUploadedError: 'خطأ في تحميل %{fileName} لـ %{vpName}',
    fileUploadedSuccessfully: 'تم تحميل %{fileName} بنجاح لـ %{vpName}',
    forkedGameLimit: 'ينتمي مشروع الفيديو هذا إلى مؤسسة أخرى، لذا لا يمكن تعديله',
    gameDeletedSuccessfully: 'تم حذف %{game} بنجاح',
    gameVideoProject: 'مشروع لعبة فيديو',
    getMoreMinutes: 'المزيد',
    homeTeam: 'الفريق المضيف',
    homeTeamScore: 'نتيجة الفريق المضيف',
    initializing: 'جار التهيئة...',
    jobFailed: 'فشلت عملية %{process} لـ %{vpName}. يرجى الاتصال بفريق دعم Metrica Sports',
    liveCoding: 'أو الاستمرار في الترميز المباشر؟',
    liveCodingTooltip:
      'يمكنك إنشاء مشروع فيديو بدون تحديد ملف فيديو لأغراض الترميزالمباشر. بمجرد الحصول على الفيديو ، يمكنك إضافته للحصول على بيانات التتبع التلقائي داخل مشروع الفيديو المرمز.',
    localTeamOnTheLeft: "هل يقع 'الفريق المضيف' على الجانب الأيسر من الملعب في الشوط الأول؟",
    localTeamOnTheLeftTooltip:
      'من أجل معالجة وضع العلامات التلقائي ، حدد في أي جانب من الملعب يقع كل فريق. تحقق من الفيديو الذي تقوم بتحميله للتحقق من صحته.',
    noFootballSideViewTooltip:
      'يحتوي مشروع الفيديو هذا على ملف فيديو بزاوية عريضة أو بث تلفزيوني غير كرة القدم / كرة القدم ، لذلك تمت معالجة اكتشاف اللاعب والفريق فقط.',
    noMatches: 'لا تطابقات ...',
    noSubscriptionOwnerTooltip:
      "اتصل بـ '%{email}' ، مسؤول اشتراكك ، للترقية أو الانتقال إلى صفحة الخطط الخاصة بنا لإنشاء اشتراكك الخاص",
    noVideoFile: 'لا يوجد ملف فيديو',
    notPossibleToDeleteThisEntry: 'لا يمكن حذف هذا الإدخال',
    numberOfHalves: 'كم نصف هذه اللعبة؟',
    numberOfHalvesTooltip:
      'لمعالجة وضع العلامات التلقائي، حدد عدد فترات اللعب المضمنة في الفيديو الذي تقوم بتحميله.',
    oldAtdTooltip: 'تمت معالجة هذا الفيديو بإصدار قديم من ATD',
    onRequest: 'تحت الطلب',
    organizations: 'المنظمات',
    place: 'موقع',
    playerTracking: 'اكتشاف اللاعب والفريق',
    playerTrackingProcessedTooltip: 'تمت معالجة اكتشاف اللاعب',
    playerTrackingTooltip:
      'ستكتشف هذه العملية حراس المرمى واللاعبين الميدانيين العشرة لكل فريق. سيسمح لك ذلك بتصور التشكيل المباشر (الملعب ثنائي الأبعاد) وإضافة تصورات اللاعب تلقائيًا في اللعبة. ستتوفر إحداثيات اللاعبين أيضًا في ملف تنسيق EPTS FIFA.',
    postProcessingData: 'بيانات ما بعد المعالجة ...',
    preProcessingAtd: 'المعالجة المسبقة ...',
    processingTooltip: 'جارٍ المعالجة',
    readyToUpload: 'جاهز للتحميل',
    search: 'بحث...',
    selectVideoFile: 'حدد ملف الفيديو',
    settingUpAtd: 'جارٍ إعداد الكشف ...',
    settings: 'الإعدادات',
    singleVideoProject: 'مشروع فيديو واحد',
    startAtd: 'ابدأ ATD',
    startFifaMessage:
      'بدأت عملية تكامل FIFA لـ %{vpName}. سنعرض إشعارًا بمجرد الانتهاء. أرجو الإنتظار.',
    startProcessingWaitingJobsFailed: 'فشل إرسال وظائف الانتظار',
    startProcessingWaitingJobsSuccessfully: 'تم إرسال الوظائف المنتظرة بنجاح',
    startSsMessage:
      'بدأت عملية تكامل الطيف الثانية لـ %{vpName}. سنعرض إشعارًا بمجرد الانتهاء. أرجو الإنتظار.',
    startStatsMessage:
      'بدأت عملية تكامل الإحصائيات لـ %{vpName}. سنعرض إشعارًا بمجرد الانتهاء. أرجو الإنتظار.',
    startTracabDflMessage:
      'بدأت عملية تكامل Tracab DFL لـ %{vpName}. سنعرض إشعارًا بمجرد الانتهاء. أرجو الإنتظار.',
    startTracabMessage:
      'بدأت عملية تكامل Tracab لـ %{vpName}. سنعرض إشعارًا بمجرد الانتهاء. أرجو الإنتظار.',
    surfacesJobSuccess: '%{vpName} تم الانتهاء من معالجة السطح بنجاح',
    trackingCorrectionProcessedTooltip: 'تمت معالجة تصحيح التتبع ',
    trackingFifaFormatJobSuccess:
      'تم الانتهاء من تتبع عملية تنسيق FIFA لـ %{vpName} بنجاح. يمكنك الآن تنزيل الملف بالنقر فوق عنصر قائمة تنزيل تنسيق FIFA',
    trackingLicence: 'ليس لديك ترخيص تتبع',
    uncaughtErrorRow:
      'خطأ في مشروع الفيديو. من فضلك ، قم بتحديث هذه الصفحة. إذا استمرت المشكلة ، فاتصل بالدعم أو احذفها وأنشئها مرة أخرى',
    updatingGame: 'جارٍ تحديث مشروع الفيديو ...',
    upgradePlan: 'ترقية',
    uploadAndTracking: 'تحميل وتتبع',
    uploadingVideoFile: 'تحميل ملف الفيديو',
    videoFile: 'ملف فيديو',
    videoFootballSideView:
      'هل ملف الفيديو هذا عبارة عن زاوية عريضة لكرة القدم / كرة القدم أم بث تلفزيوني؟',
    videoFootballSideViewTooltip: 'تم تحسين اكتشاف المجال لهذا النوع من مقاطع الفيديو فقط.',
    videoMultipartStart:
      'يتم تحميل %{videoName} على %{vpName}. لا تغلق هذه الصفحة حتى ينتهي التحميل',
    videoPlaylist: 'هل ملف الفيديو هذا قائمة تشغيل؟',
    videoPlaylistTooltip:
      'إذا قمت بتحديد نعم ، تنفيذ اكتشاف المقطع ، للتعرف على وقت حدوث قص في الفيديو ، مما يجعله أداة مثالية إذا كنت تقوم بتحميل مقطع فيديو يحتوي على عدة مقاطع ، على سبيل المثال. بعد تشغيل هذه العملية ، قم بتنزيل مشروع الفيديو في Play وسيكون هناك ملف ترميز للكشف عن المقطع مع رمز يتضمن كل قص كحدث.',
    videoProjectSettings: 'إعدادات مشروع الفيديو',
    videoUploadError: 'فشل تحميل الفيديو لـ %{videoName} لـ %{vpName}. حاول مرة اخرى',
    videoUploaded: 'تم تحميل %{videoName} على %{vpName}',
    vpCreated: 'تم إنشاء %{vpName}',
    waitingAtd: 'انتظار...'
  },
  billing: {
    address: 'العنوان',
    billed: 'مفوتر',
    completed: 'مدفوع',
    details: 'تفاصيل الفوترة',
    history: 'تاريخ الفوترة',
    invoiceNotFound: 'فاتورة غير موجودة',
    noInvoices: 'لا توجد فواتير',
    past_due: 'متأخر',
    taxId: 'الرقم الضريبي'
  },
  checkout: {
    chargeSubscription: 'الشحن إلى الاشتراك',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*يمكن تطبيق أكواد الخصم عند الدفع',
    excTax: '(باستثناء الضرائب)',
    immediatePayment: 'الدفع الفوري',
    incTax: '(شامل الضريبة)',
    monthlyBilled: 'يُفوتر شهريًا',
    nextPayment: 'الدفعة التالية',
    noPacks: 'بدون حزمة دقائق السحابة',
    onDate: 'في %{date}',
    packs: 'باقات',
    packsTotal: 'الباقات الكاملة',
    perMonth: '/شهر',
    perYear: '/سنة',
    plans: 'خطط',
    plansTotal: 'إجمالي الخطط',
    proceed: 'المتابعة إلى الدفع',
    subtotal: 'المجموع الفرعي',
    taxPayable: 'الضريبة المستحقة (%{percentage}%)',
    total: 'المجموع',
    unpaid: 'لم يتم دفع فاتورتك الأخيرة بعد. يرجى تحديث معلومات الدفع الخاصة بك للمتابعة.',
    updatePaymentMethod: 'تحديث طريقة الدفع',
    updateSubscription: 'تحديث الاشتراك',
    userDevice: 'مستخدم/جهاز واحد',
    usersDevices: '%{number} مستخدمين/أجهزة',
    yearlyBilled: 'مفوتر سنويًا'
  },
  common: {
    back: 'عودة',
    cancel: 'إلغاء',
    canceling: 'جارٍ الإلغاء ...',
    clearAll: 'امسح الكل',
    edit: 'يحرر',
    email: 'بريد إلكتروني',
    errorMsg: 'خطأ في معالجة %{name}. للاستفسارات يرجى الاتصال بالدعم.',
    errorTryAgain: 'خطأ. حاول مرة أخرى',
    genericError: 'خطأ ، فشلت العملية. للاستفسارات يرجى الاتصال بالدعم.',
    loading: 'تحميل...',
    login: 'تسجيل الدخول',
    logout: 'تسجيل خروج',
    new: 'جديد',
    next: 'التالي',
    no: 'لا',
    noFileChosen: 'لم يتم اختيار ملف',
    ok: 'نعم',
    required: 'مطلوب',
    reset: 'إعادة تعيين كلمة المرور',
    restart: 'إعادة بدء',
    selectFile: 'حدد ملف',
    sort: 'نوع',
    start: 'ابدأ',
    today: 'اليوم',
    uploading: 'جارٍ التحميل ...',
    yes: 'نعم'
  },
  confirmModal: {
    description: 'هل أنت متأكد؟',
    title: 'تأكيد'
  },
  dataIntegration: {
    deleteFile: 'حذف ملف',
    featureNotAvailable:
      'هذه الميزة غير متاحة في خطتك الحالية. تحتاج إلى الترقية إلى خطة أخرى للحصول على هذه الميزة. اتصل بنا للحصول على مزيد من المعلومات',
    notSideView:
      'لا يتوفر تكامل البيانات في مشاريع الفيديو التي تحتوي على ملف فيديو بزاوية عريضة غير كرة القدم / كرة القدم أو ملف فيديو بث تلفزيوني.',
    open: 'تكامل البيانات المفتوحة',
    processJobsFirst:
      'تحتاج إلى بدء معالجة بيانات التتبع التلقائي (ATD) أولاً ، قبل الوصول إلى لوحة تكامل البيانات',
    processed: 'معالجة تكامل البيانات',
    selectFile: 'حدد ملف',
    startProcess: 'ابدأ العملية',
    title: 'تكامل البيانات',
    waiting: 'عملية الانتظار ...',
    waitingProcess: 'ستبدأ عملية التكامل بمجرد الانتهاء من عمليتي اكتشاف اللاعب والملعب.'
  },
  deleteModal: {
    checkboxLabel: 'أريد أيضًا التوقف عن تلقي رسائل البريد الإلكتروني من Metrica Sports',
    confirmationType: 'اكتب %{validateFormKey} للتأكيد:',
    deleteAction: 'نعم ، احذفها',
    description: 'هل أنت متأكد أنك تريد حذف حسابك بشكل دائم؟',
    information:
      'ستفقد جميع مشاريع الفيديو التي تم إنشاؤها باستخدام هذا الحساب. لا يمكننا استعادتها بمجرد حذفها.',
    keepAccount: 'احتفظ بالحساب',
    suggestion: 'نقترح الاحتفاظ بحسابك.',
    title: 'حذف إلى الأبد؟',
    validateFormKey: 'حذف'
  },
  error: {
    oops: 'عفوا!',
    restart: 'يبدو أنك تحاول الدخول في شيء ما زلنا نطوره ...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging غير متاح في مشاريع الفيديو التي تستخدم نسخة أقدم من ATD',
    needsAtd: 'ابدأ عملية ATD أولاً لتتمكن من تشغيل عملية Smart Tagging',
    needsFootballSideView:
      'الوسم الذكي غير متاح في مشاريع الفيديو التي تحتوي على ملف فيديو بزاوية واسعة أو ملف فيديو مُبث عبر التلفزيون لا يتعلق بكرة القدم/السوكر',
    needsGameVp: 'Smart Tagging متاح فقط في مشاريع فيديو المباراة',
    needsNoPlaylist:
      'Smart Tagging غير متوفر في مشاريع الفيديو التي تحتوي على ملف فيديو على شكل قائمة تشغيل'
  },
  forgotPassword: {
    adminNoResetPassword:
      'يجب على المستخدمين الإداريين استخدام نقطة نهاية خاصة لإعادة تعيين كلمة المرور',
    notFound: 'المستخدم ليس موجود. يرجى المحاولة مرة أخرى باستخدام بريد إلكتروني صالح',
    success: 'إعادة تعيين كلمة المرور! من فضلك تفقد بريدك الالكتروني'
  },
  login: {
    contactUs: 'ﺇﺗﺼﻞ ﺑﻨﺎ',
    enterYourEmail: 'أدخل بريدك الإلكتروني',
    enterYourPassword: 'ادخل رقمك السري',
    forgotPassword: 'هل نسيت كلمة السر؟',
    invalidEmail: 'عنوان البريد الإلكتروني غير صالح',
    licenceExpired: 'انتهت صلاحية الترخيص الخاص بك.',
    trialExpired: 'انتهت صلاحية الإصدار التجريبي الخاص بك.',
    upgradePlan: 'قم بترقية خطتك'
  },
  patterns: {
    delete: 'حذف ملف النمط',
    title: 'ملف نمط مخصص',
    upload: 'تحميل ملف النمط المخصص',
    uploaded: 'تم تحميل ملف النمط',
    uploading: 'تحميل ملف النمط'
  },
  plans: {
    academic: 'أكاديمي',
    addMoreProducts: '+ إضافة المزيد من المنتجات',
    admin: 'مشرف',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code شهري',
    basicCodePadYearly: 'Basic+Code سنوي',
    basicMftCodePadMonthly: 'Full Basic شهري',
    basicMftCodePadYearly: 'Full Basic سنوي',
    basicMftMonthly: 'Basic+MFT شهري',
    basicMftXmlMonthly: 'Basic MFT + XML شهري',
    basicMftXmlYearly: 'Basic MFT + XML سنوي',
    basicMftYearly: 'Basic+MFT سنوي',
    basicMonthly: 'Basic شهري',
    basicXmlMonthly: 'Basic XML شهري',
    basicXmlYearly: 'Basic XML سنوي',
    basicYearly: 'Basic سنوي',
    code: 'كود',
    elite: 'Elite',
    elite2024Yearly: 'إيليت سنوي',
    eliteYearly: 'Elite سنويا',
    essentialsCodeMonthly: 'Essentials + Code ∞ شهري',
    essentialsCodeYearly: 'Essentials + Code ∞ سنوي',
    essentialsMftCodeMonthly: 'الأساسيات الكاملة الشهرية',
    essentialsMftCodeYearly: 'الأساسيات الكاملة السنوية',
    essentialsMftMonthly: 'Essentials + MFT شهري',
    essentialsMftYearly: 'Essentials + MFT سنوي',
    essentialsMonthly: 'الأساسيات الشهرية',
    essentialsYearly: 'الأساسيات السنوية',
    influencer: 'المؤثر',
    lite: 'Lite',
    liteMonthly: 'Lite شهري',
    liteYearly: 'Lite سنوي',
    minutes10: 'باقة 10 دقائق كلاود',
    minutes100: 'باقة 100 دقيقة كلاود',
    minutes1000: 'باقة 1.000 دقيقة كلاود',
    minutes10000: 'باقة 10.000 دقيقة كلاود',
    plan: 'خطة %{planName}',
    primeMftMonthly: 'برايم + MFT شهري',
    primeMftYearly: 'برايم + MFT سنوي',
    primeMonthly: 'برايم شهري',
    primePresetsMftMonthly: 'فل برايم شهري',
    primePresetsMftYearly: 'فل برايم سنوي',
    primePresetsMonthly: 'برايم + الإعدادات المسبقة شهريًا',
    primePresetsYearly: 'برايم + بريستس سنوي',
    primeYearly: 'برايم سنوي',
    pro: 'Pro',
    pro2024Monthly: 'برو شهري',
    pro2024Yearly: 'برو سنوي',
    proYearly: 'Pro سنوي',
    starter: 'Starter',
    starterCodeMonthly: 'ستارتر + كود ∞ شهري',
    starterCodePadMonthly: 'Code شهري',
    starterCodePauseMonthly: 'الباقة الكاملة للمبتدئين شهريا',
    starterCodePauseYearly: 'فول ستارتر سنوي',
    starterCodeYearly: 'Starter + Code ∞ سنوي',
    starterPauseMonthly: 'Starter + Pause ∞ شهري',
    starterPauseYearly: 'Starter + Pause ∞ سنوي',
    student: 'طالب',
    trial: 'نسخة تجريبية'
  },
  playlist: {
    download: 'تنزيل ملف قائمة التشغيل',
    notProcessed: 'لم تتم معالجة ملف قائمة التشغيل في مشروع الفيديو هذا.',
    processed: 'تمت معالجة ملف قائمة التشغيل',
    title: 'قائمة التشغيل',
    titleDownload: 'تنزيل قائمة التشغيل'
  },
  products: {
    addOns: 'إضافات',
    basic: 'أكواد وتعليقات غير محدودة',
    code: 'CodePad',
    elite: {
      description:
        'قم بأتمتة وتخصيص كامل سير عمل التحليل لديك. يشمل هذا الخطة الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials" والوصول إلى Play3 مع جميع ميزات خطة "Pro".',
      feature1: '✓ تخزين سحابي مشترك',
      feature2: '✓ دمج بيانات التتبع',
      feature3: '✓ مدير تصحيح التتبع',
      feature4: '✓ RESTful API',
      feature5: '✓ حزمة 5.000 دقيقة ATD'
    },
    essentials: {
      addon1: 'ميزات البرمجة غير المحدودة (CodePad، البرمجة المباشرة واستيراد XML)',
      addon2: 'التتبع اليدوي للميدان',
      essentialsCodeDescription:
        'يتيح هذا الخطة استخدام التوضيحات الديناميكية المتاحة لإضافة تصورات حركية غير محدودة أثناء تشغيل الفيديو وفي الاستراحات. هذه الخطة لا تشمل تتبع الميدان اليدوي، لذا لا تتضمن تصورات السرعة والمسافة. كما أنها تفتح قيود ميزات البرمجة مثل CodePad، البرمجة المباشرة واستيراد XML وتلغي الحد الأقصى لـ 4 أكواد لكل مشروع فيديو إلى أكواد غير محدودة.',
      essentialsDescription:
        'يتيح هذا الخطة استخدام الرسوم التوضيحية الديناميكية لإضافة تصورات حركية غير محدودة أثناء تشغيل الفيديو وفي الاستراحات. هذا الخطة لا يشمل تتبع الملعب اليدوي، لذا لا تتضمن تصورات السرعة والمسافة. ميزات البرمجة تظل محدودة.',
      essentialsMftCodeDescription:
        'يتيح هذا الخطة استخدام الرسوم التوضيحية الديناميكية لإضافة تصورات حركية غير محدودة أثناء تشغيل الفيديو وفي الاستراحات، ويفعل أيضًا تتبع الميدان اليدوي لإضافة تصورات السرعة والمسافات. كما يزيل قيود ميزات البرمجة مثل CodePad، والبرمجة المباشرة واستيراد XML ويُلغي حد الـ 4 أكواد لكل مشروع فيديو إلى أكواد غير محدودة.',
      essentialsMftDescription:
        'يتيح هذا الخطط استخدام الرسوم البيانية الديناميكية لإضافة توضيحات حركية غير محدودة أثناء تشغيل الفيديو وفي الاستراحات، ويفعل أيضًا تتبع الميدان اليدوي لإضافة توضيحات السرعة والمسافات. ميزات البرمجة تظل محدودة.',
      feature1: '✓ الترميز بعد المباراة (Code Table مع حتى 4 أكواد)',
      feature2: '✓ رسومات غير محدودة أثناء الاستراحات',
      feature3: '✓ توضيحات ديناميكية',
      feature4: '✓ حزمة 3 دقائق ATD'
    },
    featuresIncluded: 'الميزات المتضمنة:',
    freeFeature1: '✓ ترميز ما بعد المباراة (Code Table مع ما يصل إلى 4 أكواد)',
    freeFeature2: '✓ التعقب اليدوي',
    freeFeature3: '✓ التوضيحات (حتى 150 تعليقًا)',
    freeFeature4: '✓ تصدير الفيديو',
    freeFeatures: 'ميزات مجانية',
    fullBasic: 'التتبع اليدوي للملعب',
    lite: 'تتبع اللاعبين والملعب الأوتوماتيكي',
    liteDescription:
      'الترميز المتقدم والتصورات المتقدمة. كل شيء تلقائي، مما يجعله أسرع 3 مرات من Full Basic!',
    minutesDescription: 'اشحن أي خطة. احصل على حزم دقائق لبيانات التتبع الآلي.',
    minutesPack: 'باقة دقائق السحابة',
    otherDescription: 'ميزات الترميز المتقدمة مع توضيحات أساسية وتعليقات محدودة',
    prime: {
      addon1: 'التتبع اليدوي للميدان',
      addon2: 'التوضيحات المسبقة الإعداد (تخصيص وحفظ تصوراتك الخاصة)',
      feature1: '✓ الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials"',
      feature2: '✓ الوصول إلى Play3 مع:',
      feature2_1: 'التوقفات التسلسلية',
      feature2_2: 'التوضيحات المتقدمة',
      feature2_3: 'تصدير الفيديو المخصص',
      feature3: '✓ حزمة 10 دقائق ATD',
      primeDescription:
        'يشمل هذا الخطة الوصول الكامل إلى Play2 مع جميع ميزات خطة "Essentials + Code ∞" ويتيح استخدام Play3 مع توضيحات متقدمة.',
      primeMftDescription:
        'هذا الخطة تتضمن الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials" وتمكن Play3 بالرسوم التوضيحية المتقدمة.',
      primePresetsDescription:
        'يشمل هذا الخطة الوصول الكامل إلى Play2 مع جميع ميزات خطة "Essentials + Code ∞" ويتيح استخدام Play3 مع التوضيحات المتقدمة والتصورات المخصصة.',
      primePresetsMftDescription:
        'يشمل هذا الخطة الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials" ويتيح استخدام Play3 مع التوضيحات المتقدمة والتصورات المخصصة.'
    },
    pro: {
      description:
        'يشمل هذا الخطة الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials" والوصول إلى Play3 مع جميع ميزات خطة "Full Prime".',
      feature1: '✓ تتبع اللاعبين التلقائي',
      feature2: '✓ تتبع الملعب الآلي',
      feature3: '✓ الوصول الكامل إلى Play2 مع جميع ميزات خطة "Full Essentials"',
      feature4: '✓ الوصول إلى Play3 مع:',
      feature4_1: 'التوقفات التسلسلية',
      feature4_2: 'التوضيحات المتقدمة',
      feature4_3: 'تصدير الفيديو المخصص',
      feature4_4: 'التوضيحات المسبقة الإعداد (تخصيص وحفظ تصوراتك الخاصة)',
      feature5: '✓ حزمة 100 دقيقة ATD'
    },
    remove: 'حذف',
    starter: {
      addon1: 'توضيحات غير محدودة أثناء الاستراحات',
      addon2: 'ميزات البرمجة غير المحدودة (CodePad، البرمجة المباشرة واستيراد XML)',
      feature1: '✓ ترميز ما بعد المباراة (جدول الترميز مع ما يصل إلى 4 رموز)',
      feature2: '✓ التوضيح أثناء الاستراحات (حتى 50 تعليقًا في الشهر)',
      feature3: '✓ تصدير الفيديو',
      starterCodeDescription:
        'هذا الخطة تلغي قيود ميزات البرمجة مثل CodePad، البرمجة المباشرة واستيراد XML. كما أنها تزيل حد الـ 4 أكواد لكل مشروع فيديو إلى أكواد غير محدودة. القيود المتعلقة بميزات العرض تظل كما هي في Free Starter.',
      starterCodePauseDescription:
        'هذا الخطة تلغي قيود ميزات البرمجة مثل CodePad، البرمجة المباشرة واستيراد XML. كما أنها ترفع الحد من 4 أكواد لكل مشروع فيديو إلى أكواد غير محدودة، وتلغي قيود العروض التوضيحية خلال الاستراحات من 50 شهريًا إلى غير محدودة.',
      starterPauseDescription:
        'هذا الخطة تلغي قيود الرسم التوضيحي خلال الاستراحات من ٥٠ في الشهر إلى غير محدود. القيود على ميزات البرمجة تظل كما هي في Free Starter.'
    },
    totalPack: 'الإجمالي: %{currency} %{cost}',
    totalPlan: 'الإجمالي: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'الفوترة',
    billingSettings: 'إعدادات الفواتير',
    billingSettingsDescription: '(قم بتغيير خطتك ، وتحديث طرق الدفع ...)',
    cancelAt: 'يتم إلغاء خطتك',
    cancelPlan: 'إلغاء الخطة',
    confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
    deleteAccount: 'حذف الحساب',
    details: 'تفاصيلي',
    emailNotActivated: 'تم تنشيط إشعار البريد الإلكتروني الآن',
    emailNotDeactivated: 'تم الآن إلغاء تنشيط إشعار البريد الإلكتروني',
    emailNotifications: 'اشعارات البريد الالكتروني',
    language: 'اللغة',
    languageChanged: 'تم تحديث اللغة',
    newPassword: 'كلمة مرور جديدة',
    organizationLabel: 'بادئة المنظمة',
    organizationTooltip:
      'هذه البادئة مطلوبة لاستيراد أنماط مخصصة إلى مشروع فيديو. اتصل بنا للحصول على مزيد من المعلومات أو تحقق من الوثائق على codeball.metrica-sports.com',
    passwordsDontMatch: 'كلمات المرور غير متطابقة',
    pauseAt: 'سيتم إيقاف خطتك مؤقتًا في',
    planCanceled: 'تم إلغاء خطتك. تحقق من بريدك الإلكتروني لمزيد من المعلومات',
    plans: 'خطط',
    quantity: 'كمية:',
    renewAt: 'يتم تجديد خطتك في',
    title: 'الإعدادات',
    upgradePlan: 'خطة الترقية',
    userKey: 'مفتاح المستخدم:',
    userKeyUsages: '%{current}/%{max} من الاستخدامات',
    userSettings: 'إعدادات المستخدم'
  },
  shoppingCart: {
    subtitle: 'اختر خطتك Play والإضافات لتحسين أدائك.',
    title: 'قم بإعداد Play الخاص بك'
  },
  subscription: {
    cancelSubscription: 'إلغاء الاشتراك',
    errorUpdating:
      'حدث خطأ أثناء تحديث اشتراكك. يرجى إرسال بريد إلكتروني إلى support@metrica-sports.com وسنكون سعداء بمساعدتك.',
    free: 'مجانًا',
    isObsolete: 'لا يمكن تعديل اشتراكك. استبدل المنتجات القديمة بأخرى جديدة لتمكين التغييرات',
    licenseKeys: 'مفاتيح الترخيص',
    pendingRefundRequest:
      'لا يمكن إجراء المزيد من التغييرات على هذا الاشتراك طالما كان هناك طلب استرداد معلق.',
    undoCancel: 'إلغاء إلغاء الاشتراك',
    undoPause: 'إلغاء توقف الاشتراك',
    updatePaymentMethod: 'تحديث طريقة الدفع',
    updatedSuccessfully: 'تم تحديث الاشتراك بنجاح',
    yourPlans: 'خططك',
    yourSubscription: 'اشتراكك'
  },
  successfulPayment: {
    activateLicence: 'فعّل ترخيصك أو رصيد الـCloud بإدخال مفتاح الترخيص المقدم.',
    allTheBest: 'كل التوفيق،',
    applyKey: 'قم بتطبيق المفتاح على هذا الحساب',
    applyKeyDescription:
      'هل ترغب في تطبيق مفتاح الترخيص على الحساب الذي قمت بتسجيل الدخول إليه تلقائيًا؟',
    downloadPlay: '%{pre}قم بتنزيل PLAY%{post} عبر موقعنا الإلكتروني.',
    faq: 'لمساعدتك على البدء بطلبك، تحقق من %{pre}الأسئلة الشائعة%{post} على موقعنا الإلكتروني. إليك ملخصًا سريعًا للمعلومات التي قد تكون الأكثر صلة بك في هذه المرحلة:',
    goToSummary: 'إذا لم يكن كذلك، يرجى الرجوع إلى الملخص أدناه للحصول على تعليمات التفعيل.',
    happyPlay:
      'نأمل أن يكون كل شيء واضحًا، ولكن من فضلك لا تتردد في التواصل معنا إذا كان لديك أي أسئلة أو شكوك. سعيد باللعب!',
    helpDesk: 'تحقق من %{pre}HelpDesk%{post} للحصول على معلومات عامة حول استخدام PLAY.',
    keyApplied: 'المفتاح مُطبّق',
    licenseKey: 'مفتاح الترخيص الخاص بك هو: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'فريق ميتريكا سبورتس',
    settingsCloud: 'زر %{pre}Cloud%{post} لدينا وانتقل إلى قائمة الإعدادات لـ:',
    technicalSupport:
      'للحصول على الدعم الفني، يرجى تعبئة نموذج الاتصال المتاح في PLAY/Cloud، أو إرسال بريد إلكتروني إلى %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'يرجى ملاحظة أن اشتراكاتنا تتجدد تلقائيًا حتى يتم إلغاؤها صراحةً عبر إعدادات %{pre}Cloud%{post}، وفقًا لشروطنا وأحكامنا.',
    thanks: 'شكرًا لطلبك! لقد تمت معالجته بنجاح وقمنا بإرفاق مفاتيح الترخيص الخاصة بك أدناه.',
    title: 'تأكيد الطلب',
    updatePayment: 'قم بتحديث طريقة الدفع الخاصة بك.',
    updateSubscription: 'إلغاء أو تعديل اشتراكك.'
  },
  surfaces: {
    automaticVp: 'الأسطح غير متوفرة في مشاريع الفيديو بإصدار أقدم من ATD',
    delete: 'احذف ملف السطح',
    needsAtd: 'ابدأ عملية ATD أولاً لتشغيل عمليات السطح',
    needsElite: 'الأسطح المخصصة متوفرة في خطة Elite. اتصل بنا للحصول على مزيد من المعلومات',
    needsFootballSideView:
      'الأسطح غير متوفرة في مشاريع الفيديو التي تحتوي على زاوية عريضة غير لكرة القدم/كرة القدم أو ملف فيديو بث تلفزيوني',
    pitchControl: 'من خلال تشغيل هذه العملية ، ستسمح لك بإضافة تصور التحكم في الملعب في اللعبة.',
    pitchControlProcessed: 'تمت معالجة سطح التحكم في الملعب',
    pitchControlProcessing: 'معالجة التحكم في الملعب',
    processed: 'تم تحميل ملف السطح',
    processing: 'معالجة ملف السطح',
    title: 'الأسطح',
    titlePitchControl: 'السيطرة على ارض الملعب',
    upload: 'قم بتحميل جهاز الاسطح الخاص بك',
    uploading: 'جارٍ تحميل السطح ...'
  },
  trackingFifaFormat: {
    download: 'قم بتنزيل ملف تنسيق FIFA EPTS',
    needsElite:
      'هذه الوظيفة متاحة فقط بعد استيراد الملفات المقابلة إلى وحدة تكامل البيانات أو مشاريع الفيديو مع بيانات التتبع التلقائي أو مشاريع الفيديو في خطة النخبة.',
    processed: 'تمت معالجة تنسيق EPTS FIFA',
    start: 'ابدأ العملية لإنشاء ملف بتنسيق FIFA EPTS. ستتمكن بعد ذلك من تنزيل الملف',
    title: 'تنسيق EPTS FIFA',
    titleDownload: 'قم بتنزيل تنسيق FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'احصل على حزمة دقائق Cloud Engine التي تناسبك:',
    minutePackLinks: {
      minutes_10: 'احصل على 10 دقائق مقابل 10 يورو',
      minutes_100: 'احصل على 100 دقيقة مقابل 90 يورو',
      minutes_1000: 'احصل على 1.000 دقيقة مقابل 750 يورو',
      minutes_10000: 'احصل على 10.000 دقيقة مقابل 5.000 يورو'
    },
    noMinutes: {
      description:
        'ليس لديك ما يكفي من الدقائق المتاحة لإنشاء مشروع الفيديو هذا. هل تعلم أنه يمكنك الحصول على حزم دقيقة مهما كانت خطتك؟',
      secondDescription: '',
      title: 'احصل على مزيد من الدقائق لإنشاء مشروع فيديو!'
    },
    notEnoughMinutes: {
      description:
        'نأسف لإعلامك بأنه ليس لديك دقائق كافية لعملية ATD في مشروعات الفيديو الخاصة بك.',
      secondDescription:
        'إذا كنت ترغب في معالجة ATD ، فستحتاج إلى إضافة المزيد من الدقائق إلى حسابك. الدقائق التي ستحصل عليها لا تنتهي!',
      title: 'لا تتوفر دقائق كافية'
    }
  },
  video: {
    cancelUpload: 'إلغاء الرفع',
    delete: 'حذف الفيديو',
    download: 'تحميل الفيديو',
    title: 'ملف فيديو',
    upload: 'رفع فيديو',
    uploaded: 'تم تحميل الفيديو',
    uploading: 'تحميل الفيديو'
  },
  videoProject: {
    delete: 'حذف مشروع الفيديو',
    edit: 'تحرير مشروع الفيديو',
    processWaitingJobs: 'معالجة وظائف الانتظار',
    title: 'مشروع فيديو'
  }
}
