export default {
  addKeyModal: {
    action: 'Aplicar',
    errorMessage:
      'Erro ao aplicar a chave. Se o problema persistir, entre em contato com a equipa de suporte da Metrica Sports',
    minutesMessage: 'Sua chave foi processada. Agora você tem %{minutes} minutos disponíveis.',
    placeholder: 'Escreva a chave...',
    successMessage: 'A chave foi processada com sucesso',
    title: 'Adicionar uma chave'
  },
  admin: {
    NoGames: 'Sem jogos, adicione o seu primeiro...',
    NoResults: 'Sem resultados...',
    addKey: 'Adicionar chave',
    atd: 'ATD',
    automatedTrackingData: 'Dados de Tracking Automático (ATD)',
    automatedTrackingDataJobSuccess:
      'O processo de Tracking Automático de %{vpName} foi concluído e agora está disponível no Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Dados de Tracking automático processados',
    awayTeam: 'Equipa Visitante',
    awayTeamScore: 'Resultado equipa visitante',
    ballDetection: 'Deteção de bola',
    ballTracking: 'Tracking da bola',
    ballTrackingProcessedTooltip: 'Deteção de bola processada',
    ballTrackingTooltip:
      'Este processo irá detetar a bola e permitirá visualizá-la dentro da formação ao vivo (2D Pitch) em jogo. As coordenadas da bola também estarão disponíveis no arquivo de formato FIFA EPTS.',
    cancelATDProcessSuccessfully: 'O processo de Tracking Automático foi cancelado no %{vpName}',
    cancelDataIntegrationProcessSuccessfully:
      'O processo de integração de dados foi cancelado no %{vpName}',
    cancelEventsProcessSuccessfully: 'O processo de Smart Tagging foi cancelado em %{vpName}.',
    cancelProcessTooltip: 'Cancelar processo',
    cancelSurfacesProcessSuccessfully: 'O processo de superfície foi cancelado em %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'O processo de tracking do formato FIFA foi cancelado no %{vpName}',
    category: 'Categoria',
    checking: 'Verificando...',
    cloudMinutes: 'Minutos de Nuvem',
    competition: 'Competição',
    connectionFailed: 'Falha na conexão',
    connectionFailedText:
      'Parece haver um problema para recuperar as informações de status. Verifique a sua conexão à Internet e recarregue a página. Se o problema persistir, entre em contato com o suporte',
    correctedTracking: 'Processando Correção de Tracking',
    correctionUsage: 'Vai usar este projeto para Correção de Tracking?',
    correctionUsageTooltip:
      'O Tracking Correction Manager (TCM) é um módulo destinado ao usuário para corrigir tracks gerados anteriormente com a nossa tecnologia ATD, que gera dados sem IDs, automaticamente para qualquer jogo de futebol. Use o TCM para corrigir tracks incorretos e atribuir-lhes uma identidade. Entre em contato conosco para mais informações.',
    createVideoProject: 'Criar projeto de vídeo',
    creatingGame: 'Criando projeto de vídeo...',
    dataIntegrationFailed:
      'Falha na integração de dados em %{vpName}. Vá para o painel Integração de dados, verifique seus arquivos e tente novamente. Se o erro persistir, entre em contato com a equipe de suporte da Metrica Sports',
    dataIntegrationJobSuccess:
      'O processo de integração de dados para %{vpName} foi concluído com sucesso',
    date: 'Data',
    description: 'Descrição',
    editedSuccessfully: '%{vpName} foi editado com sucesso',
    errorContactSupport: 'Erro. Contactar suporte',
    errorProcessTooltip: 'Erro no processo',
    eventsDetection: 'Tagging automático',
    eventsDetectionProcessedTooltip: 'Smart Tagging processado',
    eventsDetectionTooltip: {
      footer: '*Disponível para vídeos com mais de 30 minutos.',
      header:
        'Aproveite nossa tecnologia para detectar automaticamente uma série de eventos que ocorrem no jogo*.',
      improved: 'Melhorado'
    },
    fieldRecognition: 'Deteção de Campo',
    fieldTrackingProcessedTooltip: 'Deteção de campo processado',
    fieldTrackingTooltip:
      'Processar Deteção de Campo para adicionar visualizações com valores de velocidade e distância e todos os desenhos dependendo do tipo de campo na Play.',
    fileDeletedSuccessfully: '%{fileName} excluído com sucesso para %{vpName}',
    fileUploadedError: 'Erro ao carregar %{fileName} para %{vpName}',
    fileUploadedSuccessfully: '%{fileName} foi carregado com sucesso para %{vpName}',
    forkedGameLimit:
      'Este projeto de vídeo pertence a outra organização, portanto não é possível modificá-lo',
    gameDeletedSuccessfully: '%{game} excluído com sucesso',
    gameVideoProject: 'Projeto de vídeo do jogo',
    getMoreMinutes: 'Obter mais',
    homeTeam: 'Equipa da Casa',
    homeTeamScore: 'Resultado equipa casa',
    initializing: 'Inicializando ...',
    jobFailed:
      'O processo %{process} falhou para %{vpName}. Entre em contato com a equipe de suporte da Metrica Sports',
    liveCoding: 'ou continuar para codificação ao vivo?',
    liveCodingTooltip:
      'Pode criar um projeto de vídeo sem selecionar um arquivo de vídeo para fins de codificação ao vivo. Depois de ter o vídeo, pode adicioná-lo para ter dados de tracking automático no projeto de vídeo codificado.',
    localTeamOnTheLeft:
      'A equipa da casa está localizada do lado esquerdo do campo na primeira parte?',
    localTeamOnTheLeftTooltip:
      'Para processar o tagging automático, especifique em que lado do campo cada equipa está localizada. Confira o vídeo que quer selecionar para validá-lo.',
    noFootballSideViewTooltip:
      'Este projeto de vídeo contém um vídeo de ângulo aberto ou transmissão de TV que não é de futebol, portanto apenas a deteção de jogador e equipa foi processada.',
    noMatches: 'Sem jogos...',
    noSubscriptionOwnerTooltip:
      "Entre em contato com '%{email}', o administrador da sua assinatura para atualizar ou vá à nossa página de Planos para criar o seu próprio",
    noVideoFile: 'Nenhum arquivo de vídeo',
    notPossibleToDeleteThisEntry: 'Não é possível excluir esta entrada',
    numberOfHalves: 'Quantas partes tem este jogo?',
    numberOfHalvesTooltip:
      'Para processar o tagging automático, especifique quantos períodos de jogo estão incluídos no vídeo que quer utilizar.',
    oldAtdTooltip: 'Este vídeo foi processado com uma versão mais antiga da ATD',
    onRequest: 'Sob consulta',
    organizations: 'Organizações',
    place: 'Localização',
    playerTracking: 'Deteção de jogadores e equipas',
    playerTrackingProcessedTooltip: 'Deteção de jogador processada',
    playerTrackingTooltip:
      'Este processo irá detetar os Guarda-Redes e os 10 jogadores de campo de cada equipa. Isso irá permitir visualizar a formação ao vivo (2D Pitch) e adicionar visualizações do jogador automaticamente no jogo. As coordenadas dos jogadores também estarão disponíveis no arquivo de formato FIFA EPTS.',
    postProcessingData: 'Pós-processamento de dados...',
    preProcessingAtd: 'Pré-processando...',
    processingTooltip: 'Processando',
    readyToUpload: 'Pronto para carregar',
    search: 'Procurar...',
    selectVideoFile: 'Selecionar o arquivo de vídeo',
    settingUpAtd: 'Configurando a deteção...',
    settings: 'Definições',
    singleVideoProject: 'Projeto de vídeo único',
    startAtd: 'Iniciar ATD',
    startFifaMessage:
      'O processo de integração do FIFA para %{vpName} foi iniciado. Mostraremos uma notificação assim que terminar. Por favor, espere.',
    startProcessingWaitingJobsFailed: 'Falha ao enviar trabalhos em espera',
    startProcessingWaitingJobsSuccessfully: 'Trabalhos em espera enviados com sucesso',
    startSsMessage:
      'O processo de integração do Second Spectrum %{vpName} foi iniciado. Iremos mostrar uma notificação assim que terminar. Por favor espere.',
    startStatsMessage:
      'O processo de integração de estatísticas para %{vpName} foi iniciado. Iremos mostrar uma notificação assim que terminar. Por favor espere.',
    startTracabDflMessage:
      'O processo de integração do Tracab DFL para %{vpName} foi iniciado. Iremos mostrar uma notificação assim que terminar. Por favor espere.',
    startTracabMessage:
      'O processo de integração do Tracab para %{vpName} foi iniciado. Mostraremos uma notificação assim que terminar. Por favor, espere.',
    surfacesJobSuccess: '%{vpName} Processo de superfície concluído com sucesso',
    trackingCorrectionProcessedTooltip: 'Correcção de tracking processado',
    trackingFifaFormatJobSuccess:
      'O processo de tracking de formato FIFA para %{vpName} foi concluído com sucesso. Pode baixar o arquivo clicando no item de menu Baixar Formato FIFA',
    trackingLicence: 'Você não tem licença de Tracking',
    uncaughtErrorRow:
      'Erro no projeto de vídeo. Por favor, atualize esta página. Se o problema persistir, entre em contato com o suporte ou exclua o projeto e crie-o novamente',
    updatingGame: 'Atualizando projeto de vídeo...',
    upgradePlan: 'Atualizar',
    uploadAndTracking: 'Carregar e Tracking',
    uploadingVideoFile: 'Carregando arquivo de vídeo',
    videoFile: 'Arquivo de vídeo',
    videoFootballSideView: 'Este vídeo é uma transmissão de futebol grande angular ou de TV?',
    videoFootballSideViewTooltip: 'A deteção de campo é otimizada apenas para esse tipo de vídeo.',
    videoMultipartStart:
      '%{videoName} está sendo carregado em %{vpName}. NÃO FECHE ESTA PÁGINA até que o upload seja concluído',
    videoPlaylist: 'Este vídeo é uma lista de reprodução?',
    videoPlaylistTooltip:
      'Se você selecionar SIM, a Deteção de clips será executada para reconhecer quando um corte ocorre no vídeo, o que a torna uma ferramenta perfeita se você estiver carregando um vídeo contendo vários clipes. Depois de executar este processo, baixe o Projeto de vídeo em execução e um arquivo de codificação de Deteção de Clips estará lá com um código incluindo cada corte como um evento.',
    videoProjectSettings: 'Configurações do projeto de vídeo',
    videoUploadError:
      'Falha no upload de vídeo de %{videoName} para %{vpName}. Por favor, tente novamente',
    videoUploaded: '%{videoName} foi carregado em %{vpName}',
    vpCreated: '%{vpName} foi criado',
    waitingAtd: 'A aguardar...'
  },
  billing: {
    address: 'Endereço',
    billed: 'Faturado',
    completed: 'Pago',
    details: 'Detalhes de Faturamento',
    history: 'Histórico de Faturamento',
    invoiceNotFound: 'Fatura não encontrada',
    noInvoices: 'Não há faturas.',
    past_due: 'Vencido',
    taxId: 'CNPJ'
  },
  checkout: {
    chargeSubscription: 'Cobrar na assinatura.',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*Os códigos de desconto podem ser aplicados no momento do pagamento.',
    excTax: '(Exc. impostos)',
    immediatePayment: 'Pagamento Imediato',
    incTax: '(Inc. impostos)',
    monthlyBilled: 'Faturado mensalmente',
    nextPayment: 'Próximo Pagamento',
    noPacks: 'Sem Pacote de Minutos Cloud',
    onDate: 'em %{date}',
    packs: 'Pacotes',
    packsTotal: 'Pacotes Totais',
    perMonth: '/mês',
    perYear: '/ano',
    plans: 'Planos',
    plansTotal: 'Total de Planos',
    proceed: 'Prosseguir para o pagamento',
    subtotal: 'Subtotal',
    taxPayable: 'Imposto a pagar (%{percentage}%)',
    total: 'Total',
    unpaid:
      'Sua última fatura ainda não foi paga. Por favor, atualize suas informações de pagamento para continuar.',
    updatePaymentMethod: 'Atualizar Método de Pagamento',
    updateSubscription: 'Atualizar Assinatura',
    userDevice: '1 usuário/dispositivo',
    usersDevices: '%{number} usuários/dispositivos',
    yearlyBilled: 'Faturado anualmente'
  },
  common: {
    back: 'Voltar',
    cancel: 'Cancelar',
    canceling: 'Cancelando...',
    clearAll: 'Limpar tudo',
    edit: 'Editar',
    email: 'E-mail',
    errorMsg: 'Erro ao processar %{name}. Para dúvidas, entre em contato com o suporte.',
    errorTryAgain: 'Erro. Tente novamente',
    genericError: 'Erro, processo falhou. Para dúvidas, entre em contato com o suporte.',
    loading: 'Carregando...',
    login: 'Login',
    logout: 'Logout',
    new: 'Novo',
    next: 'Próximo',
    no: 'Não',
    noFileChosen: 'Nenhum arquivo selecionado',
    ok: 'OK',
    required: 'Obrigatório',
    reset: 'Redefinir senha',
    restart: 'Reiniciar',
    selectFile: 'Selecionar ficheiro',
    sort: 'Ordenar',
    start: 'Começar',
    today: 'Hoje',
    uploading: 'A carregar ...',
    yes: 'Sim'
  },
  confirmModal: {
    description: 'Tem a certeza?',
    title: 'Confirmar'
  },
  dataIntegration: {
    deleteFile: 'Eliminar ficheiro',
    featureNotAvailable:
      'Este recurso não está disponível no seu plano atual. Precisa atualizar para outro plano para ter esse recurso. Contacte-nos para mais informações',
    notSideView:
      'A Integração de Dados não está disponível em Projetos de Vídeo que contenham um arquivo de vídeo de grande angular que não seja futebol ou de transmissão de TV.',
    open: 'Abrir integração de dados',
    processJobsFirst:
      'Precisa começar a processar a Automated Tracking Data (ATD) primeiro antes de entrar no painel de integração de dados',
    processed: 'Integração de dados processada',
    selectFile: 'Selecionar ficheiro',
    startProcess: 'Iniciar processo',
    title: 'Integração de dados',
    waiting: 'Aguardando processo...',
    waitingProcess:
      'O processo de integração começará assim que os processos de Deteção de Jogador e Campo forem concluídos.'
  },
  deleteModal: {
    checkboxLabel: 'Também quero parar de receber e-mails da Metrica Sports',
    confirmationType: 'Digite %{validateFormKey} para confirmar:',
    deleteAction: 'Sim, Excluir',
    description: 'Tem certeza de que deseja excluir permanentemente sua conta?',
    information:
      'Vai perder todos os projetos de vídeo criados com esta conta. Não podemos recuperá-los depois de os excluir.',
    keepAccount: 'Manter conta',
    suggestion: 'Sugerimos manter sua conta.',
    title: 'Excluir para sempre?',
    validateFormKey: 'Excluir'
  },
  error: {
    oops: 'Oops!',
    restart: 'Parece está tentando entrar em algo que ainda estamos a desenvolver...'
  },
  eventsDetection: {
    automaticVp:
      'Smart Tagging não disponível em Projetos de Vídeo com uma versão anterior de ATD.',
    needsAtd: 'Inicie primeiro o processo ATD para poder executar o processo de Smart Tagging.',
    needsFootballSideView:
      'Smart Tagging não disponível em Projetos de Vídeo que contenham um arquivo de vídeo de futebol que não seja de ângulo largo ou transmitido pela televisão.',
    needsGameVp: 'Smart Tagging está disponível apenas em Projetos de Vídeo de Jogo.',
    needsNoPlaylist:
      'Smart Tagging não disponível em Projetos de Vídeo que contenham um arquivo de vídeo que seja uma lista de reprodução.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Os administradores precisam usar um endpoint privado para redefinir a senha',
    notFound: 'Usuário não encontrado. Tente novamente com um email válido',
    success: 'Password redefinida! Por favor verifique o seu email'
  },
  login: {
    contactUs: 'Contacte-nos',
    enterYourEmail: 'Insira o email',
    enterYourPassword: 'Insira a senha',
    forgotPassword: 'Esqueceu a sua senha?',
    invalidEmail: 'Endereço de e-mail inválido',
    licenceExpired: 'A sua licença expirou.',
    trialExpired: 'A versão de avaliação expirou.',
    upgradePlan: 'Atualize o seu plano'
  },
  patterns: {
    delete: 'Excluir arquivo de padrão',
    title: 'Arquivo de padrão personalizado',
    upload: 'Carregar arquivo de padrão personalizado',
    uploaded: 'Arquivo padrão carregado',
    uploading: 'Carregando arquivo de padrão'
  },
  plans: {
    academic: 'Académico',
    addMoreProducts: '+ Adicionar mais produtos',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Mensal',
    basicCodePadYearly: 'Basic+Code Anual',
    basicMftCodePadMonthly: 'Full Basic Mensal',
    basicMftCodePadYearly: 'Full Basic Anual',
    basicMftMonthly: 'Basic+MFT Mensal',
    basicMftXmlMonthly: 'Basic MFT + XML Mensal',
    basicMftXmlYearly: 'Basic MFT + XML Anual',
    basicMftYearly: 'Basic+MFT Anual',
    basicMonthly: 'Basic Mensal',
    basicXmlMonthly: 'Basic XML Mensal',
    basicXmlYearly: 'Basic XML Anual',
    basicYearly: 'Basic Anual',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite Anual',
    eliteYearly: 'Elite Anual',
    essentialsCodeMonthly: 'Essentials + Code ∞ Mensal',
    essentialsCodeYearly: 'Essentials + Code ∞ Anual',
    essentialsMftCodeMonthly: 'Full Essentials Mensal',
    essentialsMftCodeYearly: 'Full Essentials Anual',
    essentialsMftMonthly: 'Essentials + MFT Mensal',
    essentialsMftYearly: 'Essentials + MFT Anual',
    essentialsMonthly: 'Essentials Mensal',
    essentialsYearly: 'Essentials Anual',
    influencer: 'Influenciador',
    lite: 'Lite',
    liteMonthly: 'Lite Mensal',
    liteYearly: 'Lite Anual',
    minutes10: 'Pacote de 10 Minutos de Cloud',
    minutes100: 'Pacote de 100 Minutos de Cloud',
    minutes1000: 'Pacote de 1.000 Minutos de Cloud',
    minutes10000: 'Pacote de 10.000 Minutos de Cloud',
    plan: 'Plano %{planName}',
    primeMftMonthly: 'Prime + MFT Mensal',
    primeMftYearly: 'Prime + MFT Anual',
    primeMonthly: 'Prime Mensal',
    primePresetsMftMonthly: 'Full Prime Mensal',
    primePresetsMftYearly: 'Full Prime Anual',
    primePresetsMonthly: 'Prime + Presets Mensal',
    primePresetsYearly: 'Prime + Presets Anual',
    primeYearly: 'Prime Anual',
    pro: 'Pro',
    pro2024Monthly: 'Pro Mensal',
    pro2024Yearly: 'Pro Anual',
    proYearly: 'Pro Anual',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Mensal',
    starterCodePadMonthly: 'Code Mensal',
    starterCodePauseMonthly: 'Full Starter Mensal',
    starterCodePauseYearly: 'Full Starter Anual',
    starterCodeYearly: 'Starter + Code ∞ Anual',
    starterPauseMonthly: 'Starter + Pause ∞ Mensal',
    starterPauseYearly: 'Starter + Pause ∞ Anual',
    student: 'Estudante',
    trial: 'Versão de avaliação'
  },
  playlist: {
    download: 'Descarregar lista de reprodução',
    notProcessed: 'A lista de reprodução não foi processada neste Projeto de Vídeo',
    processed: 'Ficheiro de Lista de reprodução processado',
    title: 'Lista de reprodução',
    titleDownload: 'Descarregar a lista de reprodução'
  },
  products: {
    addOns: 'Complementos',
    basic: 'Códigos e Anotações Ilimitadas',
    code: 'CodePad',
    elite: {
      description:
        'Automatize e personalize todo o seu fluxo de trabalho de análise. Este plano inclui acesso completo ao Play2 com todas as características do Plano "Full Essentials" e acesso ao Play3 com todas as características do Plano "Pro".',
      feature1: '✓ Armazenamento Compartilhado na Cloud',
      feature2: '✓ Integração de Dados de Tracking',
      feature3: '✓ Gerenciador de Correção de Rastreamento',
      feature4: '✓ RESTful API',
      feature5: '✓ Pacote de 5.000 minutos de ATD'
    },
    essentials: {
      addon1: 'Funcionalidades de Coding ilimitadas (CodePad, Coding ao Vivo e Importação XML)',
      addon2: 'Rastreamento Manual de Campo',
      essentialsCodeDescription:
        'Este plano habilita as Visualizações Dinâmicas disponíveis para adicionar anotações de movimento ilimitadas ao reproduzir o vídeo e em pausas. Este plano não inclui Tracking de Campo Manual, portanto, as visualizações de Velocidade e Distância não estão incluídas. Também desbloqueia a limitação de recursos de coding como CodePad, Coding ao Vivo e Importação XML e elimina o limite de 4 códigos por Projeto de Vídeo para códigos ilimitados.',
      essentialsDescription:
        'Este plano habilita Visualizações Dinâmicas disponíveis para adicionar anotações de movimento ilimitadas ao reproduzir o vídeo e em pausas. Este plano não inclui Tracking de Campo Manual, portanto, as visualizações de Velocidade e Distância não estão incluídas. As características de coding permanecem limitadas.',
      essentialsMftCodeDescription:
        'Este plano habilita as Visualizações Dinâmicas disponíveis para adicionar anotações de movimento ilimitadas ao reproduzir o vídeo e em pausas, e também ativa o Tracking de Campo Manual para adicionar visualizações de velocidade e distância. Também desbloqueia a limitação de recursos de coding como CodePad, Coding ao Vivo e Importação XML e elimina o limite de 4 códigos por Projeto de Vídeo para códigos ilimitados.',
      essentialsMftDescription:
        'Este plano habilita as Visualizações Dinâmicas disponíveis para adicionar anotações de movimento ilimitadas ao reproduzir o vídeo e em pausas, e também ativa o Tracking de Campo Manual para adicionar visualizações de velocidade e distância. As características de coding permanecem limitadas.',
      feature1: '✓ Codificação pós-jogo (Code Table com até 4 códigos)',
      feature2: '✓ Telestração ilimitada em pausas',
      feature3: '✓ Telestração Dinâmica',
      feature4: '✓ Pacote de 3 minutos de ATD'
    },
    featuresIncluded: 'Recursos incluídos:',
    freeFeature1: '✓ Codificação pós-jogo (Code Table com até 4 códigos)',
    freeFeature2: '✓ Rastreamento manual',
    freeFeature3: '✓ Telestração (até 150 anotações)',
    freeFeature4: '✓ Exportação de Vídeo',
    freeFeatures: 'Funcionalidades Gratuitas',
    fullBasic: 'Rastreamento Manual de Campo',
    lite: 'Rastreamento Automático de Jogadores e Campo',
    liteDescription:
      'Codificação avançada e visualizações avançadas. Tudo automático, tornando-o 3 vezes mais rápido que o Full Basic!',
    minutesDescription:
      'Recarregue qualquer plano. Adquira pacotes de minutos para Dados de Tracking Automatizado (ATD).',
    minutesPack: 'Pacote de Minutos em Cloud',
    otherDescription:
      'Funcionalidades avançadas de Coding com telestração básica e anotações limitadas.',
    prime: {
      addon1: 'Rastreamento Manual de Campo',
      addon2: 'Visualizações Personalizadas (personalize e salve suas próprias visualizações)',
      feature1:
        '✓ Acesso completo ao Play2 com todas as características do Plano "Full Essentials"',
      feature2: '✓ Acesso ao Play3 com:',
      feature2_1: 'Pausas sequenciais',
      feature2_2: 'Telestração Avançada',
      feature2_3: 'Exportação de Vídeos Personalizada',
      feature3: '✓ Pacote de 10 minutos de ATD',
      primeDescription:
        'Este plano inclui acesso completo ao Play2 com todas as características do Plano "Essentials + Code ∞" e habilita o Play3 com telestração avançada.',
      primeMftDescription:
        'Este plano inclui acesso completo ao Play2 com todas as características do Plano "Full Essentials" e habilita o Play3 com telestração avançada.',
      primePresetsDescription:
        'Este plano inclui acesso completo ao Play2 com todas as características do Plano "Essentials + Code ∞" e habilita o Play3 com telestração avançada e visualizações personalizadas.',
      primePresetsMftDescription:
        'Este plano inclui acesso completo ao Play2 com todas as características do Plano "Full Essentials" e habilita o Play3 com telestração avançada e visualizações personalizadas.'
    },
    pro: {
      description:
        'Este plano inclui acesso completo ao Play2 com todas as características do Plano "Full Essentials" e acesso ao Play3 com todas as características do Plano "Full Prime".',
      feature1: '✓ Rastreamento Automático de Jogadores',
      feature2: '✓ Rastreamento Automático de Campo',
      feature3:
        '✓ Acesso completo ao Play2 com todas as características do Plano "Full Essentials"',
      feature4: '✓ Acesso ao Play3 com:',
      feature4_1: 'Pausas sequenciais',
      feature4_2: 'Telestração Avançada',
      feature4_3: 'Exportação de Vídeos Personalizada',
      feature4_4: 'Visualizações Personalizadas (personalize e salve suas próprias visualizações)',
      feature5: '✓ Pacote de 100 minutos de ATD'
    },
    remove: 'Remover',
    starter: {
      addon1: 'Visualizações ilimitadas em pausa',
      addon2: 'Recursos de Coding ilimitados (CodePad, Coding ao Vivo e Importação XML)',
      feature1: '✓ Codificação pós-jogo (Code Table com até 4 códigos)',
      feature2: '✓ Telestração em pausas (até 50 anotações por mês)',
      feature3: '✓ Exportação de Vídeos',
      starterCodeDescription:
        'Este plano desbloqueia a limitação de recursos de codificação como CodePad, Coding ao Vivo e Importação XML. Também remove o limite de 4 códigos por Projeto de Vídeo para códigos ilimitados. As limitações em recursos de telestração permanecem no Free Starter.',
      starterCodePauseDescription:
        'Este plano desbloqueia a limitação de recursos de codificação como CodePad, Codificação ao Vivo e Importação XML. Também elimina o limite de 4 códigos por Projeto de Vídeo para códigos ilimitados, e desbloqueia a limitação de telestração em pausas de 50 por mês para ilimitadas.',
      starterPauseDescription:
        'Este plano desbloqueia a limitação de telestração em pausas de 50 por mês para ilimitadas. As limitações nas características de coding permanecem no Free Starter.'
    },
    totalPack: 'Total: %{currency} %{cost}',
    totalPlan: 'Total: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Faturamento',
    billingSettings: 'Definições de faturação',
    billingSettingsDescription: '(altere o plano, atualize as formas de pagamento...)',
    cancelAt: 'Seu plano é cancelado a',
    cancelPlan: 'Cancelar plano',
    confirmNewPassword: 'Confirme a nova senha',
    deleteAccount: 'Eliminar conta',
    details: 'Meus detalhes',
    emailNotActivated: 'A notificação por e-mail agora está ativada',
    emailNotDeactivated: 'A notificação por e-mail agora está desativada',
    emailNotifications: 'Notificações por e-mail',
    language: 'Idioma',
    languageChanged: 'O idioma foi atualizado',
    newPassword: 'Nova Senha',
    organizationLabel: 'Prefixo da organização',
    organizationTooltip:
      'Esse prefixo é necessário para importar padrões personalizados para um projeto de vídeo. Contacte-nos para mais informações ou consulte a documentação em codeball.metrica-sports.com',
    passwordsDontMatch: 'As senhas não correspondem',
    pauseAt: 'Seu plano pausa em',
    planCanceled: 'O seu plano foi cancelado. Verifique o seu email para mais informações',
    plans: 'Planos',
    quantity: 'Quantidade:',
    renewAt: 'O seu plano renova-se a',
    title: 'Configurações',
    upgradePlan: 'Atualizar o Plano',
    userKey: 'Chave do usuário:',
    userKeyUsages: '%{current}/%{max} usos',
    userSettings: 'Configurações de Usuário'
  },
  shoppingCart: {
    subtitle: 'Escolha seu plano Play e complementos para elevar seu desempenho.',
    title: 'Configura o teu Play.'
  },
  subscription: {
    cancelSubscription: 'Cancelar assinatura',
    errorUpdating:
      'Houve um erro ao atualizar sua assinatura. Por favor, envie um e-mail para support@metrica-sports.com e ficaremos felizes em ajudá-lo.',
    free: 'Grátis',
    isObsolete:
      'Sua assinatura não pode ser modificada. Substitua os produtos obsoletos por novos para habilitar as mudanças',
    licenseKeys: 'Chaves de licença',
    pendingRefundRequest:
      'Não é possível fazer mais alterações nesta assinatura enquanto houver uma solicitação de reembolso pendente.',
    undoCancel: 'Desfazer cancelamento de assinatura.',
    undoPause: 'Desfazer pausa da assinatura',
    updatePaymentMethod: 'Atualizar método de pagamento',
    updatedSuccessfully: 'Assinatura atualizada com sucesso.',
    yourPlans: 'Seus Planos',
    yourSubscription: 'Sua Assinatura'
  },
  successfulPayment: {
    activateLicence:
      'Ative sua licença ou crédito de Cloud inserindo a chave de licença fornecida.',
    allTheBest: 'Tudo de bom,',
    applyKey: 'Aplicar chave a esta conta.',
    applyKeyDescription:
      'Gostaria de aplicar a chave de licença na conta em que está conectado automaticamente?',
    downloadPlay: '%{pre}Baixe PLAY%{post} através do nosso site.',
    faq: 'Para ajudá-lo a começar com seu pedido, confira as %{pre}Perguntas Frequentes%{post} em nosso site. Aqui está um resumo rápido das informações que podem ser mais relevantes para você nesta etapa:',
    goToSummary:
      'Caso contrário, por favor, consulte o resumo abaixo para conhecer as instruções de ativação.',
    happyPlay:
      'Esperamos que tudo esteja claro, mas por favor não hesite em nos contatar se tiver alguma dúvida ou pergunta. Feliz PLAY!',
    helpDesk:
      'Confira nosso %{pre}HelpDesk%{post} para obter informações gerais sobre o uso do PLAY.',
    keyApplied: 'Chave aplicada',
    licenseKey: 'Sua chave de licença é: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'A equipe da Metrica Sports',
    settingsCloud: 'Visite nosso %{pre}Cloud%{post} e navegue até o menu de configurações para:',
    technicalSupport:
      'Para suporte técnico, preencha o formulário de contato disponível em PLAY/Cloud, ou envie um e-mail para %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'Por favor, note que nossas assinaturas são renovadas automaticamente até que sejam canceladas explicitamente através das configurações no %{pre}Cloud%{post}, de acordo com nossos Termos e Condições.',
    thanks:
      'Obrigado pelo seu pedido! Processamos com sucesso e anexamos suas chaves de licença abaixo.',
    title: 'Confirmação do Pedido',
    updatePayment: 'Atualize seu método de pagamento.',
    updateSubscription: 'Cancele ou modifique sua assinatura.'
  },
  surfaces: {
    automaticVp: 'Superfícies não disponíveis em projetos de vídeo com uma versão ATD mais antiga',
    delete: 'Excluir arquivo de Superfície',
    needsAtd: 'Inicie o processo ATD primeiro para executar os processos de Superfície',
    needsElite:
      'As Superfícies Personalizáveis estão disponíveis no plano Elite. Contacte-nos para mais informações',
    needsFootballSideView:
      'Superfícies não disponíveis em Projetos de vídeo contendo um arquivo de vídeo de grande angular que não seja futebol ou de transmissão de TV',
    pitchControl:
      'Ao executar este processo, você poderá adicionar a visualização do Pitch Control na Play.',
    pitchControlProcessed: 'Pitch Control processado',
    pitchControlProcessing: 'Processando Pitch Control',
    processed: 'Arquivo de superfície carregado',
    processing: 'Processando arquivo de superfície',
    title: 'Superfícies',
    titlePitchControl: 'Pitch Control',
    upload: 'Carregue a sua própria Superfície',
    uploading: 'Carregando superfície...'
  },
  trackingFifaFormat: {
    download: 'Descarregar o arquivo no formato FIFA EPTS',
    needsElite:
      'Esta função só está disponível após a importação dos arquivos correspondentes no módulo de Integração de Dados, Projetos de Vídeo com Dados de Tracking Automatico ou Projetos de Vídeo no plano Elite.',
    processed: 'Formato FIFA EPTS processado',
    start:
      'Iniciar o processo para gerar o arquivo no formato FIFA EPTS. Poderá depois descarregar o arquivo',
    title: 'Formato FIFA EPTS',
    titleDownload: 'Descarregar o formato EPTS da FIFA'
  },
  upgradeModal: {
    linksDescription: 'Obtenha o pacote de minutos da Cloud Engine mais adequado para si:',
    minutePackLinks: {
      minutes_10: 'Adquira 10 minutos por 10€',
      minutes_100: 'Adquira 100 minutos por 90€',
      minutes_1000: 'Adquira 1.000 minutos por 750€',
      minutes_10000: 'Adquira 10.000 minutos por 5.000€'
    },
    noMinutes: {
      description:
        'Você não tem minutos disponíveis suficientes para criar este projeto de vídeo. Você sabe que pode obter pacotes de minutos seja qual for o seu plano?',
      secondDescription: '',
      title: 'Obtenha mais minutos para criar um projeto de vídeo!'
    },
    notEnoughMinutes: {
      description:
        'Lamentamos informar que você não tem minutos suficientes para o processo de ATD nos seus projetos de vídeo.',
      secondDescription:
        'Se quiser processar a ATD precisa adicionar mais minutos à sua conta. Os minutos que adquirir nunca expiram!',
      title: 'Não tem minutos suficientes disponíveis'
    }
  },
  video: {
    cancelUpload: 'Cancelar upload',
    delete: 'Excluir vídeo',
    download: 'Descarregar vídeo',
    title: 'Arquivo de vídeo',
    upload: 'Carregar vídeo',
    uploaded: 'Vídeo carregado',
    uploading: 'Carregando vídeo'
  },
  videoProject: {
    delete: 'Excluir projeto de vídeo',
    edit: 'Editar projeto de vídeo',
    processWaitingJobs: 'Processar trabalhos em espera',
    title: 'Projeto de vídeo'
  }
}
