/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { Button, Checkbox, TextField } from '@material-ui/core'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { theme } from '../../../constants'

const styles = {}

styles.blockingOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
`

styles.box = css`
  position: absolute;
  width: 700px;
  border-radius: 5px;
  padding: 45px 60px;
  background: white;
  border: 10px solid lightgray;
  pointer-events: all;
`

styles.title = css`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 25px;
  margin-bottom: 25px;
`

styles.descriptionTableContainer = css`
  display: flex;
  flex-wrap: wrap;
`

styles.descriptionContainer = css`
  font-size: 16px;
  margin: 10px 0px;
`

styles.validationContainer = css`
  font-size: 14px;
  margin: 15px 0px;
`

styles.actionContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0px;
`

styles.iconClose = css`
  position: absolute;
  display: flex;
  color: lightgrey;
  top: 20px;
  right: 20px;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 28px;
  cursor: pointer;
`

styles.keepAccount = css`
  background: ${theme.palette.common.mainGradient};
  color: ${theme.palette.common.charolBlack};
  text-decoration: none;
  padding: 10px;
`
styles.deleteAccount = css`
  text-decoration: none;
  color: ${theme.palette.error.main};
  margin-right: 10px;
`

class DeleteModal extends Component {
  state = {
    validateForm: false,
    keepMailing: false
  }

  handleChange(event) {
    this.setState({ validateForm: event.target.value === this.getValidateFormKey() })
  }

  handleChangeCheckbox = event => {
    this.setState({ keepMailing: event.target.checked })
  }

  handleAction = () => {
    this.props.onDelete(this.state.keepMailing)
    this.props.onClose()
  }

  getValidateFormKey = () => {
    return window.I18n.t('deleteModal.validateFormKey')
  }

  render() {
    return (
      <div css={styles.blockingOverlay}>
        <div css={styles.box}>
          <span css={styles.title}>{this.props.title}</span>
          <div css={styles.descriptionContainer}>{this.props.description}</div>
          <div css={styles.descriptionTableContainer}>
            <div css={styles.descriptionContainer}>{window.I18n.t('deleteModal.information')}</div>
            <div css={styles.descriptionContainer}>{window.I18n.t('deleteModal.suggestion')}</div>
            <div
              css={styles.validationContainer}
              style={{ color: this.state.keepMailing ? theme.palette.common.charolBlack : 'grey' }}
            >
              <Checkbox
                checked={this.state.keepMailing}
                onChange={this.handleChangeCheckbox.bind(this)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              {window.I18n.t('deleteModal.checkboxLabel')}
            </div>
            <div css={styles.descriptionContainer}>
              {window.I18n.t('deleteModal.confirmationType', {
                validateFormKey: this.getValidateFormKey()
              })}
              <TextField
                type="text"
                name="description"
                margin="normal"
                onChange={this.handleChange.bind(this)}
                fullWidth
              />
            </div>
          </div>
          <div css={styles.actionContainer}>
            <Button
              onClick={this.handleAction}
              css={styles.deleteAccount}
              disabled={!this.state.validateForm}
            >
              {window.I18n.t('deleteModal.deleteAction')}
            </Button>
            <Button onClick={this.props.onClose} css={styles.keepAccount}>
              {window.I18n.t('deleteModal.keepAccount')}
            </Button>
          </div>
          <span css={styles.iconClose} onClick={this.props.onClose}>
            {' '}
            X{' '}
          </span>
        </div>
      </div>
    )
  }
}

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default DeleteModal
