/** @jsx jsx */
// eslint-disable-next-line
import React, { Component, Fragment } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import logo from '../../assets/logo.png'
import tokenService from '../../services/token'
import * as Constants from '../../constants'
import ActionTypes from '../../constants/actionTypes'
import ZendeskWidgetButton from '../components/ZendeskWidgetButton'
import codeIcon from '../../assets/icons/code.svg'
import getTrackingMinutes from '../../utils/getTrackingMinutes'
import minTommss from '../../utils/minPlans'
import * as Translations from '../../translations'
import { history } from '../App'

const theme = Constants.theme
const styles = {}
styles.container = css`
  height: ${Constants.headerHeight}px;
  width: 100%;
  background-color: ${theme.palette.secondary.main};
  padding: 0 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`
styles.logo = css`
  width: 150px;
`
styles.clickButton = css`
  border: 0;
  background: transparent;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  cursor: pointer;
`
styles.email = {
  color: theme.palette.common.white,
  textTransform: 'none',
  fontSize: 16,
  marginRight: 48
}
styles.titleContainer = css`
  text-align: center;
  font-size: 17px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${theme.palette.common.white};
`
styles.title = css`
  text-transform: uppercase;
`
styles.menuIconMenu = {
  fontSize: 20,
  marginLeft: '20px',
  float: 'right',
  color: theme.palette.secondary.main
}
styles.menuImgMenu = {
  ...styles.menuIconMenu,
  width: '20px'
}
styles.menuIconMenuRight = {
  fontSize: 20,
  float: 'right',
  color: theme.palette.common.white
}
styles.menuItem = {
  color: theme.palette.secondary.main,
  fontWeight: '500',
  justifyContent: 'space-between'
}
styles.menuHeader = { zIndex: '9', position: 'relative' }
styles.infoContainer = {
  display: 'flex'
}
styles.minutesContainer = {
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '20px'
}
styles.minutesTitle = {
  color: theme.palette.common.grey,
  fontSize: '18px'
}
styles.minutesValue = {
  color: theme.palette.common.blue
}
styles.planContainer = {
  display: 'flex',
  flexDirection: 'column'
}
styles.planTitle = {
  color: theme.palette.common.white,
  fontSize: '18px'
}
styles.planValue = {
  color: theme.palette.common.main
}
styles.buttonContainer = {
  display: 'flex',
  alignItems: 'center'
}
styles.buttonHeader = {
  height: '23px',
  padding: '6px 10px',
  marginLeft: '10px',
  textTransform: 'none',
  fontWeight: 'none',
  lineHeight: 'inherit'
}
styles.buttonMinutes = {
  ...styles.buttonHeader,
  color: theme.palette.common.white,
  background: theme.palette.common.blue
}

styles.buttonUpgrade = {
  ...styles.buttonHeader,
  color: theme.palette.common.charolBlack,
  background: theme.palette.common.main
}

class Header extends Component {
  state = { menuOpen: false }

  onUpgrade = () => {
    history.push('/settings/plans')
  }

  onGetMoreMinutes = () => {
    if (history.location.pathname.includes('/shopping-cart')) {
      return
    }

    history.push('/shopping-cart?minutes_100_one_time')
  }

  handleMenuToggle = () => {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  handleMenuClose = event => {
    if (this.menuAnchorEl.contains(event.target)) {
      return
    }
    this.setState({ menuOpen: false })
  }

  logout = async () => {
    try {
      tokenService.delete()
      this.props.dispatch({ type: ActionTypes.LOGOUT })
      history.replace('/login')
    } finally {
      this.setState({ menuOpen: false })
    }
  }

  goToSettings = () => {
    this.setState({ menuOpen: false })
    history.push('/settings')
  }

  openKeyModal = () => {
    this.setState({ menuOpen: false })
    this.props.dispatch({
      type: ActionTypes.TOGGLE_ADD_KEY_MODAL,
      payload: true
    })
  }

  getPlanMinutes = user => {
    const totalMinutes = getTrackingMinutes(user)

    return totalMinutes === -1 ? '∞' : `${minTommss(totalMinutes)}`
  }

  render() {
    const subscriptionInUse = this.props.subscriptionInUse
    let upgradeTooltip = ''
    if (subscriptionInUse) {
      const isSubscriptionOwner =
        subscriptionInUse.linkUpdatePlan || subscriptionInUse.linkCustomerPortal
      if (!isSubscriptionOwner && subscriptionInUse.order) {
        upgradeTooltip = window.I18n.t('admin.noSubscriptionOwnerTooltip', {
          email: subscriptionInUse.order.email
        })
      }
    }

    const englishPlans = Translations.English.phrases.plans
    const planKey = Object.keys(englishPlans).find(v => englishPlans[v] === this.props.planName)

    return (
      <div css={styles.container}>
        <button onClick={() => history.push('/')} css={styles.clickButton}>
          <img src={logo} alt="Logo" css={styles.logo} />
        </button>
        <div css={styles.titleContainer}>
          <div css={styles.title}>PLAY by Metrica Sports</div>
          <div>Cloud Engine</div>
        </div>
        <div css={styles.infoContainer}>
          {this.props.user.email && (
            <div css={styles.infoContainer}>
              <div css={styles.minutesContainer}>
                <span css={styles.minutesTitle}>{window.I18n.t('admin.cloudMinutes')}</span>
                <div css={styles.buttonContainer}>
                  <span css={styles.minutesValue}>{this.getPlanMinutes(this.props.user)}</span>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={styles.buttonMinutes}
                    onClick={this.onGetMoreMinutes}
                  >
                    {window.I18n.t('admin.getMoreMinutes')}
                  </Button>
                </div>
              </div>
              <div css={styles.planContainer}>
                <span css={styles.planTitle}>{this.props.user.email}</span>
                <div css={styles.buttonContainer}>
                  <span css={styles.planValue}>
                    {window.I18n.t('plans.plan', {
                      planName: planKey ? window.I18n.t(`plans.${planKey}`) : this.props.planName
                    })}
                  </span>
                  <Tooltip title={upgradeTooltip}>
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={styles.buttonUpgrade}
                        onClick={this.onUpgrade}
                      >
                        {window.I18n.t('admin.upgradePlan')}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          <Button
            aria-haspopup="true"
            style={styles.email}
            onClick={this.handleMenuToggle}
            buttonRef={node => {
              this.menuAnchorEl = node
            }}
            aria-owns={this.state.menuOpen ? 'menu-list-grow' : undefined}
          >
            {this.props.user.email ? (
              <Fragment>
                <Icon style={styles.menuIconMenuRight}>settings</Icon>
              </Fragment>
            ) : (
              window.I18n.t('common.loading')
            )}
          </Button>
          <Popper
            open={this.state.menuOpen}
            anchorEl={this.menuAnchorEl}
            style={styles.menuHeader}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleMenuClose}>
                    <MenuList>
                      <MenuItem onClick={this.goToSettings} style={styles.menuItem}>
                        {window.I18n.t('admin.settings')}
                        <Icon style={styles.menuIconMenu}>settings</Icon>
                      </MenuItem>
                      <MenuItem onClick={this.openKeyModal} style={styles.menuItem}>
                        {window.I18n.t('admin.addKey')}
                        <img src={codeIcon} style={styles.menuImgMenu} />
                      </MenuItem>
                      <MenuItem onClick={this.logout} style={styles.menuItem}>
                        {window.I18n.t('common.logout')}
                        <Icon style={styles.menuIconMenu}>call_missed_outgoing</Icon>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ZendeskWidgetButton />
        </div>
      </div>
    )
  }
}

Header.defaultProps = {
  subscriptionInUse: undefined
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  subscriptionInUse: PropTypes.object,
  planName: PropTypes.string.isRequired,
  trackingMinutes: PropTypes.number.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    subscriptionInUse: state.auth.subscriptionInUse,
    planName: state.auth.planName,
    trackingMinutes: state.auth.trackingMinutes
  }
}

export default connect(mapStateToProps)(Header)
