import React from 'react'
import moment from 'moment/dist/moment'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

function CustomDatePicker(props) {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={window.I18n.locale()}>
      <DatePicker {...props} />
    </MuiPickersUtilsProvider>
  )
}

export default CustomDatePicker
