/** @jsx jsx */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import memoize from 'lodash/memoize'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'

const renderInputKeyDown = toggleButtonProps => e => {
  // this prevents the menu from being open or closed when the user
  // press space with the keyboard and add the sapce to the input string
  if (e.defaultPrevented || e.keyCode === 32) {
    return
  }

  toggleButtonProps().onKeyDown(e)
}

function renderInput(getToggleButtonProps, showSelect, inputProps) {
  const { InputProps, classes, ref, isOpen, ...other } = inputProps

  return (
    <div
      {...getToggleButtonProps()}
      className={classes.relative}
      onKeyDown={renderInputKeyDown(getToggleButtonProps)}
    >
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        {...other}
      />
      {showSelect && (
        <Icon classes={{ root: isOpen ? classes.iconSelectBaseRotated : classes.iconSelectBase }}>
          arrow_drop_down
        </Icon>
      )}
    </div>
  )
}

const menuItemStyles = css`
  flex: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`
const clearIconStyles = {
  position: 'absolute',
  right: 3,
  top: 8,
  color: 'grey',
  cursor: 'default',
  fontSize: 18
}
const renderSuggestionStyles = suggestionValue => ({
  pointerEvents: suggestionValue ? 'all' : 'none',
  position: 'relative',
  paddingLeft: 9,
  paddingRight: 9,
  fontSize: 14.5
})
const renderSuggestionStylesMemo = memoize(renderSuggestionStyles)

// eslint-disable-next-line
function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  query,
  onOptionDelete
}) {
  const matches = match(suggestion.label, query)
  const parts = parse(suggestion.label, matches)
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
      key={suggestion.value}
      style={renderSuggestionStylesMemo(suggestion.value)}
    >
      <div css={menuItemStyles}>
        {parts.map((part, idx) =>
          part.highlight ? (
            <span
              key={String(idx)}
              css={[
                menuItemStyles,
                css`
                  font-weight: 500;
                `
              ]}
            >
              {part.text}
            </span>
          ) : (
            <strong
              key={String(idx)}
              css={[
                menuItemStyles,
                css`
                  font-weight: 300;
                `
              ]}
            >
              {part.text}
            </strong>
          )
        )}
      </div>
      {isHighlighted && (
        <Icon style={clearIconStyles} onClick={onOptionDelete(suggestion.value)}>
          clear
        </Icon>
      )}
    </MenuItem>
  )
}

/*eslint-disable */
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}
/* eslint-enable */

function getSuggestions(value, suggestions, stateValue) {
  const val = value || stateValue
  const inputValue = deburr(val.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0
  const suggestionsFiltered = suggestions.filter(suggestion => {
    const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue
    if (keep) {
      count += 1
    }
    return keep
  })

  if (inputLength === 0) {
    return suggestions
  } else if (!suggestionsFiltered.length) {
    return [{ label: window.I18n.t('admin.noMatches'), value: null }]
  }

  return suggestionsFiltered
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 100,
    scrollbarWidth: 'none',
    overflowY: 'overlay',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    flexGrow: 1,
    paddingRight: 20
  },
  iconSelectBase: {
    position: 'absolute',
    right: -5,
    fontSize: 24,
    top: 36,
    color: 'rgba(0, 0, 0, 0.54)',
    transition: 'all .2s ease'
  },
  iconSelectBaseRotated: {
    position: 'absolute',
    right: -5,
    fontSize: 24,
    top: 36,
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(180deg)',
    transition: 'all .2s ease'
  },
  relative: {
    position: 'relative'
  }
})

const maxLength = (val, maxString) => (maxString ? val.substring(0, maxString) : val)
const equalsIgnoringCase = (text, other) =>
  text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0
const itemToString = i => (i ? i.label : '')
const onStateChange = (setValue, onChange, suggestions, maxString) => changes => {
  if (changes.hasOwnProperty('selectedItem') && changes.selectedItem) {
    // eslint-disable-line
    setValue(maxLength(changes.selectedItem.label, maxString))
    onChange({ ...changes.selectedItem, label: maxLength(changes.selectedItem.label, maxString) })
  } else if (changes.hasOwnProperty('inputValue')) {
    // eslint-disable-line
    const suggestionValue = suggestions.find(
      ({ label }) => !!equalsIgnoringCase(label, changes.inputValue)
    )
    const inputValue = suggestionValue ? suggestionValue.label : changes.inputValue
    setValue(maxLength(inputValue, maxString))
    const onChangeVal = suggestionValue
      ? { ...suggestionValue, label: maxLength(suggestionValue.label, maxString) }
      : inputValue
    onChange(onChangeVal)
  }
}
const stateReducer = (state, changes) => {
  if (changes.type !== Downshift.stateChangeTypes.changeInput) {
    const inputValue = changes.inputValue || state.inputValue
    return { ...state, ...changes, inputValue }
  }
  return { ...state, ...changes }
}

const optionDelete = props => value => e => {
  e.preventDefault()
  e.stopPropagation()
  props.onOptionDelete(value)
}

function IntegrationDownshift({
  classes,
  onChange,
  placeholder,
  name,
  onBlur,
  label,
  error,
  errorText,
  suggestions,
  ...other
}) {
  const [value, setValue] = useState('')
  useEffect(() => {
    if (other.initialSelected) {
      setValue(other.initialSelected.label)
    }
  }, [])
  const showSelect = !!suggestions.length

  return (
    <div className={classes.root}>
      <Downshift
        {...other}
        itemToString={itemToString}
        inputValue={value}
        onStateChange={onStateChange(setValue, onChange, suggestions, other.maxlength)}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          getToggleButtonProps
        }) => (
          <div className={classes.container}>
            {renderInput(getToggleButtonProps, showSelect, {
              fullWidth: true,
              classes,
              isOpen,
              InputProps: getInputProps({
                placeholder,
                name,
                onBlur,
                maxLength: other.maxlength || undefined
              }),
              label,
              error,
              helperText: errorText,
              margin: 'normal'
            })}
            <div {...getMenuProps()}>
              {isOpen && showSelect ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue, suggestions, value).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      query: inputValue,
                      selectedItem,
                      onOptionDelete: optionDelete(other)
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}

IntegrationDownshift.defaultProps = {
  placeholder: '',
  onBlur: () => ({}),
  onChange: () => ({}),
  onOptionDelete: () => ({}),
  name: '',
  label: '',
  errorText: '',
  error: false
}

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  onOptionDelete: PropTypes.func
}

export default withStyles(styles)(IntegrationDownshift)
