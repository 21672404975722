export default {
  addKeyModal: {
    action: 'Aplicar',
    errorMessage:
      "S'ha produït un error en aplicar la clau. Si el problema continua, poseu-vos en contacte amb l'equip de suport de Metrica Sports",
    minutesMessage: 'La teva clau ha estat processada. Ara tens %{minutes} minuts disponibles.',
    placeholder: 'Escriu una clau...',
    successMessage: "La clau s'ha processat correctament",
    title: 'Afegeix una clau'
  },
  admin: {
    NoGames: 'No hi ha partits, afegeix el teu primer...',
    NoResults: 'Sense resultats...',
    addKey: 'Afegeix la clau',
    atd: 'ATD',
    automatedTrackingData: 'Dades de tracking automatitzades (ATD)',
    automatedTrackingDataJobSuccess:
      'El procés de ATD a %{vpName} ha finalitzat i ja està disponible a Metrica Play',
    automatedTrackingDataProcessedTooltip: 'ATD processat',
    awayTeam: 'Equip Visitant',
    awayTeamScore: "Resultat de l'equip visitant",
    ballDetection: 'Detecció de pilota',
    ballTracking: 'Tracking de pilota',
    ballTrackingProcessedTooltip: 'Detecció de pilota processada',
    ballTrackingTooltip:
      'Aquest procés detectarà la pilota i us permetrà visualitzar-la dins de la "Live formation" (Pitch 2D) a Play. Les coordenades de la pilota també estaran disponibles al fitxer en format FIFA EPTS.',
    cancelATDProcessSuccessfully: "El procés de ATD en el %{vpName} s'ha cancel.lat.",
    cancelDataIntegrationProcessSuccessfully:
      "El procés d'integració de dades en el %{vpName} s'ha cancel.lat",
    cancelEventsProcessSuccessfully: 'El procés de Smart Tagging es va cancel·lar a %{vpName}.',
    cancelProcessTooltip: 'Cancel·la el procés',
    cancelSurfacesProcessSuccessfully: "El procés de superfícies en el %{vpName} s'ha cancel.lat",
    cancelTrackingFifaFormatProcessSuccessfully:
      "El procés de tracking del format FIFA en el %{vpName} s'ha cancel.lat",
    category: 'Categoria',
    checking: "S'està comprovant...",
    cloudMinutes: 'Minuts al núvol',
    competition: 'Competició',
    connectionFailed: 'Connexió fallida',
    connectionFailedText:
      "Sembla que hi ha un problema per recuperar la informació de l'estat. Si us plau, comproveu la vostra connexió a Internet i torneu a carregar la pàgina. Si el problema persisteix, poseu-vos en contacte amb el servei de suport",
    correctedTracking: 'Processant la correcció del Tracking',
    correctionUsage: 'Fareu servir aquest projecte de vídeo per a la correcció del Tracking?',
    correctionUsageTooltip:
      "Tracking Correction Manager (TCM) és un mòdul de Play orientat a l'usuari per corregir els jugadors generats prèviament amb el nostre ATD, que genera automàticament dades sense ID per a qualsevol partit de futbol. Utilitzeu TCM per corregir pistes incorrectes i assignar-los una identitat. Contacta amb nosaltres per a més informació.",
    createVideoProject: 'Crea un projecte de vídeo',
    creatingGame: "S'està creant un projecte de vídeo...",
    dataIntegrationFailed:
      "La integració de dades ha fallat a %{vpName}. Aneu al panell d'Integració de dades, comproveu els vostres fitxers i torneu-ho a provar. Si l'error continua, poseu-vos en contacte amb l'equip de suport de Metrica Sports",
    dataIntegrationJobSuccess:
      "El procés d'integració de dades per a %{vpName} s'ha acabat correctament",
    date: 'Data',
    description: 'Descripció',
    editedSuccessfully: "%{vpName} s'ha editat correctament",
    errorContactSupport: 'Error. Contacta amb suport',
    errorProcessTooltip: 'Error en el procés',
    eventsDetection: 'Etiquetatge automàtic',
    eventsDetectionProcessedTooltip: 'Smart Tagging processat',
    eventsDetectionTooltip: {
      footer: '*Disponible per a vídeos de més de 30 minuts.',
      header:
        "Aprofita de la nostra tecnologia per detectar automàticament una sèrie d'esdeveniments que es produeixen en el partit*.",
      improved: 'Millorat'
    },
    fieldRecognition: 'Detecció de camp',
    fieldTrackingProcessedTooltip: 'Detecció de camp processada',
    fieldTrackingTooltip:
      'Processa la detecció del camp per afegir visualitzacions amb valors de velocitat i distància i tots els dibuixos que depenen del camp a Play.',
    fileDeletedSuccessfully: "%{fileName} s'ha suprimit correctament per a %{vpName}",
    fileUploadedError: "S'ha produït un error en carregar %{fileName} per a %{vpName}",
    fileUploadedSuccessfully: "%{fileName} s'ha pujat correctament per a %{vpName}",
    forkedGameLimit:
      'Aquest projecte de vídeo pertany a una altra organització. No és possible modificar-lo',
    gameDeletedSuccessfully: "%{game} s'ha suprimit correctament",
    gameVideoProject: 'Projecte de video de partit',
    getMoreMinutes: 'Obtenir més',
    homeTeam: 'Equip de casa',
    homeTeamScore: "Resultat de l'equip local",
    initializing: 'Inicialitzant...',
    jobFailed:
      "El procés %{process} ha fallat per a %{vpName}. Poseu-vos en contacte amb l'equip de suport de Metrica Sports",
    liveCoding: 'o continuar amb la codificació en directe?',
    liveCodingTooltip:
      'Podeu crear un projecte de vídeo sense seleccionar un fitxer de vídeo per a la codificació en directe. Un cop tingueu el vídeo, podeu afegir-lo per tenir dades de tracking automàtic dins del projecte de vídeo codificat.',
    localTeamOnTheLeft: "L'equip local es troba a l'esquerra del camp a la primera part?",
    localTeamOnTheLeftTooltip:
      "Per processar l'etiquetatge automàtic, especifiqueu en quin costat del camp es troba cada equip. Fes una ullada al vídeo que estàs penjant per validar-ho.",
    noFootballSideViewTooltip:
      "Aquest projecte de vídeo conté un fitxer de vídeo de càmera tàctica o televisió que no és de futbol, de manera que només s'ha processat la detecció de jugadors i equips.",
    noMatches: 'Sense coincidències...',
    noSubscriptionOwnerTooltip:
      "Poseu-vos en contacte amb %{email} (l'administrador de la vostra subscripció) per actualitzar, o aneu a la nostra pàgina de plans per crear el vostre propi pla",
    noVideoFile: 'Cap fitxer de vídeo',
    notPossibleToDeleteThisEntry: 'No es pot eliminar aquesta entrada',
    numberOfHalves: 'Quantes meitats té aquest partit?',
    numberOfHalvesTooltip:
      "Per processar l'etiquetatge automàtic, especifica quants períodes de joc s'nclouen en el vídeo que estàs penjant.",
    oldAtdTooltip: "Aquest vídeo s'ha processat amb una versió anterior d'ATD",
    onRequest: 'A demanda',
    organizations: 'Organitzacions',
    place: 'Lloc',
    playerTracking: 'Detecció de jugadors i equips',
    playerTrackingProcessedTooltip: "S'ha processat la detecció de jugadors",
    playerTrackingTooltip:
      'Aquest procés detectarà els porters i els 10 jugadors de camp de cada equip. Això us permetrà visualitzar la formació en directe (pitch 2D) i afegir visualitzacions de jugadors automàticament a Play. Les coordenades dels jugadors també estaran disponibles al fitxer en format FIFA EPTS.',
    postProcessingData: 'Post-processant les dades...',
    preProcessingAtd: 'Pre-processament...',
    processingTooltip: "S'està processant",
    readyToUpload: 'A punt per pujar',
    search: 'Busca...',
    selectVideoFile: 'Seleccioneu el fitxer de vídeo',
    settingUpAtd: "S'està configurant la detecció...",
    settings: 'Configuració',
    singleVideoProject: 'Projecte de vídeo',
    startAtd: "Inicieu l'ATD",
    startFifaMessage:
      "El procés d'integració de FIFA per a %{vpName} ha començat. Mostrarem una notificació un cop feta. Si us plau, espereu.",
    startProcessingWaitingJobsFailed: "No s'han pogut enviar les feines en espera",
    startProcessingWaitingJobsSuccessfully: 'Feines en espera enviades correctament',
    startSsMessage:
      "S'ha iniciat el procés d'integració de Second Spectrum per a %{vpName}. Mostrarem una notificació un cop feta. Si us plau, espereu.",
    startStatsMessage:
      "El procés d'integració de Stats per a %{vpName} ha començat. Mostrarem una notificació un cop feta. Si us plau, espereu.",
    startTracabDflMessage:
      "El procés d'integració de Tracab DFL per a %{vpName} ha començat. Mostrarem una notificació un cop hagi acabat. Si us plau, espereu.",
    startTracabMessage:
      "El procés d'integració de Tracab per a %{vpName} ha començat. Mostrarem una notificació un cop feta. Si us plau, espereu.",
    surfacesJobSuccess: "El procés de superfície %{vpName} s'ha acabat correctament",
    trackingCorrectionProcessedTooltip: 'Correcció de Tracking processada',
    trackingFifaFormatJobSuccess:
      "El procés de tracking del format FIFA per a %{vpName} s'ha acabat correctament. Ara podeu descarregar el fitxer fent clic a l'element del menú Descarregar format FIFA",
    trackingLicence: 'No teniu llicència de Tracking',
    uncaughtErrorRow:
      'Error del projecte de vídeo. Si us plau, actualitzeu aquesta pàgina. Si el problema persisteix, poseu-vos en contacte amb el servei de suport o suprimiu-lo i torneu-lo a crear',
    updatingGame: "S'està actualitzant el Projecte de Video...",
    upgradePlan: 'Actualitzar',
    uploadAndTracking: 'Pujada i tracking',
    uploadingVideoFile: 'Carregant un fitxer de vídeo',
    videoFile: 'Arxiu de vídeo',
    videoFootballSideView:
      "Aquest arxiu de vídeo és d'un gran angular o retransmissió de TV de futbol?",
    videoFootballSideViewTooltip:
      'La detecció de camp només està optimitzada per a aquest tipus de vídeos.',
    videoMultipartStart:
      "%{videoName} s'està carregant a %{vpName}. NO TANQUIS AQUESTA PÀGINA fins que no acabi la càrrega",
    videoPlaylist: 'Aquest fitxer de vídeo és una llista de reproducció?',
    videoPlaylistTooltip:
      "Si seleccioneu SÍ, s'executarà la detecció de clips per reconèixer quan s'està produint un tall al vídeo, la qual cosa el converteix en una eina perfecta si, per exemple, pengeu un vídeo que conté diversos clips. Després d'executar aquest procés, descarregueu el projecte de vídeo a Play i hi haurà un fitxer de codificació de detecció de clips amb un codi que inclou cada tall com a Event.",
    videoProjectSettings: 'Configuració del projecte de vídeo',
    videoUploadError:
      'La pujada de vídeo de %{videoName} per a %{vpName} ha fallat. Siusplau torna-ho a provar',
    videoUploaded: "%{videoName} s'ha penjat a %{vpName}",
    vpCreated: "S'ha creat %{vpName}",
    waitingAtd: 'Esperant...'
  },
  billing: {
    address: 'Adreça',
    billed: 'Facturat',
    completed: 'Pagat',
    details: 'Detalls de Facturació',
    history: 'Historial de Facturació',
    invoiceNotFound: 'Factura no trobada',
    noInvoices: 'No hi ha factures.',
    past_due: 'Vencut',
    taxId: 'Identificador Fiscal'
  },
  checkout: {
    chargeSubscription: 'Carregar a la subscripció',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: 'Els codis de descompte es poden aplicar al moment de pagar.',
    excTax: '(Exc. impostos)',
    immediatePayment: 'Pagament Immediat',
    incTax: '(Inc. impostos)',
    monthlyBilled: 'Facturat mensualment',
    nextPayment: 'Pròxim Pagament',
    noPacks: 'Sense Pack de Minuts Cloud',
    onDate: 'el %{date}',
    packs: 'Packs',
    packsTotal: 'Packs Totals',
    perMonth: '/mes',
    perYear: '/any',
    plans: 'Plans',
    plansTotal: 'Total de Plans',
    proceed: 'Procedir al pagament',
    subtotal: 'Subtotal',
    taxPayable: 'Impostos a pagar (%{percentage}%)',
    total: 'Total',
    unpaid:
      'La teva última factura encara no ha estat pagada. Si us plau, actualitza la teva informació de pagament per continuar.',
    updatePaymentMethod: 'Actualitzar Mètode de Pagament',
    updateSubscription: 'Actualitzar Subscripció',
    userDevice: '1 usuari/dispositiu',
    usersDevices: '%{number} usuaris/dispositius',
    yearlyBilled: 'Facturat anualment'
  },
  common: {
    back: 'Enrere',
    cancel: 'cancel · lar',
    canceling: 'Cancel·lant...',
    clearAll: 'Esborra-ho tot',
    edit: 'Edita',
    email: 'Correu electrònic',
    errorMsg:
      "S'ha produït un error en processar %{name}. Per a consultes, poseu-vos en contacte amb suport.",
    errorTryAgain: 'Error. Torna-ho a provar',
    genericError:
      'Error, el procés ha fallat. Per a consultes, poseu-vos en contacte amb el suport.',
    loading: 'Carregant ...',
    login: 'iniciar Sessió',
    logout: 'Tancar sessió',
    new: 'Nou',
    next: 'Següent',
    no: 'No',
    noFileChosen: "No s'ha seleccionat cap fitxer",
    ok: "D'acord",
    required: 'Obligatori',
    reset: 'Restablir la contrasenya',
    restart: 'Reinicia',
    selectFile: 'Seleccioneu Fitxer',
    sort: 'Ordena',
    start: 'Començar',
    today: 'Avui',
    uploading: "S'està pujant…",
    yes: 'Sí'
  },
  confirmModal: {
    description: 'Estàs segur?',
    title: 'Confirma'
  },
  dataIntegration: {
    deleteFile: 'Esborrar Arxiu',
    featureNotAvailable:
      'Aquesta funció no està disponible al vostre pla actual. Actualitza a un altre pla per tenir aquesta funció. Contacta amb nosaltres per a més informació',
    notSideView:
      'La integració de dades no està disponible en projectes de vídeo que continguin un fitxer de vídeo de televisió o gran angular que no sigui de futbol.',
    open: 'Obrir Integració de dades',
    processJobsFirst:
      "Heu de començar a processar les dades de tracking automatic (ATD) abans d'accedir al panell d'integració de dades",
    processed: 'Integració de dades processades',
    selectFile: 'Seleccioneu Fitxer',
    startProcess: 'Inicia el procés',
    title: 'Integració de dades',
    waiting: "Procés d'espera...",
    waitingProcess:
      "El procés d'integració començarà un cop finalitzats els processos de detecció de jugadors i camp."
  },
  deleteModal: {
    checkboxLabel: 'També vull deixar de rebre correus electrònics de Metrica Sports',
    confirmationType: 'Escriviu %{validateFormKey} per confirmar:',
    deleteAction: 'Sí, esborra-ho',
    description: 'Estàs segur que vols suprimir el teu compte permanentment?',
    information:
      'Perdràs tots els projectes de vídeo creats amb aquest compte. No els podrem recuperar un cop els suprimiu.',
    keepAccount: 'Mantenir el compte',
    suggestion: 'Us suggerim que mantingueu el vostre compte.',
    title: 'Esborrar per sempre?',
    validateFormKey: 'SUPRIMEIX'
  },
  error: {
    oops: 'Vaja!',
    restart: 'Sembla que estàs intentant entrar en alguna cosa que encara estem desenvolupant...'
  },
  eventsDetection: {
    automaticVp: "Smart Tagging no disponible en Projectes de Vídeo amb una versió anterior d'ATD",
    needsAtd: 'Inicia primer el procés ATD per poder executar el procés de Smart Tagging.',
    needsFootballSideView:
      "Smart Tagging no disponible en Projectes de Vídeo que continguin un arxiu de vídeo que no sigui de futbol des d'un punt de vista ampli o retransmès per televisió",
    needsGameVp: 'Smart Tagging només està disponible en Projectes de Vídeo de Partit.',
    needsNoPlaylist:
      'Smart Tagging no disponible en Projectes de Vídeo que continguin un arxiu de vídeo que sigui una llista de reproducció'
  },
  forgotPassword: {
    adminNoResetPassword:
      "Els usuaris administradors han d'utilitzar un punt final privat per restablir la contrasenya",
    notFound: 'Usuari no trobat. Si us plau, torna-ho a provar amb un correu electrònic vàlid',
    success: 'Contrasenya restablerta! Si us plau, comproveu el vostre correu electrònic'
  },
  login: {
    contactUs: 'Contactar amb nosaltres',
    enterYourEmail: 'Introdueixi la seva adreça de correu electrònic',
    enterYourPassword: 'Introdueix la contrasenya',
    forgotPassword: 'Has oblidat la contrasenya?',
    invalidEmail: 'Adreça de correu invàlida',
    licenceExpired: 'La llicència ha caducat.',
    trialExpired: 'El Trial ha caducat.',
    upgradePlan: 'Actualitza el teu pla'
  },
  patterns: {
    delete: 'Suprimeix el fitxer de patrons',
    title: 'Fitxer de patrons personalitzat',
    upload: 'Carregueu un fitxer de patrons personalitzat',
    uploaded: "S'ha carregat el fitxer de patrons",
    uploading: "S'està carregant un fitxer de patrons"
  },
  plans: {
    academic: 'Acadèmic',
    addMoreProducts: '+ Afegir més productes',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Mensual',
    basicCodePadYearly: 'Basic+Code Anual',
    basicMftCodePadMonthly: 'Full Basic Mensual',
    basicMftCodePadYearly: 'Full Basic Anual',
    basicMftMonthly: 'Basic+MFT Mensual',
    basicMftXmlMonthly: 'Basic MFT + XML Mensual',
    basicMftXmlYearly: 'Basic MFT + XML Anual',
    basicMftYearly: 'Basic+MFT Anual',
    basicMonthly: 'Basic Mensual',
    basicXmlMonthly: 'Basic XML Mensual',
    basicXmlYearly: 'Basic XML Anual',
    basicYearly: 'Basic Anual',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite Anual',
    eliteYearly: 'Elite Anual',
    essentialsCodeMonthly: 'Essentials + Code ∞ Mensual',
    essentialsCodeYearly: 'Essentials + Code ∞ Anual',
    essentialsMftCodeMonthly: 'Full Essentials Mensual',
    essentialsMftCodeYearly: 'Full Essentials Anual',
    essentialsMftMonthly: 'Essentials + MFT Mensual',
    essentialsMftYearly: 'Essentials + MFT Anual',
    essentialsMonthly: 'Essentials Mensual',
    essentialsYearly: 'Essentials Anual',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Mensual',
    liteYearly: 'Lite Anual',
    minutes10: 'Pack de 10 Minuts de Cloud',
    minutes100: 'Pack de 100 Minuts de Cloud',
    minutes1000: 'Pack de 1.000 Minuts de Cloud',
    minutes10000: 'Pack de 10.000 Minuts de Cloud',
    plan: 'Pla %{planName}',
    primeMftMonthly: 'Prime + MFT Mensual',
    primeMftYearly: 'Prime + MFT Anual',
    primeMonthly: 'Prime Mensual',
    primePresetsMftMonthly: 'Full Prime Mensual',
    primePresetsMftYearly: 'Full Prime Anual',
    primePresetsMonthly: 'Prime + Presets Mensual',
    primePresetsYearly: 'Prime + Presets Anual',
    primeYearly: 'Prime Anual',
    pro: 'Pro',
    pro2024Monthly: 'Pro Mensual',
    pro2024Yearly: 'Pro Anual',
    proYearly: 'Pro Anual',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Mensual',
    starterCodePadMonthly: 'Code Mensual',
    starterCodePauseMonthly: 'Full Starter Mensual',
    starterCodePauseYearly: 'Full Starter Anual',
    starterCodeYearly: 'Starter + Code ∞ Anual',
    starterPauseMonthly: 'Starter + Pause ∞ Mensual',
    starterPauseYearly: 'Starter + Pause ∞ Anual',
    student: 'Estudiant',
    trial: 'Prova'
  },
  playlist: {
    download: 'Descarrega el fitxer de la llista de reproducció',
    notProcessed:
      "L'arxiu de la llista de reproducció no s'ha processat en aquest projecte de vídeo.",
    processed: "S'ha processat el fitxer de la llista de reproducció",
    title: 'Llista de reproducció',
    titleDownload: 'Descarrega la llista de reproducció'
  },
  products: {
    addOns: 'Complements',
    basic: 'Còdigs i Anotacions Il·limitats',
    code: 'CodePad',
    elite: {
      description:
        'Automatitza i personalitza tot el teu flux de treball d\'anàlisi. Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Full Essentials" i accés a Play3 amb totes les característiques del Pla "Pro".',
      feature1: '✓ Emmagatzematge al Cloud compartit',
      feature2: '✓ Integració de Dades de Tracking',
      feature3: '✓ Tracking Correction Manager',
      feature4: '✓ RESTful API',
      feature5: "✓ Paquet de 5.000 minuts d'ATD"
    },
    essentials: {
      addon1: 'Funcionalitats de Coding il·limitades (CodePad, Coding en Viu i Importació XML)',
      addon2: 'Tracking de Camp Manual',
      essentialsCodeDescription:
        'Aquest pla habilita les Visualitzacions Dinàmiques disponibles per afegir anotacions en moviment il·limitades en reproduir el vídeo i en pauses. Aquest pla no inclou Tracking de Camp Manual, per tant, les visualitzacions de Velocitat i Distància no estan incloses. També desbloqueja la limitació de característiques de coding com CodePad, Coding en Viu i Importació XML i allibera el límit de 4 codis per Projecte de Vídeo a codis il·limitats.',
      essentialsDescription:
        'Aquest pla habilita les Visualitzacions Dinàmiques disponibles per afegir anotacions en moviment il·limitades en reproduir el vídeo i en pauses. Aquest pla no inclou Tracking de Camp Manual, per tant, les visualitzacions de Velocitat i Distància no estan incloses. Les característiques de coding romanen limitades.',
      essentialsMftCodeDescription:
        'Aquest pla habilita les Visualitzacions Dinàmiques disponibles per afegir anotacions en moviment il·limitades en reproduir el vídeo i en pauses, i també activa el Tracking de Camp Manual per afegir visualitzacions de Velocitat i Distància. A més, desbloqueja la limitació de característiques de coding com CodePad, Coding en Directe i Importació XML i allibera el límit de 4 codis per Projecte de Vídeo a codis il·limitats.',
      essentialsMftDescription:
        'Aquest pla habilita les Visualitzacions Dinàmiques disponibles per afegir anotacions en moviment il·limitades en reproduir el vídeo i en pauses, i també activa el Tracking de Camp Manual per afegir visualitzacions de Velocitat i Distància. Les característiques de coding romanen limitades.',
      feature1: '✓ Codificació post-partit (Code Table amb fins a 4 codis)',
      feature2: '✓ Visualitzacions il·limitades en pausa',
      feature3: '✓ Telestració dinàmica',
      feature4: "✓ Paquet de 3 minuts d'ATD"
    },
    featuresIncluded: 'Característiques incloses:',
    freeFeature1: '✓ Codificació post-partit (Code Table amb fins a 4 codis).',
    freeFeature2: '✓ Keyframing (Tracking manual)',
    freeFeature3: '✓ Telestració (fins a 150 anotacions)',
    freeFeature4: '✓ Exportació de vídeo',
    freeFeatures: 'Característiques Gratüites',
    fullBasic: 'Tracking de Camp Manual',
    lite: 'Tracking Automàtic de Jugadors i Camp',
    liteDescription:
      'Coding avançat i visualitzacions avançades. Tot automàtic, fent-ho 3 vegades més ràpid que Full Basic!',
    minutesDescription:
      'Recarrega qualsevol pla. Aconsegueix paquets de minuts per a Dades de Tracking Automatitzat (ATD)',
    minutesPack: 'Pack de Minuts de Cloud',
    otherDescription:
      'Funcionalitats de coding avançades amb telestració bàsica i anotacions limitades.',
    prime: {
      addon1: 'Seguiment de Camp Manual',
      addon2:
        'Visualitzacions Personalitzades (personalitza i guarda les teves pròpies visualitzacions)',
      feature1: '✓ Accés complet a Play2 amb totes les característiques del Pla "Full Essentials"',
      feature2: '✓ Accés a Play3 amb:',
      feature2_1: 'Pausas seqüencials',
      feature2_2: 'Visualitzacions avançades',
      feature2_3: 'Exportació de vídeos personalitzada',
      feature3: "✓ Paquet de 10 minuts d'ATD",
      primeDescription:
        'Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Essentials + Code ∞" i habilita Play3 amb telestracions avançades.',
      primeMftDescription:
        'Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Full Essentials" i habilita Play3 amb telestracions avançades.',
      primePresetsDescription:
        'Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Essentials + Code ∞" i habilita Play3 amb telestració avançada i visualitzacions personalitzades.',
      primePresetsMftDescription:
        'Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Full Essentials" i habilita Play3 amb telestració avançada i visualitzacions personalitzades.'
    },
    pro: {
      description:
        'Aquest pla inclou accés complet a Play2 amb totes les característiques del Pla "Full Essentials" i accés a Play3 amb totes les característiques del Pla "Full Prime".',
      feature1: '✓ Seguiment Automàtic de Jugadors',
      feature2: '✓ Seguiment Automàtic del Camp',
      feature3: '✓ Accés complet a Play2 amb totes les característiques del Pla "Full Essentials"',
      feature4: '✓ Accés a Play3 amb:',
      feature4_1: 'Pausas seqüencials',
      feature4_2: 'Visualitzacions avançades',
      feature4_3: 'Exportació de vídeos personalitzada',
      feature4_4:
        'Visualitzacions Personalitzades (personalitza i guarda les teves pròpies visualitzacions)',
      feature5: "✓ Paquet de 100 minuts d'ATD"
    },
    remove: 'Eliminar',
    starter: {
      addon1: 'Visualitzacions il·limitades en pausa',
      addon2: 'Característiques de Coding il·limitades (CodePad, Coding en Viu i Importació XML)',
      feature1: '✓ Codificació post-partit (Code Table amb fins a 4 codis)',
      feature2: '✓ Telestració en pauses (fins a 50 anotacions per mes)',
      feature3: '✓ Exportació de vídeos',
      starterCodeDescription:
        'Aquest pla desbloqueja la limitació de característiques de coding com CodePad, Coding en Viu i Importació XML. També allibera el límit de 4 codis per Projecte de Vídeo a codis il·limitats. Les limitacions en les característiques de visualització romanen en Free Starter.',
      starterCodePauseDescription:
        'Aquest pla desbloqueja la limitació de característiques de coding com CodePad, Coding en Viu i Importació XML. També allibera el límit de 4 codis per Projecte de Vídeo a codis il·limitats, i desbloqueja la limitació de telestracions en pauses de 50 per mes a il·limitades.',
      starterPauseDescription:
        'Aquest pla desbloqueja la limitació de telestracions en pauses de 50 per mes a il·limitades. Les limitacions en les característiques de coding romanen en Free Starter.'
    },
    totalPack: 'Total: %{currency} %{cost}',
    totalPlan: 'Total: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Facturació',
    billingSettings: 'Configuració de facturació',
    billingSettingsDescription: '(canviar el pla, actualitzar les formes de pagament...)',
    cancelAt: 'El teu pla es cancel·la el',
    cancelPlan: 'Cancel·lar pla',
    confirmNewPassword: 'Confirmar nova contrasenya',
    deleteAccount: 'Esborrar compte',
    details: 'Els meus detalls',
    emailNotActivated: 'La notificació per correu electrònic està activada',
    emailNotDeactivated: 'La notificació per correu electrònic està desactivada',
    emailNotifications: 'Notificacions per correu electrònic',
    language: 'Idioma',
    languageChanged: "S'ha actualitzat l'idioma",
    newPassword: 'Nova contrasenya',
    organizationLabel: "Prefix de l'organització",
    organizationTooltip:
      'Aquest prefix és necessari per importar patrons personalitzats a un projecte de vídeo. Poseu-vos en contacte amb nosaltres per obtenir més informació o consulteu la documentació a codeball.metrica-sports.com',
    passwordsDontMatch: 'Les contrasenyes no coincideixen',
    pauseAt: 'El teu pla es pausa el.',
    planCanceled:
      "El teu pla s'ha cancel·lat. Consulteu el vostre correu electrònic per obtenir més informació",
    plans: 'Plans',
    quantity: 'Quantitat :',
    renewAt: 'El teu pla es renova el',
    title: 'Configuració',
    upgradePlan: 'Actualitza el Pla',
    userKey: "Clau d'usuari:",
    userKeyUsages: '%{current}/%{max} usos',
    userSettings: "Configuració d'usuari"
  },
  shoppingCart: {
    subtitle: 'Trieu el vostre pla de Play i complements per elevar el vostre rendiment.',
    title: 'Configura el teu Play.'
  },
  subscription: {
    cancelSubscription: 'Cancel·lar subscripció',
    errorUpdating:
      "Hi ha hagut un error en actualitzar la teva subscripció. Si us plau, envia un correu electrònic a support@metrica-sports.com i estarem encantats d'ajudar-te",
    free: 'Gratuït',
    isObsolete:
      'La teva subscripció no pot ser modificada. Reemplaça els productes obsolets per uns de nous per habilitar els canvis',
    licenseKeys: 'Claus de llicència',
    pendingRefundRequest:
      'No es poden realitzar més canvis en aquesta subscripció mentre hi hagi una sol·licitud de reemborsament pendent',
    undoCancel: 'Desfer cancel·lació de subscripció',
    undoPause: 'Desfer pausa de subscripció',
    updatePaymentMethod: 'Actualitzar mètode de pagament',
    updatedSuccessfully: 'Subscripció actualitzada amb èxit.',
    yourPlans: 'Els teus Plans',
    yourSubscription: 'La teva subscripció'
  },
  successfulPayment: {
    activateLicence:
      'Activa la teva llicència o crèdit de Cloud introduint la clau de llicència proporcionada.',
    allTheBest: 'Tot el millor,',
    applyKey: 'Aplicar clau a aquest compte.',
    applyKeyDescription:
      'Voldries aplicar la clau de llicència al compte on estàs connectat automàticament?',
    downloadPlay: '%{pre}Descarrega PLAY%{post} a través del nostre lloc web.',
    faq: 'Per ajudar-te a començar amb la teva comanda, consulta les %{pre}Preguntes Freqüents%{post} a la nostra web. Aquí tens un resum ràpid de la informació que pot ser més rellevant per a tu en aquesta etapa:',
    goToSummary:
      "Si no, si us plau, consulta el resum a continuació per conèixer les instruccions d'activació.",
    happyPlay:
      'Esperem que tot estigui clar, però si us plau no dubteu a contactar-nos si teniu qualsevol pregunta o dubte. Feliç PLAY!',
    helpDesk:
      "Consulta el nostre %{pre}HelpDesk%{post} per a obtenir informació general sobre l'ús de PLAY.",
    keyApplied: 'Clau aplicada',
    licenseKey: 'La teva clau de llicència és: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: "L'equip de Metrica Sports",
    settingsCloud:
      'Visita el nostre %{pre}Cloud%{post} i navega fins al menú de configuració per a:',
    technicalSupport:
      'Per a suport tècnic, omple el formulari de contacte disponible a PLAY/Cloud, o envia un correu electrònic a %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      "Si us plau, tingues en compte que les nostres subscripcions es renoven automàticament fins que es cancel·len explícitament a través de la configuració en %{pre}Cloud%{post}, d'acord amb els nostres Termes i Condicions.",
    thanks:
      "Gràcies pel teu comanda! L'hem processat amb èxit i adjuntem les teves claus de llicència a continuació.",
    title: 'Confirmació de la Comanda',
    updatePayment: 'Actualitza el teu mètode de pagament.',
    updateSubscription: 'Cancel·la o modifica la teva subscripció.'
  },
  surfaces: {
    automaticVp:
      "Les superfícies no estan disponibles als projectes de vídeo amb una versió anterior d'ATD",
    delete: 'Suprimeix el fitxer Surface',
    needsAtd: 'Inicieu primer el procés ATD per executar els processos de Surface',
    needsElite:
      'Les superfícies personalitzades estan disponibles al pla Elite. Contacta amb nosaltres per a més informació',
    needsFootballSideView:
      'Les superfícies no estan disponibles als projectes de vídeo que continguin un fitxer de vídeo de televisió o gran angular que no sigui de futbol',
    pitchControl:
      'En executar aquest procés us permetrà afegir la visualització de "Pitch control" a Play.',
    pitchControlProcessed: 'Superfície de Pitch control processada',
    pitchControlProcessing: 'Processant Pitch Control',
    processed: 'Arxiu de Superficie pujat',
    processing: 'Processant fitxer de superfície',
    title: 'Superfícies',
    titlePitchControl: 'Pitch Control',
    upload: 'Carregueu la vostra propia Superficie',
    uploading: 'Carregant Superficie...'
  },
  trackingFifaFormat: {
    download: 'Baixeu el fitxer en format FIFA EPTS',
    needsElite:
      "Aquesta funció només està disponible després d'importar els fitxers corresponents al mòdul d'integració de dades, projectes de vídeo amb dades de tracking automatitzat o projectes de vídeo al pla Elite.",
    processed: 'Format FIFA EPTS processat',
    start:
      'Inicieu el procés per generar un fitxer en format FIFA EPTS. Aleshores podreu descarregar el fitxer',
    title: 'Format FIFA EPTS',
    titleDownload: 'Descarrega el format FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: "Aconsegueix el paquet de minuts de Cloud Engine que millor s'adapti a tu:",
    minutePackLinks: {
      minutes_10: 'Aconsegueix 10 minuts per 10 €',
      minutes_100: 'Aconsegueix 100 minuts per 90 €',
      minutes_1000: 'Aconsegueix 1.000 minuts per 750 €',
      minutes_10000: 'Aconsegueix 10.000 minuts per 5.000 €'
    },
    noMinutes: {
      description:
        'No tens prou minuts disponibles per crear aquest projecte de vídeo. Saps que pots obtenir paquets de minuts sigui quin sigui el teu pla?',
      secondDescription: '',
      title: 'Aconsegueix més minuts per crear un projecte de vídeo!'
    },
    notEnoughMinutes: {
      description:
        'Lamentem fer-te saber que no tens prou minuts per processar ATD als teus projectes de vídeo.',
      secondDescription:
        "Si voleu processar ATD, haureu d'afegir més minuts al vostre compte. Els minuts que obtindràs no caduquen!",
      title: 'No hi ha prou minuts disponibles'
    }
  },
  video: {
    cancelUpload: 'Cancel·la la càrrega',
    delete: 'Suprimeix el vídeo',
    download: 'Descarrega el vídeo',
    title: 'Arxiu de vídeo',
    upload: 'Puja vídeo',
    uploaded: 'Vídeo penjat',
    uploading: 'Carregant vídeo'
  },
  videoProject: {
    delete: 'Suprimeix el projecte de vídeo',
    edit: 'Edita el projecte de vídeo',
    processWaitingJobs: 'Processar treballs en espera',
    title: 'Projecte de vídeo'
  }
}
