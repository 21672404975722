import EnglishTranslation from './en'
import SpanishTranslation from './es'
import PortugueseTranslation from './pt'
import ArabicTranslation from './ar'
import ChineseTranslation from './zh'
import JapaneseTranslation from './ja'
import FrenchTranslation from './fr'
import TurkishTranslation from './tr'
import PolishTranslation from './pl'
import ItalianTranslation from './it'
// import GermanTranslation from './de'
import RussianTranslation from './ru'
import DutchTranslation from './nl'
import HebrewTranslation from './he'
import CatalanTranslation from './ca'
// import IndonesianTranslation from './id'

const English = { locale: 'en', phrases: EnglishTranslation }
const Spanish = { locale: 'es', phrases: SpanishTranslation }
const Portuguese = { locale: 'pt', phrases: PortugueseTranslation }
const Arabic = { locale: 'ar', phrases: ArabicTranslation, rtl: true }
const Chinese = { locale: 'zh', phrases: ChineseTranslation }
const Japanese = { locale: 'ja', phrases: JapaneseTranslation }
const French = { locale: 'fr', phrases: FrenchTranslation }
const Turkish = { locale: 'tr', phrases: TurkishTranslation }
const Polish = { locale: 'pl', phrases: PolishTranslation }
const Italian = { locale: 'it', phrases: ItalianTranslation }
// const German = { locale: 'de', phrases: GermanTranslation }
const Russian = { locale: 'ru', phrases: RussianTranslation }
const Dutch = { locale: 'nl', phrases: DutchTranslation }
const Hebrew = { locale: 'he', phrases: HebrewTranslation, rtl: true }
const Catalan = { locale: 'ca', phrases: CatalanTranslation }
// const Indonesian = { locale: 'id', phrases: IndonesianTranslation }

export {
  English,
  Spanish,
  Portuguese,
  Arabic,
  Chinese,
  Japanese,
  French,
  Turkish,
  Polish,
  Italian,
  // German,
  Russian,
  Dutch,
  Hebrew,
  Catalan
  // Indonesian
}
