export default {
  addKeyModal: {
    action: 'Appliquer',
    errorMessage:
      "Erreur d'application de la clé. Si le problème persiste, contactez l'équipe d'assistance de Metrica Sports",
    minutesMessage: 'Votre clé a été traitée. Vous avez maintenant %{minutes} minutes disponibles.',
    placeholder: 'Tapez une clé...',
    successMessage: 'La clé a été traitée avec succès',
    title: 'Ajouter une clé'
  },
  admin: {
    NoGames: 'Pas de matchs, ajoutez votre premier...',
    NoResults: 'Aucun résultat...',
    addKey: 'Ajouter une clé',
    atd: 'ATD',
    automatedTrackingData: 'Données de tracking automatisé (ATD)',
    automatedTrackingDataJobSuccess:
      'Le traitement des données de tracking automatisé de %{vpName} est terminé et est désormais disponible dans Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Données de détection automatisé traitées',
    awayTeam: "L'équipe visiteuse",
    awayTeamScore: 'Score par équipe visiteuse',
    ballDetection: 'Détection de balle',
    ballTracking: 'Détection du ballon',
    ballTrackingProcessedTooltip: 'Détection du ballon traitée',
    ballTrackingTooltip:
      'Ce processus détectera le ballon et vous permettra de le visualiser dans la formation en direct (terrain 2D) dans Play. Les coordonnées du ballon seront également disponibles dans le fichier au format FIFA EPTS.',
    cancelATDProcessSuccessfully:
      'Le traitement des données de tracking automatisé a été annulé pour %{vpName}',
    cancelDataIntegrationProcessSuccessfully:
      "Le processus d'intégration des données a été annulé pour %{vpName}",
    cancelEventsProcessSuccessfully: 'Le processus de Smart Tagging a été annulé sur %{vpName}.',
    cancelProcessTooltip: "Procédure d'annulation",
    cancelSurfacesProcessSuccessfully: 'Le traitement de superficie a été annulé le %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Le processus de Tracking au format FIFA a été annulé pour %{vpName}',
    category: 'Catégorie',
    checking: 'Vérification...',
    cloudMinutes: 'Minutes Cloud',
    competition: 'Compétition',
    connectionFailed: 'Échec de la connexion',
    connectionFailedText:
      'Il semble y avoir un problème lors de la récupération des informations du statut. Vérifiez votre connexion Internet et rechargez la page. Si le problème persiste, veuillez contacter le support',
    correctedTracking: 'Tracking Correction traité',
    correctionUsage: 'Allez-vous utiliser ce projet vidéo pour le Tracking de corrections ?',
    correctionUsageTooltip:
      "Tracking Correction Manager (TCM) est un module orienté utilisateur intégré à Play qui permet de corriger les pistes précédemment générées avec notre ATD, qui génère automatiquement des données sans identifiant pour n'importe quel match de football. Utilisez TCM pour corriger les mauvaises pistes et leur attribuer une identité. Contactez-nous pour plus d'informations.",
    createVideoProject: 'Créer un projet vidéo',
    creatingGame: "Création d'un projet vidéo...",
    dataIntegrationFailed:
      "L'intégration des données a échoué pour %{vpName}. Accédez au panneau Intégration des données, vérifiez vos fichiers et réessayez. Si l'erreur persiste, veuillez contacter l'équipe de support Metrica Sports",
    dataIntegrationJobSuccess:
      "Le processus d'intégration des données pour %{vpName} s'est terminé avec succès",
    date: 'Date',
    description: 'Description',
    editedSuccessfully: '%{vpName} a été modifié avec succès',
    errorContactSupport: "Erreur. Contacter l'équipe de support",
    errorProcessTooltip: 'Erreur de traitement',
    eventsDetection: 'Étiquetage automatique',
    eventsDetectionProcessedTooltip: 'Smart Tagging traité',
    eventsDetectionTooltip: {
      footer: '*Disponible pour des vidéos de plus de 30 minutes.',
      header:
        "Profitez de notre technologie pour détecter automatiquement une série d'événements qui se produisent dans le match*.",
      improved: 'Amélioré'
    },
    fieldRecognition: 'Détection de terrain',
    fieldTrackingProcessedTooltip: 'Détection de terrain traitée',
    fieldTrackingTooltip:
      'Traiter la détection de terrain pour ajouter des visualisations avec des valeurs de vitesse et de distance et tous les dessins en fonction du terrain dans Play.',
    fileDeletedSuccessfully: '%{fileName} a été supprimé avec succès pour %{vpName}',
    fileUploadedError: 'Erreur lors du chargement de %{fileName} pour %{vpName}',
    fileUploadedSuccessfully: '%{fileName} chargé avec succès pour %{vpName}',
    forkedGameLimit:
      "Ce projet vidéo appartient à une autre organisation, il n'est donc pas possible de le modifier",
    gameDeletedSuccessfully: '%{game} supprimé avec succès',
    gameVideoProject: 'Projet de vidéo de match',
    getMoreMinutes: 'Obtenez-en plus',
    homeTeam: "L'équipe à domicile",
    homeTeamScore: "Score de l'équipe locale",
    initializing: 'Initialisation en cours...',
    jobFailed:
      "Le processus %{process} a échoué pour %{vpName}. Veuillez contacter l'équipe de support Metrica Sports",
    liveCoding: 'ou continuer pour le coding en direct ?',
    liveCodingTooltip:
      "Vous pouvez créer un projet vidéo sans sélectionner de fichier vidéo à des fins de Coding en direct. Une fois que vous avez la vidéo, vous pouvez l'ajouter pour avoir des données de tracking automatique dans le projet vidéo codé.",
    localTeamOnTheLeft:
      "L'équipe locale est-elle située sur le côté gauche du terrain en première mi-temps ?",
    localTeamOnTheLeftTooltip:
      "Afin de traiter l'étiquetage automatique, spécifiez de quel côté du terrain se trouve chaque équipe. Regardez la vidéo que vous mettez en ligne pour la valider.",
    noFootballSideViewTooltip:
      "Ce projet vidéo contient un fichier vidéo qui n'est pas une vidéo de football en grand angle ou en retransmission TV, de sorte que seule la détection des joueurs et des équipes a été traitée.",
    noMatches: 'Aucune correspondance...',
    noSubscriptionOwnerTooltip:
      "Contactez '%{email} ', l'administrateur de votre abonnement, pour effectuer une mise à niveau ou rendez-vous sur notre page Plans pour créer le vôtre",
    noVideoFile: 'Aucun fichier vidéo',
    notPossibleToDeleteThisEntry: 'Impossible de supprimer cette entrée',
    numberOfHalves: 'Combien de périodes ce match a-t-il ?',
    numberOfHalvesTooltip:
      "Afin de traiter l'étiquetage automatique, spécifiez le nombre de périodes du match incluses dans la vidéo que vous mettez en ligne.",
    oldAtdTooltip: "Cette vidéo a été traitée avec une ancienne version d'ATD",
    onRequest: 'Sur demande',
    organizations: 'Organisations',
    place: 'Lieu',
    playerTracking: 'Détection des joueurs et des équipes',
    playerTrackingProcessedTooltip: 'Détection des joueurs traitée',
    playerTrackingTooltip:
      "Ce processus détectera les gardiens de but et les 10 joueurs de terrain de chaque équipe. Cela vous permettra de visualiser la formation en direct (terrain 2D) et d'ajouter automatiquement des visualisations de joueurs dans Play. Les coordonnées des joueurs seront également disponibles dans le fichier au format FIFA EPTS.",
    postProcessingData: 'Post-traitement des données...',
    preProcessingAtd: 'Pré-traitement...',
    processingTooltip: 'Traitement',
    readyToUpload: 'Prêt à être envoyé',
    search: 'Rechercher…',
    selectVideoFile: 'Sélectionnez un fichier vidéo',
    settingUpAtd: 'Configuration de la détection...',
    settings: 'Paramètres',
    singleVideoProject: 'Projet vidéo simple',
    startAtd: 'Démarrez ATD',
    startFifaMessage:
      "Le processus d'intégration de FIFA pour %{vpName} a commencé. Nous afficherons une notification une fois que c'est terminé. Veuillez patienter.",
    startProcessingWaitingJobsFailed: "Échec de l'envoi des tâches",
    startProcessingWaitingJobsSuccessfully: 'Travaux en attente envoyés avec succès',
    startSsMessage:
      "Le processus d'intégration de Second Spectrum %{vpName} a commencé. Nous afficherons une notification une fois que c'est terminé. Veuillez patienter.",
    startStatsMessage:
      "Le processus d'intégration de STATS pour %{vpName} a commencé. Nous afficherons une notification une fois que c'est terminé. Veuillez patienter.",
    startTracabDflMessage:
      "Le processus d'intégration Tracab DFL pour %{vpName} a commencé. Nous afficherons une notification une fois que c'est terminé. Veuillez patienter.",
    startTracabMessage:
      "Le processus d'intégration de Tracab pour %{vpName} a commencé. Nous afficherons une notification une fois que c'est terminé. Veuillez patienter.",
    surfacesJobSuccess: "Le processus de superficie %{vpName} s'est terminé avec succès",
    trackingCorrectionProcessedTooltip: 'Tracking Correction traité',
    trackingFifaFormatJobSuccess:
      "Le processus de détection au format FIFA pour %{vpName} s'est terminé avec succès. Vous pouvez maintenant télécharger le fichier en cliquant sur l'élément de menu Télécharger le format FIFA",
    trackingLicence: "Vous n'avez pas de licence de Tracking",
    uncaughtErrorRow:
      'Erreur du projet vidéo. Veuillez actualiser cette page. Si le problème persiste, contactez le support ou supprimez-le et créez-le à nouveau.',
    updatingGame: 'Mise à jour du projet vidéo...',
    upgradePlan: 'Mettre à jour',
    uploadAndTracking: 'Chargement et Tracking',
    uploadingVideoFile: "Chargement d'un fichier vidéo",
    videoFile: 'Fichier vidéo',
    videoFootballSideView:
      'Ce fichier vidéo est-il une vidéo de football en grand-angle ou en retransmission TV ?',
    videoFootballSideViewTooltip:
      'La détection du terrain est optimisée pour ce type de vidéos uniquement.',
    videoMultipartStart:
      '%{videoName} est en cours de téléchargement sur %{vpName}. NE FERMEZ PAS CETTE PAGE avant la fin du chargement',
    videoPlaylist: 'Ce fichier vidéo est-il une liste de lecture ?',
    videoPlaylistTooltip:
      "Si vous sélectionnez OUI, la détection de clips sera exécutée pour reconnaître quand une coupure a lieu dans la vidéo, ce qui en fait un outil parfait si vous téléchargez une vidéo contenant plusieurs clips, par exemple. Après avoir exécuté ce processus, téléchargez le projet vidéo dans Play et un fichier de codage Clip Detection s'y trouvera avec un code incluant chaque coupure en tant qu'événement.",
    videoProjectSettings: 'Paramètres du projet vidéo',
    videoUploadError:
      'Le chargement de la vidéo de %{videoName} pour %{vpName} a échoué. Veuillez réessayer',
    videoUploaded: '%{videoName} a été chargé sur %{vpName}',
    vpCreated: '%{vpName} a été créé',
    waitingAtd: 'En attente…'
  },
  billing: {
    address: 'Adresse',
    billed: 'Facturé',
    completed: 'Payé',
    details: 'Détails de Facturation',
    history: 'Historique de Facturation',
    invoiceNotFound: 'Facture non trouvée',
    noInvoices: 'Pas de factures',
    past_due: 'Échu',
    taxId: "Numéro d'identification fiscale"
  },
  checkout: {
    chargeSubscription: "Facturer à l'abonnement",
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*Les codes de réduction peuvent être appliqués lors du paiement.',
    excTax: '(Hors taxes)',
    immediatePayment: 'Paiement Immédiat',
    incTax: '(TTC)',
    monthlyBilled: 'Facturé mensuellement',
    nextPayment: 'Prochain Paiement',
    noPacks: 'Sans Pack de Minutes Cloud',
    onDate: 'le %{date}',
    packs: 'Packs',
    packsTotal: 'Packs Total',
    perMonth: '/mois',
    perYear: '/année',
    plans: 'Plans',
    plansTotal: 'Total des Plans',
    proceed: 'Procéder au paiement',
    subtotal: 'Sous-total',
    taxPayable: 'Impôts à payer (%{percentage}%)',
    total: 'Total',
    unpaid:
      "Votre dernière facture n'a pas encore été payée. Veuillez mettre à jour vos informations de paiement pour continuer.",
    updatePaymentMethod: 'Mettre à jour la méthode de paiement',
    updateSubscription: "Mettre à jour l'abonnement",
    userDevice: '1 utilisateur/appareil',
    usersDevices: '%{number} utilisateurs/appareils',
    yearlyBilled: 'Facturé annuellement'
  },
  common: {
    back: 'Retour',
    cancel: 'Annuler',
    canceling: 'Annulation…',
    clearAll: 'Tout effacer',
    edit: 'Éditer',
    email: 'Email',
    errorMsg:
      'Erreur lors du traitement de %{name}. Pour toute demande, veuillez contacter le support.',
    errorTryAgain: 'Erreur. Essayez à nouveau',
    genericError:
      'Erreur, le processus a échoué. Pour toute demande, veuillez contacter le support.',
    loading: 'Chargement…',
    login: 'Connexion',
    logout: 'Déconnexion',
    new: 'Nouveau',
    next: 'Suivant',
    no: 'Non',
    noFileChosen: 'Aucun fichier choisi',
    ok: 'Valider',
    required: 'Obligatoire',
    reset: 'Réinitialiser le mot de passe',
    restart: 'Redémarrer',
    selectFile: 'Sélectionner un fichier',
    sort: 'Trier',
    start: 'Commencez',
    today: "Aujourd'hui",
    uploading: 'Chargement en cours...',
    yes: 'Oui'
  },
  confirmModal: {
    description: 'Êtes vous sûr ?',
    title: 'Confirmer'
  },
  dataIntegration: {
    deleteFile: 'Supprimer le fichier',
    featureNotAvailable:
      "Cette fonctionnalité n'est pas disponible dans votre forfait actuel. Vous devez passer à un autre forfait pour bénéficier de cette fonctionnalité. Contactez-nous pour plus d'informations",
    notSideView:
      "L'intégration des données n'est pas disponible pour les projets vidéo dont le fichier vidéo n'est pas une vidéo de football en grand angle ou en retransmission TV.",
    open: "Ouvrir l'intégration des données",
    processJobsFirst:
      "Vous devez commencer à traiter les données de tracking automatisé (ATD) avant d'accéder au panneau d'intégration des données",
    processed: 'Intégration des données traitée',
    selectFile: 'Sélectionner un fichier',
    startProcess: 'Démarrage du processus',
    title: 'Intégration des données',
    waiting: "Processus d'attente...",
    waitingProcess:
      "Le processus d'intégration commencera une fois que les processus de détection des joueurs et des champs seront terminés."
  },
  deleteModal: {
    checkboxLabel: "Je souhaite également ne plus recevoir d'e-mails de Metrica Sports",
    confirmationType: 'Tapez %{validateFormKey} pour confirmer :',
    deleteAction: 'Oui, supprimez-le',
    description: 'Êtes-vous sûr de vouloir supprimer définitivement votre compte ?',
    information:
      'Vous perdrez tous les projets vidéo créés avec ce compte. Nous ne pouvons pas les récupérer une fois que vous les supprimez.',
    keepAccount: 'Garder le compte',
    suggestion: 'Nous vous conseillons de conserver votre compte.',
    title: 'Supprimer pour toujours ?',
    validateFormKey: 'SUPPRIMER'
  },
  error: {
    oops: 'OUPS!',
    restart:
      "On dirait que vous essayez d'utiliser quelque chose que nous sommes encore en train de développer..."
  },
  eventsDetection: {
    automaticVp:
      "Smart Tagging non disponible sur les Projets Vidéo avec une version antérieure d'ATD.",
    needsAtd:
      "Démarrez d'abord le processus ATD pour pouvoir exécuter le processus de Smart Tagging.",
    needsFootballSideView:
      "Smart Tagging non disponible sur les Projets Vidéo contenant un fichier vidéo qui n'est pas un match de football filmé en plan large ou diffusé à la télévision.",
    needsGameVp: 'Smart Tagging est uniquement disponible sur les Projets de Vidéo de Match.',
    needsNoPlaylist:
      "Le Smart Tagging n'est pas disponible sur les Projets Vidéo contenant un fichier vidéo qui est une playlist."
  },
  forgotPassword: {
    adminNoResetPassword:
      'Les utilisateurs administrateurs doivent utiliser un point de terminaison privé pour réinitialiser leur mot de passe',
    notFound: 'Utilisateur introuvable. Veuillez réessayer avec une adresse e-mail valide',
    success: 'Réinitialisation du mot de passe! Merci de consulter vos emails'
  },
  login: {
    contactUs: 'Contactez-nous',
    enterYourEmail: 'Entrer votre Email',
    enterYourPassword: 'Saisissez votre mot de passe',
    forgotPassword: 'Mot de passe oublié ?',
    invalidEmail: 'Adresse e-mail non valide',
    licenceExpired: 'Votre licence a expiré.',
    trialExpired: "Votre période d'essai a expiré.",
    upgradePlan: 'Mettez à jour votre plan'
  },
  patterns: {
    delete: 'Supprimer le fichier de modèles',
    title: 'Fichier de modèles personnalisés',
    upload: 'Télécharger un fichier de modèle personnalisé',
    uploaded: 'Fichier de Modèles chargé',
    uploading: 'Chargement du fichier Modèle'
  },
  plans: {
    academic: 'Académique',
    addMoreProducts: '+ Ajouter plus de produits',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Mensuel',
    basicCodePadYearly: 'Basic+Code Annuel',
    basicMftCodePadMonthly: 'Full Basic Mensuel',
    basicMftCodePadYearly: 'Full Basic Annuel',
    basicMftMonthly: 'Basic+MFT Mensuel',
    basicMftXmlMonthly: 'Basic MFT + XML Mensuel',
    basicMftXmlYearly: 'Basic MFT + XML Annuel',
    basicMftYearly: 'Basic+MFT Annuel',
    basicMonthly: 'Basic Mensuel',
    basicXmlMonthly: 'Basic XML Mensuel',
    basicXmlYearly: 'Basic XML Annuel',
    basicYearly: 'Basic Annuel',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite Annuel',
    eliteYearly: 'Elite Annuel',
    essentialsCodeMonthly: 'Essentials + Code ∞ Mensuel',
    essentialsCodeYearly: 'Essentials + Code ∞ Annuel',
    essentialsMftCodeMonthly: 'Full Essentials Mensuel',
    essentialsMftCodeYearly: 'Full Essentials Annuel',
    essentialsMftMonthly: 'Essentials + MFT Mensuel',
    essentialsMftYearly: 'Essentials + MFT Annuel',
    essentialsMonthly: 'Essentials Mensuel',
    essentialsYearly: 'Essentials Annuel',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Mensuel',
    liteYearly: 'Lite Annuel',
    minutes10: 'Pack de 10 Minutes Cloud',
    minutes100: 'Pack de 100 Minutes Cloud',
    minutes1000: 'Pack de 1.000 Minutes de Cloud',
    minutes10000: 'Pack de 10.000 Minutes Cloud',
    plan: 'Plan %{planName}',
    primeMftMonthly: 'Prime + MFT Mensuel',
    primeMftYearly: 'Prime + MFT Annuel',
    primeMonthly: 'Prime Mensuel',
    primePresetsMftMonthly: 'Full Prime Mensuel',
    primePresetsMftYearly: 'Full Prime Annuel',
    primePresetsMonthly: 'Prime + Presets Mensuel',
    primePresetsYearly: 'Prime + Presets Annuel',
    primeYearly: 'Prime Annuel',
    pro: 'Pro',
    pro2024Monthly: 'Pro Mensuel',
    pro2024Yearly: 'Pro Annuel',
    proYearly: 'Pro Annuel',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Mensuel',
    starterCodePadMonthly: 'Code Mensuel',
    starterCodePauseMonthly: 'Full Starter Mensuel',
    starterCodePauseYearly: 'Full Starter Annuel',
    starterCodeYearly: 'Starter + Code ∞ Annuel',
    starterPauseMonthly: 'Starter + Pause ∞ Mensuel',
    starterPauseYearly: 'Starter + Pause ∞ Annuel',
    student: 'Étudiant',
    trial: 'Trial'
  },
  playlist: {
    download: 'Télécharger le fichier de la liste de lecture',
    notProcessed: "Le fichier de playlist n'a pas été traité dans ce projet vidéo.",
    processed: 'Fichier de liste de lecture traité',
    title: 'Liste de lecture',
    titleDownload: 'Télécharger la liste de lecture'
  },
  products: {
    addOns: 'Extensions',
    basic: 'Codes et Annotations Illimités',
    code: 'CodePad',
    elite: {
      description:
        'Automatisez et personnalisez l\'ensemble de votre flux de travail d\'analyse. Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials" et un accès à Play3 avec toutes les fonctionnalités du Plan "Pro".',
      feature1: '✓ Stockage Cloud partagé',
      feature2: '✓ Intégration des Données de Tracking',
      feature3: '✓ Gestionnaire de Correction de Suivi',
      feature4: '✓ RESTful API',
      feature5: '✓ Pack de 5.000 minutes ATD'
    },
    essentials: {
      addon1: 'Fonctionnalités de Coding illimitées (CodePad, Coding en Direct et Importation XML)',
      addon2: 'Suivi Manuel de Terrain',
      essentialsCodeDescription:
        "Ce plan permet d'activer les Visualisations Dynamiques pour ajouter des annotations de mouvement illimitées lors de la lecture de la vidéo et pendant les pauses. Ce plan n'inclut pas le Tracking de Champ Manuel, donc les visualisations de Vitesse et Distance ne sont pas incluses. Il débloque également la limitation des fonctionnalités de coding telles que CodePad, le Coding en Direct et l'Importation XML et supprime la limite de 4 codes par Projet Vidéo pour passer à des codes illimités.",
      essentialsDescription:
        "Ce plan permet l'utilisation de la Téléstration Dynamique disponible pour ajouter des visualisations de mouvement illimitées lors de la lecture de la vidéo et pendant les pauses. Ce plan n'inclut pas le Tracking de Champ Manuel, donc les visualisations de Vitesse et Distance ne sont pas incluses. Les fonctionnalités de coding restent limitées.",
      essentialsMftCodeDescription:
        'Ce plan permet les Visualisations Dynamiques disponibles pour ajouter des annotations de mouvement illimitées lors de la lecture de la vidéo et lors des pauses, et active également le Tracking de Champ Manuel pour ajouter des visualisations de vitesse et de distance. Il débloque également la limitation des fonctionnalités de coding telles que CodePad, Coding en Direct et Importation XML et supprime la limite de 4 codes par Projet Vidéo pour un nombre illimité de codes.',
      essentialsMftDescription:
        "Ce plan permet d'activer les Visualisations Dynamiques disponibles pour ajouter des annotations de mouvement illimitées lors de la lecture de la vidéo et pendant les pauses, et active également le Tracking de Champ Manuel pour ajouter des visualisations de vitesse et de distance. Les fonctionnalités de coding restent limitées.",
      feature1: "✓ Codage post-match (Code Table avec jusqu'à 4 codes)",
      feature2: '✓ Téléstration illimitée en pause',
      feature3: '✓ Téléstration dynamique',
      feature4: '✓ Pack de 3 minutes ATD'
    },
    featuresIncluded: 'Fonctionnalités incluses :',
    freeFeature1: "✓ Codage post-match (Code Table avec jusqu'à 4 codes)",
    freeFeature2: '✓ Suivi manuel',
    freeFeature3: "✓ Téléstration (jusqu'à 150 annotations)",
    freeFeature4: '✓ Exportation de vidéo',
    freeFeatures: 'Fonctionnalités Gratuites',
    fullBasic: 'Suivi Manuel du Terrain',
    lite: 'Suivi Automatique des Joueurs et du Terrain',
    liteDescription:
      'Codage avancé et visualisations avancées. Tout automatique, le rendant 3 fois plus rapide que Full Basic !',
    minutesDescription:
      "Rechargez n'importe quel plan. Obtenez des packs de minutes pour les Données de Tracking Automatisé.",
    minutesPack: 'Pack de Minutes Cloud',
    otherDescription:
      'Fonctionnalités de coding avancées avec téléstration basique et annotations limitées.',
    prime: {
      addon1: 'Suivi Manuel de Terrain',
      addon2:
        'Visualisations Personnalisées (personnalisez et sauvegardez vos propres visualisations)',
      feature1: '✓ Accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials"',
      feature2: '✓ Accès à Play3 avec :',
      feature2_1: 'Pauses séquentielles',
      feature2_2: 'Téléstration avancée',
      feature2_3: 'Exportation de vidéos personnalisée',
      feature3: '✓ Pack de 10 minutes ATD',
      primeDescription:
        'Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Essentials + Code ∞" et active Play3 avec des téléstrations avancées.',
      primeMftDescription:
        'Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials" et active Play3 avec des téléstrations avancées.',
      primePresetsDescription:
        'Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Essentials + Code ∞" et active Play3 avec des téléstrations avancées et des visualisations personnalisées.',
      primePresetsMftDescription:
        'Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials" et active Play3 avec des téléstrations avancées et des visualisations personnalisées.'
    },
    pro: {
      description:
        'Ce plan inclut un accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials" et un accès à Play3 avec toutes les fonctionnalités du Plan "Full Prime".',
      feature1: '✓ Suivi Automatique des Joueurs',
      feature2: '✓ Suivi Automatique du Terrain',
      feature3: '✓ Accès complet à Play2 avec toutes les fonctionnalités du Plan "Full Essentials"',
      feature4: '✓ Accès à Play3 avec :',
      feature4_1: 'Pauses séquentielles',
      feature4_2: 'Téléstration avancée',
      feature4_3: 'Exportation de vidéos personnalisée',
      feature4_4:
        'Visualisations Personnalisées (personnalisez et sauvegardez vos propres visualisations)',
      feature5: '✓ Pack de 100 minutes ATD'
    },
    remove: 'Supprimer',
    starter: {
      addon1: 'Téléstration illimitée pendant les pauses',
      addon2: 'Fonctionnalités de Coding illimitées (CodePad, Coding en Direct et Importation XML)',
      feature1: "✓ Codage post-match (Code Table avec jusqu'à 4 codes)",
      feature2: "✓ Téléstration pendant les pauses (jusqu'à 50 annotations par mois)",
      feature3: '✓ Exportation de vidéos',
      starterCodeDescription:
        'Ce plan débloque la limitation des fonctionnalités de codage telles que CodePad, Coding en Direct et Importation XML. Il libère également la limite de 4 codes par Projet Vidéo pour un nombre illimité de codes. Les limitations des fonctionnalités de téléstration restent celles du Free Starter.',
      starterCodePauseDescription:
        'Ce plan débloque la limitation des fonctionnalités de codage telles que CodePad, Coding en Direct et Importation XML. Il libère également la limite de 4 codes par Projet Vidéo pour passer à des codes illimités, et débloque la limitation de téléstration en pauses de 50 par mois à illimité.',
      starterPauseDescription:
        'Ce plan débloque la limitation de téléstration en pauses de 50 par mois à illimité. Les limitations des fonctionnalités de coding restent dans Free Starter.'
    },
    totalPack: 'Total : %{currency} %{cost}',
    totalPlan: 'Total : %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Facturation',
    billingSettings: 'Paramètres de facturation',
    billingSettingsDescription: '(modifier votre plan, mettre à jour les modes de paiement...)',
    cancelAt: "Votre plan s'annule le",
    cancelPlan: 'Annuler le plan',
    confirmNewPassword: 'Confirmer le nouveau mot de passe',
    deleteAccount: 'Supprimer le compte',
    details: 'Mes détails',
    emailNotActivated: 'La notification par e-mail est désormais activée',
    emailNotDeactivated: 'La notification par e-mail est désormais désactivée',
    emailNotifications: 'Notification par e-mail',
    language: 'Langue',
    languageChanged: 'La langue a été mise à jour',
    newPassword: 'Nouveau mot de passe',
    organizationLabel: "Préfixe de l'organisation",
    organizationTooltip:
      "Ce préfixe est nécessaire pour importer des Modèles personnalisés dans un projet vidéo. Contactez-nous pour plus d'informations ou consultez la documentation sur codeball.metrica-sports.com",
    passwordsDontMatch: 'Les mots de passe ne correspondent pas',
    pauseAt: 'Votre plan se met en pause le',
    planCanceled: "Votre plan a été annulé. Vérifiez votre e-mail pour plus d'informations",
    plans: 'Plans',
    quantity: 'Quantité :',
    renewAt: 'Votre plan se renouvelle le',
    title: 'Paramètres',
    upgradePlan: 'Actualisation du Plan',
    userKey: 'Clé utilisateur :',
    userKeyUsages: '%{current}/%{max} utilisations',
    userSettings: 'Paramètres utilisateurs'
  },
  shoppingCart: {
    subtitle: 'Choisissez votre plan Play et les compléments pour améliorer votre performance.',
    title: 'Configurez votre Play.'
  },
  subscription: {
    cancelSubscription: "Annuler l'abonnement",
    errorUpdating:
      'Il y a eu une erreur lors de la mise à jour de votre abonnement. Veuillez envoyer un courriel à support@metrica-sports.com et nous serons ravis de vous aider.',
    free: 'Gratuit',
    isObsolete:
      'Votre abonnement ne peut pas être modifié. Remplacez les produits obsolètes par de nouveaux pour activer les changements.',
    licenseKeys: 'Clés de licence',
    pendingRefundRequest:
      "Il est impossible d'apporter d'autres modifications à cet abonnement tant qu'une demande de remboursement est en attente.",
    undoCancel: "Annuler l'annulation de l'abonnement.",
    undoPause: "Annuler la pause de l'abonnement.",
    updatePaymentMethod: 'Mettre à jour la méthode de paiement',
    updatedSuccessfully: 'Abonnement mis à jour avec succès',
    yourPlans: 'Vos Plans',
    yourSubscription: 'Votre abonnement'
  },
  successfulPayment: {
    activateLicence:
      'Activez votre licence ou crédit Cloud en saisissant la clé de licence fournie.',
    allTheBest: 'Tout le meilleur,',
    applyKey: 'Appliquer la clé à ce compte',
    applyKeyDescription:
      'Souhaitez-vous appliquer automatiquement la clé de licence sur le compte auquel vous êtes connecté ?',
    downloadPlay: '%{pre}Téléchargez PLAY%{post} via notre site web.',
    faq: 'Pour vous aider à commencer avec votre commande, consultez les %{pre}Foire Aux Questions%{post} sur notre site web. Voici un résumé rapide des informations qui peuvent être les plus pertinentes pour vous à ce stade :',
    goToSummary:
      "Si non, veuillez consulter le résumé ci-dessous pour connaître les instructions d'activation.",
    happyPlay:
      "Nous espérons que tout est clair, mais n'hésitez pas à nous contacter si vous avez d'autres questions ou doutes. Joyeux PLAY !",
    helpDesk:
      "Consultez notre %{pre}HelpDesk%{post} pour obtenir des informations générales sur l'utilisation de PLAY.",
    keyApplied: 'Clé appliquée',
    licenseKey: 'Votre clé de licence est : %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: "L'équipe de Metrica Sports",
    settingsCloud:
      "Visitez notre %{pre}Cloud%{post} et naviguez jusqu'au menu des paramètres pour :",
    technicalSupport:
      'Pour le support technique, remplissez le formulaire de contact disponible dans PLAY/Cloud, ou envoyez un courriel à %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      "Veuillez noter que nos abonnements sont renouvelés automatiquement jusqu'à ce qu'ils soient explicitement annulés via les paramètres de notre %{pre}Cloud%{post}, conformément à nos Termes et Conditions.",
    thanks:
      "Merci pour votre commande ! Nous l'avons traitée avec succès et avons joint vos clés de licence ci-dessous.",
    title: 'Confirmation de commande',
    updatePayment: 'Mettez à jour votre méthode de paiement.',
    updateSubscription: 'Annulez ou modifiez votre abonnement.'
  },
  surfaces: {
    automaticVp: 'Superficies non disponibles sur les projets vidéo avec une ancienne version ATD',
    delete: 'Supprimer le fichier Superficie',
    needsAtd: "Démarrez d'abord le processus ATD pour exécuter les processus de Superficie",
    needsElite:
      "Superficies personnalisées disponibles sur le plan Elite. Contactez-nous pour plus d'informations",
    needsFootballSideView:
      "Superficies non disponibles dans les projets vidéo dont le fichier vidéo n'est pas une vidéo de football en grand angle ou en retransmission TV",
    pitchControl:
      'En exécutant ce processus, vous pourrez ajouter la visualisation "Pitch Control" dans Play.',
    pitchControlProcessed: 'Superficie de Pitch Control traitée',
    pitchControlProcessing: 'Traitement du Pitch Control',
    processed: 'Fichier Superficie chargé',
    processing: 'Traitement du fichier de Superficie',
    title: 'Superficies',
    titlePitchControl: 'Pitch Control',
    upload: 'Téléchargez votre propre Superficie',
    uploading: 'Chargement de Superficie...'
  },
  trackingFifaFormat: {
    download: 'Télécharger le fichier au format FIFA EPTS',
    needsElite:
      "Cette fonction n'est disponible qu'après avoir importé les fichiers correspondants dans le module d'intégration de données, les projets vidéo avec données de tracking automatisé ou les projets vidéo dans le plan Elite.",
    processed: 'Traitement du format FIFA EPTS',
    start:
      'Démarrez le processus pour générer le fichier au format FIFA EPTS. Vous pourrez ensuite télécharger le fichier',
    title: 'Format EPTS de la FIFA',
    titleDownload: 'Télécharger le format FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'Obtenez le pack de minutes Cloud Engine qui vous convient le mieux :',
    minutePackLinks: {
      minutes_10: 'Bénéficiez de 10 minutes pour 10€',
      minutes_100: 'Bénéficiez de 100 minutes pour 90€',
      minutes_1000: 'Bénéficiez de 1.000 minutes pour 750€',
      minutes_10000: 'Bénéficiez de 10 000 minutes pour 5 000€'
    },
    noMinutes: {
      description:
        'Vous ne disposez pas de suffisamment de minutes pour créer ce projet vidéo. Savez-vous que vous pouvez obtenir des packs de minutes quel que soit votre plan ?',
      secondDescription: '',
      title: 'Obtenez plus de minutes pour créer un projet vidéo !'
    },
    notEnoughMinutes: {
      description:
        'Nous sommes désolés de vous informer que vous ne disposez pas de suffisamment de minutes pour le traitement ATD de vos projets vidéo.',
      secondDescription:
        "Si vous voulez traiter l'ATD, vous devrez ajouter des minutes supplémentaires à votre compte. Les minutes que vous obtiendrez n'expirent pas !",
      title: 'Minutes disponibles insuffisantes'
    }
  },
  video: {
    cancelUpload: 'Annuler le chargement',
    delete: 'Supprimer la vidéo',
    download: 'Télécharger la vidéo',
    title: 'Fichier vidéo',
    upload: 'Upload vidéo',
    uploaded: 'Vidéo téléchargée',
    uploading: 'Chargement de la vidéo'
  },
  videoProject: {
    delete: 'Supprimer le projet vidéo',
    edit: 'Modifier le projet vidéo',
    processWaitingJobs: 'Traitement des tâches en attente',
    title: 'Projet vidéo'
  }
}
