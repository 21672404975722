export default {
  addKeyModal: {
    action: 'Применить',
    errorMessage:
      'Ошибка применения ключа. Если проблема не устранена, обратитесь в службу поддержки Metrica Sports.',
    minutesMessage: 'Ваш ключ был обработан. Теперь у вас есть %{minutes} минут доступа.',
    placeholder: 'Введите ключ...',
    successMessage: 'Ключ успешно обработан',
    title: 'Добавить ключ'
  },
  admin: {
    NoGames: 'Нет игр, добавьте свою первую...',
    NoResults: 'Нет результатов...',
    addKey: 'Добавить ключ',
    atd: 'ATD',
    automatedTrackingData: 'Данные автоматического Tracking (ATD)',
    automatedTrackingDataJobSuccess:
      'Процесс авто Tracking данных на %{vpName} завершен и теперь доступен в Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Данные авто Tracking обработаны',
    awayTeam: 'Команда гостей',
    awayTeamScore: 'Счет команды гостей',
    ballDetection: 'Обнаружение мяча',
    ballTracking: 'Tracking мяча',
    ballTrackingProcessedTooltip: 'Обнаружение мяча обработано',
    ballTrackingTooltip:
      'Этот процесс обнаружит мяч и позволит вам визуализировать его в динамической формации (2D поле) в игре. Координаты мяча также будут доступны в файле формата FIFA EPTS.',
    cancelATDProcessSuccessfully: 'Процесс авто Tracking данных был отменен на %{vpName}',
    cancelDataIntegrationProcessSuccessfully: 'Процесс интеграции данных был отменен на %{vpName}',
    cancelEventsProcessSuccessfully: 'Процесс Smart Tagging был отменен на %{vpName}.',
    cancelProcessTooltip: 'Отменить процесс',
    cancelSurfacesProcessSuccessfully: 'Процесс Surface был отменен на %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Процесс отслеживания формата FIFA был отменен на %{vpName}',
    category: 'Категория',
    checking: 'Проверка...',
    cloudMinutes: 'Минуты облака',
    competition: 'Соревнование',
    connectionFailed: 'Ошибка подключения',
    connectionFailedText:
      'Кажется, возникла проблема с получением информации о состоянии. Пожалуйста, проверьте подключение к Интернету и перезагрузите страницу. Если проблема не устранена, обратитесь в службу поддержки',
    correctedTracking: 'Tracking коррекция в Proцессе',
    correctionUsage: 'Будете ли вы использовать этот видеопProект для коррекции Tracking?',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM) — это ориентированный на пользователя модуль Play для исправления треков, ранее созданных с помощью нашего ATD, который автоматически генерирует данные без идентификаторов для любого футбольного матча. Используйте TCM для исправления неправильных треков и присвоения им идентификационных данных. Свяжитесь с нами для получения дополнительной информации.',
    createVideoProject: 'Создать видео проект',
    creatingGame: 'Создание видеоProекта...',
    dataIntegrationFailed:
      'Ошибка интеграции данных на %{vpName}. Перейдите на панель интеграции данных, проверьте файлы и повторите попытку. Если ошибка повторяется, обратитесь в службу поддержки Metrica Sports.',
    dataIntegrationJobSuccess: 'Процесс интеграции данных для %{vpName} успешно завершен',
    date: 'Дата',
    description: 'Описание',
    editedSuccessfully: '%{vpName} успешно отредактировано',
    errorContactSupport: 'Ошибка. Обратитесь в службу поддержки',
    errorProcessTooltip: 'Ошибка в процессе',
    eventsDetection: 'Автоматическая маркировка',
    eventsDetectionProcessedTooltip: 'Smart Tagging обработан',
    eventsDetectionTooltip: {
      footer: '*Доступно для видео длительностью более 30 минут.',
      header:
        'Воспользуйтесь нашей технологией для автоматического обнаружения серии событий, происходящих в игре*.',
      improved: 'Улучшено'
    },
    fieldRecognition: 'Обнаружение поля',
    fieldTrackingProcessedTooltip: 'Обнаружение поля обработано',
    fieldTrackingTooltip:
      'Обработка обнаружения поля для добавления визуализаций со значениями скорости и расстояния и всех рисунков в зависимости от поля в Play.',
    fileDeletedSuccessfully: '%{fileName} успешно удален для %{vpName}',
    fileUploadedError: 'Ошибка загрузки %{fileName} для %{vpName}',
    fileUploadedSuccessfully: '%{fileName} успешно загружен для %{vpName}',
    forkedGameLimit:
      'Этот видеопроект принадлежит другой организации, поэтому его невозможно изменить.',
    gameDeletedSuccessfully: '%{game} успешно удалена',
    gameVideoProject: 'Игровой видео проект',
    getMoreMinutes: 'Получите больше',
    homeTeam: 'Домашняя команда',
    homeTeamScore: 'Счет домашней команды',
    initializing: 'Инициализация ...',
    jobFailed:
      'Не удалось выполнить процесс %{process} для %{vpName}. Обратитесь в службу поддержки Metrica Sports.',
    liveCoding: 'или продолжить кодирование в живом режиме?',
    liveCodingTooltip:
      'Вы можете создать видеопроект, не выбирая видеофайл для целей Coding в живом режиме. Когда у вас есть видео, вы можете добавить его для авто Tracking данных в закодированном видеопроекте.',
    localTeamOnTheLeft:
      'Находится ли "домашняя команда" на левой стороне поля в первой половине игры?',
    localTeamOnTheLeftTooltip:
      'Для proцесса обработки автоматической маркировки укажите, на какой стороне поля находится каждая команда. Посмотрите видео, которое вы загружаете, чтобы проверить его.',
    noFootballSideViewTooltip:
      'Этот видеопроект содержит широкоугольный видеофайл, не относящийся к футболу или футболу, или видеофайл, транслируемый по телевидению, поэтому обработано только обнаружение игрока и команды.',
    noMatches: 'Нет совпадений...',
    noSubscriptionOwnerTooltip:
      'Свяжитесь с &#39;%{email}&#39;, администратором вашей подписки, для обновления или перейдите на страницу наших планов, чтобы создать свой собственный план.',
    noVideoFile: 'Нет видеофайла',
    notPossibleToDeleteThisEntry: 'Невозможно удалить эту запись',
    numberOfHalves: 'Сколько таймов в этой игре?',
    numberOfHalvesTooltip:
      'Чтобы выполнить proцесс автоматической маркировки, укажите, сколько игровых периодов включено в загружаемое видео.',
    oldAtdTooltip: 'Это видео было обработано с помощью более старой версии ATD',
    onRequest: 'По запросу',
    organizations: 'Организации',
    place: 'Расположение',
    playerTracking: 'Обнаружение игроков и команд',
    playerTrackingProcessedTooltip: 'Обнаружение игрока обработано',
    playerTrackingTooltip:
      'Этот процесс обнаружит вратарей и 10 полевых игроков каждой команды. Это позволит вам визуализировать Live Formation (2D Pitch) и автоматически добавлять визуализацию игроков в Play. Координаты игроков также будут доступны в файле формата FIFA EPTS.',
    postProcessingData: 'Постобработка данных...',
    preProcessingAtd: 'Предварительная обработка...',
    processingTooltip: 'Обработка',
    readyToUpload: 'Готов к загрузке',
    search: 'Поиск...',
    selectVideoFile: 'Выберите видеофайл',
    settingUpAtd: 'Настройка обнаружения...',
    settings: 'Настройки',
    singleVideoProject: 'Одиночный видеопроект',
    startAtd: 'Начать ATD',
    startFifaMessage:
      'Процесс интеграции FIFA для %{vpName} запущен. Мы покажем уведомление, как только это будет сделано. Пожалуйста подождите.',
    startProcessingWaitingJobsFailed: 'Не удалось отправить ожидающие задания',
    startProcessingWaitingJobsSuccessfully: 'Ожидающие задания успешно отправлены',
    startSsMessage:
      'Процесс интеграции второго спектра для %{vpName} запущен. Мы покажем уведомление, как только это будет сделано. Пожалуйста подождите.',
    startStatsMessage:
      'Процесс интеграции статистики для %{vpName} запущен. Мы покажем уведомление, как только это будет сделано. Пожалуйста подождите.',
    startTracabDflMessage:
      'Процесс интеграции Tracab DFL для %{vpName} запущен. Мы покажем уведомление, как только это будет сделано. Пожалуйста подождите.',
    startTracabMessage:
      'Процесс интеграции Tracab для %{vpName} запущен. Мы покажем уведомление, как только это будет сделано. Пожалуйста подождите.',
    surfacesJobSuccess: 'Процесс Surface%{vpName} успешно завершен',
    trackingCorrectionProcessedTooltip: 'Tracking коррекция Proведена',
    trackingFifaFormatJobSuccess:
      'Процесс отслеживания формата FIFA для %{vpName} успешно завершен. Теперь вы можете загрузить файл, щелкнув пункт меню «Загрузить формат FIFA».',
    trackingLicence: 'У вас нет лицензии на Tracking',
    uncaughtErrorRow:
      'Ошибка видеопроекта. Пожалуйста, обновите эту страницу. Если проблема не устранена, обратитесь в службу поддержки или удалите ее и создайте заново.',
    updatingGame: 'Обновление видеоProекта...',
    upgradePlan: 'Перейти на новый тарифный план',
    uploadAndTracking: 'Загрузка и Tracking',
    uploadingVideoFile: 'Загрузка видеофайла',
    videoFile: 'Видеофайл',
    videoFootballSideView:
      'Является ли этот видеофайл футбольным широкоугольным видео или телевизионной трансляцией?',
    videoFootballSideViewTooltip: 'Обнаружение поля оптимизировано только для таких видео.',
    videoMultipartStart:
      '%{videoName} загружается на %{vpName}. НЕ ЗАКРЫВАЙТЕ ЭТУ СТРАНИЦУ, пока загрузка не будет завершена',
    videoPlaylist: 'Является ли этот видеофайл плейлистом?',
    videoPlaylistTooltip:
      'Если вы выберете ДА, будет выполнено обнаружение клипа, чтобы распознать, когда в видео происходит обрезка, что делает его идеальным инструментом, например, если вы загружаете видео, содержащее несколько клипов. После запуска этого процесса загрузите видеопроект в Play, и файл кодирования обнаружения клипа будет там с кодом, включающим каждый монтаж как событие.',
    videoProjectSettings: 'Настройки видеопроекта',
    videoUploadError:
      'Не удалось загрузить видео %{videoName} для %{vpName}. Пожалуйста, попробуйте еще раз',
    videoUploaded: '%{videoName} было загружено на %{vpName}',
    vpCreated: '%{vpName} создан',
    waitingAtd: 'Ожидание...'
  },
  billing: {
    address: 'Адрес',
    billed: 'Выставлено к оплате',
    completed: 'Оплачено',
    details: 'Детали Биллинга',
    history: 'История выставления счетов',
    invoiceNotFound: 'Счет-фактура не найдена',
    noInvoices: 'Нет счетов-фактур',
    past_due: 'Просрочено',
    taxId: 'Идентификационный номер налогоплательщика'
  },
  checkout: {
    chargeSubscription: 'Начислить на подписку',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*Промокоды можно применять при оформлении заказа.',
    excTax: '(Без учета налогов)',
    immediatePayment: 'Немедленный платеж',
    incTax: '(Вкл. налог)',
    monthlyBilled: 'Ежемесячная оплата',
    nextPayment: 'Следующий Платеж',
    noPacks: 'Без Пакета Минут в Облаке',
    onDate: '%{date} числа',
    packs: 'Пакеты',
    packsTotal: 'Всего Пакетов',
    perMonth: '/месяц',
    perYear: '/год',
    plans: 'Планы',
    plansTotal: 'Общее количество планов',
    proceed: 'Перейти к оплате',
    subtotal: 'Субтотал',
    taxPayable: 'Налоги (%{percentage}%)',
    total: 'Всего',
    unpaid:
      'Ваш последний счет еще не был оплачен. Пожалуйста, обновите вашу платежную информацию, чтобы продолжить.',
    updatePaymentMethod: 'Обновить способ оплаты',
    updateSubscription: 'Обновить подписку',
    userDevice: '1 пользователь/устройство',
    usersDevices: '%{number} пользователей/устройств',
    yearlyBilled: 'Ежегодно выставляется счет'
  },
  common: {
    back: 'Назад',
    cancel: 'Отмена',
    canceling: 'Отмена ...',
    clearAll: 'Очистить всё',
    edit: 'Редактировать',
    email: 'Электронная почта',
    errorMsg: 'Ошибка обработки %{name}. По вопросам обращайтесь в службу поддержки.',
    errorTryAgain: 'Ошибка. Попробуйте еще раз',
    genericError: 'Ошибка, процесс не выполнен. По вопросам обращайтесь в службу поддержки.',
    loading: 'Загружается ...',
    login: 'Войти',
    logout: 'Выйти',
    new: 'Новый',
    next: 'Следующий',
    no: 'Нет',
    noFileChosen: 'Файл не выбран',
    ok: 'ОК',
    required: 'Обязательное поле',
    reset: 'Сброс пароля',
    restart: 'Перезапустить',
    selectFile: 'Выбрать файл',
    sort: 'Сортировать',
    start: 'Начать',
    today: 'Сегодня',
    uploading: 'Загрузка...',
    yes: 'да'
  },
  confirmModal: {
    description: 'Вы уверены?',
    title: 'Подтвердить'
  },
  dataIntegration: {
    deleteFile: 'Удалить файл',
    featureNotAvailable:
      'Эта функция недоступна в вашем текущем тарифном плане. Вам нужно перейти на другой план, чтобы иметь эту функцию. Для получения более подробной информации свяжитесь с нами',
    notSideView:
      'Интеграция данных недоступна для видеопроектов, содержащих широкоугольный или телевизионный видеофайл, не относящийся к футболу.',
    open: 'Интеграция открытых данных',
    processJobsFirst:
      'Вам необходимо сначала начать обработку данных авто Tracking (ATD), прежде чем получить доступ к панели интеграции данных.',
    processed: 'Интеграция данных обработана',
    selectFile: 'Выбрать файл',
    startProcess: 'Начать процесс',
    title: 'Интеграция данных',
    waiting: 'Процесс ожидания...',
    waitingProcess:
      'Процесс интеграции начнется после завершения процессов обнаружения игроков и полей.'
  },
  deleteModal: {
    checkboxLabel: 'Я также хочу перестать получать электронные письма от Metrica Sports',
    confirmationType: 'Введите %{validateFormKey} для подтверждения:',
    deleteAction: 'Да, удалить',
    description: 'Вы уверены, что хотите навсегда удалить свою учетную запись?',
    information:
      'Вы потеряете все видеопроекты, созданные с помощью этой учетной записи. Мы не сможем восстановить их после удаления.',
    keepAccount: 'Сохранить аккаунт',
    suggestion: 'Мы предлагаем сохранить ваш аккаунт.',
    title: 'Удалить навсегда?',
    validateFormKey: 'УДАЛИТЬ'
  },
  error: {
    oops: 'Ой!',
    restart: 'Похоже, вы пытаетесь воспользоваться тем, что мы всё еще разрабатываем...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging недоступен в Видео Проектах с более старой версией ATD.',
    needsAtd: 'Сначала запустите процесс ATD, чтобы выполнить процесс Smart Tagging.',
    needsFootballSideView:
      'Smart Tagging недоступен в Видео Проектах, содержащих видеофайл, не относящийся к футболу, снятый с широкоугольного ракурса или телевизионной трансляции.',
    needsGameVp: 'Smart Tagging доступен только в проектах видео игр.',
    needsNoPlaylist:
      'Smart Tagging недоступен в Видео Проектах, содержащих файл видео в форме плейлиста.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Admin пользователи должны использовать частную конечную точку для сброса пароля',
    notFound:
      'Пользователь не найден. Пожалуйста, попробуйте еще раз, указав действительный адрес электронной почты',
    success: 'Восстановление пароля! Пожалуйста, проверьте свою электронную почту'
  },
  login: {
    contactUs: 'Связаться с нами',
    enterYourEmail: 'Введите адрес электронной почты',
    enterYourPassword: 'Введите свой пароль',
    forgotPassword: 'Забыли пароль?',
    invalidEmail: 'Неверный адрес электронной почты',
    licenceExpired: 'Срок действия вашей лицензии истек.',
    trialExpired: 'Срок действия пробной версии истек.',
    upgradePlan: 'Обновите свой план'
  },
  patterns: {
    delete: 'Удалить файл шаблона',
    title: 'Пользовательский файл шаблона',
    upload: 'Загрузить файл пользовательского шаблона',
    uploaded: 'Файл шаблона загружен',
    uploading: 'Загрузка файла шаблона'
  },
  plans: {
    academic: 'Академический',
    addMoreProducts: '+ Добавить больше продуктов',
    admin: 'Администратор',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Ежемесячно',
    basicCodePadYearly: 'Basic+Code Eжегодно',
    basicMftCodePadMonthly: 'Full Basic Ежемесячно',
    basicMftCodePadYearly: 'Full Basic Eжегодно',
    basicMftMonthly: 'Basic+MFT Eжемесячно',
    basicMftXmlMonthly: 'Basic MFT + XML Ежемесячно',
    basicMftXmlYearly: 'Basic MFT + XML Ежегодно',
    basicMftYearly: 'Basic+MFT Ежегодно',
    basicMonthly: 'Basic Ежемесячно',
    basicXmlMonthly: 'Basic XML Ежемесячно',
    basicXmlYearly: 'Basic XML Ежегодно',
    basicYearly: 'Basic Ежегодно',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Элита Годовой',
    eliteYearly: 'Elite Ежегодно',
    essentialsCodeMonthly: 'Essentials + Code ∞ Ежемесячно',
    essentialsCodeYearly: 'Essentials + Code ∞ Годовой',
    essentialsMftCodeMonthly: 'Полный пакет Essentials Ежемесячно',
    essentialsMftCodeYearly: 'Full Essentials Годовой',
    essentialsMftMonthly: 'Essentials + MFT Ежемесячно',
    essentialsMftYearly: 'Essentials + MFT Ежегодно',
    essentialsMonthly: 'Essentials Ежемесячно',
    essentialsYearly: 'Essentials Ежегодный',
    influencer: 'Инфлюенсер',
    lite: 'Lite',
    liteMonthly: 'Lite Ежемесячно',
    liteYearly: 'Lite Ежегодно',
    minutes10: 'Пакет 10 минут Cloud',
    minutes100: 'Пакет 100 Минут в Облаке',
    minutes1000: 'Пакет 1.000 Минут Cloud',
    minutes10000: 'Пакет 10.000 Минут в Облаке',
    plan: '%{planName} План',
    primeMftMonthly: 'Prime + MFT Ежемесячно',
    primeMftYearly: 'Prime + MFT Ежегодно',
    primeMonthly: 'Prime Ежемесячно',
    primePresetsMftMonthly: 'Полный Prime Ежемесячно',
    primePresetsMftYearly: 'Полный Prime Годовой',
    primePresetsMonthly: 'Prime + Presets Ежемесячно',
    primePresetsYearly: 'Prime + Presets Ежегодно',
    primeYearly: 'Prime Ежегодный',
    pro: 'Pro',
    pro2024Monthly: 'Про Ежемесячно',
    pro2024Yearly: 'Про Годовой',
    proYearly: 'Pro Ежегодно',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Ежемесячно',
    starterCodePadMonthly: 'Code Ежемесячно',
    starterCodePauseMonthly: 'Полный Стартер Ежемесячно',
    starterCodePauseYearly: 'Полный Стартовый Годовой',
    starterCodeYearly: 'Starter + Code ∞ Годовой',
    starterPauseMonthly: 'Starter + Pause ∞ Ежемесячно',
    starterPauseYearly: 'Starter + Pause ∞ Годовой',
    student: 'Студент',
    trial: 'Пробная версия'
  },
  playlist: {
    download: 'Загрузить файл плейлиста',
    notProcessed: 'Файл из плейлиста не был обработан в этом видеоProекте.',
    processed: 'Файл из плейлиста proобработан',
    title: 'Плейлист',
    titleDownload: 'Скачать плейлист'
  },
  products: {
    addOns: 'Дополнения',
    basic: 'Безлимитные Коды и Аннотации',
    code: 'CodePad',
    elite: {
      description:
        'Автоматизируйте и настройте весь ваш рабочий процесс анализа. Этот план включает полный доступ к Play2 со всеми функциями плана "Full Essentials" и доступ к Play3 со всеми функциями плана "Pro".',
      feature1: '✓ Общее облачное хранилище',
      feature2: '✓ Интеграция данных Tracking',
      feature3: '✓ Менеджер Коррекции Трекинга',
      feature4: '✓ RESTful API',
      feature5: '✓ Пакет на 5.000 минут ATD'
    },
    essentials: {
      addon1: 'Неограниченные возможности Coding (CodePad, Живое Coding и Импорт XML)',
      addon2: 'Ручное Полевое Трекинг',
      essentialsCodeDescription:
        'Этот план активирует Динамические Телестраторы, позволяя добавлять неограниченное количество визуализаций движений при воспроизведении видео и на паузах. Этот план не включает Ручное Отслеживание Поля, поэтому визуализации Скорости и Дистанции не включены. Также снимается ограничение на функции кодирования, такие как CodePad, Живое Кодирование и Импорт XML, и убирается лимит в 4 кода на Видеопроект, делая количество кодов неограниченным.',
      essentialsDescription:
        'Этот план позволяет использовать Динамическую Телестрацию, доступную для добавления неограниченных визуализаций движения при воспроизведении видео и на паузах. Этот план не включает Ручное Отслеживание Поля, поэтому визуализации Скорости и Дистанции не включены. Возможности кодирования остаются ограниченными.',
      essentialsMftCodeDescription:
        'Этот план активирует возможность Динамической Телестрации, позволяя добавлять неограниченное количество визуализаций движений при воспроизведении видео и на паузах, а также включает Мануальное Полевое Трекинг для добавления визуализаций скорости и расстояний. Также снимает ограничение на функции кодирования, такие как CodePad, Живое Кодирование и Импорт XML, и убирает лимит в 4 кода на Видеопроект, делая количество кодов неограниченным.',
      essentialsMftDescription:
        'Этот план включает возможность использования Динамической Телестрации, позволяя добавлять неограниченное количество визуализаций движения при воспроизведении видео и на паузах, а также активирует Ручное Отслеживание Поля для добавления визуализаций скорости и расстояния. Возможности кодирования остаются ограниченными.',
      feature1: '✓ Постигровое кодирование (Code Table с до 4 кодами)',
      feature2: '✓ Неограниченная телестраторная работа в паузах',
      feature3: '✓ Динамическая телестратория',
      feature4: '✓ Пакет на 3 минуты ATD'
    },
    featuresIncluded: 'Включенные функции:',
    freeFeature1: '✓ Постигровое кодирование (Code Table с до 4 кодами)',
    freeFeature2: '✓ Ручное трекинг',
    freeFeature3: '✓ Телестратор (до 150 аннотаций)',
    freeFeature4: '✓ Экспорт видео',
    freeFeatures: 'Бесплатные функции',
    fullBasic: 'Ручное Полевое Трекинг',
    lite: 'Автоматическое отслеживание игроков и поля',
    liteDescription:
      'Продвинутое кодирование и продвинутые визуализации. Все автоматически, что делает это в 3 раза быстрее, чем Full Basic!',
    minutesDescription:
      'Пополните любой план. Получите пакеты минут для Автоматизированных Данных Трекинга.',
    minutesPack: 'Пакет Минут Облака',
    otherDescription:
      'Расширенные функции coding с базовой телестрацией и ограниченными аннотациями',
    prime: {
      addon1: 'Ручное Полевое Трекинг',
      addon2:
        'Предустановленные Визуализации (настройте и сохраните свои собственные визуализации)',
      feature1: '✓ Полный доступ к Play2 со всеми функциями плана "Full Essentials"',
      feature2: '✓ Доступ к Play3 с:',
      feature2_1: 'Последовательные паузы',
      feature2_2: 'Продвинутая телестраторика',
      feature2_3: 'Пользовательский экспорт видео',
      feature3: '✓ Пакет на 10 минут ATD',
      primeDescription:
        'Этот план включает полный доступ к Play2 со всеми функциями плана "Essentials + Code ∞" и обеспечивает доступ к Play3 с продвинутыми телестраторными возможностями.',
      primeMftDescription:
        'Этот план включает полный доступ к Play2 со всеми функциями плана "Full Essentials" и активирует Play3 с продвинутыми телеиллюстрациями.',
      primePresetsDescription:
        'Этот план включает полный доступ к Play2 со всеми функциями плана "Essentials + Code ∞" и активирует Play3 с продвинутыми телеиллюстрациями и персонализированными визуализациями.',
      primePresetsMftDescription:
        'Этот план включает полный доступ к Play2 со всеми функциями плана "Full Essentials" и активирует Play3 с продвинутыми телеиллюстрациями и персонализированными визуализациями.'
    },
    pro: {
      description:
        'Этот план включает полный доступ к Play2 со всеми функциями плана "Full Essentials" и доступ к Play3 со всеми функциями плана "Full Prime".',
      feature1: '✓ Автоматическое отслеживание игроков',
      feature2: '✓ Автоматическое Отслеживание Поля',
      feature3: '✓ Полный доступ к Play2 со всеми функциями плана "Full Essentials"',
      feature4: '✓ Доступ к Play3 с:',
      feature4_1: 'Последовательные паузы',
      feature4_2: 'Продвинутая телестраторика',
      feature4_3: 'Пользовательский экспорт видео',
      feature4_4:
        'Предустановленные Визуализации (настройте и сохраните свои собственные визуализации)',
      feature5: '✓ Пакет на 100 минут ATD'
    },
    remove: 'Удалить',
    starter: {
      addon1: 'Безлимитная телестраторная визуализация в паузах',
      addon2: 'Неограниченные возможности Coding (CodePad, Живое Coding и Импорт XML)',
      feature1: '✓ Постигровое кодирование (Code Table с до 4 кодами)',
      feature2: '✓ Телестратор в паузах (до 50 аннотаций в месяц)',
      feature3: '✓ Экспорт видео',
      starterCodeDescription:
        'Этот план снимает ограничение на функции кодирования, такие как CodePad, Живое Кодирование и Импорт XML. Также убирается ограничение в 4 кода на Видеопроект, делая количество кодов неограниченным. Ограничения на функции телестратора остаются, как в Free Starter.',
      starterCodePauseDescription:
        'Этот план снимает ограничение на функции кодирования, такие как CodePad, Живое Кодирование и Импорт XML. Также он убирает ограничение в 4 кода на Видео Проект, делая их количество неограниченным, и снимает ограничение на телестрации в паузах с 50 в месяц до неограниченного количества.',
      starterPauseDescription:
        'Этот план снимает ограничение на телестратор в паузах с 50 в месяц до неограниченного. Ограничения на функции coding остаются как в Free Starter.'
    },
    totalPack: 'Итого: %{currency} %{cost}',
    totalPlan: 'Итого: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Биллинг',
    billingSettings: 'Платежные настройки',
    billingSettingsDescription: '(измените свой план, обновите способы оплаты...)',
    cancelAt: 'Ваш план заканчивается',
    cancelPlan: 'Отменить план',
    confirmNewPassword: 'Подтвердите новый пароль',
    deleteAccount: 'Удалить аккаунт',
    details: 'Мои данные',
    emailNotActivated: 'Уведомление по электронной почте теперь активировано',
    emailNotDeactivated: 'Уведомление по электронной почте отключено',
    emailNotifications: 'Уведомления по электронной почте',
    language: 'Язык',
    languageChanged: 'Язык обновлен',
    newPassword: 'Новый пароль',
    organizationLabel: 'Префикс организации',
    organizationTooltip:
      'Этот префикс необходим для импорта пользовательских шаблонов в видеопроект. Свяжитесь с нами для получения дополнительной информации или ознакомьтесь с документацией на codeball.metrica-sports.com',
    passwordsDontMatch: 'Пароли не совпадают',
    pauseAt: 'Ваш план приостанавливается %{date}.',
    planCanceled:
      'Ваш план отменен. Проверьте свою электронную почту для получения дополнительной информации',
    plans: 'Планы',
    quantity: 'Количество:',
    renewAt: 'Ваш план продлевается на',
    title: 'Настройки',
    upgradePlan: 'Перейти на новый тарифный план',
    userKey: 'Пользовательский ключ:',
    userKeyUsages: '%{current}/%{max} использований',
    userSettings: 'Пользовательские настройки'
  },
  shoppingCart: {
    subtitle: 'Выберите ваш план Play и дополнения, чтобы повысить вашу производительность.',
    title: 'Настройте ваш Play'
  },
  subscription: {
    cancelSubscription: 'Отменить подписку',
    errorUpdating:
      'При обновлении вашей подписки произошла ошибка. Пожалуйста, отправьте электронное письмо на адрес support@metrica-sports.com, и мы с удовольствием поможем вам.',
    free: 'Бесплатно',
    isObsolete:
      'Ваша подписка не может быть изменена. Замените устаревшие продукты на новые, чтобы внести изменения',
    licenseKeys: 'Лицензионные ключи',
    pendingRefundRequest:
      'Нельзя вносить дополнительные изменения в эту подписку, пока есть нерассмотренный запрос на возврат средств.',
    undoCancel: 'Отменить отмену подписки',
    undoPause: 'Отменить приостановку подписки',
    updatePaymentMethod: 'Обновить способ оплаты',
    updatedSuccessfully: 'Подписка успешно обновлена.',
    yourPlans: 'Ваши Планы',
    yourSubscription: 'Ваша подписка'
  },
  successfulPayment: {
    activateLicence:
      'Активируйте вашу лицензию или кредит Cloud, введя предоставленный лицензионный ключ.',
    allTheBest: 'Всего наилучшего,',
    applyKey: 'Применить ключ к этому аккаунту',
    applyKeyDescription:
      'Хотели бы вы автоматически применить лицензионный ключ к учетной записи, в которой вы вошли?',
    downloadPlay: '%{pre}Скачайте PLAY%{post} через наш веб-сайт.',
    faq: 'Чтобы помочь вам начать с вашего заказа, ознакомьтесь с разделом %{pre}Часто Задаваемые Вопросы%{post} на нашем сайте. Вот краткое изложение информации, которая может быть наиболее актуальной для вас на данном этапе:',
    goToSummary:
      'Если нет, пожалуйста, обратитесь к приведенному ниже резюме для получения инструкций по активации.',
    happyPlay:
      'Мы надеемся, что все понятно, но, пожалуйста, не стесняйтесь обращаться к нам, если у вас возникнут какие-либо вопросы или сомнения. Счастливого PLAY!',
    helpDesk:
      'Посетите наш %{pre}HelpDesk%{post} для получения общей информации об использовании PLAY.',
    keyApplied: 'Ключ применён',
    licenseKey: 'Ваш ключ лицензии: %{pre}%{licenseKey}%{post}.',
    metricaSportsTeam: 'Команда Metrica Sports',
    settingsCloud: 'Посетите наш %{pre}Cloud%{post} и перейдите в меню настроек, чтобы:',
    technicalSupport:
      'Для технической поддержки заполните контактную форму, доступную в PLAY/Cloud, или отправьте электронное письмо на адрес %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'Пожалуйста, обратите внимание, что наши подписки автоматически продлеваются до тех пор, пока они не будут явно отменены через настройки в %{pre}Cloud%{post}, в соответствии с нашими Условиями использования.',
    thanks:
      'Спасибо за ваш заказ! Мы успешно его обработали и прикрепили ваши лицензионные ключи ниже.',
    title: 'Подтверждение заказа',
    updatePayment: 'Обновите ваш способ оплаты.',
    updateSubscription: 'Отмените или измените вашу подписку.'
  },
  surfaces: {
    automaticVp: 'Поверхности, недоступные в видеопроектах с более старой версией ATD',
    delete: 'Удалить файл Surface',
    needsAtd: 'Сначала запустите процесс ATD, чтобы запустить процессы Surface.',
    needsElite:
      'Пользовательские поверхности доступны в плане Elite. Для получения более подробной информации свяжитесь с нами',
    needsFootballSideView:
      'Поверхности, недоступные в видеопроектах, содержащих широкоугольный видеофайл, не относящийся к футболу, или видеофайл телетрансляции.',
    pitchControl: 'Запустив этот процесс, вы сможете добавить визуализацию Pitch Control в Play.',
    pitchControlProcessed: 'Поверхность управления полем обработана',
    pitchControlProcessing: 'Обработка управления полем',
    processed: 'Файл Surface загружен',
    processing: 'Обработка файла Surface',
    title: 'Поверхности',
    titlePitchControl: 'Управление полем',
    upload: 'Загрузите свою поверхность',
    uploading: 'Загрузка поверхности...'
  },
  trackingFifaFormat: {
    download: 'Скачать файл формата FIFA EPTS',
    needsElite:
      'Эта функция доступна только после импорта соответствующих файлов в модуль интеграции данных, видеопроекты с автоматическим Tracking данных или видеопроекты в плане Elite.',
    processed: 'Обработан формат FIFA EPTS',
    start:
      'Запустите процесс создания файла формата FIFA EPTS. После этого вы сможете скачать файл',
    title: 'Формат FIFA EPTS',
    titleDownload: 'Скачать формат FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'Получите наиболее подходящий пакет минут Cloud Engine:',
    minutePackLinks: {
      minutes_10: 'Получите 10 минут за 10 евро',
      minutes_100: 'Получите 100 минут за 90 евро',
      minutes_1000: 'Получите 1000 минут за 750 евро',
      minutes_10000: 'Получите 10 000 минут за 5 000 евро'
    },
    noMinutes: {
      description:
        'У вас недостаточно минут для создания этого видеопроекта. Знаете ли вы, что вы можете получить пакеты минут независимо от вашего плана?',
      secondDescription: '',
      title: 'Получите больше минут для создания видеопроекта!'
    },
    notEnoughMinutes: {
      description: 'Сожалеем, что у вас недостаточно минут для процесса ATD в ваших видеопроектах.',
      secondDescription:
        'Если вы хотите обработать ATD, вам нужно будет добавить больше минут к своей учетной записи. Минуты, которые вы получите, не сгорают!',
      title: 'Недостаточно минут'
    }
  },
  video: {
    cancelUpload: 'Отменить загрузку',
    delete: 'Удалить видео',
    download: 'Скачать видео',
    title: 'Видеофайл',
    upload: 'Загрузить видео',
    uploaded: 'Видео загружено',
    uploading: 'Загрузка видео'
  },
  videoProject: {
    delete: 'Удалить видеопроект',
    edit: 'Редактировать видео проект',
    processWaitingJobs: 'Обрабатывать ожидающие задания',
    title: 'Видеопроект'
  }
}
