/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import { Button } from '@material-ui/core'
import moment from 'moment/dist/moment'
import { toast } from 'react-toastify'
import { theme } from '../../../constants'
import * as Translations from '../../../translations'
import openInNewTab from '../../../utils/openInNewTab'
import orderService from '../../../services/order'
import * as Actions from '../../admin/actions'

const styles = {}
styles.container = css`
  min-width: 600px;
`
styles.fontBolder = {
  fontWeight: 'bolder',
  color: theme.palette.common.charolBlack
}
styles.billingWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-bottom: 10px;
`
styles.upgradeButtonContainer = css`
  display: flex;
  justify-content: right;
`
styles.upgradeButton = {
  ...styles.backgroundGradient,
  color: theme.palette.common.charolBlack,
  textTransform: 'none',
  position: 'absolute'
}
styles.planTitle = css`
  color: ${theme.palette.common.charolBlack};
  font-weight: bolder;
  font-size: 18px;
`
styles.infoPlanNewLine = css`
  padding-top: 10px;
  padding-bottom: 3px;
`
styles.infoPlanValue = css`
  color: ${theme.palette.common.grey};
  font-size: 13px;
`
styles.infoPlanDescription = css`
  color: ${theme.palette.common.grey};
`
styles.planSeparator = css`
  border-top: 2px solid #888;
  width: 90%;
`
styles.billingButton = css`
  color: ${theme.palette.common.blue};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`
styles.billingButtonDescription = css`
  color: ${theme.palette.common.grey};
  font-size: 11px;
`
styles.cancelButton = css`
  color: ${theme.palette.common.grey};
  cursor: pointer;
  font-size: 13px;
  margin-top: 15px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

function PlanStripe(props) {
  const englishPlans = Translations.English.phrases.plans
  const planKey = Object.keys(englishPlans).find(
    p => englishPlans[p] === props.subscription.planName
  )

  return (
    <div key={props.subscription.customerId} css={styles.container}>
      <div css={styles.billingWrapper}>
        <div css={styles.upgradeButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              openInNewTab(
                props.subscription.linkUpdatePlan || props.subscription.linkCustomerPortal
              )
            }
            style={styles.upgradeButton}
          >
            {window.I18n.t('settings.upgradePlan')}
          </Button>
        </div>
        <span css={styles.planTitle}>
          {window.I18n.t('plans.plan', {
            planName: planKey ? window.I18n.t(`plans.${planKey}`) : props.subscription.planName
          })}
        </span>
        {props.subscription.order && (
          <React.Fragment>
            <span css={styles.infoPlanNewLine}>
              <span css={styles.fontBolder}>{window.I18n.t('settings.quantity')}</span>
              <span> </span>
              <span css={styles.infoPlanValue}>
                {props.subscription.order.items.length
                  ? props.subscription.order.items[0].quantity
                  : 1}
              </span>
            </span>
            {props.subscription.order.cancel_at && (
              <span>
                <span css={styles.infoPlanDescription}>{window.I18n.t('settings.cancelAt')}</span>
                <span> </span>
                <span css={styles.fontBolder}>
                  {_getDateFormatted(props.subscription.order.cancel_at)}
                </span>
              </span>
            )}
            {!props.subscription.order.cancel_at && props.subscription.order.renewal_at && (
              <span>
                <span css={styles.infoPlanDescription}>{window.I18n.t('settings.renewAt')}</span>
                <span> </span>
                <span css={styles.fontBolder}>
                  {_getDateFormatted(props.subscription.order.renewal_at)}
                </span>
              </span>
            )}
          </React.Fragment>
        )}

        {props.subscription.userKey && (
          <React.Fragment>
            <span css={styles.infoPlanNewLine}>
              <span css={styles.fontBolder}>{window.I18n.t('settings.userKey')}</span>
              <span> </span>
              <span css={styles.infoPlanDescription}>
                {window.I18n.t('settings.userKeyUsages', {
                  current: props.subscription.userKey.count_usage,
                  max: props.subscription.userKey.max_usage
                })}
              </span>
            </span>
            <span css={styles.infoPlanValue}>{props.subscription.userKey.code}</span>
          </React.Fragment>
        )}
        <span
          css={styles.billingButton}
          onClick={() => openInNewTab(props.subscription.linkCustomerPortal)}
        >
          {window.I18n.t('settings.billingSettings')}
        </span>
        <span css={styles.billingButtonDescription}>
          {window.I18n.t('settings.billingSettingsDescription')}
        </span>
      </div>
      <hr css={styles.planSeparator} />
    </div>
  )
}

function _getDateFormatted(date) {
  return moment(date).format('LL')
}

PlanStripe.defaultProps = {}

PlanStripe.propTypes = {
  subscription: PropTypes.object.isRequired
}

export default PlanStripe
