/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import { theme } from '../../../../constants'

const grey = theme.palette.common.greyHover

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom: 1px solid ${grey};
  `,
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  innerIcon: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.85);
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function PlaylistMenuItem(props) {
  const isUploaded = props.playlistFileUploaded(false)
  const disabledItem = !isUploaded
  const isComplete = isUploaded

  const isUploaded3 = props.playlistFileUploaded(true)
  const disabledItem3 = !isUploaded3
  const isComplete3 = isUploaded3

  return (
    <div css={styles.container}>
      <Tooltip
        title={window.I18n.t('playlist.notProcessed')}
        enterDelay={200}
        placement="top"
        {...(disabledItem ? {} : { open: false })}
      >
        <div>
          <div
            css={css`
              ${styles.itemContainer};
              pointer-events: ${disabledItem ? 'none' : 'all'};
            `}
          >
            <div css={styles.icon} />
            <div css={styles.content} className="video-menu-item-content">
              <TitleLabel isComplete={isComplete} disabledItem={disabledItem} play3={false} />
              <div css={styles.icons}>
                {playlistMenuItemsUI(isComplete, disabledItem, props.downloadPlaylistFile, false)}
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
      {
        <Tooltip
          title={window.I18n.t('playlist.notProcessed')}
          enterDelay={200}
          placement="top"
          {...(disabledItem3 ? {} : { open: false })}
        >
          <div>
            <div
              css={css`
                ${styles.itemContainer};
                pointer-events: ${disabledItem3 ? 'none' : 'all'};
              `}
            >
              <div css={styles.icon} />
              <div css={styles.content} className="video-menu-item-content">
                <TitleLabel isComplete={isComplete3} disabledItem={disabledItem3} play3 />
                <div css={styles.icons}>
                  {playlistMenuItemsUI(
                    isComplete3,
                    disabledItem3,
                    props.downloadPlaylistFile,
                    true
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      }
    </div>
  )
}

function playlistMenuItemsUI(isComplete, disabledItem, downloadPlaylistFile, play3) {
  if (!isComplete) {
    return
  }

  return (
    <React.Fragment>
      <Tooltip title={window.I18n.t('playlist.download')} enterDelay={400} placement="top">
        <Icon
          css={css`
            ${styles.iconTag};
            ${styles.iconButton};
            color: ${theme.palette.secondary.main};
            ${disabledItem ? styles.disableIcon : ''};
          `}
          onClick={() => downloadPlaylistFile(play3)}
        >
          cloud_download
        </Icon>
      </Tooltip>
      <Tooltip title={window.I18n.t('playlist.processed')} enterDelay={400} placement="top">
        <Icon
          css={css`
            ${styles.iconTag};
            color: ${theme.palette.common.successGreen};
          `}
        >
          check_circle_outline
        </Icon>
      </Tooltip>
    </React.Fragment>
  )
}

// eslint-disable-next-line react/prop-types
function TitleLabel({ disabledItem, isComplete, play3 }) {
  const prefix = play3 ? 'Play3' : 'Play2'
  return (
    <div
      css={css`
        ${styles.name};
        color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
      `}
    >
      {prefix}: {window.I18n.t(isComplete ? 'playlist.titleDownload' : 'playlist.title')}
    </div>
  )
}

PlaylistMenuItem.defaultProps = {}

PlaylistMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  playlistFileUploaded: PropTypes.func.isRequired,
  downloadPlaylistFile: PropTypes.func.isRequired
}

export default PlaylistMenuItem
