/** @jsx jsx */
// eslint-disable-next-line
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { theme } from '../../../constants'

const styles = {}
styles.wrapper = css`
  margin-bottom: 30px;
`
styles.selectFileContainer = css`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
styles.selectFileButton = {
  borderRadius: '3px',
  color: theme.palette.common.charolBlack,
  background: theme.palette.common.mainGradient
}
styles.selectFileTextContent = css`
  flex: 1;
  margin-left: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 13px;
  position: relative;
  justify-content: space-between;
`
styles.closeIcon = css`
  color: #444444;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
`
styles.videoRequired = css`
  font-size: 12px;
  color: ${theme.palette.error.main};
  position: absolute;
  bottom: -23px;
  left: 0;
`
styles.clearInputVideo = css`
  color: #fff;
  background-color: grey;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: default;
  margin-left: 2px;
  margin-top: 7px;
  cursor: pointer;
  position: relative;
  left: 25px;
  transform: scale(0.9);
  bottom: 4px;
`
styles.title = css`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 5px;
`
styles.icon = css`
  color: ${theme.palette.common.successGreen};
  right: 0;
  height: 100%;
  position: absolute;
  top: 2px;
`
styles.fileNameContainer = css`
  color: #444444;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`
styles.errorTrackingLink = css`
  font-size: 13px;
  text-transform: none;
  color: ${theme.palette.error.main};
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: -3px;
  padding: 5px;
  cursor: pointer;
`

class UploadFile extends PureComponent {
  state = {
    videoFilename: this.props.fileName,
    selectedFile: null,
    isSubmitting: false
  }

  selectVideoFile = () => {
    if (this.props.loading || this.props.uploaded || this.state.videoFilename) {
      return
    }

    this.selectFileInputEl.click()
  }

  fileChangedHandler = event => {
    event.persist()
    const eventTarget = event.target
    const selectedFile = eventTarget && eventTarget.files && eventTarget.files[0]
    const videoFilename = selectedFile && selectedFile.name
    if (selectedFile && videoFilename) {
      this.setState(
        {
          videoFilename,
          selectedFile
        },
        () => {
          this.props.onChange({
            ...this.props.file,
            error: false,
            showRequired: false,
            fileName: videoFilename,
            data: selectedFile
          })
        }
      )
    } else {
      this.clearInputVideo()
    }
  }

  clearInputVideo = e => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.setState(
      {
        videoFilename: '',
        selectedFile: null
      },
      () => {
        this.props.onChange({
          ...this.props.file,
          error: false,
          showRequired: false,
          fileName: '',
          data: null
        })
      }
    )
  }

  deleteFile = e => {
    e.stopPropagation()
    e.preventDefault()
    this.props.onDeleteFile(this.props.file).then(() => {
      this.clearInputVideo()
    })
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <div css={styles.title}>
          {this.props.title} ({this.props.extension})
        </div>
        <div css={styles.selectFileContainer}>
          <Button
            color="primary"
            variant="contained"
            style={styles.selectFileButton}
            onClick={this.selectVideoFile}
          >
            {window.I18n.t('dataIntegration.selectFile')}
          </Button>
          <div
            css={css`
              ${styles.selectFileTextContent};
              border-bottom: ${this.props.showRequired
                ? `2px solid ${theme.palette.error.main}`
                : '1px solid lightgrey'};
              margin-right: 25px;
            `}
            onClick={this.selectVideoFile}
          >
            {this.state.videoFilename ? (
              <React.Fragment>
                <span css={styles.fileNameContainer}>{this.state.videoFilename}</span>
                {this.props.uploaded && !this.props.loading && (
                  <Icon css={styles.icon}>check_circle_outline</Icon>
                )}
                <Tooltip title={window.I18n.t('dataIntegration.deleteFile')} placement="top">
                  <span
                    css={styles.clearInputVideo}
                    onClick={this.deleteFile}
                    style={{
                      display:
                        this.state.videoFilename && !this.props.loading ? 'inline-block' : 'none'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </span>
                </Tooltip>
              </React.Fragment>
            ) : (
              <span
                css={css`
                  color: ${this.props.showRequired
                    ? theme.palette.error.main
                    : theme.palette.common.black};
                `}
              >
                {window.I18n.t('common.noFileChosen')}
              </span>
            )}
            {this.props.loading && (
              <CircularProgress css={styles.icon} color="primary" size={24} thickness={4} />
            )}
            {this.props.error && (
              <span
                css={styles.errorTrackingLink}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.props.onTryAgain(this.props.file)
                }}
              >
                {window.I18n.t('common.errorTryAgain')}
              </span>
            )}
            {this.props.showRequired && (
              <span css={styles.videoRequired}>{window.I18n.t('common.required')}</span>
            )}
          </div>
          <input
            ref={el => {
              this.selectFileInputEl = el
            }}
            style={{ display: 'none' }}
            type="file"
            id={`file${this.props.file.id}`}
            accept={this.props.extension}
            value=""
            onChange={this.fileChangedHandler}
          />
        </div>
      </div>
    )
  }
}

UploadFile.propTypes = {
  title: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  uploaded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showRequired: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired
}

export default UploadFile
