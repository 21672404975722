/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  withStyles
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Icon from '@material-ui/core/Icon'
import { Formik } from 'formik'
import { Languages, theme } from '../../../constants'
import * as Actions from '../../admin/actions'
import orderService from '../../../services/order'
import TextSwitch from '../../../utils/textSwitch'

const styles = {}
styles.sectionContainer = css`
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.palette.common.charolBlack}80;
`
styles.sectionHeader = css`
  width: 25%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-weight: bold;
`
styles.sectionContent = css`
  display: flex;
  width: 40%;
`
styles.infoIcon = css`
  color: ${theme.palette.text.disabled};
  position: relative;
  left: 5px;
  transform: rotate(5deg);
`
styles.progressBarWrapper = css`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  position: absolute;
  bottom: -10px;
`
styles.deleteConfirmation = css`
  color: ${theme.palette.error.main};
  cursor: pointer;
  left: 0;
  padding-top: 5px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`
styles.notificationsContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
`
styles.switchContainer = css`
  position: relative;
  top: 1px;
  left: 10px;
`
styles.dropdownContainer = css`
  position: relative;
  width: 100%;
  top: 1px;
`
styles.selectContainer = css`
  border-radius: 2px;
  background: transparent;
  width: 100%;
  text-align: center;
  height: 40px;
`
styles.selectForm = css`
  color: ${theme.palette.common.charolBlack};
  height: 40px;
  font-weight: 500;
`
styles.selectOption = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
styles.languageLabel = css`
  display: flex;
  flex-grow: 1;
`
styles.languageIcon = css`
  width: 36px;
  margin-bottom: 6px;
`
styles.button = {
  padding: '0px 50px',
  marginLeft: '16px',
  color: theme.palette.common.charolBlack,
  boxShadow: 'none'
}
styles.cancelButton = {
  ...styles.button,
  textTransform: 'none'
}
styles.okButton = {
  ...styles.button
}
styles.emailIcon = {
  color: '#828282',
  fontSize: 28,
  marginRight: 10,
  marginBottom: 3
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.charolBlack
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 16px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

const DialogContent = withStyles(() => ({
  root: {
    overflow: 'unset',
    padding: '0px !important',
    '& .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 0.5
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 1
    },
    '& .MuiSwitch-thumb': {
      color: theme.palette.common.white
    }
  }
}))(MuiDialogContent)

const CssSelect = withStyles({
  select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '16px'
  }
})(Select)

const inputText = {
  input: { color: theme.palette.common.charolBlack },
  label: { color: theme.palette.common.charolBlack },
  width: '100%',
  marginTop: 0,
  marginBottom: 0
}

class Settings extends Component {
  formInitialValues = {
    email: this.props.user.email,
    notifications: this.props.user.email_notifications,
    language: this.props.user.language || 'en',
    password: '',
    password2: '',
    showPassword: false
  }

  validateForm = values => {
    const errors = {}
    if (!values.password) {
      errors.password = window.I18n.t('common.required')
    }

    if (!values.password2) {
      errors.password2 = window.I18n.t('common.required')
    }

    if (values.password && values.password2 && values.password !== values.password2) {
      errors.password2 = window.I18n.t('settings.passwordsDontMatch')
    }

    return errors
  }

  onToggleNot = (value, formik) => e => {
    this.props.persistNotification({ email_notifications: !value })
    formik(e)
  }

  onToggleShowPassword = formik => () => {
    formik.setFieldValue('showPassword', !formik.values.showPassword)
  }

  handleSelectChange = formik => e => {
    this.props.persistLanguage({ language: e.target.value })
    formik(e)
  }

  cancelSubscription = subscription => {
    if (!window.profitwell) {
      return
    }

    window
      .profitwell('init_cancellation_flow', {
        user_id: subscription.order.customer_id,
        subscription_id: subscription.order.code
      })
      .then(async result => {
        // This means the customer accepted a salvage attempt or salvage offer.
        // Thus, we have to update the user in case he has downgrade the plan.
        if (result.status === 'retained') {
          await Actions.updateUser()
          return
        }

        // This means the customer either aborted the flow (i.e. they clicked on
        // "never mind, I don't want to cancel"), or could also be the case the
        // widget couldn't be shown properly for some reason.
        if (result.status === 'aborted' || result.status === 'error') {
          return
        }

        // At this point, the customer ended deciding to cancel (i.e.
        // they rejected the salvage attempts and the salvage offer).
        await orderService.cancelSubscription(subscription.order.code)
        await Actions.updateUser()
        toast.info(window.I18n.t('settings.planCanceled'))
      })
  }

  render() {
    return (
      <Formik
        initialValues={this.formInitialValues}
        validate={this.validateForm}
        onSubmit={this.props.onSubmit}
      >
        {formik => (
          <form noValidate onSubmit={formik.handleSubmit} method="POST">
            <DialogContent>
              <div css={styles.sectionContainer} style={{ paddingBottom: '36px' }}>
                <div css={styles.sectionHeader}>{window.I18n.t('common.email')}</div>
                <div css={styles.sectionContent}>
                  <CssTextField
                    variant="outlined"
                    value={formik.values.email}
                    type="email"
                    name="email"
                    margin="normal"
                    css={inputText}
                    disabled
                  />
                </div>
              </div>

              <div css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>{window.I18n.t('settings.newPassword')}</div>
                <div css={styles.sectionContent}>
                  <CssTextField
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    type={formik.values.showPassword ? 'text' : 'password'}
                    name="password"
                    margin="normal"
                    css={inputText}
                    error={
                      formik.errors.password && formik.touched.password && !!formik.errors.password
                    }
                    helperText={
                      formik.errors.password && formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              </div>

              <div css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>{window.I18n.t('settings.confirmNewPassword')}</div>
                <div css={styles.sectionContent}>
                  <CssTextField
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password2}
                    type={formik.values.showPassword ? 'text' : 'password'}
                    name="password2"
                    margin="normal"
                    css={inputText}
                    error={
                      formik.errors.password2 &&
                      formik.touched.password2 &&
                      !!formik.errors.password2
                    }
                    helperText={
                      formik.errors.password2 && formik.touched.password2 && formik.errors.password2
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" css={{ position: 'absolute', right: '0px' }}>
                          {(formik.values.password || formik.values.password2) && (
                            <IconButton
                              name="showPassword"
                              aria-label="toggle password visibility"
                              onClick={this.onToggleShowPassword(formik)}
                            >
                              {formik.values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>

              <div css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>{window.I18n.t('settings.emailNotifications')}</div>
                <div css={styles.sectionContent}>
                  <div css={styles.notificationsContainer}>
                    <div css={styles.switchContainer}>
                      <TextSwitch
                        containerStyles={{ display: 'flex', alignItems: 'center' }}
                        checked={formik.values.notifications}
                        onChange={this.onToggleNot(
                          formik.values.notifications,
                          formik.handleChange
                        )}
                        onBlur={formik.handleBlur}
                        value={formik.values.notifications}
                        name="notifications"
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>{window.I18n.t('settings.language')}</div>
                <div css={styles.sectionContent}>
                  <div css={styles.dropdownContainer}>
                    <FormControl css={styles.selectContainer}>
                      <CssSelect
                        variant="outlined"
                        onChange={this.handleSelectChange(formik.handleChange)}
                        name="language"
                        value={formik.values.language}
                        css={styles.selectForm}
                      >
                        {Object.values(Languages).map(v => (
                          <MenuItem key={v.value} value={v.value} css={styles.selectOption}>
                            <span css={styles.languageLabel}>{v.label}</span>
                            <img src={v.icon} css={styles.languageIcon} />
                          </MenuItem>
                        ))}
                      </CssSelect>
                    </FormControl>
                  </div>
                </div>
              </div>

              {this.props.prefix && (
                <div css={styles.sectionContainer}>
                  <div css={styles.sectionHeader}>
                    {window.I18n.t('settings.organizationLabel')}
                    <Tooltip
                      // eslint-disable-next-line max-len
                      title={window.I18n.t('settings.organizationTooltip')}
                      placement="bottom"
                    >
                      <Icon css={styles.infoIcon}>info</Icon>
                    </Tooltip>
                  </div>
                  <div css={styles.sectionContent}>
                    <CssTextField
                      variant="outlined"
                      value={this.props.prefix}
                      type="email"
                      name="email"
                      margin="normal"
                      css={inputText}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>
                  <span onClick={this.props.deleteUserModal} css={styles.deleteConfirmation}>
                    {window.I18n.t('settings.deleteAccount')}
                  </span>
                </div>
                <div
                  css={styles.sectionContent}
                  style={{ width: '100%', justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="outlined"
                    style={styles.cancelButton}
                    disabled={formik.isSubmitting}
                    type="submit"
                    onClick={this.props.goBack}
                  >
                    {window.I18n.t('common.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.okButton}
                    disabled={formik.isSubmitting}
                    type="submit"
                  >
                    {window.I18n.t('common.ok')}
                  </Button>
                </div>
              </div>

              <div css={styles.progressBarWrapper}>
                {formik.isSubmitting && (
                  <CircularProgress color="primary" size={30} thickness={4} />
                )}
              </div>
            </DialogContent>
          </form>
        )}
      </Formik>
    )
  }
}

Settings.propTypes = {
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  persistNotification: PropTypes.func.isRequired,
  persistLanguage: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  deleteUserModal: PropTypes.func.isRequired
}

export default Settings
