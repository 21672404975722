export default {
  addKeyModal: {
    action: 'Uygula',
    errorMessage:
      'Anahtar uygulanırken hata oluştu. Sorun devam ederse, Metrica Sports destek ekibiyle iletişime geçin.',
    minutesMessage: 'Anahtarınız işlendi. Şimdi %{minutes} dakikanız var.',
    placeholder: 'Bir anahtar yazın...',
    successMessage: 'Anahtar başarıyla işlendi',
    title: 'Bir Anahtar Ekle'
  },
  admin: {
    NoGames: 'Maç yok, ilk maçınızı ekleyin...',
    NoResults: 'Sonuç yok...',
    addKey: 'Anahtar Ekle',
    atd: 'OTV',
    automatedTrackingData: 'Otomatik Takip Verisi (OTV)',
    automatedTrackingDataJobSuccess:
      "%{vpName} üzerindeki Otomatik Takip Verisi işlemi tamamlandı ve artık Metrica Play'de kullanıma sunuldu",
    automatedTrackingDataProcessedTooltip: 'Otomatik Takip Verisi işlendi',
    awayTeam: 'Deplasman Takımı',
    awayTeamScore: 'Deplasman Takımı Skoru',
    ballDetection: 'Top Algılama',
    ballTracking: 'Top Takibi',
    ballTrackingProcessedTooltip: 'Top Algılama işlendi',
    ballTrackingTooltip:
      "Bu işlem topu algılayacak ve Play'de Canlı Diziliş (2D Saha) sekmesinde görüntülenmesini sağlayacak. Top koordinatları, FIFA EPTS formatındaki dosyada da mevcut olacak.",
    cancelATDProcessSuccessfully: 'Otomatik Takip Verisi işlemi %{vpName} üzerinde iptal edildi',
    cancelDataIntegrationProcessSuccessfully:
      'Veri Entegrasyon işlemi %{vpName} üzerinde iptal edildi',
    cancelEventsProcessSuccessfully: 'Smart Tagging işlemi %{vpName} üzerinde iptal edildi.',
    cancelProcessTooltip: 'İşlemi iptal et',
    cancelSurfacesProcessSuccessfully: 'Yüzey işlemi %{vpName} üzerinde iptal edildi',
    cancelTrackingFifaFormatProcessSuccessfully:
      'FIFA formatı takip işlemi %{vpName} üzerinde iptal edildi',
    category: 'Kategori',
    checking: 'Kontrol ediliyor...',
    cloudMinutes: 'Bulut Dakikaları',
    competition: 'Müsabaka',
    connectionFailed: 'Bağlantı başarısız',
    connectionFailedText:
      'Durum bilgisini almakta bir sorun var gibi görünüyor. Lütfen internet bağlantınızı kontrol edin ve sayfayı yeniden yükleyin. Sorun devam ederse, lütfen destek ekibiyle iletişime geçin',
    correctedTracking: 'Tracking Düzeltme İşlemi',
    correctionUsage: 'Bu Video Projesini Tracking Düzeltme için mi kullanacaksınız?',
    correctionUsageTooltip:
      "Tracking Düzeltme Yöneticisi (TDY), daha önce ATD'mizle oluşturulan izleri düzeltmek için Play'de kullanıcı odaklı bir modüldür ve herhangi bir futbol oyunu için otomatik olarak kimliksiz veriler oluşturur. Yanlış izleri düzeltmek ve onlara bir kimlik atamak için TDY'yi kullanın. Daha fazla bilgi için bize ulaşın.",
    createVideoProject: 'Video Projesi Oluştur',
    creatingGame: 'Video Projesi Oluşturma...',
    dataIntegrationFailed:
      '%{vpName} üzerinde Veri Entegrasyonu başarısız oldu. Veri Entegrasyon paneline gidin, dosyalarınızı kontrol edin ve tekrar deneyin. Hata devam ederse, lütfen Metrica Sports destek ekibiyle iletişime geçin.',
    dataIntegrationJobSuccess: '%{vpName} için Veri Entegrasyon işlemi başarıyla tamamlandı',
    date: 'Tarih',
    description: 'Açıklama',
    editedSuccessfully: '%{vpName} başarıyla düzenlendi',
    errorContactSupport: 'Hata oluştu. Destek ile iletişime geçin',
    errorProcessTooltip: 'İşlemde hata',
    eventsDetection: 'Otomatik Etiketleme',
    eventsDetectionProcessedTooltip: 'Smart Tagging işlendi.',
    eventsDetectionTooltip: {
      footer: '*30 dakikadan uzun videolar için mevcuttur.',
      header:
        'Oyun sırasında gerçekleşen bir dizi olayı otomatik olarak tespit etmek için teknolojimizden faydalanın.',
      improved: 'İyileştirilmiş'
    },
    fieldRecognition: 'Saha Algılama',
    fieldTrackingProcessedTooltip: 'Saha Algılama işlendi',
    fieldTrackingTooltip:
      "Play'de mevcut alana bağlı olarak hız ve mesafe değerleri ile tüm çizimleri içeren görselleştirmeler eklemek için Saha Algılamayı İşleyin.",
    fileDeletedSuccessfully: '%{fileName}, %{vpName} için başarıyla silindi',
    fileUploadedError: '%{vpName} için %{fileName} yüklenirken hata oluştu',
    fileUploadedSuccessfully: '%{fileName}, %{vpName} için başarıyla yüklendi',
    forkedGameLimit:
      'Bu Video Projesi başka bir Kuruluşa ait, bu yüzden onu değiştirmek mümkün değil',
    gameDeletedSuccessfully: '%{game} başarıyla silindi',
    gameVideoProject: 'Maç Video Projesi',
    getMoreMinutes: 'Daha fazla al',
    homeTeam: 'Ev Sahibi Takım',
    homeTeamScore: 'Ev Sahibi Takım Skoru',
    initializing: 'Başlatılıyor...',
    jobFailed:
      '%{process} işlemi %{vpName} için başarısız oldu. Lütfen Metrica Sports destek ekibiyle iletişime geçin',
    liveCoding: 'veya canlı kodlamaya devam mı?',
    liveCodingTooltip:
      "Canlı Kodlama yapmadan da bir video dosyası seçerek bir Video Projesi oluşturabilirsiniz. Videonuzu, kodlanmış Video Projesi içerisinde Otomatik Takip Verisi'ne sahip olmak için ekleyebilirsiniz.",
    localTeamOnTheLeft: "'Ev Sahibi Takım' ilk yarıda sahanın sol tarafında mı yer alıyor?",
    localTeamOnTheLeftTooltip:
      'Otomatik Etiketlemeyi işlemek için, her takımın sahanın hangi tarafında bulunacağını belirtin. Doğrulamak için yüklediğiniz videoyu izleyin.',
    noFootballSideViewTooltip:
      'Bu Video Projesi, futbol maçına ait olmayan bir geniş açı veya TV yayını video dosyası içeriyor. Bu nedenle yalnızca Oyuncu ve Takım Algılama işlendi.',
    noMatches: 'Eşleşme yok',
    noSubscriptionOwnerTooltip:
      "Yükseltme yapmak için aboneliğinizin yöneticisi olan '%{email}' ile iletişime geçin veya kendi sayfanızı oluşturmak üzere Planlar sayfamıza gidin",
    noVideoFile: 'Video dosyası yok',
    notPossibleToDeleteThisEntry: 'Bu girişi silmek mümkün değil',
    numberOfHalves: 'Bu oyunun kaç devresi var?',
    numberOfHalvesTooltip:
      'Otomatik Etiketleme işlemini gerçekleştirmek için, yüklediğiniz videoda kaç oyun periyodunun yer aldığını belirtin.',
    oldAtdTooltip: "Bu video, OTV'nin daha eski bir sürümüyle işlendi",
    onRequest: 'İstek üzerine',
    organizations: 'Kuruluşlar',
    place: 'Konum',
    playerTracking: 'Oyuncu ve Takım Algılama',
    playerTrackingProcessedTooltip: 'Oyuncu Algılama işlendi',
    playerTrackingTooltip:
      "Bu süreç, her takımın kalecilerini ve diğer 10 oyuncuyu tespit edecektir. Böylelikle, Canlı Diziliş'i (2D Saha) görüntülemenizi ve Play'de otomatik olarak Oyuncu Görselleştirmeleri ekleyebilmenizi sağlar. Oyuncu koordinatları, FIFA EPTS formatındaki dosyada da yer alacaktır.",
    postProcessingData: 'İşlem sonrası veriler...',
    preProcessingAtd: 'Ön işleme...',
    processingTooltip: 'İşleniyor',
    readyToUpload: 'Karşıya yüklemeye hazır',
    search: 'Ara…',
    selectVideoFile: 'Video dosyası seçin',
    settingUpAtd: 'Algılama ayarlanıyor...',
    settings: 'Ayarlar',
    singleVideoProject: 'Tek Video Projesi',
    startAtd: "OTV'yi başlat",
    startFifaMessage:
      '%{vpName} için FIFA Entegrasyon süreci başlatıldı. Tamamlandığında bir bildirim görüntüleyeceğiz. Lütfen bekleyiniz.',
    startProcessingWaitingJobsFailed: 'Bekleyen işler gönderilemedi',
    startProcessingWaitingJobsSuccessfully: 'Bekleyen işler başarıyla gönderildi',
    startSsMessage:
      '%{vpName} için ikinci Spektrum Entegrasyon süreci başladı. Tamamlandığında bir bildirim görüntüleyeceğiz. Lütfen bekle.',
    startStatsMessage:
      '%{vpName} için İstatistik Entegrasyon süreci başladı. Tamamlandığında bir bildirim görüntüleyeceğiz. Lütfen bekleyiniz.',
    startTracabDflMessage:
      '%{vpName} için Tracab DFL Entegrasyon süreci başlatıldı. Tamamlandığında bir bildirim görüntüleyeceğiz. Lütfen bekleyiniz.',
    startTracabMessage:
      '%{vpName} için Tracab Entegrasyon süreci başlatıldı. Tamamlandığında bir bildirim görüntüleyeceğiz. Lütfen bekleyiniz.',
    surfacesJobSuccess: '%{vpName} Yüzey İşlemi başarıyla tamamlandı',
    trackingCorrectionProcessedTooltip: 'Tracking Düzeltmesi işlendi',
    trackingFifaFormatJobSuccess:
      "%{vpName} için FIFA formatı takip işlemi başarıyla tamamlandı. Artık 'FIFA formatını indir' menü öğesini tıklayarak dosyayı indirebilirsiniz.",
    trackingLicence: 'Takip Lisansınız yok',
    uncaughtErrorRow:
      'Video Projesi hatası. Lütfen bu sayfayı yenileyin. Sorun devam ederse desteğe başvurun veya silin ve yeniden oluşturun',
    updatingGame: 'Video Projesi Güncelleniyor...',
    upgradePlan: 'Yükseltme',
    uploadAndTracking: 'Karşı Yükleme ve Takip',
    uploadingVideoFile: 'Video dosyası karşıya yükleniyor',
    videoFile: 'Video Dosyası',
    videoFootballSideView:
      'Bu video dosyası bir futbol maçının geniş açı görüntüsü mü yoksa TV yayını mı?',
    videoFootballSideViewTooltip:
      'Saha Algılama yalnızca bu tür videolar için optimize edilmiştir.',
    videoMultipartStart:
      '%{videoName} %{vpName} üzerine yükleniyor. Yükleme tamamlanana kadar BU SAYFAYI KAPATMAYIN',
    videoPlaylist: 'Bu video dosyası bir oynatma listesi mi?',
    videoPlaylistTooltip:
      "EVET'i seçerseniz, Klip Algılama çalışmaya başlar ve videonun kesildiğini zamanları gösterir. Birkaç farklı klip içeren videolar yüklüyorsanız, Klip Algılama çok kullanışlı olacaktır. Bu işlemi çalıştırdıktan sonra, Video Projesi'ni Play'de indirin. Kesik videoların kodlarının yer aldığı bir Klip Algılama kodlama dosyası oluşacaktır.",
    videoProjectSettings: 'Video Projesi Ayarları',
    videoUploadError: '%{vpName} için %{videoName} videosu yüklenemedi. Lütfen tekrar deneyin',
    videoUploaded: '%{videoName} %{vpName} üzerine yüklendi',
    vpCreated: '%{vpName} oluşturuldu',
    waitingAtd: 'Bekleniyor…'
  },
  billing: {
    address: 'Adres',
    billed: 'Faturalandı',
    completed: 'Ödendi',
    details: 'Fatura Detayları',
    history: 'Faturalama Geçmişi',
    invoiceNotFound: 'Fatura bulunamadı',
    noInvoices: 'Fatura yok.',
    past_due: 'Süresi geçmiş',
    taxId: 'Vergi Kimlik Numarası'
  },
  checkout: {
    chargeSubscription: 'Aboneliğe yükle',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*İndirim kodları ödeme sırasında uygulanabilir.',
    excTax: '(Vergi hariç)',
    immediatePayment: 'Hemen Ödeme',
    incTax: '(Dahil. vergi)',
    monthlyBilled: 'Aylık faturalandırılır.',
    nextPayment: 'Sonraki Ödeme',
    noPacks: 'Bulut Dakika Paketi Yok',
    onDate: '%{date} tarihinde',
    packs: 'Paketler',
    packsTotal: 'Toplam Paketler',
    perMonth: '/ay',
    perYear: '/yıl',
    plans: 'Planlar',
    plansTotal: 'Planların Toplamı',
    proceed: 'Ödemeye devam et',
    subtotal: 'Ara toplam',
    taxPayable: 'Ödenecek Vergi (%{percentage}%)',
    total: 'Toplam',
    unpaid:
      'Son faturanız henüz ödenmedi. Lütfen devam etmek için ödeme bilgilerinizi güncelleyin.',
    updatePaymentMethod: 'Ödeme Yöntemini Güncelle',
    updateSubscription: 'Aboneliği Güncelle',
    userDevice: '1 kullanıcı/cihaz',
    usersDevices: '%{number} kullanıcı/cihaz',
    yearlyBilled: 'Yıllık faturalandırılır.'
  },
  common: {
    back: 'Geri',
    cancel: 'İptal et',
    canceling: 'İptal ediliyor…',
    clearAll: 'Hepsini temizle',
    edit: 'Düzenle',
    email: 'E-posta',
    errorMsg: '%{name} işlenirken hata oluştu. Sorularınız için lütfen destek ile iletişime geçin.',
    errorTryAgain: 'Hata. Tekrar deneyin',
    genericError: 'Hata, işlem başarısız oldu. Sorularınız için lütfen destek ile iletişime geçin.',
    loading: 'Yükleniyor...',
    login: 'Oturum aç',
    logout: 'Çıkış Yap',
    new: 'Yeni',
    next: 'Sonraki',
    no: 'Hayır',
    noFileChosen: 'Seçili dosya yok',
    ok: 'Tamam',
    required: 'Zorunlu',
    reset: 'Şifreyi sıfırla',
    restart: 'Tekrar başlat',
    selectFile: 'Dosya Seç',
    sort: 'Sırala',
    start: 'Başlat',
    today: 'Bugün',
    uploading: 'Karşıya yükleniyor...',
    yes: 'Evet'
  },
  confirmModal: {
    description: 'Emin misiniz?',
    title: 'Onayla'
  },
  dataIntegration: {
    deleteFile: 'Dosyayı sil',
    featureNotAvailable:
      'Bu özellik mevcut planınızda bulunmamaktadır. Bu özelliğe sahip olmak daha gelişmiş bir plana geçmeniz gerekiyor. Daha fazla bilgi için bize ulaşın',
    notSideView:
      'Futbol harici geniş açı veya TV yayını video dosyası içeren Video Projelerinde Veri Entegrasyonu mevcut değildir.',
    open: 'Açık Veri Entegrasyonu',
    processJobsFirst:
      "Veri Entegrasyonu paneline erişmeden önce, Otomatik Takip Verisi'ni (OTV) işlemeye başlamanız gerekir.",
    processed: 'Veri Entegrasyonu işlendi',
    selectFile: 'Dosya Seç',
    startProcess: 'İşlemi Başlat',
    title: 'Veri Entegrasyonu',
    waiting: 'Bekleme süreci...',
    waitingProcess:
      'Oyuncu ve Saha Algılama işlemleri tamamlandıktan sonra entegrasyon süreci başlayacaktır.'
  },
  deleteModal: {
    checkboxLabel: "Ayrıca Metrica Sports'tan e-posta almayı da durdurmak istiyorum",
    confirmationType: 'Onaylamak için %{validateFormKey} yazın:',
    deleteAction: 'Evet, Sil',
    description: 'Hesabınızı kalıcı olarak silmek istediğinizden emin misiniz?',
    information:
      'Bu hesapla oluşturulan tüm Video Projelerini kaybedeceksiniz. Sildikten sonra onları kurtaramayız.',
    keepAccount: 'Hesabı Tut',
    suggestion: 'Hesabınızı muhafaza etmenizi öneririz.',
    title: 'Sonsuza kadar silinsin mi?',
    validateFormKey: 'SİL'
  },
  error: {
    oops: 'Hay aksi!',
    restart: 'Görünüşe göre hala geliştirmekte olduğumuz bir yere girmeyi deniyorsun...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging, eski bir ATD sürümüne sahip Video Projelerinde kullanılamaz.',
    needsAtd: 'Önce ATD sürecini başlatarak Smart Tagging işlemini çalıştırabilirsiniz.',
    needsFootballSideView:
      'Smart Tagging, içeriği futbol olmayan yan açıdan veya televizyon yayınından bir video dosyası içeren Video Projelerinde kullanılamaz.',
    needsGameVp: 'Smart Tagging yalnızca Maç Video Projelerinde mevcuttur.',
    needsNoPlaylist:
      'Smart Tagging, bir oynatma listesi video dosyası içeren Video Projelerinde kullanılamaz.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Yönetici kullanıcılar, parolayı sıfırlamak için özel bir uç nokta kullanmalıdır',
    notFound: 'Kullanıcı bulunamadı. Lütfen geçerli bir e-posta ile tekrar deneyin',
    success: 'Şifre sıfırlandı! Lütfen e-postanızı kontrol edin'
  },
  login: {
    contactUs: 'Bizimle iletişime geçin',
    enterYourEmail: 'E-postanızı giriniz',
    enterYourPassword: 'Şifrenizi girin',
    forgotPassword: 'Parolanızı mı unuttunuz?',
    invalidEmail: 'Geçersiz e-posta adresi',
    licenceExpired: 'Lisansınızın süresi doldu.',
    trialExpired: 'Deneme süreniz sona erdi.',
    upgradePlan: 'Planınızı yükseltin'
  },
  patterns: {
    delete: 'Şablon dosyasını sil',
    title: 'Özel Şablon Dosyası',
    upload: 'Özel Şablon dosyasını karşıya yükleyin',
    uploaded: 'Şablon dosyası karşıya yüklendi',
    uploading: 'Şablon dosyası karşıya yükleniyor'
  },
  plans: {
    academic: 'Akademik',
    addMoreProducts: '+ Daha fazla ürün ekle',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code Aylık',
    basicCodePadYearly: 'Basic+Code Yıllık',
    basicMftCodePadMonthly: 'Full Basic Aylık',
    basicMftCodePadYearly: 'Full Basic Yıllık',
    basicMftMonthly: 'Basic+MFT Aylık',
    basicMftXmlMonthly: 'Basic MFT + XML Aylık',
    basicMftXmlYearly: 'Basic MFT + XML Yıllık',
    basicMftYearly: 'Basic+MFT Yıllık',
    basicMonthly: 'Basic Aylık',
    basicXmlMonthly: 'Basic XML Aylık',
    basicXmlYearly: 'Basic XML Yıllık',
    basicYearly: 'Basic Yıllık',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elit Yıllık',
    eliteYearly: 'Elite Yıllık',
    essentialsCodeMonthly: 'Essentials + Code ∞ Aylık',
    essentialsCodeYearly: 'Essentials + Code ∞ Yıllık',
    essentialsMftCodeMonthly: 'Full Essentials Aylık',
    essentialsMftCodeYearly: 'Full Essentials Yıllık',
    essentialsMftMonthly: 'Essentials + MFT Aylık',
    essentialsMftYearly: 'Essentials + MFT Yıllık',
    essentialsMonthly: 'Essentials Aylık',
    essentialsYearly: 'Essentials Yıllık',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite Aylık',
    liteYearly: 'Lite Yıllık',
    minutes10: '10 Dakikalık Cloud Paketi',
    minutes100: '100 Cloud Dakikası Paketi',
    minutes1000: '1.000 Cloud Dakikası Paketi',
    minutes10000: '10.000 Cloud Dakikası Paketi',
    plan: '%{planName} Planı',
    primeMftMonthly: 'Prime + MFT Aylık',
    primeMftYearly: 'Prime + MFT Yıllık',
    primeMonthly: 'Prime Aylık',
    primePresetsMftMonthly: 'Full Prime Aylık',
    primePresetsMftYearly: 'Full Prime Yıllık',
    primePresetsMonthly: 'Prime + Presets Aylık',
    primePresetsYearly: 'Prime + Presets Yıllık',
    primeYearly: 'Prime Yıllık',
    pro: 'Pro',
    pro2024Monthly: 'Pro Aylık',
    pro2024Yearly: 'Pro Yıllık',
    proYearly: 'Pro Yıllık',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Aylık',
    starterCodePadMonthly: 'Code Aylık',
    starterCodePauseMonthly: 'Full Starter Aylık',
    starterCodePauseYearly: 'Full Starter Yıllık',
    starterCodeYearly: 'Starter + Code ∞ Yıllık',
    starterPauseMonthly: 'Starter + Pause ∞ Aylık',
    starterPauseYearly: 'Starter + Pause ∞ Yıllık',
    student: 'Öğrenci',
    trial: 'Deneme'
  },
  playlist: {
    download: 'Oynatma Listesi dosyasını indir',
    notProcessed: 'Oynatma listesi dosyası bu Video Projesinde işlenmedi.',
    processed: 'Oynatma listesi dosyası işlendi',
    title: 'Çalma Listesi',
    titleDownload: 'Çalma Listesini İndir'
  },
  products: {
    addOns: 'Eklentiler',
    basic: 'Sınırsız Kodlar & Anotasyonlar',
    code: 'CodePad',
    elite: {
      description:
        'Tüm analiz iş akışınızı otomatikleştirin ve kişiselleştirin. Bu plan, "Full Essentials" Planı özelliklerinin tamamına ve "Pro" Planı özelliklerinin tamamına sahip Play3\'e tam erişim ile Play2\'ye tam erişimi içerir.',
      feature1: '✓ Paylaşılan Bulut Depolama',
      feature2: '✓ Takip Verisi Entegrasyonu',
      feature3: '✓ Takip Düzeltme Yöneticisi',
      feature4: '✓ RESTful API',
      feature5: '✓ 5.000 ATD dakika paketi'
    },
    essentials: {
      addon1: 'Sınırsız Kodlama özellikleri (CodePad, Canlı Kodlama ve XML İthalatı)',
      addon2: 'Manuel Alan Takibi',
      essentialsCodeDescription:
        "Bu plan, videoyu oynatırken ve duraklamalarda sınırsız hareketli görselleştirmeler eklemeyi sağlayan Dinamik Telestrasyonu etkinleştirir. Bu plan, Manuel Alan Takibi'ni içermediği için Hız ve Mesafe görselleştirmeleri dahil değildir. Ayrıca, CodePad, Canlı Coding ve XML İçe Aktarma gibi kodlama özelliklerinin kısıtlamalarını kaldırır ve Video Projesi başına 4 kod limitini sınırsız kodlara çıkarır.",
      essentialsDescription:
        "Bu plan, videoyu oynatırken ve duraklamalarda sınırsız hareketli görselleştirmeler eklemeye olanak tanıyan Dinamik Telestrasyon'u etkinleştirir. Bu plan, Manuel Alan Takibi'ni içermediği için Hız ve Mesafe görselleştirmeleri dahil değildir. Kodlama özellikleri sınırlı kalmaya devam eder.",
      essentialsMftCodeDescription:
        "Bu plan, videoyu oynatırken ve duraklamalarda sınırsız hareketli görselleştirmeler eklemeye olanak tanıyan Dinamik Telestrasyon'u etkinleştirir ve ayrıca hız ve mesafe görselleştirmeleri eklemek için Manuel Alan Takibi'ni aktive eder. Ayrıca, CodePad, Canlı Coding ve XML İçe Aktarma gibi kodlama özelliklerinin kısıtlamalarını kaldırır ve Video Projesi başına 4 kod limitini sınırsız kodlara çıkarır.",
      essentialsMftDescription:
        'Bu plan, videoyu oynatırken ve duraklamalarda sınırsız hareket görselleştirmeleri eklemeye olanak tanıyan Dinamik Telestrasyonu etkinleştirir ve ayrıca hız ve mesafe görselleştirmeleri eklemek için Manuel Alan Takibini aktive eder. Coding özellikleri sınırlı kalmaya devam eder.',
      feature1: '✓ Maç sonrası kodlama (Code Table ile en fazla 4 kod)',
      feature2: '✓ Duraklamalarda sınırsız telestrasyon',
      feature3: '✓ Dinamik Telestrasyon',
      feature4: '✓ 3 dakikalık ATD paketi'
    },
    featuresIncluded: 'Özellikler dahildir:',
    freeFeature1: '✓ Maç sonrası kodlama (Code Table ile en fazla 4 kod).',
    freeFeature2: '✓ Manuel Takip',
    freeFeature3: '✓ Telestrasyon (150 anotasyona kadar)',
    freeFeature4: '✓ Video Dışa Aktarımı',
    freeFeatures: 'Ücretsiz Özellikler',
    fullBasic: 'Manuel Alan Takibi',
    lite: 'Otomatik Oyuncu ve Alan Takibi',
    liteDescription:
      "İleri düzey kodlama ve ileri düzey görselleştirmeler. Hepsi otomatik, Full Basic'ten 3 kat daha hızlı hale getiriyor!",
    minutesDescription:
      'Herhangi bir planı yükseltin. Otomatik Takip Verileri için dakika paketleri edinin.',
    minutesPack: 'Bulut Dakika Paketi',
    otherDescription: 'İleri düzey coding özellikleri ile temel telestrasyon ve sınırlı notlar.',
    prime: {
      addon1: 'Manuel Alan Takibi',
      addon2:
        'Özelleştirilmiş Görselleştirmeler (kendi görselleştirmelerinizi özelleştirin ve kaydedin)',
      feature1: '✓ "Full Essentials" Planı özelliklerinin tamamıyla Play2\'ye tam erişim',
      feature2: '✓ Play3 ile erişim:',
      feature2_1: 'Sıralı Duraklamalar',
      feature2_2: 'İleri Düzey Telestrasyon',
      feature2_3: 'Özel Video Dışa Aktarma',
      feature3: '✓ 10 dakikalık ATD paketi',
      primeDescription:
        'Bu plan, "Essentials + Code ∞" Planı özelliklerinin tamamına Play2 ile tam erişim sağlar ve Play3\'ü gelişmiş telestrasyon ile etkinleştirir.',
      primeMftDescription:
        'Bu plan, "Full Essentials" Planı özelliklerinin tamamına Play2 ile tam erişim sağlar ve Play3\'ü gelişmiş telestrasyon ile etkinleştirir.',
      primePresetsDescription:
        'Bu plan, "Essentials + Code ∞" Planı özelliklerinin tamamına Play2 ile tam erişim sağlar ve Play3\'ü gelişmiş telestrasyon ve özelleştirilmiş görselleştirmelerle etkinleştirir.',
      primePresetsMftDescription:
        'Bu plan, "Full Essentials" Planı özelliklerinin tamamına Play2 ile tam erişim sağlar ve Play3\'ü gelişmiş telestrasyon ve özelleştirilmiş görselleştirmelerle etkinleştirir.'
    },
    pro: {
      description:
        'Bu plan, "Full Essentials" Planı özelliklerinin tamamına Play2 ile tam erişimi ve "Full Prime" Planı özelliklerinin tamamına Play3 ile tam erişimi içerir.',
      feature1: '✓ Otomatik Oyuncu Takibi',
      feature2: '✓ Otomatik Alan Takibi',
      feature3: '✓ "Full Essentials" Planı özelliklerinin tamamıyla Play2\'ye tam erişim',
      feature4: '✓ Play3 ile erişim:',
      feature4_1: 'Sıralı Duraklamalar',
      feature4_2: 'İleri Düzey Telestrasyon',
      feature4_3: 'Özel Video Dışa Aktarma',
      feature4_4:
        'Özelleştirilmiş Görselleştirmeler (kendi görselleştirmelerinizi özelleştirin ve kaydedin)',
      feature5: '✓ 100 ATD dakika paketi'
    },
    remove: 'Kaldır',
    starter: {
      addon1: 'Molasız Sınırsız Telestrasyon',
      addon2: 'Sınırsız Coding özellikleri (CodePad, Canlı Coding ve XML İçe Aktarımı)',
      feature1: '✓ Maç sonrası kodlama (Code Table ile en fazla 4 kod)',
      feature2: '✓ Duraklamalarda Telestrasyon (ayda en fazla 50 not)',
      feature3: '✓ Video İhracatı',
      starterCodeDescription:
        "Bu plan, CodePad, Canlı Coding ve XML İçe Aktarma gibi kodlama özelliklerindeki kısıtlamaları kaldırır. Ayrıca, Video Projesi başına 4 kod limitini sınırsız kodlara çıkarır. Görselleştirme özelliklerindeki kısıtlamalar Free Starter'da devam eder.",
      starterCodePauseDescription:
        "Bu plan, CodePad, Canlı Coding ve XML İçe Aktarma gibi kodlama özelliklerinin kısıtlamalarını kaldırır. Ayrıca, Video Projesi başına 4 kod sınırlamasını sınırsız kodlara çıkarır ve duraklamalarda telestrasyon sınırlamasını ayda 50'den sınırsıza çıkarır.",
      starterPauseDescription:
        "Bu plan, duraklamalarda telestrasyon sınırlamasını ayda 50'den sınırsıza çıkarır. Coding özelliklerindeki sınırlamalar Free Starter'da devam eder."
    },
    totalPack: 'Toplam: %{currency} %{cost}',
    totalPlan: 'Toplam: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Faturalandırma',
    billingSettings: 'Faturalama Ayarları',
    billingSettingsDescription: '(planınızı değiştirin, ödeme yöntemlerini güncelleyin...)',
    cancelAt: 'Planınız şu tarihte iptal olur',
    cancelPlan: 'Planı İptal Et',
    confirmNewPassword: 'Yeni şifreyi onayla',
    deleteAccount: 'Hesabı sil',
    details: 'Benim detaylarım',
    emailNotActivated: 'E-posta bildirimi etkinleştirildi',
    emailNotDeactivated: 'E-posta bildirimi devre dışı',
    emailNotifications: 'E-posta bildirimleri',
    language: 'Dil',
    languageChanged: 'Dil güncellendi',
    newPassword: 'Yeni Şifre',
    organizationLabel: 'Kuruluş öneki',
    organizationTooltip:
      'Bu ön ek, özel şablonları bir Video Projesinin içine aktarmak için gereklidir. Daha fazla bilgi için bizimle iletişime geçin veya codeball.metrica-sports.com adresindeki belgelere bakın',
    passwordsDontMatch: 'Şifreler Eşleşmiyor',
    pauseAt: 'Planın duraklatılacak tarihi:',
    planCanceled: 'Planınız iptal edilmiştir. Daha fazla bilgi için e-postanızı kontrol edin',
    plans: 'Planlar',
    quantity: 'Adet:',
    renewAt: 'Planınız şu tarihte yenilenir',
    title: 'Ayarlar',
    upgradePlan: 'Yükseltme Planı',
    userKey: 'Kullanıcı Anahtarı:',
    userKeyUsages: '%{current}/%{max} kullanım',
    userSettings: 'Kullanıcı Ayarları'
  },
  shoppingCart: {
    subtitle: 'Performansını yükseltmek için Play planını ve eklerini seç.',
    title: 'Oyununu Ayarla'
  },
  subscription: {
    cancelSubscription: 'Aboneliği iptal et',
    errorUpdating:
      'Aboneliğinizi güncellerken bir hata oluştu. Lütfen support@metrica-sports.com adresine bir e-posta gönderin, size yardımcı olmaktan mutluluk duyarız.',
    free: 'Ücretsiz',
    isObsolete:
      'Aboneliğiniz değiştirilemez. Değişiklikleri etkinleştirmek için eski ürünleri yenileriyle değiştirin.',
    licenseKeys: 'Lisans anahtarları',
    pendingRefundRequest:
      'Bu abonelikte, bekleyen bir iade talebi olduğu sürece daha fazla değişiklik yapılamaz.',
    undoCancel: 'Abonelik iptalini geri al',
    undoPause: 'Abonelik duraklamasını geri al',
    updatePaymentMethod: 'Ödeme yöntemini güncelle',
    updatedSuccessfully: 'Abonelik başarıyla güncellendi.',
    yourPlans: 'Senin Planların',
    yourSubscription: 'Senin Aboneliğin'
  },
  successfulPayment: {
    activateLicence:
      'Verilen lisans anahtarını girerek lisansınızı veya Cloud kredisini aktifleştirin.',
    allTheBest: 'En iyileri,',
    applyKey: 'Bu hesaba anahtar uygula.',
    applyKeyDescription:
      'Oturum açtığınız hesap için lisans anahtarını otomatik olarak uygulamak ister misiniz?',
    downloadPlay: '%{pre}PLAY%{post} uygulamasını web sitemiz üzerinden indirin.',
    faq: 'Siparişinize başlamanıza yardımcı olmak için web sitemizdeki %{pre}Sıkça Sorulan Sorular%{post} bölümüne göz atın. Bu aşamada sizin için en alakalı olabilecek bilgilerin hızlı bir özeti aşağıda yer almaktadır:',
    goToSummary:
      'Aksi takdirde, lütfen aktivasyon talimatlarını öğrenmek için aşağıdaki özete başvurun.',
    happyPlay:
      'Her şeyin açık olduğunu umuyoruz, ancak herhangi bir sorunuz veya şüpheniz olursa lütfen bizimle iletişime geçmekten çekinmeyin. Mutlu PLAY!',
    helpDesk:
      "Genel bilgi almak için %{pre}HelpDesk%{post}'imizi kullanarak PLAY hakkında bilgi edinin.",
    keyApplied: 'Anahtar uygulandı',
    licenseKey: 'Lisans anahtarınız: %{pre}%{licenseKey}%{post}',
    metricaSportsTeam: 'Metrica Sports ekibi',
    settingsCloud: "%{pre}Cloud%{post}'ımızı ziyaret edin ve ayarlar menüsüne gitmek için:",
    technicalSupport:
      "Teknik destek için, PLAY/Cloud'da bulunan iletişim formunu doldurun veya %{pre}support@metrica-sports.com%{post} adresine e-posta gönderin.",
    termsAndConditions:
      'Lütfen aboneliklerimizin, %{pre}Cloud%{post} ayarları üzerinden açıkça iptal edilene kadar otomatik olarak yenilendiğini ve bunun Şartlar ve Koşullarımıza uygun olduğunu unutmayın.',
    thanks:
      'Siparişiniz için teşekkürler! Başarıyla işledik ve aşağıda lisans anahtarlarınızı ekledik.',
    title: 'Sipariş Onayı',
    updatePayment: 'Ödeme yöntemini güncelle.',
    updateSubscription: 'Aboneliğini iptal et veya değiştir.'
  },
  surfaces: {
    automaticVp:
      'Yüzeyler, daha eski bir OTV sürümüne sahip Video Projelerinde kullanıma uygun değildir',
    delete: 'Yüzey dosyasını sil',
    needsAtd: 'Yüzey işlemlerini çalıştırmak için önce OTV işlemini başlatın',
    needsElite: 'Özel Yüzeyler Elit planda mevcuttur. Daha fazla bilgi için bize ulaşın',
    needsFootballSideView:
      'Yüzeyler, sadece geniş açı veya TV yayını görüntüsü içeren futbol maçı Video Projelerinde kullanılabilir',
    pitchControl:
      "Bu işlemi çalıştırarak Play'e Saha Kontrolü görselleştirmesini ekleyebilirsiniz.",
    pitchControlProcessed: 'Saha Kontrolü Yüzeyi işlendi',
    pitchControlProcessing: 'Saha Kontrolü işleniyor',
    processed: 'Yüzey dosyası karşıya yüklendi',
    processing: 'Yüzey dosyası işleniyor',
    title: 'Yüzeyler',
    titlePitchControl: 'Saha Kontrolü',
    upload: 'Kendi Yüzeyinizi yükleyin',
    uploading: 'Yüzey karşıya yükleniyor...'
  },
  trackingFifaFormat: {
    download: 'FIFA EPTS formatındaki dosyayı indirin',
    needsElite:
      'İlgili dosyalar; Veri Entegrasyon modülüne, Otomatik Takip Verisi içeren Video Projelerine veya Elite plandaki Video Projelerine aktarıldıktan sonra bu işlev kullanılabilir.',
    processed: 'FIFA EPTS formatı işlendi',
    start:
      'FIFA EPTS formatında dosya oluşturma işlemini başlatın. Daha sonra dosyayı indirebileceksiniz',
    title: 'FIFA EPTS Formatı',
    titleDownload: 'FIFA EPTS Formatını İndirin'
  },
  upgradeModal: {
    linksDescription: 'Size en uygun Bulut Motoru dakika paketini edinin:',
    minutePackLinks: {
      minutes_10: '10 € karşılığında 10 dakika alın',
      minutes_100: '90 € karşılığında 100 dakika alın',
      minutes_1000: '750 € karşılığında 1.000 dakika alın',
      minutes_10000: '5.000 € karşılığında 10.000 dakika alın'
    },
    noMinutes: {
      description:
        'Bu Video Projesini oluşturmak için yeterli dakikanız yok. Planınız ne olursa olsun, dakika paketleri alabileceğinizi biliyor musunuz?',
      secondDescription: '',
      title: 'Bir Video Projesi oluşturmak için daha fazla dakika alın!'
    },
    notEnoughMinutes: {
      description:
        'Video Projelerinizde OTV işlemi için yeterli dakikanız olmadığını bildirdiğimiz için üzgünüz.',
      secondDescription:
        "ATD'yi işlemek istiyorsanız, hesabınıza daha fazla dakika eklemeniz gerekir. Alacağınız dakikaların süresi dolmaz!",
      title: 'Yeterli dakika mevcut değil'
    }
  },
  video: {
    cancelUpload: 'Karşıya yüklemeyi iptal et',
    delete: 'Videoyu sil',
    download: 'Video indir',
    title: 'Video Dosyası',
    upload: 'Video yükle',
    uploaded: 'Video karşıya yüklendi',
    uploading: 'Video karşıya yükleniyor'
  },
  videoProject: {
    delete: 'Video Projesini Sil',
    edit: 'Video Projesini Düzenle',
    processWaitingJobs: 'Bekleyen işleri işle',
    title: 'Video Projesi'
  }
}
