/** @jsx jsx */
// eslint-disable-next-line
import React, { Fragment } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import get from 'lodash/get'
import { theme } from '../../../constants'

const styles = {}
styles.container = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`
styles.content = css`
  position: absolute;
  top: 3px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: #f3ddde;
  border: 1px solid #eabac0;
  border-radius: 3px;
  color: ${theme.palette.error.main};
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`
styles.buttonWrapper = css`
  cursor: pointer;
  margin-left: auto;
`
styles.icon = {
  color: theme.palette.error.main,
  marginRight: 10,
  marginLeft: 10
}

const makeName = item => {
  const homeTeamName = get(item, 'game.home_team.name') || null
  const awayTeamName = get(item, 'game.away_team.name') || null
  const description = get(item, 'game.description') || null

  if (description) return `${description}:`
  if (homeTeamName && awayTeamName) return `${homeTeamName} vs ${awayTeamName}:`
  if (!homeTeamName && !awayTeamName) return ''
  if (!homeTeamName && awayTeamName) return `-- vs ${awayTeamName}:`
  if (homeTeamName && !awayTeamName) return `${homeTeamName} vs -- :`
  return ''
}

function UncaughtErrorRow({ deleteVideoProject, rowItem }) {
  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <Icon style={styles.icon}>highlight_off</Icon>
        {`${makeName(rowItem)} ${window.I18n.t('admin.uncaughtErrorRow')}`}.
        <div
          css={styles.buttonWrapper}
          onClick={deleteVideoProject(rowItem._id, makeName(rowItem))}
        >
          <Icon style={styles.icon}>delete</Icon>
        </div>
      </div>
    </div>
  )
}

UncaughtErrorRow.propTypes = {
  deleteVideoProject: PropTypes.func.isRequired,
  rowItem: PropTypes.object.isRequired
}

export default UncaughtErrorRow
