/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css, jsx } from '@emotion/core'
import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { theme } from '../../constants'
import { ProductNames } from '../../constants/payments'
import orderService from '../../services/order'
import userService from '../../services/user'
import getTrackingMinutes from '../../utils/getTrackingMinutes'
import minTommss from '../../utils/minPlans'
import * as Actions from '../admin/actions'
import { history } from '../App'
import sleep from '../../utils/sleep'

const styles = {}
styles.container = css`
  max-width: 900px;
  margin: auto;
  padding-bottom: 16px;
  text-align: left;
  color: #757575;
`
styles.header = css`
  background: #cdf256;
  color: #333333;
  font-size: 24px;
  padding: 20px;
  text-align: center;
`
styles.textBody = css`
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 20px;
`
styles.indent = css`
  padding-left: 20px;
`
styles.loadingContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
  opacity: 0.5;
`
styles.applyKeyContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
styles.button = {
  height: '32px',
  minWidth: '180px',
  textTransform: 'none',
  textWrap: 'nowrap',
  borderRadius: '2px',
  padding: '0px 6px'
}

function UserKeyInfo(props) {
  const [loading, setLoading] = React.useState(false)
  const [applied, setApplied] = React.useState(
    props.product.usages === 0 ||
      props.user.user_keys.find(v => v.user_key.code === props.product.code)
  )

  async function applyKey() {
    try {
      setLoading(true)

      try {
        for (let i = 0; i < props.product.usages; i += 1) {
          await userService.addKey(props.user._id, { key: props.product.code })
        }
      } catch (error) {
        const message = error.response && error.response.data && error.response.data.message
        if (message) {
          toast.error(message)
        } else {
          console.error(error)
          toast.error(window.I18n.t('addKeyModal.errorMessage'))
        }

        setLoading(false)
        return
      }

      const totalMinutes = getTrackingMinutes(props.user)
      const fetchedUser = await Actions.updateUser(null, false)
      const totalMinutesAfter = getTrackingMinutes(fetchedUser)
      if (totalMinutesAfter > totalMinutes) {
        toast.info(
          window.I18n.t('addKeyModal.minutesMessage', { minutes: minTommss(totalMinutesAfter) })
        )
      } else {
        toast.info(window.I18n.t('addKeyModal.successMessage'))
      }

      setApplied(true)
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('addKeyModal.errorMessage'))
    } finally {
      setLoading(false)
    }
  }

  const buttonLabel = window.I18n.t(`successfulPayment.${applied ? 'keyApplied' : 'applyKey'}`)

  return (
    <React.Fragment>
      <p>
        <strong>
          {props.product.quantity} x {props.product.name}
        </strong>
      </p>
      <p
        css={styles.indent}
        dangerouslySetInnerHTML={{
          __html: window.I18n.t('successfulPayment.licenseKey', {
            pre: '<strong>',
            licenseKey: props.product.code,
            post: '</strong>'
          })
        }}
      />
      <div css={styles.applyKeyContainer}>
        {window.I18n.t('successfulPayment.applyKeyDescription')}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading || applied}
          style={{
            ...styles.button,
            color: applied ? theme.palette.common.charolBlack : theme.palette.common.white,
            backgroundColor: applied ? theme.palette.common.main : theme.palette.common.blue
          }}
          onClick={applyKey}
        >
          {loading ? (
            <CircularProgress color="white" size={24} thickness={4} />
          ) : (
            <span>{buttonLabel}</span>
          )}
        </Button>
      </div>
      <p>{window.I18n.t('successfulPayment.goToSummary')}</p>
      <hr />
    </React.Fragment>
  )
}

UserKeyInfo.defaultProps = {}

UserKeyInfo.propTypes = {
  user: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function SuccessfulPayment(props) {
  const [recurrentProducts, setRecurrentProducts] = React.useState([])
  const [oneTimeProducts, setOneTimeProducts] = React.useState([])

  React.useEffect(() => {
    // Get transaction ID from URL.
    const url = window.location.search
    const keyAndId = url.split('?')[1]
    const [key, id] = keyAndId.split('=')
    if (!id) {
      history.push('/')
      return
    }

    const fetchOrder = async () => {
      let response

      // Retry order fetching if it fails.
      const maxRetries = 10
      let retries = 0
      while (retries < maxRetries) {
        try {
          if (key === 'transaction') {
            response = await orderService.getOrderByTransactionId(id)
          } else {
            response = await orderService.getOrderById(id)
          }
          break
        } catch (error) {
          retries += 1
          await sleep(5000)
        }
      }

      if (retries === maxRetries) {
        toast.error(window.I18n.t('admin.errorContactSupport'))
        return
      }

      // Once we're sure order is retrieved, update user to get the proper subscription info.
      await Actions.updateUser()

      const order = response.data
      const { newRecurrentProducts, newOneTimeProducts } = order.items.reduce(
        (acc, v) => {
          let usages = v.user_key.max_usage - v.user_key.count_usage
          let products
          let productCode

          let product = props.monthlyProducts.find(p => p.priceId === v.id)
          if (product) {
            usages = 1
            products = acc.newRecurrentProducts
            productCode = product.productCode
          } else {
            product = props.yearlyProducts.find(p => p.priceId === v.id)
            if (product) {
              usages = 1
              products = acc.newRecurrentProducts
              productCode = product.productCode
            } else {
              product = props.oneTimeProducts.find(p => p.priceId === v.id)
              if (product) {
                products = acc.newOneTimeProducts
                productCode = product.priceCode
              }
            }
          }

          if (products) {
            const productInfo = ProductNames.find(n => n.value === productCode)

            let name
            if (productInfo.value.includes('minutes')) {
              name = window.I18n.t(`plans.${productInfo.translationKey}`)
            } else {
              name = window.I18n.t('plans.plan', {
                planName: productInfo.translationKey
                  ? window.I18n.t(`plans.${productInfo.translationKey}`)
                  : productInfo.translation
              })
            }

            products.push({
              name,
              code: v.user_key.code,
              quantity: v.quantity,
              usages
            })
          }

          return acc
        },
        { newRecurrentProducts: [], newOneTimeProducts: [] }
      )

      setRecurrentProducts(newRecurrentProducts)
      setOneTimeProducts(newOneTimeProducts)
    }
    fetchOrder()
  }, [props.monthlyProducts, props.yearlyProducts, props.oneTimeProducts])

  return (
    <div css={styles.container}>
      <div css={styles.header}>Metrica Sports | {window.I18n.t('successfulPayment.title')}</div>
      <div css={styles.textBody}>
        <div>{window.I18n.t('successfulPayment.thanks')}</div>
        <hr />

        {recurrentProducts.length === 0 && oneTimeProducts.length === 0 && (
          <React.Fragment>
            <div css={styles.loadingContainer}>
              <CircularProgress size={32} thickness={4} />
            </div>
            <hr />
          </React.Fragment>
        )}

        {(recurrentProducts.length > 0 || oneTimeProducts.length > 0) && (
          <React.Fragment>
            <div>
              {recurrentProducts.map(product => (
                <UserKeyInfo product={product} user={props.user} />
              ))}
            </div>

            <div>
              {oneTimeProducts.map(product => (
                <UserKeyInfo product={product} user={props.user} />
              ))}
            </div>
          </React.Fragment>
        )}

        <p
          dangerouslySetInnerHTML={{
            __html: window.I18n.t('successfulPayment.faq', {
              pre: '<a href="https://metrica-sports.com/faq/" target="_blank" rel="noopener noreferrer">',
              post: '</a>'
            })
          }}
        />

        <ul css={styles.indent}>
          <li
            dangerouslySetInnerHTML={{
              __html: window.I18n.t('successfulPayment.downloadPlay', {
                pre: '<a href="https://downloads.metrica-sports.com/" target="_blank" rel="noopener noreferrer">',
                post: '</a>'
              })
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: window.I18n.t('successfulPayment.helpDesk', {
                pre: '<a href="https://help.metrica-sports.com/" target="_blank" rel="noopener noreferrer">',
                post: '</a>'
              })
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: window.I18n.t('successfulPayment.settingsCloud', {
                pre: '<a href="/">',
                post: '</a>'
              })
            }}
          />
          <ul css={styles.indent}>
            <li>{window.I18n.t('successfulPayment.updateSubscription')}</li>
            <li>{window.I18n.t('successfulPayment.updatePayment')}</li>
            <li>{window.I18n.t('successfulPayment.activateLicence')}</li>
          </ul>
          <li
            dangerouslySetInnerHTML={{
              __html: window.I18n.t('successfulPayment.technicalSupport', {
                pre: '<a href="mailto:support@metrica-sports.com">',
                post: '</a>'
              })
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: window.I18n.t('successfulPayment.termsAndConditions', {
                pre: '<a href="/">',
                post: '</a>'
              })
            }}
          />
        </ul>

        <div>{window.I18n.t('successfulPayment.happyPlay')}</div>

        <p>
          {window.I18n.t('successfulPayment.allTheBest')}
          <br />
          {window.I18n.t('successfulPayment.metricaSportsTeam')}
        </p>
      </div>
    </div>
  )
}

SuccessfulPayment.defaultProps = {}

SuccessfulPayment.propTypes = {
  user: PropTypes.object.isRequired,
  monthlyProducts: PropTypes.array.isRequired,
  yearlyProducts: PropTypes.array.isRequired,
  oneTimeProducts: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    monthlyProducts: state.admin.monthlyProducts,
    yearlyProducts: state.admin.yearlyProducts,
    oneTimeProducts: state.admin.oneTimeProducts
  }
}

export default connect(mapStateToProps)(SuccessfulPayment)
