/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import { withStyles } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import getSymbolFromCurrency from 'currency-symbol-map'
import PropTypes from 'prop-types'
import TextSwitch from '../../utils/textSwitch'
import { theme } from '../../constants'
import { ProductNames } from '../../constants/payments'
import formatPrice from '../../utils/formatPrice'
import localizePrice from '../../utils/localizePrice'

const styles = {}
styles.container = css`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #cecece;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -16px #767676;
  padding: 16px;
  padding-right: 32px;
  margin-bottom: 16px;
`
styles.title = css`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`
styles.description = css``
styles.featuresContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
styles.featureTitle = css`
  font-weight: bold;
`
styles.featureDescription = css``
styles.addonsContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
styles.addonTitle = css`
  font-weight: bold;
  margin-bottom: 8px;
`
styles.addonDescription = css`
  margin-left: 12px;
`
styles.addonPrice = css`
  min-width: 100px;
  text-align: right;
  margin-right: 12px;
`
styles.addonContainer = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`
styles.addonToggleContainer = css`
  display: flex;
  align-items: center;
`
styles.totalContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
styles.removeButton = css`
  cursor: pointer;
  color: grey;
  :hover {
    text-decoration: underline;
  }
`
styles.totalDescription = css`
  font-size: 16px;
  font-weight: bold;
`
styles.planQuantityContainer = css`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 16px;
  right: 16px;
`
styles.packQuantityContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
styles.quantity = css`
  min-width: 16px;
  max-width: 16px;
  text-align: center;
  margin: 0px 12px;
  user-select: none;
`
styles.quantityButton = css`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${theme.palette.common.charolBlack};
  background-color: ${theme.palette.common.mainGreen};
  cursor: pointer;
  user-select: none;
`
styles.disabled = css`
  pointer-events: none;
  opacity: 0.5;
`

const DialogContent = withStyles(() => ({
  root: {
    flex: 'unset !important',
    padding: '0px !important',
    '& .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 0.5
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 1
    },
    '& .MuiSwitch-thumb': {
      color: theme.palette.common.white
    }
  }
}))(MuiDialogContent)

function OneTimeProducts(props) {
  const [currencySymbol, setCurrencySymbol] = React.useState(
    getSymbolFromCurrency(props.currencyCode)
  )
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    setCurrencySymbol(getSymbolFromCurrency(props.currencyCode))
  }, [props.currencyCode])

  React.useEffect(() => {
    const newTotal = props.products.reduce((acc, v) => {
      return acc + localizePrice(v, props.currencyCode) * v.quantity
    }, 0)

    setTotal(newTotal)
  }, [props.products, props.currencyCode])

  return (
    <DialogContent>
      <div css={styles.container}>
        <span css={styles.title}>{window.I18n.t('products.minutesPack')}</span>
        <span css={styles.description}>{window.I18n.t('products.minutesDescription')}</span>

        <div css={styles.addonsContainer}>
          {props.oneTimeProducts.map(v => {
            const product = props.products.find(p => p.id === v.id)
            const productInfo = ProductNames.find(p => p.value === v.priceCode)

            return (
              <div css={styles.addonContainer} key={`container-${v.id}`}>
                <div css={styles.addonToggleContainer}>
                  <TextSwitch
                    name={v.id}
                    checked={product && product.quantity > 0}
                    color="primary"
                    onChange={() => {
                      if (product && product.quantity > 0) {
                        props.onUpdate([{ ...product, quantity: 0 }])
                      } else {
                        props.onUpdate([{ ...v, quantity: 1 }])
                      }
                    }}
                  />
                  <span css={styles.addonDescription}>
                    {window.I18n.t(`plans.${productInfo.translationKey}`)}
                  </span>
                </div>
                <div
                  css={css`
                    ${styles.packQuantityContainer};
                    ${!product || product.quantity <= 0 ? styles.disabled : ''}
                  `}
                >
                  <span css={styles.addonPrice}>
                    {props.currencyCode}{' '}
                    {formatPrice(localizePrice(v, props.currencyCode), props.currencyCode)}
                  </span>

                  <span
                    css={css`
                      ${styles.quantityButton}
                      ${product && product.quantity <= 1 ? styles.disabled : ''}
                    `}
                    onClick={() => {
                      props.onUpdate([{ ...product, quantity: Math.max(0, product.quantity - 1) }])
                    }}
                  >
                    -
                  </span>
                  <span css={styles.quantity}>{product ? product.quantity : 0}</span>
                  <span
                    css={css`
                      ${styles.quantityButton}
                      ${product && product.quantity >= 99 ? styles.disabled : ''}
                    `}
                    onClick={() => {
                      props.onUpdate([{ ...product, quantity: Math.min(99, product.quantity + 1) }])
                    }}
                  >
                    +
                  </span>
                </div>
              </div>
            )
          })}
        </div>

        <div css={styles.totalContainer}>
          {false && (
            <a
              css={styles.removeButton}
              onClick={() => props.onUpdate(props.products.map(v => [{ ...v, quantity: 0 }]))}
            >
              {window.I18n.t('products.remove')}
            </a>
          )}
          <span css={styles.totalDescription}>
            {window.I18n.t('products.totalPack', {
              currency: props.currencyCode,
              cost: formatPrice(total, props.currencyCode)
            })}
          </span>
        </div>
      </div>
    </DialogContent>
  )
}

OneTimeProducts.defaultProps = {}

OneTimeProducts.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  oneTimeProducts: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default OneTimeProducts
