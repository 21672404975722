/** @jsx jsx */
// eslint-disable-next-line
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/core'
import MuiDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import CloseIcon from '@material-ui/icons/Close'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { toast } from 'react-toastify'
import { withStyles } from '@material-ui/core/styles'
import UploadFile from './components/UploadFile'
import gameDatasetsService from '../../services/gameDatasets'
import { confirmAlert } from '../..../../notifications/Actions'
import { theme, DATA_INTEGRATION_PROVIDERS } from '../../constants'

const styles = {}
styles.dialogContent = css`
  h6 {
    font-weight: bold;
  }
  padding: 20px;
  padding-top: 0;

  text {
    font-weight: bolder;
  }
`
styles.selectFileContainer = css`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
styles.selectFileButton = { borderRadius: '3px' }
styles.selectFileTextContent = css`
  flex: 1;
  margin-left: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 13px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`
styles.closeIcon = css`
  color: #444444;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
`
styles.videoRequired = css`
  font-size: 12px;
  color: ${theme.palette.error.main};
  position: absolute;
  bottom: -23px;
  left: 0;
`
styles.fontBolder = {
  fontWeight: 'bolder',
  color: theme.palette.common.charolBlack
}
styles.backgroundGradientDisabled = {
  ...styles.fontBolder,
  background: 'rgba(0, 0, 0, 0.12)',
  color: 'none'
}
styles.backgroundGradient = {
  ...styles.fontBolder,
  background: theme.palette.common.mainGradient
}
styles.clearInputVideo = css`
  color: #fff;
  background-color: grey;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: default;
  transform: scale(0.6);
  margin-left: 10px;
`
styles.title = css`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 40px;
`

styles.selectContainer = css`
  border-radius: 2px;
  background: ${theme.palette.primary.main};
  color: ${theme.palette.common.charolBlack};
  width: 300px;
  text-align: center;
  height: 40px;
`

styles.selectForm = css`
  color: ${theme.palette.common.charolBlack};
  background: ${theme.palette.common.mainGradient};
  text-transform: uppercase;
  height: 40px;
  font-weight: 500;
`

const DialogContent = withStyles(() => ({
  root: {
    paddingBottom: '50px'
  }
}))(MuiDialogContent)

const Dialog = withStyles(() => ({
  paper: {
    maxHeight: '100%'
  }
}))(MuiDialog)

class DataIntegrationUploadModal extends PureComponent {
  tracabFiles = this.props.vp.files.tracab
  tracabDflFiles = this.props.vp.files.tracab_dfl
  ssFiles = this.props.vp.files.second_spectrum
  statsFiles = this.props.vp.files.stats
  fifaFiles = this.props.vp.files.fifa

  getFilenameFromPath = file => {
    return file.split('/').splice(-1)[0]
  }

  tracabFilesValues = {
    trackingFile:
      (this.tracabFiles &&
        this.tracabFiles.tracking &&
        this.getFilenameFromPath(this.tracabFiles.tracking)) ||
      '',
    metadataFile:
      (this.tracabFiles &&
        this.tracabFiles.metadata &&
        this.getFilenameFromPath(this.tracabFiles.metadata)) ||
      '',
    f7File:
      (this.tracabFiles && this.tracabFiles.f7 && this.getFilenameFromPath(this.tracabFiles.f7)) ||
      '',
    f24File:
      (this.tracabFiles &&
        this.tracabFiles.f24 &&
        this.getFilenameFromPath(this.tracabFiles.f24)) ||
      ''
  }

  tracabDflFilesValues = {
    trackingFile:
      (this.tracabDflFiles &&
        this.tracabDflFiles.tracking &&
        this.getFilenameFromPath(this.tracabDflFiles.tracking)) ||
      '',
    metadataFile:
      (this.tracabDflFiles &&
        this.tracabDflFiles.metadata &&
        this.getFilenameFromPath(this.tracabDflFiles.metadata)) ||
      ''
  }

  ssFilesValues = {
    trackingFile:
      (this.ssFiles && this.ssFiles.tracking && this.getFilenameFromPath(this.ssFiles.tracking)) ||
      '',
    metadataFile:
      (this.ssFiles && this.ssFiles.metadata && this.getFilenameFromPath(this.ssFiles.metadata)) ||
      ''
  }

  statsFilesValues = {
    trackingFile:
      (this.statsFiles &&
        this.statsFiles.tracking &&
        this.getFilenameFromPath(this.statsFiles.tracking)) ||
      '',
    metadataFile:
      (this.statsFiles &&
        this.statsFiles.metadata &&
        this.getFilenameFromPath(this.statsFiles.metadata)) ||
      ''
  }

  fifaFilesValues = {
    trackingFile:
      (this.fifaFiles &&
        this.fifaFiles.tracking &&
        this.getFilenameFromPath(this.fifaFiles.tracking)) ||
      '',
    metadataFile:
      (this.fifaFiles &&
        this.fifaFiles.metadata &&
        this.getFilenameFromPath(this.fifaFiles.metadata)) ||
      ''
  }

  initialTracabFiles = [
    {
      id: 1,
      title: 'Tracking',
      name: 'tracking',
      extension: '.dat',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabFilesValues.trackingFile,
      data: null,
      showRequired: false
    },
    {
      id: 2,
      title: 'Metadata',
      name: 'metadata',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabFilesValues.metadataFile,
      data: null,
      showRequired: false
    },
    {
      id: 3,
      title: 'F7',
      name: 'f7',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabFilesValues.f7File,
      data: null,
      showRequired: false
    },
    {
      id: 4,
      title: 'F24',
      name: 'f24',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabFilesValues.f24File,
      data: null,
      showRequired: false
    }
  ]

  initialSsFiles = [
    {
      id: 5,
      title: 'Tracking',
      name: 'tracking',
      extension: '.jsonl',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.ssFilesValues.trackingFile,
      data: null,
      showRequired: false
    },
    {
      id: 6,
      title: 'Metadata',
      name: 'metadata',
      extension: '.json',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.ssFilesValues.metadataFile,
      data: null,
      showRequired: false
    }
  ]

  initialTracabDflFiles = [
    {
      id: 7,
      title: 'Tracking (Positions Raw Observed)',
      name: 'tracking',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabDflFilesValues.trackingFile,
      data: null,
      showRequired: false
    },
    {
      id: 8,
      title: 'Metadata (Match Information)',
      name: 'metadata',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.tracabDflFilesValues.metadataFile,
      data: null,
      showRequired: false
    }
  ]

  initialStatsFiles = [
    {
      id: 9,
      title: 'Tracking (Positions Raw Observed)',
      name: 'tracking',
      extension: '.txt',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.statsFilesValues.trackingFile,
      data: null,
      showRequired: false
    },
    {
      id: 10,
      title: 'Metadata (Match Information)',
      name: 'metadata',
      extension: '.csv',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.statsFilesValues.metadataFile,
      data: null,
      showRequired: false
    }
  ]

  initialFifaFiles = [
    {
      id: 11,
      title: 'Tracking',
      name: 'tracking',
      extension: '.txt',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.fifaFilesValues.trackingFile,
      data: null,
      showRequired: false
    },
    {
      id: 12,
      title: 'Metadata',
      name: 'metadata',
      extension: '.xml',
      loading: false,
      error: false,
      uploaded: false,
      fileName: this.fifaFilesValues.metadataFile,
      data: null,
      showRequired: false
    }
  ]

  setInitialState = () => {
    const hasTracabFilesValues = Object.values(this.tracabFilesValues).some(file => !!file)
    const hasSsFilesValues = Object.values(this.ssFilesValues).some(file => !!file)
    const hasTracabDflFilesValues = Object.values(this.tracabDflFilesValues).some(file => !!file)
    const hasStatsFilesValues = Object.values(this.statsFilesValues).some(file => !!file)
    const hasFifaFilesValues = Object.values(this.fifaFilesValues).some(file => !!file)

    let dataIntegrationTypeParameters = {
      dataIntegrationType: DATA_INTEGRATION_PROVIDERS.TRACAB,
      files: this.initialTracabFiles
    }

    if (hasSsFilesValues && !hasTracabFilesValues) {
      dataIntegrationTypeParameters = {
        dataIntegrationType: DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM,
        files: this.initialSsFiles
      }
    }

    if (hasTracabDflFilesValues && !hasTracabFilesValues) {
      dataIntegrationTypeParameters = {
        dataIntegrationType: DATA_INTEGRATION_PROVIDERS.TRACAB_DFL,
        files: this.initialTracabDflFiles
      }
    }

    if (hasStatsFilesValues && !hasTracabFilesValues) {
      dataIntegrationTypeParameters = {
        dataIntegrationType: DATA_INTEGRATION_PROVIDERS.STATS,
        files: this.initialStatsFiles
      }
    }

    if (hasFifaFilesValues && !hasTracabFilesValues) {
      dataIntegrationTypeParameters = {
        dataIntegrationType: DATA_INTEGRATION_PROVIDERS.FIFA,
        files: this.initialFifaFiles
      }
    }

    return {
      ...dataIntegrationTypeParameters,
      isSubmitting: false
    }
  }

  state = this.setInitialState()

  setAsyncState = newState => new Promise(resolve => this.setState(newState, () => resolve()))

  isFormValid = async () => {
    let isFormValid = true
    const files = [...this.state.files]
    files.forEach((file, idx) => {
      if (!file.fileName) {
        files[idx].showRequired = true
        isFormValid = false
      }
      if (file.error) {
        isFormValid = false
      }
    })
    await this.setAsyncState({ files })
    return Promise.resolve(isFormValid)
  }

  onSubmit = async (values, { setSubmitting }) => {
    try {
      await this.setAsyncState({ isSubmitting: true })
      const isFormValid = await this.isFormValid()
      if (!isFormValid) {
        return
      }

      this.props.startJobFromDataIntegration(this.state.dataIntegrationType)
      setSubmitting(false)
      this.props.close()
      this.setState({ isSubmitting: false })
    } catch (error) {
      console.error(error)

      const vpGame = this.props.vp.game
      const isGvp = vpGame && !vpGame.description
      const vpName = isGvp
        ? `${vpGame.home_team.name} vs ${vpGame.away_team.name}`
        : vpGame.description

      toast.error(window.I18n.t('common.errorMsg', { name: vpName }))
      setSubmitting(false)
      this.setState({ isSubmitting: false })
    }
  }

  onFileChange = newFile => {
    const files = [...this.state.files]
    const index = findIndex(files, { id: newFile.id, name: newFile.name })
    files[index] = newFile
    this.setState({ files })
    if (newFile.data) this.uploadSingleFile(newFile)
  }

  updateFileAsync = async newFile => {
    const files = [...this.state.files]
    const index = findIndex(files, { id: newFile.id, name: newFile.name })
    files[index] = newFile
    await this.setAsyncState({ files })
    return Promise.resolve(files)
  }

  uploadSingleFile = async file => {
    let newFile
    let gameDataset
    try {
      newFile = { ...file }
      newFile.loading = true
      newFile.error = false
      await this.updateFileAsync(newFile)
      const id = this.props.vp._id
      const payload = new FormData()
      const dataIntegrationType = this.state.dataIntegrationType
      payload.append(dataIntegrationType, newFile.data)
      gameDataset = await gameDatasetsService.uploadResourceFile(
        id,
        dataIntegrationType,
        newFile.name,
        payload
      )
      newFile.loading = false
      newFile.error = false
      newFile.uploaded = true
      await this.updateFileAsync(newFile)
      await this.props.updateTableItem(this.props.vp._id)
      return Promise.resolve(gameDataset)
    } catch (error) {
      newFile = { ...file }
      newFile.loading = false
      newFile.error = true
      await this.updateFileAsync(newFile)
      return Promise.resolve() // No need to reject this
    }
  }

  deleteFile = async file => {
    return confirmAlert()
      .then(async () => {
        const newFile = { ...file }
        try {
          newFile.loading = true
          await this.updateFileAsync(newFile)
          const dataIntegrationType = this.state.dataIntegrationType
          await gameDatasetsService.deleteResourceFile(
            this.props.vp._id,
            dataIntegrationType,
            file.name
          )
          await this.props.updateTableItem(this.props.vp._id)
          return Promise.resolve()
        } catch (error) {
          console.log(error)
        } finally {
          newFile.loading = false
          newFile.uploaded = false
          newFile.error = false
          newFile.fileName = ''
          newFile.data = null
          await this.updateFileAsync(newFile)
        }
      })
      .catch(() => {})
  }

  shouldDisableSubmit = files => {
    return files.some(file => !file.fileName || file.error || file.loading)
  }

  handleSelectChange = e => {
    const dataIntegrationType = e.target.value
    let files

    switch (dataIntegrationType) {
      case DATA_INTEGRATION_PROVIDERS.TRACAB:
        files = this.initialTracabFiles
        break

      case DATA_INTEGRATION_PROVIDERS.TRACAB_DFL:
        files = this.initialTracabDflFiles
        break

      case DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM:
        files = this.initialSsFiles
        break

      case DATA_INTEGRATION_PROVIDERS.STATS:
        files = this.initialStatsFiles
        break

      case DATA_INTEGRATION_PROVIDERS.FIFA:
        files = this.initialFifaFiles
        break

      default:
        break
    }

    this.setState({ dataIntegrationType, files })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        disableBackdropClick
      >
        <Formik initialValues={this.tracabFilesValues} onSubmit={this.onSubmit}>
          {formik => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              method="POST"
              css={css`
                width: 710px;
                position: relative;
              `}
              ref={el => {
                this.formEl = el
              }}
            >
              <div css={styles.closeIcon}>
                <IconButton onClick={this.props.close}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
              <div css={styles.dialogContent}>
                <div css={styles.title}>{window.I18n.t('dataIntegration.title')}</div>
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <FormControl css={styles.selectContainer}>
                    <Select
                      labelId="data-integration-type"
                      id="data-integration-type"
                      onChange={this.handleSelectChange}
                      value={this.state.dataIntegrationType}
                      css={styles.selectForm}
                    >
                      <MenuItem value={DATA_INTEGRATION_PROVIDERS.SECOND_SPECTRUM}>
                        Second Spectrum
                      </MenuItem>
                      <MenuItem value={DATA_INTEGRATION_PROVIDERS.TRACAB}>Tracab</MenuItem>
                      <MenuItem value={DATA_INTEGRATION_PROVIDERS.TRACAB_DFL}>Tracab DFL</MenuItem>
                      <MenuItem value={DATA_INTEGRATION_PROVIDERS.STATS}>Stats</MenuItem>
                      <MenuItem value={DATA_INTEGRATION_PROVIDERS.FIFA}>FIFA</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  css={css`
                    height: 500px;
                  `}
                >
                  <div
                    css={css`
                      height: 48px;
                    `}
                  />
                  <DialogContent>
                    {this.state.files.map(file => (
                      <UploadFile
                        key={file.id}
                        title={file.title}
                        extension={file.extension}
                        loading={file.loading}
                        error={file.error}
                        uploaded={file.uploaded}
                        showRequired={file.showRequired}
                        fileName={file.fileName}
                        file={file}
                        onChange={this.onFileChange}
                        onTryAgain={this.uploadSingleFile}
                        onDeleteFile={this.deleteFile}
                      />
                    ))}
                  </DialogContent>
                </div>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={
                      this.shouldDisableSubmit(this.state.files)
                        ? styles.backgroundGradientDisabled
                        : styles.backgroundGradient
                    }
                    disabled={this.shouldDisableSubmit(this.state.files)}
                  >
                    {window.I18n.t('dataIntegration.startProcess')}
                  </Button>
                </DialogActions>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

DataIntegrationUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  startJobFromDataIntegration: PropTypes.func.isRequired,
  vp: PropTypes.object.isRequired,
  updateTableItem: PropTypes.func.isRequired
}

export default DataIntegrationUploadModal
