export default {
  addKeyModal: {
    action: '申请',
    errorMessage: '应用密钥时出错。如果问题仍然存在，请联系 Metrica Sports 支持团队',
    minutesMessage: '您的密钥已处理。您现在有 %{minutes} 分钟可用。',
    placeholder: '键入一个键...',
    successMessage: '密钥已成功处理',
    title: '添加密钥'
  },
  admin: {
    NoGames: '没有比赛，添加你的第一个...',
    NoResults: '没有结果...',
    addKey: '添加密钥',
    atd: 'ETC',
    automatedTrackingData: '自动跟踪数据 (ATD)',
    automatedTrackingDataJobSuccess:
      '%{vpName} 上的自动跟踪数据处理已完成，现在可在 Metrica Play 中使用',
    automatedTrackingDataProcessedTooltip: '自动跟踪数据处理',
    awayTeam: '客队',
    awayTeamScore: '客队得分',
    ballDetection: '球检测',
    ballTracking: '球追踪',
    ballTrackingProcessedTooltip: '球检测处理',
    ballTrackingTooltip:
      '此过程将检测球，并允许您在比赛中的 Live Formation（2D Pitch）中对其进行可视化。球坐标也将在 FIFA EPTS 格式文件中提供。',
    cancelATDProcessSuccessfully: '%{vpName} 上的自动跟踪数据进程已取消',
    cancelDataIntegrationProcessSuccessfully: '%{vpName} 上的数据集成过程已取消',
    cancelEventsProcessSuccessfully: 'Smart Tagging 过程在 %{vpName} 被取消了。',
    cancelProcessTooltip: '取消流程',
    cancelSurfacesProcessSuccessfully: 'Surface 进程已在 %{vpName} 上取消',
    cancelTrackingFifaFormatProcessSuccessfully: '%{vpName} 上的跟踪 FIFA 格式过程已取消',
    category: '类别',
    checking: '检查...',
    cloudMinutes: '云分钟',
    competition: '竞赛',
    connectionFailed: '连接失败',
    connectionFailedText:
      '检索状态信息似乎有问题。请检查您的互联网连接并重新加载页面。如果问题仍然存在，请联系支持',
    correctedTracking: '正在进行 Tracking Correction（追踪校正）',
    correctionUsage: '您会使用这个视频项目进行 Tracking Correction（追踪校正）吗？ ',
    correctionUsageTooltip:
      'Tracking Correction Manager (即追踪校正管理器，TCM) 是 Play 专为用户开发的模块，用于校正之前使用 ATD 生成的轨迹。ATD 可以自动为任何足球比赛生成没有 ID 的数据。使用 TCM 可以纠正错误的轨迹并为他们分配 ID。联系我们获取更多信息。',
    createVideoProject: '创建视频项目',
    creatingGame: '创建视频项目……',
    dataIntegrationFailed:
      '%{vpName} 上的数据集成失败。转到数据集成面板，检查您的文件并重试。如果错误仍然存在，请联系 Metrica Sports 支持团队',
    dataIntegrationJobSuccess: '%{vpName} 的数据集成过程已成功完成',
    date: '日期',
    description: '描述',
    editedSuccessfully: '%{vpName} 已成功编辑',
    errorContactSupport: '错误。联系支持',
    errorProcessTooltip: '进程错误',
    eventsDetection: '自动标记',
    eventsDetectionProcessedTooltip: '智能标记已处理',
    eventsDetectionTooltip: {
      footer: '*适用于超过30分钟的视频',
      header: '利用我们的技术自动检测比赛中发生的一系列事件*：',
      improved: '改进'
    },
    fieldRecognition: '现场检测',
    fieldTrackingProcessedTooltip: '现场检测处理',
    fieldTrackingTooltip:
      '处理现场检测以添加具有速度和距离值的可视化效果以及取决于 Play 中的字段的所有图纸。',
    fileDeletedSuccessfully: '%{vpName} 的 %{fileName} 已成功删除',
    fileUploadedError: '为 %{vpName} 上传 %{fileName} 时出错',
    fileUploadedSuccessfully: '为 %{vpName} 成功上传了 %{fileName}',
    forkedGameLimit: '此视频项目属于另一个组织，因此无法修改',
    gameDeletedSuccessfully: '%{game} 删除成功',
    gameVideoProject: '比赛视频项目',
    getMoreMinutes: '获得更多',
    homeTeam: '主队',
    homeTeamScore: '主队得分',
    initializing: '正在初始化...',
    jobFailed: '%{vpName} 的 %{process} 进程失败。请联系 Metrica Sports 支持团队',
    liveCoding: '还是继续进行实时编码？',
    liveCodingTooltip:
      '您可以在不为实时编码目的选择视频文件的情况下创建视频项目。获得视频后，您可以将其添加到编码视频项目中的自动跟踪数据。',
    localTeamOnTheLeft: '上半场 “主队” 位于球场左侧吗？',
    localTeamOnTheLeftTooltip:
      '要处理自动标记，请指定每支队伍位于球场的哪一边。查看您正在上传的视频以进行验证。',
    noFootballSideViewTooltip:
      '此视频项目包含非足球/足球广角或电视广播视频文件，因此仅处理了球员和球队检测。',
    noMatches: '无匹配...',
    noSubscriptionOwnerTooltip:
      '联系订阅管理员 “%{email}” 进行升级，或前往我们的套餐页面创建自己的套餐',
    noVideoFile: '没有视频文件',
    notPossibleToDeleteThisEntry: '无法删除此条目',
    numberOfHalves: '这场比赛有多少个半场？',
    numberOfHalvesTooltip: '要处理自动添加标签，请指定要上传的视频中包含多少比赛时段。',
    oldAtdTooltip: '此视频已使用旧版本的 ATD 处理',
    onRequest: '根据要求',
    organizations: '组织',
    place: '地点',
    playerTracking: '球员和球队检测',
    playerTrackingProcessedTooltip: '已处理玩家检测',
    playerTrackingTooltip:
      '此过程将检测每支球队的守门员和 10 名场上球员。这将允许您可视化实时编队（2D 音高）并在 Play 中自动添加玩家可视化。球员坐标也将在 FIFA EPTS 格式文件中提供。',
    postProcessingData: '后处理数据...',
    preProcessingAtd: '预处理...',
    processingTooltip: '加工',
    readyToUpload: '准备上传',
    search: '搜索...',
    selectVideoFile: '选择视频文件',
    settingUpAtd: '设置检测...',
    settings: '设置',
    singleVideoProject: '单视频项目',
    startAtd: '启动 ATD',
    startFifaMessage: '%{vpName} 的 FIFA 集成过程已启动。完成后我们将显示通知。请稍等。',
    startProcessingWaitingJobsFailed: '发送等待作业失败',
    startProcessingWaitingJobsSuccessfully: '等待作业发送成功',
    startSsMessage: '%{vpName} 的第二个频谱集成过程已开始。完成后我们将显示通知。请稍等。',
    startStatsMessage: '%{vpName} 的统计信息集成过程已开始。完成后我们将显示通知。请稍等。',
    startTracabDflMessage: '%{vpName} 的 Tracab DFL 集成过程已启动。完成后我们将显示通知。请稍等。',
    startTracabMessage: '%{vpName} 的 Tracab 集成过程已启动。完成后我们将显示通知。请稍等。',
    surfacesJobSuccess: '%{vpName} 表面处理已成功完成',
    trackingCorrectionProcessedTooltip: 'Tracking Correction（追踪校正）已处理',
    trackingFifaFormatJobSuccess:
      '%{vpName} 的跟踪 FIFA 格式过程已成功完成。您现在可以通过单击“下载 FIFA 格式”菜单项来下载文件',
    trackingLicence: '您没有跟踪许可证',
    uncaughtErrorRow:
      '视频项目错误。请刷新此页面。如果问题仍然存在，请联系支持人员或将其删除并重新创建',
    updatingGame: '正在更新视频项目……',
    upgradePlan: '升级',
    uploadAndTracking: '上传和跟踪',
    uploadingVideoFile: '上传视频文件',
    videoFile: '视频文件',
    videoFootballSideView: '该视频文件是足球/足球广角还是电视广播？',
    videoFootballSideViewTooltip: '现场检测仅针对这些类型的视频进行了优化。',
    videoMultipartStart: '%{videoName} 正在上传到 %{vpName}。上传完成前请勿关闭此页面',
    videoPlaylist: '此视频文件是播放列表吗？',
    videoPlaylistTooltip:
      '如果您选择“是”，将执行视频片段检测，以识别视频中何时发生剪辑，例如，如果您上传包含多个片段的视频，它会是一个完美的工具。运行此过程后，在 Play 中下载视频项目，剪辑检测编码文件将在那里，其中包含包含每个剪辑作为事件的代码。',
    videoProjectSettings: '视频项目设置',
    videoUploadError: '%{vpName} 的 %{videoName} 视频上传失败。请再试一次',
    videoUploaded: '%{videoName} 已上传到 %{vpName}',
    vpCreated: '%{vpName} 已创建',
    waitingAtd: '等待...'
  },
  billing: {
    address: '地址',
    billed: '开票',
    completed: '已付款',
    details: '账单详情',
    history: '账单历史',
    invoiceNotFound: '发票未找到',
    noInvoices: '没有发票',
    past_due: '逾期',
    taxId: '纳税人识别号'
  },
  checkout: {
    chargeSubscription: '计费到订阅',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*折扣码可在结账时使用。',
    excTax: '(不含税)',
    immediatePayment: '即时支付',
    incTax: '(含税)',
    monthlyBilled: '按月计费',
    nextPayment: '下一个付款',
    noPacks: '没有云分钟套餐',
    onDate: '在%{date}',
    packs: '套餐',
    packsTotal: '总套餐',
    perMonth: '/月',
    perYear: '/年',
    plans: '计划',
    plansTotal: '计划总数',
    proceed: '进行结账',
    subtotal: '小计',
    taxPayable: '应缴税款（%{percentage}%）',
    total: '总计',
    unpaid: '您的最新发票尚未支付。请更新您的支付信息以继续。',
    updatePaymentMethod: '更新支付方式',
    updateSubscription: '更新订阅',
    userDevice: '1 用户/设备',
    usersDevices: '%{number} 用户/设备',
    yearlyBilled: '按年计费'
  },
  common: {
    back: '后退',
    cancel: '取消',
    canceling: '取消...',
    clearAll: '全部清除',
    edit: '编辑',
    email: '电子邮件',
    errorMsg: '处理 %{name} 时出错。如需查询，请联系支持人员。',
    errorTryAgain: '错误。再试一次',
    genericError: '错误，进程失败。如需查询，请联系支持人员。',
    loading: '正在加载...',
    login: '登录',
    logout: '登出',
    new: '新的',
    next: '下一个',
    no: '不',
    noFileChosen: '没有选中任何文件',
    ok: '好的',
    required: '必需的',
    reset: '重设密码',
    restart: '重新开始',
    selectFile: '选择文件',
    sort: '种类',
    start: '开始',
    today: '今天',
    uploading: '正在上传...',
    yes: '是的'
  },
  confirmModal: {
    description: '你确定吗？',
    title: '确认'
  },
  dataIntegration: {
    deleteFile: '删除文件',
    featureNotAvailable:
      '此功能在您当前的套餐中不可用。您需要升级到另一个套餐才能拥有此功能。联系我们获取更多信息',
    notSideView: '数据集成不适用于包含非足球/足球广角或电视广播视频文件的视频项目。',
    open: '开放数据集成',
    processJobsFirst: '在访问数据集成面板之前，您需要首先开始处理自动跟踪数据 (ATD)',
    processed: '数据整合处理',
    selectFile: '选择文件',
    startProcess: '启动过程',
    title: '数据集成',
    waiting: '等待过程...',
    waitingProcess: '一旦玩家和场地检测过程完成，集成过程将开始。'
  },
  deleteModal: {
    checkboxLabel: '我也想停止接收来自 Metrica Sports 的电子邮件',
    confirmationType: '键入 %{validateFormKey} 进行确认：',
    deleteAction: '是的，删除它',
    description: '您确定要永久删除您的帐户吗？',
    information: '您将丢失使用此帐户创建的所有视频项目。一旦您删除它们，我们将无法恢复它们。',
    keepAccount: '保留帐户',
    suggestion: '我们建议保留您的帐户。',
    title: '永久删除？',
    validateFormKey: '删除'
  },
  error: {
    oops: '哎呀！',
    restart: '看起来你正试图进入我们仍在开发的东西......'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging 在使用较旧 ATD 版本的视频项目中不可用。',
    needsAtd: '首先启动ATD过程，然后才能运行Smart Tagging过程。',
    needsFootballSideView: '智能标记在包含非足球/足球宽角或电视广播视频文件的视频项目中不可用。',
    needsGameVp: 'Smart Tagging 仅在比赛视频项目中可用。',
    needsNoPlaylist: '智能标记在包含播放列表视频文件的视频项目中不可用。'
  },
  forgotPassword: {
    adminNoResetPassword: '管理员用户必须使用私有终端节点来重置密码',
    notFound: '找不到用户。请使用有效的电子邮件重试',
    success: '密码重置！请查看你的电子邮件'
  },
  login: {
    contactUs: '联系我们',
    enterYourEmail: '输入你的电子邮箱',
    enterYourPassword: '输入您的密码',
    forgotPassword: '忘记密码？',
    invalidEmail: '无效的邮件地址',
    licenceExpired: '您的许可证已过期。',
    trialExpired: '您的试用版已过期。',
    upgradePlan: '升级您的套餐'
  },
  patterns: {
    delete: '删除模式文件',
    title: '自定义模式文件',
    upload: '上传自定义模式文件',
    uploaded: '模式文件上传',
    uploading: '上传模式文件'
  },
  plans: {
    academic: '学术的',
    addMoreProducts: '+ 添加更多产品',
    admin: '行政',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code 月度',
    basicCodePadYearly: 'Basic+Code 年度的',
    basicMftCodePadMonthly: 'Full Basic 月度',
    basicMftCodePadYearly: 'Full Basic 年度的',
    basicMftMonthly: 'Basic+MFT 月度',
    basicMftXmlMonthly: 'Basic MFT + XML 月度',
    basicMftXmlYearly: 'Basic MFT + XML 年度的',
    basicMftYearly: 'Basic+MFT 年度的',
    basicMonthly: 'Basic 月度',
    basicXmlMonthly: 'Basic XML 月度',
    basicXmlYearly: 'Basic XML 年度的',
    basicYearly: 'Basic 年度的',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: '精英年度',
    eliteYearly: 'Elite 年度的',
    essentialsCodeMonthly: 'Essentials + Code ∞ 每月',
    essentialsCodeYearly: 'Essentials + Code ∞ 年度订阅',
    essentialsMftCodeMonthly: '完整版基础月付',
    essentialsMftCodeYearly: '完整版基础套餐年度版',
    essentialsMftMonthly: 'Essentials + MFT 月费版',
    essentialsMftYearly: 'Essentials + MFT 年度版',
    essentialsMonthly: 'Essentials 月费版',
    essentialsYearly: 'Essentials 年度',
    influencer: '影响者',
    lite: 'Lite',
    liteMonthly: 'Lite 月度',
    liteYearly: 'Lite 年度的',
    minutes10: '10分钟云套餐',
    minutes100: '100云分钟套餐',
    minutes1000: '1,000云分钟套餐',
    minutes10000: '10,000云分钟套餐',
    plan: '%{planName} 套餐',
    primeMftMonthly: 'Prime + MFT 月费',
    primeMftYearly: 'Prime + MFT 年度',
    primeMonthly: 'Prime 月费',
    primePresetsMftMonthly: '全套 Prime 月费',
    primePresetsMftYearly: '全年 Prime 计划',
    primePresetsMonthly: 'Prime + Presets 每月',
    primePresetsYearly: 'Prime + Presets 年度订阅',
    primeYearly: 'Prime 年度',
    pro: 'Pro',
    pro2024Monthly: '专业版月度订阅',
    pro2024Yearly: '专业版年度订阅',
    proYearly: 'Pro 年度的',
    starter: 'Starter',
    starterCodeMonthly: '入门版 + 代码 ∞ 月付',
    starterCodePadMonthly: 'Code 月度',
    starterCodePauseMonthly: '完整入门版月付',
    starterCodePauseYearly: '完整入门年度计划',
    starterCodeYearly: '入门版 + 代码 ∞ 年度',
    starterPauseMonthly: '入门版 + 无限暂停 月付',
    starterPauseYearly: '入门版 + 无限暂停 年度订阅',
    student: '学生',
    trial: '审判'
  },
  playlist: {
    download: '下载播放列表文件',
    notProcessed: '本视频项目未处理此播放列表文件。',
    processed: '播放列表文件已处理',
    title: '播放列表',
    titleDownload: '下载播放列表'
  },
  products: {
    addOns: '附加组件',
    basic: '无限制代码与注释',
    code: 'CodePad',
    elite: {
      description:
        '自动化并定制您的整个分析工作流程。此计划包括完全访问Play2并拥有“Full Essentials”计划的所有功能，以及访问Play3并拥有“Pro”计划的所有功能。',
      feature1: '✓ 共享云存储',
      feature2: '✓ 跟踪数据集成',
      feature3: '✓ 跟踪修正管理器',
      feature4: '✓ RESTful API',
      feature5: '✓ 5,000 ATD 分钟套餐'
    },
    essentials: {
      addon1: '无限制编码功能（CodePad、实时编码和XML导入）',
      addon2: '手动场地追踪',
      essentialsCodeDescription:
        '此计划启用动态标注功能，允许在播放视频和暂停时添加无限的动态可视化效果。此计划不包括手动场地追踪，因此不包括速度和距离的可视化效果。它还解锁了编码功能的限制，如CodePad、现场编码和XML导入，并将每个视频项目的4个代码限制提升至无限代码。',
      essentialsDescription:
        '此计划启用动态标注功能，允许在播放视频和暂停时添加无限的动态视觉效果。此计划不包括手动场地追踪，因此不包括速度和距离的视觉效果。编码功能仍然受限。',
      essentialsMftCodeDescription:
        '此计划启用动态标注功能，允许在播放视频和暂停时添加无限的动态可视化效果，并激活手动场地追踪，以添加速度和距离的可视化效果。此外，它还解锁了编码功能的限制，如CodePad、实时编码和XML导入，并取消了每个视频项目仅限4个代码的限制，允许使用无限代码。',
      essentialsMftDescription:
        '此计划启用动态标注功能，允许在播放视频和暂停时添加无限的动态可视化效果，并激活手动场地追踪，以添加速度和距离的可视化显示。编码功能仍然受限。',
      feature1: '✓ 比赛后编码（Code Table 最多4个代码）',
      feature2: '✓ 暂停时无限制的标注显示',
      feature3: '✓ 动态标注',
      feature4: '✓ 3 分钟 ATD 套餐'
    },
    featuresIncluded: '功能包括：',
    freeFeature1: '✓ 比赛后编码（Code Table 最多包含4个代码）',
    freeFeature2: '✓ 关键帧制作',
    freeFeature3: '✓ 标注（最多150个注释）',
    freeFeature4: '✓ 视频导出',
    freeFeatures: '免费功能',
    fullBasic: '手动场地追踪',
    lite: '自动球员和场地追踪',
    liteDescription: '高级编码和高级可视化。全自动，比Full Basic快3倍！',
    minutesDescription: '为任何计划充值。获取自动跟踪数据（ATD）的分钟包。',
    minutesPack: '云分钟套餐',
    otherDescription: '高级编码功能，配备基础插图和有限的注释',
    prime: {
      addon1: '手动场地追踪',
      addon2: '预设标注（自定义并保存您自己的可视化）',
      feature1: '✓ 使用“Full Essentials”计划的所有功能，完全访问Play2',
      feature2: '✓ 访问Play3，包括：',
      feature2_1: '顺序暂停',
      feature2_2: '高级电视插图',
      feature2_3: '自定义视频导出',
      feature3: '✓ 10分钟ATD套餐',
      primeDescription:
        '此计划包括完全访问Play2，具备“Essentials + Code ∞”计划的所有功能，并启用Play3的高级插图功能。',
      primeMftDescription:
        '此计划包括完全访问Play2，并拥有“Full Essentials”计划的所有功能，并启用Play3与高级插图功能。',
      primePresetsDescription:
        '此计划包括完全访问Play2，拥有“Essentials + Code ∞”计划的所有功能，并启用Play3，具有高级插图和自定义视图功能。',
      primePresetsMftDescription:
        '此计划包括完全访问Play2，具有“Full Essentials”计划的所有功能，并启用Play3，具有高级插图和自定义视图功能。'
    },
    pro: {
      description:
        '此计划包括完全访问Play2，拥有“Full Essentials”计划的所有功能，并且可以完全访问Play3，拥有“Full Prime”计划的所有功能。',
      feature1: '✓ 自动球员追踪',
      feature2: '✓ 自动场地追踪',
      feature3: '✓ 使用“Full Essentials”计划的所有功能，完全访问Play2',
      feature4: '✓ 访问Play3，包括：',
      feature4_1: '顺序暂停',
      feature4_2: '高级电视插图',
      feature4_3: '自定义视频导出',
      feature4_4: '预设标注（自定义并保存您自己的可视化）',
      feature5: '✓ 100分钟ATD套餐'
    },
    remove: '删除',
    starter: {
      addon1: '暂停时无限制的插图显示',
      addon2: '无限制编码功能（CodePad、实时编码和XML导入）',
      feature1: '✓ 比赛后编码（Code Table 最多包含4个代码）',
      feature2: '✓ 暂停时的插图标注（每月最多50个注释）',
      feature3: '✓ 视频导出',
      starterCodeDescription:
        '此计划解锁了编码功能的限制，如CodePad、实时编码和XML导入。它还将每个视频项目的代码限制从4个提升至无限。在Free Starter中，插图功能的限制仍然存在。',
      starterCodePauseDescription:
        '此计划解锁了编码功能的限制，如CodePad、实时编码和XML导入。它还将每个视频项目的代码限制从4个提升至无限，并将每月在暂停时的标注限制从50个解锁至无限。',
      starterPauseDescription:
        '此计划将暂停中的插图限制从每月50次解锁至无限次。编码功能的限制仍然适用于Free Starter。'
    },
    totalPack: '总计：%{currency} %{cost}',
    totalPlan: '总计：%{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: '账单',
    billingSettings: '计费设置',
    billingSettingsDescription: '（更改套餐，更新付款方式...）',
    cancelAt: '您的套餐取消于',
    cancelPlan: '取消计划',
    confirmNewPassword: '确认新密码',
    deleteAccount: '删除帐户',
    details: '我的详细信息',
    emailNotActivated: '电子邮件通知现已激活',
    emailNotDeactivated: '电子邮件通知现已停用',
    emailNotifications: '电子邮件通知',
    language: '语言',
    languageChanged: '语言已更新',
    newPassword: '新密码',
    organizationLabel: '组织前缀',
    organizationTooltip:
      '将自定义模式导入视频项目需要此前缀。联系我们以获取更多信息或查看 codeball.metrica-sports.com 上的文档',
    passwordsDontMatch: '密码不匹配',
    pauseAt: '您的计划将在暂停。',
    planCanceled: '您的计划已取消。请查看您的电子邮件，获取更多信息',
    plans: '计划',
    quantity: '数量：',
    renewAt: '您的套餐续期为',
    title: '设置',
    upgradePlan: '升级套餐',
    userKey: '用户密钥：',
    userKeyUsages: '%{current}/%{max} 次使用',
    userSettings: '用户设置'
  },
  shoppingCart: {
    subtitle: '选择您的 Play 计划和附加组件以提升您的表现。',
    title: '设置你的 Play'
  },
  subscription: {
    cancelSubscription: '取消订阅',
    errorUpdating:
      '更新您的订阅时出现错误。请发送电子邮件至support@metrica-sports.com，我们将很乐意为您提供帮助。',
    free: '免费',
    isObsolete: '您的订阅无法修改。请将过时的产品替换为新产品以启用更改',
    licenseKeys: '许可证密钥',
    pendingRefundRequest: '在有待处理的退款请求时，无法对此订阅进行更多更改。',
    undoCancel: '撤销订阅取消',
    undoPause: '取消订阅暂停',
    updatePaymentMethod: '更新支付方式',
    updatedSuccessfully: '订阅成功更新',
    yourPlans: '你的计划',
    yourSubscription: '你的订阅'
  },
  successfulPayment: {
    activateLicence: '通过输入提供的许可证密钥来激活您的许可证或Cloud信用。',
    allTheBest: '一切顺利，',
    applyKey: '将密钥应用于此账户',
    applyKeyDescription: '您是否希望自动为您当前登录的账户应用许可证密钥？',
    downloadPlay: '%{pre}通过我们的网站下载PLAY%{post}。',
    faq: '为了帮助您开始您的订单，请查看我们网站上的%{pre}常见问题解答%{post}。以下是在此阶段对您可能最相关的信息的快速总结：',
    goToSummary: '如果不是，请参考下面的摘要以获取激活指令。',
    happyPlay:
      '我们希望一切都很清楚，但如果您有任何其他问题或疑问，请不要犹豫，随时联系我们。祝您玩得开心！',
    helpDesk: '查看我们的%{pre}HelpDesk%{post}，获取使用PLAY的一般信息。',
    keyApplied: '密钥已应用',
    licenseKey: '你的许可证密钥是：%{pre}%{licenseKey}%{post}',
    metricaSportsTeam: '米特里卡体育团队',
    settingsCloud: '访问我们的%{pre}Cloud%{post}并导航至设置菜单以：',
    technicalSupport:
      '如需技术支持，请填写PLAY/Cloud中提供的联系表格，或发送电子邮件至%{pre}support@metrica-sports.com%{post}。',
    termsAndConditions:
      '请注意，根据我们的条款和条件，我们的订阅会自动续订，直到通过%{pre}Cloud%{post}的设置明确取消。',
    thanks: '感谢您的订单！我们已成功处理并在下方附上了您的许可证密钥。',
    title: '订单确认',
    updatePayment: '更新您的支付方式。',
    updateSubscription: '取消或修改您的订阅。'
  },
  surfaces: {
    automaticVp: '具有较旧 ATD 版本的视频项目上不可用的表面',
    delete: '删除曲面文件',
    needsAtd: '首先启动 ATD 进程以运行 Surface 进程',
    needsElite: '精英版提供自定义表面。联系我们获取更多信息',
    needsFootballSideView: '包含非足球/足球广角或电视广播视频文件的视频项目中不可用的表面',
    pitchControl: '通过运行此过程，您可以在 Play 中添加 Pitch Control 可视化。',
    pitchControlProcessed: '俯仰控制表面处理',
    pitchControlProcessing: '处理音高控制',
    processed: '已上传 Surface 文件',
    processing: '处理表面文件',
    title: '表面',
    titlePitchControl: '音高控制',
    upload: '上传您自己的 Surface',
    uploading: '正在上传 Surface...'
  },
  trackingFifaFormat: {
    download: '下载 FIFA EPTS 格式文件',
    needsElite:
      '此功能仅在将相应文件导入数据集成模块、具有自动跟踪数据的视频项目或精英版套餐中的视频项目后才可用。',
    processed: 'FIFA EPTS 格式已处理',
    start: '开始生成 FIFA EPTS 格式文件的过程。然后你就可以下载文件了',
    title: 'FIFA EPTS 格式',
    titleDownload: '下载 FIFA EPTS 格式'
  },
  upgradeModal: {
    linksDescription: '获取最适合您的 Cloud Engine 分钟包：',
    minutePackLinks: {
      minutes_10: '10 欧元可获得 10 分钟',
      minutes_100: '90 欧元可获得 100 分钟',
      minutes_1000: '750 欧元即可获得 1.000 分钟',
      minutes_10000: '5.000 欧元即可获得 10.000 分钟'
    },
    noMinutes: {
      description:
        '您没有足够的可用分钟数来创建此视频项目。你知道无论你的套餐是什么，你都可以获得分钟包吗？',
      secondDescription: '',
      title: '获得更多时间来创建视频项目！'
    },
    notEnoughMinutes: {
      description: '很遗憾地通知您，您的视频项目的 ATD 处理时间不足。',
      secondDescription:
        '如果您想处理 ATD，您需要在您的帐户中添加更多分钟。您将获得的分钟数不会过期！',
      title: '可用分钟数不足'
    }
  },
  video: {
    cancelUpload: '取消上传',
    delete: '删除视频',
    download: '下载视频',
    title: '视频文件',
    upload: '上传视频',
    uploaded: '视频上传',
    uploading: '上传视频'
  },
  videoProject: {
    delete: '删除视频项目',
    edit: '编辑视频项目',
    processWaitingJobs: '处理等待作业',
    title: '视频项目'
  }
}
