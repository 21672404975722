import { Component } from 'react'

class ChangePercentage extends Component {
  state = {
    percentage: null
  }

  updatePercentage = percentage => {
    this.setState({ percentage })
  }

  render() {
    const renderFn = this.props.render || this.props.children // eslint-disable-line

    return renderFn({
      percentage: this.state.percentage,
      updatePercentage: this.updatePercentage
    })
  }
}

export default ChangePercentage
