function toTimestamp(value) {
  if (value instanceof Date) {
    return value.getTime()
  }

  const date = new Date(value)
  return date.getTime()
}

export default toTimestamp
