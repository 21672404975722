export default {
  addKeyModal: {
    action: 'Zastosuj',
    errorMessage:
      'Błąd podczas stosowania klucza. Jeśli problem nie ustąpi, skontaktuj się z zespołem pomocy Metrica Sports',
    minutesMessage: 'Twój klucz został przetworzony. Masz teraz %{minutes} minut dostępnych.',
    placeholder: 'Wpisz klucz...',
    successMessage: 'Klucz został pomyślnie zastosowany',
    title: 'Dodaj klucz'
  },
  admin: {
    NoGames: 'Brak gier, dodaj swoją pierwszą...',
    NoResults: 'Brak wyników...',
    addKey: 'Dodaj klucz',
    atd: 'ATD',
    automatedTrackingData: 'Automatyczny Tracking Danych (ATD)',
    automatedTrackingDataJobSuccess:
      'Proces automatycznego śledzenia danych na %{vpName} zakończył się i jest teraz dostępny w Metrica Play',
    automatedTrackingDataProcessedTooltip: 'Przetwarzane zautomatyzowane dane śledzenia',
    awayTeam: 'Drużyna gości',
    awayTeamScore: 'Wynik drużyny gości',
    ballDetection: 'Wykrywanie piłki',
    ballTracking: 'Tracking Piłki',
    ballTrackingProcessedTooltip: 'Przetworzono wykrywanie piłki',
    ballTrackingTooltip:
      'Ten proces wykryje ruch piłki i umożliwi jej wizualizację w podglądzie formacji na żywo w grze (na boisku 2D). Współrzędne piłki będą również dostępne w pliku w formacie FIFA EPTS.',
    cancelATDProcessSuccessfully: 'Automatyczny Tracking Danych został anulowany w %{vpName}',
    cancelDataIntegrationProcessSuccessfully: 'Proces integracji danych został anulowany %{vpName}',
    cancelEventsProcessSuccessfully: 'Proces Smart Tagging został anulowany w %{vpName}.',
    cancelProcessTooltip: 'Anuluj proces',
    cancelSurfacesProcessSuccessfully: 'Przetwarzanie powierzchni zostało anulowane w %{vpName}',
    cancelTrackingFifaFormatProcessSuccessfully:
      'Tracking formatu FIFA został anulowany w %{vpName}',
    category: 'Kategoria',
    checking: 'Sprawdzanie…',
    cloudMinutes: 'Minuty w chmurze',
    competition: 'Rozgrywki',
    connectionFailed: 'Połączenie nie powiodło się',
    connectionFailedText:
      'Wygląda na to, że wystąpił problem z pobraniem aktualnych informacji. Sprawdź połączenie internetowe i ponownie załaduj stronę. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną',
    correctedTracking: 'Tracking Correction Processing',
    correctionUsage: 'Czy będziesz używać tego Video Project do korekty Trackingu?',
    correctionUsageTooltip:
      'Tracking Correction Manager (TCM) jest zorientowanym na użytkownika modułem w Play, służącym do korygowania śladów wygenerowanych wcześniej za pomocą naszego ATD, który generuje dane bez identyfikatorów automatycznie dla każdego meczu piłkarskiego. Użyj TCM, aby poprawić błędne ślady i przypisać im tożsamość. Skontaktuj się z nami, aby uzyskać więcej informacji.',
    createVideoProject: 'Utwórz projekt wideo',
    creatingGame: 'Tworzenie Video Project...',
    dataIntegrationFailed:
      'Integracja danych nie powiodła się w %{vpName}. Przejdź do panelu integracji danych, sprawdź swoje pliki i spróbuj ponownie. Jeśli błąd będzie się powtarzał, skontaktuj się z zespołem pomocy Metrica Sports',
    dataIntegrationJobSuccess: 'Proces integracji danych dla %{vpName} został pomyślnie zakończony',
    date: 'Data',
    description: 'Opis',
    editedSuccessfully: '%{vpName} został pomyślnie edytowany',
    errorContactSupport: 'Błąd. Skontaktuj się z pomocą techniczną',
    errorProcessTooltip: 'Błąd w procesie',
    eventsDetection: 'Automatyczne tagowanie',
    eventsDetectionProcessedTooltip: 'Smart Tagging przetworzony',
    eventsDetectionTooltip: {
      footer: '*Dostępne dla filmów dłuższych niż 30 minut.',
      header:
        'Skorzystaj z naszej technologii, aby automatycznie wykrywać serię zdarzeń, które mają miejsce podczas meczu*.',
      improved: 'Ulepszony'
    },
    fieldRecognition: 'Wykrywanie pola gry',
    fieldTrackingProcessedTooltip: 'Przetworzono wykrywanie pola gry',
    fieldTrackingTooltip:
      'Rozpocznij wykrywanie pola gry, aby dodać wizualizacje z danymi prędkości i odległości oraz wszystkie inne rysunki.',
    fileDeletedSuccessfully: '%{fileName} został pomyślnie usunięty dla %{vpName}',
    fileUploadedError: 'Błąd podczas przesyłania %{fileName} dla %{vpName}',
    fileUploadedSuccessfully: '%{fileName} został pomyślnie przesłany do %{vpName}',
    forkedGameLimit: 'Ten projekt wideo należy do innej organizacji, więc nie można go modyfikować',
    gameDeletedSuccessfully: '%{game} usunięto pomyślnie',
    gameVideoProject: 'Projekt wideo',
    getMoreMinutes: 'Uzyskaj więcej',
    homeTeam: 'Drużyna gospodarzy',
    homeTeamScore: 'Wynik drużyny gospodarzy',
    initializing: 'Inicjowanie...',
    jobFailed:
      'Proces %{process} nie powiódł się dla %{vpName}. Skontaktuj się z zespołem pomocy Metrica Sports',
    liveCoding: 'czy kontynuować Coding na żywo?',
    liveCodingTooltip:
      'Możesz utworzyć projekt wideo bez wybierania pliku wideo do pracy w trybie Coding na żywo. Gdy masz już wideo, możesz je dodać, aby uzyskać Automatyczny Tracking Danych w danym projekcie wideo.',
    localTeamOnTheLeft:
      'Czy drużyna gospodarzy znajduje się po lewej stronie boiska w pierwszej połowie?',
    localTeamOnTheLeftTooltip:
      'Aby przetworzyć Automatyczne Tagowanie, określ, po której stronie boiska znajduje się każda drużyna. Sprawdź przesyłany film, aby go zweryfikować.',
    noFootballSideViewTooltip:
      'Ten projekt wideo zawiera plik wideo niebędący nagraniem szerokokątnym ani nagraniem telewizyjnym, więc przetworzono tylko wykrywanie zawodników i drużyn.',
    noMatches: 'Brak meczów...',
    noSubscriptionOwnerTooltip:
      "Skontaktuj się z '%{email} ' (Admin Twojej subskrypcji), aby uaktualnić plan, lub przejdź do sekcji Plany na naszej stronie, aby utworzyć własną opcję",
    noVideoFile: 'Brak pliku wideo',
    notPossibleToDeleteThisEntry: 'Nie można usunąć tego wpisu',
    numberOfHalves: 'Na ile części podzielony jest ten mecz?',
    numberOfHalvesTooltip:
      'Aby przetworzyć Automatyczne Tagowanie, określ ile części gry jest uwzględnionych w przesyłanym filmie.',
    oldAtdTooltip: 'Ten film został przetworzony przy użyciu starszej wersji ATD',
    onRequest: 'Na życzenie',
    organizations: 'Organizacje',
    place: 'Miejsce',
    playerTracking: 'Wykrywanie Zawodników i Drużyny',
    playerTrackingProcessedTooltip: 'Przetworzono wykrywanie gracza',
    playerTrackingTooltip:
      'Ten proces wykryje bramkarzy i 10 zawodników z każdej drużyny. Umożliwi to wizualizację formacji na żywo (boisko 2D) i automatyczne dodawanie wizualizacji gracza w Play. Współrzędne graczy będą również dostępne w pliku w formacie FIFA EPTS.',
    postProcessingData: 'Dalsze przetwarzanie danych...',
    preProcessingAtd: 'Wstępne przetwarzanie...',
    processingTooltip: 'Przetwarzanie',
    readyToUpload: 'Można rozpocząć przesyłanie',
    search: 'Szukaj...',
    selectVideoFile: 'Wybierz plik wideo',
    settingUpAtd: 'Konfigurowanie wykrywania...',
    settings: 'Ustawienia',
    singleVideoProject: 'Pojedynczy projekt wideo',
    startAtd: 'Rozpocznij ATD',
    startFifaMessage:
      'Proces integracji FIFA dla %{vpName} został rozpoczęty. Po zakończeniu wyświetlimy powiadomienie. Proszę czekać.',
    startProcessingWaitingJobsFailed: 'Wysyłanie zadań oczekujących nie powiodło się',
    startProcessingWaitingJobsSuccessfully: 'Zadania oczekujące wysłane pomyślnie',
    startSsMessage:
      'Rozpoczęła się integracja Second Spectrum dla %{vpName}. Po zakończeniu wyświetlimy stosowne powiadomienie. Proszę czekać.',
    startStatsMessage:
      'Rozpoczął się proces integracji "Stats" dla %{vpName}. Po zakończeniu wyświetlimy powiadomienie. Proszę czekać.',
    startTracabDflMessage:
      'Proces integracji Tracab DFL dla %{vpName} został rozpoczęty. Po zakończeniu wyświetlimy powiadomienie. Proszę czekać.',
    startTracabMessage:
      'Proces integracji Tracab dla %{vpName} został rozpoczęty. Po zakończeniu wyświetlimy powiadomienie. Proszę czekać.',
    surfacesJobSuccess: 'Diagnozowanie powierzchni w %{vpName} zakończyło się pomyślnie',
    trackingCorrectionProcessedTooltip: 'Tracking Correction processed',
    trackingFifaFormatJobSuccess:
      'Tracking formatu FIFA dla %{vpName} został pomyślnie zakończony. Możesz teraz pobrać plik, klikając pozycję "Pobierz format FIFA" w menu.',
    trackingLicence: 'Nie posiadasz licencji z trybem Tracking',
    uncaughtErrorRow:
      'Błąd projektu wideo. Proszę odśwież tę stronę. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną, lub usuń go i utwórz ponownie',
    updatingGame: 'Aktualizowanie Video Project...',
    upgradePlan: 'Ulepsz',
    uploadAndTracking: 'Przesyłanie i Tracking',
    uploadingVideoFile: 'Przesyłanie pliku wideo',
    videoFile: 'Plik wideo',
    videoFootballSideView:
      'Czy ten plik wideo to szerokokątne lub telewizyjne nagranie meczu piłki nożnej?',
    videoFootballSideViewTooltip:
      'Wykrywanie pola jest zoptymalizowane tylko dla tego typu filmów.',
    videoMultipartStart:
      '%{videoName} jest przesyłany do %{vpName}. NIE ZAMYKAJ TEJ STRONY, dopóki przesyłanie nie zostanie zakończone',
    videoPlaylist: 'Czy ten plik wideo jest listą odtwarzania?',
    videoPlaylistTooltip:
      'Jeśli wybierzesz TAK, zostanie uruchomione wykrywanie klipów, aby rozpoznać, kiedy w filmie ma miejsce cięcie, co czyni go idealnym narzędziem, jeśli na przykład przesyłasz wideo zawierające kilka klipów. Po uruchomieniu tego procesu pobierz projekt wideo w Play, a następnie każde cięcie będzie widoczne jako zdarzenie.',
    videoProjectSettings: 'Ustawienia projektu wideo',
    videoUploadError:
      'Przesyłanie wideo %{videoName} dla %{vpName} nie powiodło się. Proszę spróbuj ponownie',
    videoUploaded: '%{videoName} został przesłany na %{vpName}',
    vpCreated: '%{vpName} został utworzony',
    waitingAtd: 'Oczekiwanie...'
  },
  billing: {
    address: 'Adres',
    billed: 'Fakturowany',
    completed: 'Opłacony',
    details: 'Szczegóły Faktury',
    history: 'Historia Fakturowania',
    invoiceNotFound: 'Faktura nie znaleziona.',
    noInvoices: 'Brak faktur.',
    past_due: 'Przeterminowany',
    taxId: 'NIP'
  },
  checkout: {
    chargeSubscription: 'Obciążenie subskrypcji',
    costOneTime: '%{currency} %{cost}',
    costRecurrent: '%{currency} %{cost} %{cycle}',
    discountCodes: '*Kody rabatowe można zastosować przy kasie.',
    excTax: '(Bez podatku)',
    immediatePayment: 'Natychmiastowa płatność',
    incTax: '(Brutto)',
    monthlyBilled: 'Rozliczane miesięcznie',
    nextPayment: 'Następna płatność',
    noPacks: 'Bez Pakietu Minut Cloud',
    onDate: 'dnia %{date}',
    packs: 'Pakiety',
    packsTotal: 'Pakiety Total',
    perMonth: '/miesiąc',
    perYear: '/rok',
    plans: 'Plany',
    plansTotal: 'Łączna liczba planów',
    proceed: 'Przejdź do kasy',
    subtotal: 'Suma częściowa',
    taxPayable: 'Podatek do zapłaty (%{percentage}%)',
    total: 'Całkowity',
    unpaid:
      'Twoja ostatnia faktura nie została jeszcze opłacona. Proszę zaktualizuj swoje informacje płatnicze, aby kontynuować.',
    updatePaymentMethod: 'Aktualizuj metodę płatności',
    updateSubscription: 'Aktualizuj subskrypcję',
    userDevice: '1 użytkownik/urządzenie',
    usersDevices: '%{number} użytkowników/urządzeń',
    yearlyBilled: 'Rocznie fakturowane'
  },
  common: {
    back: 'Cofnij',
    cancel: 'Anuluj',
    canceling: 'Anulowanie...',
    clearAll: 'Wyczyść wszystko',
    edit: 'Edytuj',
    email: 'E-mail',
    errorMsg:
      'Błąd przetwarzania %{name}. W przypadku pytań prosimy o kontakt z pomocą techniczną.',
    errorTryAgain: 'Błąd. Spróbuj ponownie',
    genericError:
      'Błąd, proces nie powiódł się. W przypadku pytań prosimy o kontakt z pomocą techniczną.',
    loading: 'Ładowanie...',
    login: 'Zaloguj się',
    logout: 'Wyloguj',
    new: 'Nowy',
    next: 'Kolejny',
    no: 'Nie',
    noFileChosen: 'Nie wybrano żadnego pliku',
    ok: 'Ok',
    required: 'Wymagane',
    reset: 'Zresetuj hasło',
    restart: 'Uruchom ponownie',
    selectFile: 'Wybierz plik',
    sort: 'Sortuj',
    start: 'Rozpocznij',
    today: 'Dziś',
    uploading: 'Przesyłanie...',
    yes: 'Tak'
  },
  confirmModal: {
    description: 'Jesteś pewien?',
    title: 'Potwierdź'
  },
  dataIntegration: {
    deleteFile: 'Usuń plik',
    featureNotAvailable:
      'Ta funkcja nie jest dostępna w bieżącym planie. Aby mieć tę funkcję, musisz przejść na inny plan. Skontaktuj się z nami, aby uzyskać więcej informacji',
    notSideView:
      'Integracja danych nie jest dostępna w projektach wideo zawierających plik wideo inny niż szerokokątne, lub telewizyjne nagranie meczu.',
    open: 'Otwarta integracja danych',
    processJobsFirst:
      'Musisz najpierw rozpocząć Automatyczny Tracking Danych (ATD), zanim uzyskasz dostęp do panelu integracji danych',
    processed: 'Integracja danych przetworzona',
    selectFile: 'Wybierz plik',
    startProcess: 'Rozpocznij proces',
    title: 'Integracja danych',
    waiting: 'Proces oczekiwania...',
    waitingProcess:
      'Proces integracji rozpocznie się po zakończeniu procesów wykrywania zawodników i pola gry.'
  },
  deleteModal: {
    checkboxLabel: 'Chcę również przestać otrzymywać e-maile od Metrica Sports',
    confirmationType: 'Wpisz %{validateFormKey}, aby potwierdzić:',
    deleteAction: 'Tak, usuń to',
    description: 'Czy na pewno chcesz trwale usunąć swoje konto?',
    information:
      'Utracisz wszystkie projekty wideo utworzone za pomocą tego konta. Nie możemy ich odzyskać po usunięciu.',
    keepAccount: 'Zachowaj konto',
    suggestion: 'Sugerujemy, aby zachować swoje konto.',
    title: 'Usunąć ostatecznie?',
    validateFormKey: 'USUŃ'
  },
  error: {
    oops: 'UUPS!',
    restart: 'Wygląda na to, że próbujesz dostać się do obszaru, nad którym wciąż pracujemy...'
  },
  eventsDetection: {
    automaticVp: 'Smart Tagging nie jest dostępne w Projektach Wideo z starszą wersją ATD.',
    needsAtd: 'Rozpocznij najpierw proces ATD, aby móc uruchomić proces Smart Tagging.',
    needsFootballSideView:
      'Smart Tagging nie jest dostępne w Projektach Wideo zawierających plik wideo z szerokokątnym ujęciem lub transmisją telewizyjną, które nie dotyczą piłki nożnej.',
    needsGameVp: 'Smart Tagging jest dostępny tylko w Projektach Wideo Meczowych.',
    needsNoPlaylist:
      'Smart Tagging nie jest dostępne w Projektach Wideo zawierających plik wideo będący listą odtwarzania.'
  },
  forgotPassword: {
    adminNoResetPassword:
      'Użytkownicy o statusie "Admin" muszą używać prywatnego punktu końcowego do resetowania hasła',
    notFound: 'Użytkownik nie został znaleziony. Spróbuj ponownie, podając prawidłowy adres e-mail',
    success: 'Hasła zresetowane! Proszę sprawdzić pocztę'
  },
  login: {
    contactUs: 'Skontaktuj się z nami',
    enterYourEmail: 'Wpisz swój e-mail',
    enterYourPassword: 'Wprowadź hasło',
    forgotPassword: 'Zapomniałeś hasła?',
    invalidEmail: 'Nieprawidłowy adres e-mail',
    licenceExpired: 'Twoja licencja wygasła.',
    trialExpired: 'Twój okres "trial\' upłynął.',
    upgradePlan: 'Uaktualnij swój plan'
  },
  patterns: {
    delete: 'Usuń plik wzorca',
    title: 'Niestandardowy plik wzoru',
    upload: 'Prześlij plik niestandardowego wzoru',
    uploaded: 'Przesłano plik wzorca',
    uploading: 'Przesyłanie pliku wzorca'
  },
  plans: {
    academic: 'Academic',
    addMoreProducts: '+ Dodaj więcej produktów',
    admin: 'Admin',
    basic: 'Basic',
    basicCodePadMonthly: 'Basic+Code - Miesięczny',
    basicCodePadYearly: 'Basic+Code - Roczny',
    basicMftCodePadMonthly: 'Full Basic - Miesięcznie',
    basicMftCodePadYearly: 'Full Basic - Roczny',
    basicMftMonthly: 'Basic+MFT - Miesięczny',
    basicMftXmlMonthly: 'Basic MFT + XML - Miesięczny',
    basicMftXmlYearly: 'Basic MFT + XML - Roczny',
    basicMftYearly: 'Basic+MFT - Roczny',
    basicMonthly: 'Basic - Miesięczny',
    basicXmlMonthly: 'Basic XML - Miesięczny',
    basicXmlYearly: 'Basic XML - Roczny',
    basicYearly: 'Basic - Roczny',
    code: 'Code',
    elite: 'Elite',
    elite2024Yearly: 'Elite roczny',
    eliteYearly: 'Elite - Roczny',
    essentialsCodeMonthly: 'Essentials + Code ∞ Miesięczny',
    essentialsCodeYearly: 'Essentials + Code ∞ Roczny',
    essentialsMftCodeMonthly: 'Full Essentials Miesięczny',
    essentialsMftCodeYearly: 'Full Essentials Roczny',
    essentialsMftMonthly: 'Essentials + MFT Miesięczny',
    essentialsMftYearly: 'Essentials + MFT Roczny',
    essentialsMonthly: 'Essentials Miesięczny',
    essentialsYearly: 'Essentials Roczny',
    influencer: 'Influencer',
    lite: 'Lite',
    liteMonthly: 'Lite - Miesięczny',
    liteYearly: 'Lite - Roczny',
    minutes10: 'Pakiet 10 Minut Cloud',
    minutes100: 'Pakiet 100 Minut w Chmurze',
    minutes1000: 'Pakiet 1.000 Minut w Chmurze',
    minutes10000: 'Pakiet 10.000 Minut w Chmurze',
    plan: 'Plan %{planName}',
    primeMftMonthly: 'Prime + MFT Miesięczny',
    primeMftYearly: 'Prime + MFT Roczny',
    primeMonthly: 'Prime Miesięczny',
    primePresetsMftMonthly: 'Full Prime Miesięczny',
    primePresetsMftYearly: 'Full Prime Roczny',
    primePresetsMonthly: 'Prime + Presets Miesięczny',
    primePresetsYearly: 'Prime + Presets Roczny',
    primeYearly: 'Prime roczny',
    pro: 'Pro',
    pro2024Monthly: 'Pro Miesięczny',
    pro2024Yearly: 'Pro rocznie',
    proYearly: 'Pro - Roczny',
    starter: 'Starter',
    starterCodeMonthly: 'Starter + Code ∞ Miesięczny',
    starterCodePadMonthly: 'Code - Miesięczny',
    starterCodePauseMonthly: 'Full Starter Miesięczny',
    starterCodePauseYearly: 'Full Starter Roczny',
    starterCodeYearly: 'Starter + Code ∞ Rocznik',
    starterPauseMonthly: 'Starter + Pause ∞ Miesięczny',
    starterPauseYearly: 'Starter + Pause ∞ Roczny',
    student: 'Student',
    trial: 'Trial'
  },
  playlist: {
    download: 'Pobierz plik playlisty',
    notProcessed: 'Playlista nie została processed w tym Video Project.',
    processed: 'Plik playlisty processed',
    title: 'Playlist',
    titleDownload: 'Pobierz playlistę'
  },
  products: {
    addOns: 'Dodatki',
    basic: 'Nieograniczone Kody i Adnotacje',
    code: 'CodePad',
    elite: {
      description:
        'Automatyzuj i dostosuj cały swój proces analizy. Ten plan obejmuje pełny dostęp do Play2 ze wszystkimi funkcjami Planu "Full Essentials" oraz dostęp do Play3 ze wszystkimi funkcjami Planu "Pro".',
      feature1: '✓ Współdzielone przechowywanie w Chmurze',
      feature2: '✓ Integracja Danych Trackingowych',
      feature3: '✓ Menadżer Korekty Śledzenia',
      feature4: '✓ RESTful API',
      feature5: '✓ Pakiet 5.000 minut ATD'
    },
    essentials: {
      addon1: 'Nieograniczone funkcje Coding (CodePad, Live Coding i Import XML)',
      addon2: 'Manualne Śledzenie na Boisku',
      essentialsCodeDescription:
        'Ten plan umożliwia korzystanie z Dynamicznych Telestracji, pozwalając na dodawanie nieograniczonych wizualizacji ruchu podczas odtwarzania wideo oraz w pauzach. Plan ten nie obejmuje Ręcznego Śledzenia Pola, więc wizualizacje Prędkości i Dystansu nie są włączone. Ponadto, odblokowuje ograniczenia funkcji kodowania takich jak CodePad, Kodowanie na Żywo i Import XML, oraz znosi ograniczenie do 4 kodów na Projekt Wideo, umożliwiając korzystanie z nieograniczonej liczby kodów.',
      essentialsDescription:
        'Ten plan umożliwia korzystanie z Dynamic Telestration, co pozwala na dodawanie nieograniczonych wizualizacji ruchu podczas odtwarzania wideo oraz w przerwach. Ten plan nie obejmuje Manual Field Tracking, więc wizualizacje Prędkości i Dystansu nie są włączone. Funkcje kodowania pozostają ograniczone.',
      essentialsMftCodeDescription:
        'Ten plan umożliwia korzystanie z Dynamicznych Telestracji, pozwalając na dodawanie nieograniczonych wizualizacji ruchu podczas odtwarzania wideo i w pauzach, oraz aktywuje Manuel Field Tracking do dodawania wizualizacji prędkości i dystansu. Ponadto usuwa ograniczenie funkcji kodowania takich jak CodePad, Live Coding i Import XML, oraz znosi limit 4 kodów na Projekt Wideo na nieograniczoną liczbę kodów.',
      essentialsMftDescription:
        'Ten plan umożliwia korzystanie z Dynamicznych Telestracji, pozwalając na dodawanie nieograniczonych wizualizacji ruchu podczas odtwarzania wideo i w przerwach, oraz aktywuje Ręczne Śledzenie na Boisku, aby dodawać wizualizacje prędkości i dystansu. Funkcje kodowania pozostają ograniczone.',
      feature1: '✓ Kodowanie po meczu (Code Table z maksymalnie 4 kodami)',
      feature2: '✓ Nieograniczone telestracje podczas pauzy',
      feature3: '✓ Dynamiczne Telestracje',
      feature4: '✓ Pakiet 3 minut ATD'
    },
    featuresIncluded: 'Dołączone funkcje:',
    freeFeature1: '✓ Kodowanie po meczu (Code Table z maksymalnie 4 kodami)',
    freeFeature2: '✓ Ręczne śledzenie',
    freeFeature3: '✓ Telestracja (do 150 adnotacji)',
    freeFeature4: '✓ Eksport wideo',
    freeFeatures: 'Darmowe Funkcje',
    fullBasic: 'Manualne Śledzenie na Boisku',
    lite: 'Automatyczne Śledzenie Graczy i Boiska',
    liteDescription:
      'Zaawansowane kodowanie i zaawansowane wizualizacje. Wszystko automatycznie, co czyni to 3 razy szybszym niż Full Basic!',
    minutesDescription:
      'Doładuj dowolny plan. Zdobądź pakiety minut dla Automatycznego Śledzenia Danych (ATD).',
    minutesPack: 'Pakiet Minut Cloud',
    otherDescription:
      'Zaawansowane funkcje kodowania z podstawową telestracją i ograniczonymi adnotacjami.',
    prime: {
      addon1: 'Manualne Śledzenie na Boisku',
      addon2: 'Własne Wizualizacje (dostosuj i zapisz swoje własne wizualizacje)',
      feature1: '✓ Pełny dostęp do Play2 ze wszystkimi funkcjami planu "Full Essentials"',
      feature2: '✓ Dostęp do Play3 z:',
      feature2_1: 'Sekwencyjne pauzy',
      feature2_2: 'Zaawansowane telestracje',
      feature2_3: 'Eksport niestandardowych wideo',
      feature3: '✓ Pakiet 10 minut ATD',
      primeDescription:
        'Ten plan zapewnia pełny dostęp do Play2 ze wszystkimi funkcjami planu "Essentials + Code ∞" oraz umożliwia korzystanie z Play3 z zaawansowanymi narzędziami do telestracji.',
      primeMftDescription:
        'Ten plan zapewnia pełny dostęp do Play2 ze wszystkimi funkcjami planu "Full Essentials" oraz umożliwia korzystanie z Play3 z zaawansowanymi narzędziami do telestracji.',
      primePresetsDescription:
        'Ten plan zapewnia pełny dostęp do Play2 ze wszystkimi funkcjami planu "Essentials + Code ∞" oraz umożliwia korzystanie z Play3 z zaawansowanymi narzędziami do telestracji i personalizowanymi wizualizacjami.',
      primePresetsMftDescription:
        'Ten plan zapewnia pełny dostęp do Play2 ze wszystkimi funkcjami planu "Full Essentials" oraz umożliwia korzystanie z Play3 z zaawansowanymi narzędziami do telestracji i personalizowanymi wizualizacjami.'
    },
    pro: {
      description:
        'Ten plan zapewnia pełny dostęp do Play2 ze wszystkimi funkcjami planu "Full Essentials" oraz dostęp do Play3 ze wszystkimi funkcjami planu "Full Prime".',
      feature1: '✓ Automatyczne Śledzenie Graczy',
      feature2: '✓ Automatyczne Śledzenie Pola',
      feature3: '✓ Pełny dostęp do Play2 ze wszystkimi funkcjami Planu "Full Essentials"',
      feature4: '✓ Dostęp do Play3 z:',
      feature4_1: 'Sekwencyjne pauzy',
      feature4_2: 'Zaawansowane Telestracje',
      feature4_3: 'Eksport niestandardowych wideo',
      feature4_4: 'Własne Wizualizacje (dostosuj i zapisz swoje własne wizualizacje)',
      feature5: '✓ Pakiet 100 minut ATD'
    },
    remove: 'Usunąć',
    starter: {
      addon1: 'Nielimitowane telestracje podczas pauz',
      addon2: 'Nieograniczone funkcje Coding (CodePad, Live Coding i Import XML)',
      feature1: '✓ Kodowanie po meczu (Code Table z maksymalnie 4 kodami)',
      feature2: '✓ Telestracja podczas pauz (do 50 adnotacji miesięcznie)',
      feature3: '✓ Eksport wideo',
      starterCodeDescription:
        'Ten plan znosi ograniczenia funkcji kodowania takich jak CodePad, Kodowanie na Żywo i Import XML. Znosi również ograniczenie do 4 kodów na Projekt Wideo, umożliwiając ich nieograniczoną liczbę. Ograniczenia funkcji telestracji pozostają jak w Free Starter.',
      starterCodePauseDescription:
        'Ten plan odblokowuje ograniczenia funkcji kodowania takich jak CodePad, Kodowanie na Żywo i Import XML. Znosi również limit 4 kodów na Projekt Wideo na nieograniczoną liczbę kodów oraz odblokowuje ograniczenie telestracji w pauzach z 50 na miesiąc do nieograniczonej liczby.',
      starterPauseDescription:
        'Ten plan znosi ograniczenie telestracji w pauzach z 50 miesięcznie do nieograniczonych. Ograniczenia funkcji coding pozostają jak w Free Starter.'
    },
    totalPack: 'Suma: %{currency} %{cost}',
    totalPlan: 'Suma: %{currency} %{cost} %{cycle}'
  },
  settings: {
    billing: 'Fakturowanie',
    billingSettings: 'Ustawienia płatności',
    billingSettingsDescription: '(zmień swój plan, zaktualizuj metody płatności...)',
    cancelAt: 'Twój plan zostanie anulowany w dniu',
    cancelPlan: 'Anuluj plan',
    confirmNewPassword: 'Potwierdź nowe hasło',
    deleteAccount: 'Usuń konto',
    details: 'Moje dane',
    emailNotActivated: 'Powiadomienie e-mail jest teraz aktywne',
    emailNotDeactivated: 'Powiadomienie e-mail jest teraz wyłączone',
    emailNotifications: 'powiadomienia e-mailowe',
    language: 'Język',
    languageChanged: 'Język został zaktualizowany',
    newPassword: 'Nowe hasło',
    organizationLabel: 'Prefiks organizacji',
    organizationTooltip:
      'Ten prefiks jest potrzebny do importowania niestandardowych wzorów do projektu wideo. Skontaktuj się z nami, aby uzyskać więcej informacji, lub sprawdź dokumentację na codeball.metrica-sports.com',
    passwordsDontMatch: 'Hasła nie są identyczne',
    pauseAt: 'Twój plan zostaje wstrzymany dnia',
    planCanceled:
      'Twój plan został anulowany. Sprawdź pocztę e-mail, aby uzyskać więcej informacji',
    plans: 'Plany',
    quantity: 'Ilość:',
    renewAt: 'Twój plan odnawia się w dniu',
    title: 'Ustawienia',
    upgradePlan: 'Aktualizuj Plan',
    userKey: 'Klucz użytkownika:',
    userKeyUsages: '%{current}/%{max} zużycia',
    userSettings: 'Ustawienia użytkownika'
  },
  shoppingCart: {
    subtitle: 'Wybierz swój plan Play i dodatki, aby podnieść swoje wyniki.',
    title: 'Skonfiguruj swój Play.'
  },
  subscription: {
    cancelSubscription: 'Anuluj subskrypcję',
    errorUpdating:
      'Wystąpił błąd podczas aktualizacji twojej subskrypcji. Proszę, wyślij e-mail na adres support@metrica-sports.com, a z przyjemnością Ci pomożemy.',
    free: 'Darmowy',
    isObsolete:
      'Twoja subskrypcja nie może zostać zmodyfikowana. Zastąp przestarzałe produkty nowymi, aby umożliwić zmiany.',
    licenseKeys: 'Klucze licencyjne',
    pendingRefundRequest:
      'Nie można dokonywać dalszych zmian w tej subskrypcji, dopóki istnieje oczekujące żądanie zwrotu pieniędzy.',
    undoCancel: 'Cofnij anulowanie subskrypcji.',
    undoPause: 'Cofnij wstrzymanie subskrypcji.',
    updatePaymentMethod: 'Aktualizuj metodę płatności',
    updatedSuccessfully: 'Subskrypcja została pomyślnie zaktualizowana.',
    yourPlans: 'Twoje Plany',
    yourSubscription: 'Twoja Subskrypcja'
  },
  successfulPayment: {
    activateLicence:
      'Aktywuj swoją licencję lub kredyt Cloud, wprowadzając dostarczony klucz licencyjny.',
    allTheBest: 'Wszystkiego najlepszego,',
    applyKey: 'Zastosuj klucz do tego konta.',
    applyKeyDescription:
      'Czy chciałbyś zastosować klucz licencyjny do konta, na którym jesteś automatycznie zalogowany?',
    downloadPlay: '%{pre}Pobierz PLAY%{post} przez naszą stronę internetową.',
    faq: 'Aby pomóc Ci rozpocząć składanie zamówienia, sprawdź %{pre}Najczęściej Zadawane Pytania%{post} na naszej stronie internetowej. Oto krótkie podsumowanie informacji, które mogą być dla Ciebie najbardziej istotne na tym etapie:',
    goToSummary:
      'Jeśli nie, zapoznaj się proszę z poniższym podsumowaniem, aby poznać instrukcje aktywacji.',
    happyPlay:
      'Mamy nadzieję, że wszystko jest jasne, ale prosimy nie wahaj się z nami skontaktować, jeśli masz jakiekolwiek dodatkowe pytania lub wątpliwości. Szczęśliwego PLAY!',
    helpDesk:
      'Sprawdź nasz %{pre}HelpDesk%{post}, aby uzyskać ogólne informacje na temat korzystania z PLAY.',
    keyApplied: 'Klucz zastosowany',
    licenseKey: 'Twój klucz licencyjny to: %{pre}%{licenseKey}%{post}.',
    metricaSportsTeam: 'Zespół Metrica Sports',
    settingsCloud: 'Odwiedź nasze %{pre}Cloud%{post} i przejdź do menu ustawień, aby:',
    technicalSupport:
      'Aby uzyskać wsparcie techniczne, wypełnij formularz kontaktowy dostępny w PLAY/Cloud, lub wyślij e-mail na adres %{pre}support@metrica-sports.com%{post}.',
    termsAndConditions:
      'Prosimy zauważyć, że nasze subskrypcje są automatycznie odnawiane do czasu wyraźnego anulowania za pośrednictwem ustawień w %{pre}Cloud%{post}, zgodnie z naszymi Warunkami Świadczenia Usług.',
    thanks:
      'Dziękujemy za Twoje zamówienie! Przetworzyliśmy je pomyślnie i załączyliśmy poniżej Twoje klucze licencyjne.',
    title: 'Potwierdzenie zamówienia',
    updatePayment: 'Zaktualizuj metodę płatności.',
    updateSubscription: 'Anuluj lub zmodyfikuj swoją subskrypcję.'
  },
  surfaces: {
    automaticVp: 'Powierzchnie niedostępne w projektach wideo ze starszą wersją ATD',
    delete: 'Usuń plik powierzchni pola gry',
    needsAtd:
      'Najpierw uruchom ATD, aby uruchomić procesy związane z diagnozą powierzchni pola gry',
    needsElite:
      'Niestandardowe powierzchnie są dostępne w abonamencie Elite. Skontaktuj się z nami, aby uzyskać więcej informacji',
    needsFootballSideView:
      'Diagnoza powierzchni pola gry nie jest dostępna w projektach wideo zawierających plik wideo inny niż szerokokątne, lub telewizyjne nagranie meczu piłki nożnej',
    pitchControl:
      'Uruchomienie tego procesu umożliwi Ci dodanie wizualizacji Pitch Control w programie Play.',
    pitchControlProcessed: 'Tryb "Pitch Control" dla tej powierzchni został przetworzony',
    pitchControlProcessing: 'Pitch Control - przetwarzanie',
    processed: 'Przesłano plik związany z powierzchnią pola gry',
    processing: 'Przetwarzanie pliku związanego z powierzchnią pola gry',
    title: 'Powierzchnie pola gry',
    titlePitchControl: 'Pitch Control',
    upload: 'Prześlij swoją własną powierzchnię pola gry',
    uploading: 'Przesyłanie powierzchni pola gry...'
  },
  trackingFifaFormat: {
    download: 'Pobierz plik w formacie FIFA EPTS',
    needsElite:
      'Ta funkcja jest dostępna tylko po zaimportowaniu odpowiednich plików do modułu integracji danych, projektów wideo zawierających Automatyczny Tracking Danych, lub projektów wideo w planie Elite.',
    processed: 'Przetworzono format FIFA EPTS',
    start:
      'Rozpocznij proces generowania pliku w formacie FIFA EPTS. Będziesz wtedy mógł pobrać plik',
    title: 'Format FIFA EPTS',
    titleDownload: 'Pobierz format FIFA EPTS'
  },
  upgradeModal: {
    linksDescription: 'Pobierz pakiet minutowy Cloud Engine, który najbardziej Ci odpowiada:',
    minutePackLinks: {
      minutes_10: 'Zdobądź 10 minut za 10 €',
      minutes_100: 'Zdobądź 100 minut za 90 €',
      minutes_1000: 'Zdobądź 1000 minut za 750€',
      minutes_10000: 'Zdobądź 10 000 minut za 5000 €'
    },
    noMinutes: {
      description:
        'Nie masz wystarczająco dużo dostępnych minut, aby utworzyć ten projekt wideo. Czy wiesz, że możesz zdobyć pakiety minutowe, niezależnie od twojego planu?',
      secondDescription: '',
      title: 'Uzyskaj więcej minut na utworzenie projektu wideo!'
    },
    notEnoughMinutes: {
      description:
        'Z przykrością informujemy, że nie masz wystarczająco dużo minut na przetworzenie ATD w swoich projektach wideo.',
      secondDescription:
        'Jeśli chcesz przetwarzać ATD, musisz dodać więcej minut do swojego konta. Minuty, które otrzymasz, nie wygasają!',
      title: 'Brak wystarczającej liczby dostępnych minut'
    }
  },
  video: {
    cancelUpload: 'Anuluj przesyłanie',
    delete: 'Usuń film',
    download: 'Pobierz wideo',
    title: 'Plik wideo',
    upload: 'Prześlij wideo',
    uploaded: 'Przesłano film wideo',
    uploading: 'Przesyłanie wideo'
  },
  videoProject: {
    delete: 'Usuń projekt wideo',
    edit: 'Edytuj projekt wideo',
    processWaitingJobs: 'Przetwarzaj zadania oczekujące',
    title: 'Projekt wideo'
  }
}
