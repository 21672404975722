/** @jsx jsx */
// eslint-disable-next-line
import React, { memo } from 'react'
import { css, jsx } from '@emotion/core'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import { theme, headerHeight } from '../../../constants'

const styles = {}
styles.container = css`
  height: ${headerHeight}px;
  width: 100%;
  background: linear-gradient(#e9f75f, #b4ed4b);
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`
styles.searchIcon = { color: theme.palette.common.charolBlack, marginRight: 10 }
styles.inputSearch = css`
  background: transparent;
  border: none;
  outline: 0;
  color: ${theme.palette.common.charolBlack};
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  opacity: 1;
  ::placeholder {
    color: ${theme.palette.common.charolBlack};
    opacity: 1;
  }
  ::-moz-placeholder {
    color: ${theme.palette.common.charolBlack};
    opacity: 1;
  }
`
styles.closeIcon = {
  color: theme.palette.common.charolBlack,
  marginLeft: 10,
  fontSize: 19,
  cursor: 'pointer',
  marginRight: 2
}

function SearchBar(props) {
  return (
    <div css={styles.container}>
      <Icon style={styles.searchIcon}>search</Icon>
      <input
        type="text"
        value={props.searchValue}
        onChange={props.changeSearchValue}
        css={styles.inputSearch}
        placeholder={window.I18n.t('admin.search')}
      />
      {!!props.searchValue && (
        <Icon style={styles.closeIcon} onClick={props.clearSearchValue}>
          highlight_off
        </Icon>
      )}
    </div>
  )
}

SearchBar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  changeSearchValue: PropTypes.func.isRequired,
  clearSearchValue: PropTypes.func.isRequired
}

export default memo(SearchBar)
