import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import tokenService from '../../../services/token'

function PrivateRoute({ component: Component, componentProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return tokenService.get() ? (
          <Component {...componentProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  componentProps: PropTypes.object
}

PrivateRoute.defaultProps = {
  componentProps: {}
}

export default PrivateRoute
