import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { apiTimeout } from '../../constants'
import api from '../../services/api'
import getErrorMessage from '../../utils/getErrorMessage'

const initialState = {
  resource: undefined,
  selectedFile: undefined,
  dataFile: undefined,
  fileName: undefined
}

class FileHiddenUploadForm extends PureComponent {
  state = { ...initialState }

  openFileSelector = () => {
    this.input.click()
  }

  fileChangedHandler = event => {
    if (this.props.onStart) {
      this.props.onStart(this.props.suffix)
    }

    const selectedFile = event.target.files[0]
    const fileName = selectedFile.name
    this.setState({ selectedFile, fileName }, async () => {
      let resource = this.props.resource
      if (this.props.beforeUpload) {
        try {
          resource = await this.props.beforeUpload(selectedFile, fileName, resource)
        } catch (err) {
          this.onError(err)
          return
        }
      }

      const dataFile = new FormData()
      dataFile.append(resource, selectedFile)

      this.setState({ resource, dataFile }, () => {
        this.startUpload()
      })
    })
  }

  startUpload = async () => {
    try {
      const url = `/game-datasets/${this.props.vpId}/${this.state.resource}`
      const params = this.state.dataFile
      const response = await api.put(url, params, {
        timeout: apiTimeout,
        onUploadProgress: e => {
          const progress = ((e.loaded / e.total) * 100.0).toFixed(0)
          console.log(`${progress}%`)
          if (this.props.onProgress) {
            this.props.onProgress(progress, this.props.suffix)
          }
        }
      })

      if (this.props.onFinish) {
        this.props.onFinish(response.data, this.props.vpId, this.state.fileName, this.props.suffix)
      }
    } catch (error) {
      this.onError(getErrorMessage(error))
    }
  }

  onError = error => {
    if (error) {
      console.error(error)
    }

    if (this.props.onError) {
      this.props.onError(error, this.state.fileName, this.props.suffix)
    }
  }

  render() {
    return (
      <input
        ref={element => {
          this.input = element
        }}
        style={{ display: 'none' }}
        type="file"
        id="single-file-upload"
        onChange={this.fileChangedHandler}
        value=""
        multiple={false}
        accept={this.props.accept}
      />
    )
  }
}

FileHiddenUploadForm.defaultProps = {
  onProgress: () => ({}),
  onError: () => ({}),
  onFinish: () => ({}),
  onStart: () => ({}),
  beforeUpload: undefined,
  accept: '*'
}

FileHiddenUploadForm.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
  beforeUpload: PropTypes.func,
  resource: PropTypes.string.isRequired,
  vpId: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  accept: PropTypes.string
}

export default FileHiddenUploadForm
