import React, { Fragment, Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Polyglot from 'node-polyglot'
import moment from 'moment/dist/moment'
// Import moment locales explicitly because they are excluded by default.
import 'moment/dist/locale/es'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/ar'
import 'moment/dist/locale/zh-cn'
import 'moment/dist/locale/ja'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/tr'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/it'
import 'moment/dist/locale/de'
import 'moment/dist/locale/ru'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/he'
import 'moment/dist/locale/ca'
import 'moment/dist/locale/id'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import styled from '@emotion/styled'
// eslint-disable-next-line
import { createBrowserHistory } from 'history'
import PrivateRoute from './components/routes/PrivateRoute'
import UnauthorizedRoute from './components/routes/UnauthorizedRoute'
import UncaughtApplicationError from './components/UncaughtApplicationError'
import Login from './login'
import Forgot from './forgot'
import Home from './home'
import { theme } from '../constants'
import zendeskWidget from '../utils/zendeskWidget'
import ConfirmAlert from './notifications/ConfirmAlert'
import packageJson from '../../package.json'
import * as Translations from '../translations'

export const history = createBrowserHistory()
const customTheme = createMuiTheme(theme)
const routes = [
  {
    id: 0,
    exact: true,
    path: '/forgot-password',
    component: Forgot,
    render: Route
  },
  {
    id: 1,
    exact: true,
    path: '/login',
    component: Login,
    render: UnauthorizedRoute
  },
  {
    id: 2,
    exact: false,
    path: '/',
    component: Home,
    render: PrivateRoute
  }
]

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uncaughtError: false
    }
  }

  componentDidMount() {
    console.info(`(Cloud) ${packageJson.version}`)
    this.updateLanguage(this.props.language)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.updateLanguage(nextProps.language)
    }
  }

  componentDidCatch(error, info) {
    console.log('Uncaught Error: ', error)
    console.log('Error Info: ', info)
    this.setState({ uncaughtError: true })
  }

  updateLanguage = locale => {
    // Use the translations of the selected language.
    const translation = Object.values(Translations).find(v => v.locale === locale)
    window.I18n = new Polyglot(translation)
    moment.locale(translation.locale)
    zendeskWidget.updateLanguage(translation.locale)
  }

  render() {
    return this.state.uncaughtError ? (
      <UncaughtApplicationError />
    ) : (
      <Router history={history}>
        <Fragment>
          <ToastContainerStyles>
            <ToastContainer
              position={toast.POSITION.BOTTOM_RIGHT}
              pauseOnHover={false}
              closeButton={false}
              autoClose={false}
              closeOnClick
              hideProgressBar
            />
            <DismissAll />
          </ToastContainerStyles>
          <MuiThemeProvider theme={customTheme}>
            <ConfirmAlert />
            <Switch>
              {routes.map(({ exact, path, component, render: Render, id }) => (
                <Render exact={exact} path={path} component={component} key={id} />
              ))}
            </Switch>
          </MuiThemeProvider>
        </Fragment>
      </Router>
    )
  }
}

const ToastContainerStyles = styled.div`
  .dismiss-all {
    position: fixed;
    bottom: 10px;
    right: 21px;
    background: ${theme.palette.common.white};
    z-index: 99999;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }

  .Toastify__toast-container {
    width: unset;
    min-width: 320px;
    max-width: 500px;
    max-height: 100vh;
    padding-top: 35px;
    scrollbar-width: none;
    overflow-y: overlay;
    overflow-x: hidden;
    margin-bottom: 15px;

    &::-webkit-scrollbar: {
      width: 0;
    }

    .Toastify__toast {
      font-size: 14px;
      border-radius: 5px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);

      &.Toastify__toast--success {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.charolBlack};
      }

      &.Toastify__toast--error {
        background: ${theme.palette.error.main};
      }

      &.Toastify__toast--info {
        background: ${theme.palette.common.blue};
      }
    }
  }
`

function DismissAll() {
  const [numberOfToasts, setNumberOfToasts] = useState(0)
  useEffect(() => {
    toast.onChange(numberOfToastDisplayed => {
      setNumberOfToasts(numberOfToastDisplayed)
    })
  }, [])

  return numberOfToasts > 1 ? (
    <div
      className="dismiss-all"
      role="button"
      onClick={() => {
        toast.dismiss()
        setNumberOfToasts(0)
      }}
      tabIndex={-1}
    >
      {window.I18n.t('common.clearAll')}
    </div>
  ) : null
}

function mapStateToProps(state) {
  return {
    language: state.auth.language
  }
}

App.propTypes = {
  language: PropTypes.string.isRequired,
  subscriptionInUse: PropTypes.object
}

App.defaultProps = {
  subscriptionInUse: undefined
}

export default connect(mapStateToProps)(App)
