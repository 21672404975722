/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css, jsx } from '@emotion/core'
import { CircularProgress, FormControl, MenuItem, TextField, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { headerHeight, theme } from '../../constants'
import { ProductNames2024 } from '../../constants/payments'
import orderService from '../../services/order'
import Checkout from '../components/Checkout'
import OneTimeProducts from '../components/OneTimeProducts'
import RecurrentProduct from '../components/RecurrentProduct'
import { history } from '../App'

const styles = {}
styles.container = css`
  width: 1112px;
  height: calc(100vh - ${headerHeight}px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
`
styles.header = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  margin-left: 32px;
  padding: 16px;
`
styles.title = css`
  font-size: 24px;
  font-weight: bold;
`
styles.description = css`
  margin-top: 8px;
`
styles.content = css`
  display: flex;
`
styles.newProductButtonContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`
styles.productsContainer = css`
  width: 700px;
  display: flex;
  flex-direction: column;
  margin-right: 32px;
`
styles.checkoutContainer = css`
  width: 400px;
  height: fit-content;
  display: flex;
`
styles.checkoutPlanContainer = css`
  display: flex;
  flex-direction: column;
`
styles.checkoutPlanTitle = css`
  font-size: 16px;
  font-weight: bold;
`
styles.checkoutPaymentContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 16px;
`
styles.checkoutTotalContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`
styles.checkoutTotalText = css`
  font-size: 16px;
  font-weight: bold;
`
styles.vatText = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`
styles.checkoutButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
`
styles.loadingContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
styles.newProductButton = {
  color: theme.palette.common.charolBlack,
  textTransform: 'none'
}
styles.checkoutButton = {
  height: '32px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.blue,
  textTransform: 'none',
  borderRadius: '2px',
  padding: '0px 6px'
}

const CssProductSelect = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.mainGreen,
      padding: '0px 32px 0px 12px',
      height: '32px',
      borderRadius: '4px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.charolBlack
      }
    }
  }
})(TextField)

function ShoppingCart(props) {
  const [products, setProducts] = React.useState([])
  const [dropdownProducts, setDropdownProducts] = React.useState([])
  const [isMonthly, setIsMonthly] = React.useState(true)
  const [cycle, setCycle] = React.useState('/month')
  const [currencyCode, setCurrencyCode] = React.useState('')
  const [taxRate, setTaxRate] = React.useState(0)
  const [plansCheckout, setPlansCheckout] = React.useState([])
  const [packsCheckout, setPacksCheckout] = React.useState([])
  const [chargeToSubscription, setChargeToSubscription] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    setChargeToSubscription(
      props.subscriptionInUse &&
        props.subscriptionInUse.order &&
        props.subscriptionInUse.order.merchant &&
        plansCheckout.length === 0 &&
        packsCheckout.length > 0
    )
  }, [props.subscriptionInUse, plansCheckout, packsCheckout])

  React.useEffect(() => {
    if (!props.paddle || products.length === 0) {
      return
    }

    const previewRequest = {
      items: [{ priceId: products[0].priceId, quantity: 1 }],
      customerIpAddress: props.userIp
    }

    if (props.customer && props.customer.id) {
      previewRequest.customerId = props.customer.id
    }

    props.paddle.PricePreview(previewRequest).then(preview => {
      setCurrencyCode(preview.data.currencyCode)
      setTaxRate(Number(preview.data.details.lineItems[0].taxRate) * 100.0)
    })
  }, [props.paddle, props.customer, props.user, props.userIp, products])

  React.useEffect(() => {
    // Don't parse URL if products are not loaded yet.
    if (props.monthlyProducts.length === 0 || props.yearlyProducts.length === 0) {
      return
    }

    const availableProducts = [
      ...props.monthlyProducts,
      ...props.yearlyProducts,
      ...props.oneTimeProducts
    ].filter(v => !v.isDeprecated)
    setProducts(availableProducts)

    // Get prices/products from URL, if any.
    const url = window.location.search
    const priceStrings = url.split('?')[1]
    const priceCodes = priceStrings
      ? priceStrings.split('&')
      : [
          (
            props.monthlyProducts.find(v => v.productCode === 'pro_2024') ||
            props.monthlyProducts[props.monthlyProducts.length - 1]
          ).priceCode
        ]

    // Reset URL.
    history.push('/shopping-cart')

    // Apply prices/products and cycle depending on URL.
    const willBeMonthly = !priceCodes.some(v => v.includes('yearly'))
    const newDropdownProducts = willBeMonthly ? props.monthlyProducts : props.yearlyProducts
    const { newPlansCheckout, newPacksCheckout } = priceCodes.reduce(
      (acc, v) => {
        let product = newDropdownProducts.find(p => p.priceCode === v)
        if (product && product.priceId) {
          acc.newPlansCheckout.push({ ...product, quantity: 1 })
        } else {
          product = props.oneTimeProducts.find(p => p.priceCode === v)
          if (product) {
            acc.newPacksCheckout.push({ ...product, quantity: 1 })
          }
        }

        return acc
      },
      { newPlansCheckout: [], newPacksCheckout: [] }
    )
    setPlansCheckout(newPlansCheckout)
    setPacksCheckout(newPacksCheckout)
    setIsMonthly(willBeMonthly)

    updateDropdownProducts(newDropdownProducts)
  }, [props.monthlyProducts, props.yearlyProducts, props.oneTimeProducts])

  React.useEffect(() => {
    setCycle(isMonthly ? '/month' : '/year')

    // Replace existing items with the products that are available in the new cycle.
    const planProducts = isMonthly ? props.monthlyProducts : props.yearlyProducts
    const newPlansCheckout = plansCheckout.reduce((acc, v) => {
      const product = planProducts.find(p => p.productCode === v.productCode)
      if (product && product.priceId) {
        acc.push({ ...product, quantity: v.quantity })
      }

      return acc
    }, [])
    setPlansCheckout(newPlansCheckout)

    updateDropdownProducts(isMonthly ? props.monthlyProducts : props.yearlyProducts)
  }, [isMonthly])

  function updateDropdownProducts(newProducts) {
    const newDropdownProducts = newProducts
      .filter(v => !v.isDeprecated)
      .map(v => {
        const product = ProductNames2024.find(w => w.value === v.productCode)

        return {
          value: v.priceId,
          label: window.I18n.t('plans.plan', {
            planName: product.translationKey
              ? window.I18n.t(`plans.${product.translationKey}`)
              : product.translation
          })
        }
      })
    newDropdownProducts.unshift({
      value: ProductNames2024[0].value,
      label: window.I18n.t(`plans.${ProductNames2024[0].translationKey}`)
    })
    setDropdownProducts(newDropdownProducts)
  }

  function handleSelectProduct(e) {
    const productId = e.target.value
    const product = products.find(v => v.priceId === productId)
    if (!product) {
      return
    }

    const itemIndex = plansCheckout.findIndex(v => v.priceCode === product.priceCode)
    if (itemIndex !== -1) {
      const newPlansCheckout = [...plansCheckout]
      newPlansCheckout[itemIndex].quantity = Math.min(99, newPlansCheckout[itemIndex].quantity + 1)
      setPlansCheckout(newPlansCheckout)
    } else {
      setPlansCheckout([{ ...product, quantity: 1 }, ...plansCheckout])
    }
  }

  function handleUpdatePlans(plans) {
    const newPlansCheckout = [...plansCheckout]

    let lastIndex = plans.length - 1
    plans.forEach(plan => {
      const index = newPlansCheckout.findIndex(v => v.priceCode === plan.priceCode)
      if (index === -1) {
        newPlansCheckout.splice(lastIndex, 0, plan)
      } else {
        newPlansCheckout[index] = plan
        lastIndex = index
      }
    })

    // Remove empty products.
    setPlansCheckout(newPlansCheckout.filter(v => v.quantity > 0))
  }

  function handleUpdatePacks(packs) {
    const newPacksCheckout = [...packsCheckout]

    packs.forEach(pack => {
      const index = newPacksCheckout.findIndex(v => v.priceCode === pack.priceCode)
      if (index === -1) {
        newPacksCheckout.push(pack)
      } else {
        newPacksCheckout[index] = pack
      }
    })

    // Remove empty products.
    setPacksCheckout(newPacksCheckout.filter(v => v.quantity > 0))
  }

  async function handleCheckout() {
    if (!props.paddle) {
      return
    }

    // If there's a subscription in use and user is only purchasing cloud-minutes,
    // charge them to the subscription to use existing card details.
    if (chargeToSubscription) {
      try {
        setIsLoading(true)

        const items = packsCheckout.map(v => ({ id: v.priceId, quantity: v.quantity }))
        await orderService.chargeSubscription(props.subscriptionInUse.order.code, items)

        toast.success('Successful payment')
        history.push(`/successful-payment?order=${props.subscriptionInUse.order.code}`)
      } catch (e) {
        toast.error('Payment failed')
        setIsLoading(false)
      }
    } else {
      const checkoutSettings = {
        settings: {
          allowLogout: false,
          locale: props.user.language,
          theme: 'dark'
        },
        customer: {},
        items: [...plansCheckout, ...packsCheckout].map(v => ({
          priceId: v.priceId,
          quantity: v.quantity
        }))
      }

      if (props.customer && props.customer.id) {
        checkoutSettings.customer.id = props.customer.id
      } else {
        checkoutSettings.customer.email = props.user.email
      }

      props.paddle.Checkout.open(checkoutSettings)
    }
  }

  function renderMoreProductsButton() {
    return (
      <div css={styles.newProductButtonContainer}>
        <FormControl>
          <CssProductSelect
            select
            variant="outlined"
            name="product"
            value="add"
            onChange={handleSelectProduct}
          >
            {dropdownProducts.map(v => {
              if (!v.value) {
                return null
              }

              return (
                <MenuItem key={`menu-item-${v.value}`} value={v.value}>
                  <span css={styles.productDropdownLabel}>{v.label}</span>
                </MenuItem>
              )
            })}
          </CssProductSelect>
        </FormControl>
      </div>
    )
  }

  if (!props.userLoaded || products.length === 0) {
    return (
      <div css={styles.loadingContainer}>
        <CircularProgress color="primary" size={35} thickness={4} />
      </div>
    )
  }

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <span css={styles.title}>{window.I18n.t('shoppingCart.title')}</span>
        <span css={styles.description}>{window.I18n.t('shoppingCart.subtitle')}</span>
      </div>

      <div css={styles.content}>
        <div css={styles.productsContainer}>{renderMoreProductsButton()}</div>
        <div css={styles.checkoutContainer} />
      </div>

      <div css={styles.content}>
        <div css={styles.productsContainer}>
          {plansCheckout.map(v => (
            <RecurrentProduct
              key={`product-${v.id}`}
              product={v}
              currencyCode={currencyCode}
              isMonthly={isMonthly}
              cycle={cycle}
              monthlyProducts={props.monthlyProducts}
              yearlyProducts={props.yearlyProducts}
              onUpdate={handleUpdatePlans}
            />
          ))}

          <OneTimeProducts
            products={packsCheckout}
            currencyCode={currencyCode}
            oneTimeProducts={props.oneTimeProducts}
            onUpdate={handleUpdatePacks}
          />

          {renderMoreProductsButton()}
        </div>

        <div css={styles.checkoutContainer}>
          <Checkout
            paddle={props.paddle}
            customer={props.customer}
            address={props.address}
            userIp={props.userIp}
            user={props.user}
            plansCheckout={plansCheckout}
            packsCheckout={packsCheckout}
            products={products}
            currencyCode={currencyCode}
            taxRate={taxRate}
            isMonthly={isMonthly}
            isLoading={isLoading}
            buttonDisabled={
              !props.paddle || (chargeToSubscription && (!props.customer || !props.address))
            }
            buttonText={window.I18n.t(
              chargeToSubscription ? 'checkout.chargeSubscription' : 'checkout.proceed'
            )}
            hintText={chargeToSubscription ? '' : window.I18n.t('checkout.discountCodes')}
            onBillingCycle={setIsMonthly}
            onCheckout={handleCheckout}
          />
        </div>
      </div>
    </div>
  )
}

ShoppingCart.defaultProps = {
  paddle: undefined,
  customer: undefined,
  address: undefined,
  subscriptionInUse: undefined
}

ShoppingCart.propTypes = {
  paddle: PropTypes.object,
  userLoaded: PropTypes.bool.isRequired,
  userIp: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  subscriptionInUse: PropTypes.object,
  customer: PropTypes.object,
  address: PropTypes.object,
  monthlyProducts: PropTypes.array.isRequired,
  yearlyProducts: PropTypes.array.isRequired,
  oneTimeProducts: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    userLoaded: state.auth.userLoaded,
    userIp: state.auth.userIp,
    user: state.auth.user,
    subscriptionInUse: state.auth.subscriptionInUse,
    customer: state.auth.customer,
    address: state.auth.address,
    monthlyProducts: state.admin.monthlyProducts,
    yearlyProducts: state.admin.yearlyProducts,
    oneTimeProducts: state.admin.oneTimeProducts
  }
}

export default connect(mapStateToProps)(ShoppingCart)
