import api from './api'

const gamesService = {}

// Get game-datasets.
gamesService.getList = (search, payload) =>
  api.get(
    search ? `game-datasets/search/${search}/organization` : '/game-datasets/organization',
    payload
  )

// Create cloud game-dataset.
gamesService.create = game => api.post('/game-datasets/cloud', game)

// Update cloud game-dataset.
gamesService.update = (id, game) => api.put(`game-datasets/cloud/${id}`, game)

// Mark as corrected a cloud game-dataset.
gamesService.markAsCorrected = id => api.put(`game-datasets/cloud/${id}/mark-as-corrected`)

export default gamesService
