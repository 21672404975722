/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from 'react'
import { css, jsx } from '@emotion/core'
import * as Paddle from '@paddle/paddle-js'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import sessionService from '../../services/session'
import tokenService from '../../services/token'
import { history } from '../App'
import ActionTypes from '../../constants/actionTypes'
import * as Constants from '../../constants'
import LoginForm from './components/LoginForm'
import getErrorMessage from '../../utils/getErrorMessage'
import zendeskWidget from '../../utils/zendeskWidget'
import amplitude from '../../analytics/amplitude'
import ZendeskWidgetButton from '../components/ZendeskWidgetButton'
import { showLicenceReminder } from '../admin/actions'
import getIp from '../../utils/getIp'

const styles = {}
styles.container = css`
  height: 100%;
  width: 100%;
  background: linear-gradient(#fff, #cccccc);
  display: flex;
  flex-direction: center;
  align-items: center;
`
styles.loginBox = css`
  width: 460px;
  height: 530px;
  background: linear-gradient(#e9f75f, #b4ed4b);
  border-radius: 5px;
  margin: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative;
`
styles.title = css`
  text-align: center;
  color: ${Constants.theme.palette.common.charolBlack};
  text-transform: uppercase;
  font-weight: lighter;
  margin-top: 40px;
`

const theme2 = createMuiTheme({
  palette: {
    primary: {
      light: Constants.theme.palette.common.white,
      main: Constants.theme.palette.common.white,
      dark: Constants.theme.palette.common.white,
      contrastText: Constants.theme.palette.common.white
    },
    secondary: {
      light: Constants.theme.palette.primary.light,
      main: Constants.theme.palette.common.white,
      dark: Constants.theme.palette.primary.dark,
      contrastText: Constants.theme.palette.common.white
    },
    common: {
      black: Constants.theme.palette.common.charolBlack,
      grey: Constants.theme.palette.common.grey
    },
    text: {
      primary: Constants.theme.palette.common.white,
      secondary: Constants.theme.palette.common.white,
      hint: Constants.theme.palette.common.white
    },
    error: { main: Constants.theme.palette.error.dark }
  },
  typography: {
    useNextVariants: true
  }
})

class Login extends Component {
  componentDidMount() {
    if (window.zESettings) {
      zendeskWidget.reset()
    } else {
      zendeskWidget.setup()
    }

    // Initialize Paddle in Login page for ProfitWell Retain, to not need to be logged in.
    const isProduction = process.env.REACT_APP_ENV === 'production'
    const pwAuth = process.env.REACT_APP_PROFITWELL_TOKEN || null
    Paddle.initializePaddle({
      environment: isProduction ? 'production' : 'sandbox',
      token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
      pwAuth
    })
  }

  onSubmit = async (values, { setSubmitting }) => {
    zendeskWidget.hide()
    try {
      const response = await sessionService.create(values.email, values.password)

      const data = response.data
      try {
        data.userIp = await getIp()
      } catch (error) {
        console.error(error)
      }

      const token = data.token
      const user = data.user
      const subscriptionInUse = data.subscriptionInUse
      tokenService.set(token, user._id)
      this.props.dispatch({ type: ActionTypes.MAKE_AUTH, payload: data })

      amplitude.setUser(data.user)
      amplitude.logEvent(Constants.AnalyticsEvents.LOGIN_METRICA_CLOUD)
      showLicenceReminder(user, subscriptionInUse)

      // Redirect to the path user was trying to access before auth.
      const location = this.props.location
      const from = location && location.state && location.state.from
      const redirectUrl = (from && `${from.pathname}${from.search}`) || '/'
      history.replace(redirectUrl)

      zendeskWidget.hide()
    } catch (error) {
      console.error(error)
      const errorMsg = getErrorMessage(error)

      // We are handling this error on the api service file
      const apiMessages = Constants.API_MESSAGES
      if (errorMsg === apiMessages.trialLicenseInvalid || errorMsg === apiMessages.licenseInvalid) {
        return
      }

      const wrongPassMsg = 'Wrong username and/or password'
      if (errorMsg !== wrongPassMsg) {
        zendeskWidget.openInForm()
      }

      toast.error(errorMsg)
    } finally {
      setSubmitting(false)
    }
  }

  goToReset = () => {
    history.push('/forgot-password')
  }

  goToSignUp = () => {
    const isProduction = process.env.REACT_APP_ENV === 'production'
    const baseUrl = isProduction
      ? 'https://getplay.metrica-sports.com'
      : 'http://getplay-dev.metrica-sports.com'

    const location = this.props.location
    const from = location && location.state && location.state.from
    const returnUrl = (from && `${window.location.origin}${from.pathname}${from.search}`) || ''
    const signUpUrl = `${baseUrl}${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`

    window.open(signUpUrl, '_self')
  }

  render() {
    return (
      <div css={styles.container}>
        <ZendeskWidgetButton />
        <div css={styles.loginBox}>
          <h1 css={styles.title}>Cloud Engine</h1>
          <MuiThemeProvider theme={theme2}>
            <LoginForm
              onSubmit={this.onSubmit}
              goToReset={this.goToReset}
              goToSignUp={this.goToSignUp}
            />
          </MuiThemeProvider>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default connect()(Login)
