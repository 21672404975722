import productService from '../services/product'

async function fetchProducts() {
  const productsData = { monthly: [], yearly: [], oneTime: [] }

  try {
    const response = await productService.getProducts()
    response.data.reduce((acc, v) => {
      if (v.cycle === 'monthly') {
        acc.monthly.push(v)
      } else if (v.cycle === 'yearly') {
        acc.yearly.push(v)
      } else if (v.cycle === 'one_time') {
        acc.oneTime.push(v)
      }

      return acc
    }, productsData)

    // Make sure all product codes are available in both cycles.
    productsData.monthly.forEach((v, i) => {
      const product = productsData.yearly.find(p => p.productCode === v.productCode)
      if (!product) {
        productsData.yearly.splice(i, 0, {
          ...v,
          priceId: '',
          cycle: 'yearly',
          basePrice: {
            // Take into account discount.
            amount: String(Number(v.basePrice.amount) * 10),
            currency: v.basePrice.currency
          },
          localizedPrices: v.localizedPrices.map(lp => ({
            ...lp,
            unit_price: {
              ...lp.unit_price,
              amount: String(Number(lp.unit_price.amount) * 10)
            }
          }))
        })
      }
    })
  } catch (error) {
    console.log('error', error)
  }

  return productsData
}

export default fetchProducts
