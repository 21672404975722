import * as UserLocale from 'get-user-locale'
import ActionTypes from '../constants/actionTypes'
import getTrackingMinutes from '../utils/getTrackingMinutes'
import * as Translations from '../translations'
import isAdminUser from '../utils/isAdminUser'

const initialState = {
  token: '',
  userLoading: false,
  userLoaded: false,
  userIp: '',
  user: {},
  customerLoading: false,
  customerLoaded: false,
  customer: null,
  address: null,
  business: null,
  transactions: [],
  isAuthorized: false,
  isMetricaSportsUser: false,
  subscriptions: [],
  subscriptionInUse: null,
  planName: '',
  language: _getLanguage(),
  trackingMinutes: 0
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MAKE_AUTH: {
      return {
        ...state,
        token: action.payload.token,
        userLoaded: true,
        userIp: action.payload.userIp,
        user: action.payload.user,
        isAuthorized: true,
        subscriptions: action.payload.subscriptions,
        subscriptionInUse: action.payload.subscriptionInUse,
        planName: action.payload.planName,
        language: action.payload.user.language,
        trackingMinutes: getTrackingMinutes(action.payload.user),
        isMetricaSportsUser: isAdminUser(action.payload.user)
      }
    }

    case ActionTypes.LOGOUT: {
      return {
        ...state,
        ...initialState
      }
    }

    case ActionTypes.FETCHING_USER: {
      return {
        ...state,
        userLoading: action.payload
      }
    }

    case ActionTypes.UPDATE_USER: {
      const newState = { ...state }
      if (action.payload.user) {
        newState.user = action.payload.user
        newState.language = action.payload.user.language
        newState.trackingMinutes = getTrackingMinutes(action.payload.user)
      }

      if (action.payload.subscriptions) {
        newState.subscriptions = action.payload.subscriptions
      }

      if (action.payload.subscriptionInUse) {
        newState.subscriptionInUse = action.payload.subscriptionInUse
      }

      if (action.payload.planName) {
        newState.planName = action.payload.planName
      }

      return newState
    }

    case ActionTypes.FETCHING_CUSTOMER: {
      return {
        ...state,
        customerLoading: action.payload
      }
    }

    case ActionTypes.FETCH_CUSTOMER: {
      const newState = { ...state }

      newState.customerLoaded = true

      if (action.payload.customer) {
        newState.customer = action.payload.customer
      }

      if (action.payload.address) {
        newState.address = action.payload.address
      }

      if (action.payload.business) {
        newState.business = action.payload.business
      }

      if (action.payload.transactions) {
        newState.transactions = action.payload.transactions
      }

      return newState
    }

    default:
      return state
  }
}

function _getLanguage() {
  // Read language from navigator settings.
  let languageInfo
  if (!languageInfo) {
    const userLocales = UserLocale.getUserLocales()
    for (let i = 0; i < userLocales.length; i += 1) {
      languageInfo = Object.values(Translations).find(v => v.locale === userLocales[i])
      if (languageInfo) {
        break
      }
    }
  }

  // If null, use default language.
  if (!languageInfo) {
    languageInfo = Translations.English
  }

  return languageInfo.locale
}
