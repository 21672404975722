/** @jsx jsx */
// eslint-disable-next-line
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import { theme } from '../../../../constants'
import { confirmAlert } from '../../../notifications/Actions'

const grey = theme.palette.common.greyHover

const styles = {
  itemContainer: css`
    display: flex;
    height: 38px;
    margin-right: 7px;
    outline: 0;
    cursor: default;
    &:hover {
      .video-menu-item-content {
        background: ${grey};
      }
    }
  `,
  icon: css`
    width: 30px;
    height: 100%;
  `,
  content: css`
    flex: 1;
    display: flex;
    padding: 0 7px;
  `,
  name: css`
    display: flex;
    flex: 1;
    font-size: 13px;
    align-items: center;
    padding-right: 15px;
  `,
  icons: css`
    display: flex;
    align-items: center;
  `,
  iconTag: css`
    font-size: 17px;
    margin-left: 10px;
  `,
  iconButton: css`
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `,
  disableIcon: css`
    color: lightgrey;
    pointer-events: none;
    cursor: not-allowed;
  `
}

function deleteData(props) {
  confirmAlert()
    .then(() => {
      props.deleteVideoProject(props.vp._id)()
    })
    .catch(() => {})
}

function startProcessingWaitingJobs(props) {
  confirmAlert()
    .then(() => {
      props.startProcessingWaitingJobs(props.vp._id)()
    })
    .catch(() => {})
}

function VideoProjectMenuItem(props) {
  const isForked = !!props.vp.forked_from

  let disabledItem
  if (props.isVpCreatedInCloud || isForked) {
    disabledItem = !props.isDeleteVideoProjectAvailable && !props.isEditVideoProjectAvailable
  } else {
    disabledItem = true
  }

  const isDeleteVideoProjectAvailable =
    props.isDeleteVideoProjectAvailable && (props.isVpCreatedInCloud || isForked)
  const isEditVideoProjectAvailable =
    props.isEditVideoProjectAvailable && props.isVpCreatedInCloud && !isForked

  return (
    <div
      css={css`
        ${styles.itemContainer};
        pointer-events: ${disabledItem ? 'none' : 'all'};
      `}
    >
      <div css={styles.icon} />
      <div css={styles.content} className="video-menu-item-content">
        <div
          css={css`
            ${styles.name};
            color: ${disabledItem ? 'lightgrey' : theme.palette.common.black};
          `}
        >
          {window.I18n.t('videoProject.title')}
        </div>
        <div css={styles.icons}>
          {/* Send waiting jobs from API to AWS Batch */}
          {props.isMetricaSportsUser && (
            <Tooltip
              title={window.I18n.t('videoProject.processWaitingJobs')}
              enterDelay={400}
              placement="top"
            >
              <Icon
                css={css`
                  ${styles.iconTag};
                  ${styles.iconButton};
                  color: ${theme.palette.secondary.main};
                `}
                onClick={() => startProcessingWaitingJobs(props)}
              >
                refresh
              </Icon>
            </Tooltip>
          )}
          {/* Delete video project */}
          <Tooltip title={window.I18n.t('videoProject.delete')} enterDelay={400} placement="top">
            <Icon
              css={css`
                ${styles.iconTag};
                ${styles.iconButton};
                color: ${theme.palette.error.main};
                ${isDeleteVideoProjectAvailable ? '' : styles.disableIcon};
              `}
              onClick={() => deleteData(props)}
            >
              delete
            </Icon>
          </Tooltip>

          {/* Edit video project */}
          <Tooltip title={window.I18n.t('videoProject.edit')} enterDelay={400} placement="top">
            <Icon
              css={css`
                ${styles.iconTag};
                ${styles.iconButton};
                color: ${theme.palette.secondary.main};
                ${isEditVideoProjectAvailable ? '' : styles.disableIcon};
              `}
              onClick={props.editVideoProject(props.vp)}
            >
              edit
            </Icon>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

VideoProjectMenuItem.propTypes = {
  vp: PropTypes.object.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  isVpCreatedInCloud: PropTypes.bool.isRequired,
  isEditVideoProjectAvailable: PropTypes.bool.isRequired,
  isDeleteVideoProjectAvailable: PropTypes.bool.isRequired,
  startProcessingWaitingJobs: PropTypes.func.isRequired, // eslint-disable-line
  deleteVideoProject: PropTypes.func.isRequired, // eslint-disable-line
  editVideoProject: PropTypes.func.isRequired
}

export default VideoProjectMenuItem
