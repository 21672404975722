/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import whistle from '../../assets/whistle.png'

const reload = () => window.location.reload()
const styles = {}
styles.container = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(#fff, #cccccc);
  display: flex;
  justify-content: center;
  align-items: center;
`
styles.content = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
styles.text = css`
  font-size: 22px;
  text-align: center;
`
styles.buttonWrapper = css`
  margin-top: 40px;
`

function UncaughtApplicationError() {
  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <img src={whistle} alt="whistle-logo" width="200" />
        <div css={styles.text}>{window.I18n.t('error.oops')}</div>
        <div css={styles.text}>{window.I18n.t('error.restart')}</div>
        <div css={styles.buttonWrapper}>
          <Button variant="contained" onClick={reload}>
            {window.I18n.t('common.restart')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UncaughtApplicationError
