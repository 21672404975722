/** @jsx jsx */
// eslint-disable-next-line
import { PureComponent } from 'react'
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { paymentsBaseUrl, theme } from '../../../constants'
import openInNewTab from '../../../utils/openInNewTab'

const styles = {}

styles.blockingOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
`

styles.box = css`
  position: absolute;
  width: 700px;
  border-radius: 5px;
  padding: 45px 70px;
  background: white;
  border: 10px solid lightgray;
  pointer-events: all;
`

styles.title = css`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 25px;
`

styles.icon = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
`

styles.descriptionTableContainer = css`
  display: flex;
  flex-wrap: wrap;
`

styles.descriptionTableContainerTitle = css`
  font-size: 16px;
  margin: 15px 0px;
`

styles.actionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

styles.button = {
  width: '300px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  fontSize: '13px',
  cursor: 'pointer'
}

styles.actionButton = {
  ...styles.button,
  background: theme.palette.common.blue,
  color: theme.palette.common.white,
  marginTop: '5px'
}

styles.learnMoreButton = {
  ...styles.button,
  color: theme.palette.common.blue,
  marginTop: '15px'
}

styles.iconClose = css`
  position: absolute;
  display: flex;
  color: lightgrey;
  top: 20px;
  right: 20px;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 28px;
  cursor: pointer;
`

class UpgradeModal extends PureComponent {
  render() {
    return (
      <div css={styles.blockingOverlay}>
        <div css={styles.box}>
          <span css={styles.title}>{this.props.title}</span>
          <div css={styles.icon}>
            <img src={this.props.icon} style={{ maxWidth: '100px' }} />
          </div>
          <div css={styles.descriptionTableContainerTitle}>{this.props.description}</div>
          {this.props.secondDescription && (
            <div css={styles.descriptionTableContainerTitle}>{this.props.secondDescription}</div>
          )}
          <div css={styles.descriptionTableContainerTitle}>
            {window.I18n.t('upgradeModal.linksDescription')}
          </div>
          <div css={styles.actionContainer}>
            {['minutes_10', 'minutes_100', 'minutes_1000', 'minutes_10000'].map(v => (
              <span
                css={styles.actionButton}
                onClick={() =>
                  openInNewTab(`${paymentsBaseUrl[process.env.REACT_APP_ENV]}checkout/${v}`)
                }
              >
                {window.I18n.t(`upgradeModal.minutePackLinks.${v}`)}
              </span>
            ))}
            <span
              css={styles.learnMoreButton}
              onClick={() => openInNewTab('https://metrica-sports.com/automated-tracking-data/')}
            >
              Learn more
            </span>
          </div>
          <span css={styles.iconClose} onClick={this.props.onClose}>
            {' '}
            X{' '}
          </span>
        </div>
      </div>
    )
  }
}

UpgradeModal.defaultProps = {
  secondDescription: undefined
}

UpgradeModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  clickMessage: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default UpgradeModal
