import amplitude from 'amplitude-js'
import ObjectId from 'bson-objectid'
import getHighestPriorityOrganization from '../utils/getHighestPriorityOrganization'

const key =
  process.env.REACT_APP_ENV === 'production'
    ? 'c720affbd112e1fba5b3058292313684'
    : '8094dd1c9ea36844867f8e1d360d067e'

class Amplitude {
  constructor() {
    this.amp = amplitude.getInstance()
    this.amp.init(key)
    window.AMP_CLIENT = this.amp
    this.context = 'metrica-cloud'
    this.sessionId = ObjectId().str
  }

  setUser(user) {
    const mainOrganization = getHighestPriorityOrganization(user)
    this.mainOrganization = mainOrganization
    this.userEmail = user.email

    try {
      this.amp.setUserId(user.email)
      this.amp.setUserProperties({
        id: user._id,
        email_notifications: user.email_notifications,
        name: user.name,
        data_access: mainOrganization.data_access.code,
        organization: mainOrganization.organization.code,
        lastUpdate: user.lastUpdate,
        last_login: user.last_login
      })
    } catch (e) {
      console.warn('Could not set up user in Amplitude')
    }
  }

  logEvent(action) {
    const isProd = process.env.REACT_APP_ENV === 'production'
    try {
      this.amp.logEvent(action, {
        action: action || undefined,
        context: this.context,
        data_access: this.mainOrganization.data_access.code,
        organization: this.mainOrganization.organization.code,
        sessionId: this.sessionId,
        user: this.userEmail
      })
      if (!isProd) console.log(`Amplitud event: ${action} registered`)
    } catch (error) {
      if (!isProd) console.log(`Amplitud event: ${action} ERROR`, error)
    }
  }
}

// Amplitud Event
// {
// action: string (comes from the constants for Analytics),
// context: 'metrica-cloud',
// data_access: mainOrganization.data_access.code,
// organization: mainOrganization.organization.code,
// sessionId: generate or get a session ID,
// user: User's email
// }

export default new Amplitude()
