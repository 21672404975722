/** @jsx jsx */
// eslint-disable-next-line
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/core'
import MuiDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiStepper from '@material-ui/core/Stepper'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import path from 'path'
import { toast } from 'react-toastify'
import AutocompleteField from './AutocompleteField'
import DatePickerField from './DatePickerField'
import MultiselectField from './MultiselectField'
import modelsService from '../../../services/models'
import playerLogo from '../../../assets/player-detection.svg'
import fieldLogo from '../../../assets/field-detection.svg'
import ballLogo from '../../../assets/ball-detection.svg'
import automaticTaggingLogo from '../../../assets/events-detection-icon.png'
import atdLogo from '../../../assets/atd-icon.svg'
import correctionIcon from '../../../assets/correction-icon.svg'
import amplitude from '../../../analytics/amplitude'
import {
  shortInputMaxLength,
  longInputMaxLength,
  theme,
  AnalyticsEvents,
  videoFormats,
  GAMES_DATASET_DATA_TYPE
} from '../../../constants'
import TextSwitch from '../../../utils/textSwitch'

const styles = {}
styles.formContainer = css`
  width: 750px;
  position: relative;
`
styles.dialogContainer = css`
  h6 {
    font-weight: bold;
  }
  padding: 20px;
  padding-top: 0;

  text {
    font-weight: bolder;
  }

  fieldset {
    padding: 0px 25px;
    width: 100%;
    & > div {
      display: flex;
      justify-content: space-between;
      flex-flow: nowrap;
    }
  }
`
styles.stepperContainer = css`
  display: flex;
  justify-content: center;
`
styles.stepLabelIcon = css`
  .MuiStepIcon-root.MuiStepIcon-active text {
    fill: ${theme.palette.common.charolBlack};
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${theme.palette.common.charolBlack};
    text {
      fill: ${theme.palette.common.charolBlack};
    }
  }
`
styles.stepLabel = css`
  font-weight: bolder;
`
styles.dialogContentContainer = css`
  padding: 0;
`
styles.controlLabel = css`
  margin-right: unset;
  margin-left: unset;
`
styles.organizationContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
styles.correctionContainer = css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 16px;
  margin-bottom: -6px;
  line-height: 30px;
  font-weight: 500;
`
styles.progressWrapper = css`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
`
styles.selectFileContainer = css`
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`
styles.selectFileButtonContainer = css`
  display: flex;
  flex-direction: column;
`
styles.selectFileNameContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
styles.radioContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  label span {
    font-size: 21px;
    font-weight: 500;
  }
`
styles.selectFileButton = {
  borderRadius: '3px',
  minWidth: '195px',
  color: theme.palette.common.charolBlack,
  background: theme.palette.common.mainGreen,
  whiteSpace: 'nowrap'
}
styles.selectFileName = css`
  flex: 1;
  margin-left: 39px;
  min-height: 29px;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 13px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`
styles.selectFileNameText = css`
  color: ${theme.palette.text.darkGrey};
  font-weight: 500;
  font-size: 14px;
  overflow-wrap: anywhere;
`
styles.jobsContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`
styles.jobTogglesContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
`
styles.atdContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 4px;
`
styles.atdText = css`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
`
styles.informationTogglesContainer = css`
  display: flex;
  flex-direction: column;
`
styles.informationContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`
styles.jobContainer = css`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  color: #444444;
  line-height: 30px;
  font-weight: 500;
`
styles.jobText = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
styles.switchContainer = css`
  position: relative;
  top: 3px;
`
styles.requestContainer = css`
  position: relative;
  font-size: 10px;
  color: ${theme.palette.common.blue};
  text-transform: uppercase;
`
styles.loadingLabel = css`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
`
styles.closeIcon = css`
  color: #444444;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
`
styles.logoImg = css`
  width: 26px;
  position: relative;
  margin-right: 15px;
  bottom: 2px;
`
styles.correctionLogoImg = css`
  width: 20px;
  position: relative;
  margin-right: 8px;
  bottom: 2px;
  opacity: 0.65;
`
styles.videoRequired = css`
  font-size: 12px;
  color: ${theme.palette.error.main};
  position: absolute;
  bottom: -23px;
  left: 0;
`
styles.informationText = css`
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
`
styles.liveCodingInformation = css`
  font-size: 13px;
  text-decoration: underline;
  white-space: nowrap;
  display: flex;
  margin: 7px 0px;
`
styles.fontBolder = {
  fontWeight: 'bolder',
  color: theme.palette.common.charolBlack,
  padding: '5px 30px'
}
styles.backgroundGradient = {
  ...styles.fontBolder,
  background: theme.palette.common.mainGradient
}
styles.infoIcon = {
  color: 'grey',
  fontSize: '16px',
  position: 'relative',
  transform: 'rotate(5deg)'
}
styles.clearInputVideo = css`
  color: #fff;
  background-color: grey;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: default;
  transform: scale(0.6);
  margin-left: 10px;
`

const DialogContent = withStyles(() => ({
  root: {
    padding: '8px 50px 55px 50px',
    minHeight: '300px',
    overflow: 'hidden',
    '& label.Mui-focused': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.charolBlack
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: theme.palette.common.charolBlack
    },
    '& .MuiSwitch-colorPrimary': {
      color: theme.palette.common.mainGreen
    },
    '& .MuiBadge-colorPrimary': {
      position: 'relative',
      backgroundColor: theme.palette.common.blue,
      fontSize: '12px',
      textTransform: 'uppercase'
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'unset',
      padding: '0px 10px'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 0.5
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.common.mainGreen,
      opacity: 1
    },
    '& .MuiSwitch-thumb': {
      color: theme.palette.common.white
    }
  }
}))(MuiDialogContent)

const Dialog = withStyles(() => ({
  paper: {
    maxHeight: '100%'
  }
}))(MuiDialog)

const Stepper = withStyles(() => ({
  root: {
    flex: 0.8
  }
}))(MuiStepper)

const CustomBadge = withStyles(() => ({
  root: {
    '& .MuiBadge-colorPrimary': {
      position: 'relative',
      backgroundColor: theme.palette.common.blue,
      textTransform: 'uppercase',
      fontSize: '9px',
      height: '14px',
      marginLeft: '10px'
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'unset',
      padding: '0px 10px'
    }
  }
}))(Badge)

const inputNumberProps = { min: 0, step: 1, max: 99 }
const RadioEl = <Radio color={theme.palette.common.black} />

class CreateUpdateGameBySteps extends PureComponent {
  constructor(props) {
    super(props)

    this.vp = props.videoProject
    this.vpVideo = ''
    if (this.vp) {
      this.vpVideo = this.vp.files.video

      const isGvp = !this.vp.game.description
      const sharedProps = {
        competition: this.vp.game.competition,
        place: this.vp.game.stadium,
        date: this.vp.game.date,
        id: this.vp.game._id,
        organizations: this.vp.organizations
          ? this.vp.organizations.map(({ _id, name }) => ({ value: _id, label: name }))
          : []
      }

      // vp.game.result could be undefined
      const homeTeamScore =
        typeof _.get(this.vp, 'game.result.home') === 'number'
          ? { homeTeamScore: this.vp.game.result.home }
          : {}
      const awayTeamScore =
        typeof _.get(this.vp, 'game.result.away') === 'number'
          ? { awayTeamScore: this.vp.game.result.away }
          : {}

      if (isGvp) {
        this.formInitialValues = {
          ...sharedProps,
          homeTeam: this.vp.game.home_team,
          awayTeam: this.vp.game.away_team,
          ...homeTeamScore,
          ...awayTeamScore
        }
      } else {
        this.formInitialValues = {
          ...sharedProps,
          description: this.vp.game.description
        }
      }
    } else {
      const highestPriorityUserOrganization = _.maxBy(
        props.userOrganizations.filter(
          v => v.permission.code === 'WRITE' && !v.organization.correction_usage
        ),
        v => v.priority
      )

      let selectedOrganizations
      if (highestPriorityUserOrganization) {
        selectedOrganizations = [highestPriorityUserOrganization.organization].map(
          ({ _id, name }) => ({
            value: _id,
            label: name
          })
        )
      } else {
        selectedOrganizations = []
      }

      this.formInitialValues = {
        homeTeam: '',
        awayTeam: '',
        homeTeamScore: undefined,
        awayTeamScore: undefined,
        competition: '',
        place: '',
        date: new Date(),
        organizations: selectedOrganizations
      }
    }

    const vpType = this.formInitialValues.description ? 'svp' : 'gvp'
    const halves = this.vp && this.vp.video.halves
    this.state = {
      vpType,
      vpId: this.vp ? this.vp._id : '',
      selectedOrganizations: this.formInitialValues.organizations,
      customOrganizations: false,
      activeStep: 0,
      isPlaylist: this.vpVideo ? this.vp.is_playlist : false,
      isFootballSideView: this.vpVideo ? this.vp.is_football_side_view : true,
      isLocalTeamOnTheLeft:
        vpType === 'gvp' && halves && halves.length
          ? halves[0].starting_sides.some(v => {
              return (
                v === this.formInitialValues.homeTeam ||
                v === this.formInitialValues.homeTeam._id ||
                v === this.formInitialValues.homeTeam.name
              )
            })
          : true,
      numberOfHalves: halves ? halves.length || 2 : 2,
      automatedTrackingData: !!this.vpVideo,
      playerTracking: !!this.vpVideo,
      fieldRecognition: !!this.vpVideo,
      eventsDetection: this.vp ? this.vp.compute_events : false,
      ballDetection: this.vp ? this.vp.compute_ball : false,
      corrected: this.vp ? this.vp.data_type === GAMES_DATASET_DATA_TYPE.CORRECTED : false,
      selectFileInputValue: '',
      videoFilename: this.vpVideo ? path.basename(this.vpVideo) : '',
      selectedFile: this.vpVideo ? {} : null,
      step1Error: false,
      isSubmitting: false
    }
  }

  handleNext = formik => () => {
    if (this.state.activeStep === 0) {
      const errors = this.validateForm(formik.values)
      if (Object.values(errors).length) {
        return formik.submitForm()
      }
    }
    // No need to validate video file
    // if (this.state.activeStep === 1 && !this.state.selectFileInputValue) {
    //   this.setState({ step1Error: true })
    //   return
    // }
    if (this.state.activeStep === 1) {
      this.onSubmit(formik.values, { setSubmitting: formik.setSubmitting })
    }
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }))
  }

  handleBack = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }))
  }

  handleReset = () => {
    this.setState({ activeStep: 1 })
  }

  startUploadVideo = () => {
    const { videoFilename, selectedFile } = this.state
    this.props.startUploadVideo(
      this.state.vpId,
      {
        fileName: videoFilename,
        selectedFile
      },
      this.state.automatedTrackingData
    )
  }

  validateForm = values => {
    const errors = {}
    if (!values.date) {
      errors.date = window.I18n.t('common.required')
    }

    if (this.state.vpType === 'gvp') {
      if (typeof values.homeTeamScore === 'number' && values.homeTeamScore < 0) {
        errors.homeTeamScore = 'Score must be positive'
      }

      if (typeof values.homeTeamScore === 'number' && values.homeTeamScore > 99) {
        errors.homeTeamScore = 'Score must be a two digits number'
      }

      if (typeof values.awayTeamScore === 'number' && values.awayTeamScore < 0) {
        errors.awayTeamScore = 'Score must be positive'
      }

      if (typeof values.awayTeamScore === 'number' && values.awayTeamScore > 99) {
        errors.awayTeamScore = 'Score must be a two digits number'
      }

      if (!values.homeTeam) {
        errors.homeTeam = window.I18n.t('common.required')
      }

      if (!values.awayTeam) {
        errors.awayTeam = window.I18n.t('common.required')
      }

      if (typeof values.homeTeamScore === 'undefined') {
        errors.homeTeamScore = window.I18n.t('common.required')
      }

      if (typeof values.awayTeamScore === 'undefined') {
        errors.awayTeamScore = window.I18n.t('common.required')
      }
    } else if (this.state.vpType === 'svp') {
      if (!values.description) {
        errors.description = window.I18n.t('common.required')
      }
    }

    return errors
  }

  onSubmit = async (values, { setSubmitting }) => {
    const isGvp = this.state.vpType === 'gvp'
    let vpName = isGvp ? 'Game Video Project' : 'Single Video Project'

    try {
      // If video-project ID exists before submitting, it means we're updating.
      let vpId = this.state.vpId
      const isUpdating = !!vpId

      this.setState({ isSubmitting: true })
      const payload = this.createUpdatePayload(values)
      const response = await this.props.createUpdateGame(payload)
      if (response.data && response.data._id) {
        vpId = response.data._id
        this.setState({ vpId })
      }

      const newItem = await this.props.onCreateUpdate(vpId, isUpdating)

      vpName = isGvp
        ? `${newItem.game.home_team.name} vs ${newItem.game.away_team.name}`
        : newItem.game.description
      let toastKey = 'admin.vpCreated'
      let analyticKey = AnalyticsEvents.CREATE_GAME_DATASET
      if (isUpdating) {
        toastKey = 'admin.editedSuccessfully'
        analyticKey = AnalyticsEvents.UPDATE_GAME_DATASET
      }

      toast.info(window.I18n.t(toastKey, { vpName }))
      amplitude.logEvent(analyticKey)

      if (this.state.selectedFile && this.state.selectedFile.name) {
        this.startUploadVideo()
      }
    } catch (error) {
      console.error(error)
      toast.error(window.I18n.t('common.errorMsg', { name: vpName }))
    } finally {
      this.setState({ isSubmitting: false })
      setSubmitting(false)
      this.props.close()
    }
  }

  createUpdatePayload = values => {
    const payload = {
      competition: values.competition._id || values.competition,
      stadium: values.place._id || values.place,
      date: this.createDate(values.date)
    }

    if (values.organizations.length) {
      payload.organizations = values.organizations.map(org => org.value || org)
    }

    // Set video config if video will be uploaded.
    if (!this.vpVideo && this.state.selectedFile) {
      payload.is_playlist = this.state.isPlaylist
      payload.is_football_side_view = this.state.isFootballSideView
    }

    if (this.state.vpId) {
      payload.gameId = this.vp.game._id
    } else {
      payload.data_type = GAMES_DATASET_DATA_TYPE.AUTOMATED_TRACKING_DATA
    }

    const isGvp = this.state.vpType === 'gvp'
    if (isGvp) {
      payload.home_team = values.homeTeam._id || values.homeTeam
      payload.away_team = values.awayTeam._id || values.awayTeam
      payload.result = { home: values.homeTeamScore, away: values.awayTeamScore }
      payload.compute_events = this.state.eventsDetection
      payload.compute_ball = this.state.ballDetection

      if (!this.state.vpId && this.state.corrected) {
        payload.data_type = GAMES_DATASET_DATA_TYPE.CORRECTED
      }

      // Add halves data if video exists.
      if (this.state.videoFilename) {
        payload.halves = [
          {
            starting_sides: this.state.isLocalTeamOnTheLeft
              ? [payload.home_team, payload.away_team]
              : [payload.away_team, payload.home_team]
          }
        ]

        if (Number(this.state.numberOfHalves) === 2) {
          payload.halves.push({
            starting_sides: this.state.isLocalTeamOnTheLeft
              ? [payload.away_team, payload.home_team]
              : [payload.home_team, payload.away_team]
          })
        }
      }
    } else {
      payload.description = values.description
      payload.compute_ball = this.state.ballDetection
    }

    return payload
  }

  createDate = date => {
    if (date.isLuxonDateTime) {
      return new Date(date.ts).toISOString()
    }
    if (Object.prototype.toString.call(date) === '[object Date]') {
      return date.toISOString()
    }
    return date
  }

  onOptionDelete = type => id => {
    const request = modelsService[`delete${type}`]
    const fetch = `fetch${type}s`
    this.props.onOptionDelete(request, id, fetch)
  }

  setVpType = e => {
    const vpType = e.target.value
    const nextState = { vpType }

    if (vpType === 'svp') {
      nextState.corrected = false
    }

    let selectedOrganizations
    if (this.state.customOrganizations) {
      let selectableOrganizations = this.props.nonCorrectedOrganizations
      if (vpType === 'gvp' && !this.state.corrected) {
        selectableOrganizations = this.props.nonCorrectedOrganizations
      }

      selectedOrganizations = this.state.selectedOrganizations.filter(v =>
        selectableOrganizations.some(w => v.value === w.value)
      )
    } else {
      const highestPriorityUserOrganization = _.maxBy(
        this.props.userOrganizations.filter(
          v => v.permission.code === 'WRITE' && !v.organization.correction_usage
        ),
        v => v.priority
      )

      if (highestPriorityUserOrganization) {
        selectedOrganizations = [highestPriorityUserOrganization.organization].map(
          ({ _id, name }) => ({
            value: _id,
            label: name
          })
        )
      } else {
        selectedOrganizations = []
      }
    }

    nextState.selectedOrganizations = selectedOrganizations

    this.setState(nextState)
  }

  descriptionOnChange = formikOnChange => e => {
    if (e.target.value.length > longInputMaxLength) {
      return
    }

    formikOnChange(e)
  }

  onToggleCorrected = () => {
    this.setState(prevState => {
      const corrected = !prevState.corrected
      let selectedOrganizations

      if (this.state.customOrganizations) {
        // Filter organizations with correction-usage flag enabled. If any organization selected is
        // not in the non-corrected ones, filter it.
        const selectableOrganizations = corrected
          ? this.props.organizations
          : this.props.nonCorrectedOrganizations
        selectedOrganizations = this.state.selectedOrganizations.filter(v =>
          selectableOrganizations.some(w => v.value === w.value)
        )
      } else {
        const highestPriorityUserOrganization = _.maxBy(
          this.props.userOrganizations.filter(
            v => v.permission.code === 'WRITE' && !!v.organization.correction_usage === corrected
          ),
          v => v.priority
        )

        if (highestPriorityUserOrganization) {
          selectedOrganizations = [highestPriorityUserOrganization.organization].map(
            ({ _id, name }) => ({
              value: _id,
              label: name
            })
          )
        } else {
          selectedOrganizations = []
        }
      }

      this.setState({ selectedOrganizations })

      return { corrected }
    })
  }

  onChangeOrganizations = organizations => {
    this.setState({
      selectedOrganizations: organizations,
      customOrganizations: organizations.length !== 0
    })
  }

  onToggleAutomatedTrackingData = () => {
    this.setState(prevState => ({ automatedTrackingData: !prevState.automatedTrackingData }))
  }

  onToggleEventsDetection = () => {
    this.setState(prevState => ({
      eventsDetection: !prevState.eventsDetection,
      ballDetection: !prevState.eventsDetection ? true : prevState.ballDetection
    }))
  }

  onToggleBallDetection = () => {
    this.setState(prevState => ({
      ballDetection: !prevState.ballDetection,
      eventsDetection: prevState.ballDetection ? false : prevState.eventsDetection
    }))
  }

  selectVideoFile = event => {
    this.selectVideoFileInput.click()
  }

  fileChangedHandler = event => {
    event.persist()
    const eventTarget = event.target
    const selectFileInputValue = eventTarget && eventTarget.value
    const selectedFile = eventTarget && eventTarget.files && eventTarget.files[0]
    const videoFilename = selectedFile && selectedFile.name
    if (selectFileInputValue && selectedFile && videoFilename) {
      this.setState({
        selectFileInputValue,
        videoFilename,
        selectedFile,
        step1Error: false,
        automatedTrackingData: true,
        playerTracking: true,
        fieldRecognition: true,
        eventsDetection: false,
        ballDetection: false
      })
    } else {
      this.clearInputVideo()
    }
  }

  clearInputVideo = e => {
    if (e) {
      e.stopPropagation()
    }

    this.setState({
      selectFileInputValue: null,
      videoFilename: '',
      selectedFile: null,
      step1Error: false,
      isPlaylist: false,
      isFootballSideView: true,
      automatedTrackingData: false,
      playerTracking: false,
      fieldRecognition: false,
      eventsDetection: false,
      ballDetection: false
    })

    this.selectVideoFileInput.value = null
  }

  render() {
    const homeTeam = this.formInitialValues.homeTeam
    const awayTeam = this.formInitialValues.awayTeam
    const competition = this.formInitialValues.competition
    const place = this.formInitialValues.place
    const videoReadyToUpload = !this.vpVideo && this.state.selectedFile
    const hasCorrectionOrganizations =
      this.props.isMetricaSportsUser ||
      this.props.organizations.length > this.props.nonCorrectedOrganizations.length

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        maxWidth="md"
      >
        <Formik
          initialValues={this.formInitialValues}
          validate={this.validateForm}
          onSubmit={this.onSubmit}
        >
          {formik => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              method="POST"
              css={styles.formContainer}
              ref={el => {
                this.formEl = el
              }}
            >
              {this.state.activeStep !== 2 && (
                <div css={styles.closeIcon}>
                  <IconButton onClick={this.props.close}>
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </div>
              )}
              <div css={styles.dialogContainer}>
                <div css={styles.stepperContainer}>
                  <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {[
                      window.I18n.t('admin.videoProjectSettings'),
                      window.I18n.t('admin.uploadAndTracking')
                    ].map(label => (
                      <Step key={label}>
                        <StepLabel css={styles.stepLabelIcon}>
                          <span css={styles.stepLabel}>{label}</span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                {/* Step 0 */}
                <div
                  css={css`
                    display: ${this.state.activeStep < 1 ? 'block' : 'none'};
                  `}
                >
                  <div css={styles.radioContainer}>
                    <FormControl component="fieldset" disabled={!!this.state.vpId}>
                      <RadioGroup
                        name="videoProject"
                        value={this.state.vpType}
                        onChange={this.setVpType}
                        row
                      >
                        <FormControlLabel
                          value="gvp"
                          control={RadioEl}
                          label={window.I18n.t('admin.gameVideoProject')}
                          css={styles.controlLabel}
                        />
                        <FormControlLabel
                          value="svp"
                          control={RadioEl}
                          label={window.I18n.t('admin.singleVideoProject')}
                          css={styles.controlLabel}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <DialogContent>
                    <div css={styles.dialogContentContainer}>
                      {/* Teams container. */}
                      <div
                        css={css`
                          display: ${this.state.vpType === 'gvp' ? 'flex' : 'none'};
                          justify-content: space-between;
                        `}
                      >
                        <div
                          css={css`
                            margin-right: 15px;
                            flex: 1;
                          `}
                        >
                          <Field
                            name="homeTeam"
                            label={window.I18n.t('admin.homeTeam')}
                            placeholder={window.I18n.t('admin.homeTeam')}
                            css={css`
                              '&$focused': {
                                color: 'red';
                              }
                            `}
                            component={AutocompleteField}
                            suggestions={this.props.teams}
                            onOptionDelete={this.onOptionDelete('Team')}
                            maxlength={shortInputMaxLength}
                            initialSelected={
                              homeTeam && homeTeam._id
                                ? { value: homeTeam._id, label: homeTeam.name }
                                : null
                            }
                          />
                        </div>
                        <div
                          css={css`
                            margin-left: 15px;
                            flex: 1;
                          `}
                        >
                          <Field
                            name="awayTeam"
                            label={window.I18n.t('admin.awayTeam')}
                            placeholder={window.I18n.t('admin.awayTeam')}
                            component={AutocompleteField}
                            suggestions={this.props.teams}
                            onOptionDelete={this.onOptionDelete('Team')}
                            maxlength={shortInputMaxLength}
                            initialSelected={
                              awayTeam && awayTeam._id
                                ? { value: awayTeam._id, label: awayTeam.name }
                                : null
                            }
                          />
                        </div>
                      </div>
                      {/* Team scores container. */}
                      <div
                        css={css`
                          display: ${this.state.vpType === 'gvp' ? 'flex' : 'none'};
                          justify-content: space-between;
                        `}
                      >
                        <div
                          css={css`
                            margin-right: 15px;
                            flex: 1;
                          `}
                        >
                          <TextField
                            label={window.I18n.t('admin.homeTeamScore')}
                            placeholder={window.I18n.t('admin.homeTeamScore')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.homeTeamScore}
                            type="number"
                            name="homeTeamScore"
                            margin="normal"
                            inputProps={inputNumberProps}
                            error={
                              formik.errors.homeTeamScore &&
                              formik.touched.homeTeamScore &&
                              !!formik.errors.homeTeamScore
                            }
                            helperText={
                              formik.errors.homeTeamScore &&
                              formik.touched.homeTeamScore &&
                              formik.errors.homeTeamScore
                            }
                            fullWidth
                          />
                        </div>
                        <div
                          css={css`
                            margin-left: 15px;
                            flex: 1;
                          `}
                        >
                          <TextField
                            label={window.I18n.t('admin.awayTeamScore')}
                            placeholder={window.I18n.t('admin.awayTeamScore')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.awayTeamScore}
                            type="number"
                            name="awayTeamScore"
                            margin="normal"
                            inputProps={inputNumberProps}
                            error={
                              formik.errors.awayTeamScore &&
                              formik.touched.awayTeamScore &&
                              !!formik.errors.awayTeamScore
                            }
                            helperText={
                              formik.errors.awayTeamScore &&
                              formik.touched.awayTeamScore &&
                              formik.errors.awayTeamScore
                            }
                            fullWidth
                          />
                        </div>
                      </div>
                      {this.state.vpType === 'svp' && (
                        <TextField
                          label={window.I18n.t('admin.description')}
                          placeholder={window.I18n.t('admin.description')}
                          onChange={this.descriptionOnChange(formik.handleChange)}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          type="text"
                          name="description"
                          margin="normal"
                          error={formik.errors.description && !!formik.errors.description}
                          helperText={formik.errors.description && formik.errors.description}
                          fullWidth
                        />
                      )}
                      <Field
                        name="competition"
                        label={`${window.I18n.t('admin.competition')}/${window.I18n.t(
                          'admin.category'
                        )}`}
                        placeholder={`${window.I18n.t('admin.competition')}/${window.I18n.t(
                          'admin.category'
                        )}`}
                        component={AutocompleteField}
                        suggestions={this.props.competitions}
                        initialSelected={
                          competition && competition._id
                            ? { value: competition._id, label: competition.name }
                            : null
                        }
                        onOptionDelete={this.onOptionDelete('Competition')}
                        maxlength={longInputMaxLength}
                      />
                      <Field
                        name="place"
                        label={window.I18n.t('admin.place')}
                        placeholder={window.I18n.t('admin.place')}
                        component={AutocompleteField}
                        suggestions={this.props.stadiums}
                        initialSelected={
                          place && place._id ? { value: place._id, label: place.name } : null
                        }
                        onOptionDelete={this.onOptionDelete('Stadium')}
                        maxlength={longInputMaxLength}
                      />
                      <div
                        css={css`
                          input {
                            text-transform: uppercase;
                          }
                        `}
                      >
                        <Field
                          name="date"
                          component={DatePickerField}
                          label={window.I18n.t('admin.date')}
                          showTodayButton
                        />
                      </div>
                      <div css={styles.organizationContainer}>
                        {this.state.vpType === 'gvp' && (
                          <div css={styles.correctionContainer}>
                            <Tooltip
                              title={window.I18n.t('admin.correctionUsageTooltip')}
                              placement="top"
                            >
                              <div css={styles.jobText}>
                                <img src={correctionIcon} css={styles.correctionLogoImg} />
                                <span style={{ paddingRight: '3px' }}>
                                  {window.I18n.t('admin.correctionUsage')}
                                </span>
                                <Icon style={styles.infoIcon}>info</Icon>
                              </div>
                            </Tooltip>
                            <div css={styles.switchContainer}>
                              <TextSwitch
                                checked={this.state.corrected}
                                onChange={this.onToggleCorrected}
                                name="corrected"
                                color="primary"
                                disabled={!hasCorrectionOrganizations}
                              />
                            </div>
                          </div>
                        )}
                        <Field
                          name="organizations"
                          label={window.I18n.t('admin.organizations')}
                          placeholder={window.I18n.t('admin.organizations')}
                          component={MultiselectField}
                          onChange={this.onChangeOrganizations}
                          suggestions={
                            this.state.corrected
                              ? this.props.organizations
                              : this.props.nonCorrectedOrganizations
                          }
                          fetchSuggestions={this.props.isMetricaSportsUser}
                          initialSelected={this.state.selectedOrganizations}
                        />
                      </div>
                    </div>
                  </DialogContent>
                </div>
                {/* Step 1 */}
                <div
                  css={css`
                    display: ${this.state.activeStep === 1 ? 'block' : 'none'};
                  `}
                >
                  <DialogContent>
                    {/* Select video file. */}
                    <div
                      css={css`
                        ${styles.selectFileContainer};
                        pointer-events: ${this.vpVideo ? 'none' : 'all'};
                        opacity: ${this.vpVideo ? 0.5 : 1};
                      `}
                    >
                      <div css={styles.selectFileButtonContainer}>
                        <Button
                          color="primary"
                          variant="contained"
                          style={styles.selectFileButton}
                          onClick={this.selectVideoFile}
                        >
                          {window.I18n.t('admin.selectVideoFile')}
                        </Button>
                      </div>
                      <div css={styles.selectFileNameContainer}>
                        <div
                          css={styles.selectFileName}
                          style={{
                            borderBottom: this.state.step1Error
                              ? `2px solid ${theme.palette.error.main}`
                              : '1px dotted lightgrey'
                          }}
                          onClick={this.selectVideoFile}
                        >
                          {this.state.videoFilename ? (
                            <React.Fragment>
                              <span css={styles.selectFileNameText}>
                                {this.state.videoFilename}
                              </span>
                              {!this.vpVideo && (
                                <span
                                  css={styles.clearInputVideo}
                                  onClick={e => this.clearInputVideo(e)}
                                >
                                  <CloseIcon fontSize="small" />
                                </span>
                              )}
                            </React.Fragment>
                          ) : (
                            <span
                              css={css`
                                color: ${this.state.step1Error ? theme.palette.error.main : 'grey'};
                              `}
                            >
                              {window.I18n.t('common.noFileChosen')}
                            </span>
                          )}
                          {this.state.step1Error && (
                            <span css={styles.videoRequired}>
                              {window.I18n.t('common.required')}
                            </span>
                          )}
                        </div>
                        <input
                          ref={element => {
                            this.selectVideoFileInput = element
                          }}
                          style={{ display: 'none' }}
                          type="file"
                          id="file"
                          accept={videoFormats}
                          value={this.state.selectFileInputValue}
                          onChange={this.fileChangedHandler}
                        />
                      </div>
                    </div>
                    {!this.vpVideo && (
                      <div>
                        <Tooltip
                          title={window.I18n.t('admin.liveCodingTooltip')}
                          placement="bottom"
                        >
                          <span
                            css={styles.liveCodingInformation}
                            style={{
                              color: this.state.selectedFile
                                ? theme.palette.text.disabled
                                : theme.palette.text.darkGrey
                            }}
                          >
                            <span style={{ paddingRight: '3px' }}>
                              {window.I18n.t('admin.liveCoding')}
                            </span>
                            <Icon style={styles.infoIcon}>info</Icon>
                          </span>
                        </Tooltip>
                      </div>
                    )}

                    {/* Automated Tracking Data. */}
                    <div css={styles.atdContainer}>
                      <div css={styles.atdText}>
                        <img
                          src={atdLogo}
                          css={styles.logoImg}
                          style={{ transform: 'rotate(270deg)' }}
                        />
                        {window.I18n.t('admin.automatedTrackingData')}
                      </div>
                      <Tooltip
                        title={window.I18n.t('admin.trackingLicence')}
                        placement="top"
                        {...(this.props.hasTrackingLicense ? { open: false } : {})}
                      >
                        <TextSwitch
                          containerStyles={{ marginBottom: '2px' }}
                          checked={this.state.selectedFile && this.state.automatedTrackingData}
                          onChange={this.onToggleAutomatedTrackingData}
                          name="automatedTrackingData"
                          color="primary"
                          disabled={!videoReadyToUpload}
                        />
                      </Tooltip>
                    </div>

                    {/* Information toggles. */}
                    <div css={styles.informationTogglesContainer}>
                      {/* Video playlist. */}
                      <div css={styles.informationContainer}>
                        <Tooltip
                          title={window.I18n.t('admin.videoPlaylistTooltip')}
                          placement="bottom"
                        >
                          <span
                            css={styles.informationText}
                            style={{
                              color: videoReadyToUpload
                                ? theme.palette.text.darkGrey
                                : theme.palette.text.disabled
                            }}
                          >
                            <Icon style={styles.infoIcon}>info</Icon>
                            <span style={{ marginLeft: '8px' }}>
                              {window.I18n.t('admin.videoPlaylist')}
                            </span>
                          </span>
                        </Tooltip>
                        <TextSwitch
                          containerStyles={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          checked={this.state.isPlaylist}
                          onChange={() => this.setState({ isPlaylist: !this.state.isPlaylist })}
                          color="primary"
                          name="isPlaylist"
                          disabled={!videoReadyToUpload}
                        />
                      </div>

                      {/* Football side-view. */}
                      <div css={styles.informationContainer}>
                        <Tooltip
                          title={window.I18n.t('admin.videoFootballSideViewTooltip')}
                          placement="bottom"
                        >
                          <span
                            css={styles.informationText}
                            style={{
                              color: videoReadyToUpload
                                ? theme.palette.text.darkGrey
                                : theme.palette.text.disabled
                            }}
                          >
                            <Icon style={styles.infoIcon}>info</Icon>
                            <span style={{ marginLeft: '8px' }}>
                              {window.I18n.t('admin.videoFootballSideView')}
                            </span>
                          </span>
                        </Tooltip>
                        <TextSwitch
                          containerStyles={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          checked={this.state.isFootballSideView}
                          onChange={() =>
                            this.setState({
                              isFootballSideView: !this.state.isFootballSideView
                            })
                          }
                          color="primary"
                          name="isFootballSideView"
                          disabled={!videoReadyToUpload}
                        />
                      </div>
                    </div>

                    {/* Team in the left side. */}
                    {this.state.vpType === 'gvp' && (
                      <div css={styles.informationContainer}>
                        <Tooltip
                          title={window.I18n.t('admin.localTeamOnTheLeftTooltip')}
                          placement="bottom"
                        >
                          <span
                            css={styles.informationText}
                            style={{
                              color: videoReadyToUpload
                                ? theme.palette.text.darkGrey
                                : theme.palette.text.disabled
                            }}
                          >
                            <Icon style={styles.infoIcon}>info</Icon>
                            <span style={{ marginLeft: '8px' }}>
                              {window.I18n.t('admin.localTeamOnTheLeft')}
                            </span>
                          </span>
                        </Tooltip>
                        <TextSwitch
                          containerStyles={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          checked={this.state.isLocalTeamOnTheLeft}
                          onChange={() =>
                            this.setState({
                              isLocalTeamOnTheLeft: !this.state.isLocalTeamOnTheLeft
                            })
                          }
                          color="primary"
                          name="isLocalTeamOnTheLeft"
                          disabled={!videoReadyToUpload}
                        />
                      </div>
                    )}

                    {/* Number of halves. */}
                    {this.state.vpType === 'gvp' && (
                      <div css={styles.informationContainer}>
                        <Tooltip
                          title={window.I18n.t('admin.numberOfHalvesTooltip')}
                          placement="bottom"
                        >
                          <span
                            css={styles.informationText}
                            style={{
                              color: videoReadyToUpload
                                ? theme.palette.text.darkGrey
                                : theme.palette.text.disabled
                            }}
                          >
                            <Icon style={styles.infoIcon}>info</Icon>
                            <span style={{ marginLeft: '8px' }}>
                              {window.I18n.t('admin.numberOfHalves')}
                            </span>
                          </span>
                        </Tooltip>
                        <TextSwitch
                          containerStyles={{
                            display: 'flex',
                            alignItems: 'center',
                            opacity: videoReadyToUpload ? 1.0 : 0.5,
                            pointerEvents: videoReadyToUpload ? 'all' : 'none'
                          }}
                          checked={this.state.numberOfHalves === 2}
                          onChange={() =>
                            this.setState({
                              numberOfHalves: this.state.numberOfHalves === 1 ? 2 : 1
                            })
                          }
                          color="primary"
                          name="numberOfHalves"
                          enabledText="2"
                          disabledText="1"
                          disabledColor={theme.palette.common.darkGrey}
                          disabledBackground={theme.palette.common.mainGreen}
                        />
                      </div>
                    )}

                    {/* Job toggles. */}
                    <div css={styles.jobsContainer}>
                      <div css={styles.jobTogglesContainer}>
                        {/* Player & Team Detection. */}
                        <div css={styles.jobContainer}>
                          <Tooltip
                            title={window.I18n.t('admin.playerTrackingTooltip')}
                            placement="top"
                          >
                            <div css={styles.jobText}>
                              <img
                                src={playerLogo}
                                css={styles.logoImg}
                                style={{ width: '18px' }}
                              />
                              <span style={{ paddingRight: '3px' }}>
                                {window.I18n.t('admin.playerTracking')}
                              </span>
                              <Icon style={styles.infoIcon}>info</Icon>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={window.I18n.t('admin.trackingLicence')}
                            placement="top"
                            {...(this.props.hasTrackingLicense ? { open: false } : {})}
                          >
                            <div css={styles.switchContainer}>
                              <Switch
                                checked={
                                  this.state.selectedFile &&
                                  this.state.automatedTrackingData &&
                                  this.props.hasTrackingLicense &&
                                  this.state.playerTracking
                                }
                                onChange={this.onTogglePlayerTracking}
                                name="playerTracking"
                                color="primary"
                                disabled
                              />
                            </div>
                          </Tooltip>
                        </div>
                        {/* Field Detection. */}
                        <div css={styles.jobContainer}>
                          <Tooltip
                            title={window.I18n.t('admin.fieldTrackingTooltip')}
                            placement="top"
                          >
                            <div css={styles.jobText}>
                              <img src={fieldLogo} css={styles.logoImg} style={{ width: '18px' }} />
                              <span style={{ paddingRight: '3px' }}>
                                {window.I18n.t('admin.fieldRecognition')}
                              </span>
                              <Icon style={styles.infoIcon}>info</Icon>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={window.I18n.t('admin.trackingLicence')}
                            placement="top"
                            {...(this.props.hasFRecognitionLicense ? { open: false } : {})}
                          >
                            <div css={styles.switchContainer}>
                              <Switch
                                checked={
                                  this.state.selectedFile &&
                                  this.state.isFootballSideView &&
                                  this.state.automatedTrackingData &&
                                  this.props.hasFRecognitionLicense &&
                                  this.state.fieldRecognition
                                }
                                onChange={this.onToggleFieldRecognition}
                                name="fieldRecognition"
                                color="primary"
                                disabled
                              />
                            </div>
                          </Tooltip>
                        </div>
                        {/* Ball Detection. */}
                        <div css={styles.jobContainer}>
                          <Tooltip
                            title={window.I18n.t('admin.ballTrackingTooltip')}
                            placement="top"
                          >
                            <div css={styles.jobText}>
                              <img src={ballLogo} css={styles.logoImg} style={{ width: '18px' }} />
                              <span style={{ paddingRight: '3px' }}>
                                {window.I18n.t('admin.ballDetection')}
                              </span>
                              <Icon style={styles.infoIcon}>info</Icon>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={window.I18n.t('admin.trackingLicence')}
                            placement="top"
                            {...(this.props.hasTrackingLicense ? { open: false } : {})}
                          >
                            <div css={styles.switchContainer}>
                              <Switch
                                checked={
                                  this.state.automatedTrackingData &&
                                  this.state.ballDetection &&
                                  this.state.isFootballSideView
                                }
                                onChange={this.onToggleBallDetection}
                                name="ballDetection"
                                color="primary"
                                disabled={
                                  !this.state.selectedFile ||
                                  !this.state.automatedTrackingData ||
                                  (this.vp &&
                                    // Avoid enable if post-processing stage has already started.
                                    (this.vp.files.automated_tracking_data ||
                                      (this.vp.jobs && this.vp.jobs.post_processing.length > 0) ||
                                      // Avoid disable if pre-processing stage has already started.
                                      (this.vp.compute_events &&
                                        this.vp.jobs.pre_processing.length > 0)))
                                }
                              />
                            </div>
                          </Tooltip>
                        </div>
                        {/* Events Detection. */}
                        {this.state.vpType === 'gvp' && (
                          <div css={styles.jobContainer}>
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <span>
                                    {window.I18n.t('admin.eventsDetectionTooltip.header')}
                                  </span>
                                  <ul>
                                    <li>ATTACKING PLAYERS IN THE BOX</li>
                                    <li>BALL IN FINAL THIRD</li>
                                    <li>BUILD UPS ({window.I18n.t('common.new')})</li>
                                    <li>COUNTER ATTACKS ({window.I18n.t('common.new')})</li>
                                    <li>DIRECT ATTACKS ({window.I18n.t('common.new')})</li>
                                    <li>GOALS</li>
                                    <li>ORGANIZED ATTACKS ({window.I18n.t('common.new')})</li>
                                    <li>SET PIECES - THROW-IN ({window.I18n.t('common.new')})</li>
                                    <li>
                                      SET PIECES - GOAL KICK (
                                      {window.I18n.t('admin.eventsDetectionTooltip.improved')})
                                    </li>
                                    <li>
                                      SET PIECES - CORNERS (
                                      {window.I18n.t('admin.eventsDetectionTooltip.improved')})
                                    </li>
                                    <li>SHOTS AND CROSSES ({window.I18n.t('common.new')})</li>
                                    <li>SPRINTS</li>
                                  </ul>
                                  <span>
                                    {window.I18n.t('admin.eventsDetectionTooltip.footer')}
                                  </span>
                                </React.Fragment>
                              }
                              placement="top"
                            >
                              <div css={styles.jobText}>
                                <img
                                  src={automaticTaggingLogo}
                                  css={styles.logoImg}
                                  style={{ width: '18px' }}
                                />
                                <span style={{ paddingRight: '3px' }}>Smart Tagging</span>
                                <Icon style={styles.infoIcon}>info</Icon>
                                <span css={styles.requestContainer}>
                                  <CustomBadge
                                    badgeContent={window.I18n.t('common.new')}
                                    color="primary"
                                  />
                                </span>
                              </div>
                            </Tooltip>
                            <Tooltip
                              title={window.I18n.t('admin.trackingLicence')}
                              placement="top"
                              {...(this.props.hasEventingLicense ? { open: false } : {})}
                            >
                              <div css={styles.switchContainer}>
                                <Switch
                                  checked={
                                    this.state.selectedFile &&
                                    !this.state.isPlaylist &&
                                    this.state.isFootballSideView &&
                                    this.state.automatedTrackingData &&
                                    this.props.hasEventingLicense &&
                                    this.state.ballDetection &&
                                    this.state.eventsDetection
                                  }
                                  onChange={this.onToggleEventsDetection}
                                  name="eventsDetection"
                                  color="primary"
                                  disabled={
                                    !this.props.hasEventingLicense ||
                                    !this.state.selectedFile ||
                                    !this.state.automatedTrackingData ||
                                    this.state.vpType === 'svp' ||
                                    (this.vp &&
                                      // Avoid enable if post-processing stage has already started.
                                      (this.vp.files.automated_tracking_data ||
                                        (this.vp.jobs && this.vp.jobs.post_processing.length > 0) ||
                                        // Avoid disable if pre-processing stage has already started.
                                        (this.vp.compute_events &&
                                          this.vp.jobs.pre_processing.length > 0)))
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </DialogContent>
                </div>
                {/* Step 2 */}
                {this.state.activeStep === 2 && (
                  <React.Fragment>
                    <div
                      css={css`
                        height: 48px;
                      `}
                    />
                    <DialogContent>
                      <div css={styles.progressWrapper}>
                        <CircularProgress color="primary" size={40} thickness={4} />
                        <div css={styles.loadingLabel}>
                          {window.I18n.t(this.vp ? 'admin.updatingGame' : 'admin.creatingGame')}
                        </div>
                      </div>
                    </DialogContent>
                  </React.Fragment>
                )}
                {this.state.activeStep === 2 && (
                  <div
                    css={css`
                      height: 36px;
                    `}
                  />
                )}
                {this.state.activeStep !== 2 && (
                  <DialogActions>
                    <Button
                      onClick={this.state.activeStep === 0 ? this.props.close : this.handleBack}
                      style={styles.fontBolder}
                    >
                      {this.state.activeStep === 0
                        ? window.I18n.t('common.cancel')
                        : window.I18n.t('common.back')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.handleNext(formik)}
                      style={styles.backgroundGradient}
                    >
                      {window.I18n.t(this.state.activeStep === 0 ? 'common.next' : 'common.ok')}
                    </Button>
                  </DialogActions>
                )}
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

CreateUpdateGameBySteps.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  stadiums: PropTypes.array.isRequired,
  competitions: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  nonCorrectedOrganizations: PropTypes.array.isRequired,
  createUpdateGame: PropTypes.func.isRequired,
  videoProject: PropTypes.object,
  onCreateUpdate: PropTypes.func.isRequired,
  isMetricaSportsUser: PropTypes.bool.isRequired,
  userOrganizations: PropTypes.array.isRequired,
  onOptionDelete: PropTypes.func.isRequired,
  startUploadVideo: PropTypes.func.isRequired,
  hasTrackingLicense: PropTypes.bool.isRequired,
  hasFRecognitionLicense: PropTypes.bool.isRequired,
  hasBallTrackingLicence: PropTypes.bool.isRequired,
  hasEventingLicense: PropTypes.bool.isRequired
}

CreateUpdateGameBySteps.defaultProps = {
  videoProject: undefined
}

export default CreateUpdateGameBySteps
